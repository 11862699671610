import styled from 'styled-components/macro';

export const Container = styled.div`
  /* min-height: 355px; */
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  word-break: break-word;
`;

export const AvatarContainer = styled.div`
  margin: 0 auto;
`;

export const UserNameContainer = styled.div`
  margin: 25px 0;
  text-align: center;
  min-height: 68px;
`;

export const UserDetailContainer = styled.div`
  display: grid;
  gap: 12px;
  div {
    display: flex;
    justify-content: space-between;
    & span:nth-child(1) {
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.GREY_4};
    }
    & span:nth-child(2) {
      font-weight: 400;
      line-height: 20px;
      color: ${({ theme }) => theme.GREY_1};
    }
  }
`;

export const ShowMoreContainer = styled.div`
  text-align: center;
  margin-top: 24px;
`;

export const UserIdFrame = styled.div`
  margin: 0 auto;
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.GREY_4};
`;

export const TagContainer = styled.div`
  width: 100%;
  margin-top: 4px;
`;
