import { Typography } from 'antd';
import styled from 'styled-components/macro';

export const Label = styled(Typography.Text)`
  font-size: 16px;
  margin-top: -5px;
`;

export const LabelBox = styled.div`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderContainer = styled.div`
  padding: 8px 16px;
`;

export const ContentBox = styled.div``;

export const IconBox = styled.div`
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
`;
