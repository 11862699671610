import React from 'react';
import { ITicket, TicketHistoryItem } from '@types';
import { isEmpty } from 'lodash';
import { Font12G1W500 } from '@components/UtilsComponent';
import { formatDate } from '@utils/day';

export type IFirstResponseProps = {
  ticket?: ITicket | TicketHistoryItem | null;
};

export const FirstResponse = ({ ticket }: IFirstResponseProps) => {
  if (!ticket) {
    return <span> -</span>;
  }
  if (isEmpty(ticket)) {
    return <span> -</span>;
  }
  if (isEmpty(ticket.firstResponder)) {
    return <span> -</span>;
  }

  return (
    <Font12G1W500>
      {ticket.firstResponder?.timestamp ? formatDate(ticket.firstResponder.timestamp, 'DD MMM YYYY, HH:mm') : ''}
      {ticket.firstResponder?.displayName ? ` (${ticket.firstResponder.displayName})` : ''}
    </Font12G1W500>
  );
};
