import { client } from '@api';
import { IGetByCursorParams, ISurveyChannel, ISurveyPayload } from '@types';

export const getChannelsSurveys = async (params?: IGetByCursorParams) => {
  const { data }: { data: ISurveyChannel[] } = await client.get('/api/v1/channels/surveys', {
    params: {
      take: params?.take,
      cursorId: params?.cursorId,
      keyword: params?.keyword ? params.keyword : undefined,
      skip: params?.skip,
    },
  });
  return data;
};

export const getChannelSurveyById = async (channelId: string, id: string) => {
  const { data } = await client.get(`/api/v1/channels/${channelId}/surveys/${id}`);
  return data;
};

export const createSurvey = async (payload: ISurveyPayload) => {
  const { data } = await client.post('/api/v1/channels/surveys', {
    ...payload,
  });
  return data;
};

export const updateSurvey = async (id: string, payload: ISurveyPayload) => {
  const { data } = await client.put(`/api/v1/channels/surveys/${id}`, {
    ...payload,
  });
  return data;
};

export const enableSurvey = async (payload: { surveyId: string; isEnabled: boolean; channelId: string }) => {
  const { data } = await client.patch(`/api/v1/channels/surveys/enable/${payload.surveyId}`, {
    isEnabled: payload.isEnabled,
    channelId: payload.channelId,
  });
  return data;
};
