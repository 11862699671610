import { IQuickReplyCollectionResponse } from '@types';
import { useState } from 'react';

export const useTemplateComposer = () => {
  const [selectedQuickReply, setSelectQuickReply] = useState<IQuickReplyCollectionResponse | null>(null);
  return {
    selectedQuickReply,
    onSelectQuickReply: (quickReply: IQuickReplyCollectionResponse | null) => {
      setSelectQuickReply(quickReply);
    },
  };
};
