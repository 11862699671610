import styled from 'styled-components/macro';
import { Input as AntdInput } from 'antd';

export const Input = styled(AntdInput)<{ isGrayBg?: boolean }>`
  border-radius: 7px;
  background: ${({ isGrayBg, theme }) => (isGrayBg ? 'rgba(0, 0, 0, 0.04)' : theme.WHITE)};
  border: ${({ isGrayBg }) => (isGrayBg ? 'none' : '')};
  &.ant-input-affix-wrapper-focused {
    border-color: ${({ theme }) => theme.SECONDARY_BLUE} !important;
    box-shadow: none !important;
    .ant-input-prefix svg {
      color: ${({ isGrayBg, theme }) => (isGrayBg ? 'rgb(158, 158, 158)' : theme.SECONDARY_BLUE)};
    }
  }

  input {
    background: ${({ isGrayBg, theme }) => (isGrayBg ? 'transparent' : theme.WHITE)};
  }
  svg {
    transition: all 0.3s;
    color: ${({ theme }) => theme.GREY_5};
  }
  .ant-input-suffix {
    cursor: pointer;
  }
`;
