import styled from 'styled-components/macro';

export const ErrorWrap = styled.div`
  img {
    margin: 0 auto;
  }
  display: grid;
  gap: 8px;
  text-align: center;
  div:nth-child(2) span {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${({ theme }) => theme.GREY_5};
  }
  div:nth-child(3) span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.GREY_5};
  }
`;
