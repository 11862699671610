import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const ActionAnimation = styled.div<{ state: any }>`
  display: ${({ state }) => (state === 'exited' ? 'none' : 'block')};
  ${({ state }) =>
    state === 'entered'
      ? `
          height: 392px;
          overflow: hidden;
        `
      : `
          overflow: hidden;
          height: 0px;
          border: none;
        `};
  transition: height 0.3s ease;
`;

export const ToastContainer = styled.div`
  padding: 0 12px;
`;
