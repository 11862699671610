import styled from 'styled-components/macro';

export const TicketHistoryMessageBoxContainer = styled.div`
  flex: 1;
  overflow: hidden;
  position: relative;
`;

export const MessageComposerContainer = styled.div`
  padding: 24px;
  height: 88px;
`;
