import { BOTS } from '@types';
import React from 'react';
import { AvatarContainer, ContentWrap, QuickReplyWrap } from './style';
import Avatar from '@assets/images/sample_avatar.png';
import { TextPreview } from '../TextPreview';
import { ImagePreview } from '../ImagePreview';
import { VideoPreview } from '../VideoPreview';
import { CarouselPreview } from '../CarouselPreview';
import { ImageCarouselPreview } from '../ImageCarouselPreview';
import { ButtonsPreview } from '../ButtonPreview';
import { render } from 'flex-render';
import { FlexWrap } from '../PreviewWrapper/style';
import root from 'react-shadow';
import { decodeHTML } from '@utils/html';

type IPreviewContentProps = {
  messages: BOTS.IMessageApi[];
};

export const PreviewContent = ({ messages }: IPreviewContentProps) => {
  return (
    <>
      {messages.map((item) => {
        if (!item) return null;
        if (item.type === 'text') {
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <TextPreview text={item.text} />
            </Wrap>
          );
        }
        if (item.type === 'image') {
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <ImagePreview url={item.previewImageUrl} />
            </Wrap>
          );
        }

        if (item.type === 'video') {
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <VideoPreview url={item.originalContentUrl} />
            </Wrap>
          );
        }

        if (item.type === 'imagemap') {
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <ImagePreview url={item.baseUrl} />
            </Wrap>
          );
        }

        if (item.type === 'image_carousel') {
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <ImageCarouselPreview carousel={item} />
            </Wrap>
          );
        }

        if (item.type === 'carousel') {
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <CarouselPreview carousel={item} />
            </Wrap>
          );
        }

        if (item.type === 'buttons') {
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <ButtonsPreview buttons={item} />
            </Wrap>
          );
        }

        if (item.type === 'flex') {
          const result = render(item.contents);
          if (!result) {
            return null;
          }
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <FlexWrap dangerouslySetInnerHTML={{ __html: result }} />
            </Wrap>
          );
        }
        if (item.type === 'html') {
          const decodedHtml = decodeHTML(item.htmlContent);
          return (
            <Wrap key={item.id} quickReply={item.quickReply}>
              <root.div
                className="overflow-auto max-h-[400px] p-[16px] bg-white rounded-[8px] border border-t-0 border-[#E0E0E0]"
                // prevent position fixed from parent
                style={{
                  transform: 'scale(1)',
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodedHtml,
                  }}
                />
              </root.div>
            </Wrap>
          );
        }
        return null;
      })}
    </>
  );
};

export const Wrap = ({ children, quickReply }: { children?: React.ReactNode; quickReply?: BOTS.IQuickReplyApi }) => {
  return (
    <AvatarContainer>
      <div className="min-w-[30px] h-[30px]">
        <img className="w-full h-full" width={30} height={30} src={Avatar} />
      </div>
      <ContentWrap>
        {children}
        <QuickReplyWrap>
          {quickReply?.items.map((item) => {
            return <div key={item.action.id}>{item.action.label}</div>;
          })}
        </QuickReplyWrap>
      </ContentWrap>
    </AvatarContainer>
  );
};
