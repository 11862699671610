import React, { useEffect } from 'react';
import { QuickReplyFilter } from '@components/QuickReplyFilter';
import SearchInput from '@components/SearchInput';
import { Font14G1W400 } from '@components/UtilsComponent';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { BOTS, IQuickReplyCollectionResponse } from '@types';
import { IOption } from '@components/Select';
import { fetchTemplateQuickReplyCollection } from '@api/quickReply';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { scrollHandler } from '@utils/scroll';
import { useTemplateComposer } from './useTemplateComposer';
export type ITemplateComposerProps = {
  platform?: string;
  onSelectTemplate: (template: BOTS.ITemplateApi | null) => void;
};

const getTemplateId = (input: IQuickReplyCollectionResponse | null): string | undefined => {
  if (!input) return undefined;
  if (input.id === 'all') {
    return undefined;
  }

  return input.id;
};

export const TemplateComposer = ({ platform, onSelectTemplate }: ITemplateComposerProps) => {
  const { selectedQuickReply, onSelectQuickReply } = useTemplateComposer();
  const {
    data,
    searchKeyword,
    hasMore,
    firstLoading,
    isLoadingMore,
    onOpenDropdown,
    onLoadmore,
    onUpdateSearchKeyword,
  } = useInfinityLoadByCursorId<BOTS.ITemplateApi, IOption>({
    initialFilter: {
      take: 100,
    },
    fetchFn: async (filter, cancelToken) => {
      const newFilter = {
        keyword: filter.keyword || undefined,
        cursorId: filter.cursorId || undefined,
        take: filter.take,
        templateCollectionId: getTemplateId(selectedQuickReply),
      };
      const data = await fetchTemplateQuickReplyCollection(
        newFilter.keyword,
        newFilter.cursorId,
        newFilter.take,
        newFilter.templateCollectionId,
        platform === 'custom' ? undefined : platform,
        cancelToken,
      );
      const result = data.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
      return result;
    },
  });
  useEffect(() => {
    onOpenDropdown();
  }, []);

  useEffect(() => {
    if (selectedQuickReply) {
      onOpenDropdown();
    }
  }, [selectedQuickReply]);
  return (
    <div className="w-full flex flex-col">
      <div className="flex mb-[8px] gap-x-[8px]">
        <QuickReplyFilter
          selectedQuickReply={selectedQuickReply}
          onSelectQuickReply={(item: IQuickReplyCollectionResponse | null) => {
            onSelectQuickReply(item);
          }}
        />
        <SearchInput
          className="w-[328px]"
          value={searchKeyword}
          onChange={(e) => onUpdateSearchKeyword(e.target.value)}
          onClear={() => {
            onUpdateSearchKeyword('');
          }}
        />
      </div>

      <div
        className="grid grid-cols-[repeat(auto-fill,minmax(120px,1fr))] gap-[8px] max-h-[168px] overflow-auto pr-1"
        onScroll={(e) => {
          if (!hasMore || firstLoading || isLoadingMore) return;
          scrollHandler.onScrollToBottom(e, onLoadmore);
        }}
      >
        {data.map((template) => {
          return (
            <button
              key={template.id}
              onClick={() => onSelectTemplate(template)}
              className="p-[8px] overflow-hidden bg-[#F5F5F5] rounded-[4px] flex justify-center items-center h-[36px] "
            >
              <Font14G1W400 className="overflow-ellipsis overflow-hidden whitespace-nowrap">
                {template.name}
              </Font14G1W400>
            </button>
          );
        })}
      </div>
      {firstLoading || isLoadingMore ? (
        <LoadingWrap>
          <LoadingCircle />
        </LoadingWrap>
      ) : null}
    </div>
  );
};
