import { createMachine, assign, actions } from 'xstate';
import { TEAM_MANAGEMENT_TABS_DATA } from '@configs/constants';
import { getAllMembers, getTeams, deleteMember, deleteTeam, getVirtualAgents } from '../fns';
import { ITeam } from '@types';
const { choose } = actions;

export type ITeamFetchSource = 'team' | 'va';

export type IMember = {
  _id: string;
  username: string;
  nid: string;
  teams: any[];
  status: string;
  tickets: string[];
  last_assigned: string;
  hold_ticket_count: number;
  roles: string[];
  password: {
    mustChange: boolean;
  };
};

export type IMemberList = {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  rows: IMember[];
};

export type IVaList = {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  rows: IVaData[];
};

export type IVaData = {
  _id: string;
  username: string;
  displayName: string;
  email: string;
  socket: any[];
  nid: string;
  status: string;
  roles: string[];
  roleOrder: number;
  createdAt: Date;
  lastLoggedInAt: ILastLoggedInAt;
  teams: any[];
  tickets: any[];
  virtualAgentConfig: IVirtualAgentConfig;
};

export type ILastLoggedInAt = {
  $date: Date;
};

export type IVirtualAgentConfig = {
  virtualAgentSource: string;
};

export type ITeamList = {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  rows: ITeam[];
};

export type IControllerContext = {
  searchTxt: string;
  selectedRole: string;
  memberCurrentPage: number;
  teamCurrentPage: number;
  selectedMember: IMember;
  selectedTeam: ITeam;
  teamFilters: {
    key: string;
    items: {
      label: string;
      value: string;
    }[];
  }[];
  selectedTab: {
    key: string;
    name: string;
  };
  memberData: IMemberList;
  teamData: ITeamList;
  vaData: IVaList;
};

const teamManagementControllerMachine = createMachine<IControllerContext>(
  {
    id: 'team-management-controller-machine',
    type: 'parallel',
    context: {
      //filter
      searchTxt: '',
      selectedRole: 'admin,supervisor,agent,bot',
      memberCurrentPage: 1,
      teamCurrentPage: 1,
      teamFilters: [],
      selectedTab: {
        key: TEAM_MANAGEMENT_TABS_DATA[0].key,
        name: TEAM_MANAGEMENT_TABS_DATA[0].name,
      },
      selectedMember: {} as IMember,
      selectedTeam: {} as ITeam,
      // render on table
      memberData: {} as IMemberList,
      teamData: {} as ITeamList,
      vaData: {} as IVaList,
    },
    states: {
      filterCriteria: {
        on: {
          TAB_CHANGE: {
            target: 'fetchData.fetchingTeam',
            actions: 'updateCurrentTab',
          },
          ROLE_CHANGE: {
            target: 'fetchData.fetchingMember',
            actions: 'updateSelectedRole',
          },
          SEARCH_TEXT_CHANGE: [
            {
              target: 'fetchData.debounceFetchTeam',
              actions: ['updateSearchTxt'],
              cond: (context) => context.selectedTab.key === 'virtual-agent',
            },
            {
              target: 'fetchData.debounceFetchTeam',
              actions: ['updateSearchTxt'],
              cond: (context) => context.selectedTab.key === 'team',
            },
          ],
        },
      },
      fetchData: {
        initial: 'fetchingTeam',
        states: {
          firstFetch: {
            invoke: {
              id: 'team-management-first-fetching-member-data',
              src: getAllMembers,
              onDone: {
                target: 'standby',
                actions: 'updateMembers',
              },
              onError: 'fetchMemberFailed',
            },
          },
          standby: {
            on: {
              FETCH_MEMBER: {
                target: 'fetchingMember',
              },
              FETCH_TEAM: {
                target: 'fetchingTeam',
              },
              DELETE_MEMBER: {
                target: 'deleteMember',
                actions: 'updateSelectedMember',
              },
              DELETE_TEAM: {
                target: 'deleteTeam',
                actions: 'updateSelectedTeam',
              },
            },
          },
          fetchingMember: {
            entry: 'updateMemberCurrentPage',
            invoke: {
              id: 'team-management-fetching-member-data',
              src: getAllMembers,
              onDone: {
                target: 'standby',
                actions: 'updateMembers',
              },
              onError: {
                target: 'fetchMemberFailed',
                actions: 'clearMemberData',
              },
            },
            on: {
              ROLE_CHANGE: {
                target: 'fetchingMember',
                actions: 'updateSelectedRole',
              },
            },
          },
          debounceFetchMember: {
            on: {
              SEARCH_TEXT_CHANGE: [
                {
                  target: 'debounceFetchMember',
                  actions: 'updateSearchTxt',
                },
              ],
            },
            after: {
              450: {
                target: 'fetchingMember',
              },
            },
          },
          fetchMemberFailed: {
            on: {
              STANDBY: 'standby',
            },
          },
          deleteMember: {
            on: {
              CONFIRM_DELETE_MEMBER: {
                target: 'deletingMember',
                actions: 'updateSearchTxt',
              },
              CANCEL_DELETE_MEMBER: 'standby',
            },
          },
          deletingMember: {
            invoke: {
              id: 'team-management-deleting-member',
              src: deleteMember,
              onDone: 'fetchingMember',
              onError: 'deleteMemberFailed',
            },
          },
          deleteMemberFailed: {
            tags: 'deleteFailed',
            on: {
              STANDBY: 'standby',
            },
          },
          //team
          fetchingTeam: {
            entry: 'updateTeamCurrentPage',
            invoke: {
              id: 'team-management-fetching-team',
              src: async (context) => {
                if (context.selectedTab.key === 'team') {
                  const { data } = await getTeams(context);
                  return {
                    payload: data,
                    from: 'team',
                  };
                } else {
                  const { data } = await getVirtualAgents(context);
                  return {
                    payload: data,
                    from: 'va',
                  };
                }
              },
              onDone: {
                target: 'standby',
                actions: choose([
                  {
                    cond: (context, event) => event.data.from === 'team',
                    actions: 'updateTeams',
                  },
                  {
                    cond: (context, event) => event.data.from === 'va',
                    actions: 'updateVirtualAgents',
                  },
                ]),
              },
              onError: {
                target: 'fetchTeamFailed',
                actions: 'clearTeamData',
              },
            },
          },
          fetchTeamFailed: {
            on: {
              STANDBY: 'standby',
            },
          },
          debounceFetchTeam: {
            on: {
              SEARCH_TEXT_CHANGE: [
                {
                  target: 'debounceFetchTeam',
                  actions: 'updateSearchTxt',
                },
              ],
            },
            after: {
              450: {
                target: 'fetchingTeam',
              },
            },
          },
          deleteTeam: {
            on: {
              CONFIRM_DELETE_TEAM: 'deletingTeam',
              CANCEL_DELETE_TEAM: 'standby',
            },
          },
          deletingTeam: {
            invoke: {
              id: 'team-management-deleting-team',
              src: deleteTeam,
              onDone: 'fetchingTeam',
              onError: 'deleteTeamFailed',
            },
          },
          deleteTeamFailed: {
            tags: 'deleteFailed',
            on: {
              STANDBY: 'standby',
            },
          },
        },
      },
    },
  },
  {
    actions: {
      updateCurrentTab: assign({
        selectedTab: (_context, event) => {
          const tabItem = TEAM_MANAGEMENT_TABS_DATA.find((item) => item.key === event.value);
          return tabItem ?? TEAM_MANAGEMENT_TABS_DATA[0];
        },
        // reset to current page when tab change
        teamCurrentPage: (_context) => {
          return 1;
        },
      }),
      updateSelectedRole: assign({
        selectedRole: (_context, event) => {
          return event.value;
        },
      }),
      updateSearchTxt: assign({
        searchTxt: (_context, event) => {
          if (!event.searchTxt) return '';
          return event.searchTxt;
        },
      }),
      //member
      updateMembers: assign({
        memberData: (context, event) => {
          const { data } = event.data;
          return data;
        },
      }),
      clearMemberData: assign({
        memberData: (_context, _event) => {
          return {} as IMemberList;
        },
      }),
      clearTeamData: assign({
        teamData: (_context, _event) => {
          return {} as ITeamList;
        },
      }),
      updateSelectedMember: assign({
        selectedMember: (context, event) => {
          return event.data;
        },
      }),
      updateMemberCurrentPage: assign({
        memberCurrentPage: (context, event) => {
          const { memberData } = context;
          const { type } = event;
          if (type === 'done.invoke.team-management-deleting-member') {
            //for pagination reason if current page user has 1 and got deleted current page mush minus 1 for get remain member otherwise it will return empty
            if (context.memberCurrentPage !== 1 && memberData.rows.length === 1) {
              return context.memberCurrentPage - 1;
            }
            return context.memberCurrentPage;
          }
          return event.page ?? 1;
        },
      }),
      // team
      updateTeams: assign({
        teamData: (_context, event) => {
          const { payload } = event.data;
          return payload;
        },
      }),
      updateVirtualAgents: assign({
        vaData: (_, event) => {
          const { payload } = event.data;
          return payload;
        },
      }),
      updateTeamCurrentPage: assign({
        teamCurrentPage: (context, event) => {
          const { teamData } = context;
          const { type } = event;
          if (type === 'done.invoke.team-management-deleting-team') {
            //for pagination reason if current page user has 1 and got deleted current page mush minus 1 for get remain member otherwise it will return empty
            if (context.teamCurrentPage !== 1 && teamData.rows.length === 1) {
              return context.teamCurrentPage - 1;
            }
            return context.teamCurrentPage;
          }
          return event.page ?? 1;
        },
      }),
      updateSelectedTeam: assign({
        selectedTeam: (context, event) => {
          const { data } = event;
          return data;
        },
      }),
    },
  },
);

export default teamManagementControllerMachine;
