import styled from 'styled-components/macro';
import { Input } from 'antd';

export const AntdTextarea = styled(Input.TextArea)``;

export const Container = styled.div`
  textarea {
    border: 1px solid ${({ theme }) => theme.GREY_7} !important;
    box-shadow: none !important;
    box-sizing: border-box;
    border-radius: 8px !important;
    min-height: 100px;
  }
`;
