import { useRef, useEffect, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';

export const useCancelTokenSource = () => {
  const axiosSource = useRef<CancelTokenSource | null>(null);
  const newCancelTokenSource = useCallback(() => {
    axiosSource.current?.cancel();
    axiosSource.current = axios.CancelToken.source();
    return axiosSource.current;
  }, []);

  return { newCancelTokenSource };
};
