import { IResolveForm } from '@components/ResolveTicket/ResolveModal/useResolveModal';

export const getSummaryFromPayload = (payload: IResolveForm) => {
  return {
    categories: payload.data.categories,
    reasons: payload.data.reasons,
    result: payload.data.result,
    note: payload.data.note,
  };
};
