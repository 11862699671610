import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 191px;
  > div {
    width: 100%;
  }
  button {
    width: 100%;
    display: flex;
    padding: 5px 12px;
    border: 1px solid ${({ theme }) => theme.GREY_7};
    border-radius: 8px;
    height: 36px;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.GREY_1} !important;
    border-color: ${({ theme }) => theme.GREY_7} !important;
    & > div:nth-child(1) {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;
