import React from 'react';
import { Container, Image } from './style';

export type IImagemapMessage = {
  url: string;
};

export const ImagemapMessage: React.FC<IImagemapMessage> = ({ url }: IImagemapMessage) => {
  return (
    <Container>
      <Image loading="lazy" src={url} />
    </Container>
  );
};
