import React, { useEffect, useMemo } from 'react';
import { Button, GhostButton, PrimaryButton, TertiaryButton } from '@components/Button';
import { Checkbox } from '@components/Checkbox';
import SearchInput from '@components/SearchInput';
import Table, { IColumns } from '@components/Table';
import TextInput from '@components/TextInput';
import { Font14G1W600, Font14G3W400 } from '@components/UtilsComponent';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { ConfigProvider, Spin } from 'antd';
import { useEditor, useModalListSelect } from './useEditor';
import { IReasonBE, IReasonCategoryItemFE } from '@types';
import {
  CATEGORY_REASON_NAME_MAX_LENGTH,
  CATEGORY_REASON_NAME_MIN_LENGTH,
  CATEGORY_REASON_SELECTION_MAX_LENGTH,
} from '@configs/wrapup';
import { ListItem } from './style';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEmpty } from 'lodash';
import './style.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTE } from '@configs/route';
import { Typography } from 'antd';
import { RouteLeavingGuard } from '@components/RouteLeavingGuard';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { ModalList } from '@components/ModalList';
import { fetchReasons } from '@api/livechat/Wrapup';
import { checkOrUnCheckById } from '@utils/checkOrUnCheck';

const { Title } = Typography;
export const ReasonCategoryEditor = () => {
  const t = useTranslation();
  const { manage } = useEditor();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const modalListSelect = useModalListSelect<IReasonBE>();
  const generateColumns = (
    selected: IReasonCategoryItemFE[],
    onCheckAll: (e: CheckboxChangeEvent) => void,
    onCheckSingle: (e: CheckboxChangeEvent, record: IReasonCategoryItemFE) => void,
  ): IColumns[] => {
    return [
      {
        title: () => {
          const isNotEmpty = selected.length > 0;
          const isCheckAll = isNotEmpty && selected.every((item) => item.isChecked);
          return <Checkbox checked={isCheckAll} disabled={!isNotEmpty} onChange={onCheckAll} />;
        },
        className: 'align-middle cursor-pointer',
        width: 40,
        render: (record: IReasonCategoryItemFE) => {
          return (
            <Checkbox
              onChange={(e) => {
                onCheckSingle(e, record);
              }}
              checked={record.isChecked}
            />
          );
        },
      },
      {
        title: 'REASON',
        className: 'align-middle cursor-pointer',
        ellipsis: true,
        render: (record: IReasonCategoryItemFE) => (
          <div className="cursor-pointer overflow-ellipsis overflow-hidden">{record.name}</div>
        ),
      },
      {
        title: (
          <div className="text-right pr-4">
            {selected.length}/{CATEGORY_REASON_SELECTION_MAX_LENGTH}
          </div>
        ),
        className: 'align-middle cursor-pointer',
        render(value, record: IReasonCategoryItemFE) {
          return (
            <div className="flex items-center justify-end ml-[-16px]">
              <div className="w-[56px] h-[44px] flex items-center ml-4">
                <FontAwesomeIcon
                  role="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    manage.onRemoveSingleReason(record);
                  }}
                  icon={faTrash}
                  color="#BDBDBD"
                  className="text-base flex-1"
                />
              </div>
            </div>
          );
        },
      },
    ];
  };
  const isSelectSome =
    !isEmpty(manage.reasonCategory.reasons) && manage.reasonCategory.reasons.some((item) => item.isChecked);

  const dataList = useMemo(
    () =>
      manage.reasonCategory.reasons.filter((item) => item.name.toLowerCase().includes(manage.keyword.toLowerCase())),
    [manage.reasonCategory, manage.keyword],
  );

  useEffect(() => {
    // update case
    // update data in modal when query data is available
    if (manage.query.data) {
      const setter = modalListSelect.getSetter();

      const outListBE: IReasonBE[] = manage.query.data.reasons.map((item) => {
        return {
          id: item.id,
          name: item.name,
          createdAt: '',
          networkId: '',
          ticketWrapUpCategoryIds: [],
          updatedAt: '',
        };
      });
      setter(outListBE);
    }
  }, [manage.query.data]);

  return (
    <div className="py-10 pt-[44px] h-full flex flex-col min-w-[1148px]">
      <Spin
        spinning={manage.createMute.isLoading || manage.updateMute.isLoading || manage.query.isLoading}
        indicator={
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        }
      >
        <RouteLeavingGuard
          when={manage.isSomethingUpdate}
          navigate={(path) => {
            history.push(path);
          }}
          shouldBlockNavigation={() => {
            if (manage.isSomethingUpdate) {
              return true;
            }
            return false;
          }}
        />
        <div className="flex justify-between items-center">
          <Title level={3}>{t('reason.category.title')}</Title>
          <div className="flex gap-x-1">
            <Link to={ROUTE.TICKET_WRAP_UP}>
              <GhostButton>{t('cancel.btn')}</GhostButton>
            </Link>
            <PrimaryButton
              onClick={id ? manage.onUpdate : manage.onSave}
              disabled={
                isEmpty(manage.reasonCategory.reasons) ||
                !manage.reasonCategory.name ||
                manage.reasonCategory.name.trim().length < CATEGORY_REASON_NAME_MIN_LENGTH
              }
            >
              {t('save.and.close.btn')}
            </PrimaryButton>
          </div>
        </div>

        <div className="mt-6 flex flex-col gap-y-2">
          <Font14G1W600>{t('reason.category.name.label')}</Font14G1W600>
          <TextInput
            className="w-[425px]"
            onChange={manage.onNameChange}
            maxLength={CATEGORY_REASON_NAME_MAX_LENGTH}
            value={manage.reasonCategory.name}
            size="large"
            placeholder={t('reason.category.add.reason.input.placeholder')}
          />
        </div>
        <div className="mt-6 flex justify-between">
          <div>
            <SearchInput
              className="w-[316px]"
              value={manage.keyword}
              onChange={(e) => manage.onSearch(e.target.value)}
              onClear={() => {
                manage.onSearch('');
              }}
            />
          </div>
          <div className="flex gap-x-2">
            <TertiaryButton
              onClick={() => {
                const outList = manage.reasonCategory.reasons.filter((item) => !item.isChecked);
                manage.onDeleteSelected(outList);
                const setter = modalListSelect.getSetter();
                const outListBE: IReasonBE[] = outList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    createdAt: '',
                    networkId: '',
                    ticketWrapUpCategoryIds: [],
                    updatedAt: '',
                  };
                });
                setter(outListBE);
              }}
              disabled={!isSelectSome}
            >
              {t('delete.selection')}
            </TertiaryButton>

            <Button onClick={modalListSelect.onToggle} icon={<FontAwesomeIcon className="mr-2" icon={faPlus} />}>
              {t('reason.category.add.reason.btn.text')}
            </Button>
          </div>
        </div>
        <div className="mt-4 gap-x-4 flex-1">
          <div className="w-full category-reason-table">
            <ConfigProvider renderEmpty={() => <div>{t('reason.category.table.empty')}</div>}>
              <Table
                header={undefined}
                data={dataList}
                columns={generateColumns(manage.reasonCategory.reasons, manage.onCheckAll, manage.onCheckSingle)}
                pagination={false}
                scroll={{ y: 'calc(100vh - 390px)' }}
              />
            </ConfigProvider>
          </div>
        </div>
      </Spin>
      <ModalList<IReasonBE>
        title={t('reason.category.modal.title')}
        columns={[
          {
            label: '',
            key: '',
            width: '56px',
          },
          {
            label: 'REASON',
            key: 'name',
            width: '540px',
          },
          {
            label: `${modalListSelect.select.length}/${CATEGORY_REASON_SELECTION_MAX_LENGTH}`,
            key: '',
            width: '156px',
          },
        ]}
        fetchFn={async (params) => {
          return await fetchReasons(params.take, params.keyword, params.cursorId);
        }}
        visible={modalListSelect.showModal}
        onToggleModal={function (event: 'confirm' | 'close'): void {
          if (event === 'confirm') {
            manage.onAddReasonCategory(modalListSelect.select);
          }
          modalListSelect.onToggle();
        }}
        _renderItems={(items) => {
          return items.map((item) => {
            const isSelected = modalListSelect.select.some((selected) => selected.id === item.id);
            return (
              <ListItem
                title={item.name}
                isChecked={false}
                onClick={() => {
                  const setter = modalListSelect.getSetter();
                  setter((prev) => {
                    return checkOrUnCheckById(prev, item);
                  });
                }}
                key={item.id}
              >
                <div>
                  <Checkbox checked={isSelected} />
                </div>
                <div className="w-full">
                  <span>{item.name}</span>
                </div>
              </ListItem>
            );
          });
        }}
        extra={
          <div className="mt-[16px]">
            <Font14G3W400 className="mr-[8px]">{t('reason.category.modal.new.reason.txt')}</Font14G3W400>
            <Link to={'/settings/ticket-wrap-up?tab=reason&open=1'} role="link" className="text-[#0F86FE]">
              {t('reason.category.modal.new.reason.link')}
            </Link>
          </div>
        }
      />
    </div>
  );
};
