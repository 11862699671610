import styled from 'styled-components/macro';

export const Container = styled.div``;

export const Menu = styled.ul``;
export const MenuItem = styled.li`
  height: 56px;
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const StatusActionContainer = styled.div`
  display: grid;
  align-items: center;
  column-gap: 8px;
  grid-template-columns: auto 7px;
  span {
    color: ${({ theme }) => theme.GREY_5};
  }
  svg {
    font-size: 18px;
    color: ${({ theme }) => theme.GREY_5};
  }
`;

export const LogoutBox = styled.div`
  display: grid;
  font-size: 16px;
  column-gap: 16px;
  grid-template-columns: 16px auto;
  color: ${({ theme }) => theme.RED};
`;

export const StatusBox = styled.div``;

export const MemberStatusItem = styled.li<{ circleColor?: string }>`
  padding: 0 16px;
  height: 44px;
  display: flex !important;
  align-items: center;
  ${StatusBox} {
    flex: 1;
    display: flex;
    align-items: center;
    div:nth-child(1) {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: ${({ circleColor }) => circleColor};
      margin-right: 8px;
    }
  }
`;
