import {
  ERROR_REQUIRED_ASSIGNED,
  PERMISSION_DENIED,
  PERMISSION_DENIED_NAME_ERROR,
  REFRESH_PAGE_BUTTON,
} from '@configs/constants';
import { useAlertModal } from '@providers/AlertModalProvider';
import { IMember, ITicket } from '@types';
import { AxiosError } from 'axios';
import { useState, useEffect } from 'react';
import { UseMutationResult, useMutation } from 'react-query';

type IUseFollowUpParams = {
  ticket: ITicket | null;
  member: IMember;
  initCondition: (
    ticket: ITicket,
    member: IMember,
    setter: (value: React.SetStateAction<boolean | null>) => void,
  ) => boolean;
  updateFollowUpFn: (ticketId: string, status: { flag: 'follow' | 'unfollow' }) => Promise<any>;
};

/**
 * This function is used for follow up
 *
 */
export const useFollowUp = ({
  ticket,
  member,
  initCondition,
  updateFollowUpFn,
}: IUseFollowUpParams): [
  boolean | null,
  UseMutationResult<
    any,
    unknown,
    {
      ticketId: string;
      data: {
        flag: 'follow' | 'unfollow';
      };
    },
    unknown
  >,
  () => Promise<void>,
] => {
  const { onOpen, onUpdateDetail } = useAlertModal();
  const followUpMutation = useMutation((payload: { ticketId: string; data: { flag: 'follow' | 'unfollow' } }) =>
    updateFollowUpFn(payload.ticketId, payload.data),
  );
  const [followUp, setFollowUp] = useState<boolean | null>(null);
  useEffect(() => {
    if (ticket) {
      // initial
      if (initCondition(ticket, member, setFollowUp)) {
        return;
      }

      setFollowUp(false);
    }
  }, [member, ticket]);

  const onFollowUp = async () => {
    const tempFollowUp = followUp;
    try {
      if (ticket) {
        setFollowUp(!followUp);
        if (!followUp) {
          await followUpMutation.mutateAsync({ ticketId: ticket._id, data: { flag: 'follow' } });
        } else {
          await followUpMutation.mutateAsync({ ticketId: ticket._id, data: { flag: 'unfollow' } });
        }
      }
    } catch (error) {
      setFollowUp(tempFollowUp);
      const err = error as AxiosError;
      const errorName = err.response?.data?.name ? (err.response?.data?.name as string) : '';
      const errorMessage = err.response?.data?.message ? (err.response?.data?.message as string) : '';
      if (errorName === PERMISSION_DENIED_NAME_ERROR) {
        onUpdateDetail(
          PERMISSION_DENIED,
          errorMessage.replace(/\((.*?)\)/, ''),
          errorMessage === ERROR_REQUIRED_ASSIGNED ? 'Close' : REFRESH_PAGE_BUTTON,
        );
        onOpen();
      }
    }
  };
  return [followUp, followUpMutation, onFollowUp];
};
