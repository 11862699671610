import { EMessageType } from '@enums/MessageType';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { IMessageLevel } from '@types';

type ILocationMessage = {
  text: string;
} & BaseMessageJson;

const regex = /Location\(([-+]?\d*\.\d+),([-+]?\d*\.\d+)\)\s*(.*)/;

export class LocationMessage extends BaseMessage {
  private text: string;
  private level: IMessageLevel;
  private lat: number;
  private lng: number;

  constructor(data: ILocationMessage) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    const match = regex.exec(data.text);

    if (match) {
      const latitude = match[1];
      const longitude = match[2];
      const locationInfo = match[3];
      this.lat = parseFloat(latitude);
      this.lng = parseFloat(longitude);
      this.text = locationInfo;
    } else {
      this.lat = 0;
      this.lng = 0;
      this.text = 'Not found location info';
    }
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      text: this.text,
      lat: this.lat,
      lng: this.lng,
      retry: this.retry,
      level: this.level,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  get getMessageType() {
    return EMessageType.LOCATION;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
