import { DropdownItem as IExtendableDropdown } from '@components/Dropdown/ExtendableDropdown';

export const generateDropdownOption = <T>(
  data: T[],
  { labelKey, valueKey }: { labelKey: keyof T; valueKey: keyof T },
): (T & IExtendableDropdown)[] => {
  const result: (T & IExtendableDropdown)[] =
    data?.map((item) => {
      return {
        ...item,
        label: item[labelKey] as unknown as string,
        value: item[valueKey] as unknown as string,
      };
    }) ?? [];
  return result;
};
