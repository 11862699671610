// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ChangeEvent } from 'react';
import All from '@assets/images/all-channel-icon.svg';
import { Checkbox } from '@components/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Typography, Avatar, DropDownProps } from 'antd';
import _ from 'lodash';
import {
  Button,
  Container,
  Dropdown,
  DropDownSearch,
  DropdownSearchContainer,
  IconContainer,
  Item,
  ItemGroup,
  ItemGroupTitle,
  Menu,
  PlaceHolder,
  SelectedItem,
  ImgCol,
  DropdownOptionLabel,
} from './style';
import useDropdownApply from './useDropdownApply';

export type DropDownData = {
  label: string;
  value: string;
  group?: string;
  icon?: string;
  iconAsText?: boolean;
};

export type IDropdownApply = {
  data: DropDownData[];
  selected: DropDownData[];
  multipleSelectLabel?: string;
  onSelectChange: (selected: DropDownData[]) => void;
  onVisibleChange?: (value: boolean) => void;
  showSearch?: boolean;
  allLabel?: string;
  allIcon?: string | null;
  placeHolder?: string;
} & Omit<DropDownProps, 'overlay'>;

export type DropdownItem = { selectedItems: DropDownData[]; item: DropDownData };
export type DropdownItemGroup = { group: string; selectedItems: DropDownData[]; items: DropDownData[] };
export type DropdownMenu = { searchText: string; selectedItems: DropDownData[]; items: DropDownData[] };
export type DropdownSearch = {
  text: string;
  onClear: () => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};
export type DropdownSelectAllItem = {
  selectedItems: DropDownData[];
  items: DropDownData[];
  allLabel?: string;
  allIcon?: string | null;
};

const DropdownApplyMemo: React.FC<IDropdownApply> = ({
  data,
  selected,
  allLabel,
  allIcon,
  multipleSelectLabel,
  showSearch,
  placeHolder,
  onSelectChange,
  onVisibleChange,
  ...rest
}: IDropdownApply) => {
  const {
    visible,
    searchText,
    handleSelectItem,
    handleSelectAll,
    handleVisible,
    handleOnSearchTextChange,
    handleOnClearSearchText,
  } = useDropdownApply(data, selected, onSelectChange);
  const _renderItem = ({ selectedItems, item }: DropdownItem) => {
    const checked = selectedItems.some((si) => _.isEqual(si, item));
    return (
      <Item key={`dropdown-item-${item.label}`} data-testid="dropdown-item" onClick={() => handleSelectItem(item)}>
        <Row gutter={[16, 0]} justify={'space-between'}>
          <Col>
            <Row gutter={[8, 0]}>
              {item.icon && (
                <ImgCol>
                  {item.iconAsText ? (
                    <Avatar>{item.icon[0]}</Avatar>
                  ) : (
                    <IconContainer>
                      <img src={item.icon} />
                    </IconContainer>
                  )}
                </ImgCol>
              )}
              <Col>
                <DropdownOptionLabel ellipsis>{item.label}</DropdownOptionLabel>
              </Col>
            </Row>
          </Col>
          <Col>
            <Checkbox checked={checked} />
          </Col>
        </Row>
      </Item>
    );
  };

  const _renderItemGroup = ({ group, selectedItems, items }: DropdownItemGroup) => {
    const filteredItems = items.filter((item) => item.group && item.group === group);
    return (
      <>
        <ItemGroup key={`dropdown-group-${group}`} title={<ItemGroupTitle type="secondary">{group}</ItemGroupTitle>}>
          {filteredItems.map((item) => {
            return _renderItem({ selectedItems, item });
          })}
        </ItemGroup>
      </>
    );
  };

  const _renderDropdownSearch = ({ text, onChange, onClear }: DropdownSearch) => {
    return (
      <DropdownSearchContainer>
        <DropDownSearch value={text} onChange={onChange} onClear={onClear} />
      </DropdownSearchContainer>
    );
  };

  const _renderSelectAll = ({ selectedItems, items }: DropdownSelectAllItem) => {
    return (
      <Item onClick={() => handleSelectAll()}>
        <Row gutter={[16, 0]} justify={'space-between'}>
          <Col>
            <Row gutter={[8, 0]} align="middle">
              {allIcon !== null && (
                <Col>
                  <IconContainer>
                    <div style={{ height: '100%' }}>
                      {_.isUndefined(allIcon) ? (
                        <img src={All} />
                      ) : (
                        <img src={allIcon} style={{ objectPosition: 'center' }} />
                      )}
                    </div>
                  </IconContainer>
                </Col>
              )}
              <Col>
                <Typography.Text strong>{allLabel ?? 'All'}</Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col>
            <Checkbox checked={selectedItems.length === items.length} />
          </Col>
        </Row>
      </Item>
    );
  };

  const _renderMenu = ({ searchText, selectedItems, items }: DropdownMenu) => {
    const filteredItems = searchText
      ? items.filter((item) => {
          const includedInLabel = item.label.toLowerCase().includes(searchText.toLowerCase());
          const includedInValue = item.label.toLowerCase().includes(searchText.toLowerCase());
          return includedInLabel || includedInValue;
        })
      : items;
    const uniqueGroups = _.uniq(filteredItems.filter((item) => item.group).map((item) => item.group));
    const ungroupItems = filteredItems.filter((item) => !item.group);
    const groupedMenuItems =
      uniqueGroups.length > 0 &&
      uniqueGroups.map((group) => {
        return group && _renderItemGroup({ group, selectedItems, items: filteredItems });
      });
    const ungroupedMenuItems =
      ungroupItems.length > 0 &&
      ungroupItems.map((item) => {
        return _renderItem({ selectedItems, item });
      });
    return (
      <div>
        <Menu>
          {showSearch &&
            _renderDropdownSearch({
              text: searchText,
              onChange: handleOnSearchTextChange,
              onClear: handleOnClearSearchText,
            })}
          {_renderSelectAll({ selectedItems: selected, items: data })}
          {groupedMenuItems}
          {uniqueGroups.length > 0 && ungroupItems.length > 0 ? (
            <ItemGroup title={<ItemGroupTitle type="secondary">UNGROUP</ItemGroupTitle>}>
              {ungroupedMenuItems}
            </ItemGroup>
          ) : (
            ungroupedMenuItems
          )}
        </Menu>
      </div>
    );
  };

  const _renderDropdownLabel = (menuList: DropDownData[], selectedItem: DropDownData[]) => {
    if (selectedItem.length > 1 && selectedItem.length !== menuList.length) {
      return (
        <DropdownOptionLabel ellipsis>
          {selectedItem.length} {multipleSelectLabel ?? 'items selected'}
        </DropdownOptionLabel>
      );
    }
    if (selectedItem.length === 1) {
      return (
        <>
          {selectedItem[0] && selectedItem[0].icon && <img src={selectedItem[0].icon} />}
          <DropdownOptionLabel ellipsis>{selectedItem[0].label}</DropdownOptionLabel>
        </>
      );
    }
    if (selectedItem.length === menuList.length) {
      return (
        <>
          {_.isNull(allIcon) ? <></> : _.isUndefined(allIcon) ? <img src={All} /> : <img src={allIcon} />}
          <DropdownOptionLabel ellipsis>{allLabel ?? 'All'}</DropdownOptionLabel>
        </>
      );
    }
    return <PlaceHolder contentEditable={'true'} title={placeHolder} />;
  };

  return (
    <Container>
      <Dropdown
        data-testid="dropdown-wrap"
        {...rest}
        overlay={_renderMenu({ searchText, selectedItems: selected, items: data })}
        visible={visible}
        onVisibleChange={(value: boolean) => {
          if (onVisibleChange) {
            onVisibleChange(value);
          }
          handleVisible(value);
        }}
      >
        <Button data-testid="dropdown-apply-btn">
          <SelectedItem>{_renderDropdownLabel(data, selected)}</SelectedItem>
          <div>
            <FontAwesomeIcon icon={['fas', 'chevron-down']} />
          </div>
        </Button>
      </Dropdown>
    </Container>
  );
};

export const DropdownApply = React.memo(DropdownApplyMemo);
