import React from 'react';
import { TextMessage } from '..';
import { Container, FlexContent } from './style';
import 'flex-render/css';
import { render } from 'flex-render';
export type IFlexMessage = {
  altText: string;
  side: 'left' | 'right';
  contents: any;
};

export const FlexMessage: React.FC<IFlexMessage> = ({ altText, side, contents }: IFlexMessage) => {
  const result =
    render(contents) ??
    `<div class="${side === 'left' ? 'flexAltTextUser' : 'flexAltTextAgent'}">Empty Flex Message</div>`;
  return (
    <Container>
      <TextMessage side={side} type="first">
        {altText}
      </TextMessage>
      <FlexContent dangerouslySetInnerHTML={{ __html: result }} />
    </Container>
  );
};
