import React, { useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { DeleteModal } from '@components/Modal';
import * as H from 'history';

export type RouteLeavingGuardProps = {
  when: boolean;
  wording?: string;
  navigate: (path: string) => void;
  shouldBlockNavigation: (nextLocation: H.Location) => boolean;
};

export const RouteLeavingGuard = ({
  when,
  wording = 'Are you sure you want to leave this page? This page that you made will not be saved.',
  navigate,
  shouldBlockNavigation,
}: RouteLeavingGuardProps) => {
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState<H.Location>({} as H.Location);
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const showModal = (location: H.Location) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb?: () => void) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleBlockedNavigation = (nextLocation: H.Location, action: H.Action) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };
  const beforeUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (!when) return; // Only prevent leaving if `when` is true
      e.preventDefault();
      // For older browsers, setting returnValue is necessary
      e.returnValue = '';
    },
    [when],
  );

  useEffect(() => {
    // Add the event listener
    window.addEventListener('beforeunload', beforeUnload);

    // Specify how to clean up after this effect
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [beforeUnload]);

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <DeleteModal
        cancelBtnTxt="Stay"
        confirmBtnTxt="Leave page"
        visible={modalVisible}
        isLoading={false}
        centered
        handleClose={(_status) => closeModal()}
        handleConfirm={handleConfirmNavigationClick}
      >
        <div>{wording}</div>
      </DeleteModal>
    </>
  );
};
