import React from 'react';
import NOT_FOUND_IMG from '@assets/images/not_found_bot.svg';
import { Font14G3W400, Font20G1W600 } from '@components/UtilsComponent';
type IContentNotFoundProps = {
  label: string;
  description: string;
};

export const ContentNotFound = ({ label, description }: IContentNotFoundProps) => {
  return (
    <div className="flex flex-col gap-y-[8px] w-[256px] justify-center items-center text-center">
      <img src={NOT_FOUND_IMG} width={112} height={80} />
      <Font20G1W600>{label}</Font20G1W600>
      <Font14G3W400>{description}</Font14G3W400>
    </div>
  );
};
