import { ERole } from '@enums/Role';
import { intersection } from 'lodash';

type IPageAccessPermission = {
  [key: string]: string[];
};

const PAGE_ACCESS_PERMISSION: IPageAccessPermission = {
  settings: [ERole.SUPERADMIN, ERole.ADMIN],
  recents: [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR, ERole.AGENT],
  'team-management': [ERole.SUPERADMIN, ERole.ADMIN],
  'ticket-management': [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR, ERole.AGENT],
  report: [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR, ERole.AGENT],
  'operational-dashboard': [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR],
};

export const rolesHasAccessTo = (roles: string[], pageName: string): boolean => {
  return intersection(roles, PAGE_ACCESS_PERMISSION[pageName]).length > 0;
};
