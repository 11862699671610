import React from 'react';
import { Container } from './style';

export type ITextPreviewProps = {
  text: string;
};

export const TextPreview = ({ text }: ITextPreviewProps) => {
  return <Container>{text}</Container>;
};
