import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderBox = styled.div``;

export const ActionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterGroup = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const AddNewGroup = styled.div`
  display: flex;
`;

export const SearchWrap = styled.div`
  input {
    width: 300px;
  }
`;

export const RoleFilter = styled.div`
  .ant-select {
    width: 200px;
  }
`;

export const ActionBox = styled.div`
  display: flex;
  column-gap: 8px;
  svg {
    margin-right: 8px;
  }
`;

export const ContentBox = styled.div`
  height: 100%;
  margin-top: 36px;
`;

export const LoadingWrap = styled.div`
  position: initial !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
