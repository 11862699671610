import styled from 'styled-components/macro';

export const DropdownItem = styled.li`
  cursor: pointer;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    flex: 1;
    height: 100%;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
