import { useState } from 'react';
import { resolveTicket } from '@api/livechat/EngagementMessage';
import { useHistory } from 'react-router-dom';
import { selectedTicketState, selectedUserState } from '@store/atom';
import { useRecoilValue } from 'recoil';
import { useMember } from '@providers/MemberProvider';

import axios, { AxiosError } from 'axios';
import { canResolveTicket } from '@utils/canResolveTicket';

const useTicketConsole = () => {
  const { member } = useMember();
  const history = useHistory();
  const [isResolving, setIsResolving] = useState(false);
  const selectedUser = useRecoilValue(selectedUserState);
  const selectedTicket = useRecoilValue(selectedTicketState);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleSubmit = async () => {
    setErrorMessage('');
    if (selectedTicket && selectedUser) {
      try {
        setIsResolving(true);
        await resolveTicket(selectedTicket._id, '');
        handleShowModal(false);
        setIsResolving(false);
        history.push(`/recents/`);
        history.push(`/recents/${selectedTicket.userId}`);
      } catch (error) {
        console.error(error);
        const err = error as Error | AxiosError;
        // hide form
        handleShowModal(false);
        setIsResolving(false);
        if (axios.isAxiosError(err)) {
          // Access to config, request, and response
          setErrorMessage(err?.response?.data?.message ? err?.response?.data?.message : '');
        }
      }
    }
  };

  const onHideErrorModal = () => {
    setErrorMessage('');
  };

  const handleShowModal = (value: boolean) => {
    setShowModal(value);
  };

  const handleAssginSuccess = () => {
    history.replace('/recents');
  };

  return {
    showModal,
    selectedTicket,
    errorMessage,
    isResolving,
    isDisalbedResolve: !canResolveTicket(selectedTicket!, member),
    onHideErrorModal,
    handleShowModal,
    handleSubmit,
    handleAssginSuccess,
  };
};

export default useTicketConsole;
