import styled from 'styled-components/macro';

export const MessageBoxContainer = styled.div`
  flex: 1;
  padding: 0 0 0 24px;
  overflow: hidden;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.GREY_7};
  height: 100vh;
`;
