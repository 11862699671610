import { getSuggestionMessage } from '@api/va';
import { CombineAllMessage } from '@model/MessageItem';
import { isEqual, isUndefined } from 'lodash';
import { useEffect, useRef, useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuid } from 'uuid';

type IVaChunkJson = {
  __status: 'active' | 'finished';
  data: {
    content?: string;
    answer?: string;
  };
};

export const useVaSuggestion = (
  lastMessage: CombineAllMessage,
  ticketId: string,
  onStreaming: () => void,
  onStreamFinish: () => void,
) => {
  const prevMessage = useRef<CombineAllMessage>(lastMessage);
  const [vaMessage, setVaMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const currentRequestId = useRef('');
  const isMountedRef = useRef(false);
  const callApi = useCallback(async () => {
    currentRequestId.current = uuid();
    try {
      const response = await getSuggestionMessage(ticketId, currentRequestId.current);
      // check header request id with current request id

      if (response.headers['x-request-id'] !== currentRequestId.current) {
        return;
      }
      // clear
      setVaMessage('');
      setErrorMessage('');
      const stream = response.data;
      for await (const chunk of stream) {
        let incompleteChunkIndex: number | undefined;
        let incompleteChunk: Buffer | undefined;
        const decoder = new TextDecoder('utf-8');
        const jsonString = decoder.decode(chunk);
        const lines = jsonString
          .toString()
          .split('\n')
          .filter((line: string) => line.trim() !== '');
        for (const [index, line] of lines.entries()) {
          let clonedLine = line;
          if (incompleteChunk && !isUndefined(incompleteChunkIndex)) {
            const combined = Buffer.concat([incompleteChunk, chunk]);
            const lns = combined
              .toString()
              .split('\n')
              .filter((ln) => ln.trim() !== '');

            clonedLine = lns[incompleteChunkIndex];

            incompleteChunk = undefined;
            incompleteChunkIndex = undefined;
          }
          const message = clonedLine.replace(/^data: /, '');
          try {
            const parsed: IVaChunkJson = JSON.parse(message);
            if (parsed.__status === 'active') {
              onStreaming();
              setVaMessage((prev) => prev + parsed.data.content);
            }
            if (parsed.__status === 'finished') {
              onStreamFinish();
              setVaMessage((prev) => parsed.data.answer || prev);
            }
          } catch (error) {
            incompleteChunk = chunk;
            incompleteChunkIndex = index;
            console.warn('incomplete chunk', error);
          }
        }
      }
    } catch (error) {
      console.error('Error get streaming data', error);
      setVaMessage('');
      setErrorMessage('The co-pilot is disabled in this team. Please contact admin to enabled the feature');
    }
  }, []);

  const debouncedCallApi = useDebouncedCallback(callApi, 5000);

  useEffect(() => {
    if (!lastMessage) return;

    if (!isMountedRef.current) {
      isMountedRef.current = true;
      if (lastMessage.getSender === 'user') {
        callApi();
        prevMessage.current = lastMessage;
      }
      return;
    }

    if (lastMessage.getSender === 'user') {
      if (!isEqual(lastMessage, prevMessage.current)) {
        debouncedCallApi();
        prevMessage.current = lastMessage;
      }
    }
  }, [lastMessage, callApi, debouncedCallApi]);

  return {
    vaMessage,
    errorMessage,
  };
};
