import { sendMessage } from '@api/livechat/EngagementMessage';
import { AnyEventObject } from 'xstate';
import { IMessageBoxMachineContext } from '../messageBoxMachine';

export const resendAgentMessage = async (_: IMessageBoxMachineContext, event: AnyEventObject) => {
  const { memberId, messagePayload } = event;
  try {
    await sendMessage(memberId, messagePayload);
    return;
  } catch (error) {
    throw {
      error,
    };
  }
};
