import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
`;

export const UserProfileContainer = styled.div`
  padding: 32px 12px 0px;
`;
export const HistoryContainer = styled.div`
  padding: 0 12px;
  margin-top: 16px;
  height: 100%;
`;
