import React from 'react';
import useWindowSize from '@hooks/useWindowSize';
import styled from 'styled-components/macro';

const Container = styled.div<{ height: number }>`
  width: 100vw;
  height: ${({ height }) => height}px;
  display: flex;
  overflow: hidden;
`;

const MainLayout: React.FC = ({ children }) => {
  const { height } = useWindowSize();
  if (height) {
    return <Container height={height}>{children}</Container>;
  }
  return null;
};

export default MainLayout;
