import { removeSpecialWhenPasted } from '@utils/letters';
import { useState } from 'react';

export const useInput = (regex?: RegExp) => {
  const [value, setValue] = useState('');
  return {
    value,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      // if regex is provided replace anything that does not match with empty string
      if (regex) {
        const { value } = e.target;
        const newRegex = new RegExp(regex);
        const replaced = removeSpecialWhenPasted(value, newRegex);
        setValue(replaced);
        return;
      }

      setValue(e.target.value);
    },
    onClear: () => setValue(''),
  };
};
