import { cimClient, client } from '@api';
import { getMemberByTokenApi } from '@api/auth';

export const getMemberByToken = async () => {
  const { data } = await getMemberByTokenApi();
  return {
    _id: data._id,
    username: data.username,
    teamId: data.teams.map(({ _id }: { _id: string }) => _id),
    status: data.status,
    roles: data.roles,
    isNotification: false,
    nid: data.nid,
    email: data.email,
    password: data.password,
    teams: data.teams,
    tickets: data.tickets,
  };
};

export const setHeader = (key: string, value: string) => {
  client.defaults.headers.common[key] = value;
  cimClient.defaults.headers.common[key] = value;
};
