import { AnyEventObject, assign, createMachine } from 'xstate';
import { ITicket, IUser } from '@types';
import { getTicketById, openTicket, reOpenTicket } from '@api/livechat/Ticket';
import { updateFollowUp } from '@api/livechat/EngagementMessage';
import { getUserById } from '@api/livechat/User';
import { GLOBAL_MESSAGE } from '@configs/constants';
import { getSummaryFromPayload } from '@utils/ticketWrapUp';

export type ITicketHistoryMachineContext = {
  currentTicket: ITicket;
  user: IUser;
  errorMessage: string;
};

const ticketHistoryMachine = (ticketId: string) =>
  createMachine<ITicketHistoryMachineContext, AnyEventObject>(
    {
      id: 'ticketHistory',
      initial: 'fetchingCurrentTicket',
      context: {
        currentTicket: {} as ITicket,
        user: {} as IUser,
        errorMessage: '',
      },
      states: {
        idle: {},
        fetchingCurrentTicket: {
          invoke: {
            id: 'fetching-current-ticket',
            src: async () => {
              const ticket = await getTicketById(ticketId);
              const user = await getUserById(ticket.data.userId);
              return { ticket, user };
            },
            onDone: {
              target: 'idle',
              actions: 'updateCurrentTicketAndUser',
            },
            onError: 'fetchingCurrentTicketFailed',
          },
        },
        fetchingCurrentTicketFailed: {
          type: 'final',
        },
        following: {
          invoke: {
            id: 'following',
            src: (context, event) => {
              const { flag } = event;
              return updateFollowUp(ticketId, { flag });
            },
            onDone: {
              target: 'idle',
              actions: 'updateCurrentTicket',
            },
            onError: {
              target: 'idle',
              actions: 'updateCurrentTicket',
            },
          },
        },
        askOpenNewTicket: {
          on: {
            CONFIRM: 'openningTicket',
            CANCEL: 'idle',
          },
        },
        openningTicket: {
          invoke: {
            id: 'openning-ticket',
            src: (context) => {
              const { currentTicket } = context;
              return openTicket(currentTicket.userId);
            },
            onDone: {
              target: 'openTicketSuccess',
            },
            onError: {
              target: 'errorOpenTicket',
              actions: 'updateErrorMessage',
            },
          },
        },
        askReopenTicket: {
          on: {
            CONFIRM: 'reOpenningTicket',
            CANCEL: 'idle',
          },
        },
        reOpenningTicket: {
          invoke: {
            id: 're-openning-ticket',
            src: (context) => {
              const { currentTicket } = context;
              return reOpenTicket(currentTicket._id);
            },
            onDone: {
              target: 'reOpenTicketSuccess',
            },
            onError: {
              target: 'errorReOpenTicket',
              actions: 'updateErrorMessage',
            },
          },
        },
        assigningToSelf: {
          invoke: {
            id: 're-openning-ticket',
            src: async (context) => {
              const { currentTicket } = context;
              return await reOpenTicket(currentTicket._id);
            },
            onDone: {
              target: 'reOpenTicketSuccess',
            },
            onError: {
              target: 'errorReOpenTicket',
              actions: 'updateErrorMessage',
            },
          },
        },
        openTicketSuccess: {
          type: 'final',
        },
        reOpenTicketSuccess: {},
        errorOpenTicket: {
          on: {
            IDLE: 'idle',
          },
        },
        errorReOpenTicket: {
          on: {
            IDLE: 'idle',
          },
        },
        fetchingUser: {
          invoke: {
            id: 'fetching-user',
            src: async (context) => {
              const { currentTicket } = context;
              return await getUserById(currentTicket.userId);
            },
            onDone: {
              target: 'idle',
              actions: 'updateUser',
            },
            onError: {
              target: 'idle',
              actions: 'updateUser',
            },
          },
        },
      },
      on: {
        FETCH_USER: {
          target: 'fetchingUser',
        },
        FETCH_CURRENT_TICKET: {
          target: 'fetchingCurrentTicket',
        },
        FOLLOW_UP: {
          target: 'following',
        },
        OPEN_TICKET: {
          target: 'askOpenNewTicket',
        },
        RE_OPEN_TICKET: {
          target: 'askReopenTicket',
        },
        UPDATE_TICKET_SUMMARY: {
          actions: 'updateTicketSummary',
        },
      },
    },
    {
      actions: {
        updateUser: assign({
          user: (_context, event) => {
            const { data } = event;
            return data?.data ?? null;
          },
        }),
        updateCurrentTicket: assign({
          currentTicket: (_context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        updateCurrentTicketAndUser: assign({
          currentTicket: (_context, event) => {
            const { ticket } = event.data;
            return ticket.data;
          },
          user: (_context, event) => {
            const { user } = event.data;
            return user.data;
          },
        }),
        updateTicketSummary: assign({
          currentTicket: (context, event) => {
            const { payload } = event;
            return {
              ...context.currentTicket,
              summary: getSummaryFromPayload(payload),
            };
          },
        }),
        updateErrorMessage: assign({
          errorMessage: (_, event) => {
            if (event.data?.response?.data?.message && typeof event.data?.response?.data?.message === 'object') {
              return GLOBAL_MESSAGE.ERROR_TITLE;
            }
            return event.data?.response?.data?.message ?? GLOBAL_MESSAGE.ERROR_TITLE;
          },
        }),
      },
    },
  );

export default ticketHistoryMachine;
