import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  selectedUserState,
  appState,
  selectedTicketState,
  userListState,
  filterItemState,
  currentFilterState,
} from '@store/atom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { getRecentTicketByUserId } from '@api/livechat/EngagementMessage';
import { getUserById } from '@api/livechat/User';
import { getQueryString } from '@utils/getQueryString';

const useChatWrap = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation<{ lastClickTime: number }>();
  const filterItems = useRecoilValue(filterItemState);
  const currentAppState = useRecoilValue(appState);
  const currentFilter = useRecoilValue(currentFilterState);
  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserState);
  const [selectedTicket, setSelectedTicket] = useRecoilState(selectedTicketState);
  const userList = useRecoilValue(userListState);
  const messageTime = getQueryString(history.location.search, 'messageTime');
  const ticketData = useQuery(
    ['getRecentTicketByUserId', id, messageTime, location.state?.lastClickTime],
    () => getRecentTicketByUserId(id),
    {
      retry: 0,
      onSuccess: (data) => (data ? setSelectedTicket(data.data) : setSelectedTicket(null)),
    },
  );

  const currentUserData = useQuery(
    ['getUserById', id, messageTime, location.state?.lastClickTime],
    () => getUserById(id),
    {
      retry: 0,
      cacheTime: 0,
      onSuccess: (data) => {
        data
          ? setSelectedUser({
              ...data.data,
              id: data.data._id,
              userSocialId: data.data.referenceId,
              channelId: data.data.channelId._id,
              channelType: data.data.channelId.type,
            })
          : setSelectedUser(null);
      },
    },
  );

  return {
    ticketData,
    currentUserData,
    selectedTicket,
    filterItems,
    currentFilter,
    selectedUser,
    currentAppState: currentAppState,
    userId: id,
    userList,
  };
};

export default useChatWrap;
