import styled from 'styled-components/macro';

export const Inner = styled.div<{ type: 'error' | 'general' | 'success'; size: 'large' | 'medium' }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  span {
    font-weight: 600;
    font-size: ${({ size }) => getSize(size)};
    line-height: 30px;
    margin: 20px 0 0 0;
  }
  div {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin: 4px 0 0 0;
  }

  /* title */
  span {
    color: ${({ theme, type }) => getColor(type, theme)};
    text-align: center;
  }

  /* description */
  div {
    color: ${({ theme, type }) => getColor(type, theme)};
    text-align: center;
    white-space: pre-wrap;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const getColor = (type: 'error' | 'general' | 'success', theme: any) => {
  if (type === 'error' || type === 'general') {
    return theme.GREY_5;
  }
  if (type === 'success') {
    return theme.GREY_1;
  }
  return theme.GREY_5;
};

const getSize = (size: 'large' | 'medium') => {
  if (size === 'large') {
    return '20px';
  }
  return '16px';
};

export const ImageItem = styled.img`
  width: fit-content;
`;
