import axios, { AxiosError } from 'axios';

export type ErrorMapping = {
  [key: string]: {
    title: string;
    description: string;
  };
};

export const errorGenerator = (err: Error | AxiosError, mapping: ErrorMapping) => {
  if (axios.isAxiosError(err)) {
    const errorMessage = err.response?.data?.message ? (err.response?.data?.message as string) : '';
    if (errorMessage && mapping[errorMessage]) {
      return {
        title: mapping[err.response?.data?.message].title,
        description: mapping[err.response?.data?.message].description,
      };
    }
    return true;
  }
  return false;
};
