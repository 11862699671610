import { IFileHandler } from '@types';
import { useMessageBox } from '.';

// must use inside MessageBoxProvider
export const useFile = (onConfirmUpload: (handleUploadFile: () => void) => void) => {
  const {
    current: messageCurrent,
    messageContext,
    uploadRef,
    beforeUpload,
    handleCancelUpload,
    handleUploadFile,
  } = useMessageBox();
  const fileHandler: IFileHandler = {
    isFileProcessing: messageCurrent.hasTag('file-processing'),
    isFileUploading:
      messageCurrent.matches({ chatHistoryState: 'uploadingFiles' }) ||
      messageCurrent.matches({ chatHistoryState: 'uploadFilesSuccess' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendingFilesMessage' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendFilesMessageWithTime' }),

    isFileUploadFailed:
      messageCurrent.matches({ chatHistoryState: 'uploadFilesFailed' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendFilesMessageFailed' }),
    selectedFiles: messageContext.selectedFiles,
    rejectedFiles: messageContext.rejectFiles,
    errorMessage: messageContext.fileErrorMessage,
    uploadRef: uploadRef,
    onConfirmUpload: () => {
      onConfirmUpload(handleUploadFile);
    },
    onCancelUpload: handleCancelUpload,
    beforeUpload,
  };
  return { fileHandler };
};
