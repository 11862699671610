/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { client } from '@api';
import { MEMBER_LIST_PAGE_SIZE } from '@configs/constants';
import { IFetchFnResponse } from '@hooks/useInfinityLoadByPage';
import { IAssignee, IMember } from '@types';
import { qsTransform } from '@utils/qsTransform';

type IMemberPayload = {
  username: string;
  roles: string[];
  nid: string;
  email: string;
  phone?: string;
  displayName: string;
  password: string;
};

const includeAssignableRoles = qsTransform('roles', ['agent', 'supervisor', 'bot']);
const sortByRoles = qsTransform('sortFields', ['roles']);
const sortAvaliableMembers = qsTransform('sortFields', ['-teams', 'roles', 'displayName']);
const sortDisplayName = qsTransform('sortFields', ['displayName']);
export const updateMemberInfo = (id: string, payload: Pick<IMemberPayload, 'email' | 'phone' | 'roles'>) => {
  return client.put(`/api/agents/${id}`, payload);
};

export const saveMember = (payload: IMemberPayload) => {
  return client.post('/api/agents', payload);
};

export const getMemberBySearch = (searchTxt: string, roles: string, page = 1, pageSize = MEMBER_LIST_PAGE_SIZE) => {
  const newRoles = qsTransform('roles', roles.split(','));
  return client.get(
    `/api/agents/search?keyword=${searchTxt}&${newRoles}&page=${page}&pageSize=${pageSize}&${sortByRoles}`,
  );
};

export const getMembers = (roles: string, page = 1, pageSize = MEMBER_LIST_PAGE_SIZE) => {
  const newRoles = qsTransform('roles', roles.split(','));
  return client.get(`/api/agents?${newRoles}&page=${page}&pageSize=${pageSize}&${sortByRoles}`);
};

export const getAvailableMembers = (page = 1, pageSize = MEMBER_LIST_PAGE_SIZE, teamIds?: string[]) => {
  const newRoles = includeAssignableRoles;
  if (teamIds) {
    const newTeams = qsTransform('teams', teamIds);
    return client.get(
      `/api/agents/list?${newRoles}&page=${page}&pageSize=${pageSize}&${sortAvaliableMembers}&${newTeams}`, // sort by agents have team first and username
    );
  }
  return client.get(
    `/api/agents/list?${newRoles}&page=${page}&pageSize=${pageSize}&${sortAvaliableMembers}`, // sort by agents have team first and username
  );
};

export const getAgentInTeam = (page = 1, pageSize = MEMBER_LIST_PAGE_SIZE, teamIds: string[], searchTxt?: string) => {
  const newRoles = includeAssignableRoles;

  const newTeams = qsTransform('teams', teamIds);
  if (searchTxt) {
    return client.get<IFetchFnResponse<IAssignee>>(
      `/api/agents/search?keyword=${searchTxt}&${newRoles}&page=${page}&pageSize=${pageSize}&${sortByRoles}&${newTeams}`, // sort by agents have team first and username
    );
  }
  return client.get<IFetchFnResponse<IAssignee>>(
    `/api/agents/list?agentNoTeam=false&${newRoles}&page=${page}&pageSize=${pageSize}&${newTeams}&${sortDisplayName}`, // sort by agents have team first and username
  );
};

export const getAvailableMembersBySearch = (
  searchTxt: string,
  page = 1,
  pageSize = MEMBER_LIST_PAGE_SIZE,
  teamIds?: string[],
) => {
  const newRoles = includeAssignableRoles;
  if (teamIds) {
    const newTeams = qsTransform('teams', teamIds);
    return client.get(
      `/api/agents/search?keyword=${searchTxt}&${newRoles}&page=${page}&pageSize=${pageSize}&${sortByRoles}&${newTeams}`,
    );
  }
  return client.get(
    `/api/agents/search?keyword=${searchTxt}&${newRoles}&page=${page}&pageSize=${pageSize}&${sortByRoles}`,
  );
};

export const deleteMember = (id: string) => {
  return client.delete(`/api/agents/${id}`);
};

export const getMemberById = (id: string) => {
  return client.get(`/api/agents/${id}`);
};

export const resetPassword = (id: string, password: string) => {
  return client.put(`/api/agents/${id}/password`, { password });
};
