import React from 'react';
import LoadingCircle from '@components/Loading';
import { LoadingWrap } from '@components/Loading/style';
import { ConfigProvider, Spin } from 'antd';
import SearchInput from '@components/SearchInput';
import { Button, GhostButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faPlus, faTimes, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import useTranslation from '@hooks/useTranslation';
import TableList from '@components/TableList';
import Table, { IColumns } from '@components/Table';
import { Font16G1W600, Font20G1W600, Spacing } from '@components/UtilsComponent';
import { fetchHandler, useTable } from '@hooks/useTable';
import { tableListGenerator } from '@utils/tableListGenerator';
import { IReasonBE } from '@types';
import { useDelete } from '@hooks/useDelete';
import { ConfirmModal, DeleteModal } from '@components/Modal';
import { useCreateModal } from '@hooks/useCreateModal';
import TextArea from '@components/TextArea';
import { REASON_ERROR_MAPPING, REASON_MAX_LENGTH, REASON_NAME_MIN_LENGTH } from '@configs/wrapup';
import { useRowEdit } from '@hooks/useRowEdit';
import { createReason, deleteReason, fetchReasons, updateReason } from '@api/livechat/Wrapup';
import { Input } from 'antd';
import { useAlert } from 'react-alert';
import { useQueryParams } from '@hooks/useQueryParams';
import { useAlertModal } from '@providers/AlertModalProvider';
import { AxiosError } from 'axios';

export const ReasonList = () => {
  const t = useTranslation();
  const alert = useAlert();
  const generateColumns = (): IColumns[] => [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 56,
      render: (_, record: IReasonBE, index: number) => {
        if (rowEdit.selected.index === index) {
          return (
            <div className="flex items-center gap-x-2">
              <button
                className="disabled:cursor-not-allowed disabled:opacity-50"
                disabled={
                  rowEdit.selected.value?.name
                    ? rowEdit.selected.value.name.trim().length < REASON_NAME_MIN_LENGTH
                    : true
                }
              >
                <FontAwesomeIcon
                  onClick={() => {
                    if (!rowEdit.selected.value) return;
                    rowEdit.onConfirmEdit(async () => {
                      await query.refetch();
                    });
                  }}
                  icon={faCheck}
                  className={`text-[#29CB72] text-[16px]`}
                />
              </button>
              <FontAwesomeIcon
                onClick={rowEdit.onCancelEdit}
                icon={faTimes}
                className="text-[#F72C40] text-[16px] cursor-pointer"
              />
            </div>
          );
        }
        return (
          <div>
            <FontAwesomeIcon
              onClick={() =>
                rowEdit.onSelectEdit({
                  index,
                  value: record,
                })
              }
              icon={faEdit}
              className="text-[16px] text-[#BDBDBD] cursor-pointer"
            />
          </div>
        );
      },
    },
    {
      title: 'REASON',
      dataIndex: '',
      key: '',
      render: (_, record, index) => {
        return (
          <>
            <Input.TextArea
              style={{
                display: rowEdit.selected.index === index ? 'block' : 'none',
                border: 'none',
                borderBottom: '1px solid #E5E5E5',
                outline: 'none',
              }}
              className="bg-transparent !shadow-none pl-0"
              maxLength={REASON_MAX_LENGTH}
              autoSize={{ minRows: 1, maxRows: 5 }}
              onChange={(e) =>
                rowEdit.onUpdateSelected((setter) => {
                  setter((prevState) => {
                    if (!prevState.value) {
                      return prevState;
                    }
                    return {
                      ...prevState,
                      value: {
                        ...prevState.value,
                        name: e.target.value,
                      },
                    };
                  });
                })
              }
              value={rowEdit.selected.value?.name}
            />
            <div
              style={{
                display: rowEdit.selected.index !== index ? 'block' : 'none',
              }}
              className="border-b-[1px] border-transparent"
            >
              {record.name}
            </div>
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 80,
      render: (text: string, record: IReasonBE) => (
        <div>
          <GhostButton
            onClick={() => {
              deleteHandler.onSelectForDelete(record);
              deleteHandler.confirmModal.onToggle();
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-[16px] text-[#757575] mt-[2px]" />
          </GhostButton>
        </div>
      ),
    },
  ];
  const deleteHandler = useDelete<IReasonBE, boolean>({
    deleteFn: async (payload) => {
      await deleteReason(payload.id);
      return true;
    },
  });

  const queryParams = useQueryParams();
  const currentTab = queryParams.get('tab');
  const isDefaultOpen = queryParams.get('open');
  const alertModal = useAlertModal();
  const { query, keyword, isDisabledPrev, onUpdateKeyword, onNext, onPrev, onUpdateCursorId, onUpdateCurrentPage } =
    useTable({
      fetchFn: async (keyword, cursorId, currentPageEvent, currentPage, cancelToken) => {
        const result = await tableListGenerator({
          ...(await fetchHandler(
            keyword,
            cursorId,
            currentPageEvent,
            currentPage,
            async ({ keyword, cursorId, take }) => {
              const data = await fetchReasons(take, keyword || undefined, cursorId || undefined, cancelToken);
              return data;
            },
          )),
        });
        onUpdateCursorId(result?.nextCursorId ?? '', result?.prevCursorId ?? '');
        onUpdateCurrentPage(result?.currentPage ?? 1);
        return result;
      },
      debounceTime: 500,
      enable: currentTab === 'reason',
      watchParams: currentTab === 'reason' ? [currentTab] : undefined,
    });

  const { showConfirmModal, isSaving, txt, onCancel, onToggle, onConfirm, onUpdateText } = useCreateModal(
    createReason,
    !!isDefaultOpen,
  );
  const rowEdit = useRowEdit<IReasonBE>({
    updateFn: async (payload) => {
      if (!payload.id) return;
      try {
        await updateReason(payload.id, payload.name);
      } catch (error) {
        throw error;
      }
    },
    errorMapping: REASON_ERROR_MAPPING,
    successTxt: t('ticket.wrapup.reason.list.update.reason.success'),
  });

  const onConfirmCreate = async () => {
    try {
      await onConfirm(txt);
      onUpdateText('');
      alert.success(t('ticket.wrapup.reason.list.create.new.reason.success'));
      await query.refetch();
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      const errorMessage = err.response?.data?.message ? (err.response?.data?.message as string) : '';
      if (errorMessage && REASON_ERROR_MAPPING[errorMessage]) {
        alertModal.onUpdateDetail(
          REASON_ERROR_MAPPING[err.response?.data?.message].title,
          REASON_ERROR_MAPPING[err.response?.data?.message].description,
        );
      }
      alertModal.onOpen();
    }
  };
  return (
    <Spin
      spinning={rowEdit.updateMute.isLoading || query.isFetching}
      indicator={
        <LoadingWrap data-testid="test-loading-wrap">
          <LoadingCircle />
        </LoadingWrap>
      }
    >
      <div className="mt-6">
        <div className="flex justify-between items-center">
          <SearchInput
            className="w-[328px]"
            value={keyword}
            onClear={() => {
              onUpdateKeyword('');
            }}
            onChange={(e) => {
              onUpdateKeyword(e.target.value);
            }}
          />
          <Button icon={<FontAwesomeIcon icon={faPlus} className="mr-2" />} onClick={onToggle}>
            {t('ticket.wrapup.reason.list.new.reason.button')}
          </Button>
        </div>
      </div>
      <ConfigProvider renderEmpty={() => <div>{t('ticket.wrapup.reason.list.no.reason')}</div>}>
        <TableList
          next={{
            onClick: onNext,
            disabled: query.data?.disabledNext || false,
          }}
          prev={{
            onClick: onPrev,
            disabled: isDisabledPrev,
          }}
        >
          <Table
            header={
              <div className="my-[16px]">
                <Font16G1W600>{t('ticket.wrapup.reason.list.title')}</Font16G1W600>
              </div>
            }
            data={query.data?.items ?? []}
            columns={generateColumns()}
            pagination={false}
            scroll={{ y: 'calc(100vh - 570px)' }}
          />
        </TableList>
      </ConfigProvider>
      <ConfirmModal
        visible={showConfirmModal}
        confirmBtnTxt={t('confirm.btn')}
        cancelBtnTxt={t('cancel.btn')}
        bodyWidth={'100%'}
        handleClose={onCancel}
        handleConfirm={onConfirmCreate}
        isLoading={isSaving}
        isDisabledConfirmBtn={txt.trim().length < REASON_NAME_MIN_LENGTH}
      >
        <Font20G1W600>{t('ticket.wrapup.reason.list.new.reason.modal.title')}</Font20G1W600>
        <Spacing height={24} />
        <TextArea
          value={txt}
          onChange={(e) => onUpdateText(e.target.value)}
          showCount
          maxLength={REASON_MAX_LENGTH}
          className="w-full"
          placeholder={t('ticket.wrapup.reason.list.new.reason.modal.text.area.placeholder')}
        />
        <Spacing height={32} />
      </ConfirmModal>
      <DeleteModal
        visible={deleteHandler.confirmModal.visible}
        isLoading={deleteHandler.deleteMut.isLoading}
        handleConfirm={() =>
          deleteHandler.confirmModal.onConfirm(async () => {
            try {
              if (!deleteHandler.selectForDelete) return;
              await deleteHandler.onDelete(deleteHandler.selectForDelete);
              deleteHandler.confirmModal.onToggle();
              deleteHandler.onSelectForDelete(null);
              rowEdit.onSelectEdit({
                index: null,
                value: null,
              });
              await query.refetch();
            } catch (error) {
              console.error(error);
              deleteHandler.confirmModal.onToggle();
            }
          })
        }
        handleClose={() => {
          if (deleteHandler.deleteMut.isLoading) return;
          deleteHandler.onSelectForDelete(null);
          deleteHandler.confirmModal.onToggle();
        }}
        cancelBtnTxt={t('cancel.btn')}
        confirmBtnTxt={t('confirm.btn')}
      >
        <span>
          {t('ticket.wrapup.reason.list.delete.reason.success', {
            name: <Font16G1W600>{deleteHandler.selectForDelete?.name || '-'}</Font16G1W600>,
          })}
        </span>
      </DeleteModal>
    </Spin>
  );
};
