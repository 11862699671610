import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.GREY_9};
  border-radius: 8px;
  overflow: auto;
`;

export const TopSection = styled.div`
  border-radius: 8px 8px 0 0;
  height: 36px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid ${({ theme }) => theme.GREY_9};
`;

export const Inner = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 36px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const FlexWrap = styled.div`
  width: 290px;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

export const QuickReplyWrap = styled.div`
  max-width: 290px;
  padding-bottom: 12px;
  overflow: auto;
  display: flex;
  column-gap: 8px;
  div {
    height: fit-content;
    padding: 7px 10px;
    color: white;
    border-radius: 17px;
    background: rgba(56, 66, 92, 0.4);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
  }
`;
