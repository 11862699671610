import styled from 'styled-components/macro';
import { Tabs as AntdTabs } from 'antd';
import _ from 'lodash';

export const Container = styled.div`
  height: 100%;
`;

export const TabContainer = styled(AntdTabs)`
  height: 100%;
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    justify-content: center;
    min-width: ${(props) => {
      const minWidth = props.tabBarStyle?.minWidth;
      return (_.isNumber(minWidth) ? `${minWidth}px` : minWidth) || '108px';
    }};
  }
  .ant-tabs-tab-btn {
    text-align: center;
    color: ${({ theme }) => theme.GREY_5};
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.GREY_1} !important;
  }
  .ant-tabs-ink-bar {
    border: 1px solid ${({ theme }) => theme.PRIMARY};
  }
  .ant-tabs-content {
    height: 100%;
  }
`;
