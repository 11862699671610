import { getTicket, getUserListBySearch, getUserMessageListBySearch } from '@api/livechat/EngagementUserList';
import { DEBOUNCE_TIME, MAX_USER_PER_PAGE } from '@configs/constants';
import { useMember } from '@providers/MemberProvider';
import SocketIO from '@socket';
import {
  appState,
  currentFilterState,
  EAppState,
  excludeTeamIdsState,
  filterItemState,
  searchUserListState,
  searchUserMessagesState,
  selectedTicketState,
  selectedUserState,
  userListState,
} from '@store/atom';
import { ILastMessageSocket, ISocketTicketUpdate, ITicket, IUser, ITicketFilter, UserDataItem } from '@types';
import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import produce from 'immer';
import { keyBy, merge, orderBy, values } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useDebounce } from 'use-lodash-debounce';
import { IFilterPayload } from './TicketFilter/useTicketFilter';
import { EAgentStatus } from '@enums/AgentStatus';
import { ETicketStatus } from '@enums/TicketStatus';
import { ETicketFilterStatus } from '@enums/TicketFilter';
import { ESocketEventTopics } from '@enums/Socket';
import UserList from '@model/UserList';
import { ISocketEvent } from '@types';
import { privateAction } from '@utils/privateAction';
import { ESenderType } from '@enums/SenderType';

export const useEngagementUser = () => {
  const socket = useRef<SocketIO | null>(null);
  const [searchTxt, setSearchTxt] = useState('');
  const { member, isAdmin } = useMember();
  const excludeTeamIds = useRecoilValue(excludeTeamIdsState);
  const debouncedSearchTxt = useDebounce(searchTxt, DEBOUNCE_TIME);
  const [currentFilter, setCurrentFilter] = useRecoilState(currentFilterState);
  const setCurrentAppState = useSetRecoilState(appState);
  const [filterItems, setFilterItems] = useRecoilState(filterItemState);
  const [userList, setUserList] = useRecoilState(userListState);
  const [searchUserList, setSearchUserList] = useRecoilState(searchUserListState);
  const [searchUserMessagesList, setUserMessagesState] = useRecoilState(searchUserMessagesState);
  const setSelectedUser = useSetRecoilState(selectedUserState);
  const setSelectedTicket = useSetRecoilState(selectedTicketState);
  const cancelToken = useRef<CancelTokenSource>();
  const canFilterByTeam =
    isAdmin() &&
    (currentFilter.ticketStatus === ETicketFilterStatus.ASSIGNED_TO_TEAM ||
      currentFilter.ticketStatus === ETicketFilterStatus.TEAM_FOLLOW_UP ||
      currentFilter.ticketStatus === ETicketFilterStatus.UNASSIGNED);

  useEffect(() => {
    //avoid loading when change status
    if (member.status === EAgentStatus.UNAVAILABLE) {
      setCurrentFilter((prev) => {
        return {
          ...prev,
          hasNextPage: false,
        };
      });
    }
  }, [member]);

  //socket
  useEffect(() => {
    if (socket.current) {
      socket.current.disconnect();
    }

    privateAction((token) => {
      socket.current = new SocketIO(
        `user-list`,
        {
          nid: member.nid,
          agentName: member.username,
          agentId: member._id,
          channels: currentFilter.channels.map((item) => item),
          teams: member.teamId,
          task: currentFilter.ticketStatus,
        },
        token,
      );
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [currentFilter.channels, currentFilter.ticketStatus, currentFilter.selectedTeam]);

  useEffect(() => {
    if (!socket.current) return;
    socket.current.on(ESocketEventTopics.USERLIST_TICKET, onNewTicket);
    socket.current.on(ESocketEventTopics.USERLIST_LAST_MESSAGE_REALTIME, onLastMessageFromSocket);
    socket.current.on(ESocketEventTopics.USERLIST_TICKET_REMOVE, onRemoveTicket);

    return () => {
      if (!socket.current) return;
      socket.current.off(ESocketEventTopics.USERLIST_TICKET, onNewTicket);
      socket.current.off(ESocketEventTopics.USERLIST_LAST_MESSAGE_REALTIME, onLastMessageFromSocket);
      socket.current.off(ESocketEventTopics.USERLIST_TICKET_REMOVE, onRemoveTicket);
    };
  }, [socket.current]);

  useEffect(() => {
    loadData();
  }, [
    debouncedSearchTxt,
    currentFilter.channels,
    currentFilter.ticketStatus,
    currentFilter.selectedTeam,
    currentFilter.filterByUnreplied,
    currentFilter.filterNoTeam,
    excludeTeamIds,
  ]);

  const loadData = () => {
    // search by keyword
    if (debouncedSearchTxt) {
      //clear before loadData
      setSearchUserList((prev) => ({
        ...prev,
        items: [],
      }));
      setUserMessagesState((prev) => ({
        ...prev,
        items: [],
      }));
      loadUserBySearchFromApi();
      loadUserMessageBySearchFromApi();
    } else {
      loadUser();
    }
  };

  const loadUser = async () => {
    setCurrentAppState(EAppState.LOADING);
    try {
      //clear user list
      //set paging in filter for show loading
      setCurrentFilter((prev) => ({ ...prev, hasNextPage: true, isNextPageLoading: true }));
      const channelIds = getChannelValue();
      let excludeTeamIdsQuery: string[] | undefined = undefined;
      // current task is unassigned and current member is admin
      if (currentFilter.ticketStatus === ETicketFilterStatus.UNASSIGNED && isAdmin()) {
        excludeTeamIdsQuery = excludeTeamIds;
      }
      const { data, userData } = await loadUserFromApi(
        channelIds ? channelIds : '_all',
        undefined,
        excludeTeamIdsQuery,
      );
      //set filterItems
      updateFilterItem(data.task_count);
      setCurrentFilter((prev) => ({
        ...prev,
        hasNextPage: data.results.length >= MAX_USER_PER_PAGE,
        isNextPageLoading: false,
      }));

      // if current login is admin and filter is assigned to team or team follow up
      // And no data
      if (canFilterByTeam && data.results.length === 0) {
        setUserList(
          produce((draft) => {
            draft.items = [];
            return draft;
          }),
        );
        setCurrentAppState(EAppState.IDLE);
        return;
      }

      setUserList(
        produce((draft) => {
          draft.items = userData;
          return draft;
        }),
      );

      //set app state
      setCurrentAppState(EAppState.IDLE);
    } catch (err) {
      console.error(err);
      setCurrentFilter((prev) => ({
        ...prev,
        hasNextPage: false,
        isNextPageLoading: false,
      }));
      if (axios.isCancel(err)) {
        setCurrentFilter((prev) => ({
          ...prev,
          hasNextPage: true,
          isNextPageLoading: true,
        }));
        return;
      }
      setCurrentAppState(EAppState.ERROR);
    }
  };

  const getMessageCount = ({ value, relation }: { value: number; relation: string }) => {
    if (relation === 'eq') return `${value}`;
    return `${value}+`;
  };

  const loadUserFromApi = async (channelIds: string, lastTicketTime?: number, excludeTeamIdsQuery?: string[]) => {
    if (typeof cancelToken.current != typeof undefined) {
      cancelToken.current?.cancel('Operation canceled due to new request.');
    }

    cancelToken.current = axios.CancelToken.source();
    let filterNoTeam = false;
    let selectedTeam = [];
    // current member is admin and filter is assigned to team or team follow up
    if (currentFilter.selectedTeam) {
      selectedTeam = currentFilter.selectedTeam.length === 0 ? [] : currentFilter.selectedTeam;
    } else {
      selectedTeam = member.teamId;
    }
    if (currentFilter.ticketStatus === ETicketFilterStatus.UNASSIGNED) {
      if (!currentFilter.filterNoTeam) {
        filterNoTeam = false;
      } else {
        filterNoTeam = true;
      }
    } else {
      filterNoTeam = !!currentFilter.filterNoTeam;
    }

    const { data } = await getTicket(
      member._id,
      MAX_USER_PER_PAGE,
      member.nid,
      'desc',
      channelIds,
      currentFilter.ticketStatus,
      selectedTeam,
      cancelToken.current,
      lastTicketTime,
      currentFilter.filterByUnreplied,
      excludeTeamIdsQuery,
      filterNoTeam,
    );

    const userData: UserDataItem[] = data.results.map(({ user, ticket, message }) => {
      let isRead = false;
      if (message && (message.payload.sender === ESenderType.AGENT || message.payload.sender === ESenderType.SYSTEM)) {
        isRead = true;
      }
      return {
        isRead: isRead,
        user: {
          id: user._id,
          name: user.name,
          displayName: user.displayName,
          img: user.image,
          message: UserList.getDescriptionMessage(
            (message?.payload.messageType as string) ?? '',
            (message?.payload.text as string) ?? '',
          ),
          time: message?.createdAt ? message.createdAt : Date.now(),
          channelType: ticket?.channelType,
          userSocialId: user.referenceId,
        },
        ticket,
      };
    });
    return { data, userData };
  };

  const loadUserBySearchFromApi = async (searchAfter?: number) => {
    try {
      setSearchUserList((prev) => ({
        ...prev,
        isFetching: true,
      }));
      const { data } = await getUserListBySearch(
        debouncedSearchTxt,
        member._id ?? '',
        getChannelValue(),
        currentFilter.ticketStatus,
        member.nid,
        dayjs(searchAfter).valueOf(),
      );
      setSearchUserList((prev) => ({
        ...prev,
        items: prev.items.concat(data.userMessages),
        total: getMessageCount(data.total),
        isFetchError: false,
        isFetching: false,
        isNoMore: true,
      }));
      setCurrentAppState(EAppState.IDLE);
    } catch (error) {
      setSearchUserList({
        items: [],
        total: `0`,
        isFetchError: true,
        isFetching: false,
        isNoMore: true,
      });
    }
  };

  const loadUserMessageBySearchFromApi = async (searchAfter?: number) => {
    try {
      setUserMessagesState((prev) => ({
        ...prev,
        isFetching: true,
      }));
      const { data } = await getUserMessageListBySearch(
        debouncedSearchTxt,
        member?._id ?? '',
        getChannelValue(),
        currentFilter.ticketStatus,
        member.nid,
        dayjs(searchAfter).valueOf(),
      );

      setUserMessagesState((prev) => ({
        ...prev,
        items: prev.items.concat(data.userMessages),
        total: getMessageCount(data.total),
        isFetchError: false,
        isFetching: false,
        isNoMore: data.userMessages.length === 0,
      }));
      setCurrentAppState(EAppState.IDLE);
    } catch (error) {
      setUserMessagesState((prev) => ({
        ...prev,
        items: [],
        total: `0`,
        isFetchError: true,
        isFetching: false,
        isNoMore: true,
      }));
    }
  };

  //this function will call every time when hasNextPage is true it mean scroll to bottom of userList
  const loadMoreOnScroll = useCallback(async () => {
    try {
      const lastMessageAt = dayjs(userList?.items[userList.items.length - 1]?.ticket.latestMessageAt).valueOf();
      const channels = getChannelValue();
      const { userData } = await loadUserFromApi(channels, lastMessageAt);
      updateUser(userData);
      setCurrentFilter((prev) => ({
        ...prev,
        hasNextPage: userData.length === MAX_USER_PER_PAGE, // total  userList.items.length + userData.length < data.total
      }));
    } catch (error) {}
  }, [currentFilter, userList]);

  //filter
  //channel
  //load more
  const handleFilterChange = useCallback(<T>(value: T, field: keyof ITicketFilter) => {
    setUserList({
      items: [],
    });
    setCurrentFilter((prev) => {
      return {
        ...prev,
        hasNextPage: true,
        [field]: value,
      };
    });
  }, []);

  const handleSearchTxtChange = (value: string) => {
    setSearchTxt(value);
  };

  const updateUser = (data: UserDataItem[]) => {
    setUserList(
      produce((draft) => {
        draft.items = values(merge(keyBy(draft.items, 'user.id'), keyBy(data, 'user.id')));
        return draft;
      }),
    );
  };

  const updateFilterItem = (data: IFilterPayload) => {
    setFilterItems(
      produce((draft) => {
        for (const key in data) {
          const foundTicketStatusIndex = draft.findIndex((item) => item.value === key);
          if (foundTicketStatusIndex > -1) {
            draft[foundTicketStatusIndex]['count'] = data[key];
          }
        }
      }),
    );
  };

  // socket event
  const onLastMessageFromSocket = (data: ILastMessageSocket) => {
    setSocketOffset(data.eventId);
    setUserList(
      produce((draft) => {
        const result = UserList.handleNewSocketMessage(draft.items, data);
        if (result) {
          draft.items = result;
        }
        return draft;
      }),
    );
  };

  const onRemoveTicket = (data: IUser & ISocketEvent) => {
    setSocketOffset(data.eventId);
    setUserList(
      produce((draft) => {
        const index = draft.items.findIndex(({ user }) => user.id === data.id);
        if (index > -1) {
          draft.items.splice(index, 1);
          return draft;
        }
        return draft;
      }),
    );
  };

  const onNewTicket = (data: ISocketTicketUpdate) => {
    setSocketOffset(data.eventId);
    // check team filter
    // do nothing if team filter is selected and not include in selected team
    if (
      currentFilter.selectedTeam &&
      currentFilter.selectedTeam.length > 0 &&
      !currentFilter.selectedTeam.includes(data.team)
    ) {
      return;
    }

    // resolve event
    if (data.status === ETicketStatus.RESOLVED) {
      setUserList(
        produce((draft) => {
          const index = draft.items.findIndex(({ user }) => user.id === data.id);
          draft.items.splice(index, 1);
        }),
      );
      return;
    }

    // other event
    setUserList(
      produce((draft) => {
        const index = draft.items.findIndex(({ user }) => user.id === data.id);
        //found user
        if (index > -1) {
          const draftItem = draft.items[index];
          // update user message
          draftItem.user.message = UserList.getDescriptionMessage(data.message.messageType, data.message.text);
          draftItem.user.unreadMessageCount = UserList.getUnreadMessageCount(
            data.unreadMessageCount,
            draftItem.user.unreadMessageCount,
          );
          return draft;
        }

        //not found
        draft.items.push({
          isRead: data.message.sender === ESenderType.AGENT,
          ticket: {} as ITicket,
          user: {
            id: data.id,
            img: data.img,
            displayName: data.displayName ?? '',
            message: UserList.getDescriptionMessage(data.message.messageType, data.message.text),
            name: data.name,
            unreadMessageCount: data.unreadMessageCount,
            channelType: data.channelType,
            time: dayjs(data.lastMessageTime).valueOf(),
          },
        });
        // sort
        draft.items = orderBy(
          draft.items,
          (item) => {
            return new Date(item.user.time);
          },
          ['desc'],
        );

        return draft;
      }),
    );
  };

  const setSocketOffset = (offset?: string) => {
    if (socket.current) {
      socket.current.setOffset(offset);
    }
  };
  // end socket event

  const handleLoadMoreUserAndMessage = (loadType: 'user' | 'message') => {
    const lastItemSearchUserList = searchUserList.items[userList.items.length - 1];
    const lastItemSearchMessage = searchUserMessagesList.items[searchUserMessagesList.items.length - 1];
    if (loadType === 'user' && lastItemSearchUserList) {
      loadUserBySearchFromApi(lastItemSearchUserList.time);
      return;
    }
    if (loadType === 'message' && lastItemSearchMessage) {
      loadUserMessageBySearchFromApi(lastItemSearchMessage.time);
      return;
    }
  };

  const getChannelValue = (): string => {
    return currentFilter.channels.map((channel) => channel).join(',');
  };

  const handleSelectUser = () => {
    setSelectedUser(null);
    setSelectedTicket(null);
    setUserList(
      produce((draft) => {
        return draft;
      }),
    );
  };

  return {
    currentFilter,
    debouncedSearchTxt,
    searchTxt,
    userList,
    filterItems,
    searchUserList,
    searchUserMessagesList,
    canFilterByTeam,
    handleFilterChange,
    loadMoreOnScroll,
    handleSearchTxtChange,
    handleLoadMoreUserAndMessage,
    handleSelectUser,
    onSelectChannel: (values: string[]) => {
      setCurrentFilter((prev) => {
        return {
          ...prev,
          channels: values,
        };
      });
    },
  };
};
