import { Typography } from 'antd';
import styled from 'styled-components/macro';

export const Container = styled.a`
  direction: ltr;
  svg {
    width: 48px;
  }
`;

export const FileSizeWrap = styled.a`
  display: flex;
  column-gap: 8px;
  padding: 12px;
  border: 1px solid #ebecef;
  border-radius: 4px;
`;

export const FileName = styled(Typography.Paragraph)`
  width: 180px;
`;
