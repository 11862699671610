import React, { useCallback, useEffect } from 'react';
import assigneeMachine, { IGroupPeople, TAB } from './assigneeMachine';
import { useMachine } from '@xstate/react';
import { ITeam, ITicket } from '@types';
import { IMember } from '@types';
import { useMember } from '@providers/MemberProvider';
import { useAlert } from 'react-alert';
import { ETicketStatus } from '@enums/TicketStatus';
import { isEqual } from 'lodash';
import { useAlertModal } from '@providers/AlertModalProvider';
import { PERMISSION_DENIED, PERMISSION_DENIED_NAME_ERROR, REFRESH_PAGE_BUTTON } from '@configs/constants';
import { useHistory } from 'react-router-dom';

const useAssignee = (currentTicket: ITicket, onAssignSuccesss: (currentTicket: ITicket, agentName: string) => void) => {
  const history = useHistory();
  const { onOpen, onUpdateDetail } = useAlertModal();
  const { member, isSupervisor, isAdmin } = useMember();
  const alert = useAlert();
  const assigneeMachineCb = useCallback(() => {
    return assigneeMachine(currentTicket);
  }, []);

  const [current, send] = useMachine(assigneeMachineCb, {
    // devTools: import.meta.env.DEV,
  });
  const { currentAssignee, peopleData, teamData, searchTxt } = current.context;

  useEffect(() => {
    if (!isEqual(current.context.currentTicket, currentTicket)) {
      send('UPDATE_TICKET_DATA', { data: currentTicket });
    }
  }, [currentTicket, current.context.currentTicket]);

  const isTicketHasTeamButNotAssignee = () => {
    return !currentTicket.agentId && currentTicket.team && currentTicket.status === ETicketStatus.ASSIGNED;
  };
  const filterMember = Array.isArray(peopleData)
    ? peopleData.map((item: IGroupPeople) => {
        // current login member is admin
        if (isAdmin()) {
          const members = item.members?.filter((memberItem: IMember) => {
            return (
              memberItem.username.toLowerCase().trim().includes(searchTxt.toLowerCase().trim()) &&
              memberItem._id !== currentTicket.agentId
            );
          });
          return { ...item, members };
        }
        // current login member is own this ticket
        if (currentTicket.agentId === member._id) {
          const members = item.members?.filter((memberItem: IMember) => {
            return (
              memberItem.username.toLowerCase().trim().includes(searchTxt.toLowerCase().trim()) &&
              memberItem._id !== currentTicket.agentId
            );
          });

          return { ...item, members };
        }
        // the ticket already has team but still not assigned to any agent in the team
        // and current login member is not supervisor
        if (isTicketHasTeamButNotAssignee() && !isSupervisor()) {
          const members = item.members?.filter((memberItem: IMember) => {
            return memberItem._id === member._id;
          });
          return { ...item, members };
        }
        // filter from search text
        const members = item.members?.filter(() => {
          return member.username.toLowerCase().trim().includes(searchTxt.toLowerCase().trim());
        });

        return { ...item, members };
      })
    : [];

  useEffect(() => {
    if (current.matches('assignTicketToAgentSuccess')) {
      const { currentTicket, selectedAgent } = current.context;
      onAssignSuccesss(currentTicket, selectedAgent.username);
      return;
    }
    if (current.matches('assignTicketToTeamSuccess')) {
      const { currentTicket, selectedTeam } = current.context;
      onAssignSuccesss(currentTicket, selectedTeam.name);
      return;
    }
    if (current.matches('assignTicketFailed')) {
      const { errorName, errorMessage } = current.context;
      if (errorName === PERMISSION_DENIED_NAME_ERROR) {
        onUpdateDetail(PERMISSION_DENIED, errorMessage.replace(/\((.*?)\)/, ''), REFRESH_PAGE_BUTTON);
        onOpen(() => {
          history.goBack();
        });
      } else {
        alert.error(errorMessage);
      }
      send('IDLE');
      return;
    }
  }, [current]);

  const handleSearchText = (value: string) => {
    send('SEARCH_TEXT_CHANGE', { value, currentTab: current.context.currentTab });
  };

  const onSelectAgent = (member: IMember) => {
    send('SELECT_AGENT', { value: member });
  };

  const onSelectTeam = (team: ITeam) => {
    send('SELECT_TEAM', { value: team });
  };

  const handleClearSearch = () => {
    send('SEARCH_TEXT_CHANGE', { value: '', currentTab: current.context.currentTab });
  };

  const handleConfirm = (confirmStatus: boolean) => {
    const { currentTab } = current.context;

    if (confirmStatus && currentTab === TAB.PEOPLE) {
      send('CONFIRM_ASSIGN_AGENT');
      return;
    }
    if (confirmStatus && currentTab === TAB.TEAMS) {
      send('CONFIRM_ASSIGN_TEAM');
      return;
    }
    send('CANCEL_ASSIGN');
  };

  const handleAskConfirm = () => {
    send('ASK_CONFIRM');
  };

  const onTabChange = (activeKey: string) => {
    send('TAB_CHANGE', { currentTab: activeKey });
  };

  const onLoadmoreTeam = () => {
    send('LOAD_MORE_TEAM');
  };

  const onIdle = () => {
    send('IDLE');
  };

  return {
    current,
    currentAssignee,
    searchTxt,
    filterMember,
    teamData,
    currentTab: current.context.currentTab,
    onIdle,
    handleSearchText,
    handleClearSearch,
    onSelectAgent,
    handleAskConfirm,
    handleConfirm,
    onTabChange,
    onLoadmoreTeam,
    onSelectTeam,
    isTicketHasTeamButNotAssignee,
    send,
  };
};

export default useAssignee;
