import React from 'react';
import { Container, UserProfileContainer, UserDetailTabContainer } from './style';
import UserProfile from './UserProfile';
import EngagementUserProfileProvider from './EngagementUserProfileProvider';
import UserDetailTab from './UserDetailTab';
import { isBrowser } from 'react-device-detect';
import { ITicket, IUser } from '@types';

const EngagementUserProfile = React.memo(
  ({
    userProfile,
    ticket,
    userId,
    onRefetch,
  }: {
    userProfile: IUser;
    ticket: ITicket;
    userId: string;
    onRefetch: () => void;
  }) => {
    return (
      <EngagementUserProfileProvider userId={userId}>
        <Container>
          <UserProfileContainer>
            <UserProfile userProfile={userProfile} ticket={ticket as ITicket} onRefetch={onRefetch} />
          </UserProfileContainer>
          <UserDetailTabContainer isBrowser={isBrowser}>
            <UserDetailTab />
          </UserDetailTabContainer>
        </Container>
      </EngagementUserProfileProvider>
    );
  },
);

EngagementUserProfile.displayName = 'EngagementUserProfile';

export default EngagementUserProfile;
