import styled from 'styled-components/macro';

export const LoadingWrap = styled.div`
  position: initial !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
