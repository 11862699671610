import { useState, useEffect, useRef } from 'react';

export const useScrollBottom = (height: number) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const lastScrollTopRef = useRef(0);
  useEffect(() => {
    if (contentRef.current && !isUserScrolling) {
      contentRef.current.scrollTop = height;
    }
  }, [height, isUserScrolling]);

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.currentTarget;
    const currentScrollTop = element.scrollTop;

    const direction = currentScrollTop > lastScrollTopRef.current ? 'down' : 'up';
    if (direction === 'up') {
      setIsUserScrolling(true);
    }
    // if scroll bar close to bottom within 50px then set isUserScrolling to false
    if (element.scrollHeight - element.clientHeight - currentScrollTop < 50) {
      setIsUserScrolling(false);
    }
    lastScrollTopRef.current = currentScrollTop;
  };

  return {
    contentRef,
    onScroll,
  };
};
