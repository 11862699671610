import { useState } from 'react';
import { resolveTicket } from '@api/livechat/EngagementMessage';
import { ITicket, IUser } from '@types';
import axios, { AxiosError } from 'axios';
import { useAlertModal } from '@providers/AlertModalProvider';
import { PERMISSION_DENIED, PERMISSION_DENIED_NAME_ERROR, REFRESH_PAGE_BUTTON } from '@configs/constants';
import { useHistory } from 'react-router-dom';

export const useResolveTicket = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isResolving, setIsResolving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { onOpen, onUpdateDetail } = useAlertModal();
  return {
    showModal,
    isResolving,
    errorMessage,
    onSubmit: async (ticket: ITicket, user: IUser, submitCb: () => void) => {
      setErrorMessage('');
      if (ticket && user) {
        try {
          setIsResolving(true);
          await resolveTicket(ticket._id, '');
          setShowModal(false);
          setIsResolving(false);
          submitCb();
        } catch (error) {
          console.error(error);
          const err = error as Error | AxiosError;
          // hide form
          setShowModal(false);
          setIsResolving(false);
          if (axios.isAxiosError(err)) {
            const name = err?.response?.data?.name;
            const errorMessage = err?.response?.data?.message;
            if (name === PERMISSION_DENIED_NAME_ERROR) {
              onUpdateDetail(PERMISSION_DENIED, errorMessage.replace(/\((.*?)\)/, ''), REFRESH_PAGE_BUTTON);
              onOpen(() => {
                history.goBack();
              });
              return;
            }
            // Access to config, request, and response
            setErrorMessage(err?.response?.data?.message ? err?.response?.data?.message : '');
          }
        }
      }
    },
    onHideErrorModal: () => {
      setErrorMessage('');
    },
    onShowModal: (value: boolean) => {
      setShowModal(value);
    },
  };
};
