import React from 'react';
import { OperationalDashboardProvider } from './context';
import { ChannelFilter } from '@components/ChannelFilter';
import { DATE_INTERVAL, EDateInterval, DateRangePickerV2 } from '@components/DateRangePickerV2';
import { TeamFilter } from '@components/TeamFilter';
import { Font24G1W700, FrameV2, Font14G3W400, CustomButton, Font14G5W400, NormalBtn } from '@components/UtilsComponent';
import { faInfoCircle, faCalendarDay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { PageLayout } from '@layouts/PageLayout';
import { Tooltip } from 'antd';
import { DropdownFilterTag } from '../ticket_management/DropdownFilterTag';
import { RightGroup, SelectedCustomDateWrap, ColumnDivider, SelectedDateIntervalWrap } from './style';
import { useDashboardFilter } from './useDashboardFilter';
import { AssigneeFilterDashboard } from '@components/AssigneeFilterDashboard';
import { StatusTicket } from './StatusTicket';
import { TicketActivity } from './TicketActivity';
import { AverageTime } from './AverageTime';
import { TicketByAgent } from './TicketByAgent';
import { RatingTable } from './RatingTable';

export const OperationalDashboardEntry = () => {
  return (
    <OperationalDashboardProvider>
      <OperationalDashboard />
    </OperationalDashboardProvider>
  );
};

const OperationalDashboard = () => {
  const t = useTranslation();
  const { filter, dateState, onChangeFilter } = useDashboardFilter();
  const { openDatePickers, clearDatePicker, onClearDatePicker, onToggleDatePickers } = dateState;

  return (
    <div className="overflow-auto w-full h-screen">
      <PageLayout data-testid="test-operational-dashboard">
        <div className="flex gap-x-[12px] items-center w-full">
          <Font24G1W700>{t('operational.dashboard.title')}</Font24G1W700>
          <Tooltip placement="right" title={t('operational.dashboard.tooltip')}>
            <FontAwesomeIcon icon={faInfoCircle} className="text-[#BDBDBD]" />
          </Tooltip>
        </div>
        <div className="flex justify-between">
          <div className="mt-[24px] flex gap-x-[8px]">
            <div className="w-[144px]">
              <ChannelFilter
                selectedChannels={filter.channels}
                onSelectChannel={(items) => onChangeFilter('channels', items)}
              />
            </div>
            <div className="w-[144px]">
              <TeamFilter
                selectedTeam={filter.teams}
                onSelectTeam={(items: string[]) => {
                  // clear assignees when team is changed
                  onChangeFilter('assignees', []);
                  onChangeFilter('teams', items);
                }}
              />
            </div>
            <div className="w-[144px]">
              <AssigneeFilterDashboard
                teamsIds={filter.teams}
                selectedAssignee={filter.assignees}
                onSelectAssignee={(items: string[]) => {
                  onChangeFilter('assignees', items);
                }}
              />
            </div>
            <div className="w-[144px]">
              <DropdownFilterTag
                selectedTag={filter.tags}
                onSelectDropdown={(items: string[]) => {
                  onChangeFilter('tags', items);
                }}
              />
            </div>
          </div>
          <div className="h-[36px]">
            <RightGroup className="mt-[24px]">
              {DATE_INTERVAL.map((item) => {
                if (item.value === EDateInterval.CUSTOM) {
                  return (
                    <React.Fragment key={item.value}>
                      <DateRangePickerV2
                        showTime={false}
                        visible={openDatePickers}
                        trigger={['click']}
                        onVisibleChange={(visible) => {
                          if (!visible) {
                            if (filter.dateInterval.value !== EDateInterval.CUSTOM) {
                              onClearDatePicker(false);
                              setTimeout(() => {
                                onClearDatePicker(true);
                              }, 0);
                            }
                            onToggleDatePickers();
                          }
                        }}
                        isClearEvent={clearDatePicker}
                        onSubmit={(payload) => {
                          // close date pickers after submit
                          onToggleDatePickers();
                          dateState.onChangeCustomDate(payload);
                        }}
                      >
                        {filter.dateInterval.value === item.value ? (
                          <SelectedCustomDateWrap
                            onClick={() => {
                              onToggleDatePickers();
                            }}
                          >
                            <FrameV2 width={16} height={16} centered>
                              <FontAwesomeIcon icon={faCalendarDay} />
                            </FrameV2>
                            <div>
                              <Font14G3W400>
                                {item.label} : {filter.startIsoStringDate.format('DD MMM YY')} -{' '}
                                {filter.endIsoStringDate.format('DD MMM YY')}
                              </Font14G3W400>
                            </div>
                          </SelectedCustomDateWrap>
                        ) : (
                          <CustomButton
                            onClick={() => {
                              onToggleDatePickers();
                            }}
                          >
                            <Font14G5W400>{t('custom')}</Font14G5W400>
                          </CustomButton>
                        )}
                      </DateRangePickerV2>
                      <ColumnDivider />
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={item.value}>
                    {filter.dateInterval.value === item.value ? (
                      <SelectedDateIntervalWrap>
                        <FrameV2 width={16} height={16} centered>
                          <FontAwesomeIcon icon={faCalendarDay} />
                        </FrameV2>
                        <div>
                          <Font14G3W400>
                            {item.label} : {filter.startIsoStringDate.format('DD MMM YY')} -{' '}
                            {filter.endIsoStringDate.format('DD MMM YY')}
                          </Font14G3W400>
                        </div>
                      </SelectedDateIntervalWrap>
                    ) : (
                      <NormalBtn
                        key={item.value}
                        onClick={() => {
                          onClearDatePicker(false);
                          setTimeout(() => {
                            onClearDatePicker(true);
                          }, 0);
                          dateState.onChangeDateInterval(item);
                        }}
                      >
                        <Font14G5W400>{item.label}</Font14G5W400>
                      </NormalBtn>
                    )}
                  </React.Fragment>
                );
              })}
            </RightGroup>
          </div>
        </div>
        <div className="mt-[32px] grid grid-cols-[repeat(auto-fit,minmax(634px,1fr))] gap-[24px] pb-[24px]">
          <StatusTicket filter={filter} />
          <TicketActivity filter={filter} />
          <AverageTime filter={filter} />
          <TicketByAgent filter={filter} />
        </div>
        <RatingTable filter={filter} />
      </PageLayout>
    </div>
  );
};
