import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTE } from '@configs/route';
import { useMember } from '@providers/MemberProvider';
import { isEmpty } from 'lodash';

export const PrivateRoute: React.FC = ({ children }) => {
  const { cognitoUser, member } = useMember();

  const isNotLoggedIn = !cognitoUser || isEmpty(member);
  const isReportPath = window.location.pathname.includes(ROUTE.REPORT);

  useEffect(() => {
    if (import.meta.env.DEV) {
      return;
    }
    if (isNotLoggedIn) {
      const url = `${import.meta.env.VITE_APP_LOGIN_REDIRECT_PATH}?redirect=${window.location.href}`;
      window.location.href = url;
    }
  }, [cognitoUser, member, isNotLoggedIn, isReportPath]);
  if (import.meta.env.DEV && isNotLoggedIn) {
    if (isReportPath) {
      return <Redirect to={`${ROUTE.LOGIN}?redirect=${window.location.pathname}`} />;
    }
    return <Redirect to={`${ROUTE.LOGIN}`} />;
  }
  if (isNotLoggedIn) return null;
  return <>{children}</>;
};
