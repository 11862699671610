import styled from 'styled-components/macro';
import { Modal, Form as AntdForm } from 'antd';

export const ErrorContainer = styled(Modal)`
  .ant-modal-content {
    overflow: hidden;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 278px;
    width: 400px;
    img {
      margin: 0 auto;
    }
    .ant-modal-body > div {
      display: grid;
      gap: 8px;
      text-align: center;
      div:nth-child(2) span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: ${({ theme }) => theme.GREY_5};
      }
      div:nth-child(3) span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.GREY_5};
      }
    }
  }
`;

export const Container = styled(Modal)`
  width: 600px !important;
  top: 50px;
  .ant-modal-content {
    overflow: hidden;
    border-radius: 12px;
  }
  .ant-modal-footer {
    padding: 24px;
  }
`;

export const Section = styled.div`
  display: grid;
  gap: 8px;
  .ant-row {
    margin: 0;
  }
  :not(:first-child) {
    margin-top: 16px;
  }
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const Form = styled(AntdForm)``;
export const ActionContainer = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  margin-top: 4px;
`;

export const ActionInner = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const AutoSaveTime = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.GREY_5};
`;
