import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import EngagementUser from './EngagementUserList';
import { Container } from './style';
import ChatWrap from './ChatWrap';
import smile_chat from '@assets/images/smile-chat.svg';
import empty from '@assets/images/empty.svg';
import error from '@assets/images/error.svg';
import StateMessage from '@components/StateMessage';
import useTranslation from '@hooks/useTranslation';
import { useRecoilValue } from 'recoil';
import { appState, userListState } from '@store/atom';
import useRecent from './useRecent';
import { useMember } from '@providers/MemberProvider';

export type IRecents = { [key: string]: any };

const Recents: React.FC<IRecents> = ({}: IRecents) => {
  const t = useTranslation();
  const { member } = useMember();
  useRecent();
  const location = useLocation();
  const currentAppState = useRecoilValue(appState);
  const userList = useRecoilValue(userListState);
  const _renderState = () => {
    const lo = location.pathname.split('/');
    if (currentAppState === 'loading' && lo[1] === 'recents' && !lo[2]) {
      return null;
    }
    if (currentAppState === 'error') {
      return (
        <StateMessage
          size="large"
          type="error"
          img={error}
          label={t('livechat.error.title')}
          description={t('livechat.error.description')}
        />
      );
    }
    if (userList.items.length === 0 && lo[1] === 'recents' && !lo[2]) {
      return (
        <StateMessage
          size="large"
          type="general"
          img={empty}
          label={t('livechat.empty.title')}
          description={t('livechat.empty.description')}
        />
      );
    }

    if (userList.items.length > 0 && lo[1] === 'recents' && !lo[2]) {
      return (
        <StateMessage
          size="large"
          type="success"
          img={smile_chat}
          label={t('livechat.start.conversation.title', { memberName: member.username })}
          description={t('livechat.start.conversation.description')}
        />
      );
    }

    return (
      <Route path={`/recents/:id`}>
        <ChatWrap />
      </Route>
    );
  };

  return (
    <Container wrap={false}>
      <EngagementUser />
      {_renderState()}
    </Container>
  );
};

export default Recents;
