import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  column-gap: 8px;
  height: 230px;
  width: 100%;
  max-width: 290px;
  overflow: auto;
  img {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
`;

export const Item = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  min-width: 250px;
  button {
    height: 36px;
    position: absolute;
    border: none;
    display: inline-flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    max-width: 100%;
    color: white;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, 0);
    border-radius: 17px;
    background: rgba(0, 0, 0, 0.5);
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
