import styled from 'styled-components/macro';
import { Avatar as AntdAvatar } from 'antd';

export const AvatarItem = styled(AntdAvatar)<{ fontSize?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  .ant-avatar-string {
    transform: translateX(-50%) translateY(-50%) !important;
    top: 50%;
    left: 50%;
    font-weight: 600;
    font-size: ${({ fontSize }) => fontSize ?? 16}px;
    text-transform: uppercase;
  }
`;

export const Container = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  div {
    position: absolute;
    right: 0;
    bottom: 0;
    img {
      width: ${({ width }) => width / 2.5}px;
      height: ${({ height }) => height / 2.5}px;
    }
  }
`;

export const CircleStatus = styled.div<{ memberStatus: string; width: number; height: number }>`
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid ${({ theme }) => theme.GREY_1};
  border-radius: 50%;
  background: ${({ theme, memberStatus }) => getColorStatus(theme, memberStatus)};
  width: ${({ width }) => width / 3}px;
  height: ${({ height }) => height / 3}px;
`;

const getColorStatus = (theme: any, memberStatus: string) => {
  if (memberStatus === 'on_duty') return theme.GREEN;
  if (memberStatus === 'busy') return theme.RED;
  if (memberStatus === 'away') return theme.SECONDARY_BLUE;
  if (memberStatus === 'break') return theme.BLUE;
  if (memberStatus === 'meeting') return theme.ORANGE;
  //unavailable
  return theme.GREY_4;
};
