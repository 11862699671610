import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  padding: 4px 10px;
  align-items: flex-start;
  border-radius: 16px;
  background: #fff;
  white-space: pre-wrap;
  align-items: center;
  width: fit-content;
  word-break: break-word;
  width: fit-content;
  max-width: 290px;
`;
