import useOnScreen from '@hooks/useOnSecreen';
import isUrl from '@utils/isUrl';
import { useEffect, useRef, useState } from 'react';

export type IStickerList = [staticUrl: string, animationUrl?: string];

const createSrcList = (stickerData: string) => {
  let srcList: [string, string?];
  if (isUrl(stickerData)) {
    srcList = [stickerData];
  } else {
    srcList = [
      `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerData}/android/sticker.png`,
      `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerData}/android/sticker_animation.png`,
    ];
  }
  return srcList;
};

export default (stickerData: string) => {
  const ref = useRef<HTMLImageElement>(null);
  const isOnScreen = useOnScreen(ref);
  const [animatable, setAnimatable] = useState(true);
  const [staticUrl, animationUrl] = createSrcList(stickerData);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (animatable && animationUrl && isOnScreen) {
      ref.current.src = animationUrl;
    } else {
      ref.current.src = staticUrl;
    }
  }, [isOnScreen]);

  const onError = () => {
    if (!ref.current) {
      return;
    }
    ref.current.src = staticUrl;
    setAnimatable(false);
  };

  return {
    ref,
    onError,
  };
};
