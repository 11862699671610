import axios, { CancelTokenSource } from 'axios';
import { useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'use-lodash-debounce';

export enum ETableEvent {
  NEXT = 'next',
  PREV = 'prev',
  NONE = 'none',
}

export const fetchHandler = async <T>(
  keyword: string,
  cursorId: {
    next: string;
    prev: string;
  },
  currentPageEvent: ETableEvent,
  currentPage: number,
  fetchFn: ({ keyword, cursorId, take }: { keyword: string; cursorId: string; take: number }) => Promise<T>,
) => {
  return {
    fetchFn: async (maxTake: number) => {
      let cursor = '';
      if (currentPageEvent === ETableEvent.NEXT) {
        cursor = cursorId.next;
      }
      if (currentPageEvent === ETableEvent.PREV) {
        cursor = cursorId.prev;
      }

      const result = await fetchFn({ cursorId: cursor, keyword: keyword, take: maxTake });

      return result;
    },
    behavior: currentPageEvent,
    params: {
      max: 10,
      currentPage: currentPage,
    },
  };
};

export type IUseTableParams<T> = {
  fetchFn: (
    keyword: string,
    cursorId: {
      next: string;
      prev: string;
    },
    currentPageEvent: ETableEvent,
    currentPage: number,
    cancelToken?: CancelTokenSource,
  ) => Promise<T>;
  debounceTime: number;
  enable?: boolean;
  watchParams?: string[];
  externalSetter?: (data: T) => void;
};

export const useTable = <T>({ debounceTime, enable, watchParams, fetchFn, externalSetter }: IUseTableParams<T>) => {
  const [keyword, setKeyword] = useState('');
  const cursorRef = useRef({
    next: '',
    prev: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageEventRef = useRef<ETableEvent>(ETableEvent.NONE);
  const debouncedSearchTxt = useDebounce(keyword, debounceTime);

  const reQuery = useQuery({
    queryKey: ['table', debouncedSearchTxt, watchParams ? watchParams : ''],
    queryFn: async ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const res = fetchFn(debouncedSearchTxt, cursorRef.current, currentPageEventRef.current, currentPage, source);
      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by React Query');
      });
      return res;
    },
    enabled: enable,
    onSuccess: (data) => {
      if (externalSetter) {
        externalSetter(data);
      }
    },
  });
  return {
    query: reQuery,
    keyword,
    cursorRef,
    currentPage,
    isDisabledPrev: currentPage === 1,
    onUpdateKeyword: (keyword: string) => {
      currentPageEventRef.current = ETableEvent.NONE;
      setKeyword(keyword);
    },
    onUpdateCursorId: (nextId: string, prevId: string) => {
      cursorRef.current = {
        next: nextId,
        prev: prevId,
      };
    },
    onNext: () => {
      currentPageEventRef.current = ETableEvent.NEXT;
      reQuery.refetch();
    },
    onPrev: () => {
      currentPageEventRef.current = ETableEvent.PREV;
      reQuery.refetch();
    },
    onUpdateCurrentPage: (page: number) => {
      setCurrentPage(page);
    },
  };
};
