import { getTemplates } from '@api/template';
import { BOTS } from '@types';
import { tableListGeneratorV2 } from '@utils/tableListGeneratorV2';
import { useMachine } from '@xstate/react';
import { tableMachine } from '@machine/tableMachine';
import { useState, useCallback, SyntheticEvent, useEffect } from 'react';

const MAX_TAKE = 30;

export const useTemplateSelector = (
  selectedTemplate: BOTS.ITemplateApi | null,
  fetchFn: (params?: BOTS.IFetchTemplateRequest | undefined) => Promise<BOTS.ITemplateApi[]> = getTemplates,
  maxTake: number = MAX_TAKE,
) => {
  const [visible, setVisible] = useState(false);
  const [innerSelectedTemplate, setInnerSelectedTemplate] = useState<BOTS.ITemplateApi | null>(selectedTemplate);

  useEffect(() => {
    setInnerSelectedTemplate(selectedTemplate);
  }, [selectedTemplate]);

  const machineFn = useCallback(() => {
    return tableMachine<BOTS.ITemplateApi>({
      fetchOnInit: false,
      fetchFn: async (behavior, currentPage, nextCursorId, prevCursorId, keyword) => {
        const result = await tableListGeneratorV2({
          behavior,
          fetchParams: {
            max: maxTake,
            currentPage,
            nextCursorId,
            prevCursorId,
            keyword,
          },
          fetchFn: fetchFn,
        });
        if (!result) throw new Error('fetch failed');
        const newResult = {
          currentPage: result.currentPage,
          disabledNext: result.disabledNext,
          nextCursorId: result.nextCursorId,
          prevCursorId: result.prevCursorId,
          data: result.items,
        };

        return newResult;
      },
    });
  }, []);
  const [current, send] = useMachine(machineFn, {
    devTools: import.meta.env.MODE === 'development',
  });

  return {
    innerSelectedTemplate,
    current,
    visible,
    isLoading: current.matches('fetching'),
    isLoadingMore: current.matches('loadingMore'),
    onToggleModal: () => {
      setVisible((prev) => {
        // send fetch if modal is open
        if (!prev) {
          send('FETCH');
        }
        return !prev;
      });
    },
    onFetchTemplateList: (behavior: 'prev' | 'next') => {
      send('FETCH', { behavior });
    },
    onSearch: () => {
      send('FETCH', { behavior: 'none' });
    },
    onUpdateKeyword: (keyword: string) => {
      send('UPDATE_SEARCH_KEYWORD', { keyword });
    },
    onScroll: (e: SyntheticEvent<HTMLDivElement>) => {
      const { currentTarget } = e;

      // is scroll bottom and not disabled next
      if (
        currentTarget.scrollTop + currentTarget.offsetHeight >= currentTarget.scrollHeight &&
        !current.context.list.disabledNext
      ) {
        // if failed return
        if (current.matches('failure')) return;
        send('FETCH_MORE', { behavior: 'next' });
      }
    },
    onSelectTemplate: (template: BOTS.ITemplateApi | null) => {
      setInnerSelectedTemplate(template);
    },
  };
};
