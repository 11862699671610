import { client } from '@api';
import { CancelTokenSource } from 'axios';

export type IChannelParams = {
  type?: string;
  skip?: number;
  take?: number;
  keyword?: string;
  cursorId?: string;
};

export type IConnectChannelPayload<T> = {
  id?: string;
  name?: string;
  referenceId?: string;
  config?: T;
  activate?: boolean;
};

export type IConnectFacebookChannelPayload = IConnectChannelPayload<{
  pageToken?: string;
  channelAccessToken?: string;
  channelSecret?: string;
}>;

export type IConnectLineChannelPayload = IConnectChannelPayload<{
  pageToken?: string;
}>;

export type IConnectAmityChatChannelPayload = IConnectChannelPayload<{
  adminAccessToken?: string;
}>;

export type IConnectCustomChannelPayload = IConnectChannelPayload<{
  baseUrl?: string;
}>;

type IConnectAllChannelPayload =
  | IConnectFacebookChannelPayload
  | IConnectLineChannelPayload
  | IConnectAmityChatChannelPayload
  | IConnectCustomChannelPayload;

export const getAllChannel = (status: boolean, channelType: string) => {
  if (!status) {
    return client.get(`/api/channels?type=${channelType}`);
  }
  return client.get(`/api/channels?activate=${status}&type=${channelType}`);
};

export const getChannels = (params: IChannelParams, cancelToken?: CancelTokenSource) => {
  return client.get(`/api/channels`, {
    params: {
      ...params,
    },
    cancelToken: cancelToken?.token,
  });
};

export const createChannel = (payload: IConnectAllChannelPayload) => {
  return client.post('/api/channels', payload);
};

export const updateChannel = (payload: IConnectAllChannelPayload) => {
  return client.put(`/api/channels/${payload.id}`, payload);
};

export const deleteChannel = (id: string) => {
  return client.delete(`/api/channels/${id}`);
};

export const getFbPages = (token: string, userId: string) => {
  return client.get(`/api/channels/facebook/pages?token=${token}&userId=${userId}`);
};

// for custom channel only
export const generateKey = (channelId: string) => {
  return client.post(`/api/channels/refresh-api-key`, { channelId });
};
