import styled from 'styled-components/macro';

export const TableWrap = styled.div`
  .ant-table-cell {
    white-space: nowrap;
  }
  td.ant-table-cell:nth-child(2),
  td.ant-table-cell:nth-child(13) {
    &:after {
      height: 60%;
      width: 1px;
      background-color: #e5e5e5;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  th.ant-table-cell:nth-child(13) {
    padding-right: 0;
  }
  th.ant-table-cell:nth-child(14) {
    padding-left: 0;
  }
`;
