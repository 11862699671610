import { BaseMessage, BaseMessageJson } from './BaseMessage';
import dayjs from 'dayjs';

export type DateMessageJson = BaseMessageJson;

export class DateMessage extends BaseMessage {
  constructor(data: DateMessageJson) {
    super(data);
  }

  isToday(): boolean {
    return dayjs(this.createdAt).isToday();
  }

  public formatDate(format: string): string {
    return dayjs(this.createdAt).format(format);
  }
}
