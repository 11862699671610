import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { IMessageLevel } from '@types';

export type HtmlMessageJson = {
  htmlContent: string;
} & BaseMessageJson;

export class HtmlMessage extends BaseMessage {
  private htmlContent: string;
  private level: IMessageLevel;

  constructor(data: HtmlMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.htmlContent = data.htmlContent; // not garuntee that it will be first message
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      htmlContent: this.htmlContent,
      createdAt: this.createdAt,
      level: this.level,
      retry: this.retry,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
