import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from './style';
import { InputProps } from 'antd';
import { faSearch, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

export type ISearchInput = {
  value: string;
  isGrayBg?: boolean;
  onClear: () => void;
} & InputProps;

const SearchInput = ({ value, placeholder, onClear, onChange, ...rest }: ISearchInput, ref: any) => {
  return (
    <Input
      {...rest}
      ref={ref}
      value={value}
      prefix={<FontAwesomeIcon icon={faSearch} />}
      suffix={value && <FontAwesomeIcon icon={faTimesCircle} onClick={onClear} />}
      onChange={onChange}
      placeholder={placeholder ?? 'Search'}
    />
  );
};

export default React.forwardRef(SearchInput);
