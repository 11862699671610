import React from 'react';
import { Container } from './style';
import EngagementUserProfile from '@pages/desktop/recents/EngagementUserProfile';
import { TopbarBox } from '@pages/components/TopbarBox/style';
import { useHistory, useParams } from 'react-router-dom';
import { Icon } from '@components/UtilsComponent';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { useTicket } from '@hooks/useTicket';
import { ITicket } from '@types';
import { useQuery } from 'react-query';
import { getUserById } from '@api/livechat/User';

const UserProfile: React.FC = () => {
  const history = useHistory();
  const { id: userId } = useParams<{ id: string }>();
  const { ticketFetch } = useTicket(userId);
  const currentUserData = useQuery(['getUserById', userId], () => getUserById(userId), {
    retry: 0,
  });
  if (ticketFetch.isLoading || !ticketFetch.data || currentUserData.isLoading) return <></>;
  const { data } = ticketFetch.data;

  return (
    <Container>
      <TopbarBox>
        <a onClick={() => history.goBack()}>
          <Icon icon={faArrowLeft} />
        </a>
      </TopbarBox>
      <EngagementUserProfile
        userProfile={currentUserData.data?.data}
        ticket={data as unknown as ITicket}
        userId={userId}
        onRefetch={() => currentUserData.refetch()}
      />
    </Container>
  );
};

export default UserProfile;
