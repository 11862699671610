import React, { createContext, useContext } from 'react';
import { Row } from 'antd';
import { Container, UserProfile, MiddlePane } from './style';
import TicketConsole from './TicketConsole';
import useTicketHistory from './useTicketHistory';
import { InterpreterFrom } from 'xstate';
import ticketHistoryMachine from './ticketHistoryMachine';
import TicketHistoryUserProfile from './TicketHistoryUserProfile';
import { isEmpty } from 'lodash';
import MessageBoxProvider from '@providers/MessageBoxProvider';
import dayjs from 'dayjs';
import { TicketHistoryChatPane } from './TicketHistoryChatPane';
import { useMember } from '@providers/MemberProvider';
import { ComposerManagerProvider } from '@providers/ComposerManagerProvider';

export const TicketHistoryContext = createContext({
  ticketHistoryService: {} as InterpreterFrom<typeof ticketHistoryMachine>,
});
export const useTicketHistoryContext = () => useContext(TicketHistoryContext);

export const MESSAGE_BOX_OPTIONS = { isSocket: true, isTicketManagementMode: true };

const TicketHistory: React.FC = () => {
  const { ticketHistoryService, ticket, onFetchUser } = useTicketHistory();
  const { currentTicket, user } = ticket;
  const { member } = useMember();
  return (
    <TicketHistoryContext.Provider value={{ ticketHistoryService }}>
      {/* important */}
      {/* if remove this line everything down here will call api with undefined */}
      {!isEmpty(currentTicket) && (
        <ComposerManagerProvider>
          <Container>
            <Row wrap={false}>
              <MiddlePane flex={1}>
                <TicketConsole />
                <MessageBoxProvider
                  user={user}
                  ticket={currentTicket}
                  member={member}
                  messageTime={dayjs(currentTicket.createdAt).valueOf()}
                  options={MESSAGE_BOX_OPTIONS}
                >
                  <TicketHistoryChatPane />
                </MessageBoxProvider>
              </MiddlePane>
              <UserProfile>
                <TicketHistoryUserProfile user={user} onFetchUser={() => onFetchUser()} />
              </UserProfile>
            </Row>
          </Container>
        </ComposerManagerProvider>
      )}
    </TicketHistoryContext.Provider>
  );
};

export default TicketHistory;
