import styled from 'styled-components/macro';

export const SlaContainer = styled.div<{ isOverdue: boolean }>`
  display: flex;
  color: ${({ theme, isOverdue }) => (isOverdue ? theme.RED : theme.BLUE)};
  span {
    color: ${({ theme, isOverdue }) => (isOverdue ? theme.RED : theme.BLUE)} !important;
    margin-left: 4px;
  }
`;
