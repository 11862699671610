import { useState, useEffect } from 'react';
import { RcFile } from 'antd/lib/upload';
import { useAlert } from 'react-alert';
import { AxiosResponse } from 'axios';

export type IUploadResponse = {
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  size: number;
  bucket: string;
  key: string;
  acl: string;
  contentType: string;
  contentDisposition: null;
  contentEncoding: null;
  storageClass: string;
  serverSideEncryption: null;
  metadata: Metadata;
  location: string;
  etag: string;
};

export type Metadata = {
  type: string;
  nid: string;
};

export type IImageUploaderFnType = (file: RcFile) => Promise<AxiosResponse<IUploadResponse[]>>;

export type IMinImageDimension = {
  minWidth: number;
  minHeight: number;
};

export type IImageUploadedParams = {
  url: string;
  type?: string;
  dimension?: {
    width: number;
    height: number;
  };
};

export type IUseFileParams = {
  selectedMedia: string;
  minImageDimension?: IMinImageDimension;
  uploader: IImageUploaderFnType;
};

const MB_SIZE = 1024 ** 2;

//this hook must use inside AlertProvider from react-alert
export const useImageUpload = ({ selectedMedia, minImageDimension, uploader }: IUseFileParams) => {
  const alert = useAlert();
  const [textUrl, setTextUrl] = useState('');
  const [currentUrl, setCurrentUrl] = useState<string>(selectedMedia);
  const [currentFile, setCurrentFile] = useState<RcFile | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isFileLessthanMinSize, setIsFileLessthanMinSize] = useState(false);

  useEffect(() => {
    if (isFileTooLarge) {
      alert.error('File is too large');
      return;
    }
    if (isFileLessthanMinSize) {
      alert.error(
        `Image width and height should more than ${minImageDimension?.minWidth}px x ${minImageDimension?.minHeight}px`,
      );
      return;
    }
  }, [isFileTooLarge, isFileLessthanMinSize]);

  const getImageDimension = (file: RcFile): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      // the following handler will fire after a successful loading of the image
      img.onload = () => {
        const { naturalWidth: width, naturalHeight: height } = img;
        resolve({ width, height });
      };

      // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
      img.onerror = (err) => {
        reject(err);
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const checkMinImageSizeFn = (file: RcFile, minImageDimension: IMinImageDimension): Promise<boolean> => {
    if (!minImageDimension) return Promise.resolve(false);
    const { minWidth, minHeight } = minImageDimension;
    return getImageDimension(file)
      .then(({ width, height }) => {
        if (width < minWidth || height < minHeight) {
          return false;
        }
        return true;
      })
      .catch(() => {
        return false;
      });
  };

  return {
    currentUrl,
    currentFile,
    isLoading,
    textUrl,
    beforeUpload: async (sourceFile: RcFile, maxMbSize = 1) => {
      const fileSizeInMb = sourceFile.size / MB_SIZE;
      // check file size
      if (Number(fileSizeInMb.toFixed(2)) > maxMbSize) {
        setIsFileTooLarge(true);
        return false;
      }
      // check min image size
      if (minImageDimension) {
        const result = await checkMinImageSizeFn(sourceFile, minImageDimension);
        if (!result) {
          setIsFileLessthanMinSize(true);
          return false;
        }
      }
      setTextUrl('');
      setCurrentFile(sourceFile);
      setIsFileTooLarge(false);
      return false;
    },
    onAddTextUrl: (url: string) => {
      setTextUrl(url);
    },
    onUpload: async () => {
      if (!currentFile) return;
      try {
        setIsLoading(true);

        const { data } = await uploader(currentFile);
        const firstItem = data[0];
        setCurrentUrl(firstItem.location);
        if (firstItem.mimetype.includes('image')) {
          const dimension = await getImageDimension(currentFile);
          return {
            url: firstItem.location,
            type: firstItem.mimetype,
            dimension: dimension,
          };
        }

        return {
          url: firstItem.location,
          type: firstItem.mimetype,
        };
      } catch (error) {
        console.error('upload file failed ', error);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    onClearDraftUrl: () => {
      setCurrentFile(null);
      setCurrentUrl('');
    },
    isNoUrl: () => {
      return !currentFile;
    },
  };
};
