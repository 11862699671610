import dayjs from 'dayjs';
import {
  IPreviewCarouselItem,
  IPreviewOption,
} from '@pages/desktop/recents/EngagementMessage/Composer/ActionComposer/types';

export const actions = [
  {
    id: '1',
    name: 'Greeting',
    type: 'text',
    actions: [
      {
        type: 'text',
        text: 'Hello',
      },
      {
        type: 'text',
        text: 'baby',
      },
      {
        type: 'image',
        url: 'https://picsum.photos/200/400',
      },
      {
        type: 'option',
        label: 'this is option',
        actions: [
          {
            id: '1',
            label: 'Click',
          },
          {
            id: '2',
            label: 'Click 2',
          },
        ],
      } as IPreviewOption,
    ],
  },
  {
    id: '2',
    name: 'FAQ Carousel F...',
    type: 'carousel',
    actions: [
      {
        type: 'text',
        text: 'Hello',
      },
      {
        type: 'text',
        text: 'Hello',
      },
      {
        type: 'text',
        text: 'Hello',
      },
      {
        type: 'text',
        text: 'Hello',
      },
      {
        type: 'text',
        text: 'Hello',
      },
      {
        type: 'text',
        text: 'Hello',
      },
      {
        type: 'text',
        text: 'Hello',
      },
    ],
  },
  {
    id: '3',
    name: 'Test',
    type: 'text',
    actions: [
      {
        type: 'text',
        text: 'Hello',
      },
    ],
  },
  {
    id: '4',
    name: 'Test Carousel',
    type: 'carousel',
    actions: [
      {
        type: 'carousel',
        message: 'อรุ่มเจ๊าะ',
        items: [
          {
            id: 'arum1',
            image: 'https://cat-thai.com/wp-content/uploads/2021/08/26-2.jpg',
            title: 'CTitle1',
            description: 'CDescription 1',
            buttons: ['click1', 'click2'],
          },
          {
            id: 'b',
            image: 'https://nitter.hu/pic/media%2FE7Y3MUfUUAYcD9A.jpg%3Fname%3Dsmall',
            title: 'CItem 2',
            description: 'CDescription 2',
            buttons: ['b3', 'b4'],
          },
        ],
      } as IPreviewCarouselItem,
    ],
  },
];

const first = dayjs().subtract(2, 'd').valueOf();

export const moreMessageMock = {
  previousRemain: 0,
  messages: [
    {
      id: 'gdfgdfg23423',
      eventType: 'message',
      messageType: 'text',
      sender: 'agent',
      senderName: 'def',
      text: 'ดีวันนี้',
      createdAt: dayjs(first).add(1, 'd').valueOf(),
    },
    {
      id: 'dgdg234234',
      eventType: 'message',
      messageType: 'text',
      sender: 'user',
      senderName: 'def',
      text: 'ดีวันนี้ 20 วิ',
      createdAt: dayjs(first).add(1, 'd').add(20, 's').valueOf(),
    },
    {
      id: 'dbdfb567567',
      eventType: 'message',
      messageType: 'text',
      sender: 'agent',
      senderName: 'def',
      text: 'ดีดีวันนี้ 20 วิ',
      createdAt: dayjs(first).add(1, 'd').add(1, 'h').add(20, 's').valueOf(),
    },
  ],
  nextRemain: 0,
};

export const beforeMessageMock = {
  previousRemain: 0,
  messages: [
    {
      id: '4564567457457',
      eventType: 'message',
      messageType: 'text',
      sender: 'agent',
      senderName: 'def',
      text: 'ดีอีกแล้วจ่ะเมื่อวานของเมื่อวานซืน',
      createdAt: dayjs(first).subtract(3, 'd').valueOf(),
    },
    {
      id: '124125555',
      eventType: 'message',
      messageType: 'text',
      sender: 'user',
      senderName: 'def',
      text: 'ดีๆนะจ๊ะเมื่อวานซืน',
      createdAt: dayjs(first).subtract(2, 'd').valueOf(),
    },
    {
      id: '1213412412',
      eventType: 'message',
      messageType: 'text',
      sender: 'agent',
      senderName: 'def',
      text: 'ดีเมื่อวาน',
      createdAt: dayjs(first).subtract(1, 'd').valueOf(),
    },
  ],
  nextRemain: 0,
};

export const messagesMock = {
  previousRemain: 3,
  nextRemain: 0,
  messages: [
    // new TicketEvent({
    //   text: 'Apiniya Saetang opened Ticket #122334455667788',
    //   createdAt: first,
    //   eventType: 'ticket',
    // }),
    // {
    //   id: '1',
    //   eventType: 'message',
    //   messageType: 'text',
    //   sender: 'agent',
    //   senderName: 'def',
    //   text: 'สวัสดี1',
    //   createdAt: first,
    // },
    // {
    //   id: '2',
    //   eventType: 'message',
    //   messageType: 'text',
    //   sender: 'agent',
    //   senderName: 'def',
    //   text: 'สวัสดี2',
    //   createdAt: second,
    // },
    // {
    //   id: '3',
    //   eventType: 'message',
    //   messageType: 'text',
    //   sender: 'agent',
    //   senderName: 'def',
    //   text: 'สวัสดี3',
    //   createdAt: third,
    // },
    // {
    //   id: '4',
    //   eventType: 'message',
    //   messageType: 'text',
    //   sender: 'agent',
    //   senderName: 'def',
    //   text: 'สวัสดี4',
    //   createdAt: fourth,
    // },
    // {
    //   id: '5',
    //   eventType: 'message',
    //   messageType: 'text',
    //   sender: 'agent',
    //   senderName: 'def',
    //   text: 'สวัสดี5',
    //   createdAt: five,
    // },
    // {
    //   id: '6',
    //   eventType: 'message',
    //   messageType: 'text',
    //   sender: 'user',
    //   senderName: 'usernaja',
    //   text: 'สวัสดี6 จาก user',
    //   createdAt: six,
    // },
    // {
    //   id: '7',
    //   eventType: 'message',
    //   messageType: 'image',
    //   sender: 'user',
    //   senderName: 'usernaja',
    //   url: 'https://picsum.photos/200/300',
    //   createdAt: seven,
    // },

    // {
    //   id: '9',
    //   eventType: 'message',
    //   messageType: 'image',
    //   sender: 'agent',
    //   senderName: 'agent',
    //   url: 'https://picsum.photos/200/300',
    //   createdAt: nine,
    // },
    // {
    //   id: '10',
    //   eventType: 'message',
    //   messageType: 'message',
    //   sender: 'agent',
    //   senderName: 'agent',
    //   text: 'ดีจ้า',
    //   createdAt: ten,
    // },
    // {
    //   id: '11',
    //   eventType: 'message',
    //   messageType: 'carousel',
    //   sender: 'agent',
    //   senderName: 'agent',
    //   items: [
    //     {
    //       id: '1',
    //       img: 'https://picsum.photos/200/300',
    //       title: 'Hello 1',
    //       description: 'testestesst',
    //       buttons: ['btn 1', 'btn 2'],
    //     },
    //     {
    //       id: '2',
    //       img: 'https://picsum.photos/200/300',
    //       title: 'Hello 2',
    //       description: 'testestesst',
    //       buttons: ['btn 1', 'btn 2'],
    //     },
    //     {
    //       id: '3',
    //       img: 'https://picsum.photos/200/300',
    //       title: 'Hello 3',
    //       buttons: ['btn 1', 'btn 2'],
    //     },
    //   ],
    //   createdAt: ten,
    // },
    {
      id: '12',
      eventType: 'message',
      messageType: 'video',
      sender: 'agent',
      senderName: 'agent',
      url: 'https://upload.convolab.ai/phet-dev_1631197720642_content.mp4',
    },
    {
      id: '13',
      eventType: 'message',
      messageType: 'video',
      sender: 'agent',
      senderName: 'agent',
      url: 'https://upload.convolab.ai/phet-dev_1631197720642_content.mp4',
    },
  ],
};

export const people = [
  {
    teamName: 'Team A',
    members: [
      {
        _id: '1',
        username: 'Agent A',
        img: 'https://picsum.photos/200/300',
      },
    ],
  },
  {
    teamName: 'Team B',
    members: [
      {
        _id: '1',
        username: 'Agent B',
        img: 'https://picsum.photos/200/300',
      },
    ],
  },
];

export const teams = [
  {
    id: '1',
    teamName: 'Team A',
  },
  {
    id: '2',
    teamName: 'Team B',
  },
];
