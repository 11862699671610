import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const NoPaddingRow = styled.div`
  padding: 0;
`;

export const Container = styled.div`
  height: ${`${window.innerHeight}px`};
  width: 100%;
`;

export const ContentBox = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: auto;
`;

export const BottomBox = styled.div`
  width: 100%;
  height: 100px;
  text-align: center;
`;

export const HeaderBox = styled.div`
  padding: 16px;
  height: 60px;
`;

export const InnerContentBox = styled.div`
  padding: 16px;
  height: inherit;
`;

export const ButtonBox = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

export const TextCenterBox = styled.div`
  text-align: center;
`;

export const AutoSaveBox = styled.div`
  margin: 8px;
`;

export const SecondaryFontAwesome = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.GREY_5};
`;
