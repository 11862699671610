import { IChannel } from '@types';
import { filterOutIfExistAddIfNot } from '@utils/checkOrUnCheck';
import { useState } from 'react';

export const useChannelSelector = (outerSelect: IChannel[] = []) => {
  const [innerSelect, setInnerSelect] = useState<IChannel[]>(outerSelect);
  return {
    innerSelect,
    onInnerSelect: (value: IChannel) => {
      const result = filterOutIfExistAddIfNot(innerSelect, value);
      setInnerSelect(result);
    },
    onClearAll: () => {
      setInnerSelect([]);
    },
    preserveOuter: (outer: IChannel[]) => {
      setInnerSelect(outer);
    },
  };
};
