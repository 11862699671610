import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  word-break: break-word;
  overflow: hidden auto;
  position: relative;
  contain: paint;
`;

export const ListContainer = styled.div`
  position: relative;
`;

export const LoadingWrap = styled.div``;
