import React from 'react';
import { Container } from './style';

export type ITextMessage = {
  side: 'left' | 'right';
  type: 'first' | 'middle' | 'last';
};

export const TextMessage: React.FC<React.PropsWithChildren<ITextMessage>> = ({
  side,
  type,
  children,
  ...rest
}: React.PropsWithChildren<ITextMessage>) => {
  return (
    <Container side={side} type={type} {...rest}>
      {children}
    </Container>
  );
};
