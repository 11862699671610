export const ROUTE = {
  OPERATIONAL_DASHBOARD: '/operational-dashboard',
  LOGIN: '/login',
  RECENTS: '/recents',
  TICKET_MANAGEMENT: '/ticket-management',
  TEAM_MANAGEMENT: '/team-management',
  SETTINGS: '/settings',
  REPORT: '/report',
  REPORT_WITH_ID: '/report/:id',
  CHANGE_PASSWORD: '/change-password',
  GENERAL_SETTING: '/settings/general',
  TICKET_TAG: '/settings/ticket-tag',
  TEMPLATE_COLLECTION: '/settings/template-collection',
  TEMPLATE_COLLECTION_NEW: '/settings/template-collection/new',
  TEMPLATE_COLLECTION_EDIT: '/settings/template-collection/:id',
  TICKET_WRAP_UP: '/settings/ticket-wrap-up',
  TICKET_WRAP_REASON_CATEGORY_EDITOR: '/settings/ticket-wrap-up/category-reason',
  TICKET_WRAP_REASON_CATEGORY_EDITOR_NEW: '/settings/ticket-wrap-up/category-reason/new',
  TICKET_WRAP_REASON_CATEGORY_EDITOR_EDIT: '/settings/ticket-wrap-up/category-reason/:id',
  CUSTOMER_SATISFACTION: '/settings/customer-satisfaction',
  CUSTOMER_SATISFACTION_CUSTOMIZATION_NEW: '/settings/customer-satisfaction/survey-customization/new',
  CUSTOMER_SATISFACTION_CUSTOMIZATION_EDIT: '/settings/customer-satisfaction/survey-customization/:id',
} as const;
