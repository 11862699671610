import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .outcome {
    width: 100%;
  }
  .outcome span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Counter = styled.div`
  text-align: right;
`;
