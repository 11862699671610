import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { RangeValue, EventValue } from 'rc-picker/lib/interface';
import { CustomDivider, PickerCell, PickerTodayCell, RangePicker, Text, TextBox } from './style';
import useDateRangePicker, { DateRange } from './useDateRangePicker';

import './style.css';

export type IDateRangePickerProps = {
  value: DateRange;
  onDateRangeChange: (range: DateRange) => void;
  disabledDate?: (current: Moment) => boolean;
  ranges?: Record<string, [EventValue<Moment>, EventValue<Moment>]>;
  picker?: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year';
};

const DateRangePicker = ({ value = {}, disabledDate, onDateRangeChange, ...rest }: IDateRangePickerProps) => {
  const rangeValue = [value.from ? moment(value.from) : null, value.to ? moment(value.to) : null] as RangeValue<Moment>;
  const { handleOnDateCalendarChange } = useDateRangePicker({ onDateRangeChange });
  const _renderDate = (current: Moment, today: Moment) => {
    if (current.date() === today.date() && current.month() === today.month() && current.year() === today.year()) {
      return (
        <Row justify={'center'}>
          <Col>
            <PickerTodayCell className={'ant-picker-cell-inner'}>{current.date()}</PickerTodayCell>
          </Col>
        </Row>
      );
    } else {
      return <PickerCell className={'ant-picker-cell-inner'}>{current.date()}</PickerCell>;
    }
  };
  const _renderPanel = (node: ReactNode) => {
    return (
      <>
        <Row justify={'center'} align={'middle'}>
          <Col>
            <TextBox>
              <Text level={4}>
                {!rangeValue?.[0] && !rangeValue?.[1] ? (
                  'Select Range'
                ) : (
                  <>
                    {rangeValue?.[0]?.format('DD MMM')}
                    {(rangeValue?.[0] || rangeValue?.[1]) && '  -  '}
                    {rangeValue?.[1]?.format('DD MMM')}
                  </>
                )}
              </Text>
            </TextBox>
          </Col>
        </Row>
        <CustomDivider />
        {node}
      </>
    );
  };
  return (
    <RangePicker
      value={rangeValue}
      onCalendarChange={handleOnDateCalendarChange}
      dropdownClassName={'amity-daterange-picker'}
      panelRender={_renderPanel}
      dateRender={_renderDate}
      disabledDate={disabledDate}
      {...rest}
    />
  );
};

export default React.memo(DateRangePicker);
