export const TAG_ERROR_MAPPING: {
  [key: string]: {
    title: string;
    description: string;
  };
} = {
  'Unique constraint failed. (TicketTag_networkId_value_key)': {
    title: 'Tag already existing',
    description: 'Please check and change the name tag',
  },
};
export const MAX_INPUT = 20;
