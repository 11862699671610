export enum ESocketEventTopics {
  ERROR = 'error',
  RECONNECT = 'reconnect',
  MESSAGE_BOX_MESSAGE_REALTIME = 'messageRealtime',

  USERLIST_TICKET = 'ticket',
  USERLIST_LAST_MESSAGE_REALTIME = 'lastMessageRealtime',
  USERLIST_TICKET_REMOVE = 'ticketRemove',
  USERLIST_AGENT_READ = 'agentRead',
  USERLIST_FILTER_TICKET = 'filterTicket',
  USERLIST_DISPLAYNAME_UPDATE = 'users.displayName.updated',
  USERLIST_DISPLAYNAME_DELETE = 'users.displayName.deleted',
}
