import React from 'react';
import { ModalProps } from 'antd';
import { ModalContainer } from './style';

export type IAlertModal = React.PropsWithChildren<ModalProps>;

export const AlertModal: React.FC<IAlertModal> = ({ children, ...rest }: IAlertModal) => {
  return <ModalContainer {...rest}>{children}</ModalContainer>;
};

export default AlertModal;
