import _ from 'lodash';
import { useReducer, useRef } from 'react';

export default () => {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const [, _forceUpdate] = useReducer((x) => x + 1, 0);

  const forceUpdate = (delay?: number) => {
    let millis = 0;
    if (delay && _.isNumber(delay)) {
      millis = delay;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(_forceUpdate, millis || 0);
  };
  return forceUpdate;
};
