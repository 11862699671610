import React from 'react';
import { useState, useEffect } from 'react';

export const useMaxWidth = <T>(trigger: T) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState<number | null>(null);
  useEffect(() => {
    if (divRef.current) {
      setMaxWidth(divRef.current.clientWidth);
    }
  }, [divRef, trigger]);
  return {
    divRef,
    maxWidth,
  };
};
