import React, { useContext, createContext } from 'react';
import useMemberProvider from './useMemberProvider';
import { IMember } from '@types';
import LoadingCircle from '@components/Loading';
import { LoadingContainer } from './style';

type IMemberProvider = ReturnType<typeof useMemberProvider>;

type IMemberProviderProps = {
  initialData?: IMember;
  [key: string]: any;
};

export const MemberContext = createContext<IMemberProvider>({} as IMemberProvider);

export const useMember = () => useContext(MemberContext);

const MemberProvider: React.FC<IMemberProviderProps> = ({ initialData, children }: IMemberProviderProps) => {
  const api = useMemberProvider(initialData ?? ({} as IMember));

  const _renderPage = () => {
    if (api.isLoading) {
      return (
        <LoadingContainer>
          <LoadingCircle />
        </LoadingContainer>
      );
    }

    return children;
  };
  return <MemberContext.Provider value={api}>{_renderPage()}</MemberContext.Provider>;
};

export default MemberProvider;
