import { useEffect, useRef, useState } from 'react';
import { searchTag } from '@api/livechat/Tag';
import { DEBOUNCE_TIME, TAG_API_MAX_TAKE } from '@configs/constants';
import { ITagResponse, ITicketTagConsole } from '@types';
import { generateDropdownOption } from '@utils/getDropdownArray';
import { useDebounce } from 'use-lodash-debounce';
import { useCancelTokenSource } from './useCancelToken';
import axios from 'axios';

export const useDropdownTag = () => {
  const { newCancelTokenSource } = useCancelTokenSource();
  const isInit = useRef(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const debouncedSearchTxt = useDebounce(searchKeyword, DEBOUNCE_TIME);
  const [selectedTags, setSelectedTags] = useState<ITicketTagConsole[]>([]);
  const [dropdownTagsInfo, setDropdownTagsInfo] = useState({
    isFirstLoading: false,
    isError: false,
    data: [] as ITicketTagConsole[],
    cursorId: '',
    hasMore: false,
  });

  useEffect(() => {
    const cancelToken = newCancelTokenSource();
    const searchTagsByKeyword = async () => {
      try {
        // reset filter
        setDropdownTagsInfo(() => {
          return {
            data: [],
            isFirstLoading: true,
            isError: false,
            cursorId: '',
            hasMore: false,
          };
        });
        const result = await searchTag(
          {
            keyword: debouncedSearchTxt,
            cursorId: '',
            take: TAG_API_MAX_TAKE + 1,
          },
          cancelToken,
        );
        const ticketTagsConsole: ITicketTagConsole[] = genTicketTagConsole(result.data);
        const newRow = generateDropdownOption<ITicketTagConsole>(ticketTagsConsole, {
          labelKey: 'value',
          valueKey: 'id',
        });
        updateTagListDropdownData(newRow);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setDropdownTagsInfo((prev) => {
            return {
              ...prev,
              isFirstLoading: false,
              isError: true,
              cursorId: '',
              hasMore: false,
            };
          });
        }
      }
    };
    if (isInit.current) {
      searchTagsByKeyword();
    }
  }, [debouncedSearchTxt, isInit]);

  const updateTagListDropdownData = (newRow: ITicketTagConsole[]) => {
    setDropdownTagsInfo((prev) => {
      return {
        data: [...prev.data, ...newRow],
        isFirstLoading: false,
        isError: false,
        cursorId: newRow.length === TAG_API_MAX_TAKE + 1 ? newRow[newRow.length - 1].id : '',
        hasMore: newRow.length > TAG_API_MAX_TAKE,
      };
    });
  };

  useEffect(() => {
    // re computed dropdown list if selectedTags change from external
    setDropdownTagsInfo((prev) => {
      return {
        ...prev,
        data: prev.data.map((tag) => {
          const isSelected = selectedTags?.some((tagItem) => tagItem.id === tag.id);
          return {
            ...tag,
            selected: isSelected,
          };
        }),
      };
    });
  }, [selectedTags]);

  const genTicketTagConsole = (data: ITagResponse[]) => {
    const result = data.map((item: ITagResponse) => {
      const isSelected = selectedTags?.some((tag) => tag.id === item.id);
      return {
        id: item.id,
        value: item.value,
        label: item.value,
        selected: isSelected,
        loading: false,
      };
    });
    return result;
  };

  return {
    dropdownTagsInfo,
    searchKeyword,
    onUpdateSearchKeyword: (keyword: string) => {
      setSearchKeyword(keyword);
    },
    updateSelectedTags: (tagInps: ITicketTagConsole[]) => {
      setSelectedTags(tagInps);
    },
    updateTagListDropdownData,
    onLoadMore: async () => {
      try {
        const cancelToken = newCancelTokenSource();
        setDropdownTagsInfo((prev) => {
          return {
            ...prev,
          };
        });
        const result = await searchTag({
          keyword: searchKeyword,
          cursorId: dropdownTagsInfo.cursorId,
          take: TAG_API_MAX_TAKE + 1,
        });
        const ticketTagsConsole: ITicketTagConsole[] = genTicketTagConsole(result.data);
        const newRow = generateDropdownOption<ITicketTagConsole>(ticketTagsConsole, {
          labelKey: 'value',
          valueKey: 'id',
        });
        updateTagListDropdownData(newRow);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setDropdownTagsInfo((prev) => {
            return {
              ...prev,
              isError: true,
              cursorId: '',
              hasMore: false,
            };
          });
        }
      }
    },
    onGetAvailableTags: async () => {
      try {
        const cancelToken = newCancelTokenSource();
        // reset tag
        setDropdownTagsInfo(() => {
          return {
            data: [],
            isFirstLoading: true,
            isError: false,
            cursorId: '',
            hasMore: false,
          };
        });
        const result = await searchTag(
          {
            take: TAG_API_MAX_TAKE + 1,
          },
          cancelToken,
        );
        const ticketTagsConsole: ITicketTagConsole[] = genTicketTagConsole(result.data);
        const newRow = generateDropdownOption<ITicketTagConsole>(ticketTagsConsole, {
          labelKey: 'value',
          valueKey: 'id',
        });
        // inited
        isInit.current = true;
        updateTagListDropdownData(newRow);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setDropdownTagsInfo((prev) => {
            return {
              ...prev,
              isFirstLoading: false,
              isError: true,
              cursorId: '',
              hasMore: false,
            };
          });
        }
      }
    },
  };
};
