import { CombineAllMessage } from '@model/MessageItem';
import { SocketMessage } from '@types';
import dayjs from 'dayjs';
import { isPersonMessage } from './messageGenerator';

export const findMessage = (message: SocketMessage, messages: CombineAllMessage[]) => {
  for (const item of messages) {
    if (item instanceof isPersonMessage(item) && item.isAgent()) {
      const found = item.createdDate === dayjs(message.createdAt).valueOf();
      if (found) return true;
    }
  }
  return false;
};
