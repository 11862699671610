import { ITicketTag, ITicketTagConsole } from '@types';

export const generateSelectedTag = (tags: ITicketTag[]): ITicketTagConsole[] => {
  return tags.map((item) => {
    return {
      ...item,
      label: item.value,
      selected: true,
      loading: false,
    };
  });
};
