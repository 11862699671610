import React from 'react';
import { Login as LoginComponent } from '@components/AuthComponent/Login';
import { useMember } from '@providers/MemberProvider';

const Login = () => {
  const { cognitoLoading, errorMessage, onLogin } = useMember();

  return (
    <>
      <LoginComponent
        loading={cognitoLoading}
        errorMessage={errorMessage}
        onLogin={onLogin}
        onForgotPassword={() => false}
        appVersion={`Version ${__VERSION__}`}
      />
    </>
  );
};

export default Login;
