import styled from 'styled-components/macro';

export const EmptyContainer = styled.div`
  height: calc(100vh - 518px);
`;

export const Content = styled.div`
  word-break: break-word;
  padding-bottom: 24px;
`;

export const CustomFieldDivider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.GREY_7};
  margin: 16px 0 0 0;
`;

export const ImageField = styled.img`
  max-width: 240px;
  max-height: 240px;
`;
