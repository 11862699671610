import _ from 'lodash';
import type { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

export interface DateRange {
  to?: Date;
  from?: Date;
}
type DateRangePickerConfig = {
  onDateRangeChange: (value: DateRange) => void;
};
const useDateRangePicker = ({ onDateRangeChange }: DateRangePickerConfig) => {
  const handleOnDateCalendarChange = (value?: RangeValue<Moment>) => {
    let ret: DateRange = {};
    if (value) {
      const [from, to] = value;
      ret = _.omitBy(
        {
          from: from?.startOf('day').toDate() ?? undefined,
          to: to?.endOf('day').toDate() ?? undefined,
        },
        _.isNil,
      );
    }
    onDateRangeChange(ret);
  };

  return {
    handleOnDateCalendarChange,
  };
};

export default useDateRangePicker;
