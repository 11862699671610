import React from 'react';
import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';

const { ItemGroup: AntdItemGroup } = AntdMenu;

type IItemGroupProps = {
  title: string;
};

const Container = styled(AntdItemGroup)`
  .ant-menu-item-group-title {
    color: white;
    background: transparent !important;
    font-size: 0.875rem;
    padding-left: 1.1rem !important;
  }

  .ant-menu-item div {
    height: 2.5rem;
  }
`;

const ItemGroup: React.FC<React.PropsWithChildren<IItemGroupProps>> = ({
  title,
  children,
  ...props
}: React.PropsWithChildren<IItemGroupProps>) => {
  return (
    <Container title={title} {...props}>
      {children}
    </Container>
  );
};

export default ItemGroup;
