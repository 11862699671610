import React, { useContext, createContext } from 'react';
import useMessageBoxHook from './useMessageBox';
import { IMember, ITicket, IUser } from '@types';

export const MessageBoxContext = createContext<ReturnType<typeof useMessageBoxHook>>(
  {} as ReturnType<typeof useMessageBoxHook>,
);

export const useMessageBox = () => useContext(MessageBoxContext);

export type IMessageBoxOptions = {
  isSocket: boolean;
  isTicketManagementMode: boolean;
  asReader?: boolean;
};

export type IMessageBoxProvider = {
  ticket: ITicket;
  member: IMember;
  user: IUser;
  messageTime: number | null;
  options?: IMessageBoxOptions;
  children: React.ReactNode;
};

const MessageBoxProvider: React.FC<IMessageBoxProvider> = ({
  ticket,
  user,
  messageTime,
  member,
  options,
  children,
}: IMessageBoxProvider) => {
  return (
    <MessageBoxContext.Provider value={useMessageBoxHook(ticket, messageTime, member, user, options)}>
      {children}
    </MessageBoxContext.Provider>
  );
};

export default MessageBoxProvider;
