import styled from 'styled-components/macro';
import { GhostButton } from '@components/Button';

export const IconButton = styled(GhostButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 40px !important;
  min-width: 40px !important;
  background: transparent;
  svg {
    font-size: 20px;
  }
`;

export const TextAreaContainer = styled.div`
  flex: 1;
  textarea {
    width: 100%;
    min-height: 40px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
`;

export const SendBox = styled.div``;
export const MessageComposerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
