import React from 'react';
import { uploadFile } from '@api/upload';
import { ImageMapMessage } from '@components/ImageMapMessage';
import EChannelType from '@enums/ChannelType';
import { IImageUploadedParams, ImageUploadProvider } from '@providers/ImageUploadProvider';
import { useMember } from '@providers/MemberProvider';
import { RcFile } from 'antd/lib/upload';
import { IUpdateSurveyFnSignature } from '../../CustomerSatisfactionEditor/useCustomerSatisfactionEditor';
import { ISurvey } from '@types';
import { v4 as uuid } from 'uuid';
type IImageMapSurveyProps = {
  imagemap: ISurvey['message']['imageMap'];
  channelType: EChannelType;
  onUpdateSurvey: IUpdateSurveyFnSignature;
};

export const ImageMapSurvey = ({ imagemap, channelType, onUpdateSurvey }: IImageMapSurveyProps) => {
  const { member } = useMember();
  return (
    <div className="w-full">
      <div className="w-full rounded-[16px] shadow-custom p-[24px] bg-white transition-all">
        <ImageUploadProvider
          uploadFn={(currentFile: RcFile) => {
            const formData = new FormData();
            formData.append('nid', member.nid);
            formData.append('channelType', String(channelType));
            formData.append('fileCount', '1');
            formData.append('file', currentFile);
            return uploadFile(formData);
          }}
        >
          <ImageMapMessage
            imagemap={imagemap}
            maxRegions={10}
            onChangeImageMapMode={(mode: any) => {
              return;
            }}
            onUploadedImage={async ({ url }: IImageUploadedParams) => {
              const image = new Image();
              image.src = url;
              image.onload = () => {
                const newHeight = Math.round((image.height * 1040) / image.width);
                onUpdateSurvey('message.imageMap', {
                  ...imagemap!,
                  baseSize: {
                    width: 1040,
                    height: newHeight,
                  },
                  imgUrl: url,
                  baseUrl: url,
                  imgHeight: newHeight,
                  imgWidth: 1040,
                });
              };
            }}
            onRemoveImageUrl={() => {
              return;
            }}
            onConfirmMapAction={(regions) => {
              onUpdateSurvey('message.imageMap', {
                ...imagemap!,
                actions: regions.map((region) => {
                  return {
                    id: uuid(),
                    type: 'imagemap',
                    area: {
                      height: region.height,
                      width: region.width,
                      x: region.x,
                      y: region.y,
                    },
                    message: region.data.btn.message,
                    point: region.data.btn.point,
                  };
                }),
              });
            }}
          />
        </ImageUploadProvider>
      </div>
    </div>
  );
};
