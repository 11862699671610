import React from 'react';
import { BOTS } from '@types';
import { Container, Item } from './style';

export type IImageCarouselPreviewProps = {
  carousel: BOTS.IImageCarouselApi;
};

export const ImageCarouselPreview = ({ carousel }: IImageCarouselPreviewProps) => {
  return (
    <Container>
      {carousel.columns.map((column) => {
        return (
          <Item key={column.id}>
            <img src={column.imageUrl} />
            <button>
              <span>{column.action.label}</span>
            </button>
          </Item>
        );
      })}
    </Container>
  );
};
