import { IMember, ITicket } from '@types';

export const isTicketOpen = (ticket: ITicket) => {
  return ticket.status === 'open';
};

export const isTicketResolved = (ticket: ITicket) => {
  return ticket.status === 'resolved';
};

export const isNotMyTicket = (ticket: ITicket, member: IMember) => {
  return ticket.status === 'assigned' && ticket.agentId !== member._id;
};
