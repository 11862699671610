import React from 'react';
import SearchInput from '@components/SearchInput';
import Table, { IColumns } from '@components/Table';
import TableList from '@components/TableList';
import { Font16G1W600 } from '@components/UtilsComponent';
import { useTable } from '@hooks/useTable';
import { tableListGenerator } from '@utils/tableListGenerator';
import { fetchTagHandler } from './fns/fetchTagHandler';
import TextInput from '@components/TextInput';
import { GhostButton, PrimaryButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit, faPlus, faTimes, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import useTranslation from '@hooks/useTranslation';
import { formatDate } from '@utils/day';
import { Spin, Tooltip } from 'antd';
import { LoadingWrap } from '../style';
import LoadingCircle from '@components/Loading';
import { useInput } from '@hooks/useInput';
import { TAG_CONSTRAINT_REGEX } from '@configs/regex';
import { useTicketTag } from './useTicketTag';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { ITagResponse } from '@types';
import { MAX_INPUT } from '@configs/tag';
import { DeleteModal } from '@components/Modal';
import { useDeleteTag } from './useDeleteTag';

export const TicketTagManagement = () => {
  const t = useTranslation();
  const { selectForDelete, deleteMut, confirmModal, onSelectForDelete, onDeleteTag } = useDeleteTag();
  const { query, keyword, isDisabledPrev, onUpdateKeyword, onNext, onPrev, onUpdateCursorId, onUpdateCurrentPage } =
    useTable({
      fetchFn: async (keyword, cursorId, currentPageEvent, currentPage) => {
        const result = await tableListGenerator({
          ...(await fetchTagHandler(keyword, cursorId, currentPageEvent, currentPage)),
        });
        onUpdateCursorId(result?.nextCursorId ?? '', result?.prevCursorId ?? '');
        onUpdateCurrentPage(result?.currentPage ?? 1);
        return result;
      },
      debounceTime: 500,
    });

  const { value, onChange, onClear } = useInput(TAG_CONSTRAINT_REGEX);
  const {
    updateTagMut,
    selectedTag,
    addTagMut,
    onAddTag,
    onSelectEdit,
    onConfirmEdit,
    onCancelEdit,
    onUpdateSelected,
  } = useTicketTag();

  const generateColumns = (): IColumns[] => [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 70,
      render: (_, record: ITagResponse, index: number) => {
        if (selectedTag.index === index) {
          return (
            <div className="flex items-center gap-x-2">
              <button className="disabled:cursor-not-allowed disabled:opacity-50" disabled={!selectedTag.value?.value}>
                <FontAwesomeIcon
                  onClick={() => {
                    if (!selectedTag.value?.value) return;
                    onConfirmEdit(async () => {
                      await query.refetch();
                    });
                  }}
                  icon={faCheck}
                  className={`text-[#29CB72] text-[16px]`}
                />
              </button>

              <FontAwesomeIcon
                onClick={onCancelEdit}
                icon={faTimes}
                className="text-[#F72C40] text-[16px] cursor-pointer"
              />
            </div>
          );
        }
        return (
          <div>
            <FontAwesomeIcon
              onClick={() =>
                onSelectEdit({
                  index,
                  value: record,
                })
              }
              icon={faEdit}
              className="text-[16px] text-[#BDBDBD] cursor-pointer"
            />
          </div>
        );
      },
    },
    {
      title: (
        <div className="flex items-center gap-x-2">
          TAG NAME
          <Tooltip title={`(Maximum 20 characters, can contain a-z, A-Z, ก-ฮ, 0-9, _ ,- )`}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'value',
      key: 'value',
      render: (_, record: ITagResponse, index: number) => {
        if (selectedTag.index === index) {
          return (
            <input
              className="border-b-[1px] bg-transparent outline-none"
              maxLength={MAX_INPUT}
              onChange={(e) => onUpdateSelected(e.target.value)}
              value={selectedTag.value?.value}
            />
          );
        }
        return <div className="border-b-[1px] border-transparent">{record.value}</div>;
      },
    },
    {
      title: 'LAST MODIFY DATE & TIME',
      dataIndex: '',
      key: '',
      render: (_, record) => {
        return <div>{formatDate(record.updatedAt, 'MMM DD, YYYY HH:mm')}</div>;
      },
    },
    {
      title: 'LAST MODIFY NAME',
      dataIndex: 'modifiedBy',
      key: 'modifiedBy',
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 80,
      render: (_, record: ITagResponse) => (
        <div>
          <GhostButton
            onClick={() => {
              onSelectForDelete(record);
              confirmModal.onToggle();
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="text-[16px] text-[#757575] mt-[2px]" />
          </GhostButton>
        </div>
      ),
    },
  ];

  return (
    <Spin
      spinning={query.isFetching || addTagMut.isLoading || updateTagMut.isLoading || deleteMut.isLoading}
      indicator={
        <LoadingWrap data-testid="test-loading-wrap">
          <LoadingCircle />
        </LoadingWrap>
      }
    >
      <div className="flex justify-between mt-[32px] mb-[8px]">
        <div className="w-[300px]">
          <SearchInput
            value={keyword}
            size="large"
            onClear={function (): void {
              onUpdateKeyword('');
            }}
            onChange={(e) => onUpdateKeyword(e.target.value)}
            placeholder={t('ticket.tag.list.search.placeholder')}
          />
        </div>
        <div className="flex gap-x-[8px]">
          <div className="w-[400px]">
            <TextInput
              value={value}
              onChange={onChange}
              size="large"
              maxLength={MAX_INPUT}
              placeholder={t('ticket.tag.list.add.tag.placeholder')}
            />
            <p className="text-[12px] font-normal text-[#9E9E9E] mt-[4px] ">
              {t('ticket.tag.list.add.tag.description')}
            </p>
          </div>
          <PrimaryButton
            onClick={() => {
              onAddTag(value, async () => {
                await query.refetch();
                onClear();
              });
            }}
            disabled={!value}
            icon={<FontAwesomeIcon icon={faPlus} className="mr-[8px]" />}
            size="large"
          >
            {t('ticket.tag.list.add.tag.btn')}
          </PrimaryButton>
        </div>
      </div>
      <TableList
        next={{
          onClick: onNext,
          disabled: query.data?.disabledNext || false,
        }}
        prev={{
          onClick: onPrev,
          disabled: isDisabledPrev,
        }}
      >
        <Table
          header={
            <div className="mb-[24px]">
              <Font16G1W600>{t('ticket.tag.list.table.title')}</Font16G1W600>
            </div>
          }
          data={query.data?.items ?? []}
          columns={generateColumns()}
          pagination={false}
          scroll={{ y: 'calc(100vh - 440px)' }}
        />
      </TableList>
      <DeleteModal
        visible={confirmModal.visible}
        isLoading={deleteMut.isLoading}
        handleConfirm={() =>
          confirmModal.onConfirm(async () => {
            if (!selectForDelete) return;
            await onDeleteTag(selectForDelete);
            confirmModal.onToggle();
            onSelectForDelete(null);
            onSelectEdit({
              index: null,
              value: null,
            });
            await query.refetch();
          })
        }
        handleClose={() => {
          if (deleteMut.isLoading) return;
          onSelectForDelete(null);
          confirmModal.onToggle();
        }}
        cancelBtnTxt={t('cancel.btn')}
        confirmBtnTxt={t('confirm.btn')}
      >
        <span>
          {t('ticket.tag.delete.confirm.title', {
            name: <Font16G1W600>{selectForDelete?.value || '-'}</Font16G1W600>,
          })}
        </span>
      </DeleteModal>
    </Spin>
  );
};
