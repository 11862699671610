import { io, Socket } from 'socket.io-client';
import * as Sentry from '@sentry/react';
import { ESocketEventTopics } from '@enums/Socket';
type IQuery = {
  [key: string]: any;
};

const defaultUrl = import.meta.env.VITE_APP_SOCKET_ENDPOINT;

class SocketIO {
  private socketIo: Socket;
  constructor(url: string, query: IQuery, token: string) {
    // get custom cookie
    this.socketIo = io(`${defaultUrl}/${url}`, {
      path: '/socket/',
      transports: ['websocket'],
      reconnectionDelay: 5000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      forceNew: true,
      query: query,
      auth: {
        token: token ?? '',
        offset: undefined,
      },
      withCredentials: true,
      secure: false,
    });

    this.socketIo.on(ESocketEventTopics.ERROR, (err) => {
      Sentry.captureException(`socket error`, err);
    });

    this.socketIo.on(ESocketEventTopics.RECONNECT, () => {
      Sentry.captureException(`socket reconnect`);
    });
  }

  get getIoManager() {
    return this.socketIo.io;
  }

  public disconnect(): void {
    this.socketIo.disconnect();
  }

  public getSocket(): Socket {
    return this.socketIo;
  }

  public setOffset(offset?: string) {
    if (!offset) return;
    (this.socketIo.auth as { offset?: string }).offset = offset;
  }

  public on<T>(topic: string, cb: (payload: T) => void): void {
    this.socketIo.on(topic, (payload) => {
      Sentry.captureMessage(`new socket event from topic ${topic}`, payload);
      cb(payload);
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public off<T>(topic: string, listener?: (payload: T) => void): void {
    this.socketIo.off(topic, listener);
  }
}

export default SocketIO;
