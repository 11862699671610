import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  position: relative;
`;

export const FlexContent = styled.div`
  width: 300px;
`;
