import React, { useMemo } from 'react';
import ReactPlayer from 'react-player';
import { Container } from './style';

export type IAudioPlayerMessage = {
  url: string;
  width?: string | number;
  height?: string | number;
};

export const AudioPlayerMessage: React.FC<IAudioPlayerMessage> = ({ url, ...rest }: IAudioPlayerMessage) => {
  const renderVideo = useMemo(() => {
    return (
      <Container>
        <ReactPlayer
          url={url}
          height={57}
          width={300}
          controls
          config={{
            file: {
              forceAudio: true,
            },
          }}
          {...rest}
        />
      </Container>
    );
  }, [url]);
  return renderVideo;
};
