import { getRecentTicketByUserId } from '@api/livechat/EngagementMessage';
import { markReadByTicketId } from '@api/livechat/Ticket';
import { IMember, ITicket } from '@types';
import { IMessageBoxMachineContext } from '../messageBoxMachine';
import { ETicketStatus } from '@enums/TicketStatus';
import { ERole } from '@enums/Role';

/**
 * Api request to mark read recent ticket by user
 *
 * @param {IMessageBoxMachineContext} context context of message box machine
 * @param {IMember} currentMember member (as agent) data in currunt session. get by `useMember()`
 */
export const markReadTicket = async (context: IMessageBoxMachineContext, currentMember: IMember) => {
  const userId = context.userId;
  const recentTicket = await getRecentTicketByUserId(userId);
  const isAdmin = currentMember.roles.includes(ERole.ADMIN);
  if (!recentTicket) {
    return Promise.reject();
  }
  const { _id: ticketId, status, agentId } = recentTicket.data as ITicket;
  if (status === ETicketStatus.RESOLVED) {
    return Promise.resolve();
  }
  if (!isAdmin && agentId !== currentMember._id) {
    return Promise.resolve();
  }

  return markReadByTicketId(ticketId);
};
