import DianaSearchInput from '@components/SearchInput';
import styled from 'styled-components/macro';
import { EDropdownSize } from '.';

export const Container = styled.div<{ size?: EDropdownSize }>`
  overflow: hidden auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 48px;
  background-color: #fff;
  gap: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  border-radius: 8px !important;
  padding: 14px 11px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  ${({ size }) => `
    ${size === EDropdownSize.MIDDLE ? 'min-height: 36px; padding: 0px 11px' : ''}
  `}
`;

export const DisabledContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  background-color: ${({ theme }) => theme.GREY_9};
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-radius: 8px !important;
  padding: 4px 11px;
  cursor: not-allowed;
`;

export const DropdownContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 12px 0;
  box-shadow: 0px 4px 20px 0px rgba(23, 24, 28, 0.15), 0px 0px 2px 0px rgba(23, 24, 28, 0.2);
`;

export const Input = styled.input`
  flex: 1;
  flex-basis: auto;
  min-width: 1ch;
  border: none;
  outline: none;
  ::-webkit-input-placeholder {
    color: #9e9e9e;
  }
`;

export const EmptyWord = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const DropdownItem = styled.div`
  height: 44px;
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
  .ant-col:nth-child(2) {
    max-width: 200px;
  }
`;

export const DropdownItemLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SearchBox = styled.div<{ showBorder: boolean }>`
  padding: 0px 16px 8px 16px;
  border-bottom: 1px solid ${({ showBorder, theme }) => (showBorder ? theme.GREY_7 : 'transparent')};
`;

export const Content = styled.div<{ maxHeight?: number }>`
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '200px')};
  overflow: auto;
`;

export const PlaceholderText = styled.span`
  color: #9e9e9e;
`;

export const SearchInput = styled(DianaSearchInput)`
  height: 36px;
  background: rgba(0, 0, 0, 0.04);
  border: none;
  .ant-input {
    background: transparent;
  }
`;

export const ClearButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  span {
    color: #0f86fe;
  }
  margin-top: 8px;
`;
