import useWindowSize from '@hooks/useWindowSize';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { DEFAULT_CALLPASED, DEFAULT_PATH } from './constants';

const useSideMenu = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(DEFAULT_CALLPASED);
  const [visibleDopdown, setVisibleDropdown] = useState(false);
  const theme: any = useTheme();
  //for set default path
  const [selectedMenu, setSelectedMenu] = useState(DEFAULT_PATH);
  const [selectedSubMenu, setSelectedSubMenu] = useState<string[]>([]);
  const windowSize = useWindowSize();

  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location.pathname]);

  const handleClick = (e: any) => {
    if (e.key !== 'item_0') {
      setSelectedMenu(e.key);
    }
  };

  const onOpenChange = (openKeys: any) => {
    if (!collapsed) {
      setSelectedSubMenu(openKeys);
    }
  };

  const hideDropdown = () => {
    setVisibleDropdown(false);
  };

  const checkCanAccess = (memberRole: string, accessArray: string[]) => {
    return accessArray.includes(memberRole);
  };

  return {
    collapsed,
    visibleDopdown,
    selectedMenu,
    selectedSubMenu,
    windowSize,
    theme,
    setCollapsed,
    setVisibleDropdown,
    setSelectedMenu,
    handleClick,
    onOpenChange,
    hideDropdown,
    checkCanAccess,
  };
};

export default useSideMenu;
