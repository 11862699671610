import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Badge } from './style';
import { truncate } from 'lodash';
import { MAX_LABEL_LIMIT } from './constants';
import { IconName } from '@fortawesome/fontawesome-svg-core';

export type IFilterButton = {
  iconName: IconName;
  label: string;
  value: string;
  count: number;
  onClick?: () => void;
  [key: string]: any;
};

const FilterButton: React.FC<IFilterButton> = ({
  active = false,
  iconName = 'home',
  label = 'label',
  onClick,
  count,
}: IFilterButton) => {
  return (
    <Container active={active} onClick={onClick}>
      <div data-testid={label}>
        {iconName && <FontAwesomeIcon icon={['far', iconName]} />}
        <span>
          {truncate(label, {
            length: MAX_LABEL_LIMIT,
          })}
        </span>
      </div>
      {count >= 0 && <Badge count={count} overflowCount={999} />}
    </Container>
  );
};

export default FilterButton;
