import React from 'react';
import useTranslation from '@hooks/useTranslation';
import FilterButton, { IFilterButton } from '../FilterButton';
import { Container } from './style';
import { useTicketFilter } from './useTicketFilter';
import { ETicketFilterStatus } from '@enums/TicketFilter';

const TicketFilter: React.FC = React.memo(() => {
  const t = useTranslation();
  const { currentFilter, filterItems, handleFilterChange } = useTicketFilter();
  return (
    <Container>
      {filterItems.map((item: IFilterButton) => {
        return (
          <FilterButton
            {...item}
            key={item.label}
            label={t(item.label)}
            active={currentFilter.ticketStatus === item.value}
            onClick={() => handleFilterChange(item.value as ETicketFilterStatus, 'ticketStatus')}
          />
        );
      })}
    </Container>
  );
});

export default TicketFilter;
