import React from 'react';
import { Container } from './style';

export type IImagePreviewProps = {
  url: string;
};

export const ImagePreview = ({ url }: IImagePreviewProps) => {
  return (
    <Container>
      <img src={url} />
    </Container>
  );
};
