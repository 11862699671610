import styled from 'styled-components/macro';
import { Badge as AntdBadge, Typography } from 'antd';

export const Badge = styled(AntdBadge)`
  .ant-scroll-number {
    background: ${({ theme }) => theme.PRIMARY};
    color: white;
    border: none;
    box-shadow: none;
  }
  svg {
    font-size: 12px;
    color: ${({ theme }) => theme.PRIMARY};
  }
`;

export const NotRepliedBadge = styled.div`
  background-color: #fff1ea;
  color: #ff742f !important;
  border-radius: 60px;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  height: 24px;
`;

export const Content = styled.div<{ hasLastMessage: boolean }>`
  margin: ${({ hasLastMessage }) => (hasLastMessage ? '0 12px' : 'auto 12px')};
  line-height: 16px;
  max-width: 130px;
  text-overflow: ellipsis;
  word-break: break-all;
  justify-content: flex-start;
  div {
    margin-top: 3px;
    color: ${({ theme }) => theme.GREY_5};
    font-size: 14px;
    margin-bottom: 0 !important;
  }
`;

export const AddOns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
  width: 40px;
  & div:nth-child(1) {
    color: ${({ theme }) => theme.GREY_5};
    font-size: 12px;
    margin-bottom: 4px;
    white-space: nowrap;
  }
`;

export const Container = styled.div<{ followUp?: boolean; selected?: boolean }>`
  display: flex;
  height: 80px;
  overflow: hidden;
  padding: 12px 16px;
  cursor: pointer;
  ${({ selected, theme }) => selected && `background: ${theme.GREY_9}`};
  &:hover {
    background: ${({ theme }) => theme.GREY_8};
  }

  ${AddOns} {
    svg {
      color: ${({ followUp, theme }) => followUp && theme.GREY_5};
    }
  }
  b {
    color: black;
  }
`;

export const Text = styled(Typography.Text)`
  font-weight: 500;
  max-width: 100%;
  /* line-height: 14px; */
`;

export const Paragraph = styled(Typography.Paragraph)`
  /* line-height: 16px; */
`;
