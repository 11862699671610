import React from 'react';
import {
  Menu,
  Divider,
  ShadowMenu,
  MemberMenu,
  MemberProfile,
  MemberSubMenu,
  MemberMenuItem,
  MemberNestMenuItem,
  Dropdown,
  InlineSettingIcon,
  UserSettingContainer,
} from './style';
import { Typography } from 'antd';
import Burger from './Burger';
import MenuItem from './MenuItem';
import SubMenu from './SubMenu';
import ItemGroup from './ItemGroup';
import ReactTooltip from 'react-tooltip';
import { ISideBar, ISubMenu } from '@configs/sidebar';
import { SideBarContext } from './context';
import { SIDEBAR_BEHAVIOR, AGENT_STATUS } from './constants';
import Avatar from '@components/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import useSideMenu from './useSideMenu';
import { ICimPermission, IMember } from '@types';
import { getSplitName } from '@utils/splitName';
import { EAgentStatus } from '@enums/AgentStatus';
import { ERole } from '@enums/Role';
import { Font12G4W400, Font14G1W400, Font14G3W400, Font16G1W500 } from '@components/UtilsComponent';
import { faChevronRight, faSignOutAlt } from '@fortawesome/pro-solid-svg-icons';
import { getFontColor } from '@utils/getRoleColor';
import { isEmpty } from 'lodash';

type ISideMenu = {
  sideBarData: ISideBar[];
  member: IMember;
  cimPermission: ICimPermission | null;
  handleLogout: () => void;
  handleChangeMemberStatus: (status: EAgentStatus) => void;
};

const SideMenu: React.FC<ISideMenu> = ({
  sideBarData,
  member,
  cimPermission,
  handleLogout,
  handleChangeMemberStatus,
}: ISideMenu) => {
  const {
    collapsed,
    visibleDopdown,
    selectedMenu,
    selectedSubMenu,
    windowSize,
    theme,
    setCollapsed,
    setVisibleDropdown,
    setSelectedMenu,
    handleClick,
    onOpenChange,
    hideDropdown,
    checkCanAccess,
  } = useSideMenu();
  const teamText = member.teams?.map((i) => i.name).join(', ') ?? '';
  const _renderSubMenu = (item: ISideBar) => {
    return (
      <SubMenu key={item.path} label={item.label} iconName={item.iconName} to={item.path}>
        {item.children?.map((subItem: ISubMenu, index: number) => {
          const isLastItem = index === (item.children as []).length - 1;
          if (subItem.groupName) {
            return _renderItemGroup(subItem, !isLastItem);
          }

          if (subItem.children) {
            return _renderSubMenu(subItem);
          }

          return <MenuItem key={subItem.path} label={subItem.label} to={subItem.path} />;
        })}
      </SubMenu>
    );
  };

  const _renderItemGroup = (subMenulv1: ISubMenu, showDivider: boolean) => {
    if (subMenulv1.groupName && subMenulv1.children?.length) {
      return (
        <ItemGroup title={subMenulv1.groupName || ''}>
          <SubMenu key={subMenulv1.path} label={subMenulv1.label} to={subMenulv1.path}>
            {subMenulv1.children.map((subMenulv2) => {
              if (subMenulv2.children) {
                return (
                  <SubMenu key={subMenulv2.path} label={subMenulv2.label} to={subMenulv2.path}>
                    {subMenulv2.children.map((subMenulv3) => {
                      return <MenuItem key={subMenulv3.path} label={subMenulv3.label} to={subMenulv3.path} />;
                    })}
                  </SubMenu>
                );
              }
              return <MenuItem key={subMenulv2.path} label={subMenulv2.label} to={subMenulv2.path} />;
            })}
          </SubMenu>
          {showDivider && <Divider />}
        </ItemGroup>
      );
    }
  };

  const _renderMemberMenu = () => {
    return (
      <MemberMenu className="sidebar-member-menu">
        <MemberProfile>
          <div className="flex flex-col bg-[#F5F5F5] p-[12px] rounded-[8px]  w-full">
            <div className="flex items-center gap-x-[8px]">
              <div>
                <Avatar width={40} height={40} name={getSplitName(member.username)} memberStatus={member.status} />
              </div>
              <div>
                <div className="overflow-hidden max-w-[200px] overflow-ellipsis">
                  <Font16G1W500>{member.username}</Font16G1W500>
                </div>
                <div className="overflow-hidden max-w-[200px] overflow-ellipsis">
                  {member.email && <Font12G4W400>{member.email}</Font12G4W400>}
                </div>
              </div>
            </div>
            <div className="h-[1px] bg-[#E5E5E5] w-full my-[8px]" />
            <div className="flex gap-x-[8px] items-center">
              <Font14G3W400 className="min-w-[40px]">Role</Font14G3W400>
              <div>
                <p
                  className="text-[14px] capitalize font-[400]"
                  style={{
                    color: getFontColor(member.roles?.[0] ?? ''),
                  }}
                >
                  {member.roles?.[0] ?? ''}
                </p>
              </div>
            </div>
            {!isEmpty(member.teams) ? (
              <div className="flex gap-x-[8px] items-center mt-[8px]">
                <Font14G3W400 className="min-w-[40px]">Team</Font14G3W400>

                <div className="overflow-hidden max-w-[200px] overflow-ellipsis whitespace-nowrap" title={teamText}>
                  <Font14G1W400>{teamText}</Font14G1W400>
                </div>
              </div>
            ) : null}
          </div>
        </MemberProfile>
        <MemberSubMenu title={<div>Status</div>} popupClassName="member-submenu-popup">
          {AGENT_STATUS.map((item) => (
            <MemberNestMenuItem
              key={item.value}
              circleColor={item.color}
              onClick={() => {
                handleChangeMemberStatus(item.value);
                hideDropdown();
              }}
            >
              <div />
              <div>
                <Typography.Text>{item.label}</Typography.Text>
              </div>
            </MemberNestMenuItem>
          ))}
        </MemberSubMenu>
        {/* <MemberMenuItem onClick={(e: any) => setVisibleDropdown(true)} alignMent="space-between">
          <div>
            <Typography.Text>Notification</Typography.Text>
          </div>
          <Switch />
        </MemberMenuItem> */}
        {/* <MemberMenuItem onClick={(e: any) => setVisibleDropdown(false)} iconColor={theme.GREY_4}>
          <div>
            <FontAwesomeIcon icon={['fas', 'user']} />
          </div>
          <div>
            <Typography.Text>My profile</Typography.Text>
          </div>
        </MemberMenuItem> */}
        <MemberMenuItem
          onClick={(e: any) => {
            handleLogout();
            setVisibleDropdown(false);
          }}
          iconColor={theme.RED}
          labelColor={theme.RED}
        >
          <div>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </div>
          <div>
            <Typography.Text>Log out</Typography.Text>
          </div>
        </MemberMenuItem>
      </MemberMenu>
    );
  };

  return (
    <SideBarContext.Provider value={{ collapsed, setCollapsed, selectedMenu, setSelectedMenu }}>
      {collapsed && <ReactTooltip place="right" effect="solid" className="sidebar-tooltip" />}
      <ShadowMenu
        collapsed={collapsed}
        selectedMenu={selectedMenu}
        windowWidth={windowSize.width || window.screen.width}
        behavior={SIDEBAR_BEHAVIOR}
      />
      <Menu
        mode="inline"
        selectedMenu={selectedMenu}
        inlineCollapsed={collapsed}
        windowWidth={windowSize.width || window.screen.width}
        onClick={handleClick}
        triggerSubMenuAction="click"
        onOpenChange={onOpenChange}
        openKeys={selectedSubMenu}
      >
        <Burger />
        {sideBarData.map((item: ISideBar) => {
          if (item.children) {
            return _renderSubMenu(item);
          }

          if (item.isExternal && item.cimPermission && cimPermission && cimPermission[item.cimPermission]) {
            return (
              <MenuItem key={item.path} label={item.label} iconName={item.iconName} to={item.path} isExternal={true} />
            );
          }

          if (!item.isExternal && member.roles && checkCanAccess(member.roles[0], item.accessList)) {
            return <MenuItem key={item.path} label={item.label} iconName={item.iconName} to={item.path} />;
          }

          return null;
        })}

        {(member.roles.includes(ERole.ADMIN) || member.roles.includes(ERole.SUPERADMIN)) && (
          <>
            <div className="w-full px-[16px] my-[8px]">
              <div className="h-[1px] bg-[#424242] w-full" />
            </div>
            <MenuItem key="/settings/general" label="Setting" iconName={'cog'} to="/settings/general" />
          </>
        )}
        <li>
          <UserSettingContainer>
            <Dropdown
              visible={visibleDopdown}
              onVisibleChange={(flag) => setVisibleDropdown(flag)}
              placement="topLeft"
              trigger={['click']}
              overlay={_renderMemberMenu()}
            >
              <div>
                <div>
                  <Avatar name={getSplitName(member.username)} width={32} height={32} memberStatus={member.status} />
                  <span className="text-[#E5E5E5]">{member.username}</span>
                </div>
              </div>
            </Dropdown>

            <InlineSettingIcon icon={faChevronRight} />
          </UserSettingContainer>
        </li>
      </Menu>
    </SideBarContext.Provider>
  );
};

export default SideMenu;
