import React, { useCallback } from 'react';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IOption } from '@components/Select';
import { SelectBtn } from '@components/SelectBtn';
import { DropdownItem } from './style';
import { IReasonCategoryBE } from '@types';

import { fetchReasonCategory } from '@api/livechat/Wrapup';
import useTranslation from '@hooks/useTranslation';
import { Font12G5W400 } from '@components/UtilsComponent';

type IReasonCategorySelectProps = {
  value?: string | null;
  validateText?: string;
  onClearSelection: () => void;
  onChange?: (item: IReasonCategoryBE) => void;
};

export const ReasonCategorySelect = ({
  value = null,
  validateText,
  onChange,
  onClearSelection,
}: IReasonCategorySelectProps) => {
  const t = useTranslation();
  const { data, searchKeyword, firstLoading, hasMore, onOpenDropdown, onLoadmore, onUpdateSearchKeyword } =
    useInfinityLoadByCursorId<IReasonCategoryBE, IOption>({
      fetchFn: async (filter, cancelToken) => {
        const newFilter = {
          cursorId: filter.cursorId ?? '',
          keyword: filter.keyword ?? '',
          take: 10,
        };
        const data = await fetchReasonCategory(
          newFilter.take,
          newFilter.keyword || undefined,
          newFilter.cursorId || undefined,
          cancelToken,
        );
        const result = data.map((item) => {
          return {
            ...item,
            label: item.name,
            value: item.id,
          };
        });

        return result;
      },
    });
  const _renderSelectedText = useCallback((value: string | null): React.ReactNode => {
    if (value) {
      return value;
    }

    return '';
  }, []);

  return (
    <>
      <DropdownDynamicBtn
        showClearAll
        onClearAllSelection={onClearSelection}
        placement="bottomLeft"
        searchKeyword={searchKeyword}
        onUpdateSearchKeyword={onUpdateSearchKeyword}
        options={data}
        isFirstLoading={firstLoading}
        menuWidth="100%"
        dropdownHeight={200}
        dropdownClassName="z-[9999999]"
        renderOptions={(options, setOpenDropDown) => {
          return options.map((item) => {
            return (
              <DropdownItem
                key={item.value}
                onClick={() => {
                  onChange?.(item);
                  if (setOpenDropDown) {
                    setOpenDropDown(false);
                  }
                }}
              >
                <span>{item?.name}</span>
              </DropdownItem>
            );
          });
        }}
        renderBtn={function (
          prevState: boolean,
          setter: (status: React.SetStateAction<boolean>) => void,
        ): React.ReactNode {
          return (
            <SelectBtn
              className={`!h-[52px] !w-full ${validateText ? '!border-[1px] !border-[#F72C40]' : ''}`}
              data-testid="test-reason-category"
              selectedText={_renderSelectedText(value)}
              placeholder={t('livechat.ticket.console.resolve.reason.category.placeholder')}
              onClick={() => {
                // toggle dropdown
                setter(!prevState);
                if (!prevState) {
                  onOpenDropdown();
                }
              }}
            />
          );
        }}
        hasMore={hasMore}
        onLoadMore={onLoadmore}
      />
      <div className="flex justify-between mt-[4px]">
        {<Font12G5W400 className="!text-[#F72C40]">{validateText}</Font12G5W400>}
      </div>
    </>
  );
};
