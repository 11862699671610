import { cloneDeep } from 'lodash';

export const checkOrUnCheckById = <T extends { id: string }>(data: T[], compare: T) => {
  const list = cloneDeep(data);
  const found = list.findIndex((selected) => selected.id === compare.id);

  if (found !== -1) {
    return list.filter((selected) => selected.id !== compare.id);
  }
  return [...data, { ...compare, isChecked: false }];
};

export const filterOutIfExistAddIfNot = <T extends { id: string }>(data: T[], compare: T) => {
  const list = cloneDeep(data);
  const found = list.findIndex((selected) => selected.id === compare.id);

  if (found !== -1) {
    return list.filter((selected) => selected.id !== compare.id);
  }
  return [...data, { ...compare }];
};
