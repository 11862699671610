import { IMessageLevel } from '@types';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { EMessageType } from '@enums/MessageType';

export type StickerMessageJson = {
  text: string;
} & BaseMessageJson;

export class StickerMessage extends BaseMessage {
  private text: string;
  private level: IMessageLevel;

  constructor(data: StickerMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt });
    this.text = data.text;
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      text: this.text,
      createdAt: this.createdAt,
      level: this.level,
      retry: this.retry,
      senderName: this.senderName,
      senderImage: this.senderImage,
    };
  }

  get getMessageType() {
    return EMessageType.STICKER;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
