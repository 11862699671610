import { client } from '@api';

export const updateDisplayName = async (id: string, displayName: string) => {
  return await client.post('/api/v1/users/display-name', {
    id,
    displayName,
  });
};

export const deleteDisplayName = async (id: string) => {
  return await client.delete(`/api/v1/users/${id}/display-name`);
};
