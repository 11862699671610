import MessengerLogo from '@assets/images/messenger_logo.svg';
import Line from '@assets/images/line-icon.svg';
import WebchatLogo from '@assets/images/webchat_channel.png';
import CustomChannelLogo from '@assets/images/custom_logo.svg';
import ExclaimationLogo from '@assets/images/exclaimation.svg';
import EChannelType from '@enums/ChannelType';

const getLogoByChannelType = (channelType: EChannelType | null | undefined) => {
  switch (channelType) {
    case EChannelType.LINE:
      return Line;
    case EChannelType.FACEBOOK:
      return MessengerLogo;
    case EChannelType.WEBCHAT:
      return WebchatLogo;
    case EChannelType.CUSTOM:
      return CustomChannelLogo;
    default:
      return ExclaimationLogo;
  }
};

export default getLogoByChannelType;
