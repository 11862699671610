import React from 'react';
import { Container, MessageBoxContainer } from './style';
import TicketConsole from './TicketConsole';
import Composer from './Composer';
import MessageBox from './MessageBox';
import { TemplatePreviewOverlay } from '@components/TemplatePreviewOverlay';
import { useComposerManager } from '@providers/ComposerManagerProvider';
const EngagementMessage = React.memo(() => {
  const { preview, composer } = useComposerManager();
  const { selectedTemplate, onSelectTemplate } = preview;
  const { onToggleComposer } = composer;
  return (
    <Container>
      <TicketConsole />
      <div className="flex-1 relative flex">
        <TemplatePreviewOverlay
          visible={!!selectedTemplate}
          template={selectedTemplate}
          onClose={() => {
            onSelectTemplate(null);
            onToggleComposer();
          }}
        />

        <MessageBoxContainer className={`${!!selectedTemplate ? 'blur-sm' : ''}`}>
          <MessageBox />
        </MessageBoxContainer>
      </div>
      <div>
        <Composer />
      </div>
    </Container>
  );
});

EngagementMessage.displayName = 'EngagementMessage';

export default EngagementMessage;
