import styled from 'styled-components/macro';
import { Modal } from 'antd';

export const ErrorContainer = styled(Modal)`
  .ant-modal-content {
    overflow: hidden;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 278px;
    width: 400px;
  }
`;
