import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import { SearchInputContainer, Menu, ListItem, ClearButton } from './style';
import SearchInput from '@components/SearchInput';
import { IOption } from '@components/Select';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';

export type IPlacement = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';

export type IDropdownDynamicBtnProps<T extends IOption> = {
  options: T[];
  hasMore: boolean;
  searchKeyword: string;
  isFirstLoading?: boolean;
  showClearAll?: boolean;
  showSearch?: boolean;
  placement?: IPlacement;
  dropdownHeight?: number;
  menuWidth?: string;
  className?: string;
  dropdownClassName?: string;
  onLoadMore: () => void;
  onUpdateSearchKeyword: (keyword: string) => void;
  renderBtn: (prevState: boolean, setter: (status: React.SetStateAction<boolean>) => void) => React.ReactNode;
  renderOptions: (options: T[], setter?: (status: React.SetStateAction<boolean>) => void) => React.ReactNode;
  onClearAllSelection?: () => void;
};

export const DropdownDynamicBtn = <T extends IOption>({
  searchKeyword,
  options,
  hasMore,
  isFirstLoading,
  showClearAll,
  placement,
  dropdownHeight,
  menuWidth,
  className,
  dropdownClassName,
  showSearch = true,
  onClearAllSelection,
  onUpdateSearchKeyword,
  onLoadMore,
  renderBtn,
  renderOptions,
}: IDropdownDynamicBtnProps<T>) => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const btnWrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, [openDropDown]);

  const onClickOutside = (event: MouseEvent) => {
    if (dropDownRef.current && btnWrapRef.current) {
      // openDropDown still open click outside dropdown or click on btn then do nothing
      if (
        (openDropDown && dropDownRef.current.contains(event.target as Node)) ||
        btnWrapRef.current.contains(event.target as Node)
      ) {
        return;
      }
    }
    setOpenDropDown(false);
    return;
  };

  const _renderOverlay = () => {
    return (
      <div ref={dropDownRef} data-testid="dropdown-dynamic-btn">
        <Menu
          style={{
            width: menuWidth ?? 280,
          }}
        >
          <SearchInputContainer>
            {showSearch ? (
              <SearchInput
                value={searchKeyword}
                onChange={(e) => {
                  onUpdateSearchKeyword(e.target.value);
                }}
                onClear={() => {
                  onUpdateSearchKeyword('');
                }}
              />
            ) : null}
            {showClearAll ? (
              <ClearButton
                className={`${showSearch ? 'mt-[8px]' : ''}`}
                onClick={() => (onClearAllSelection ? onClearAllSelection() : false)}
              >
                Clear selection
              </ClearButton>
            ) : null}
          </SearchInputContainer>

          <div>
            <InfiniteScroll
              height={'100%'}
              style={{ height: 'auto', maxHeight: dropdownHeight ?? 250 }}
              next={onLoadMore}
              hasMore={hasMore}
              loader={<LoadingCircle />}
              dataLength={options.length}
            >
              <ListItem>{renderOptions(options, setOpenDropDown)}</ListItem>
              {isFirstLoading ? (
                <LoadingWrap>
                  <LoadingCircle />
                </LoadingWrap>
              ) : null}
            </InfiniteScroll>
          </div>
        </Menu>
      </div>
    );
  };

  return (
    <div ref={btnWrapRef} className={className}>
      <Dropdown
        placement={placement}
        overlay={_renderOverlay()}
        visible={openDropDown}
        overlayClassName={dropdownClassName}
      >
        {renderBtn(openDropDown, setOpenDropDown)}
      </Dropdown>
    </div>
  );
};
