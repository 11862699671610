import styled from 'styled-components/macro';

export const ListItem = styled.li<{ isChecked: boolean }>`
  display: table-row;
  height: 60px;
  padding: 16px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.GREY_9};
  }
  :not(:last-child) {
    > div {
      border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
    }
  }
  > div {
    display: table-cell;
    vertical-align: middle;
    background: ${({ isChecked }) => (isChecked ? '#e6f9f4' : 'transparent')};
  }
  > div:nth-child(1) {
    min-width: 56px;
    max-width: 56px;
    text-align: center;
  }
  > div:nth-child(2) {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      width: 670px;
    }
  }
  > div:nth-child(3) {
    text-align: right;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 155px;
  }
`;
