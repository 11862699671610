import React, { useEffect } from 'react';
import { getMyTeam } from '@api/livechat/TeamManagement';
import { Checkbox } from '@components/Checkbox';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { IOption } from '@components/Select';
import { Font12G3W400, Font16G1W400 } from '@components/UtilsComponent';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IGetByCursorParams, ITeam } from '@types';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { currentFilterState, excludeTeamIdsState } from '@store/EngagementUserListAtom';
import { ETicketFilterStatus } from '@enums/TicketFilter';

type IAdditionalFilterProps = {
  filterByUnRepliedChecked: boolean;
  selectedTeams: string[];
  onFilterByUnRepliedChange: () => void;
  onSelectedTeamsChange: (value: string[]) => void;
  onClearAllSelection: () => void;
};

export const AdditionalFilter = ({
  filterByUnRepliedChecked,
  selectedTeams,
  onFilterByUnRepliedChange,
  onSelectedTeamsChange,
  onClearAllSelection,
}: IAdditionalFilterProps) => {
  const t = useTranslation();
  const setExcludeTeamIds = useSetRecoilState(excludeTeamIdsState);
  const [currentFilter, setCurrentFilter] = useRecoilState(currentFilterState);

  const { data, hasMore, onOpenDropdown, onLoadmore } = useInfinityLoadByCursorId<ITeam & { id: string }, IOption>({
    fetchFn: async (filter, cancelToken) => {
      const newFilter: IGetByCursorParams = {
        ...filter,
        cursorId: filter.cursorId,
      };
      const { data }: { data: ITeam[] } = await getMyTeam(newFilter, cancelToken);
      const findDefaultTeam = data.find((item) => item.default);
      if (findDefaultTeam) {
        setExcludeTeamIds((prevState) => {
          // check duplicate
          if (prevState.includes(findDefaultTeam.id!)) {
            return prevState;
          }

          return prevState.length === 0 ? [findDefaultTeam.id!] : [...prevState, findDefaultTeam.id!];
        });
      }
      const result = data.map((item) => {
        return {
          ...item,
          id: item.id!,
          label: item.name,
          value: item.id!,
        };
      });
      return result;
    },
  });

  useEffect(() => {
    onOpenDropdown();
  }, [currentFilter.ticketStatus]);

  return (
    <>
      <DropdownDynamicBtn
        options={data}
        hasMore={hasMore}
        searchKeyword={''}
        showClearAll
        showSearch={false}
        onClearAllSelection={onClearAllSelection}
        onLoadMore={onLoadmore}
        onUpdateSearchKeyword={() => false}
        renderBtn={function (
          prevState: boolean,
          setter: (status: React.SetStateAction<boolean>) => void,
        ): React.ReactNode {
          return (
            <div className="relative">
              {filterByUnRepliedChecked || !currentFilter.filterNoTeam || selectedTeams.length > 0 ? (
                <div className="absolute top-0 right-0 w-[8px] h-[8px] rounded-full bg-[#F72C40]" />
              ) : null}

              <FontAwesomeIcon
                className="text-[20px] text-[#757575]"
                icon={faSlidersH}
                role="button"
                onClick={() => setter(!prevState)}
              />
            </div>
          );
        }}
        renderOptions={function (
          options: IOption[],
          _setter?: ((status: React.SetStateAction<boolean>) => void) | undefined,
        ): React.ReactNode {
          return (
            <div className="py-[10px]">
              <Font12G3W400 className="px-[16px]">{t('livechat.user.list.additional.filter.general')}</Font12G3W400>
              {
                // display only filter status is unassigned
                currentFilter.ticketStatus === ETicketFilterStatus.UNASSIGNED && (
                  <div role="button">
                    <div
                      className="flex gap-x-[8px] items-center mt-[4px] px-[16px]"
                      onClick={() =>
                        setCurrentFilter((prevState) => {
                          return {
                            ...prevState,
                            filterNoTeam: !prevState.filterNoTeam,
                          };
                        })
                      }
                      role="button"
                    >
                      <Checkbox checked={!currentFilter.filterNoTeam} />
                      <Font16G1W400 role="button">{t('livechat.user.list.additional.filter.no.team')}</Font16G1W400>
                    </div>
                  </div>
                )
              }

              <div role="button">
                <div
                  className="flex gap-x-[8px] items-center mt-[4px] px-[16px]"
                  onClick={() => onFilterByUnRepliedChange()}
                  role="button"
                >
                  <Checkbox checked={filterByUnRepliedChecked} />
                  <Font16G1W400 role="button">
                    {t('livechat.user.list.additional.filter.show.not.replied.only')}
                  </Font16G1W400>
                </div>
              </div>
              <div className="flex flex-col gap-y-[8px] mt-[8px]">
                <Font12G3W400 className="px-[16px]">{t('livechat.user.list.additional.filter.team')}</Font12G3W400>
                {options.map((item) => {
                  return (
                    <div
                      key={item.value}
                      role="button"
                      className="px-[16px] flex gap-x-[8px] items-center"
                      onClick={() => {
                        // remove if exist and add if not
                        const selectedTeamsClone = selectedTeams.includes(item.value)
                          ? selectedTeams.filter((i) => i !== item.value)
                          : [...selectedTeams, item.value];
                        onSelectedTeamsChange(selectedTeamsClone);
                      }}
                    >
                      <Checkbox checked={selectedTeams.includes(item.value)} id={item.value} />
                      <Font16G1W400>{item.label}</Font16G1W400>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      ></DropdownDynamicBtn>
    </>
  );
};
