import React, { useState, useEffect, useRef } from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownProps } from 'antd';
import { Container, Dropdown, Menu, GroupName, IconWrap } from './style';

export type IDropDownItem = {
  icon?: string | { type: string; value: [IconPrefix, IconName] };
  label: string;
  value: string;
};

export type IDropDownData = {
  key: string;
  items: IDropDownItem[];
};

export type IDropdownGroup = {
  data: IDropDownData[];
  btn: (setterState: (status: React.SetStateAction<boolean>) => void) => React.ReactNode;
  handleSelectItem: (item: IDropDownItem) => void;
} & Omit<DropDownProps, 'overlay'>;

export const DropdownGroup: React.FC<IDropdownGroup> = ({ data, btn, handleSelectItem, ...rest }: IDropdownGroup) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);
    return () => document.removeEventListener('mousedown', onClickOutside);
  }, []);

  const onClickOutside = (event: MouseEvent) => {
    if (dropDownRef.current?.contains(event.target as Node)) {
      setOpenDropDown(true);
      return;
    }
    setOpenDropDown(false);
    return;
  };

  const _renderIcon = (icon: string | { type: string; value: [IconPrefix, IconName] }) => {
    if (typeof icon === 'string') {
      return <img src={icon} />;
    }
    if (typeof icon === 'object') {
      return (
        <IconWrap>
          <FontAwesomeIcon icon={icon.value} />
        </IconWrap>
      );
    }
    return null;
  };

  const menu = () => {
    return (
      <div {...rest} ref={dropDownRef} data-testid="dropdown-group">
        <Menu>
          {data.map(({ key, items }: IDropDownData, index: number) => {
            return (
              <div key={index}>
                {key && (
                  <GroupName>
                    <span>{key}</span>
                  </GroupName>
                )}

                {items.map((item) => (
                  <li
                    data-testid="dropdown-item"
                    key={item.label}
                    onClick={() => {
                      handleSelectItem(item);
                      setOpenDropDown(false);
                    }}
                  >
                    {item.icon && _renderIcon(item.icon)}
                    <span>{item.label}</span>
                  </li>
                ))}
              </div>
            );
          })}
        </Menu>
      </div>
    );
  };

  return (
    <Container>
      <Dropdown {...rest} overlay={menu()} visible={openDropDown}>
        {btn(setOpenDropDown)}
      </Dropdown>
    </Container>
  );
};
