import styled from 'styled-components/macro';
import { Typography } from 'antd';

export const Container = styled.div``;

export const FormHeader = styled.div`
  margin-top: 42px;
  margin-bottom: 24px;
`;

export const Description = styled(Typography.Text)`
  font-size: 16px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const CheckBoxWrap = styled.section`
  display: flex;
  padding-bottom: 24px;
  column-gap: 10px;
  &:not(:first-child) {
    margin-top: 24px;
    padding-bottom: 32px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20px;
`;

export const TableWrap = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 100%;
`;

export const SearchWrap = styled.div`
  width: 500px;
`;

export const ActionWrap = styled.div`
  margin-top: 16px;
  display: flex;
  column-gap: 8px;
`;

export const UsernameWrap = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const AvatarWrap = styled.div``;
export const DetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  span:nth-child(1) {
    font-weight: 600;
    color: ${({ theme }) => theme.GREY_1};
  }
  span:nth-child(2) {
    font-weight: 600;
    color: ${({ theme }) => theme.GREY_5};
    font-size: 12px;
  }
`;
