import React, { useCallback, useMemo } from 'react';
import { Button, GhostButton, PrimaryButton, TertiaryButton } from '@components/Button';
import { Checkbox } from '@components/Checkbox';
import SearchInput from '@components/SearchInput';
import Table, { IColumns } from '@components/Table';
import TextInput from '@components/TextInput';
import { Font12G5W400, Font14G1W600 } from '@components/UtilsComponent';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { ConfigProvider, Spin } from 'antd';
import { useEditor } from './useEditor';
import { TemplateSelectorModal } from '@components/TemplateSelectorModal';
import { BOTS } from '@types';
import {
  TEMPLATE_COLLECTION_NAME_MAX_LENGTH,
  TEMPLATE_COLLECTION_NAME_MIN_LENGTH,
  TEMPLATE_COLLECTION_SELECTION_MAX_LENGTH,
} from '@configs/constants';
import { ListItem } from './style';
import { generatePlatform } from '@utils/template';
import { Platform } from '@components/Platform';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEmpty } from 'lodash';
import { PreviewWrapper } from '@components/MessagePreview';
import './style.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTE } from '@configs/route';
import { Typography } from 'antd';
import { RouteLeavingGuard } from '@components/RouteLeavingGuard';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';

const { Title } = Typography;
export const TemplateCollectionEditor = () => {
  const t = useTranslation();
  const { modal, manage } = useEditor();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const _renderTemplateModalListItem = useCallback(
    (item: BOTS.ITemplateApi, index, onSelectTemplate) => {
      const platform = generatePlatform(item.platforms);
      const isSelected = modal.innerSelecteTemplateList.some((selected) => selected.id === item.id);
      return (
        <ListItem
          key={item.id}
          isChecked={modal.selectedTemplate?.id === item.id}
          onClick={(e) => {
            onSelectTemplate(item);
          }}
        >
          <div>
            <Checkbox
              checked={isSelected}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                modal.onCheckTemplate(item, e);
              }}
            />
          </div>

          <div>{item.name}</div>
          <div>
            <div className="pr-4">
              <Platform platform={platform} />
            </div>
          </div>
        </ListItem>
      );
    },
    [modal.selectedTemplate, modal.innerSelecteTemplateList],
  );

  const generateColumns = (
    selectedTemplateList: BOTS.ITemplateBEWithCheck[],
    onCheckAll: (e: CheckboxChangeEvent) => void,
    onCheckSingle: (e: CheckboxChangeEvent, record: BOTS.ITemplateBEWithCheck) => void,
  ): IColumns[] => {
    return [
      {
        title: () => {
          const isNotEmpty = selectedTemplateList.length > 0;
          const isCheckAll = isNotEmpty && selectedTemplateList.every((item) => item.isChecked);
          return <Checkbox checked={isCheckAll} disabled={!isNotEmpty} onChange={onCheckAll} />;
        },
        className: 'align-middle cursor-pointer',
        width: 40,
        render: (record: BOTS.ITemplateBEWithCheck) => {
          return (
            <Checkbox
              onChange={(e) => {
                onCheckSingle(e, record);
              }}
              checked={record.isChecked}
            />
          );
        },
      },
      {
        title: 'TEMPLATE NAME',
        className: 'align-middle cursor-pointer',
        ellipsis: true,
        render: (record: BOTS.ITemplateBEWithCheck) => (
          <div className="cursor-pointer overflow-ellipsis overflow-hidden">{record.name}</div>
        ),
      },
      {
        title: (
          <div className="text-right pr-4">
            {selectedTemplateList.length}/{TEMPLATE_COLLECTION_SELECTION_MAX_LENGTH}
          </div>
        ),
        className: 'align-middle cursor-pointer',
        render(value, record: BOTS.ITemplateBEWithCheck) {
          const platform = generatePlatform(record.platforms);
          return (
            <div className="flex items-center justify-end ml-[-16px]">
              <div>
                <Platform platform={platform} />
              </div>
              <div className="w-[56px] h-[44px] flex items-center ml-4">
                <FontAwesomeIcon
                  role="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    manage.onRemoveTemplate(record);
                  }}
                  icon={faTrash}
                  color="#BDBDBD"
                  className="text-base flex-1"
                />
              </div>
            </div>
          );
        },
      },
    ];
  };
  const isSelectSome =
    !isEmpty(manage.collection.templates) && manage.collection.templates.some((item) => item.isChecked);

  const dataList = useMemo(
    () => manage.collection.templates.filter((item) => item.name.toLowerCase().includes(manage.keyword.toLowerCase())),
    [manage.collection, manage.keyword],
  );

  return (
    <div className="py-10 pt-[44px] h-full flex flex-col min-w-[1148px]">
      <Spin
        spinning={manage.createMute.isLoading || manage.query.isLoading || manage.updateMute.isLoading}
        indicator={
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        }
      >
        <RouteLeavingGuard
          when={manage.isSomethingUpdate}
          navigate={(path) => {
            history.push(path);
          }}
          shouldBlockNavigation={() => {
            if (manage.isSomethingUpdate) {
              return true;
            }
            return false;
          }}
        />
        <div className="flex justify-between items-center">
          <Title level={3}>{t('template.collection.editor.header.title')}</Title>
          <div className="flex gap-x-1">
            <Link to={ROUTE.TEMPLATE_COLLECTION}>
              <GhostButton onClick={console.log}>{t('template.collection.editor.cancel.btn.title')}</GhostButton>
            </Link>
            <PrimaryButton
              onClick={id ? manage.onUpdate : manage.onSave}
              disabled={
                isEmpty(manage.collection.templates) ||
                !manage.collection.name ||
                manage.collection.name.trim().length < TEMPLATE_COLLECTION_NAME_MIN_LENGTH
              }
            >
              {t('template.collection.editor.save.btn.title')}
            </PrimaryButton>
          </div>
        </div>

        <div className="mt-6 flex flex-col gap-y-2">
          <Font14G1W600>{t('template.collection.editor.name.label')}</Font14G1W600>
          <TextInput
            className="w-[425px]"
            onChange={manage.onCollectionNameChange}
            maxLength={TEMPLATE_COLLECTION_NAME_MAX_LENGTH}
            value={manage.collection.name}
            size="large"
            placeholder={t('template.collection.editor.name.placeholder')}
          />
        </div>
        <div className="mt-6 flex justify-between">
          <div>
            <SearchInput
              className="w-[316px]"
              value={manage.keyword}
              onChange={(e) => manage.onSearch(e.target.value)}
              onClear={() => {
                manage.onSearch('');
              }}
            />
          </div>
          <div className="flex gap-x-2">
            <TertiaryButton onClick={manage.onDeleteSelected} disabled={!isSelectSome}>
              {t('template.collection.editor.delete.btn.title')}
            </TertiaryButton>

            <Button onClick={modal.onToggle} icon={<FontAwesomeIcon className="mr-2" icon={faPlus} />}>
              {t('template.collection.editor.add.btn.title')}
            </Button>
          </div>
        </div>
        <div className="mt-4 grid grid-cols-2 gap-x-4 flex-1">
          <div className="min-w-[565px] template-collection-table">
            <ConfigProvider renderEmpty={() => <div>{t('template.collection.editor.no.data')}</div>}>
              <Table
                rowClassName={(record) => (record.id === manage.preview?.id ? 'highlight' : '')}
                onRow={(record) => {
                  return {
                    onClick: (e) => {
                      const target = e.target as Element;
                      if (target.classList.contains('ant-checkbox-input')) {
                        return;
                      }
                      manage.onSelectPreview(record);
                    },
                  };
                }}
                header={undefined}
                data={dataList}
                columns={generateColumns(manage.collection.templates, manage.onCheckAll, manage.onCheckSingle)}
                pagination={false}
                scroll={{ y: 'calc(100vh - 250px)' }}
              />
            </ConfigProvider>
          </div>
          <div className="bg-[#F5F5F5] rounded-lg min-w-[565px] h-[calc(100vh-187px)]">
            <div className={`flex justify-center items-center h-full`}>
              {manage.preview ? (
                <PreviewWrapper message={manage.preview} showName />
              ) : (
                <Font12G5W400>{t('template.collection.editor.empty.select')}</Font12G5W400>
              )}
            </div>
          </div>
        </div>
        <TemplateSelectorModal
          visible={modal.showModal}
          selectedTemplate={modal.selectedTemplate}
          isLoading={modal.templateListHandler.isLoading}
          isLoadingMore={modal.templateListHandler.isLoadingMore}
          isDisabledConfirmBtn={modal.innerSelecteTemplateList.length > TEMPLATE_COLLECTION_SELECTION_MAX_LENGTH}
          listData={modal.templateListHandler.current.context.list.data}
          selectCount={modal.innerSelecteTemplateList.length}
          maxCount={TEMPLATE_COLLECTION_SELECTION_MAX_LENGTH}
          _renderListItem={_renderTemplateModalListItem}
          showToast
          onUpdateKeyword={(keyword: string) => {
            modal.templateListHandler.onUpdateKeyword(keyword);
          }}
          onScroll={modal.templateListHandler.onScroll}
          onSearch={modal.templateListHandler.onSearch}
          onToggleModal={modal.onToggle}
          onSelectTemplate={(template) => modal.onSelectTemplate(template)}
          onConfirmSelectTemplate={() => {
            modal.onConfirmSelectTemplate();
          }}
        />
      </Spin>
    </div>
  );
};
