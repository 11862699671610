import React from 'react';
import { Menu } from 'antd';
import AbstractBottomModal, { AbstractBottomModalProps } from '../AbstractBottomModal';
import { ContentBox, HeaderContainer } from './style';
import { SelectData } from './useSelectBottomModal';

export type SelectBottomModalProps<T> = {
  selected: T[];
  items: SelectData<T>[];
  onSelectChange?: (values: any[]) => void;
  onDone?: (values: any[]) => void;
  _renderItem: (config: { selected: T[]; item: SelectData<T> }) => React.ReactNode;
  _renderHeader: () => React.ReactNode;
  onScrollCapture?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
} & AbstractBottomModalProps;

const SelectBottomModal = <T,>(props: SelectBottomModalProps<T>) => {
  const { selected, items, _renderItem, _renderHeader, onScrollCapture, ...rest } = props;

  return (
    <AbstractBottomModal
      {...rest}
      header={<HeaderContainer>{_renderHeader()}</HeaderContainer>}
      onScrollCapture={(e) => onScrollCapture && onScrollCapture(e)}
    >
      <ContentBox>
        <Menu>{items.map((item) => _renderItem({ selected, item }))}</Menu>
      </ContentBox>
    </AbstractBottomModal>
  );
};

export default SelectBottomModal;
