import React, { useState } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CarouselItem, ImageContainer, Container, ArrowRight, ArrowLeft } from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IImageCarouselColumn } from '@types';

export type IImageCarouselMessageProps = {
  columns: IImageCarouselColumn[];
};

export const ImageCarouselMessage: React.FC<IImageCarouselMessageProps> = ({ columns }: IImageCarouselMessageProps) => {
  const [selected, setSelected] = useState(0);

  const settings = {
    slidesToShow: 1,
    infinite: false,
    nextArrow: (
      <ArrowRight show={selected !== columns.length - 1 ? true : false}>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </ArrowRight>
    ),
    prevArrow: (
      <ArrowLeft show={selected !== 0 ? true : false}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
      </ArrowLeft>
    ),
    beforeChange: (current: number, next: number) => {
      setSelected(next);
    },
  };

  return (
    <Container>
      <Slider {...settings}>
        {columns.map((column, index) => {
          return (
            <CarouselItem key={index}>
              <ImageContainer>
                <img src={column.imageUrl} />
              </ImageContainer>
              {column.button ? (
                <button>
                  <span>{column.button}</span>
                </button>
              ) : null}
            </CarouselItem>
          );
        })}
      </Slider>
    </Container>
  );
};
