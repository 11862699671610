import React from 'react';
import LineIcon from '@assets/images/line.png';
import Messenger from '@assets/images/messenger.png';
import Webchat from '@assets/images/webchat_channel.png';
import Multi from '@assets/images/multilogo.png';
import { IPlatform } from '@types';

type IPlatformProps = {
  platform: IPlatform;
};

export const Platform = ({ platform }: IPlatformProps) => {
  if (platform === 'line') {
    return (
      <div className="flex w-full justify-end">
        <img width={23} height={23} src={LineIcon} />
      </div>
    );
  }
  if (platform === 'facebook') {
    return (
      <div className="flex w-full justify-end">
        <img width={23} height={23} src={Messenger} />
      </div>
    );
  }
  if (platform === 'webchat') {
    return (
      <div className="flex w-full justify-end">
        <img width={23} height={23} src={Webchat} />
      </div>
    );
  }
  if (platform === 'line_webchat') {
    return (
      <div className="flex w-full justify-end">
        <img width={23} height={23} src={LineIcon} />
        <img width={23} height={23} src={Webchat} />
      </div>
    );
  }
  return (
    <div className="flex w-full justify-end">
      <img width={60} height={23} src={Multi} />
    </div>
  );
};
