import React, { useState, useCallback, useEffect } from 'react';
import { updateTicketSummaryMVP } from '@api/livechat/EngagementMessage';
import { useAutosave } from 'react-autosave';
import { Form } from 'antd';
import { useQuery } from 'react-query';
import { getTicketById } from '@api/livechat/Ticket';
import { ITicket } from '@types';
import { isEmpty } from 'lodash';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { GLOBAL_MESSAGE, PERMISSION_DENIED_NAME_ERROR } from '@configs/constants';

export type IResolveForm = {
  data: {
    note: string | undefined;
    result: string[];
    reasons: string[];
    categories?: string[];
  };
  ticketId: string;
};

type IResolveState = 'success' | 'error' | 'loading' | 'idle';

const useResolveModal = (ticket: ITicket, onUpdateTicketField: (payload: IResolveForm) => void) => {
  const [status, setStatus] = useState<IResolveState>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const [fieldsValue, setFieldsValue] = useState<IResolveForm>({
    ticketId: ticket._id,
    data: {
      note: ticket.summary?.note ?? '',
      result: ticket.summary?.result ?? [],
      reasons: ticket.summary?.reasons ?? [],
      categories: ticket.summary?.categories ?? undefined,
    },
  } as IResolveForm);
  const [isAutoSave, setIsAutoSave] = useState(false);
  const [form] = Form.useForm();
  const [validateFields, setValidateFields] = useState({
    note: true,
    result: false,
    reasons: false,
    categories: false,
  });
  const ticketData = useQuery(
    ['getRecentTicketByUserId', ticket?._id],
    () => {
      if (ticket) {
        return getTicketById(ticket._id);
      }
    },
    {
      retry: 0,
      onSuccess: (data: AxiosResponse<ITicket>) => {
        const { data: axiosData } = data;
        if (!isEmpty(axiosData.summary)) {
          setFieldsValue((prev) => {
            return {
              ...prev,
              data: {
                categories: axiosData.summary?.categories ?? [],
                reasons: axiosData.summary?.reasons ?? [],
                result: axiosData.summary?.result ?? [],
                note: axiosData.summary?.note ?? '',
              },
            };
          });
        }
      },
    },
  );

  const handleAutoSave = useCallback(
    async (payload: {
      data: {
        categories?: string[];
        note: string | undefined;
        result: string[] | undefined;
        reasons: string[] | undefined;
      };
      ticketId: string;
    }) => {
      try {
        setIsAutoSave(true);
        setTimeout(() => {
          setIsAutoSave(false);
        }, 2000);
        const transform = {
          ticketId: payload.ticketId,
          data: {
            categories: payload.data.categories ? payload.data.categories : undefined,
            note: payload.data.note,
            result: payload.data.result,
            reasons: payload.data.reasons,
          },
        };
        return await updateTicketSummaryMVP(transform);
      } catch (error) {
        console.warn(error);
        const err = error as Error | AxiosError;
        if (axios.isAxiosError(err)) {
          const name = err?.response?.data?.name;
          const errorMessage = err?.response?.data?.message;
          if (name === PERMISSION_DENIED_NAME_ERROR) {
            setErrorMessage(errorMessage.replace(/\((.*?)\)/, ''));
          } else {
            setErrorMessage(errorMessage ?? GLOBAL_MESSAGE.ERROR_TITLE);
          }
        }
      }
    },
    [],
  );

  useAutosave({ data: fieldsValue, onSave: handleAutoSave, interval: 300 });

  const onUpdateFields = (payload: IResolveForm) => {
    setFieldsValue(payload);
    onUpdateTicketField(payload);
  };

  return {
    validateFields,
    fieldsValue,
    status,
    form,
    isAutoSave,
    errorMessage,
    ticketData,
    onUpdateFields,
    isValidatePass: () => {
      const validateField = {
        result: fieldsValue.data.result.length === 0,
        reasons: fieldsValue.data.reasons.length === 0,
        categories: fieldsValue.data.categories?.length === 0,
      };
      setValidateFields(() => {
        return {
          note: true,
          result: fieldsValue.data.result.length === 0,
          reasons: fieldsValue.data.reasons.length === 0,
          categories: fieldsValue.data.categories?.length === 0,
        };
      });
      // check all fields is valid
      if (Object.values(validateField).every((item) => item === false)) {
        return true;
      }
      return false;
    },
  };
};

export default useResolveModal;
