import { ERole } from '@enums/Role';
import { IconName } from '@fortawesome/fontawesome-svg-core';

//pageName use for access control

export type ISideBar = {
  label: string;
  iconName?: IconName;
  path: string;
  children?: ISubMenu[];
  pageName: string;
  accessList: string[];
  isExternal?: boolean;
  cimPermission?: string;
};

export type ISubMenu = {
  label: string;
  groupName?: string;
  path: string;
  children?: ISubMenu[];
  pageName: string;
  accessList: string[];
};

export const sideBarData: ISideBar[] = [
  {
    label: 'Operational Dashboard',
    iconName: 'chart-line',
    path: '/operational-dashboard',
    pageName: 'operational-dashboard',
    accessList: [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR],
  },
  {
    label: 'Team Management',
    iconName: 'users',
    path: '/team-management',
    pageName: 'team-management',
    accessList: [ERole.SUPERADMIN, ERole.ADMIN],
  },
  {
    label: 'Livechat',
    iconName: 'headset',
    path: '/recents',
    pageName: 'recents',
    accessList: [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR, ERole.AGENT],
  },
  {
    label: 'Ticket Management',
    iconName: 'history',
    path: '/ticket-management',
    pageName: 'ticket-management',
    accessList: [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR, ERole.AGENT],
  },
  {
    label: 'Generative AI',
    iconName: 'sparkles',
    isExternal: true,
    path: (import.meta.env.VITE_ASAP_ENDPOINT as string) ?? '',
    pageName: 'generative-ai',
    accessList: [ERole.SUPERADMIN, ERole.ADMIN, ERole.SUPERVISOR, ERole.AGENT],
    cimPermission: 'genai',
  },
];
