import { createTag, updateTag } from '@api/livechat/Tag';
import { TAG_CONSTRAINT_REGEX } from '@configs/regex';
import { TAG_ERROR_MAPPING } from '@configs/tag';
import { useAlertModal } from '@providers/AlertModalProvider';
import { ITagPayload, ITagResponse } from '@types';
import { removeSpecialWhenPasted } from '@utils/letters';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';

export type ISelectedTagState = {
  index: number | null;
  value: ITagResponse | null;
};

export const useTicketTag = () => {
  const [selectedTag, setSelected] = useState<ISelectedTagState>({
    index: null,
    value: null,
  });
  const { onOpen, onUpdateDetail } = useAlertModal();
  const updateTagMut = useMutation((payload: ITagResponse) => {
    return updateTag(payload.id, { value: payload.value });
  });
  const addTagMut = useMutation((payload: ITagPayload) => {
    return createTag(payload);
  });
  const alert = useAlert();
  return {
    selectedTag,
    addTagMut,
    updateTagMut,
    onAddTag: async (value: string, cb: () => void) => {
      try {
        await addTagMut.mutateAsync({ value });
        alert.success('Tag name created');
        cb();
      } catch (error) {
        const err = error as AxiosError;
        const errorMessage = err.response?.data?.message ? (err.response?.data?.message as string) : '';
        if (errorMessage && TAG_ERROR_MAPPING[errorMessage]) {
          onUpdateDetail(
            TAG_ERROR_MAPPING[err.response?.data?.message].title,
            TAG_ERROR_MAPPING[err.response?.data?.message].description,
          );
        }
        onOpen();
      }
    },
    onSelectEdit: (payload: ISelectedTagState) => {
      setSelected(payload);
    },
    onUpdateSelected: (newName: string) => {
      setSelected((prev: ISelectedTagState) => {
        const replaced = removeSpecialWhenPasted(newName, TAG_CONSTRAINT_REGEX);
        return {
          ...prev,
          value: {
            value: replaced,
            createdAt: prev.value?.createdAt || '',
            id: prev.value?.id || '',
            networkId: prev.value?.networkId || '',
            updatedAt: prev.value?.updatedAt || '',
          },
        };
      });
    },
    onConfirmEdit: async (cb: () => Promise<void>) => {
      try {
        if (!selectedTag.value) return;
        await updateTagMut.mutateAsync(selectedTag.value);
        alert.success('Tag name updated');
        await cb();
        setSelected({ index: null, value: null });
      } catch (error) {
        const err = error as AxiosError;
        const errorMessage = err.response?.data?.message ? (err.response?.data?.message as string) : '';
        if (errorMessage && TAG_ERROR_MAPPING[errorMessage]) {
          onUpdateDetail(
            TAG_ERROR_MAPPING[err.response?.data?.message].title,
            TAG_ERROR_MAPPING[err.response?.data?.message].description,
          );
        }
        onOpen();
      }
    },
    onCancelEdit: () => {
      setSelected({ index: null, value: null });
    },
  };
};
