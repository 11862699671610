import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 332px;
    width: 332px;
    svg {
      font-size: 36px;
      color: #bdbdbd;
    }
    p {
      align-self: flex-start;
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  a,
  button {
    width: 100%;
  }
`;
