import { assign, createMachine } from 'xstate';

export const vaPlaceholderMachine = createMachine(
  {
    id: 'vaPlaceholderMachine',
    type: 'parallel',
    initial: 'idle',
    context: {},
    states: {
      tooltip: {
        initial: 'hide',
        states: {
          show: {},
          hide: {},
        },
        on: {
          TOOLTIP_SHOW: '.show',
          TOOLTIP_HIDE: '.hide',
        },
      },
      composer: {
        initial: 'hide',
        states: {
          show: {},
          hide: {},
          texting: {},
          filled: {},
          focusing: {},
        },
        on: {
          COMPOSER_SHOW: '.show',
          COMPOSER_HIDE: '.hide',
          COMPOSER_TEXTING: '.texting',
          COMPOSER_FILLED: '.filled',
          COMPOSER_FOCUSING: '.focusing',
        },
      },
      placeholder: {
        initial: 'hide',
        states: {
          show: {},
          hide: {},
        },
        on: {
          PLACEHOLDER_SHOW: '.show',
          PLACEHOLDER_HIDE: '.hide',
        },
      },
      katik: {
        initial: 'hide',
        states: {
          show: {},
          hide: {},
        },
        on: {
          KATIK_SHOW: '.show',
          KATIK_HIDE: '.hide',
        },
      },
    },
  },
  {
    actions: {},
    services: {},
    guards: {},
  },
);
