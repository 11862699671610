import { cimClient, client } from '..';

export const authenticateByUsernameApi = (username: string, password?: string) => {
  return client.post('/login', {
    username,
    password,
  });
};

export const changePasswordApi = (id: string, oldPassword: string, password: string) => {
  return client.put(`/api/agents/${id}/password`, {
    oldPassword,
    password,
  });
};

export const logoutApi = () => {
  return client.put('/api/logout');
};

export const changeMemberStatusApi = (id: string, status: string) => {
  return client.put(`/api/agents/${id}/status`, { status });
};

export const getMemberByTokenApi = () => {
  return client.get(`/api/agents/tokens/me`);
};

export const getCimPermission = async () => {
  try {
    return await cimClient.get('/api/v1/permissions/access');
  } catch (error) {
    console.error('Error getting cim permission', error);
    return null;
  }
};
