import React from 'react';
import { BOTS } from '@types';
import { Action, Column, Container, TopSection, ImageWrap } from './style';
import { Font14G3W400, Font16G1W400, Spacing } from '@components/UtilsComponent';
import { CAROUSEL_IMAGE_ASPECT_RATIO } from '@configs/constants';

export type ICarouselPreviewProps = {
  carousel: BOTS.ICarouselMessageApi;
};

export const CarouselPreview = ({ carousel }: ICarouselPreviewProps) => {
  return (
    <Container>
      {carousel.columns.map((column) => {
        return (
          <Column key={column.id}>
            {column.thumbnailImageUrl ? (
              <ImageWrap isSquare={carousel.imageAspectRatio === CAROUSEL_IMAGE_ASPECT_RATIO.SQUARE}>
                <img src={column.thumbnailImageUrl} />
              </ImageWrap>
            ) : null}

            <Action>
              <TopSection>
                {column.title ? (
                  <>
                    <Font16G1W400>
                      <p className="overflow-hidden overflow-ellipsis">{column.title}</p>
                    </Font16G1W400>
                    <Spacing height={8} />
                  </>
                ) : null}

                {column.text ? (
                  <Font14G3W400>
                    <p className="overflow-hidden overflow-ellipsis">{column.text}</p>
                  </Font14G3W400>
                ) : null}
              </TopSection>
              {column.actions.map((action, index) => {
                return (
                  <React.Fragment key={action.id}>
                    <button className="overflow-ellipsis overflow-hidden" title={action.label}>
                      {action.label}
                    </button>
                    {index !== column.actions.length - 1 ? null : <Spacing height={20} />}
                  </React.Fragment>
                );
              })}
            </Action>
          </Column>
        );
      })}
    </Container>
  );
};
