export enum ECustomerSatisfactionValue {
  quickReply = 'QUICK_REPLY',
  imagemap = 'IMAGE_MAP',
  externalLink = 'EXTERNAL_LINK',
}
export const SURVEY_TYPE_OPTION = [
  { label: 'Quick reply', value: ECustomerSatisfactionValue.quickReply },
  { label: 'Imagemap', value: ECustomerSatisfactionValue.imagemap },
  { label: 'External survey', value: ECustomerSatisfactionValue.externalLink },
];

export type ISurvey = {
  id?: string;
  channelId: string;
  networkId: string;
  type: ECustomerSatisfactionValue;
  channel: string;
  isEnable: boolean;
  message: ISurveyMessage;
  modifiedBy: string;
  timeoutMS: number;
  createdAt: string;
  updatedAt: string;
};

export type ISurveyMessage = {
  id?: string;
  question: string;
  options: ISurveyQuickReplyAction[];
  imageMap?: ISurveyImageMapAction;
  externalLink?: string;
  isFeedback: boolean;
  feedbackMessage?: ISurveyFeedbackMessage;
  thxMessage?: string;
};

export type ISurveyImageMapAction = {
  id: string;
  baseUrl: string;
  baseSize: {
    width: number;
    height: number;
  };
  imgUrl: string;
  imgHeight: number;
  imgWidth: number;
  actions: {
    id: string;
    type: string;
    area: {
      height: number;
      width: number;
      x: number;
      y: number;
    };
    point: string;
    message: string;
  }[];
};

export type ISurveyQuickReplyAction = {
  id?: string;
  label: string;
  point: string | number;
};

export type ISurveyFeedbackOption = {
  id?: string;
  label: string;
  value: boolean;
};

export type ISurveyFeedbackMessage = {
  id: string;
  question: string;
  options: ISurveyFeedbackOption[];
  guideline?: string;
};

// Survey payload
export type ISurveyPayload = {
  channelId: string; // ObjectId
  isEnabled?: boolean;
  timeoutMS?: number;
  type: ECustomerSatisfactionValue;
  message: ISurveyMessagePayload;
};

export type ISurveyImageMapActionPayload = {
  id: string;
  baseUrl: string;
  baseSize: {
    width: number;
    height: number;
  };
  imgUrl: string;
  imgHeight: number;
  imgWidth: number;
  actions: {
    id: string;
    type: string;
    area: {
      height: number;
      width: number;
      x: number;
      y: number;
    };
    point: number;
    message: string;
  }[];
};

export type ISurveyMessagePayload = {
  id?: string;
  question?: string;
  options?: ISurveyQuickReplyActionPayload[];
  imageMap?: ISurveyImageMapActionPayload;
  externalLink?: string;
  isFeedback?: boolean;
  feedbackMessage?: ISurveyFeedbackMessagePayload;
  thxMessage?: string;
};

export type ISurveyQuickReplyActionPayload = {
  id?: string;
  label: string;
  point: number;
};

export type ISurveyFeedbackMessagePayload = {
  id: string;
  question: string;
  options: ISurveyFeedbackOptionPayload[];
  guideline?: string;
};

export type ISurveyFeedbackOptionPayload = {
  label: string;
  value: boolean;
};
