import { getTeamsApi } from '@api/livechat/TeamManagement';
import { assignTicketToAdmin, assignTicketToAgent, assignTicketToTeam } from '@api/livechat/Ticket';
import { getAgentInMyTeam } from '@api/livechat/TicketManagement';
import { IMember } from '@types';
import { AnyEventObject } from 'xstate';
import { ITicketMachineContext } from '.';
import { ERole } from '@enums/Role';

export const assignessState = {
  initial: 'fechingMember',
  states: {
    //member
    fechingMember: {
      tags: 'fetching-assignee',
      invoke: {
        id: 'fetch-my-team-member',
        src: getAgentInMyTeam,
        onDone: {
          target: 'selectingMember',
          actions: 'updateMemberList',
        },
        onError: {
          target: 'fetchMemberFailed',
        },
      },
    },
    selectingMember: {
      on: {
        ASK_TO_CONFIRM_SELECT: {
          target: 'askToConfirmSelectMember',
          actions: 'updateSelectedItem',
        },
      },
    },
    fetchMemberFailed: {},
    //team
    fetchingTeam: {
      tags: 'fetching-assignee',
      invoke: {
        id: 'fetch-all-team',
        src: async () => await getTeamsApi(1, 100),
        onDone: {
          target: 'selectingTeam',
          actions: 'updateTeamList',
        },
        onError: {
          target: 'fetchTeamFailed',
        },
      },
    },
    selectingTeam: {
      on: {
        ASK_TO_CONFIRM_SELECT: {
          target: 'askToConfirmSelectTeam',
          actions: 'updateSelectedItem',
        },
      },
    },
    fetchTeamFailed: {},
    askToConfirmSelectMember: {
      tags: ['confirmSelectAssignee'],
      on: {
        CANCEL_CONFIRM: 'selectingMember',
        CONFIRM_SELECT_ASSIGNEE: 'confirmSelectAssignee',
      },
    },
    askToConfirmSelectTeam: {
      tags: ['confirmSelectAssignee'],
      on: {
        CANCEL_CONFIRM: 'selectingTeam',
        CONFIRM_SELECT_ASSIGNEE: 'confirmSelectAssignee',
      },
    },
    confirmSelectAssignee: {
      tags: ['confirmSelectAssignee'],
      invoke: {
        id: 'confirm-select-asssignee',
        src: async (context: ITicketMachineContext, event: AnyEventObject) => {
          try {
            const ticket = context.ticket;
            const selectedItem = context.selectedItem;
            if (ticket && selectedItem) {
              if (event.historyValue.idle.selectingAssignee === 'askToConfirmSelectMember') {
                // selected agent is admin
                if ((selectedItem as IMember).roles.includes(ERole.ADMIN)) {
                  return await assignTicketToAdmin(ticket._id, {
                    agentId: selectedItem._id,
                  });
                }
                return await assignTicketToAgent(ticket._id, {
                  agentId: selectedItem._id,
                  team: (selectedItem as IMember).teams[0]._id,
                });
              }
              return await assignTicketToTeam(ticket._id, {
                team: selectedItem._id,
              });
            }
          } catch (error) {
            console.error(error);
            throw error;
          }
        },
        onDone: 'assignSuccess',
        onError: 'assignFailed',
      },
    },
    assignSuccess: {
      tags: ['confirmSelectAssignee'],
    },
    assignFailed: {
      tags: ['confirmSelectAssignee'],
      on: {
        CANCEL_CONFIRM: 'selectingMember',
      },
    },
  },
  on: {
    SELECT_MEMBER_TAB: '.fechingMember',
    SELECT_TEAM_TAB: '.fetchingTeam',
  },
};
