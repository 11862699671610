import styled from 'styled-components/macro';

export const AvatarContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
export const QuickReplyWrap = styled.div`
  max-width: 290px;
  padding-bottom: 12px;
  overflow: auto;
  display: flex;
  column-gap: 8px;
  div {
    height: fit-content;
    padding: 7px 10px;
    color: white;
    border-radius: 17px;
    background: rgba(56, 66, 92, 0.4);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
  }
`;
