import styled from 'styled-components/macro';
import { Button as AntdButton, Menu as AntdMenu, Dropdown as AntdDropdown } from 'antd';

export const Container = styled.div`
  display: grid;
  gap: 4px;
  flex: 1;
  span {
    line-height: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.GREY_1};
  }
`;

export const Content = styled.div`
  display: flex;
  column-gap: 8px;
  overflow: hidden;
  .ant-spin-container {
    display: flex;
  }
`;
export const AddButton = styled(AntdButton)`
  vertical-align: 0 !important;
  color: ${({ theme }) => theme.GREY_4} !important;
  border: 1px dashed ${({ theme }) => theme.GREY_6} !important;
`;

export const Menu = styled(AntdMenu)`
  max-height: 300px;
  width: 240px;
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  li {
    display: flex;
    align-items: center;
  }
  & li:hover {
    background: ${({ theme }) => theme.GREY_9};
    color: initial;
  }
`;

export const Dropdown = styled(AntdDropdown)``;

export const SearchInputContainer = styled.div`
  padding: 16px;
  .ant-input-affix-wrapper,
  input {
    border: none;
    box-shadow: none !important;
    background: ${({ theme }) => theme.GREY_9} !important;
  }

  svg {
    color: ${({ theme }) => theme.GREY_5} !important;
  }
`;

export const AddDotButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #757575;
  }
`;

export const Item = styled.li<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  color: ${({ theme, isDisabled }) => (isDisabled ? `${theme.GREY_6} !important` : theme.GREY_1)};
  background-color: ${({ theme, isDisabled }) => (isDisabled ? `${theme.GREY_8} !important` : 'transparent')};
`;
