import React from 'react';
import { Container, Highlight } from './style';

export type ILabelMatchSearch = {
  word: string;
  searchTxt: string;
};

const LabelMatchSearch: React.FC<ILabelMatchSearch> = ({ word, searchTxt }: ILabelMatchSearch) => {
  const label = word;
  return (
    <Container>
      <Highlight
        searchWords={searchTxt.split(' ')}
        highlightStyle={{ fontWeight: 600, background: 'transparent' }}
        autoEscape={true}
        textToHighlight={label}
      />
    </Container>
  );
};

export default LabelMatchSearch;
