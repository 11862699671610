import { useEffect } from 'react';
import { EAppState, appState } from '@store/atom';
import { useSetRecoilState } from 'recoil';

const useRecent = () => {
  const setCurrentAppState = useSetRecoilState(appState);
  useEffect(() => {
    setCurrentAppState(EAppState.LOADING);
  }, []);
};

export default useRecent;
