import React from 'react';
import { Col } from 'antd';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Container, Inner } from './style';
import GeneralManagement from './GeneralManagement';
import SettingHeader from './SettingHeader';
import { TicketTagManagement } from './TicketTagManagement';
import { TemplateCollectionList } from './TemplateCollection/TemplateCollectionList';
import { ROUTE } from '@configs/route';
import { TemplateCollectionEditor } from './TemplateCollection/TemplateCollectionEditor';
import { TicketWrapUp } from './TicketWrapup';
import { ReasonCategoryEditor } from './TicketWrapup/ReasonCategoryEditor';
import { CustomerSatisfaction } from './CustomerSatisfaction';
import { CustomerSatisfactionEditor } from './CustomerSatisfactionEditor';
const regex = new RegExp(/\/settings\/((customer-satisfaction\/survey-customization))\/([^\/].*)/);
const Settings: React.FC = () => {
  const getContainerClass = () => {
    if (regex.test(location.pathname)) {
      return 'm-0 w-full max-w-full flex-1';
    }
    return '';
  };
  const innerClass = regex.test(location.pathname) ? '!p-0' : '';
  const containerClass = getContainerClass();
  return (
    <Container>
      <Inner className={innerClass} id="setting-inner">
        <Col span={20} offset={2} className={containerClass}>
          <SettingHeader />
          <Switch>
            <Route exact path={ROUTE.GENERAL_SETTING}>
              <GeneralManagement />
            </Route>
            <Route exact path={ROUTE.TICKET_TAG}>
              <TicketTagManagement />
            </Route>
            <Route exact path={ROUTE.TEMPLATE_COLLECTION}>
              <TemplateCollectionList />
            </Route>
            <Route exact path={ROUTE.TEMPLATE_COLLECTION_NEW}>
              <TemplateCollectionEditor />
            </Route>
            <Route exact path={ROUTE.TEMPLATE_COLLECTION_EDIT}>
              <TemplateCollectionEditor />
            </Route>
            <Route exact path={ROUTE.TICKET_WRAP_UP}>
              <TicketWrapUp />
            </Route>
            <Route exact path={ROUTE.TICKET_WRAP_REASON_CATEGORY_EDITOR_NEW}>
              <ReasonCategoryEditor />
            </Route>
            <Route exact path={ROUTE.TICKET_WRAP_REASON_CATEGORY_EDITOR_EDIT}>
              <ReasonCategoryEditor />
            </Route>
            <Route exact path={ROUTE.CUSTOMER_SATISFACTION}>
              <CustomerSatisfaction />
            </Route>
            <Route exact path={ROUTE.CUSTOMER_SATISFACTION_CUSTOMIZATION_NEW}>
              <CustomerSatisfactionEditor />
            </Route>
            <Route exact path={ROUTE.CUSTOMER_SATISFACTION_CUSTOMIZATION_EDIT}>
              <CustomerSatisfactionEditor />
            </Route>

            <Route path={ROUTE.SETTINGS}>
              <Redirect to={ROUTE.GENERAL_SETTING} />
            </Route>
          </Switch>
        </Col>
      </Inner>
    </Container>
  );
};

export default Settings;
