import styled from 'styled-components/macro';
import { Menu as AntdMenu, Dropdown as AntdDropdown, Button as AntdButton, Tabs as AntdTabs } from 'antd';

export const Container = styled.div`
  width: 100%;
  button {
    width: 100%;
    display: flex;
    padding: 5px 12px;
    border: 1px solid ${({ theme }) => theme.GREY_7};
    border-radius: 8px;
    height: 36px;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.GREY_1} !important;
    border-color: ${({ theme }) => theme.GREY_7} !important;
    & > div:nth-child(1) {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;
export const Dropdown = styled(AntdDropdown)``;
export const Menu = styled(AntdMenu)`
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  width: 280px;
  & li {
    height: 44px;
    display: flex;
    align-items: center;
  }
  & li > div:nth-child(1) {
    flex: 1;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
      height: 22px;
      width: 22px;
    }
  }

  & li:hover {
    background: ${({ theme }) => theme.GREY_9};
    color: initial;
  }
`;
export const SelectedItem = styled.div`
  display: flex;
  img {
    margin-right: 8px;
    height: 22px;
    width: 22px;
  }
`;

export const Button = styled(AntdButton)``;

export const SearchInputContainer = styled.div`
  padding: 16px;
  .ant-input-affix-wrapper,
  input {
    border: none;
    box-shadow: none !important;
    background: ${({ theme }) => theme.GREY_9} !important;
  }

  svg {
    color: ${({ theme }) => theme.GREY_5} !important;
  }
`;

export const TabContainer = styled.div``;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-tab-btn {
    text-align: center;
    color: ${({ theme }) => theme.GREY_5};
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.GREY_1} !important;
  }
  .ant-tabs-ink-bar {
    border: 1px solid ${({ theme }) => theme.PRIMARY};
  }
`;
export const TabPane = styled(AntdTabs.TabPane)`
  padding-bottom: 14px;
`;

export const SelectedText = styled.p`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
