import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Container } from './style';

export type IChartProps = HighchartsReact.Props;

export const Chart = (props: IChartProps) => {
  return (
    <Container>
      <HighchartsReact
        {...props}
        options={{
          ...props.options,
          credits: {
            enabled: false,
          },
        }}
        highcharts={Highcharts}
      />
    </Container>
  );
};
