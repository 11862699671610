import React, { ReactElement } from 'react';
import { Checkbox } from '@components/Checkbox';
import RangePicker from '@components/DateRangePicker';
import { TertiaryButton } from '@components/Button';
import SearchInput from '@components/SearchInput';
import { Col, Row, Button as AntdButton, Typography, Divider } from 'antd';
import { Container, GroupBox, TabBox, ExportContainer, ExportText, SelectContainer } from './style';
import TicketsTable from './TicketsTable';
import useAllTicketsPane from './useAllTicketsPane';
import useTranslation from '@hooks/useTranslation';
import { DATE_OPTIONS } from '@configs/dayjs';
import Select from '@components/Select';
import { TICKET_MANAGEMENT_MAX_API_SIZE, TICKET_SORT_OPTION, TICKET_STATUS_OPTION } from '@configs/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { AlertModal, ConfirmModal } from '@components/Modal';
import { Font14G3W400, Font16G1W400, Font20G1W600, Spacing } from '@components/UtilsComponent';
import Exclaimation from '@assets/images/exclaimation.svg';
import StateMessage from '@components/StateMessage';
import { DropdownFilterTag } from '../DropdownFilterTag';
import { ChannelFilter } from '@components/ChannelFilter';
import { AssigneeFilter } from '@components/AssigneeFilter';

function AllTicketsPane(): ReactElement {
  const t = useTranslation();
  const {
    newContext,
    newCurrent,
    isDisplayExportModal,
    isDisabledExportConfirmBtn,
    isExporting,
    isExportFailed,
    onFilterChange,
    onClearAllFilter,
    onClickExport,
    onCloseExportModal,
    onConfirmExport,
    onCheckReport,
    canExportReport,
  } = useAllTicketsPane();
  return (
    <Container>
      <GroupBox>
        <Row gutter={[8, 0]}>
          <Col span={8}>
            <SearchInput
              data-testid="test-search-input"
              value={newContext.filter.keyword ?? ''}
              placeholder={t('livechat.ticket.management.ticket.pane.table.filter.search.placeholder')}
              onChange={(e) => onFilterChange('keyword', e.target.value)}
              onClear={() => onFilterChange('keyword', '')}
            />
          </Col>
          <Col>
            <Divider className="h-full" type={'vertical'} orientation={'right'} />
          </Col>
          <Col className="flex items-center">
            <Checkbox
              data-testid="test-checkbox-only-follow-up"
              checked={newContext.filter.onlyFollowUp}
              onChange={(e) => onFilterChange('onlyFollowUp', e.target.checked)}
            >
              {t('livechat.ticket.management.ticket.pane.table.filter.follow.up')}
            </Checkbox>
            <Checkbox
              data-testid="test-checkbox-only-team-follow-up"
              checked={newContext.filter.onlyTeamFollowUp}
              onChange={(e) => onFilterChange('onlyTeamFollowUp', e.target.checked)}
            >
              {t('livechat.ticket.management.ticket.pane.table.filter.team.follow.up')}
            </Checkbox>
          </Col>
          {/* export section */}
          {canExportReport() && (
            <ExportContainer flex={1}>
              <TertiaryButton onClick={onClickExport} data-testid="export-btn">
                <ExportText>{t('livechat.ticket.management.ticket.pane.table.filter.export.btn')}</ExportText>
                <FontAwesomeIcon icon={faFileExport} />
              </TertiaryButton>
            </ExportContainer>
          )}
        </Row>
      </GroupBox>

      <GroupBox>
        <Row gutter={[16, 0]} align={'bottom'}>
          <Col span={3}>
            <Typography.Title level={5}>
              {t('livechat.ticket.management.ticket.pane.table.filter.channel')}
            </Typography.Title>
            <ChannelFilter
              selectedChannels={newContext.filter.selectedChannels}
              onSelectChannel={(items) => onFilterChange('selectedChannels', items)}
            />
          </Col>
          <Col span={3}>
            <Typography.Title level={5}>
              {t('livechat.ticket.management.ticket.pane.table.filter.assignee')}
            </Typography.Title>
            <AssigneeFilter
              selectedAssignee={newContext.filter.selectedAssignees}
              onSelectAssignee={(items: string[]) => {
                onFilterChange('selectedAssignees', items);
              }}
            />
          </Col>
          <Col span={3}>
            <Typography.Title level={5}>
              {t('livechat.ticket.management.ticket.pane.table.filter.status')}
            </Typography.Title>
            <Select
              data-testid="test-select-status"
              onChange={(value: string) => onFilterChange('selectedStatus', value)}
              value={newContext.filter.selectedStatus}
              placeholder={t('livechat.ticket.management.ticket.pane.table.filter.status.placeholder')}
              options={TICKET_STATUS_OPTION}
            />
          </Col>
          <Col span={4}>
            <Typography.Title level={5}>
              {t('livechat.ticket.management.ticket.pane.table.filter.sort.by')}
            </Typography.Title>
            <SelectContainer>
              <Select
                data-testid="test-select-sort-by"
                onChange={(value: string) => onFilterChange('selectedSortBy', value)}
                value={newContext.filter.selectedSortBy}
                placeholder={t('livechat.ticket.management.ticket.pane.table.filter.sort.by.placeholder')}
                options={TICKET_SORT_OPTION}
              />
            </SelectContainer>
          </Col>
          <Col span={3}>
            <Typography.Title level={5}>
              {t('livechat.ticket.management.ticket.pane.table.filter.tags')}
            </Typography.Title>

            <DropdownFilterTag
              selectedTag={newContext.filter.selectedTags}
              onSelectDropdown={(items: string[]) => onFilterChange('selectedTags', items)}
            />
          </Col>

          <Col span={4}>
            <Typography.Title level={5}>
              {t('livechat.ticket.management.ticket.pane.table.date.range')}
            </Typography.Title>
            <RangePicker
              data-testid="test-range-picker"
              value={newContext.filter.selectedDateRange}
              onDateRangeChange={(range) => {
                onFilterChange('selectedDateRange', range);
              }}
              picker="date"
              ranges={DATE_OPTIONS}
            />
          </Col>
          <Col>
            <AntdButton type={'link'} onClick={onClearAllFilter} data-testid="test-clear-filter-btn">
              <Typography.Text type={'secondary'} strong>
                {t('livechat.ticket.management.ticket.pane.table.filter.clear')}
              </Typography.Text>
            </AntdButton>
          </Col>
        </Row>
      </GroupBox>
      <TabBox>
        <TicketsTable
          {...{
            tickets: newContext.rows,
            filterData: {
              page: newContext.filter.page,
              pageSize: TICKET_MANAGEMENT_MAX_API_SIZE,
              total: newContext.filter.total,
            },
            onPageChange: (page: number) => onFilterChange('page', page),
            isLoading: newCurrent.matches({ fetchTableState: 'fetchingTicket' }),
          }}
        />
      </TabBox>
      {/* Export modal */}
      <ConfirmModal
        destroyOnClose={true}
        visible={isDisplayExportModal}
        confirmBtnTxt={t('confirm.btn')}
        cancelBtnTxt={t('cancel.btn')}
        handleClose={onCloseExportModal}
        handleConfirm={onConfirmExport}
        isDisabledConfirmBtn={isDisabledExportConfirmBtn}
        isLoading={isExporting}
      >
        <Font20G1W600>{t('livechat.ticket.management.ticket.pane.export.modal.title')}</Font20G1W600>
        <Spacing height={4} />
        <Font14G3W400>{t('livechat.ticket.management.ticket.pane.export.modal.description')}</Font14G3W400>
        <Divider
          style={{
            marginTop: '16px',
            marginBottom: '16px',
            backgroundColor: '#E5E5E5',
          }}
        />
        <div>
          <Row align="middle" gutter={[10, 0]}>
            <Col>
              <Row align="middle">
                <Checkbox
                  id="messageReport-check"
                  checked={newContext.report.messageReport}
                  onChange={(e) => onCheckReport('messageReport', e.target.checked)}
                />
              </Row>
            </Col>
            <Col>
              <label htmlFor="messageReport-check">
                <Font16G1W400>
                  {t('livechat.ticket.management.ticket.pane.export.modal.chat.report.label')}{' '}
                </Font16G1W400>
              </label>
            </Col>
          </Row>

          <Spacing height={12} />
          <Row align="middle" gutter={[10, 0]}>
            <Col>
              <Row align="middle">
                <Checkbox
                  id="ticketReport-check"
                  checked={newContext.report.ticketReport}
                  onChange={(e) => onCheckReport('ticketReport', e.target.checked)}
                />
              </Row>
            </Col>
            <Col>
              <label htmlFor="ticketReport-check">
                <Font16G1W400>
                  {t('livechat.ticket.management.ticket.pane.export.modal.ticket.report.label')}
                </Font16G1W400>
              </label>
            </Col>
          </Row>
        </div>
      </ConfirmModal>
      <AlertModal visible={isExportFailed} closable={false} footer={null} destroyOnClose>
        <StateMessage
          img={Exclaimation}
          label={t('livechat-error-get-action-label')}
          description={t('livechat-error-get-action-description')}
          size="large"
          type="success"
        >
          <Spacing height={16} />
          <TertiaryButton onClick={onCloseExportModal} size="large" data-testid="test-error-ok-btn">
            {t('livechat.401.btn')}
          </TertiaryButton>
        </StateMessage>
      </AlertModal>
    </Container>
  );
}

export default AllTicketsPane;
