import styled from 'styled-components/macro';

export const RightGroup = styled.div`
  display: flex;
  border-radius: 8px;
  padding: 4px 2px;
  gap: 2px;
  align-items: center;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.GREY_7};
`;

export const SelectedCustomDateWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  background-color: ${({ theme }) => theme.GREY_9};
  border-radius: 8px;
  padding: 6px 12px;
  cursor: pointer;
  white-space: nowrap;
`;

export const SelectedDateIntervalWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  background-color: ${({ theme }) => theme.GREY_9};
  border-radius: 8px;
  padding: 6px 12px;
  white-space: nowrap;
`;

export const ColumnDivider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.GREY_7};
`;
