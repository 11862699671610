import { EMessageType } from '@enums/MessageType';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { ITextType, IMessageLevel } from '@types';

export type TextMessageJson = {
  text: string;
  type?: ITextType;
} & BaseMessageJson;

export class TextMessage extends BaseMessage {
  private text: string;
  private textType?: ITextType;
  private level: IMessageLevel;

  constructor(data: TextMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.text = !data.text ? 'Get Started' : data.text; // not garuntee that it will be first message
    this.textType = data.type ?? 'first';
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      text: this.text,
      textType: this.textType,
      createdAt: this.createdAt,
      level: this.level,
      retry: this.retry,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  get messagePayload() {
    return {
      text: this.text,
    };
  }

  get getMessageType() {
    return EMessageType.TEXT;
  }

  set setTextType(textType: ITextType) {
    this.textType = textType;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
