import styled from 'styled-components/macro';
import { Menu as AntdMenu, Tag } from 'antd';

export const BaseTag = styled(Tag)`
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.07);
`;

export const BaseTagClickable = styled(Tag)`
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.07);
  cursor: pointer;
`;

export const Menu = styled(AntdMenu)`
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  width: 280px;
  & li {
    height: 44px;
    display: flex;
    align-items: center;
  }
  & li > div:nth-child(1) {
    flex: 1;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
      height: 22px;
      width: 22px;
    }
  }

  & li:hover {
    background: ${({ theme }) => theme.GREY_9};
    color: initial;
  }
`;
export const HiddenMenu = styled(AntdMenu)`
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  max-width: auto;
  max-height: 250px;
  padding: 16px;
  overflow-y: auto;
  & li {
    height: 44px;
    display: flex;
    align-items: center;
  }
  & li > div:nth-child(1) {
    flex: 1;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
      height: 22px;
      width: 22px;
    }
  }

  & li:hover {
    background: ${({ theme }) => theme.GREY_9};
    color: initial;
  }
`;

export const HiddenMenuWrap = styled.div`
  display: grid;
  width: fit-content;
  row-gap: 8px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  .ant-tag {
    width: fit-content;
  }
`;
