import styled from 'styled-components/macro';

export const Container = styled.div``;

export const SelectorContainer = styled.div`
  display: flex;
  padding: 16px;
  background: white;
  border-radius: 12px;
  flex-direction: column;
  row-gap: 16px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
  width: 640px;
`;

export const InputWrap = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

export const DateBox = styled.div<{ isSelected: boolean }>`
  width: 220px;
  display: flex;
  column-gap: 8px;
  border: 1px solid ${({ isSelected, theme }) => (isSelected ? theme.PRIMARY : theme.GREY_7)};
  border-radius: 8px;
  padding: 8px 12px;
  height: 36px;
  align-items: center;
  cursor: pointer;
`;

// Datepicker overlay
export const DatePickerOverlay = styled.div`
  margin-top: 8px;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background: white;
  padding: 12px 8px;
  width: 640px;
`;

export const DatePickerTopSection = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  margin-left: -8px;
  margin-right: -8px;
`;
export const DateWidgeContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DateWidget = styled.div`
  width: 320px;
  padding: 24px 12px 16px;
  flex: 1;
`;

export const DateWidgetEnd = styled.div`
  padding: 24px 16px 16px;
  flex: 1;
  width: 320px;
`;

export const StartDateTopBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  svg {
    cursor: pointer;
  }
`;

export const EndDateTopBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  height: 28px;
  svg {
    cursor: pointer;
  }
`;

export const StartDateOfMonthWrap = styled.div`
  margin-top: 14px;
  display: flex;
  column-gap: 8px;
  row-gap: 16px;
  flex-wrap: wrap;
`;

export const EndDateOfMonthWrap = styled.div`
  margin-top: 14px;
  display: flex;
  column-gap: 8px;
  row-gap: 16px;
  flex-wrap: wrap;
`;

export const EndDatePickerWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectMonthContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
`;
