import {
  ERROR_FILE_COUNT_EXCEED,
  ERROR_FILE_EXCEED_SPEC_SIZE,
  ERROR_FILE_TOTAL_SIZE_EXCEED,
  MAX_FILE_UPLOAD,
  MAX_TOTAL_FILES_SIZE,
} from '@configs/constants';
import { ITicket } from '@types';
import { Upload } from 'antd';
import filterFileByChannel from './filterFileByChannel';
import EChannelType from '@enums/ChannelType';
import { RefObject } from 'react';

export const validateFiles = (
  ticket: ITicket,
  fileList: File[],
  uploadRef: RefObject<HTMLButtonElement>,
  updateAlertDetail: (title: string, description: string, button: string) => void,
  openModal: (callback: () => void) => void,
) => {
  // uploaded file more than 10
  if (fileList.length > MAX_FILE_UPLOAD) {
    updateAlertDetail(
      ERROR_FILE_COUNT_EXCEED.TITLE,
      ERROR_FILE_COUNT_EXCEED.DESCRIPTION,
      ERROR_FILE_COUNT_EXCEED.BUTTON,
    );
    openModal(() => {
      if (uploadRef.current) {
        uploadRef.current.click();
      }
    });
    return Upload.LIST_IGNORE;
  }
  const rejectedFiles = fileList.filter(
    (f) => !filterFileByChannel(ticket?.channelType as EChannelType | undefined, f),
  );

  // sum files size
  if (rejectedFiles.length > 0) {
    updateAlertDetail(
      ERROR_FILE_EXCEED_SPEC_SIZE.TITLE,
      ERROR_FILE_EXCEED_SPEC_SIZE.DESCRIPTION,
      ERROR_FILE_EXCEED_SPEC_SIZE.BUTTON,
    );
    openModal(() => {
      if (uploadRef.current) {
        uploadRef.current.click();
      }
    });
    return Upload.LIST_IGNORE;
  }
  const totalSize = fileList.reduce((acc, cur) => acc + cur.size, 0);
  // total upload file size exceed 100 mb
  if (totalSize > MAX_TOTAL_FILES_SIZE) {
    updateAlertDetail(
      ERROR_FILE_TOTAL_SIZE_EXCEED.TITLE,
      ERROR_FILE_TOTAL_SIZE_EXCEED.DESCRIPTION,
      ERROR_FILE_TOTAL_SIZE_EXCEED.BUTTON,
    );
    openModal(() => {
      if (uploadRef.current) {
        uploadRef.current.click();
      }
    });

    return Upload.LIST_IGNORE;
  }

  return true;
};
