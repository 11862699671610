import { ETicketStatus } from '@enums/TicketStatus';
import { IAllTicketPaneContext } from '.';
import { IReportResponse, ISelectedTag, ITicket } from '@types';
import { DateRange } from '@components/DateRangePicker/useDateRangePicker';

export enum EFilterActionType {
  LOAD_MORE_STARTER = 'LOAD_MORE_STARTER',
  UPDATE_FILTER_DATA = 'UPDATE_FILTER_DATA',
  CLEAR_ALL_FILTER_DATA = 'CLEAR_ALL_FILTER_DATA',
  DONE_FETCHING_LIST = 'done.invoke.allTicketPane.fetchTableState.fetchingTicket:invocation[0]',
  UPDATE_PAGE = 'UPDATE_PAGE',
  EXPORT_REPORT_IDLE = 'EXPORT_REPORT_IDLE',
  EXPORT_REPORT = 'EXPORT_REPORT',
  EXPORT_REPORT_CONFIRM = 'EXPORT_REPORT_CONFIRM',
  EXPORT_REPORT_CANCEL = 'EXPORT_REPORT_CANCEL',
  UPDATE_SELECTED_REPORT = 'UPDATE_SELECTED_REPORT',
  UPDATE_SELECTED_DOWNLOAD_REPORT = 'done.invoke.export-report',
  PREPARE_DATA = 'PREPARE_DATA',
}

export type IFilterValue = string | string[] | ETicketStatus | number | boolean | DateRange | ISelectedTag[];

export type IAllTicketPaneEvent =
  | {
      type: EFilterActionType.UPDATE_FILTER_DATA;
      payload: {
        fieldName: keyof IAllTicketPaneContext['filter'];
        value: IFilterValue;
      };
    }
  | {
      type: EFilterActionType.CLEAR_ALL_FILTER_DATA;
    }
  | {
      type: EFilterActionType.DONE_FETCHING_LIST;
      data: {
        data: {
          page: number;
          total: number;
          totalPages: number;
          rows: ITicket[];
        };
      };
    }
  | {
      type: EFilterActionType.UPDATE_PAGE;
      payload: {
        page: number;
      };
    }
  | {
      type: EFilterActionType.EXPORT_REPORT;
    }
  | {
      type: EFilterActionType.EXPORT_REPORT_CONFIRM;
    }
  | {
      type: EFilterActionType.EXPORT_REPORT_CANCEL;
    }
  | {
      type: EFilterActionType.UPDATE_SELECTED_REPORT;
      payload: {
        key: keyof IAllTicketPaneContext['report'];
        value: IAllTicketPaneContext['report'][keyof IAllTicketPaneContext['report']];
      };
    }
  | {
      type: EFilterActionType.UPDATE_SELECTED_DOWNLOAD_REPORT;
      data: IReportResponse;
    }
  | {
      type: EFilterActionType.EXPORT_REPORT_IDLE;
    };
