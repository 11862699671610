import { IApiErrorMessage } from '@types';
import { assign, createMachine } from 'xstate';

export type ITableMachineParams<T> = {
  fetchOnInit: boolean;
  fetchFn: (
    behavior: 'next' | 'prev' | 'none',
    currentPage: number,
    nextCursorId?: string,
    prevCursorId?: string,
    keyword?: string,
  ) => Promise<ITableMachineContext<T>['list'] | undefined>;
};

export type ITableMachineContext<T> = {
  searchKeyword: string;
  fetchCount: number;
  list: {
    data: T[];
    currentPage: number;
    nextCursorId?: string;
    prevCursorId?: string;
    disabledNext: boolean;
  };
  errorMessage: IApiErrorMessage;
};

export type TableMachineEvent<T> =
  | { type: 'xstate.init'; behavior?: 'next' | 'prev' | 'none' }
  | { type: 'UPDATE_SEARCH_KEYWORD'; keyword: string }
  | { type: 'FETCH'; behavior?: 'next' | 'prev' | 'none' }
  | { type: 'FETCH_MORE'; behavior?: 'next' | 'prev' | 'none' }
  | { type: 'done.invoke.fetching'; data: ITableMachineContext<T> }
  | { type: 'done.invoke.fetching-more'; data: ITableMachineContext<T>['list'] };

export const tableMachine = <T>({ fetchOnInit, fetchFn }: ITableMachineParams<T>) =>
  createMachine<ITableMachineContext<T>, TableMachineEvent<T>>(
    {
      id: 'tableMachine',
      initial: fetchOnInit ? 'fetching' : 'idle',
      context: {
        searchKeyword: '',
        fetchCount: 0,
        list: {
          data: [],
          currentPage: 1,
          nextCursorId: '',
          prevCursorId: '',
          disabledNext: false,
        },
        errorMessage: {
          title: '',
          description: '',
          errorCode: undefined,
        },
      },
      states: {
        idle: {},
        fetching: {
          invoke: {
            id: 'fetching',
            src: 'fetchData',
            onDone: {
              target: 'success',
              actions: ['replaceData', 'changeFirstFetchState'],
            },
            onError: {
              target: 'failure',
            },
          },
        },
        success: {},
        failure: {},
        loadingMore: {
          invoke: {
            id: 'fetching-more',
            src: 'fetchData',
            onDone: {
              target: 'success',
              actions: ['pushContent'],
            },
            onError: {
              target: 'failure',
            },
          },
        },
      },
      on: {
        UPDATE_SEARCH_KEYWORD: {
          actions: 'updateSearchKeyword',
        },
        FETCH: {
          target: '.fetching',
        },
        FETCH_MORE: {
          target: '.loadingMore',
        },
      },
    },
    {
      actions: {
        changeFirstFetchState: assign({
          fetchCount: (context, event) => {
            if (event.type === 'done.invoke.fetching') {
              context.fetchCount++;
              return context.fetchCount;
            }
            return context.fetchCount;
          },
        }),
        updateSearchKeyword: assign({
          searchKeyword: (context, event) => {
            if (event.type === 'UPDATE_SEARCH_KEYWORD') {
              return event.keyword;
            }
            return context.searchKeyword;
          },
        }),
        replaceData: assign({
          list: (context, event) => {
            if (event.type === 'done.invoke.fetching') {
              const data = event.data;
              return {
                ...context.list,
                ...data,
              };
            }
            return context.list;
          },
        }),
        pushContent: assign({
          list: (context, event) => {
            if (event.type === 'done.invoke.fetching-more') {
              const d = event.data;

              return {
                ...context.list,
                data: [...context.list.data, ...d.data],
                currentPage: d.currentPage,
                nextCursorId: d.nextCursorId,
                prevCursorId: d.prevCursorId,
                disabledNext: d.disabledNext,
              };
            }
            return context.list;
          },
        }),
      },
      services: {
        fetchData: async (context, event) => {
          if (event.type === 'xstate.init' || event.type === 'FETCH' || event.type === 'FETCH_MORE') {
            const { behavior = 'none' } = event;
            const result = fetchFn(
              behavior,
              context.list.currentPage,
              context.list.nextCursorId,
              context.list.prevCursorId,
              context.searchKeyword,
            );
            return result;
          }
        },
      },
    },
  );
