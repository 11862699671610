import { client } from '@api';
import { FindTicketParams } from '@pages/desktop/ticket_management/AllTicketsPane/fns/findTicket';
import qs from 'qs';

export const findTicket = async (payload: FindTicketParams) => {
  const { keyword } = payload;
  let url = '/api/tickets/filter';
  if (keyword) {
    url = '/api/tickets/search';
  }
  const queryString = qs.stringify(payload);
  return client.get(`${url}?${queryString}`);
};
