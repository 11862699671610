import styled, { createGlobalStyle } from 'styled-components/macro';
import { Select as AntdSelect, Modal } from 'antd';

export const { OptGroup, Option } = AntdSelect;

export const DropdownOpenStyle = createGlobalStyle`
 .assignee-dropdown .ant-tabs-tab-btn {
    min-width: 108px !important;
  }
`;

export const Container = styled.div`
  display: grid;
  gap: 4px;
  width: 191px;
  span {
    line-height: 20px;
    height: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.GREY_1};
  }
`;
export const Select = styled(AntdSelect)`
  border: 1px solid ${({ theme }) => theme.GREY_7};
  width: 100%;
  border: none;
  .ant-select-selector {
    border-radius: 0.5rem !important;
    height: 36px !important;
    /* selected option */
    .ant-select-selection-item {
      height: 100%;
      font-weight: 400;
      color: ${({ theme }) => theme.GREY_1};
    }
  }
`;

export const SearchInputContainer = styled.div`
  padding: 16px;
  .ant-input-affix-wrapper,
  input {
    border: none;
    box-shadow: none !important;
    background: ${({ theme }) => theme.GREY_9} !important;
  }

  svg {
    color: ${({ theme }) => theme.GREY_5} !important;
  }
`;

export const DropDownContainer = styled.div`
  max-height: 400px;
  .ant-select-item-group {
    padding: 8px 16px;
    height: 28px;
    display: flex;
    align-items: center;
  }
  .ant-select-item-option {
    height: 44px !important;
    padding: 0 16px;
  }
  .ant-select-item.ant-select-item-option:hover {
    background: ${({ theme }) => theme.GREY_9} !important;
  }
  .ant-select-item-option-content {
    display: flex !important;
    align-items: center;
    img {
      width: 21px;
      height: 21px;
      margin-right: 7px;
    }
  }
`;

export const LabelContainer = styled.div`
  span {
    font-weight: 400;
    line-height: 20px;
  }
  strong {
    font-weight: 600;
    line-height: 20px;
  }
`;

export const dropDownStyle = {
  borderRadius: '8px',
  overflow: 'hidden',
  marginTop: '3.5px',
};

export const ActionContainer = styled.div`
  margin-top: 23px;
  display: flex;
  justify-content: flex-end;
  & button:nth-child(1) {
    margin-right: 8px;
  }
`;

export const ConfirmModal = styled(Modal)`
  top: 250px;
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 400px;
    margin: 0 auto;
    border-radius: 12px;
    .ant-modal-body {
      display: grid;
      gap: 8px;
      & div:nth-child(1) span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: ${({ theme }) => theme.GREY_1};
      }
      & div:nth-child(2) span {
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.GREY_1};
      }
    }
  }
`;
