import styled from 'styled-components/macro';

export const SwitchContainer = styled.div<{ size?: 'small' | 'large' | 'default' }>`
  .ant-switch-checked {
    background: ${({ theme }) => theme.PRIMARY};
  }
  .ant-switch {
    width: ${({ size }) => size === 'large' && '48px'};
    height: ${({ size }) => size === 'large' && '28px'};
    .ant-switch-handle {
      width: ${({ size }) => size === 'large' && '24px'};
      height: ${({ size }) => size === 'large' && '24px'};
    }
    .ant-switch-handle::before {
      border-radius: ${({ size }) => size === 'large' && '50%'};
    }
  }
  .ant-switch-checked .ant-switch-handle {
    left: ${({ size }) => size === 'large' && 'calc(100% - 26px) !important'};
  }
`;
