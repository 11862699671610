import React, { useCallback, useEffect, useRef } from 'react';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { IOption } from '@components/Select';
import { SelectBtn } from '@components/SelectBtn';
import { Checkbox } from '@components/Checkbox';
import { DropdownItem } from './style';
import { cloneDeep } from 'lodash';
import { ITeam } from '@types';
import { getMyTeam } from '@api/livechat/TeamManagement';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';

type ITeamFilterProps = {
  selectedTeam: string[];
  onSelectTeam: (items: string[]) => void;
};

type ITeamWithId = ITeam & { id: string };

export const TeamFilter = ({ selectedTeam = [], onSelectTeam }: ITeamFilterProps) => {
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const { data, firstLoading, hasMore, onOpenDropdown, onLoadmore } = useInfinityLoadByCursorId<ITeamWithId, IOption>({
    fetchFn: async (filter, cancelToken) => {
      const res = await getMyTeam(filter);
      const result = res.data.map((item: ITeamWithId) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
      return result;
    },
  });
  const selectedRef = useRef<string[]>([]);
  const _renderSelectedText = useCallback((selected: string[]): React.ReactNode => {
    // if length more than 1
    if (selected.length > 1) {
      return `${selected.length} teams selected`;
    }
    if (selected.length === 1) {
      return `1 team selected`;
    }
    return '';
  }, []);

  useEffect(() => {
    selectedRef.current = selectedTeam;
  }, [selectedTeam]);
  return (
    <>
      <DropdownDynamicBtn
        showClearAll
        onClearAllSelection={() => {
          selectedRef.current = [];
          onSelectTeam(selectedRef.current);
        }}
        searchKeyword={searchKeyword}
        onUpdateSearchKeyword={(keyword) => setSearchKeyword(keyword)}
        options={data}
        isFirstLoading={firstLoading}
        renderOptions={(options) => {
          const filtered = options.filter((item) => {
            return item.label.toLowerCase().includes(searchKeyword.toLowerCase());
          });
          return filtered.map((item) => {
            return (
              <DropdownItem
                key={item.value}
                onClick={() => {
                  const clone = cloneDeep(selectedRef.current);
                  const index = clone.findIndex((i) => {
                    return i === item.id;
                  });
                  // check if exist then remove
                  if (index > -1) {
                    clone.splice(index, 1);
                    onSelectTeam(clone);
                    return;
                  }
                  clone.push(item.id);
                  selectedRef.current = clone;
                  onSelectTeam(clone);
                }}
              >
                <span>{item.label}</span>
                <Checkbox
                  checked={
                    !!selectedTeam.find((i) => {
                      return i === item.id;
                    })
                  }
                />
              </DropdownItem>
            );
          });
        }}
        renderBtn={function (
          prevState: boolean,
          setter: (status: React.SetStateAction<boolean>) => void,
        ): React.ReactNode {
          return (
            <SelectBtn
              data-testid="test-team-filter-btn"
              selectedText={_renderSelectedText(selectedTeam)}
              placeholder={'All Teams'}
              onClick={() => {
                // toggle dropdown
                setter(!prevState);
                if (!prevState) {
                  onOpenDropdown();
                }
              }}
            />
          );
        }}
        hasMore={hasMore}
        onLoadMore={onLoadmore}
      />
    </>
  );
};
