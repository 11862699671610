import { useConfirm } from '@hooks/useConfirm';
import { useAlertModal } from '@providers/AlertModalProvider';
import { useState } from 'react';
import { useMutation } from 'react-query';

export type IUseDeleteParams<T, R> = {
  deleteFn: (payload: T) => Promise<R>;
};

// must call inside AlertModalProvider
export const useDelete = <T, R>({ deleteFn }: IUseDeleteParams<T, R>) => {
  const [selectForDelete, setSelectForDelete] = useState<T | null>(null);
  const deleteMut = useMutation((payload: T) => {
    return deleteFn(payload);
  });
  const confirmModal = useConfirm();
  const { onOpen } = useAlertModal();
  return {
    selectForDelete,
    confirmModal,
    deleteMut,
    onDelete: async (payload: T) => {
      try {
        await deleteMut.mutateAsync(payload);
      } catch (error) {
        onOpen();
        throw error;
      }
    },
    onSelectForDelete: (item: T | null) => {
      setSelectForDelete(item);
    },
  };
};
