import React from 'react';
import { Font14G1W400, Font14G5W400, Font16G1W600, Font20G1W600, FrameV2 } from '@components/UtilsComponent';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCalendarAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropDownProps } from 'antd';
import {
  Container,
  DateBox,
  DatePickerOverlay,
  DatePickerTopSection,
  InputWrap,
  SelectorContainer,
  StartDateTopBar,
  DateWidgeContent,
  EndDateTopBar,
  SelectMonthContainer,
  DateWidget,
} from './style';
import { EStep, useDateRangePicker } from './useDateRangePicker';
import { Calendar } from './Calendar';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { GhostButton, PrimaryButton } from '@components/Button';

export type ISubmitCustomDatePayload = {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
};

export type IDateRangePickerV2Props = {
  showTime?: boolean;
  visible: boolean;
  isClearEvent?: boolean;
  children?: React.ReactNode;
  isDisabledFutureDate?: boolean;
  disablePrevDate?: dayjs.Dayjs;
  onSubmit: ({ startDate, endDate, startTime, endTime }: ISubmitCustomDatePayload) => void;
} & Omit<DropDownProps, 'overlay'>;

export enum EDateInterval {
  LAST_24_H = 'L24H',
  TODAY = 'TODAY',
  LAST_7_D = 'L7D',
  LAST_30_D = 'L30D',
  LAST_3_M = 'LM',
  LAST_6_M = 'L6M',
  LAST_12_M = 'L12M',
  CUSTOM = 'CUSTOM',
}

export const DATE_INTERVAL = [
  {
    label: 'Custom',
    value: EDateInterval.CUSTOM,
  },
  {
    label: 'Last 24H',
    value: EDateInterval.LAST_24_H,
  },
  {
    label: '7D',
    value: EDateInterval.LAST_7_D,
  },
  {
    label: '30D',
    value: EDateInterval.LAST_30_D,
  },
  {
    label: '3M',
    value: EDateInterval.LAST_3_M,
  },
  {
    label: '6M',
    value: EDateInterval.LAST_6_M,
  },
  {
    label: '12M',
    value: EDateInterval.LAST_12_M,
  },
];

export const DateRangePickerV2 = ({
  showTime,
  children,
  visible,
  isClearEvent,
  isDisabledFutureDate = true,
  disablePrevDate,
  onSubmit,
  ...rest
}: IDateRangePickerV2Props) => {
  const {
    step,
    hoverDate,
    selectedDate,
    startDate,
    endDate,
    startDateArr,
    endDateArr,
    onClickDateBox,
    onSelectDate,
    onSelectTime,
    onClickNext,
    onClickPrev,
    onClear,
    onHoverDate,
  } = useDateRangePicker(isClearEvent);

  const _renderDateWidget = (type: 'start' | 'end') => {
    // left calendar
    if (type === 'start') {
      return (
        <DateWidget>
          <StartDateTopBar>
            {/* prev year */}
            <FrameV2 centered width={28} height={28} onClick={() => onClickPrev('year')}>
              <FontAwesomeIcon icon={faAngleDoubleLeft} fontSize={20} />
            </FrameV2>
            {/* prev month */}
            <FrameV2 centered width={28} height={28} onClick={() => onClickPrev('month')}>
              <FontAwesomeIcon icon={faAngleLeft} fontSize={20} />
            </FrameV2>
            <SelectMonthContainer>
              <FrameV2 centered width={144} height={28}>
                <Font16G1W600>{startDate.format('MMM YYYY')}</Font16G1W600>
              </FrameV2>
            </SelectMonthContainer>
          </StartDateTopBar>
          <Calendar
            showTime={showTime}
            isStartDate
            dateArr={startDateArr}
            onSelectDate={(date) => onSelectDate(date, step === EStep.START ? 'start' : 'end')}
            onSelectTime={(dateTime) => onSelectTime(dateTime, 'start')}
            timepicker={{
              label: 'Start time',
            }}
            disablePrevDate={disablePrevDate}
            startTime={selectedDate.startTime}
            endTime={selectedDate.endTime}
            selectedTime={selectedDate.startTime}
            isDisabledFutureDate={isDisabledFutureDate}
            hoverDate={hoverDate}
            onHoverDate={onHoverDate}
            startDate={selectedDate.startDate}
            endDate={selectedDate.endDate}
          />
        </DateWidget>
      );
    }

    // right calendar
    return (
      <DateWidget>
        <EndDateTopBar>
          <SelectMonthContainer>
            <FrameV2 centered width={144} height={28}>
              <Font16G1W600>{endDate.format('MMM YYYY')}</Font16G1W600>
            </FrameV2>
          </SelectMonthContainer>
          {/* display if end date is before or same day with today*/}
          {!isDisabledFutureDate || endDate.isSame(dayjs(), 'day') || endDate.isBefore(dayjs(), 'day') ? (
            <>
              {/* next month */}
              <FrameV2 centered width={28} height={28} onClick={() => onClickNext('month')}>
                <FontAwesomeIcon icon={faAngleRight} fontSize={20} />
              </FrameV2>
              {/* next year */}
              <FrameV2 centered width={28} height={28} onClick={() => onClickNext('year')}>
                <FontAwesomeIcon icon={faAngleDoubleRight} fontSize={20} />
              </FrameV2>
            </>
          ) : null}
        </EndDateTopBar>
        {/* generate days */}
        <Calendar
          dateArr={endDateArr}
          disablePrevDate={disablePrevDate}
          onSelectDate={(date) => onSelectDate(date, step === EStep.START ? 'start' : 'end')}
          onSelectTime={(dateTime) => onSelectTime(dateTime, 'end')}
          isDisabledFutureDate={isDisabledFutureDate}
          timepicker={{
            label: 'End time',
          }}
          startTime={selectedDate.startTime}
          endTime={selectedDate.endTime}
          selectedTime={selectedDate.endTime}
          hoverDate={hoverDate}
          onHoverDate={onHoverDate}
          startDate={selectedDate.startDate}
          endDate={selectedDate.endDate}
        />
      </DateWidget>
    );
  };
  const _renderOverlay = () => {
    return (
      <div>
        <SelectorContainer>
          <Font16G1W600>Select date and time</Font16G1W600>
          <InputWrap>
            {/* if start date is not selected active this box */}
            <DateBox isSelected={step === EStep.START} onClick={() => onClickDateBox(EStep.START)}>
              <FrameV2 width={20} height={20} centered>
                <FontAwesomeIcon icon={faCalendarAlt} color={step === EStep.START ? '#04BE8C' : ''} />
              </FrameV2>
              {!selectedDate.startDate ? (
                <Font14G5W400>Start date</Font14G5W400>
              ) : (
                <Font14G1W400>
                  {selectedDate.startDate.format('DD MMM YYYY')}
                  {showTime ? selectedDate.startTime?.format(', HH:mm') : null}
                </Font14G1W400>
              )}
            </DateBox>
            <span>to</span>
            {/* if start date is selected active this box */}
            <DateBox isSelected={step === EStep.END} onClick={() => onClickDateBox(EStep.END)}>
              <FrameV2 width={20} height={20} centered>
                <FontAwesomeIcon icon={faCalendarAlt} color={step === EStep.END ? '#04BE8C' : ''} />
              </FrameV2>

              {!selectedDate.endDate ? (
                <Font14G5W400>End date</Font14G5W400>
              ) : (
                <Font14G1W400>
                  {selectedDate.endDate.format('DD MMM YYYY')}
                  {showTime ? selectedDate.endTime?.format(', HH:mm') : null}
                </Font14G1W400>
              )}
            </DateBox>
            <GhostButton onClick={onClear} disabled={isNil(selectedDate.startDate) && isNil(selectedDate.endDate)}>
              Clear
            </GhostButton>
            <PrimaryButton
              // disable if user not select start date and end date
              disabled={!selectedDate.startDate || !selectedDate.endDate}
              onClick={() => {
                if (selectedDate.startDate && selectedDate.endDate && selectedDate.startTime && selectedDate.endTime) {
                  onSubmit({
                    startDate: selectedDate.startDate,
                    endDate: selectedDate.endDate,
                    startTime: selectedDate.startTime,
                    endTime: selectedDate.endTime,
                  });
                }
              }}
            >
              Apply
            </PrimaryButton>
          </InputWrap>
        </SelectorContainer>
        <DatePickerOverlay>
          <DatePickerTopSection>
            <Font20G1W600>Select date</Font20G1W600>
          </DatePickerTopSection>
          <DateWidgeContent>
            {_renderDateWidget('start')}
            {_renderDateWidget('end')}
          </DateWidgeContent>
        </DatePickerOverlay>
      </div>
    );
  };

  return (
    <Container>
      <Dropdown {...rest} visible={visible} overlay={_renderOverlay()}>
        {children}
      </Dropdown>
    </Container>
  );
};
