import styled from 'styled-components/macro';

export const PlaceholderText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${({ theme }) => theme.GREY_5};
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.2px;
`;

export const SelectedText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: ${({ theme }) => theme.GREY_1};
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.2px;
`;

export const IconWrap = styled.div`
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${({ theme }) => theme.GREY_6};
    font-size: 16px;
  }
`;

export const ButtonContainer = styled.button<{ disabled?: boolean; height?: string }>`
  width: 100%;
  display: flex;
  padding: 5px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  height: ${({ height }) => (height ? height : '36px')};
  align-items: center;
  justify-content: space-between;
  color: #212121 !important;
  border-color: #e0e0e0;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
