import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  /* height: 100%; */
  /* width: 100%; */
  word-break: break-word;
`;

export const AgentSide = styled.div`
  direction: rtl;
  display: flex;
  margin-left: 20%;
  margin-right: 25px;
  & > div {
    margin-right: 8px;
    > span {
      font-weight: 600;
    }
  }
`;
export const UserSide = styled.div`
  display: flex;
  margin-right: 20%;
  & > div {
    margin-left: 8px;
    > span {
      font-weight: 600;
    }
  }
`;

export const MessageWrap = styled.div`
  display: flex;
  align-items: flex-end;
  column-gap: 4px;
`;

export const CustomFontAweSomeIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: ${({ theme }) => theme.RED} !important;
`;

export const Badge = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: center;
`;

export const MessageItemWrap = styled.div`
  direction: ltr;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

export const TimeText = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.GREY_6};
  white-space: nowrap;
`;

export const FailAction = styled.div`
  margin-top: 4px;
`;

export const RetryButton = styled.button`
  border: none;
  font-size: 12px;
  color: ${({ theme }) => theme.BLUE};
  cursor: pointer;
  background: transparent;
`;
