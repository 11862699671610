import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const TopSection = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 16px 24px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  position: relative;
`;

export const MainSection = styled.section`
  width: 352px;
  padding-top: 8px;
  .ant-tabs-tab {
    min-width: initial !important;
  }
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${({ theme }) => theme.GREY_6};
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
`;
