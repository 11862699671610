import { useState, useEffect, useRef } from 'react';
import produce from 'immer';
import {
  rolesHasAccessTo,
  changeMemberStatus,
  getCognitoUser,
  login,
  getToken,
  changePassword,
  exceptionMapper,
  setCustomCookie,
  clearAllCookie,
} from './fns';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { logout } from './fns/logout';
import { IAPIError, IAmplifyException, ICimPermission, ICognitoUser2, IMember } from '@types';
import { getMemberByToken, setHeader } from './fns';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { ROUTE } from '@configs/route';
import { COOKIE_NAME, GLOBAL_MESSAGE } from '@configs/constants';
import { Amplify } from '@aws-amplify/core';
import { awsExports } from '@configs/awsExports';
import { setupInterceptors } from './fns/intercepter';
import { EAgentStatus } from '@enums/AgentStatus';
import { changeMemberStatusApi, getCimPermission } from '@api/auth';
import { logoutApi } from '@api/auth';
import { ERole } from '@enums/Role';

const useMemberProvider = (initialData: IMember) => {
  const [cognitoUser, setCognitoUser] = useState<ICognitoUser2 | null>(null);
  const [cognitoLoading, setCongnitoLoading] = useState(false);
  const tempCognitoUser = useRef<ICognitoUser2 | null>(null);
  const t = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [member, setMember] = useState<IMember>(initialData);
  const [isLoading, setLoading] = useState(true);
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const [openMemberMenu, setOpenMemberMenu] = useState(false);
  const tokenRef = useRef<string>('');
  const alert = useAlert();
  const history = useHistory();
  const [cimPermission, setCimPermission] = useState<ICimPermission | null>(null);
  useEffect(() => {
    if (!isEmpty(initialData)) {
      setMember(initialData);
      setLoading(false);
      return;
    }
    Amplify.configure(awsExports);
    init();
    setupInterceptors(onAuthError);
  }, []);

  const onAuthError = () => {
    history.push(ROUTE.LOGIN);
  };

  const isAdmin = () => {
    return member.roles?.includes(ERole.ADMIN);
  };

  const isSuperAdmin = () => {
    return member.roles?.includes(ERole.SUPERADMIN);
  };

  const isSupervisor = () => {
    return member.roles?.includes(ERole.SUPERVISOR);
  };

  const isAgent = () => {
    return member.roles?.includes(ERole.AGENT);
  };

  const init = async () => {
    try {
      // login in cognito
      const cognitoData = await getCognitoUser();
      // get member info
      const member = await getMemberByToken();
      // set token
      tokenRef.current = await getToken();
      // get cim permission
      const cimPermission = await getCimPermission();
      if (cimPermission) {
        setCimPermission(cimPermission.data);
      }
      // if member status offline force change status to on_duty
      if (member.status === EAgentStatus.OFFLINE) {
        await changeMemberStatusApi(member._id, EAgentStatus.DUTY);
        updateMemberData({ ...member, status: EAgentStatus.DUTY });
      } else {
        updateMemberData(member);
      }

      setCognitoUser(cognitoData);
    } catch (error) {
      setMember({} as IMember);
      setCognitoUser(null);
      const err = error as IAPIError | IAmplifyException;
      // cognito error
      if (err.hasOwnProperty('code')) {
        const cognitoError = err as IAmplifyException;
        setErrorMessage(cognitoError.message);
        return;
      }
      // api error
      if (err.hasOwnProperty('reponse')) {
        const apiError = err as IAPIError;
        setErrorMessage(apiError?.response?.data?.message || GLOBAL_MESSAGE.ERROR_TITLE);
        return;
      }
      // check if it report page
      if (window.location.pathname.includes(ROUTE.REPORT)) {
        history.replace(`${ROUTE.LOGIN}?redirect=${window.location.pathname}`);
        return;
      }

      history.replace(ROUTE.LOGIN);
    } finally {
      setLoading(false);
    }
  };

  const onClearErrorMessage = () => {
    setErrorMessage('');
  };

  const onLogin = async (username: string, password: string) => {
    try {
      setCongnitoLoading(true);
      const result = await login(username, password);

      onClearErrorMessage();
      if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        tempCognitoUser.current = result;
        setCongnitoLoading(false);
        return history.push(ROUTE.CHANGE_PASSWORD);
      }
      // get token
      const token = await getToken();
      // set token
      tokenRef.current = token;
      // set token to header
      updateHeaderToken(token);
      // set custom cookie
      setCustomCookie(COOKIE_NAME.MEGABOT_TOKEN, token);
      // set member
      const member = await getMember();
      // if member status is offline force change status to on_duty
      if (member.status === EAgentStatus.OFFLINE) {
        await changeMemberStatusApi(member._id, EAgentStatus.DUTY);
        updateMemberData({ ...member, status: EAgentStatus.DUTY });
      } else {
        updateMemberData(member);
      }
      setCongnitoLoading(false);
      setCognitoUser(result);
      const redirect = new URLSearchParams(window.location.search).get('redirect');
      if (redirect) {
        // redirect to internal in case report page
        if (redirect?.includes(ROUTE.REPORT)) {
          history.replace(redirect);
          return;
        }
      }
      // get cim permission
      const cimPermission = await getCimPermission();
      if (cimPermission) {
        setCimPermission(cimPermission.data);
      }
      history.push(ROUTE.RECENTS);
    } catch (error) {
      setCongnitoLoading(false);
      const castError = error as IAmplifyException;

      setErrorMessage(castError.message);
      await logout();
      clearAllCookie();
    }
  };

  const updateMemberInfo = (newInfo: IMember) => {
    setMember(newInfo);
  };

  const canAccess = (pageName: string) => {
    if (member) {
      return rolesHasAccessTo(member.roles, pageName);
    }
    throw 'Member does not logged in';
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      await logoutApi();
      // logout in cognito
      await logout();
      // clear all cookie
      clearAllCookie();
      window.location.href = '/';
    } catch (error) {
      setLoading(false);
      alert.error(t('livechat.member.logout.status.fail'), {
        timeout: 2000,
      });
    }
  };

  const handleChangeMemberStatus = async (status: EAgentStatus) => {
    try {
      setIsChangingStatus(true);
      await changeMemberStatus(member._id, status);
      setIsChangingStatus(false);
      setMember(
        produce((draft) => {
          return {
            ...draft,
            status,
          };
        }),
      );
    } catch (error) {
      setIsChangingStatus(false);
      alert.error(t('livechat.member.change.status.fail'), {
        timeout: 2000,
      });
    }
  };

  const toggleMemberMenu = () => {
    setOpenMemberMenu(!openMemberMenu);
  };

  const onAnyProcessError = (error: IAmplifyException | any) => {
    setLoading(false);
    if (error === 'The user is not authenticated') {
      return;
    }
    setErrorMessage(exceptionMapper(error as IAmplifyException));
  };

  const updateHeaderToken = async (token: string) => {
    setHeader('Authorization', `Bearer ${token}`);
  };

  const getMember = async () => {
    return await getMemberByToken();
  };

  const updateMemberData = (member: IMember) => {
    setMember(member);
  };

  const updateCognitoUser = async () => {
    setCognitoUser(tempCognitoUser.current);
  };

  const onChangePassword = async ({ password }: { password: string }) => {
    if (!tempCognitoUser.current) throw new Error(GLOBAL_MESSAGE.ERROR_USER_NOT_LOGIN);

    try {
      await changePassword(tempCognitoUser.current, password);
      const token = await getToken();
      tokenRef.current = token;
      updateHeaderToken(token);
      // set custom cookie
      setCustomCookie(COOKIE_NAME.MEGABOT_TOKEN, token);
      const member = await getMember();
      // update member status
      await changeMemberStatusApi(member._id, EAgentStatus.DUTY);
      updateMemberData({ ...member, status: EAgentStatus.DUTY });
      updateCognitoUser();
      onClearErrorMessage();
      history.replace('/');
    } catch (error) {
      onAnyProcessError(error);
    }
  };

  return {
    cognitoUser,
    tempCognitoUser: tempCognitoUser.current,
    cimPermission,
    member,
    isLoading,
    errorMessage,
    openMemberMenu,
    isChangingStatus,
    cognitoLoading,
    token: tokenRef.current,
    updateMemberInfo,
    onChangePassword,
    canAccess,
    handleLogout,
    handleChangeMemberStatus,
    toggleMemberMenu,
    isAdmin,
    isSuperAdmin,
    isSupervisor,
    isAgent,
    onLogin,
    onClearErrorMessage,
  };
};

export default useMemberProvider;
