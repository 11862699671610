import React, { useEffect } from 'react';
import { CustomerSurveyCard } from '@components/CustomerSurveyCard';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { getChannelsSurveys } from '@api/survey';
import { IGetByCursorParams, ISurveyChannel } from '@types';
import { scrollHandlerForListener } from '@utils/scroll';
import { Spin } from 'antd';
import LoadingCircle from '@components/Loading';
import StateMessage from '@components/StateMessage';
import Error_Img from '@assets/images/error_message.svg';
import useTranslation from '@hooks/useTranslation';
import { Font14G4W400, Font16G1W600 } from '@components/UtilsComponent';
import { isEmpty } from 'lodash';
import { ContentNotFound } from '@components/ContentNotFound';
import dayjs from 'dayjs';

export const CustomerSatisfaction = () => {
  const { data, errorMessage, isLoadingMore, firstLoading, hasMore, onOpenDropdown, onLoadmore } =
    useInfinityLoadByCursorId<ISurveyChannel, Record<string, any>>({
      fetchFn: async (filter) => {
        const newFilter: IGetByCursorParams = {
          ...filter,
          cursorId: filter.cursorId,
        };
        const data = await getChannelsSurveys(newFilter);
        return data as ISurveyChannel[];
      },
      initialFilter: {
        take: 30,
      },
    });
  const t = useTranslation();
  useEffect(() => {
    onOpenDropdown();
  }, []);

  useEffect(() => {
    const innerWrap = document.getElementById('setting-inner');
    const handleScroll = (e: Event) => {
      if (hasMore && !isLoadingMore) {
        scrollHandlerForListener.onScrollToBottom(e, onLoadmore);
      }
    };
    if (innerWrap) {
      innerWrap.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (innerWrap) {
        innerWrap.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, isLoadingMore]);

  return (
    <Spin spinning={firstLoading} indicator={<LoadingCircle />}>
      <div>
        <Font16G1W600>{t('customer.satisfaction.list.title')}</Font16G1W600>
        <br />
        <Font14G4W400>{t('customer.satisfaction.list.description')}</Font14G4W400>
        <a href={void 0} className="text-[#0F86FE]">
          {t('customer.satisfaction.list.link')}
        </a>
      </div>
      {isEmpty(data) && (
        <div className="flex justify-center w-full mt-[24px]">
          <ContentNotFound
            label={t('customer.satisfaction.empty.content.label')}
            description={t('customer.satisfaction.empty.content.description')}
          />
        </div>
      )}
      <div className="flex gap-[24px] flex-wrap pr-[16px] pt-[16px]">
        {errorMessage ? (
          <StateMessage
            size="medium"
            type="error"
            img={Error_Img}
            label={t('general.error.title')}
            description={t('general.error.description')}
          />
        ) : (
          <div className="flex flex-wrap gap-[24px] w-full">
            {data.map((item) => {
              return (
                <div className="w-full sm:w-[calc(50%-24px)] md:w-[calc(33.33%-24px)]" key={item.id}>
                  <CustomerSurveyCard
                    surveyId={item.survey?.id || ''}
                    channelId={item.id}
                    channelType={item.type}
                    label={item.name}
                    btnLabel="Customize"
                    isBearSwitch={item.survey === null}
                    isCheckRealSwitch={item.survey?.isEnabled || false}
                    modifyAt={item.survey?.updatedAt ? dayjs(item.survey?.updatedAt).format('DD MMM YYYY | HH:MM') : ''}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {isLoadingMore && <LoadingCircle />}
    </Spin>
  );
};
