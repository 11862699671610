import React from 'react';
import { TABS_DATA } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';
import Tab from '@components/Tab';
import { Container, Title, TabWrap } from './style';
import { useHistory, useLocation } from 'react-router-dom';

const SettingHeader = () => {
  const t = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const onTabClick = (key: string) => {
    history.push(`/settings/${key}`);
  };
  const activeTab = TABS_DATA.find((tab) => history.location.pathname.includes(tab.key));
  const getContainerClass = () => {
    const regex = new RegExp(
      /\/settings\/(template-collection|(ticket-wrap-up\/category-reason)|(customer-satisfaction\/survey-customization))\/([^\/].*)/,
    );
    if (regex.test(location.pathname)) {
      return 'hidden';
    }
    return '';
  };
  const containerClass = getContainerClass();

  return (
    <Container className={containerClass}>
      <Title level={3}>{t('livechat.settings.title')}</Title>
      <TabWrap>
        <Tab
          key={activeTab?.key === 'general' ? TABS_DATA[0].key : '1'} // prevent page persist when click the gear button
          tabs={TABS_DATA}
          onTabClick={onTabClick}
          defaultActiveKey={activeTab?.key ?? TABS_DATA[0].key}
          activeKey={activeTab?.key ?? TABS_DATA[0].key}
        />
      </TabWrap>
    </Container>
  );
};

export default SettingHeader;
