import { FileFilterOption } from '@types';
import isExtensionAllowByMime from './isExtensionAllowByMime';
import isImageByMime from './isImageByMime';
import isVideoByMime from './isVideoByMime';

/**
 * This function will determine the current file's metadata by `file-size` and `mime-type`
 * which `should` or `should not` be allowed to the service.
 *
 * @param {String} mimeType mime type from input file
 *
 * @param {Number} fileSize file size from input file
 *
 * @param {FileFilterOption} options option for determine a legal file
 *
 * @returns {Boolean} is current file's metadata allowed in Boolean
 */
const shouldFileBeAllowed = (mimeType: string, fileSize: number, options: FileFilterOption) => {
  if (!isExtensionAllowByMime(mimeType)) {
    return false;
  }
  if (isImageByMime(mimeType)) {
    return fileSize <= options.maxImageSize;
  }
  if (isVideoByMime(mimeType)) {
    return fileSize <= options.maxVideoSize;
  }
  return fileSize <= options.maxFileSize;
};

export default shouldFileBeAllowed;
