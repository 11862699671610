import { SyntheticEvent } from 'react';

export const scrollHandler = {
  onScrollToBottom: (e: SyntheticEvent<HTMLDivElement>, action: () => void) => {
    const { currentTarget } = e;

    // is scroll bottom
    if (currentTarget.scrollTop + currentTarget.offsetHeight >= currentTarget.scrollHeight - 5) {
      action();
    }
  },
};

export const scrollHandlerForListener = {
  onScrollToBottom: (e: Event, action: () => void) => {
    const { currentTarget } = e as unknown as SyntheticEvent<HTMLDivElement>;

    // is scroll bottom
    if (currentTarget.scrollTop + currentTarget.offsetHeight >= currentTarget.scrollHeight - 5) {
      action();
    }
  },
};
