import { cimClient, client } from '@api';
import { Auth } from '@aws-amplify/auth';
import { addTokenToHeader } from './addToken';
import { InternalAxiosRequestConfig } from 'axios';

export const setupInterceptors = (onAuthError: () => void) => {
  client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return addTokenToHeader(config, jwtToken) as InternalAxiosRequestConfig;
  });

  cimClient.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    return addTokenToHeader(config, jwtToken) as InternalAxiosRequestConfig;
  });

  client.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response) {
        console.error(err.response);
        const { status } = err.response;
        if (status === 401) {
          onAuthError();
        }
      }
      throw err;
    },
  );
};
