import { deleteTag } from '@api/livechat/Tag';
import { useConfirm } from '@hooks/useConfirm';
import { useAlertModal } from '@providers/AlertModalProvider';
import { ITagResponse } from '@types';
import { useState } from 'react';
import { useMutation } from 'react-query';

export const useDeleteTag = () => {
  const [selectForDelete, setSelectForDelete] = useState<ITagResponse | null>(null);
  const deleteMut = useMutation((tagId: string) => {
    return deleteTag(tagId);
  });
  const confirmModal = useConfirm();
  const { onOpen } = useAlertModal();
  return {
    selectForDelete,
    confirmModal,
    deleteMut,
    onDeleteTag: async (tag: ITagResponse) => {
      try {
        await deleteMut.mutateAsync(tag.id);
      } catch (error) {
        onOpen();
      }
    },
    onSelectForDelete: (tag: ITagResponse | null) => {
      setSelectForDelete(tag);
    },
  };
};
