import React from 'react';
import { SelectContainer, DropDownContainer, dropDownStyle } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type IOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

export type ISelect = {
  options: IOption[];
  [key: string]: any;
};

const Select: React.FC<ISelect> = ({ options, ...rest }: ISelect) => {
  const _renderDropDown = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
    return (
      <>
        <DropDownContainer>{menu}</DropDownContainer>
      </>
    );
  };

  return (
    <SelectContainer
      {...rest}
      data-testid="antd-select"
      dropdownStyle={dropDownStyle}
      dropdownRender={_renderDropDown}
      suffixIcon={<FontAwesomeIcon icon={['fas', 'chevron-down']} />}
    >
      {options.map(({ label, value, disabled }: IOption) => {
        return (
          <SelectContainer.Option disabled={disabled} key={value} value={value} data-testid="test-select-item">
            <span>{label}</span>
          </SelectContainer.Option>
        );
      })}
    </SelectContainer>
  );
};

export default Select;
