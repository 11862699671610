import { client } from '@api';
import { ISearchTagRequest, ITagPayload } from '@types';
import { CancelTokenSource } from 'axios';

export const searchTag = (params: ISearchTagRequest, cancelToken?: CancelTokenSource) => {
  return client.get('/api/v1/ticket-tags', {
    params,
    cancelToken: cancelToken?.token,
  });
};

export const createTag = (payload: ITagPayload) => {
  return client.post('/api/v1/ticket-tags', payload);
};

export const updateTag = (id: string, payload: ITagPayload) => {
  return client.put(`/api/v1/ticket-tags/${id}`, payload);
};

export const deleteTag = (id: string) => {
  return client.delete(`/api/v1/ticket-tags/${id}`);
};
