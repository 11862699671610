import React, { JSXElementConstructor, ReactElement, useState } from 'react';
import { ValidateContainer, ValidateMessage } from '@components/UtilsComponent';

type ICustomFormItemProps = {
  isError: boolean;
  errorMessage: string;
  id: string;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  value?: any;
  counter?: React.ReactNode;
  [key: string]: any;
};

export const CustomFormItem = ({
  id,
  isError,
  errorMessage,
  children,
  value,
  counter,
  ...rest
}: ICustomFormItemProps) => {
  // trigger after first typing

  const [touched, setTouched] = useState(false);
  const [ready, setReady] = useState(false);
  return (
    <ValidateContainer isInvalidate={touched && ready && isError} id={id}>
      {React.cloneElement(children, {
        ...rest,
        id,
        value,
        onFocus: () => {
          setTouched(true);
        },
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (!ready) {
            setReady(true);
          }
          rest?.onChange(e);
        },
      })}
      <div className="flex justify-between mt-[4px] leading-[14px]">
        <div>{touched && ready && isError && <ValidateMessage>{errorMessage}</ValidateMessage>}</div>
        {counter}
      </div>
    </ValidateContainer>
  );
};
