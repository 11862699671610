import { IPlatform, ITemplateApi } from '@types';

export const generatePlatform = (platforms: ITemplateApi['platforms']): IPlatform => {
  if (platforms.length === 3) {
    return 'multi';
  }
  if (platforms.length === 2 && platforms.includes('line') && platforms.includes('webchat')) {
    return 'line_webchat';
  }
  return platforms[0];
};
