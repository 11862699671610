import React from 'react';
import { ButtonContainer, IconWrap, PlaceholderText, SelectedText } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export enum ESize {
  LARGE = 'large',
  MEDIUM = 'medium',
}

export type ISelectBtnProps = {
  selectedText: React.ReactNode;
  placeholder: string;
  height?: string;
  size?: ESize;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const SelectBtn = ({ selectedText, placeholder, size, ...rest }: React.PropsWithChildren<ISelectBtnProps>) => {
  return (
    <ButtonContainer {...rest}>
      {selectedText ? <SelectedText>{selectedText}</SelectedText> : <PlaceholderText>{placeholder}</PlaceholderText>}
      <IconWrap>
        <FontAwesomeIcon icon={faChevronDown} />
      </IconWrap>
    </ButtonContainer>
  );
};
