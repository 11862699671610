import styled from 'styled-components/macro';
import { Typography as AntdTypography } from 'antd';
export const Container = styled.div`
  margin-top: 40px;
`;

export const Title = styled(AntdTypography.Title)`
  margin: 0 !important;
`;

export const TabWrap = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
`;
