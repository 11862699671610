import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { ISender } from '@types';
import { EMessageStatus } from '@enums/MessageStatus';
import { ESenderType } from '@enums/SenderType';

export type BaseMessageJson = {
  id: string;
  createdAt: number;
  senderImage?: string;
  sender?: ISender;
  senderName?: string;
  metadata?: {
    [key: string]: any;
  };
  messageStatus: EMessageStatus;
};

export abstract class BaseMessage {
  protected id: string;
  protected createdAt: number;
  protected senderImage?: string;
  protected sender?: ISender;
  protected senderName: string;
  protected messageStatus: EMessageStatus;
  protected retry: boolean;
  protected metadata: BaseMessageJson['metadata'] = undefined;
  constructor(data: BaseMessageJson) {
    const { id, createdAt } = data;
    this.id = id || uuid();
    this.createdAt = createdAt || Date.now();
    this.senderImage = data.senderImage ?? '';
    this.sender = data.sender ?? ESenderType.SYSTEM;
    this.senderName = data.senderName ?? '';
    this.retry = false;
    this.messageStatus = data.messageStatus;
    this.metadata = data.metadata;
  }

  get getId() {
    return this.id;
  }

  get createdDate() {
    return this.createdAt;
  }

  get getRetry() {
    return this.retry;
  }

  get getSenderImage() {
    return this.senderImage;
  }

  get getSender() {
    return this.sender;
  }

  get getSenderName() {
    return this.senderName;
  }

  get getMessageStatus() {
    return this.messageStatus;
  }

  get getMetadata() {
    return this.metadata;
  }

  set retryMessage(value: boolean) {
    this.retry = value;
  }

  set setMessageStatus(value: EMessageStatus) {
    this.messageStatus = value;
  }

  set setCreateDate(value: number) {
    this.createdAt = value;
  }

  set setMetadata(value: BaseMessageJson['metadata']) {
    this.metadata = value;
  }

  public isSameMin(anotherMessagetimestamp: number): boolean {
    return dayjs(this.createdAt).isSame(anotherMessagetimestamp, 'm');
  }

  public isWithInOneMin(anotherMessagetimestamp: number): boolean {
    return dayjs(this.createdAt).diff(anotherMessagetimestamp, 'm') === 0;
  }

  public isSameDay(anotherMessagetimestamp: number): boolean {
    return dayjs(this.createdAt).isSame(anotherMessagetimestamp, 'd');
  }
  public isSelfSender(sender?: ISender): boolean {
    return this.sender === sender;
  }

  public isAgent(): boolean {
    return this.sender === 'agent';
  }

  public isBot(): boolean {
    return this.sender === 'bot';
  }

  public isSamePersonWith(senderName: string) {
    return this.senderName === senderName;
  }

  public formatDate(format: string): string {
    return dayjs(this.createdAt).format(format);
  }
}
