import React from 'react';
import {
  Container,
  Form,
  Section,
  Item,
  Label,
  Description,
  MaximumTicketWrap,
  InputLabel,
  InputWrap,
  Unit,
  ActionWrap,
  FormItem,
  ErrorMessage,
  SlaHourWrap,
  SlaActionWrap,
  SlaActionButtonWrap,
  SlaTimeWrap,
  MaxTimeLabel,
  HourUnit,
} from './style';
import useTranslation from '@hooks/useTranslation';
import InputNumber from '@components/InputNumber';
import { PrimaryButton, Button } from '@components/Button';
import { INITIAL_MAX_TICKET, INITIAL_SLA_HOUR, INITIAL_SLA_MIN } from '@configs/settings';
import useGeneral from './useGeneral';
import { Spin } from 'antd';
import LoadingCircle from '@components/Loading';
import { LoadingWrap } from '../style';
import TextInput from '@components/TextInput';
import { restrictLetter } from '@utils/letters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullWidth } from '@components/UtilsComponent';
import { IGeneralConfig } from '@types';
import { Invalidate } from '@components/TextInput/style';
import Switch from '@components/Switch';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { Radio } from '@components/Radio';

const GeneralManagement: React.FC = () => {
  const {
    form,
    current,
    errorMessage,
    onFinish,
    onFieldsChange,
    onDiscardChange,
    onIncreaseSlaHr,
    onDecreaseSlaHr,
    onUpdateSlaHour,
    onUpdateSlaMin,
    onIncreaseSlaMin,
    onDecreaseSlaMin,
  } = useGeneral();
  const { context } = current;
  const t = useTranslation();

  return (
    <Spin
      spinning={current.matches('fetching') || current.matches('saving')}
      indicator={
        <LoadingWrap data-testid="test-loading-wrap">
          <LoadingCircle />
        </LoadingWrap>
      }
    >
      <Container>
        <Form
          id="general-form"
          form={form}
          onFinish={(values) => onFinish(values as IGeneralConfig)}
          initialValues={context.accountConfig}
          onFieldsChange={onFieldsChange}
        >
          <Section>
            <Item>
              <FormItem noStyle name="autoAssign" valuePropName="checked">
                <Switch />
              </FormItem>
            </Item>
            <Item>
              <Label>{t('livechat.settings.general.auto.assign.label')}</Label>
              <Description>{t('livechat.settings.general.auto.assign.description')}</Description>
              <FormItem shouldUpdate>
                {() => {
                  const autoAssign = form.getFieldValue('autoAssign');
                  return (
                    <div
                      style={{
                        display: autoAssign ? 'block' : 'none',
                      }}
                    >
                      <div className="mt-[24px]">{t('livechat.settings.general.auto.assign.choice.label')}</div>
                      <Section className="!border-none !p-0 !mt-[12px]">
                        <Item>
                          <FormItem name="autoAssignRandom" noStyle valuePropName="checked">
                            <Radio />
                          </FormItem>
                        </Item>
                        <Item>
                          <Label>{t('livechat.settings.general.auto.assign.random.label')}</Label>
                          <Description className="mt-[8x]">
                            {t('livechat.settings.general.auto.assign.random.description')}
                          </Description>
                        </Item>
                      </Section>

                      <Section className="!border-none !p-0">
                        <Item>
                          <FormItem name="autoAssignRoundRobin" noStyle valuePropName="checked">
                            <Radio />
                          </FormItem>
                        </Item>
                        <Item>
                          <Label>{t('livechat.settings.general.auto.assign.round.robin.label')}</Label>
                          <Description className="mt-[8x]">
                            {t('livechat.settings.general.auto.assign.round.robin.description')}
                          </Description>
                        </Item>
                      </Section>
                      <Section className="!border-none !p-0">
                        <Item>
                          <FormItem name="autoAssignLoadBalanced" noStyle valuePropName="checked">
                            <Radio disabled />
                          </FormItem>
                        </Item>
                        <Item>
                          <Label>{t('livechat.settings.general.auto.assign.load.balanced.label')}</Label>
                          <Description className="mt-[8x]">
                            {t('livechat.settings.general.auto.assign.load.balanced.description')}
                          </Description>
                        </Item>
                      </Section>
                    </div>
                  );
                }}
              </FormItem>
            </Item>
          </Section>

          {/* Custom Name */}
          <Section>
            <Item>
              <FormItem noStyle name="isCustomNameEditorEnabled" valuePropName="checked">
                <Switch />
              </FormItem>
            </Item>
            <Item>
              <Label>{t('livechat.settings.general.allow.custom.name.label')}</Label>
              <Description>{t('livechat.settings.general.allow.custom.name.description')}</Description>
            </Item>
          </Section>
          <Section>
            <Item className="!min-w-[44px]"></Item>
            <Item>
              <Label>{t('livechat.settings.general.max.ticket.label')}</Label>
              <Description>{t('livechat.settings.general.max.ticket.description')}</Description>
              <MaximumTicketWrap>
                <InputLabel>{t('livechat.settings.general.max.ticket.input.label')}</InputLabel>
                <InputWrap>
                  <FormItem
                    noStyle
                    name="maxTicket"
                    initialValue={INITIAL_MAX_TICKET}
                    rules={[
                      {
                        required: true,
                        message: t('livechat.settings.general.max.ticket.required'),
                      },
                      { type: 'number', min: 1, max: 500, message: t('livechat.settings.general.max.ticket.error') },
                    ]}
                  >
                    <InputNumber size="large" data-testid="test-max-ticket-input" />
                  </FormItem>
                  <ErrorMessage>{errorMessage.maximumTicket}</ErrorMessage>
                </InputWrap>
                <Unit>{t('livechat.settings.general.unit.label')}</Unit>
              </MaximumTicketWrap>
            </Item>
          </Section>
          <Section>
            <Item className="!min-w-[44px]"></Item>
            <FullWidth>
              <Label>{t('livechat.settings.general.sla.label')}</Label>
              <Description>{t('livechat.settings.general.sla.description')}</Description>
              <SlaTimeWrap>
                <InputLabel>{t('livechat.settings.general.sla.input.label')}</InputLabel>
                <SlaHourWrap>
                  <MaxTimeLabel>{t('livechat.settings.general.sla.max.time.label')}</MaxTimeLabel>
                  <Invalidate isInvalidate={!!errorMessage.slaHour}>
                    <FormItem
                      noStyle
                      name={['SLATime', 'hour']}
                      initialValue={INITIAL_SLA_HOUR}
                      rules={[
                        {
                          required: true,
                          message: t('livechat.settings.general.sla.hour.required'),
                        },
                      ]}
                    >
                      <TextInput
                        tabIndex={1}
                        data-testid="test-sla-hr-input"
                        size="large"
                        placeholder={t('livechat.settings.general.sla.hour.placeholder')}
                        onChange={(e) => onUpdateSlaHour(e.target.value)}
                        value={0}
                        maxLength={3}
                        onKeyPress={(e) => {
                          restrictLetter(e, /[0-9]/);
                        }}
                      />
                    </FormItem>
                  </Invalidate>
                  <SlaActionWrap>
                    <SlaActionButtonWrap onClick={onIncreaseSlaHr} tabIndex={-1} data-testid="test-increase-hr-btn">
                      <FontAwesomeIcon icon={faAngleUp} />
                    </SlaActionButtonWrap>
                    <SlaActionButtonWrap onClick={onDecreaseSlaHr} tabIndex={-1} data-testid="test-decrease-hr-btn">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </SlaActionButtonWrap>
                  </SlaActionWrap>
                  <HourUnit>{t('livechat.settings.general.sla.hour.unit.label')}</HourUnit>
                </SlaHourWrap>
                <SlaHourWrap>
                  <Invalidate isInvalidate={!!errorMessage.slaMin}>
                    <FormItem
                      noStyle
                      name={['SLATime', 'minute']}
                      initialValue={INITIAL_SLA_MIN}
                      rules={[
                        {
                          required: true,
                          message: t('livechat.settings.general.sla.min.required'),
                        },
                      ]}
                    >
                      <TextInput
                        tabIndex={2}
                        size="large"
                        data-testid="test-sla-min-input"
                        placeholder={t('livechat.settings.general.sla.min.placeholder')}
                        maxLength={2}
                        onChange={(e) => onUpdateSlaMin(e.target.value)}
                        onKeyPress={(e) => {
                          restrictLetter(e, /[0-9]/);
                        }}
                      />
                    </FormItem>
                  </Invalidate>
                  <SlaActionWrap>
                    <SlaActionButtonWrap onClick={onIncreaseSlaMin} data-testid="test-increase-min-btn">
                      <FontAwesomeIcon icon={faAngleUp} />
                    </SlaActionButtonWrap>
                    <SlaActionButtonWrap onClick={onDecreaseSlaMin} data-testid="test-decrease-min-btn">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </SlaActionButtonWrap>
                  </SlaActionWrap>
                  <Unit>{t('livechat.settings.general.sla.min.unit.label')}</Unit>
                </SlaHourWrap>
              </SlaTimeWrap>
            </FullWidth>
          </Section>
          <Section>
            <Item>
              <FormItem noStyle name="isCollaborationModeEnabled" valuePropName="checked">
                <Switch />
              </FormItem>
            </Item>
            <Item>
              <Label>{t('livechat.settings.general.collaboration.mode.label')}</Label>
              <Description>{t('livechat.settings.general.collaboration.mode.desctription')}</Description>
              <ul className="list-item pl-7">
                <li className="list-disc text-[#757575]">
                  {t('livechat.settings.general.collaboration.mode.detail1')}
                </li>
                <li className="list-disc text-[#757575]">
                  {t('livechat.settings.general.collaboration.mode.detail2')}
                </li>
                <li className="list-disc text-[#757575]">
                  {t('livechat.settings.general.collaboration.mode.detail3')}
                </li>
                <li className="list-disc text-[#757575]">
                  {t('livechat.settings.general.collaboration.mode.detail4')}
                </li>
              </ul>
            </Item>
          </Section>
          <ActionWrap>
            <FormItem noStyle>
              <PrimaryButton
                htmlType="submit"
                form="general-form"
                size="large"
                data-testid="test-save-btn"
                disabled={
                  current.matches('saving') ||
                  !!errorMessage.maximumTicket ||
                  !!errorMessage.slaHour ||
                  !!errorMessage.slaMin
                }
              >
                {t('livechat.settings.general.save')}
              </PrimaryButton>
            </FormItem>
            {current.matches('editing') && (
              <Button size="large" onClick={onDiscardChange}>
                {t('livechat.settings.general.discard')}
              </Button>
            )}
          </ActionWrap>
        </Form>
      </Container>
    </Spin>
  );
};

export default GeneralManagement;
