import React from 'react';
import { useSidebar } from './context';
import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DEPTH_RATIO } from './constants';
import { Link } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const { Item } = AntdMenu;

type IMenuItemProps = {
  label: string;
  iconName?: IconName;
  to: string;
  level?: number;
  isExternal?: boolean;
  [key: string]: any;
};

const Container = styled(Item)<{ collapsed: boolean; selected: boolean; level: number }>`
  cursor: pointer;
  background: transparent !important;
  font-size: 1.3rem;
  padding: 0 14px !important;
  padding-left: ${({ level }) => (level !== 1 ? (level * DEPTH_RATIO) / 14 : 1)}rem !important;
  padding-right: ${({ level }) => level > 1 && 0}rem !important;
  user-select: none !important;
  div a {
    color: ${({ theme, selected, level }) => activeColor(selected, theme, level)} !important;
    width: 100%;
    min-width: 40px;
    border-radius: 0.5rem;
    /* height: 2.5rem !important; */
    display: flex;
    align-items: center;
    background: ${({ theme, selected, level }) => (level < 2 && selected ? theme.PRIMARY : null)};
    &:hover {
      background: ${({ theme, level, selected }) => level < 3 && !selected && theme.GREY_2} !important;
      color: ${({ theme, level }) => level >= 3 && theme.WHITE} !important;
    }
    svg {
      margin-left: 10.5px;
      width: 1em !important;
    }
    span {
      margin-left: 0.5rem;
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), min-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      ${({ collapsed }) =>
        collapsed &&
        `
        max-width: 0;
        display: inline-block;
        opacity: 0;
    `};
    }
  }
`;

const activeColor = (selected: boolean, theme: any, level: number) => {
  if (selected && level === 1) return theme.WHITE;
  if (selected && level === 2) return theme.PRIMARY;
  if (selected && level > 2) return theme.WHITE;
  return theme.GREY_5;
};

const MenuItem: React.FC<IMenuItemProps> = ({ label, iconName, level, isExternal, ...props }: IMenuItemProps) => {
  const { collapsed, setCollapsed, selectedMenu, setSelectedMenu } = useSidebar();
  const handleClickLink = () => {
    //if path is sub path callapse sidebar
    const paths = props.to.split('/');
    if (paths.length > 2) {
      setCollapsed(true);
      setSelectedMenu(`/${paths[1]}`);
    }
  };

  return (
    <Container
      title=""
      {...props}
      collapsed={collapsed}
      level={level as number}
      selected={(selectedMenu === props.to || selectedMenu.includes(props.to)) && (level as number) <= 10}
      data-tip={collapsed ? label : ''}
    >
      {isExternal ? (
        <div>
          <a href={props.to} target="_blank" rel="noreferrer">
            {iconName && <FontAwesomeIcon icon={['fas', iconName]} />}
            <span>{label}</span>
          </a>
        </div>
      ) : (
        <div>
          <Link to={props.to} onClick={handleClickLink}>
            {iconName && <FontAwesomeIcon icon={['fas', iconName]} />}
            <span>{label}</span>
          </Link>
        </div>
      )}
    </Container>
  );
};

export default MenuItem;
