import styled from 'styled-components/macro';
import { Tag, Typography } from 'antd';

export const BaseTag = styled(Tag)`
  padding: 6px 10px;
  border: none;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.07);
`;

export const TagLabel = styled(Typography.Text)`
  font-size: 14px;
`;
