import { faMapPin } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type ILocationMessageProps = {
  lat: number;
  lng: number;
  text: string;
};

export const LocationMessage: React.FC<ILocationMessageProps> = ({ lat, lng, text }: ILocationMessageProps) => {
  return (
    <a
      href={`https://maps.google.com?q=${lat},${lng}`}
      className="rounded-lg flex bg-[#EEEEEE] overflow-hidden"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="flex justify-center items-center bg-[#424242] p-[6px] min-w-[32px] max-w-[32px]">
        <FontAwesomeIcon icon={faMapPin} className="text-[#757575]" />
      </div>
      <p className="p-3 text-[14px] font-normal leading-normal text-[#212121]">{text}</p>
    </a>
  );
};
