import { atom } from 'recoil';

export enum EAppState {
  IDLE = 'idle',
  ERROR = 'error',
  LOADING = 'loading',
}

export const appState = atom({
  key: 'appState',
  default: EAppState.LOADING as EAppState,
});

export * from './EngagementMessageAtom';
export * from './EngagementUserListAtom';
