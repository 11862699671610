import { getOlderMessage } from '@api/livechat/EngagementMessage';
import { TicketMessage } from '@model/MessageItem';
import { AnyEventObject } from 'xstate';
import { IChatHistory, IMessageBoxMachineContext } from '../messageBoxMachine';
import { getMessage, isPersonMessage, messageGenerator } from './messageGenerator';

export const fetchPreviousMessages = async (context: IMessageBoxMachineContext, event: AnyEventObject) => {
  try {
    const { chatHistory, userId } = context;
    const res = await getPreviousMessages(userId, chatHistory);
    context.previousScrollHeight = event.previousScrollHeight;
    context.chatHistory = {
      ...context.chatHistory,
      previousRemain: res.previousRemain,
      messages: res.messages,
    };
    return context;
  } catch (error) {
    throw error;
  }
};

const getPreviousMessages = async (userId: string, chatHistory: IChatHistory) => {
  const findFirstMessage = chatHistory.messages.find((message) => {
    if (message instanceof isPersonMessage(message) || message instanceof TicketMessage) {
      return message;
    }
  });
  if (findFirstMessage) {
    const { data } = await getOlderMessage(userId, findFirstMessage.createdDate);
    const userMessage = data.userMessages.map((message) => getMessage(message));
    const messageBefore = messageGenerator([], userMessage);
    const processMessage = messageGenerator(messageBefore, chatHistory.messages);
    const res = {
      previousRemain: data.userMessages.length > 0,
      messages: processMessage,
    };
    return res;
  }
  return {
    previousRemain: false,
    nextRemain: false,
    messages: [],
  };
};
