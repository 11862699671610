import React, { useRef } from 'react';
import EChannelType from '@enums/ChannelType';
import getLogoByChannelType from 'utils/getLogoByChannelType';
import { BearSwitch } from '@components/BearSwitch';
import { Font16G1W600, Font12G5W400, Font14G1W600, Font12G1W500 } from '@components/UtilsComponent';
import { Button } from '@components/Button';
import { Container } from './style';
import gsap from 'gsap';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE } from '@configs/route';
import { useMutation } from 'react-query';
import { enableSurvey } from '@api/survey';
import { Spin } from 'antd';
import LoadingCircle from '@components/Loading';
import { LoadingWrap } from '@components/Loading/style';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';

type ICustomerSurveyCardProps = {
  surveyId: string;
  channelId: string;
  channelType: EChannelType;
  label: string;
  description?: string;
  btnLabel: string;
  isCheckRealSwitch: boolean;
  isBearSwitch: boolean;
  isEnabled?: boolean;
  modifyAt?: string;
};

export const CustomerSurveyCard = ({
  surveyId,
  channelId,
  channelType,
  label,
  description = 'Please customize the survey before using it.',
  btnLabel,
  isCheckRealSwitch,
  isBearSwitch,
  modifyAt,
}: ICustomerSurveyCardProps) => {
  const updateMute = useMutation((payload: { surveyId: string; channelId: string; isEnabled: boolean }) => {
    return enableSurvey(payload);
  });
  const desRef = useRef(null);
  const history = useHistory();
  const onBearAnimatedCb = () => {
    // shake animation
    gsap.to(desRef.current, { x: '1%', duration: 0.1, repeat: 5, yoyo: true });
  };

  const onCreateNewSurvey = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (isBearSwitch) {
      history.push({
        pathname: ROUTE.CUSTOMER_SATISFACTION_CUSTOMIZATION_NEW,
        state: {
          channelId: channelId,
          channelName: label,
          channelType: channelType,
        },
      });
    } else {
      history.push({
        pathname: `${ROUTE.CUSTOMER_SATISFACTION}/survey-customization/${surveyId}`,
        state: {
          channelId: channelId,
          channelName: label,
          channelType: channelType,
        },
      });
    }
  };
  const t = useTranslation();
  const alert = useAlert();

  return (
    <Spin
      spinning={updateMute.isLoading}
      indicator={
        <LoadingWrap>
          <LoadingCircle />
        </LoadingWrap>
      }
    >
      <Container className="customer-servey-card p-[16px] flex-1 border-[#E5E5E5] border-solid border rounded-[12px] box-content">
        <div className="flex items-center justify-between h-[32px]">
          <div>
            <img alt={channelType} width={32} height={32} src={getLogoByChannelType(channelType)} />
          </div>
          <div>
            <BearSwitch
              isBearSwitch={isBearSwitch}
              isCheckRealSwitch={isCheckRealSwitch}
              onSwitchChange={async (value) => {
                try {
                  await updateMute.mutateAsync({
                    surveyId: surveyId,
                    channelId: channelId,
                    isEnabled: value,
                  });
                  return value;
                } catch (error) {
                  alert.error(t('general.error.title'));
                  console.error('error toggle suryvey', error);
                  throw new Error('error toggle suryvey');
                }
              }}
              onAnimatedCb={onBearAnimatedCb}
            />
          </div>
        </div>
        <div className="mt-[16px] max-h-[48px]">
          <Font16G1W600 className="line-clamp-2">{label}</Font16G1W600>
        </div>
        <div className="h-[1px] w-full bg-[#E5E5E5] my-[8px]" />
        {modifyAt ? (
          <div>
            <Font12G5W400>{t('customer.satisfaction.list.card.modify.at')}</Font12G5W400>
            <Font12G1W500>{modifyAt}</Font12G1W500>
          </div>
        ) : (
          <div ref={desRef}>
            <Font12G5W400>{description}</Font12G5W400>
          </div>
        )}

        <div className="mt-[16px]">
          <Link
            to={
              isBearSwitch
                ? ROUTE.CUSTOMER_SATISFACTION_CUSTOMIZATION_NEW
                : `${ROUTE.CUSTOMER_SATISFACTION}/survey-customization/${channelId}`
            }
            onClick={(e) => onCreateNewSurvey(e)}
          >
            <Button className="w-full">
              <Font14G1W600>{btnLabel}</Font14G1W600>
            </Button>
          </Link>
        </div>
      </Container>
    </Spin>
  );
};
