import styled from 'styled-components/macro';

export const Container = styled.div`
  padding: 5px;
`;

export const Group = styled.div`
  :not(:first-child) {
    margin-top: 16px;
  }
  display: grid;
  grid-gap: 8px;
`;

export const ButtonUploadWrap = styled.div`
  button {
    box-shadow: none !important;
    svg {
      margin-right: 8px;
    }
  }
`;

export const List = styled.ul`
  padding-left: 15px;
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.GREY_5};
  list-style: initial;
`;

export const HintWrap = styled.div`
  span {
    color: ${({ theme }) => theme.GREY_1};
  }
`;

export const Footer = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  button:nth-child(1) {
    margin-right: 8px;
  }
`;

export const UrlTextWrap = styled.div`
  span {
    width: 300px;
  }
  overflow: hidden;
`;
