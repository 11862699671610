import React, { useEffect } from 'react';
//router
import LiveChatMobile from '@pages/mobile';
import LiveChatDesktop from '@pages/desktop';
import { useMember } from '@providers/MemberProvider';
import { useLocation, useHistory } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';
import { ROUTE } from '@configs/route';

const DeviceChecker: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { canAccess } = useMember();

  useEffect(() => {
    if (isBrowser) {
      const redirect = new URLSearchParams(location.search).get('redirect');
      const locationSplit = location.pathname.split('/');
      const firstPath = locationSplit[1];

      if (!canAccess(firstPath) && !redirect) {
        history.replace(ROUTE.RECENTS);
        return;
      }
      return;
    }
  }, []);

  if (isBrowser) {
    return (
      <>
        <LiveChatDesktop />
      </>
    );
  }
  return <LiveChatMobile />;
};

export default DeviceChecker;
