import { client } from '@api';
import { actions } from './mock';

export const getActions = async () => {
  //TODO add real api
  return import.meta.env.VITE_APP_MOCK_ACTIONS ? Promise.resolve(actions) : client.get('/api/actions');
};

export const sendMessage = (agentId: string, payload: any) => {
  return client.post(`/api/agents/${agentId}/messages/send`, payload);
};

export const sendTemplate = (payload: any) => {
  return client.post(`/api/agents/templates/send`, payload);
};
