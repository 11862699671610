import moment, { Moment } from 'moment';
import { EventValue } from 'rc-picker/lib/interface';

export const USERLIST_RELATIVE_TIME = {
  future: 'in %s',
  past: '%s',
  s: 'now',
  m: '1m',
  mm: '%dm',
  h: '1h',
  hh: '%dh',
  d: '1d',
  dd: '%dd',
  M: 'a month',
  MM: '%d months',
  y: 'a year',
  yy: '%d years',
};

export const TODAY = moment(); // timestamp will be the end of today
export const TODAY_START = moment().startOf('day');
export const THIS_WEEK = moment().startOf('week');
export const THIS_MONTH = moment().startOf('month');
export const DATE_OPTIONS: Record<string, [EventValue<Moment>, EventValue<Moment>]> = {
  Today: [TODAY_START, TODAY],
  'This Week': [THIS_WEEK, TODAY],
  'This Month': [THIS_MONTH, TODAY],
};
