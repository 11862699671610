import styled from 'styled-components/macro';

export const Container = styled.div`
  > div {
    max-height: 175px;
    min-height: 57px;
    audio {
      height: 57px !important;
      width: 300px !important;
    }
    video {
      height: 175px !important;
      width: 300px !important;
    }
  }
`;
