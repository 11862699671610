import styled from 'styled-components/macro';

export const Container = styled.div<{ side: 'left' | 'right'; type: 'first' | 'middle' | 'last' }>`
  padding: 8px 12px;
  min-height: 36px;
  line-height: normal;
  border-radius: ${({ side, type }) => getBorderRadius(type, side)};
  background: ${({ side, theme }) => (side === 'left' ? theme.GREY_8 : theme.GREY_2)};
  color: ${({ side, theme }) => (side === 'left' ? theme.GREY_1 : theme.WHITE)};
  width: fit-content;
  direction: ltr;
  white-space: pre-wrap;
`;

const getBorderRadius = (type: 'first' | 'middle' | 'last', side: 'left' | 'right') => {
  if (type === 'first' && side === 'left') {
    return '12px 12px 12px 4px';
  }

  if (type === 'first' && side === 'right') {
    return '12px 12px 4px 12px';
  }

  if (type === 'middle' && side === 'left') {
    return '4px 12px 12px 4px';
  }

  if (type === 'middle' && side === 'right') {
    return '12px 4px 4px 12px';
  }

  if (type === 'last' && side === 'left') {
    return '4px 12px 12px 12px';
  }

  if (type === 'last' && side === 'right') {
    return '12px 4px 12px 12px';
  }
};
