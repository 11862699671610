import { Skeleton as AntdSkeleton } from 'antd';
import styled from 'styled-components/macro';

export const SkeletonInput = styled(AntdSkeleton.Input)<{ width: number; height: number }>`
  .ant-skeleton-input {
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-radius: 4px;
  }
`;

export const SkeletonAvatar = styled(AntdSkeleton.Avatar)``;

export const SkeletonButton = styled(AntdSkeleton.Button)`
  .ant-skeleton-button {
    width: 120px;
    height: 48px;
  }
`;

export const SkeletonOgTitle = styled(AntdSkeleton.Input)`
  width: 100%;
  height: 16px !important;
  border-radius: 4px;
`;

export const SkeletonOgImage = styled(AntdSkeleton.Avatar)`
  .ant-skeleton-avatar {
    width: 54px;
    height: 54px;
    border-radius: 4px !important;
  }
`;
