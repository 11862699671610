import mime from 'mime';
import { Buffer } from 'buffer';

function detectEncoding(encodedString: string) {
  // URL-decode the string
  const decodedString = decodeURIComponent(encodedString);

  // Convert the string to an array of character codes
  const charCodes = Array.from(decodedString).map((char) => char.charCodeAt(0));

  // Check for Thai character range (0E00-0E7F)
  const isThai = charCodes.some((code) => code >= 0x0e00 && code <= 0x0e7f);

  if (isThai) {
    return true;
  } else {
    return false;
  }
}

function isEncoded(str: string) {
  try {
    return decodeURIComponent(str) !== str;
  } catch (e) {
    return false; // If decoding throws, the string is not encoded
  }
}

const parseFileDataFromUrl = (url: string) => {
  const regex = new RegExp(/.+\/{2}.+\/{1}.+(\.\w+)\?*.*/);
  const isFile = regex.test(url);
  if (!isFile) {
    return null;
  }
  const directorySplit = url.split(/[#?]/)[0].split('/');
  const fileName = directorySplit[directorySplit.length - 1];
  const fileNameSplited = fileName.split('.');
  let ext;
  if (mime.getType(fileName) && fileNameSplited.length > 1) {
    ext = fileNameSplited.pop();
  }
  const joined = fileNameSplited.join('.');
  const isEncodedName = isEncoded(joined);
  if (!isEncodedName) {
    return {
      ext,
      name: joined,
    };
  } else {
    const isUtf8 = detectEncoding(joined);
    if (isUtf8) {
      return {
        ext,
        name: decodeURIComponent(joined),
      };
    }
    const decodeName = decodeURIComponent(joined);
    const name = Buffer.from(decodeName, 'latin1').toString('utf-8');
    return {
      ext,
      name,
    };
  }
};

export default parseFileDataFromUrl;
