import styled from 'styled-components/macro';

export const Container = styled.div`
  overflow: hidden;
  background: #fff;
  width: auto;
  max-height: 230px;
  width: 290px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
