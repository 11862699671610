import { useEffect } from 'react';

/**
 * useClickOutside hook
 * @param {HTMLElement[]} els - Htmlelement that want to exceptional.
 * @param {function} onClickOutside - click outside callback.
 */
export const useClickOutside = (els: HTMLElement[], onClickOutside: () => void) => {
  useEffect(() => {
    document.addEventListener('mousedown', checkClickOutside);
    return () => document.removeEventListener('mousedown', checkClickOutside);
  }, [els]);

  const checkClickOutside = (event: MouseEvent) => {
    if (els.length > 0) {
      const someContain = els.some((el) => el.contains(event.target as Node));
      if (someContain) {
        return;
      } else {
        onClickOutside();
        return;
      }
    }
  };
};
