import { useMember } from '@providers/MemberProvider';
import { useSetting } from '@providers/SettingProvider';
import { ITicket } from '@types';

//need ticket params
/**
 * useChatPermission
 * need 2 providers
 * - MemberProvider
 * - SettingProvider
 * @param {Object} ticket - current ticket type is ITicket
 * @returns
 */
export const useChatPermission = (ticket: ITicket) => {
  const { member, isAdmin } = useMember();
  const { permission } = useSetting();
  return {
    canChat: () => {
      if (permission.canReply) {
        return true;
      }
      // current login is admin and current filter is assigned to team
      if (isAdmin()) {
        return true;
      }
      return ticket?.agentId === member._id;
    },
  };
};
