import { useState, useEffect, useCallback, SyntheticEvent } from 'react';
import { tableMachine } from '@machine/tableMachine';
import { IFetchParams, tableListGeneratorV2 } from '@utils/tableListGeneratorV2';
import { useMachine } from '@xstate/react';

export type IUseInfinityScrollTableParams = {
  maxTake?: number;
  fetchFn: (params: IFetchParams) => Promise<any>;
};

export const useInfinityScrollTable = ({ fetchFn, maxTake = 30 }: IUseInfinityScrollTableParams) => {
  const machineFn = useCallback(() => {
    return tableMachine({
      fetchOnInit: false,
      fetchFn: async (behavior, currentPage, nextCursorId, prevCursorId, keyword) => {
        const result = await tableListGeneratorV2({
          behavior,
          fetchParams: {
            max: maxTake,
            currentPage,
            nextCursorId,
            prevCursorId,
            keyword,
          },
          fetchFn: fetchFn,
        });
        if (!result) throw new Error('fetch failed');
        const newResult = {
          currentPage: result.currentPage,
          disabledNext: result.disabledNext,
          nextCursorId: result.nextCursorId,
          prevCursorId: result.prevCursorId,
          data: result.items,
        };

        return newResult;
      },
    });
  }, []);
  const [current, send] = useMachine(machineFn, {
    devTools: import.meta.env.DEV,
  });

  return {
    current: current,
    isLoading: current.matches('fetching'),
    onFetch: (behavior: 'prev' | 'next') => {
      send('FETCH', { behavior });
    },
    onSearch: () => {
      send('FETCH', { behavior: 'none' });
    },
    onUpdateKeyword: (keyword: string) => {
      send('UPDATE_SEARCH_KEYWORD', { keyword });
    },
    onScroll: (e: SyntheticEvent<HTMLDivElement>) => {
      const { currentTarget } = e;

      // is scroll bottom and not disabled next
      if (
        currentTarget.scrollTop + currentTarget.offsetHeight >= currentTarget.scrollHeight &&
        !current.context.list.disabledNext
      ) {
        // if failed return
        if (current.matches('failure')) return;
        send('FETCH_MORE', { behavior: 'next' });
      }
    },
  };
};
