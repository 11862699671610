import React, { useCallback } from 'react';
import { EmptyState } from '@components/EmptyState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmptyContainer, Content, CustomFieldDivider, ImageField } from './style';
import useTranslation from '@hooks/useTranslation';
import { ECustomFieldActionType, ECustomFieldType } from '@enums/CustomField';
import { ICustomField } from '@types';
import { Font12G4W400, Font16G1W500, Spacing } from '@components/UtilsComponent';
import { PrimaryButton } from '@components/Button';
import { useEngagementUserProfile } from '../../EngagementUserProfileProvider';
import { isEmpty } from 'lodash';

export const AudienceInfoTab = () => {
  const t = useTranslation();
  const { audienceInfo } = useEngagementUserProfile();
  const { isLoading, data } = audienceInfo;
  const _renderDivider = useCallback((data: ICustomField[], index: number) => {
    return index !== data.length - 1 ? <CustomFieldDivider /> : null;
  }, []);

  const _renderAction = useCallback((item: ICustomField) => {
    if (item.type !== ECustomFieldType.ACTION) return null;
    // map action
    if (item.action.type === ECustomFieldActionType.MAP && item.action.link) {
      return (
        <div>
          <Font12G4W400>{t('livechat.user.profile.audience.info.tab.custom.field.action.format')}</Font12G4W400>
          <br />
          <a target="_blank" rel="noreferrer" href={item.action.link}>
            <PrimaryButton>{item.action.label}</PrimaryButton>
          </a>
        </div>
      );
    }
    // call action
    if (item.action.type === ECustomFieldActionType.CALL && item.action.phoneNo) {
      return (
        <div>
          <Font12G4W400>{t('livechat.user.profile.audience.info.tab.custom.field.action.format')}</Font12G4W400>
          <br />
          <a href={`tel:${item.action.phoneNo}`}>
            <PrimaryButton>{item.action.label}</PrimaryButton>
          </a>
        </div>
      );
    }
    // conference action
    if (item.action.type === ECustomFieldActionType.CONFERENCE && item.action.link) {
      return (
        <div>
          <Font12G4W400>{t('livechat.user.profile.audience.info.tab.custom.field.action.format')}</Font12G4W400>
          <Spacing height={8} />
          <a href={`tel:${item.action.link}`}>
            <PrimaryButton>{item.action.label}</PrimaryButton>
          </a>
        </div>
      );
    }
  }, []);

  const _renderContent = () => {
    // loaded and no data
    if (!isLoading && isEmpty(data)) {
      return (
        <EmptyContainer>
          <EmptyState
            icon={
              <FontAwesomeIcon
                icon={['far', 'file-search']}
                color={'rgba(0, 0, 0, 0.2)'}
                style={{
                  fontSize: 36,
                }}
              />
            }
            title={t('livechat.user.profile.audience.info.tab.empty.title')}
            description={t('livechat.user.profile.audience.info.tab.empty.description')}
          />
        </EmptyContainer>
      );
    }

    return (
      <>
        <Spacing height={16} />
        <Content>
          {data?.map((item: ICustomField, index: number) => {
            if (item.type === ECustomFieldType.TEXT) {
              return (
                <div key={item.id}>
                  <Font12G4W400>{item.key}</Font12G4W400>
                  <br />
                  <Font16G1W500>{item.value}</Font16G1W500>
                  <div>{_renderDivider(data, index)}</div>
                </div>
              );
            }
            if (item.type === ECustomFieldType.IMAGE) {
              return (
                <div key={item.id}>
                  <Font12G4W400>{item.key}</Font12G4W400>
                  <br />
                  <ImageField src={item.value} />
                  {_renderDivider(data, index)}
                </div>
              );
            }
            // render type action
            return (
              <div key={item.id}>
                {_renderAction(item)} <div>{_renderDivider(data, index)}</div>
              </div>
            );
          })}
        </Content>
      </>
    );
  };
  // return nothing if loading
  if (audienceInfo.isLoading) return null;

  return <>{_renderContent()}</>;
};
