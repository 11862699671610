import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { filterItemState, currentFilterState, userListState, excludeTeamIdsState } from '@store/atom';
import SocketIO from '@socket';
import { useMember } from '@providers/MemberProvider';
import { useDebouncedCallback } from 'use-lodash-debounce';
import { DEBOUNCE_FETCH_TASK_TIME, MAX_USER_PER_PAGE } from '@configs/constants';
import axios, { CancelTokenSource } from 'axios';
import { getTicket } from '@api/livechat/EngagementUserList';
import produce from 'immer';
import { ISocketEvent, ITicketFilter } from '@types';
import { privateAction } from '@utils/privateAction';
import { notification } from '@libs/Notification';
import { ETicketFilterStatus } from '@enums/TicketFilter';

export type IFilterPayload = {
  assignedToMe: number;
  assignedToTeam: number;
  followUps: number;
  unAssigned: number;
  [key: string]: number;
};

export const useTicketFilter = () => {
  const excludeTeamIds = useRecoilValue(excludeTeamIdsState);
  const socketRef = useRef<SocketIO | null>(null);
  const [filterItems, setFilterItems] = useRecoilState(filterItemState);
  const [currentFilter, setCurrentFilter] = useRecoilState(currentFilterState);
  const setUserList = useSetRecoilState(userListState);
  const { member, isAdmin } = useMember();
  const cancelToken = useRef<CancelTokenSource>();

  const debounced = useDebouncedCallback(
    // function
    async () => {
      const { task_count } = await loadUserFromApi();
      updateFilterItem(task_count);
    },
    // delay in ms
    DEBOUNCE_FETCH_TASK_TIME,
  );

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    privateAction((token: string) => {
      socketRef.current = new SocketIO(
        `task`,
        {
          nid: member?.nid,
          agentId: member._id,
          teams: member?.teamId,
          channels: currentFilter.channels.map((item) => item),
        },
        token,
      );
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [currentFilter.channels, currentFilter.ticketStatus, currentFilter.selectedTeam]);

  useEffect(() => {
    if (!socketRef.current) return;
    socketRef.current?.on('filterTicket', onSocketChange);

    return () => {
      if (!socketRef.current) return;
      socketRef.current.off('filterTicket', onSocketChange);
    };
  }, [socketRef.current]);

  const onSocketChange = (payload: IFilterPayload & ISocketEvent) => {
    socketRef.current?.setOffset(payload['eventId']?.toString());
    // play notification sound if new ticket assigned to me
    if (payload.assignedToMe === 1) {
      notification.playNotificationSound();
    }
    setFilterItems(
      produce((draft) => {
        //no replace in payload
        for (const key in payload) {
          if (key === 'eventId') {
            continue;
          }

          const foundTicketStatusIndex = draft.findIndex((item) => item.value === key);

          //found and current count is 0 and payload is -1
          if (foundTicketStatusIndex > -1 && draft[foundTicketStatusIndex]['count'] === 0 && payload[key] === -1) {
            continue;
          }
          // found and current count more than 0 and payload is -1
          else if (foundTicketStatusIndex > -1 && draft[foundTicketStatusIndex]['count'] > 0 && payload[key] === -1) {
            const sum = draft[foundTicketStatusIndex]['count'] - 1;
            draft[foundTicketStatusIndex]['count'] = sum;
          } else {
            const sum = draft[foundTicketStatusIndex]['count'] + payload[key];
            draft[foundTicketStatusIndex]['count'] = sum;
          }
        }
        return draft;
      }),
    );
    debounced(payload);
  };

  const loadUserFromApi = async () => {
    if (typeof cancelToken.current != typeof undefined) {
      cancelToken.current?.cancel('Operation canceled due to new request.');
    }
    cancelToken.current = axios.CancelToken.source();
    const channelIds = getChannelValue();
    let excludeTeamIdsQuery: string[] | undefined = undefined;
    // current task is unassigned and current member is admin
    if (currentFilter.ticketStatus === ETicketFilterStatus.UNASSIGNED && isAdmin()) {
      excludeTeamIdsQuery = excludeTeamIds;
    }
    let filterNoTeam = false;
    let selectedTeam = [];
    // current member is admin and filter is assigned to team or team follow up
    if (currentFilter.selectedTeam) {
      selectedTeam = currentFilter.selectedTeam.length === 0 ? [] : currentFilter.selectedTeam;
    } else {
      selectedTeam = member.teamId;
    }

    if (currentFilter.ticketStatus === ETicketFilterStatus.UNASSIGNED) {
      if (!currentFilter.filterNoTeam) {
        filterNoTeam = false;
      } else {
        filterNoTeam = true;
      }
    } else {
      filterNoTeam = !!currentFilter.filterNoTeam;
    }

    const { data } = await getTicket(
      member._id,
      MAX_USER_PER_PAGE,
      member.nid,
      'desc',
      channelIds ? channelIds : '_all',
      currentFilter.ticketStatus,
      selectedTeam,
      cancelToken.current,
      undefined,
      currentFilter.filterByUnreplied,
      excludeTeamIdsQuery,
      filterNoTeam,
    );
    return data;
  };

  const getChannelValue = (): string => {
    return currentFilter.channels.map((channel) => channel).join(',');
  };

  const handleFilterChange = <T extends ITicketFilter[keyof ITicketFilter]>(value: T, field: keyof ITicketFilter) => {
    // do nothing if current filter is the same with new filter
    if (currentFilter[field] === value) return;
    setUserList({
      items: [],
    });
    setCurrentFilter((prev) => {
      return {
        ...prev,
        [field]: value,
        currentPage: 0,
      };
    });
  };

  const updateFilterItem = (data: IFilterPayload) => {
    setFilterItems(
      produce((draft) => {
        for (const key in data) {
          const foundTicketStatusIndex = draft.findIndex((item) => item.value === key);
          if (foundTicketStatusIndex > -1) {
            draft[foundTicketStatusIndex]['count'] = data[key];
          }
        }
        return draft;
      }),
    );
  };

  return {
    filterItems,
    currentFilter,
    handleFilterChange,
  };
};
