import React from 'react';
import { InputProps } from 'antd';
import { AntdTextInput, Container } from './style';

export type ITextInput = InputProps;

const TextInput = ({ ...rest }: ITextInput, ref: any) => {
  return (
    <Container>
      <AntdTextInput {...rest} ref={ref} />
    </Container>
  );
};

export default React.forwardRef(TextInput);
