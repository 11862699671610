import React from 'react';
import { Typography } from 'antd';
import { AssignedTag, OpenTag, ResolvedTag } from './style';
import { Font14G1W700 } from '@components/UtilsComponent';

export type IStatusTag = {
  type?: 'open' | 'assigned' | 'resolved';
};

export const StatusTag = (props: IStatusTag) => {
  switch (props.type) {
    case 'open':
      return (
        <OpenTag>
          <Font14G1W700>Open</Font14G1W700>
        </OpenTag>
      );
    case 'assigned':
      return (
        <AssignedTag>
          <Font14G1W700>Assigned</Font14G1W700>
        </AssignedTag>
      );
    case 'resolved':
      return (
        <ResolvedTag>
          <Font14G1W700>Resolved</Font14G1W700>
        </ResolvedTag>
      );
    default:
      return <Typography.Text strong>-</Typography.Text>;
  }
};

export default StatusTag;
