import { isMobile } from 'react-device-detect';

export const onPressEnterAndShift = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
  const keyCode = e.which || e.keyCode;
  if (isMobile) {
    return () => void 0;
  }
  // enter
  if (keyCode === 13 && !e.shiftKey) {
    e.preventDefault();
    return (cb: () => void) => cb();
  }
  return () => void 0;
};
