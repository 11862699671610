import styled from 'styled-components/macro';

export const Container = styled.div`
  margin-top: 16px;
`;

export const LoadingWrap = styled.div``;

export const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #757575;
  svg {
    font-size: 16px !important;
  }
`;

export const TeamNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  span:nth-child(1) {
    color: ${({ theme }) => theme.GREY_1};
    font-weight: 600;
  }
  span:nth-child(2) {
    color: ${({ theme }) => theme.GREY_5};
    font-weight: 400;
  }
`;
