import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingCircle from '@components/Loading';
import { Link, useRouteMatch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import UserAvatar from '../UserAvatar';
import { AutoSizer } from 'react-virtualized';
import _ from 'lodash';
import { UserDataItem } from '@types';
import { useQueryParams } from '@hooks/useQueryParams';

export type IUserList_ = {
  userData: UserDataItem[];
  hasMore: boolean;
  handleLoadmore: () => void;
  handleSelectUser?: (index: number) => void;
};

const UserList_: React.FC<IUserList_> = ({ userData, hasMore, handleLoadmore, handleSelectUser }: IUserList_) => {
  const match: { [key: string]: any } | null = useRouteMatch('/recents/:id');
  const query = useQueryParams();
  const currentTab = query.get('status');
  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <InfiniteScroll
            style={{ width: width }}
            height={height - 16}
            dataLength={userData.length}
            next={handleLoadmore}
            hasMore={hasMore}
            loader={<LoadingCircle />}
          >
            {userData.map(({ isRead, user }, index: number) => {
              const to = {
                pathname: isMobile ? `/m/recents/${user.id}` : `/recents/${user.id}`,
                state: {
                  lastClickTime: Date.now(),
                },
                search: isMobile ? `?prev=${currentTab}` : undefined,
              };
              return (
                <Link
                  style={{ overflowAnchor: 'none' }}
                  key={user.id}
                  to={to}
                  onClick={() => handleSelectUser && handleSelectUser(index)}
                >
                  <UserAvatar shouldShowNotification={!isRead} {...user} isSelected={match?.params?.id === user.id} />
                </Link>
              );
            })}
          </InfiniteScroll>
        );
      }}
    </AutoSizer>
  );
};

export default UserList_;
