import styled from 'styled-components/macro';

export const Container = styled.div`
  .ant-switch {
    width: 48px;
    height: 28px;
  }
  .ant-switch-handle {
    width: 28px;
    height: 28px;
    top: 0;
    left: 0;
  }
  .ant-switch-handle::before {
    border-radius: 50%;
  }
`;
