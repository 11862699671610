import React, { PropsWithChildren } from 'react';
import { BottomSheet, BottomSheetProps, BottomSheetRef } from 'react-spring-bottom-sheet';
import { BackDrop } from './style';
import './style.css';
import 'react-spring-bottom-sheet/dist/style.css';

export type AbstractBottomModalProps = {
  open: boolean;
  ref?: React.RefObject<BottomSheetRef>;
  onDismiss: () => void;
  snapPoints?: ({ minHeight, maxHeight }: { minHeight: number; maxHeight: number }) => number[] | number;
} & Omit<BottomSheetProps, 'children'>;

const AbstractBottomModal = (props: PropsWithChildren<AbstractBottomModalProps>) => {
  return (
    <BottomSheet
      {...props}
      className="abstract-bottom-sheet"
      data-body-scroll-lock-ignore="true"
      ref={props.ref}
      sibling={
        <BackDrop
          data-rsbs-backdrop="true"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (props.onDismiss) {
              props.onDismiss();
            }
          }}
        />
      }
    >
      <>{props.children}</>
    </BottomSheet>
  );
};

export default AbstractBottomModal;
