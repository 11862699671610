import React, { useEffect, useState } from 'react';
import { Card } from '@components/Card';
import { Font12G5W400, Font14G1W400, Font14G1W400Outline, Font14G3W400 } from '@components/UtilsComponent';
import { faUserHeadset } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { orderBy } from 'lodash';
import { IFilterState } from '../useDashboardFilter';
import { FetchWrap } from '@components/FetchWrap';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { ITicketByAgentResponse } from '@types';
import { getTicketByAgentAnalytic } from '@api/livechat/Analytic';
import { CardContent } from '@components/CardWrap';
import Select from '@components/Select';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingCircle from '@components/Loading';
import { Popover } from '@components/Popover';
type ITicketByAgentProps = {
  filter: IFilterState;
};

const STATUS = {
  all: 'all',
  resolved: 'resolved',
  assigned: 'assigned',
} as const;
const MIN_WIDTH_THRESHOLD = 3;
let nextToken: string | undefined = undefined;
let jobId: string | undefined = undefined;
export const TicketByAgent = ({ filter }: ITicketByAgentProps) => {
  const t = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState<keyof typeof STATUS>(STATUS.all);
  const [list, setList] = useState<ITicketByAgentResponse['data']>([]);
  const [internalFilter, setInternalFilter] = useState<IFilterState>(filter);
  useEffect(() => {
    nextToken = undefined;
    jobId = undefined;
    setInternalFilter(filter);
    setList([]);
  }, [filter, selectedStatus]);

  return (
    <>
      <FetchWrap<ITicketByAgentResponse, IFilterState>
        filter={internalFilter}
        queryKey={['ticket-by-agent', internalFilter, selectedStatus]}
        fetchFn={async (filter: IFilterState, cancelToken?: CancelTokenSource | undefined) => {
          const result: AxiosResponse<ITicketByAgentResponse> = await getTicketByAgentAnalytic({
            agents: filter.assignees,
            channels: filter.channels,
            tags: filter.tags,
            teams: filter.teams,
            startIsoStringDate: filter.startIsoStringDate.toISOString(),
            endIsoStringDate: filter.endIsoStringDate.toISOString(),
            status: selectedStatus === 'all' ? undefined : [selectedStatus],
            nextToken: nextToken,
            jobId: jobId,
            cancelToken,
          });
          if (result.data.nextToken) {
            jobId = result.data.jobId;
            nextToken = result.data.nextToken;
          }
          setList((prev) => [...prev, ...(result.data.data ?? [])]);
          return result.data;
        }}
        renderFn={function ({
          isLoading,
          data,
          isError,
          refetch,
        }: {
          isLoading: boolean;
          data?: ITicketByAgentResponse;
          isError: boolean;
          refetch: () => void;
        }): JSX.Element {
          const orderByHighest = orderBy(
            list,
            (row) => (row.count?.assigned ?? 0) + (row.count?.resolved ?? 0),
            'desc',
          );

          const highest = (orderByHighest?.[0]?.count?.assigned || 0) + (orderByHighest?.[0]?.count?.resolved || 0);
          return (
            <Card
              maxHeight="420px"
              minHeight="420px"
              divider
              title={t('ticket.by.agent.title')}
              description={t('ticket.by.agent.description')}
              topLeftIcon={<FontAwesomeIcon icon={faUserHeadset} />}
              _renderCustomActionList={() => {
                return (
                  <div className="w-[144px]">
                    <Select
                      value={selectedStatus}
                      onChange={(value: keyof typeof STATUS) => {
                        setSelectedStatus(value);
                        // clear nextToken and jobId
                        nextToken = undefined;
                        jobId = undefined;
                      }}
                      options={[
                        {
                          label: 'All Ticket',
                          value: STATUS.all,
                        },
                        {
                          label: 'Assigned',
                          value: STATUS.assigned,
                        },
                        {
                          label: 'Resolved',
                          value: STATUS.resolved,
                        },
                      ]}
                    />
                  </div>
                );
              }}
            >
              <InfiniteScroll
                height={'100%'}
                style={{ height: 'auto', maxHeight: 290 }}
                next={() => {
                  refetch();
                }}
                hasMore={!!data?.nextToken}
                loader={!isError && <LoadingCircle />}
                dataLength={orderByHighest.length}
              >
                <div className="p-[24px]">
                  <CardContent
                    isLoading={isLoading}
                    isError={isError}
                    onRetry={() => {
                      jobId = undefined;
                      nextToken = undefined;
                      setList([]);
                      refetch();
                    }}
                  >
                    <div className="flex flex-col gap-y-[16px]">
                      <div className="flex justify-center items-center gap-x-[16px]">
                        <div className="flex gap-x-[8px] items-center">
                          <svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
                            <circle r="3" cx="3" cy="3" fill="#0F86FE" />
                          </svg>
                          <Font12G5W400>{t('ticket.by.agent.assgined.label')}</Font12G5W400>
                        </div>
                        <div className="flex gap-x-[8px] items-center">
                          <svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
                            <circle r="3" cx="3" cy="3" fill="#04BE8C" />
                          </svg>
                          <Font12G5W400>{t('ticket.by.agent.resolved.label')}</Font12G5W400>
                        </div>
                      </div>
                      {list?.map((row) => {
                        const resolvePercent = ((row?.count?.resolved ?? 0) / highest) * 100;
                        const assignedPercent = ((row?.count?.assigned ?? 0) / highest) * 100;
                        return (
                          <div key={row.agentId} className="flex gap-x-[16px] h-[20px] items-center">
                            <div
                              className="w-[100px] max-w-[100px] overflow-hidden overflow-ellipsis"
                              title={row.agentName}
                            >
                              <Font14G3W400>{row.agentName}</Font14G3W400>
                            </div>

                            <div className="flex w-full">
                              {assignedPercent ? (
                                <Popover
                                  content={
                                    <div className="flex flex-col">
                                      <div className="flex gap-x-[8px]">
                                        <div className="flex gap-x-[4px] items-center">
                                          <svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="3" cx="3" cy="3" fill="#0F86FE" />
                                          </svg>
                                          <Font12G5W400>{t('ticket.by.agent.assgined.label')}:</Font12G5W400>
                                        </div>
                                        <div>
                                          <Font14G1W400>{row.count.assigned}</Font14G1W400>
                                        </div>
                                      </div>

                                      <div className="flex gap-x-[8px]">
                                        <div className="flex gap-x-[4px] items-center">
                                          <svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="3" cx="3" cy="3" fill="#04BE8C" />
                                          </svg>
                                          <Font12G5W400>{t('ticket.by.agent.resolved.label')}:</Font12G5W400>
                                        </div>
                                        <div>
                                          <Font14G1W400>{row.count.resolved}</Font14G1W400>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  overlayInnerStyle={{
                                    borderRadius: 8,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: `${
                                        assignedPercent < MIN_WIDTH_THRESHOLD ? MIN_WIDTH_THRESHOLD : assignedPercent
                                      }%`,
                                    }}
                                    className={` bg-[#0F86FE] h-[20px] flex items-center justify-center`}
                                  >
                                    <Font14G1W400Outline> {row.count.assigned} </Font14G1W400Outline>
                                  </div>
                                </Popover>
                              ) : null}
                              {resolvePercent ? (
                                <Popover
                                  content={
                                    <div className="flex flex-col">
                                      <div className="flex gap-x-[8px]">
                                        <div className="flex gap-x-[4px] items-center">
                                          <svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="3" cx="3" cy="3" fill="#0F86FE" />
                                          </svg>
                                          <Font12G5W400>{t('ticket.by.agent.assgined.label')}:</Font12G5W400>
                                        </div>
                                        <div>
                                          <Font14G1W400>{row.count.assigned}</Font14G1W400>
                                        </div>
                                      </div>

                                      <div className="flex gap-x-[8px]">
                                        <div className="flex gap-x-[4px] items-center">
                                          <svg height="8" width="8" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="3" cx="3" cy="3" fill="#04BE8C" />
                                          </svg>
                                          <Font12G5W400>{t('ticket.by.agent.resolved.label')}:</Font12G5W400>
                                        </div>
                                        <div>
                                          <Font14G1W400>{row.count.resolved}</Font14G1W400>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                  overlayInnerStyle={{
                                    borderRadius: 8,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: `${
                                        resolvePercent < MIN_WIDTH_THRESHOLD ? MIN_WIDTH_THRESHOLD : resolvePercent
                                      }%`,
                                    }}
                                    className={` bg-[#04BE8C] h-[20px] flex items-center justify-center`}
                                  >
                                    <Font14G1W400Outline> {row.count.resolved}</Font14G1W400Outline>
                                  </div>
                                </Popover>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CardContent>
                </div>
              </InfiniteScroll>
            </Card>
          );
        }}
      />
    </>
  );
};
