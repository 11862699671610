import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MobileGetStart from '@pages/mobile/GetStart';
import { MOBILE_ROUTE } from '@configs/constants';
import UserList from './UserList';
import TicketManager from '@providers/TicketManagerProvider';
import EngagementMessage from './EngagementMessage';
import UserProfile from './UserProfile';
import Login from '@pages/auth/login';
import { PrivateRoute } from '@routes/PrivateRoutes';
import { ComposerManagerProvider } from '@providers/ComposerManagerProvider';

const LiveChatMobile: React.FC = () => {
  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        {/* routing is defined in config.js */}
        <Route path={MOBILE_ROUTE.GET_START}>
          <PrivateRoute>
            <MobileGetStart />
          </PrivateRoute>
        </Route>

        <Route
          path={MOBILE_ROUTE.RECENTS_WITH_ID}
          render={(routeProps) => {
            const userId = routeProps.match.params.id!;
            return (
              <TicketManager userId={userId}>
                <ComposerManagerProvider>
                  <EngagementMessage />
                </ComposerManagerProvider>
              </TicketManager>
            );
          }}
        />

        <Route path={MOBILE_ROUTE.RECENTS}>
          <UserList />
        </Route>

        <Route path={MOBILE_ROUTE.USER}>
          <UserProfile />
        </Route>

        <Route path={MOBILE_ROUTE.LOGIN}>
          <Login />
        </Route>

        <Route path="*" exact>
          <Redirect to={MOBILE_ROUTE.GET_START} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default LiveChatMobile;
