import styled from 'styled-components/macro';
import { Input } from 'antd';

export const AntdPasswordInput = styled(Input.Password)``;

export const Container = styled.div`
  .ant-input-affix-wrapper {
    border: 1px solid ${({ theme }) => theme.GREY_7};
    box-shadow: none !important;
    box-sizing: border-box;
    border-radius: 8px !important;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover {
    border: 1px solid ${({ theme }) => theme.GREY_7};
  }
`;
