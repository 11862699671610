import { client } from '@api';
import { IReportApiParams, IReportResponse } from '@types';
import { AxiosResponse } from 'axios';

export const getReport = async (body: IReportApiParams) => {
  const result: AxiosResponse<IReportResponse> = await client.post('/api/v1/reports/generate', {
    ...body,
  });

  return result;
};

export const getReportStatus = async (id: string) => {
  const result: AxiosResponse<IReportResponse> = await client.get(`/api/v1/reports/${id}`);

  return result;
};
