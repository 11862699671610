import styled from 'styled-components/macro';
import { TertiaryButton as DianaTertiaryButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const Container = styled.div``;

export const regionStyle = {
  background: 'rgba(33, 33, 33, 0.5)',
  borderRadius: 8,
};

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  display: flex !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  background: rgba(0, 0, 0, 0.4);
`;

export const OverlayAction = styled.div`
  position: absolute;
  display: flex;
  column-gap: 8px;
`;

export const ImageSection = styled.div<{
  imgUrl?: string;
  isInvalidate: boolean;
}>`
  line-height: 0;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  ${({ imgUrl }) => !imgUrl && `border: 2px dashed #bdbdbd;`}
  ${({ imgUrl }) => (imgUrl ? `cursor: initial;` : `cursor: pointer;`)}
  ${({ isInvalidate }) => isInvalidate && `border: 2px solid #F72C40 !important;`}
  width: 100%;
  height: 100%;
  height: ${({ imgUrl }) => (imgUrl ? 'auto' : '552px')};
  border-radius: 16px;
  flex-direction: column;
  background-position: center;
  background: #fafafa;
  img {
    width: 100%;
  }
`;

export const TertiaryButton = styled(DianaTertiaryButton)`
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background: transparent;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 156px;
  height: 48px;
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionWrap = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    font-size: 12px;
    color: white;
  }
`;

export const MapDecision = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 36px;
  background: rgba(33, 33, 33, 0.5);
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

export const IconWrap = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const IconBtn = styled(FontAwesomeIcon)`
  color: white;
  width: 20px;
  height: 20px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 400px;
`;

export const RegionSelectContainer = styled.div`
  line-height: 0;
`;
