import styled from 'styled-components/macro';
import { Input } from 'antd';

export const AntdTextInput = styled(Input)``;

export const Container = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.GREY_7};
    box-shadow: none !important;
    box-sizing: border-box;
    border-radius: 8px !important;
  }

  .ant-input:focus,
  .ant-input:hover {
    border-color: ${({ theme }) => theme.SECONDARY_BLUE} !important;
  }
`;

export const Invalidate = styled.div<{ isInvalidate: boolean }>`
  input {
    border: ${({ isInvalidate, theme }) => (isInvalidate ? `1px solid ${theme.RED} !important` : '')};
  }
`;
