import { uploadFile as uploadFileApi } from '@api/upload';
import { AnyEventObject } from 'xstate';
import { IMessageBoxMachineContext } from '../messageBoxMachine';

/**
 *
 * @param {IMessageBoxMachineContext} context machine context of `MessageBox`
 *
 * @param {AnyEventObject} event event that be attached to the function
 *
 * @param {IMember} event.member member that upload the files
 *
 * @returns
 */
export const uploadFile = async (context: IMessageBoxMachineContext, event: AnyEventObject) => {
  const form = new FormData();
  const { member } = event;
  form.append('nid', member.nid);
  form.append('channelType', String(context.channelType));
  form.append('fileCount', String(context.selectedFiles.length));

  for (const item of context.selectedFiles) {
    form.append('file', item);
  }
  return await uploadFileApi(form);
};
