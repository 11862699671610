import { CustomFormItem } from '@components/CustomFormItem';
import { Counter, Font12G3W400, Font12G5W400, Font14G1W500 } from '@components/UtilsComponent';
import useTranslation from '@hooks/useTranslation';
import React from 'react';
import { IUpdateSurveyFnSignature } from '../../CustomerSatisfactionEditor/useCustomerSatisfactionEditor';
import TextArea from '@components/TextArea';
import { MAX_QUESTION_LENGTH } from '@configs/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { removeExceedFromText } from '@utils/letters';

type IExternalSurveyProps = {
  question: string;
  onUpdateSurvey: IUpdateSurveyFnSignature;
};

export const ExternalSurvey = ({ question, onUpdateSurvey }: IExternalSurveyProps) => {
  const t = useTranslation();
  return (
    <div className="w-full">
      <div className="w-full rounded-[16px] shadow-custom p-[24px] bg-white transition-all">
        <div className="flex flex-col gap-y-[4px]">
          <Font14G1W500>{t('customer.satisfaction.editor.external.link.qustion.label')}</Font14G1W500>
          <CustomFormItem
            id="external-link-question"
            isError={!question}
            errorMessage={t('customer.satisfaction.editor.external.link.question.validate')}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const result = removeExceedFromText(e.target.value, MAX_QUESTION_LENGTH);
              onUpdateSurvey('message.externalLink', result);
            }}
            value={question}
            counter={
              <Counter>
                <Font12G5W400>{`${question.length} / ${MAX_QUESTION_LENGTH}`}</Font12G5W400>
              </Counter>
            }
          >
            <TextArea
              autoSize={{
                minRows: 4,
                maxRows: 8,
              }}
              maxLength={MAX_QUESTION_LENGTH}
            />
          </CustomFormItem>

          <div className="mt-[24px] flex gap-x-[4px]">
            <FontAwesomeIcon icon={faInfoCircle} className="text-[16px] text-[#BDBDBD]" />
            <Font12G3W400>{t('customer.satisfaction.editor.external.link.hint')}</Font12G3W400>
          </div>
        </div>
      </div>
    </div>
  );
};
