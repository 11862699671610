import _ from 'lodash';
import mime from 'mime';
import FileExtension from '@enums/FileExtension';
import isVideoByMime from './isVideoByMime';

/**
 * determine mime type is allowed or not
 *
 * @param {String} mimeType mime type of file
 * @returns {Boolean} should current mime type be allowed to the service in boolean
 */
const isExtensionAllowByMime = (mimeType: string) => {
  if (isVideoByMime(mimeType)) {
    return true;
  }
  const allowTypes = _.values(FileExtension).map((ext) => mime.getType(ext));
  return allowTypes.includes(mimeType);
};

export default isExtensionAllowByMime;
