import * as theme from '@styles/theme';
import styled from 'styled-components/macro';
import { BaseTag } from '../style';

export const OpenTag = styled(BaseTag)`
  background-color: ${theme.BLUE_LIGHT};
  span {
    color: ${theme.BLUE};
  }
`;

export const AssignedTag = styled(BaseTag)`
  background-color: ${theme.ORANGE_LIGHT};
  span {
    color: ${theme.ORANGE};
  }
`;

export const ResolvedTag = styled(BaseTag)``;
