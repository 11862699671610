import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowWrap, Container } from './style';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { GhostButton } from '@components/Button';

export type ITableList = {
  next: {
    onClick: () => void;
    disabled: boolean;
  };
  prev: {
    onClick: () => void;
    disabled: boolean;
  };
};

const TableList = ({ next, prev, children }: React.PropsWithChildren<ITableList>) => {
  return (
    <Container>
      {children}
      <ArrowWrap>
        <GhostButton {...prev} data-testid="prev-btn" icon={<FontAwesomeIcon icon={faChevronLeft} />} />
        <GhostButton {...next} data-testid="next-btn" icon={<FontAwesomeIcon icon={faChevronRight} />} />
      </ArrowWrap>
    </Container>
  );
};

export default TableList;
