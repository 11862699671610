import React, { useEffect, useRef, useState } from 'react';

type ISwitchFadeProps = {
  isEl1Show: boolean;
  duration: number;
  el1: {
    el: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>;
  el2: {
    el: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>;
} & React.HTMLAttributes<HTMLDivElement>;

const fIn = [{ opacity: '0' }, { opacity: '1' }];
const fOut = [{ opacity: '1' }, { opacity: '0' }];
export const SwitchFade: React.FC<ISwitchFadeProps> = React.memo(({ isEl1Show, duration, el1, el2 }) => {
  const el1Ref = useRef<HTMLDivElement>(null);
  const el2Ref = useRef<HTMLDivElement>(null);
  const animation1Ref = useRef<Animation | null>(null);
  const animation2Ref = useRef<Animation | null>(null);
  const [initialRender, setInitialRender] = useState(true);
  const { ...el1Rest } = el1;
  const { ...el2Rest } = el2;

  useEffect(() => {
    el2Ref.current!.style.display = 'none';
  }, []);

  useEffect(() => {
    if (!el1Ref.current || initialRender || !el2Ref.current) {
      setInitialRender(false);
      return;
    }
    // visible play fade in
    if (isEl1Show) {
      el1Ref.current!.style.display = 'block';
      el2Ref.current!.style.display = 'none';
      animation1Ref.current = el1Ref.current.animate(fIn, {
        duration: duration,
        fill: 'forwards',
      });
      animation2Ref.current = el2Ref.current.animate(fOut, {
        duration: duration,
        fill: 'forwards',
      });
      animation2Ref.current.onfinish = () => {
        el2Ref.current!.style.display = 'none';
      };
    } else {
      el2Ref.current!.style.display = 'block';
      el1Ref.current!.style.display = 'none';
      animation1Ref.current = el1Ref.current.animate(fOut, {
        duration: duration,
        fill: 'forwards',
      });
      animation2Ref.current = el2Ref.current.animate(fIn, {
        duration: duration,
        fill: 'forwards',
      });
      animation1Ref.current.onfinish = () => {
        el1Ref.current!.style.display = 'none';
      };
    }
    return () => {
      // Cleanup animation on component unmount
      if (animation1Ref.current) {
        animation1Ref.current.cancel();
      }
      if (animation2Ref.current) {
        animation2Ref.current.cancel();
      }
    };
  }, [isEl1Show]);

  return (
    <>
      <div {...el1Rest} ref={el1Ref}>
        {el1.el}
      </div>
      <div {...el2Rest} ref={el2Ref}>
        <div>{el2.el}</div>
      </div>
    </>
  );
});
