import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from './style';
import NewTeamEditor from './NewTeamEditor';
import TeamManagementPane from './TeamManagementPane';
import EditTeamEditor from './EditTeamEditor';

const TeamManagement: React.FC = () => {
  return (
    <Container>
      <Switch>
        <Route path="/team-management" exact>
          <TeamManagementPane />
        </Route>

        <Route path="/team-management/new-team">
          <NewTeamEditor />
        </Route>
        <Route path="/team-management/edit-team/:id">
          <EditTeamEditor />
        </Route>
        <Route path="/team-management">
          <Redirect to="/team-management" />
        </Route>
      </Switch>
    </Container>
  );
};

export default TeamManagement;
