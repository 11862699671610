import styled from 'styled-components/macro';

export const Container = styled.div`
  overflow: hidden;
  background: #fff;
  width: 290px;
  height: 193px;
  border-radius: 12px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
