import { EXCEPTIONS_MAP, GLOBAL_MESSAGE } from '@configs/constants';

export type IAmplifyException = {
  code: string;
  name: string;
  message: string;
};

export const exceptionMapper = (error: IAmplifyException) => {
  if (EXCEPTIONS_MAP[error.message]) {
    return EXCEPTIONS_MAP[error.message];
  }

  return GLOBAL_MESSAGE.ERROR_TITLE;
};
