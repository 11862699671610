import { client } from '@api';
import { BOTS, ICreateQuickReplyCollectionBE, IQuickReplyCollectionBE, IQuickReplyCollectionResponse } from '@types';
import { AxiosResponse, CancelTokenSource } from 'axios';

export const createQuickReplyCollection = async (payload: ICreateQuickReplyCollectionBE) => {
  return client.post('/api/v1/quick-replies/collections', payload);
};

export const fetchQuickReplyCollection = async (
  take: number,
  keyword?: string,
  cursorId?: string,
  cancelToken?: CancelTokenSource,
) => {
  const { data }: { data: IQuickReplyCollectionResponse[] } = await client.get('/api/v1/quick-replies/collections', {
    params: {
      keyword,
      cursorId,
      take,
    },
    cancelToken: cancelToken?.token,
  });
  return data;
};

export const fetchTemplateQuickReplyCollection = async (
  keyword?: string,
  cursorId?: string,
  take?: number,
  templateCollectionId?: string,
  platform?: string,
  cancelToken?: CancelTokenSource,
) => {
  const { data }: { data: BOTS.ITemplateApi[] } = await client.get('/api/v1/quick-replies/collections/templates', {
    params: {
      keyword,
      cursorId,
      take,
      templateCollectionId,
      platform,
    },
    cancelToken: cancelToken?.token,
  });
  return data;
};

export const deleteQuickReplyCollection = async (id: string) => {
  return client.delete(`/api/v1/quick-replies/collections/${id}`);
};

export const fetchQuickReplyCollectionById = async (
  id: string,
): Promise<AxiosResponse<IQuickReplyCollectionResponse>> => {
  return client.get(`/api/v1/quick-replies/collections/${id}`);
};

export const updateQuickReplyCollection = async (id: string, payload: IQuickReplyCollectionBE) => {
  return client.put(`/api/v1/quick-replies/collections/${id}`, payload);
};
