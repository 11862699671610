import {
  BOTS,
  ICreateQuickReplyCollectionBE,
  IQuickReplyCollectionBE,
  IQuickReplyCollectionFE,
  IQuickReplyCollectionResponse,
} from '@types';
import { cloneDeep, isEqual, set } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getTemplates } from '@api/template';
import { useTemplateSelector } from '@hooks/useTemplateSelector';
import { useMutation } from 'react-query';
import { createQuickReplyCollection, fetchQuickReplyCollectionById, updateQuickReplyCollection } from '@api/quickReply';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTE } from '@configs/route';
import { useEdit } from '@hooks/useEdit';
import { AxiosError } from 'axios';
import { useAlertModal } from '@providers/AlertModalProvider';
import { COLLECTION_ERROR_MAPPING } from '@configs/quickReplyCollection';
import { errorGenerator } from '@utils/errorGenerator';

export const useEditor = () => {
  const { onOpen, onUpdateDetail } = useAlertModal();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<BOTS.ITemplateApi | null>(null);
  const [innerSelecteTemplateList, setInnerSelectedTemplateList] = useState<BOTS.ITemplateBEWithCheck[]>([]);
  const { id }: { id: string } = useParams();
  const { query, updateMute, onUpdate } = useEdit<IQuickReplyCollectionResponse, IQuickReplyCollectionBE>({
    id,
    fetchFn: async (id) => {
      if (!id) return;
      const { data }: { data: IQuickReplyCollectionResponse } = await fetchQuickReplyCollectionById(id);
      return data;
    },
    updateFn: async (data) => {
      if (!data.id) {
        return;
      }
      await updateQuickReplyCollection(data.id, {
        ...data,
        tags: [],
      });
      return data;
    },
  });
  const [collection, setCollection] = useState<IQuickReplyCollectionFE>({
    name: '',
    templates: [],
    tags: [],
  });
  const [tempCollection, setTempCollection] = useState<IQuickReplyCollectionFE>(collection);
  const [preview, setPreview] = useState<BOTS.ITemplateApi | null>(null);
  const [keyword, setKeyword] = useState('');
  const templateListHandler = useTemplateSelector(null, getTemplates);
  const createMute = useMutation((payload: IQuickReplyCollectionFE) => {
    const transformPayload: ICreateQuickReplyCollectionBE = {
      name: payload.name,
      templateIds: payload.templates.map((item) => item.id),
      tags: [],
    };
    return createQuickReplyCollection(transformPayload);
  });
  const t = useTranslation();
  const alert = useAlert();
  const isSomethingUpdate = useMemo(() => {
    return !isEqual(tempCollection, collection);
  }, [tempCollection, collection]);

  useEffect(() => {
    if (query.data) {
      const transform: IQuickReplyCollectionFE = {
        id: query.data.id,
        name: query.data.name,
        templates:
          query.data.templates?.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }) ?? [],
      };
      const inner = transform.templates.map((item) => {
        return {
          ...item,
          isChecked: false,
        };
      });
      setTempCollection(transform);
      setCollection(transform);
      setInnerSelectedTemplateList(inner);
    }
  }, [query.data]);

  return {
    manage: {
      query,
      updateMute,
      tempCollection,
      collection,
      keyword,
      preview,
      isSomethingUpdate,
      createMute,
      onSave: async () => {
        // create case
        try {
          await createMute.mutateAsync(collection);
          alert.success(t('template.collection.editor.save.success'), {
            timeout: 2000,
          });
          setTempCollection(collection);
          history.replace(ROUTE.TEMPLATE_COLLECTION);
        } catch (error) {
          console.error('Failed create quick reply collection', error);
          const err = error as Error | AxiosError;
          const errorResult = errorGenerator(err, COLLECTION_ERROR_MAPPING);
          if (typeof errorResult === 'object') {
            onUpdateDetail(errorResult.title, errorResult.description);
            onOpen();
          } else {
            onOpen();
          }
        }
      },
      onUpdate: async () => {
        // update case
        try {
          if (!collection.id) return;
          const transfrom: IQuickReplyCollectionBE = {
            id: collection.id,
            name: collection.name,
            templateIds: collection.templates.map((item) => {
              return item.id;
            }),
          };
          await onUpdate(transfrom);
          alert.success(t('template.collection.editor.update.success'), {
            timeout: 2000,
          });
          setTempCollection(collection);
          history.replace(ROUTE.TEMPLATE_COLLECTION);
        } catch (error) {
          console.error('Failed update quick reply collection', error);
          const err = error as Error | AxiosError;
          const errorResult = errorGenerator(err, COLLECTION_ERROR_MAPPING);
          if (typeof errorResult === 'object') {
            onUpdateDetail(errorResult.title, errorResult.description);
            onOpen();
          } else {
            onOpen();
          }
        }
      },
      onSelectPreview: (template: BOTS.ITemplateBEWithCheck) => {
        setPreview(template);
      },
      onSearch: (keyword: string) => {
        setKeyword(keyword);
      },
      onRemoveTemplate: (template: BOTS.ITemplateApi) => {
        setInnerSelectedTemplateList((prev) => prev.filter((item) => item.id !== template.id));
        setCollection((prev) => {
          return {
            ...prev,
            templates: prev.templates.filter((item) => item.id !== template.id),
          };
        });
        // remove preview if it's the same
        if (preview?.id === template.id) {
          setPreview(null);
        }
      },
      onCheckAll: (e: CheckboxChangeEvent) => {
        setCollection((prev) => {
          return {
            ...prev,
            templates: prev.templates.map((item) => {
              return {
                ...item,
                isChecked: e.target.checked,
              };
            }),
          };
        });
      },
      onCheckSingle: (e: CheckboxChangeEvent, record: BOTS.ITemplateBEWithCheck) => {
        const found = collection.templates.findIndex((item) => item.id === record.id);
        if (found === -1) return;
        setCollection((prev) => {
          return {
            ...prev,
            templates: set(prev.templates, `[${found}].isChecked`, e.target.checked),
          };
        });
      },
      onDeleteSelected: () => {
        // filter selected off both inner and outer list
        const outList = collection.templates.filter((item) => !item.isChecked);
        const unCheckList = innerSelecteTemplateList.filter((item) => item.isChecked);
        // if collection.templates list has preview item, remove it
        if (preview && unCheckList.some((item) => item.id === preview.id)) {
          setPreview(null);
        }
        setCollection((prev) => {
          return {
            ...prev,
            templates: outList,
          };
        });
        setInnerSelectedTemplateList(outList);
      },
      onCollectionNameChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCollection((prev) => {
          return {
            ...prev,
            name: e.target.value,
          };
        });
      },
    },

    modal: {
      selectedTemplate,
      innerSelecteTemplateList,
      templateListHandler,
      showModal,
      onToggle: () => {
        if (!showModal) {
          templateListHandler.onSearch();
        }
        setShowModal(!showModal);
      },
      onSelectTemplate: (template: BOTS.ITemplateApi) => {
        setSelectedTemplate(template);
      },
      onConfirmSelectTemplate: () => {
        setCollection((prev) => {
          return {
            ...prev,
            templates: innerSelecteTemplateList,
          };
        });
        setSelectedTemplate(null);
        setShowModal(false);
      },
      onCheckTemplate: (template: BOTS.ITemplateApi, e: CheckboxChangeEvent) => {
        // indexTemp.current.first = index;
        setInnerSelectedTemplateList((prev) => {
          const list = cloneDeep(prev);
          const found = list.findIndex((selected) => selected.id === template.id);
          if (found !== -1) {
            return list.filter((selected) => selected.id !== template.id);
          }
          return [...prev, { ...template, isChecked: false }];
        });
      },
    },
  };
};
