import { useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useTheme } from 'styled-components';
import { selectedTicketState, selectedUserState } from '@store/atom';
import { BOTS, ILinkMessage, INewMessage, ITextMessage, IVideoMessage } from '@types';
import { useMember } from '@providers/MemberProvider';
import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { EMessageStatus } from '@enums/MessageStatus';
import { EEventType } from '@enums/EventType';
import { ESenderType } from '@enums/SenderType';
import { EMessageType } from '@enums/MessageType';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { checkLink, genTemplatePayload, getMessage, getMessageFromTemplate } from '@providers/MessageBoxProvider/fns';
import { useComposerManager } from '@providers/ComposerManagerProvider';
import { getInputMaxLength } from '@utils/getInputMaxLength';

const useMessageComposer = () => {
  const theme: any = useTheme();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [txt, setTxt] = useState('');
  const selectedTicket = useRecoilValue(selectedTicketState);
  const selectedUser = useRecoilValue(selectedUserState);
  const { member } = useMember();
  const { send } = useMessageBox();
  const { preview, composer } = useComposerManager();
  const { onSelectTemplate } = preview;
  const { onToggleComposer } = composer;

  const handleInputChange = (value: string) => {
    const maxLength = getInputMaxLength(selectedTicket?.channelType);
    if (value.length > maxLength) {
      // remove the extra characters
      value = value.slice(0, maxLength);
    }
    setTxt(value);
  };

  const handlePress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      if (txt.length > 0) {
        handleSendMessage(txt);
      }
      e.preventDefault();
    }
  };

  const genMessagePayload = (message: string) => {
    if (checkLink(message)) {
      return {
        eventType: EEventType.MESSAGE,
        messageType: EMessageType.LINK,
        sender: ESenderType.AGENT,
        senderName: member.username,
        url: message,
      };
    }

    return {
      eventType: EEventType.MESSAGE,
      messageType: EMessageType.TEXT,
      sender: ESenderType.AGENT,
      senderName: member.username,
      text: message,
    };
  };

  const handleSendMessage = async (message: string) => {
    setTxt('');
    if (selectedTicket) {
      const timestamp = dayjs().valueOf();
      const msg = genMessagePayload(message);
      const messagePayload = {
        payloads: [msg],
        userId: selectedUser?.id,
        userSocialId: selectedUser?.userSocialId,
        channelId: selectedUser?.channel?.id,
        ticketId: selectedTicket._id,
        channelType: selectedUser?.channel?.type,
        createdAt: timestamp,
      };
      const id = uuid();
      const newMessage = getMessage({
        id,
        message: {
          id,
          ...msg,
          senderImage: '',
          createdAt: messagePayload.createdAt,
          messageStatus: EMessageStatus.SENDING,
        } as INewMessage<ITextMessage | ILinkMessage | IVideoMessage>,
        time: messagePayload.createdAt,
      });

      send('SEND_MESSAGE', { memberId: member._id, messagePayload, newMessage, actionType: 'message' });
    }
  };

  const handleSendTemplate = (template: BOTS.ITemplateApi) => {
    if (selectedTicket && selectedUser) {
      const messagePayload = genTemplatePayload(
        template,
        selectedUser.id,
        selectedUser.referenceId ?? '',
        selectedTicket.channelId,
        selectedTicket._id,
        selectedTicket.channelType,
      );
      const { newMessage, trackId } = getMessageFromTemplate(template, member.username ?? '');
      send('SEND_MESSAGE', {
        newMessage,
        messagePayload,
        actionType: 'template',
        trackId: trackId,
        template,
        previewMessage: template.messages,
      });
      onToggleComposer();
      // close preview
      onSelectTemplate(null);
    }
  };

  return {
    theme,
    textAreaRef,
    txt,
    handleSendTemplate,
    handleInputChange,
    handlePress,
    handleSendMessage,
  };
};

export default useMessageComposer;
