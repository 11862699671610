export enum ECustomFieldType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  ACTION = 'ACTION',
}

export enum ECustomFieldActionType {
  MAP = 'map',
  CALL = 'call',
  CONFERENCE = 'conference',
}
