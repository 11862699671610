import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@components/LinearProgress';
import { Container, Inner } from './style';
import AmsLogo from '@assets/images/ams-logo.png';
import {
  Font12G5W400,
  Font14G1W400,
  Font14G3W400,
  Font20G1W600,
  Font20G3W600,
  Spacing,
} from '@components/UtilsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import useTranslation from '@hooks/useTranslation';
import { getReportStatus } from '@api/livechat/TicketManagement';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { PrimaryButton } from '@components/Button';
import { EReportStatusType } from '@enums/Report';

enum EReportStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
}

type IReportState = {
  status: EReportStatus;
  message: string;
};

const INTERVAL_NUMBER = 5000;
const MAX_INTERVAL_NUMBER = 20000;
export const Report = () => {
  const t = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const [queryCounter, setQueryCounter] = React.useState<number>(0);
  const [intervalState, setIntervalState] = React.useState<number>(INTERVAL_NUMBER);
  const [reportStatus, setReprotStatus] = useState<IReportState>({
    status: EReportStatus.LOADING,
    message: '',
  });

  useEffect(() => {
    window.onbeforeunload = function () {
      if (reportStatus.status === EReportStatus.LOADING) return 'Are you sure to exit?';
    };
  }, [reportStatus]);

  const { status, data } = useQuery({
    queryKey: ['reports', id],
    queryFn: async () => {
      if (id) {
        if (queryCounter === 0) {
          setQueryCounter(queryCounter + 1);
        }
        if (queryCounter === 1) {
          setIntervalState(MAX_INTERVAL_NUMBER);
        }
        const res = await getReportStatus(id);

        return res?.data;
      } else {
        throw new Error('404');
      }
    },
    onSuccess: (data) => {
      if (data.status === EReportStatusType.GENERATED) {
        setReprotStatus({
          status: EReportStatus.IDLE,
          message: '',
        });
      } else {
        setReprotStatus({
          status: EReportStatus.LOADING,
          message: '',
        });
      }
    },
    onError: (err) => {
      setReprotStatus({
        status: EReportStatus.ERROR,
        message: '',
      });
    },
    // Refetch the data if
    // id exist
    // status is pending
    refetchInterval: id && reportStatus.status === EReportStatus.LOADING ? intervalState : false,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  const _renderContent = () => {
    // no id
    if (!id) {
      return (
        <Inner>
          <span>
            <Font20G1W600>{t('report.404.title1')}</Font20G1W600>
            {'   '}
            <Font20G3W600>{t('report.404.title2')}</Font20G3W600>
          </span>
          <Spacing height={13} />
          <Font14G1W400>{t('report.404.description')}</Font14G1W400>
        </Inner>
      );
    }
    if (status === 'error') {
      return (
        <Inner>
          <Font20G3W600>{t('general.error.title')}</Font20G3W600>
          <Spacing height={13} />
          <Font14G1W400>{t('general.error.description')}</Font14G1W400>
        </Inner>
      );
    }

    if (status === 'loading' || data?.status === EReportStatusType.PENDING) {
      return (
        <Inner>
          <Font14G3W400>{t('report.download.title')}</Font14G3W400>
          <Spacing height={30} />
          <FontAwesomeIcon icon={faDownload} />
          <Spacing height={24} />
          <LinearProgress />
          <Spacing height={16} />
          <p>
            <Font12G5W400>{t('report.downloading')}</Font12G5W400>
          </p>
        </Inner>
      );
    }

    return (
      <Inner>
        <Font14G3W400>{t('report.download.title')}</Font14G3W400>
        <Spacing height={30} />
        <FontAwesomeIcon icon={faDownload} />
        <Spacing height={24} />
        <Font12G5W400>{t('report.finish.download.text')}</Font12G5W400>
        <Spacing height={32} />
        <a href={data?.exportedFileUrl} download target="_blank" rel="noreferrer">
          <PrimaryButton size="large">{t('report.download.file.text')}</PrimaryButton>
        </a>
      </Inner>
    );
  };
  return (
    <Container>
      <div>
        <img src={AmsLogo} width={262} height={40} />
        <Spacing height={24} />
        {_renderContent()}
      </div>
    </Container>
  );
};
