import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  column-gap: 8px;
  width: 290px;
  max-width: 290px;
  overflow: auto;
`;

export const TopSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  padding: 14px;
`;

export const ImageWrap = styled.div<{ isSquare: boolean }>`
  height: ${({ isSquare }) => (isSquare ? '236px' : '172px')};
  width: 236px;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
  img {
    width: 100%;
    object-fit: ${({ isSquare }) => (isSquare ? 'contain' : 'cover')};
    height: 100%;
  }
`;

export const Column = styled.div`
  border-radius: 16px;
  width: 236px;
  /* max-height: 172px; */
  max-width: 250px;
  background: white;
  p {
    white-space: pre-wrap;
  }

  button {
    width: 100%;
    border: none;
    background: white;
    font-size: 14px;
    font-weight: 400;
    color: #42659a;
    padding: 12px 16px 0;
  }
`;

export const Action = styled.div``;
