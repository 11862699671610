import { AnyEventObject, assign, createMachine } from 'xstate';
import { IMember, ITeam, ITicket, IUser } from '@types';
import { getRecentTicketByUserId, resolveTicket } from '@api/livechat/EngagementMessage';
import { getUserById } from '@api/livechat/User';
import { followUpState } from './followUpState';
import { assignessState } from './assigneeState';
import { resolveTicketState } from './resolveTicketState';

export type ITicketMachineContext = {
  ticket: ITicket | null;
  user: IUser | null;
  members: IMember[];
  teams: ITeam[];
  selectedItem: ITeam | IMember | null;
  errorMessage: null;
};

const ticketManager = (userId: string) => {
  return createMachine<ITicketMachineContext>(
    {
      id: 'ticket-management',
      initial: 'fetchingTicket',
      context: {
        ticket: null,
        user: null,
        members: [],
        teams: [],
        selectedItem: null,
        errorMessage: null,
      },
      states: {
        fetchingTicket: {
          invoke: {
            id: 'fetching-ticket-and-user-by-userId',
            src: async () => {
              const ticket = await getRecentTicketByUserId(userId);
              const user = await getUserById(userId);
              return { ticket, user };
            },
            onDone: {
              target: 'idle',
              actions: 'updateTicket',
            },
            onError: 'fetchTicketFailed',
          },
        },
        idle: {
          initial: 'standBy', // remove na krub
          states: {
            standBy: {},
            followUp: {
              ...followUpState,
            },
            selectingAssignee: {
              ...assignessState,
            },
            resolveTicket: {
              ...resolveTicketState,
            },
            resolvingTicket: {
              invoke: {
                src: (context: ITicketMachineContext) => {
                  if (context.ticket) {
                    const ticket = context.ticket;
                    return resolveTicket(ticket._id, ticket.nid);
                  }
                  return Promise.reject(`Ticket not found`);
                },
                onDone: {
                  target: 'resolvedTicketSuccess',
                },
                onError: {
                  target: 'resolvedTicketFailed',
                },
              },
            },
            resolvedTicketSuccess: {
              tags: ['isOnResultPageTag', 'resolveSuccess'],
            },
            resolvedTicketFailed: {
              tags: ['isOnResultPageTag', 'resolveFailed'],
            },
          },
          on: {
            STANDBY: '.standBy',
            FOLLOW: '.followUp',
            SELECT_ASSIGNEE: '.selectingAssignee',
            RESOLVE_TICKET: '.resolveTicket',
            INSTANCE_RESOLVE_TICKET: {
              target: '.resolvingTicket',
              cond: (_: ITicketMachineContext, event: AnyEventObject) => {
                return event.canInstanceResolve;
              },
            },
          },
        },
        fetchTicketFailed: {},
      },
    },
    {
      actions: {
        updateTicket: assign({
          ticket: (_context, event) => {
            const { ticket } = event.data;
            return ticket.data;
          },
          user: (_context, event) => {
            const { user } = event.data;
            return {
              ...user.data,
              channelId: user.data.channelId._id,
            };
          },
        }),
        updateTicketAfterFollow: assign({
          ticket: (_context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        updateTicketAfterSaveWrapup: assign({
          ticket: (_context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        updateMemberList: assign({
          members: (_context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        updateTeamList: assign({
          teams: (_context, event) => {
            const { data } = event.data;
            return data.rows;
          },
        }),
        updateSelectedItem: assign({
          selectedItem: (_context, event) => {
            return event.value;
          },
        }),
      },
    },
  );
};

export default ticketManager;
