import styled from 'styled-components/macro';
import { Form as AntdForm } from 'antd';

export const Container = styled.div`
  margin-top: 32px;
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-input-number:focus,
  .ant-input-number-focused {
    border-color: #40a9ff;
    border-right-width: 0px !important;
    outline: 0;
    box-shadow: none;
  }
`;

export const Form = styled(AntdForm)``;

export const Section = styled.section`
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  column-gap: 10px;
  &:not(:first-child) {
    margin-top: 24px;
    padding-bottom: 32px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20px;
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const Description = styled.div`
  margin-top: 8px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.GREY_4};
`;

export const MaximumTicketWrap = styled.div`
  margin-top: 24px;
  margin: 24px auto 0;
  display: flex;
  margin-left: 200px;
  align-items: center;
`;

export const SlaTimeWrap = styled.div`
  margin-top: 24px;
  margin: 24px auto 0;
  display: flex;
  margin-left: 200px;
  align-items: center;
`;

export const InputLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-right: 24px;
`;

export const InputWrap = styled.div`
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export const Unit = styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
`;

export const ActionWrap = styled.div`
  margin-top: 60px;
  display: flex;
  column-gap: 8px;
`;

export const FormItem = styled(Form.Item)``;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.RED};
  position: absolute;
`;

export const MaxTimeLabel = styled.div`
  margin-right: 8px;
  font-size: 16px;
  font-weight: 400;
`;

export const SlaHourWrap = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  input {
    width: 56px;
    height: 52px;
  }
`;

export const HourUnit = styled.span`
  margin: 0 8px;
  font-size: 16px;
  font-weight: 400;
`;

export const SlaActionWrap = styled.div`
  display: flex;
  row-gap: 4px;
  flex-direction: column;
`;

export const SlaActionButtonWrap = styled.button`
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.GREY_9};
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

export const SlaMinuteWrap = styled.div``;
