import _, { isEmpty } from 'lodash';

import { IAllTicketPaneContext } from '../machine';
import { getReport } from '@api/livechat/TicketManagement/report';
import { IReportApiParams } from '@types';
import { ESortBy, ETicketStatus } from '@enums/TicketStatus';
import { EReportType } from '@enums/Report';
import { DateRange } from '@components/DateRangePicker/useDateRangePicker';

const getStatusKey = (status: ETicketStatus): (keyof typeof ETicketStatus)[] | null => {
  switch (status) {
    case ETicketStatus.OPEN:
      return ['OPEN'];
    case ETicketStatus.ASSIGNED:
      return ['ASSIGNED'];
    case ETicketStatus.RESOLVED:
      return ['RESOLVED'];
    default:
      return ['OPEN', 'ASSIGNED', 'RESOLVED'];
  }
};

const getReportType = (reportFilter: IAllTicketPaneContext['report']): EReportType[] => {
  const arr: EReportType[] = [];
  if (reportFilter.messageReport) {
    arr.push(EReportType.MESSAGE);
  }
  if (reportFilter.ticketReport) {
    arr.push(EReportType.TICKET_SUMMARY);
  }
  return arr;
};

const getDateFromTo = (dateRange: DateRange) => {
  if (isEmpty(dateRange)) return undefined;
  if (!dateRange.from || !dateRange.to) return undefined;
  return {
    start: dateRange.from.toISOString(),
    end: dateRange.to.toISOString(),
  };
};

const getSortBy = (sortBy: ESortBy): 'latestMessageAt' | 'createdAt' => {
  if (sortBy === ESortBy.LATEST_MESSAGE) return 'latestMessageAt';
  return 'createdAt';
};

export const exportReport = async (context: IAllTicketPaneContext) => {
  const body: IReportApiParams = {
    type: getReportType(context.report),
    filters: {
      channelId: context.filter.selectedChannels.length > 0 ? context.filter.selectedChannels : undefined,
      agentId: context.filter.selectedAssignees.length > 0 ? context.filter.selectedAssignees : undefined,
      status: getStatusKey(context.filter.selectedStatus),
      dateRange: getDateFromTo(context.filter.selectedDateRange),
      tags: context.filter.selectedTags.map((tag) => tag),
      onlyFollowUp: context.filter.onlyFollowUp,
      onlyTeamFollowUp: context.filter.onlyTeamFollowUp,
    },
    sortedBy: getSortBy(context.filter.selectedSortBy),
  };
  const result = await getReport(body);
  return result;
};
