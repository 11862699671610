import styled from 'styled-components/macro';
import { Form } from 'antd';

export const Container = styled.div``;
export const AntdFormItem = styled(Form.Item)`
  flex-wrap: nowrap !important;
  .ant-form-item {
    margin-bottom: 24px !important;
  }
  label {
    width: 100%;
    flex: auto !important;
  }

  &.ant-form-item-has-error .ant-input-affix-wrapper {
    border: 1px solid #ff4d4f !important;
  }
`;

export const LabelTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  flex: auto;
`;

export const LabelDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.GREY_5};
`;
