import styled from 'styled-components/macro';
import { Menu as AntdMenu, Dropdown as AntdDropdown, Button as AntdButton, Typography, Col } from 'antd';
import SearchInput from '@components/SearchInput';

export const Container = styled.div`
  width: 100%;
  button {
    width: 100%;
    display: flex;
    padding: 5px 12px;
    border: 1px solid ${({ theme }) => theme.GREY_7};
    border-radius: 8px;
    height: 36px;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.GREY_1} !important;
    border-color: ${({ theme }) => theme.GREY_7} !important;
    & > div:nth-child(1) {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }
`;
export const Dropdown = styled(AntdDropdown)``;
export const Menu = styled(AntdMenu)`
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  max-height: 500px;
  & .ant-menu-item-group-list .ant-menu-item {
    padding: 0 16px 0 16px;
  }
`;
export const Item = styled(AntdMenu.Item)`
  :hover {
    background: ${({ theme }) => theme.GREY_9};
    color: initial;
  }
`;
export const ItemGroup = styled(AntdMenu.ItemGroup)``;

export const Divider = styled(AntdMenu.Divider)``;

export const ItemGroupTitle = styled(Typography.Text)`
  font-size: 10px;
  font-weight: bold;
`;

export const SelectedItem = styled.div`
  display: flex;
  img {
    margin-right: 8px;
    height: 22px;
    width: 22px;
  }
`;

export const Button = styled(AntdButton)``;

export const DropdownSearchContainer = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 16px;
`;

export const DropDownSearch = styled(SearchInput)`
  background: ${({ theme }) => theme.GREY_9};
  &.ant-input-affix-wrapper > input.ant-input {
    background-color: ${({ theme }) => theme.GREY_9};
  }
`;

export const IconContainer = styled.div`
  img {
    vertical-align: middle;
    width: 24px;
    height: auto;
  }
`;

export const PlaceHolder = styled.div`
  color: ${({ theme }) => theme.GREY_5};
  &[contentEditable='true']:empty:not(:focus):after {
    content: attr(title);
  }
`;

export const ImgCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const DropdownOptionLabel = styled(Typography.Text)`
  max-width: 200px;
`;
