import React, { useCallback } from 'react';
import { EmptyContainer, IconWrap, TagContainer } from './style';
import useTranslation from '@hooks/useTranslation';
import { useEngagementUserProfile } from '../../EngagementUserProfileProvider';
import TicketHistoryItem, { SummaryItem } from '@components/TicketHistoryItem';
import {
  Font12G3W400,
  Font14G1W500,
  Font14G3W400,
  Font14G5W400,
  Font16G1W400,
  Font16G1W500,
  Spacing,
} from '@components/UtilsComponent';
import { isEmpty } from 'lodash';
import { EmptyState } from '@components/EmptyState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from '@components/Avatar';
import { Col, Row } from 'antd';
import { TagWithRemove } from '@components/Tag';
import { formatDate } from '@utils/day';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltTimes } from '@fortawesome/pro-regular-svg-icons';
import { useMember } from '@providers/MemberProvider';

export const HistoryTab = () => {
  const t = useTranslation();
  const { isAgent } = useMember();
  const { ticketHistory } = useEngagementUserProfile();
  const _renderIcon = useCallback(() => {
    return (
      <IconWrap>
        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 18 }} color="#29CB72" />
      </IconWrap>
    );
  }, []);

  if (ticketHistory.isLoading) return null;
  return (
    <>
      {
        // loaded and empty ticketHistory.data
        !ticketHistory.isLoading && isEmpty(ticketHistory.data?.tickets) ? (
          <EmptyContainer>
            <EmptyState
              icon={
                <FontAwesomeIcon
                  icon={faCommentAltTimes}
                  color={'rgba(0, 0, 0, 0.2)'}
                  style={{
                    fontSize: 36,
                  }}
                />
              }
              title={t('livechat.user.profile.ticket.history.tab.empty.title')}
              description={t('livechat.user.profile.ticket.history.tab.empty.description')}
            />
          </EmptyContainer>
        ) : (
          <>
            <Spacing height={16} />
            <div>
              {ticketHistory.data?.tickets.map((ticket) => {
                return (
                  <React.Fragment key={ticket._id}>
                    <TicketHistoryItem
                      key={ticket._id}
                      header={
                        <Row>
                          <Col>{_renderIcon()}</Col>
                          <Col>
                            <Font14G1W500>{formatDate(ticket.resolvedAt, 'DD MMM YYYY, HH:mm')}</Font14G1W500>
                          </Col>
                        </Row>
                      }
                    >
                      <div className="break-words">
                        <Font14G3W400 data-testid="ticket-number-label">
                          {t('livechat.user.profile.ticket.history.ticket.no.label')}
                        </Font14G3W400>
                        <Font14G1W500 role="ticket-number">{ticket.ticketNumber ?? '-'}</Font14G1W500>
                      </div>
                      <Spacing height={16} />
                      <div className="break-words">
                        <Font14G3W400 data-testid="ticket-time-label">
                          {t('livechat.user.profile.ticket.open.ticket.time.label')}
                        </Font14G3W400>
                        <Font14G1W500 role="ticket-time">
                          {formatDate(ticket.createdAt, 'DD MMM YYYY, HH:mm') ?? '-'}
                        </Font14G1W500>
                      </div>
                      <Spacing height={16} />
                      <div className="break-words">
                        <Font14G3W400 data-testid="first-response-label">
                          {t('livechat.user.profile.ticket.first.response.time.label')}
                        </Font14G3W400>
                        <Font14G1W500 data-testid="first-response-value">
                          {ticket.firstResponder?.timestamp
                            ? ` ${formatDate(ticket.firstResponder.timestamp, 'DD MMM YYYY, HH:mm')}`
                            : '-'}
                        </Font14G1W500>
                      </div>
                      <Spacing height={16} />
                      <div className="break-words">
                        <Font14G3W400 data-testid="response-by-label">
                          {t('livechat.user.profile.ticket.first.response.label')}
                        </Font14G3W400>
                        <Font14G1W500 data-testid="response-by-value">
                          {ticket.firstResponder?.displayName ? ` ${ticket.firstResponder.displayName}` : '-'}
                        </Font14G1W500>
                      </div>
                      {/* Summart Wrap Item */}
                      <Spacing height={16} />
                      <SummaryItem header={t('livechat.user.profile.ticket.history.wrapup.title')}>
                        <div className="break-words">
                          <Font14G3W400 data-testid="categories-label">
                            {t('livechat.user.profile.ticket.categories.label')}
                          </Font14G3W400>
                          <br />
                          <Spacing height={8} />
                          <TagContainer data-testid="categories-value">
                            {!isEmpty(ticket.summary?.categories) ? (
                              ticket.summary?.categories?.map((cat) => {
                                return (
                                  <div key={cat}>
                                    <TagWithRemove label={cat} />
                                  </div>
                                );
                              })
                            ) : (
                              <Font14G1W500 data-testid="categories-value-empty">-</Font14G1W500>
                            )}
                          </TagContainer>
                        </div>

                        <Spacing height={16} />
                        <div className="break-words">
                          <Font14G3W400 data-testid="reason-label">
                            {t('livechat.user.profile.ticket.history.reasons.label')}
                          </Font14G3W400>
                          <br />
                          <Spacing height={8} />
                          {!isEmpty(ticket.summary?.reasons) ? (
                            <ul className="list-disc pl-[24px]" data-testid="reason-value">
                              {ticket.summary?.reasons?.map((reason) => {
                                return <li key={reason}>{reason}</li>;
                              })}
                            </ul>
                          ) : (
                            <Font14G1W500 data-testid="reason-value-empty">-</Font14G1W500>
                          )}
                        </div>
                        <Spacing height={16} />
                        <div className="break-words">
                          <Font14G3W400 data-testid="outcome-label">
                            {t('livechat.user.profile.ticket.history.outcomes.label')}
                          </Font14G3W400>
                          <br />
                          <Spacing height={8} />
                          <TagContainer>
                            {!isEmpty(ticket.summary?.result) ? (
                              <ul className="list-disc pl-[24px]" data-testid="outcome-value">
                                {ticket.summary?.result?.map((result) => {
                                  return <li key={result}>{result}</li>;
                                })}
                              </ul>
                            ) : (
                              <Font14G1W500 data-testid="outcome-value-empty">-</Font14G1W500>
                            )}
                          </TagContainer>
                        </div>
                        <Spacing height={16} />
                        <div className="break-words">
                          <Font12G3W400 data-testid="ticket-note-label">
                            {t('livechat.user.profile.ticket.history.ticket.note.label')}
                          </Font12G3W400>
                          <br />
                          <Font16G1W400 data-testid="ticket-note-value">{ticket.summary?.note || '-'}</Font16G1W400>
                        </div>
                      </SummaryItem>

                      <Spacing height={16} />

                      {/* Satisfaction Wrap */}
                      {!isAgent() ? (
                        <SummaryItem header={t('livechat.user.profile.ticket.history.satisfaction.title')}>
                          <div className="break-words">
                            <div>
                              <Font14G5W400 data-testid="ticket-survey-point-label">
                                {t('livechat.user.profile.ticket.satisfaction.point.label')}
                              </Font14G5W400>
                              <Font14G1W500 data-testid="ticket-survey-point-value">
                                {ticket.survey?.point || '-'}
                              </Font14G1W500>
                            </div>

                            <div>
                              <Font14G5W400 data-testid="ticket-survey-feedback-label">
                                {t('livechat.user.profile.ticket.satisfaction.additional.label')}
                              </Font14G5W400>
                              <Font14G1W500 data-testid="ticket-survey-feedback-value">
                                {ticket.survey?.feedback || '-'}
                              </Font14G1W500>
                            </div>
                          </div>
                        </SummaryItem>
                      ) : null}

                      <Spacing height={16} />
                      <div className="break-words">
                        <Font14G3W400 data-testid="assignee-label">
                          {t('livechat.user.profile.ticket.history.assignee.label')}
                        </Font14G3W400>
                        <br />
                        <Spacing height={8} />

                        <Row align="middle" gutter={[8, 0]}>
                          <Col>
                            <Avatar fontSize={10} width={24} height={24} name={ticket.agentDisplayName} />
                          </Col>
                          <Col>
                            <Font16G1W500 data-testid="agent-display-name-value">
                              {ticket.agentDisplayName}
                            </Font16G1W500>
                          </Col>
                        </Row>
                      </div>
                    </TicketHistoryItem>
                    <Spacing height={12} />
                  </React.Fragment>
                );
              })}
            </div>
          </>
        )
      }
    </>
  );
};
