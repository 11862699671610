import { createContext, useContext } from 'react';
export type ISideBarBehavior = 'over' | 'push';
export type SideBarContext = {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  selectedMenu: string;
  setSelectedMenu: (value: string) => void;
};

export const SideBarContext = createContext({ collapsed: false } as SideBarContext);
export const useSidebar = () => useContext(SideBarContext);

SideBarContext.displayName = 'SideBarContext';
