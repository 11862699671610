import { useRecoilValue } from 'recoil';
import { selectedTicketState } from '@store/EngagementUserListAtom';
import { useChatPermission } from '@hooks/useChatPermission';

export const useComposer = () => {
  const selectedTicket = useRecoilValue(selectedTicketState);
  const { canChat } = useChatPermission(selectedTicket!);

  return {
    selectedTicket,
    canChat,
  };
};

export default useComposer;
