import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ButtonPreview,
  CarouselItem,
  ImageContainer,
  ContentContainer,
  ContentHeader,
  ContentBody,
  Container,
  ArrowRight,
  ArrowLeft,
} from './style';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export type ICarouselMessage = {
  items: {
    id: string;
    image: string;
    title: string;
    description: string;
    buttons: string[];
  }[];
};

const { Text, Paragraph } = Typography;

export const CarouselMessage: React.FC<ICarouselMessage> = ({ items }: ICarouselMessage) => {
  const [selected, setSelected] = useState(0);

  const settings = {
    slidesToShow: 1,
    infinite: false,
    nextArrow: (
      <ArrowRight show={selected !== items.length - 1 ? true : false}>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </ArrowRight>
    ),
    prevArrow: (
      <ArrowLeft show={selected !== 0 ? true : false}>
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
      </ArrowLeft>
    ),
    beforeChange: (current: number, next: number) => {
      setSelected(next);
    },
  };

  return (
    <Container>
      <Slider {...settings}>
        {items.map((item) => {
          return (
            <CarouselItem key={item.id}>
              <ImageContainer>
                <img src={item.image} />
              </ImageContainer>
              <ContentContainer>
                <ContentHeader>
                  {item.title && <Text>{item.title}</Text>}
                  {item.description && <Paragraph>{item.description}</Paragraph>}
                </ContentHeader>
                <ContentBody>
                  {item.buttons.map((button) => {
                    return <ButtonPreview key={button}>{button}</ButtonPreview>;
                  })}
                </ContentBody>
              </ContentContainer>
            </CarouselItem>
          );
        })}
      </Slider>
    </Container>
  );
};
