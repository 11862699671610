import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import en from './en.json';

type LocalType = 'en';
const DEFAULT_LOCALE: LocalType = 'en';
const LOCALES = { en };

const I18nProvider = ({ locale, ...props }: { locale: LocalType; children: ReactNode }) => {
  const messages = locale in LOCALES ? LOCALES[locale] : LOCALES[DEFAULT_LOCALE];

  return <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={messages} {...props} />;
};

export default I18nProvider;
