import styled from 'styled-components/macro';

export const Container = styled.div``;

export const MessageListContainer = styled.div`
  width: 100%;
  flex: auto;
`;
export const UserListContainer = styled.div`
  width: 100%;
  flex: auto;
`;

export const ListHeader = styled.div`
  background: #f5f5f5;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  a {
    font-size: 12px;
  }
`;

export const Content = styled.ul`
  a {
    margin: 16px;
    display: block;
    font-weight: 400;
  }
`;

export const ListFooter = styled.div`
  background: #f5f5f5;
  padding: 8px 16px;
  text-align: center;
`;

export const LoadMoreBtn = styled.a`
  margin-bottom: 16px;
`;

export const StateMessageWrap = styled.div`
  height: 100%;
  .style__Inner-sc-1sgzlox-0 {
    margin-top: -70px;
  }
`;
