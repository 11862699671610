import React from 'react';
import { PasswordProps } from 'antd/lib/input';
import { AntdPasswordInput, Container } from './style';

export type IPasswordInput = PasswordProps;

const PasswordInput = React.forwardRef(({ ...rest }: IPasswordInput, ref) => {
  return (
    <Container>
      <AntdPasswordInput {...rest} ref={ref} />
    </Container>
  );
});

export default PasswordInput;
