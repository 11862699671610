import { atom } from 'recoil';
import { ITicket, ITicketFilter, IUser, UserData } from '@types';
import { ALL, FILTER_ITEM_DATA } from '@configs/constants';
import { DropDownData } from '@components/Dropdown';
import { IUserAvatar } from '@pages/components/UserAvatar';
import { ETicketFilterStatus } from '@enums/TicketFilter';

export const userListState = atom<UserData>({
  key: 'userListState',
  default: {
    items: [],
  },
});

export const searchUserListState = atom({
  key: 'searchUserListState',
  default: {
    total: '0',
    items: [] as IUserAvatar[],
    isFetchError: false,
    isFetching: false,
    isNoMore: false,
  },
});

export const searchUserMessagesState = atom({
  key: 'searchMessageListState',
  default: {
    total: '0',
    items: [] as IUserAvatar[],
    isFetchError: false,
    isFetching: false,
    isNoMore: false,
  },
});

export const filterItemState = atom({
  key: 'filterItemDataState',
  default: FILTER_ITEM_DATA,
});

export const currentFilterState = atom<ITicketFilter>({
  key: 'currentFilterState',
  default: {
    channels: [] as string[],
    ticketStatus: ETicketFilterStatus.ASSIGNED_TO_ME,
    hasNextPage: false,
    isNextPageLoading: false,
    currentPage: 0,
    selectedTeam: [],
    filterByUnreplied: false,
    filterNoTeam: true,
  },
});

export const excludeTeamIdsState = atom<string[]>({
  key: 'excludeTeamIdsState',
  default: [],
});

export const selectedUserState = atom<null | IUser>({
  key: 'selectedUserState',
  default: null,
});

export const selectedTicketState = atom<null | ITicket>({
  key: 'selectedTicketState',
  default: null,
});
