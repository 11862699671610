import React, { createContext, useContext } from 'react';

import { useDashboardFilter } from './useDashboardFilter';

const OperationalDashboardContext = createContext({} as ReturnType<typeof useDashboardFilter>);

export const useOperationalDashboard = () => useContext(OperationalDashboardContext);

export const OperationalDashboardProvider: React.FC = ({ children }) => {
  const handler = useDashboardFilter();

  return <OperationalDashboardContext.Provider value={handler}>{children}</OperationalDashboardContext.Provider>;
};
