import { useState } from 'react';
import { useMutation } from 'react-query';

export const useCreateModal = <TPayload, TResponse = any>(
  muteFn: (payload: TPayload) => Promise<TResponse>,
  isDefaultOpen?: boolean | null,
) => {
  const [showConfirmModal, setShowModalConfirmModal] = useState(isDefaultOpen ?? false);
  const [txt, setTxt] = useState('');
  const createMute = useMutation({
    mutationKey: 'createMute',
    mutationFn: async (payload: TPayload) => {
      return muteFn(payload);
    },
  });
  return {
    txt,
    showConfirmModal,
    isSaving: createMute.isLoading,
    onCancel: () => {
      setShowModalConfirmModal(false);
      setTxt('');
    },
    onConfirm: async (payload: TPayload) => {
      try {
        await createMute.mutateAsync(payload);
        setShowModalConfirmModal(false);
      } catch (error) {
        console.error(error);
        setShowModalConfirmModal(false);
        throw error;
      }
    },
    onUpdateText: (value: string) => {
      setTxt(value);
    },
    onToggle: () => {
      setShowModalConfirmModal(!showConfirmModal);
    },
  };
};
