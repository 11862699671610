import { EMessageType } from '@enums/MessageType';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { IMessageLevel } from '@types';

type ImagemapMessageJson = {
  url: string;
} & BaseMessageJson;

export class ImagemapMessage extends BaseMessage {
  private url: string;
  private level: IMessageLevel;

  constructor(data: ImagemapMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.url = data.url;
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      url: this.url,
      retry: this.retry,
      level: this.level,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  get getMessageType() {
    return EMessageType.IMAGEMAP;
  }

  set setUrl(url: string) {
    this.url = url;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
