import React from 'react';
import smile_chat from '@assets/images/smile-chat.svg';
import StateMessage from '@components/StateMessage';
import useTranslation from '@hooks/useTranslation';
import { Container, ButtonWrap } from './style';
import { PrimaryButton } from '@components/Button';
import { useHistory } from 'react-router';

export type IMobileLiveChatGetStart = { [key: string]: any };

const MobileLiveChatGetStart: React.FC<IMobileLiveChatGetStart> = ({}: IMobileLiveChatGetStart) => {
  const t = useTranslation();
  const history = useHistory();
  return (
    <Container>
      <StateMessage
        size="large"
        type="success"
        img={smile_chat}
        label={t('livechat.mobile.get.start.title')}
        description={t('livechat.mobile.get.start.description')}
      >
        <ButtonWrap>
          <PrimaryButton onClick={() => history.push('/m/recents')} size="large">
            {t('livechat.mobile.get.start.button.label')}
          </PrimaryButton>
        </ButtonWrap>
      </StateMessage>
    </Container>
  );
};

export default MobileLiveChatGetStart;
