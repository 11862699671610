import React from 'react';
import { Container } from './style';
import Tab from '@components/Tab';
import { HistoryTab } from './HistoryTab';
import { AudienceInfoTab } from './AudienceInfoTab';
import useTranslation from '@hooks/useTranslation';
import { useEngagementUserProfile } from '../EngagementUserProfileProvider';
import { isEmpty } from 'lodash';

const UserDetailTab = React.memo(() => {
  const t = useTranslation();
  const { ticketHistory } = useEngagementUserProfile();
  return (
    <Container>
      <Tab
        tabindex={-1}
        tabs={[
          {
            tab: (
              <span data-testid="test-user-profile-ticket-history-tab-label">
                {t('livechat.user.profile.ticket.history.tab.title')}
                {!isEmpty(ticketHistory.data?.tickets) ? ` (${ticketHistory.data?.tickets.length})` : null}
              </span>
            ),

            key: 'ticket_history',
            children: <HistoryTab />,
          },
          {
            tab: t('livechat.user.profile.audience.info.tab.title'),
            key: 'audience_info',
            children: <AudienceInfoTab />,
          },
        ]}
        defaultActiveKey={''}
        onTabClick={() => false}
      />
    </Container>
  );
});

UserDetailTab.displayName = 'UserDetailTab';

export default UserDetailTab;
