import React, { useMemo } from 'react';
import { PrimaryButton, Button } from '@components/Button';
import useTranslation from '@hooks/useTranslation';
import { Typography, Spin } from 'antd';
import TextArea from '@components/TextArea';
import { Section, Container, Label, Form, ActionContainer, ErrorContainer, ActionInner, AutoSaveTime } from './style';
import useResolveModal, { IResolveForm } from './useResolveModal';
import error_message from '@assets/images/error_message.svg';
import { DangerToast } from '@components/Toast';
import LoadingCircle from '@components/Loading';
import LoadingIcon from '@components/LoadingIcon';
import { AutoSaveText } from '@components/AutoSaveText';
import { ITicket } from '@types';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import { cloneDeep } from 'lodash';
import { OutcomeSelect, ReasonCategorySelect, ReasonSelect } from '@components/TicketWrapup';
import { useSetting } from '@providers/SettingProvider';
import { Font14G1W400, Font14G3W400, HyperLink } from '@components/UtilsComponent';
import { ROUTE } from '@configs/route';
import { useMember } from '@providers/MemberProvider';

export type IResolveModal = {
  ticket: ITicket;
  show: boolean;
  isResolving: boolean;
  onUpdateTicketField: (payload: IResolveForm) => void;
  handleShowModal: (value: boolean) => void;
  handleSubmit: () => void;
};

const ResolveModal: React.FC<IResolveModal> = React.memo(
  ({ ticket, show, isResolving, handleShowModal, handleSubmit, onUpdateTicketField }: IResolveModal) => {
    const t = useTranslation();
    const { isAdmin: isAdminFn } = useMember();
    const isAdmin = useMemo(() => isAdminFn(), [isAdminFn]);
    const {
      validateFields,
      fieldsValue,
      status,
      ticketData,
      errorMessage,
      form,
      isAutoSave,
      onUpdateFields,
      isValidatePass,
    } = useResolveModal(ticket, onUpdateTicketField);
    const { setting } = useSetting();
    if (status === 'error') {
      return (
        <ErrorContainer title="" visible={show} footer={false} onCancel={() => handleShowModal(false)}>
          <div>
            <div>
              <img src={error_message} />
            </div>
            <div>
              <Typography.Text>{t('livechat.ticket.console.resolve.error.title')}</Typography.Text>
            </div>
            <div>
              <Typography.Text>{t('livechat.ticket.console.resolve.error.description')}</Typography.Text>
            </div>
          </div>
        </ErrorContainer>
      );
    }
    return (
      <Container
        title={t('livechat.ticket.console.resolve.title')}
        visible={show}
        onCancel={() => handleShowModal(false)}
        maskClosable={false}
        footer={[
          <ActionContainer key="1">
            <AutoSaveTime>
              <span>{isAutoSave && <AutoSaveText />}</span>
            </AutoSaveTime>
            <ActionInner>
              <Button size="large" onClick={() => handleShowModal(false)} disabled={isResolving}>
                {t('livechat.ticket.console.resolve.cancel.button')}
              </Button>
              <PrimaryButton
                form="resolve-ticket-form"
                size="large"
                onClick={() => {
                  // if config set required of reason and outcome, we need to validate form
                  if (setting.isWrapUpTicketRequired) {
                    const result = isValidatePass();
                    if (!result) {
                      return;
                    }
                  }
                  handleSubmit();
                }}
                disabled={isResolving}
              >
                {isResolving && <LoadingIcon icon={faSyncAlt} />}
                {t('livechat.ticket.console.resolve.ok.button')}
              </PrimaryButton>
            </ActionInner>
          </ActionContainer>,
        ]}
      >
        <Spin spinning={ticketData.isFetching} indicator={<LoadingCircle />}>
          <Form id="resolve-ticket-form" form={form}>
            {errorMessage && <DangerToast align="flex-start">{errorMessage}</DangerToast>}

            <Section>
              <Label>{t('livechat.ticket.console.resolve.reason.category.label')}</Label>
              <Form.Item>
                <div className="w-[552px]">
                  <ReasonCategorySelect
                    validateText={
                      validateFields.categories ? t('livechat.ticket.console.resolve.reason.category.invalidate') : ''
                    }
                    value={fieldsValue.data.categories?.[0] ?? ''}
                    onChange={(value) => {
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          categories: [value.name],
                          reasons: [],
                        },
                      });
                    }}
                    onClearSelection={() => {
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          categories: [],
                          reasons: [],
                        },
                      });
                    }}
                  />
                </div>
              </Form.Item>
              {isAdmin ? (
                <div className="mt-[8px] flex gap-x-[8px]">
                  <Font14G3W400>
                    {t('livechat.ticket.console.resolve.reason.category.add.new.description')}
                  </Font14G3W400>
                  <HyperLink to={ROUTE.TICKET_WRAP_UP}>
                    {t('livechat.ticket.console.resolve.add.new.category.hyperlink')}
                  </HyperLink>
                </div>
              ) : null}
            </Section>
            <Section>
              <Label>{t('livechat.ticket.console.resolve.reason.label')}</Label>
              <Form.Item>
                <div className="w-[552px]">
                  <ReasonSelect
                    categoryName={fieldsValue.data?.categories?.[0] ?? ''}
                    selectedValues={fieldsValue.data.reasons ?? []}
                    isDisabled={!fieldsValue.data.categories?.length}
                    validateText={validateFields.reasons ? t('livechat.ticket.console.resolve.reason.invalidate') : ''}
                    max={20}
                    onClearSelect={() => {
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          reasons: [],
                        },
                      });
                    }}
                    onSelect={(payload) => {
                      const reasonIndex = fieldsValue.data.reasons.indexOf(payload);
                      const reasonName = payload;
                      let reasonResult: string[] = [];

                      if (reasonIndex === -1) {
                        // add new reason
                        reasonResult = [...fieldsValue.data.reasons, reasonName];
                      } else {
                        // splice reason
                        const clone = cloneDeep(fieldsValue.data.reasons);
                        clone.splice(reasonIndex, 1);
                        reasonResult = clone;
                      }
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          reasons: reasonResult,
                        },
                      });
                    }}
                    onDelete={function (item: string): void {
                      // remove item from reason
                      const reasonIndex = fieldsValue.data.reasons.indexOf(item);
                      const clone = cloneDeep(fieldsValue.data.reasons);
                      clone.splice(reasonIndex, 1);
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          reasons: clone,
                        },
                      });
                    }}
                  />
                </div>
              </Form.Item>
              {isAdmin ? (
                <div className="flex gap-x-[8px]">
                  <Font14G3W400>{t('livechat.ticket.console.resolve.reason.add.new.description')}</Font14G3W400>
                  <HyperLink to={`${ROUTE.TICKET_WRAP_UP}?tab=reason`}>
                    {t('livechat.ticket.console.resolve.add.new.reason.hyperlink')}
                  </HyperLink>
                </div>
              ) : null}
            </Section>
            <div className="h-[1px] bg-[#E5E5E5] w-full mt-[16px] mb-[16px]" />
            <Section>
              <Label>{t('livechat.ticket.console.resolve.result.label')}</Label>
              <Form.Item>
                <div className="w-[552px]">
                  <OutcomeSelect
                    max={20}
                    validateText={validateFields.result ? t('livechat.ticket.console.resolve.outcome.invalidate') : ''}
                    selectedValues={fieldsValue.data.result ?? []}
                    onSelect={(payload) => {
                      const index = fieldsValue.data.result?.indexOf(payload);
                      let outcome: string[] = [];

                      if (index === -1) {
                        // add new reason
                        outcome = [...fieldsValue.data.result, payload];
                      } else {
                        // splice reason
                        const clone = cloneDeep(fieldsValue.data.reasons);
                        clone.splice(index, 1);
                        outcome = clone;
                      }
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          result: outcome,
                        },
                      });
                    }}
                    onDelete={function (item: string): void {
                      // remove item from reason
                      const index = fieldsValue.data.result.indexOf(item);
                      const clone = cloneDeep(fieldsValue.data.result);
                      clone.splice(index, 1);
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          result: clone,
                        },
                      });
                    }}
                    onClearSelect={function (): void {
                      onUpdateFields({
                        ticketId: fieldsValue.ticketId,
                        data: {
                          ...fieldsValue.data,
                          result: [],
                        },
                      });
                    }}
                  />
                </div>
              </Form.Item>
              {isAdmin ? (
                <div className="flex gap-x-[8px]">
                  <Font14G3W400>{t('livechat.ticket.console.resolve.outcome.add.new.description')}</Font14G3W400>
                  <HyperLink to={`${ROUTE.TICKET_WRAP_UP}?tab=outcome`}>
                    {t('livechat.ticket.console.resolve.add.new.outcome.hyperlink')}
                  </HyperLink>
                </div>
              ) : (
                <div className="flex gap-x-[8px]">
                  <Font14G3W400>{t('livechat.ticket.console.resolve.contact.admin.label')}</Font14G3W400>
                  <Font14G1W400>{t('livechat.ticket.console.resolve.contact.admin.description')}</Font14G1W400>
                </div>
              )}
            </Section>
            <div className="h-[1px] bg-[#E5E5E5] w-full mt-[16px] mb-[16px]" />
            <Section>
              <Label>{t('livechat.ticket.console.resolve.note.label')}</Label>
              <Form.Item>
                <TextArea
                  showCount
                  maxLength={200}
                  value={fieldsValue.data.note}
                  onChange={(e) => {
                    onUpdateFields({
                      ticketId: fieldsValue.ticketId,
                      data: {
                        ...fieldsValue.data,
                        note: e.target.value,
                      },
                    });
                  }}
                />
              </Form.Item>
            </Section>
          </Form>
        </Spin>
      </Container>
    );
  },
);

ResolveModal.displayName = 'ResolveModal';

export default ResolveModal;
