import { STICKER_PREFIX } from '@configs/constants';
import { EMessageType } from '@enums/MessageType';
import { ESenderType } from '@enums/SenderType';
import SocketIO from '@socket';
import { ILastMessageSocket, UserDataItem } from '@types';
import dayjs from 'dayjs';
import { cloneDeep, isEmpty } from 'lodash';

export type IUserListSocketOption = {
  nid: string;
  agentName: string;
  agentId: string;
  channels: string[];
  teams: string[];
  task: string;
};

export class UserListClient {
  public socket: SocketIO | null = null;

  public handleNewSocketMessage = (
    oldUsers: UserDataItem[],
    newMessage: ILastMessageSocket,
  ): UserDataItem[] | undefined => {
    const index = oldUsers.findIndex(({ user }) => user.id === newMessage.userId);

    // not found user
    if (index === -1) {
      return;
    }

    const result: UserDataItem[] = cloneDeep(oldUsers);
    // display unread badge ignore system
    if (newMessage.payload.sender === ESenderType.AGENT) {
      result[index].isRead = true;
      result[index].user.isRead = true;
    }
    if (newMessage.payload.sender === ESenderType.USER) {
      result[index].isRead = false;
      result[index].user.isRead = false;
    }
    result[index].user.time = dayjs(newMessage.createdAt).valueOf();
    result[index].user.message = UserList.getDescriptionMessage(
      newMessage.payload.messageType,
      newMessage.payload.text,
    );

    if (newMessage.payload.sender === ESenderType.USER) {
      result[index].user.unreadMessageCount = UserList.getUnreadMessageCount(1, result[index].user.unreadMessageCount);
    } else {
      result[index].user.unreadMessageCount = 0;
    }

    // detect change displayName event
    if (!isEmpty(newMessage.userInfo)) {
      result[index].user.displayName = newMessage.userInfo.displayName;
    }

    // swap user to top
    // get user from list
    const foundUser = result[index];
    // remove user from list
    result.splice(index, 1);
    // add user to top
    result.unshift(foundUser);
    return result;
  };

  public getDescriptionMessage = (messageType: string, text: string): string => {
    // sticker message
    if (messageType === EMessageType.TEXT && text?.includes(STICKER_PREFIX)) {
      return 'sent a sticker';
    }
    // not text message
    if (messageType !== EMessageType.TEXT) {
      return messageType;
    }
    // text message
    return text;
  };

  public getUnreadMessageCount = (increaseSize: number, oldCounter?: number): number => {
    if (oldCounter) {
      return oldCounter + increaseSize;
    }

    return 1;
  };
}

const UserList = new UserListClient();
export default UserList;
