import styled from 'styled-components/macro';

export const EmptyContainer = styled.div`
  height: calc(100vh - 518px);
`;

export const IconWrap = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
`;
