import { TertiaryButton } from '@components/Button';
import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const IconWrap = styled.div`
  margin-right: 16px;
`;

export const ExportButton = styled(TertiaryButton)`
  width: 100px;
`;
