import { MutableRefObject, useEffect, useState } from 'react';

/**
 * hook use to trigger when element is visible or invisible on screen
 *
 * @param ref ref of div element to detect
 *
 * @returns {Boolean} is current div visible on screen
 */
export default function useOnScreen(ref: MutableRefObject<any>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
