import React, { useCallback } from 'react';
import { Container, CircleStatus, AvatarItem } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { matchFirstLetterToColor } from '@utils/color';
import getLogoByChannelType from '@utils/getLogoByChannelType';
import EChannelType from '@enums/ChannelType';

export type IAvatar = {
  img?: string;
  name?: string;
  channelType?: EChannelType;
  memberStatus?: string;
  width?: number;
  height?: number;
  isDefaultAvatar?: boolean;
  fontSize?: number;
  customName?: (str: string) => string;
};

const Avatar: React.FC<IAvatar> = ({
  img,
  name,
  channelType,
  memberStatus,
  width = 50,
  height = 50,
  fontSize,
  customName,
}: IAvatar) => {
  const getSplitName = useCallback((name: string) => {
    const splitName = name.split(' ');
    if (splitName.length > 1) {
      return `${splitName[0].charAt(0)}${splitName[splitName.length - 1].charAt(0)}`;
    }

    return `${splitName[0].charAt(0)}`;
  }, []);

  const _renderChannel = (channelType: EChannelType) => {
    return <img alt={channelType} width={24} height={24} src={getLogoByChannelType(channelType)} />;
  };

  const _rendermemberStatus = (memberStatus: string) => {
    return <CircleStatus width={width} height={height} data-testid="member-status" memberStatus={memberStatus} />;
  };

  const _renderAvatar = () => {
    if (img) {
      return <AvatarItem src={img}></AvatarItem>;
    }
    if (name) {
      return (
        <AvatarItem fontSize={fontSize} style={{ background: matchFirstLetterToColor(name) }}>
          {customName ? customName(name) : getSplitName(name)}
        </AvatarItem>
      );
    }
    return (
      <AvatarItem icon={<FontAwesomeIcon style={{ width: width / 2, height: height / 2 }} icon={['fal', 'user']} />} />
    );
  };

  return (
    <Container width={width} height={height}>
      {_renderAvatar()}
      {channelType && <div>{_renderChannel(channelType)}</div>}
      {memberStatus && <div>{_rendermemberStatus(memberStatus)}</div>}
    </Container>
  );
};

export default Avatar;
