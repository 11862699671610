import React, { useCallback } from 'react';
import TextArea from '@components/TextArea';
import { faStickyNote } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Font16G3W600 } from '@components/UtilsComponent';
import { Container } from './style';
import { AutoSaveText } from '@components/AutoSaveText';

export enum ETicketNoteState {
  IDLE = 'IDLE',
  SAVING = 'SAVING',
}

type ITicketNoteProps = {
  state: ETicketNoteState;
  value: string;
  isDisabled: boolean;
  onChange: (value: string) => void;
};

export const TicketNote = ({ state, value, isDisabled, onChange }: ITicketNoteProps) => {
  const _renderLoading = useCallback(() => {
    if (state === ETicketNoteState.SAVING) {
      return <AutoSaveText />;
    }
    return null;
  }, [state]);

  return (
    <Container className="flex flex-col">
      {/* header */}
      <div className="flex h-[24px] justify-between mb-2">
        <div className="flex gap-x-2 items-center">
          <FontAwesomeIcon icon={faStickyNote} className="text-[18px] text-[#616161]" />
          <Font16G3W600>Ticket Note</Font16G3W600>
        </div>

        <div className="flex gap-x-1 items-center">{_renderLoading()}</div>
      </div>
      {/* content */}
      <TextArea
        maxLength={200}
        showCount
        disabled={isDisabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Enter note"
      />
    </Container>
  );
};
