import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

export const Bookmark = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.GREY_5};
`;

export const CopyIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.GREY_5};
`;

export const TickerNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  .ant-row {
    height: 16px;
  }
`;

export const TicketDetail = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BottomSection = styled.div`
  margin-top: 16px;
  display: flex;
  column-gap: 16px;
`;

export const Container = styled.div`
  padding: 18px 24px 21px;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
`;

export const SkelInner = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const ErrorWrap = styled.div`
  img {
    margin: 0 auto;
  }
  display: grid;
  gap: 8px;
  text-align: center;
  div:nth-child(2) span {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${({ theme }) => theme.GREY_5};
  }
  div:nth-child(3) span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.GREY_5};
  }
`;

export const SlaContainer = styled.div<{ isOverdue: boolean }>`
  display: flex;
  color: ${({ theme, isOverdue }) => (isOverdue ? theme.RED : theme.BLUE)};
  span {
    color: ${({ theme, isOverdue }) => (isOverdue ? theme.RED : theme.BLUE)} !important;
    margin-left: 4px;
  }
`;
