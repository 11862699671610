import { SyntheticEvent } from 'react';
import { useInfinityLoadByPage } from '@hooks/useInfinityLoadByPage';
import { IVaData } from '../TeamManagementPane/teamManagementMachine';
import { getVirtualAgentsApi, getVirtualAgentsBySearchApi } from '@api/livechat/TeamManagement';
import { IDropdownAutoSearchFn } from '@components/DropdownAutoSearch';
import { scrollHandler } from '@utils/scroll';

export const useVa = () => {
  const coPilotInfinit = useInfinityLoadByPage<IVaData>({
    fetchFn: async (filter) => {
      let result;
      if (filter.keyword) {
        result = await getVirtualAgentsBySearchApi(filter.keyword, filter.page, 'va');
      } else {
        result = await getVirtualAgentsApi(filter.page, 'va');
      }

      return {
        keyword: result.data.keyword,
        page: result.data.page,
        rows: result.data.rows,
        total: result.data.total,
        totalPages: result.data.totalPages,
        pageSize: result.data.pageSize,
      };
    },
    initialFilter: {
      take: 30,
    },
  });
  const newOptions = coPilotInfinit.data.map((item) => {
    return {
      id: item._id,
      name: item.displayName,
      source: item.virtualAgentConfig?.virtualAgentSource || '-',
    };
  });

  const copilotInfinitFn: IDropdownAutoSearchFn = {
    onScroll: (e: SyntheticEvent<HTMLDivElement>) => {
      scrollHandler.onScrollToBottom(e, () => {
        coPilotInfinit.onLoadmore();
      });
    },
    onSelect: () => {
      return false;
    },
    onClearSearchKeyword: () => {
      coPilotInfinit.onUpdateSearchKeyword('');
    },
    onInputChange: (value: string) => {
      coPilotInfinit.onUpdateSearchKeyword(value);
    },
    onClearSelected: (handler: () => void) => {
      handler();
    },
  };

  return {
    coPilotHandler: {
      coPilotInfinit,
      copilotInfinitFn,
      newOptions: newOptions,
    },
  };
};
