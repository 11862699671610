import styled from 'styled-components/macro';
import { InputNumber } from 'antd';

export const AntdInputNumber = styled(InputNumber)`
  border: none;
  width: initial;
`;

export const Container = styled.div`
  input {
    border: 1px solid ${({ theme }) => theme.GREY_7} !important;
    box-shadow: none !important;
    box-sizing: border-box;
    border-radius: 8px !important;
    /* height: 48px; */
  }
`;
