import mime from 'mime';
import React, { useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Container } from './style';

export type IVideoPlayerMessage = {
  url: string;
  controls?: boolean;
  width?: string | number;
  height?: string | number;
};

export const VideoPlayerMessage: React.FC<IVideoPlayerMessage> = ({ url, ...rest }: IVideoPlayerMessage) => {
  const renderVideo = useMemo(() => {
    return (
      <Container>
        <ReactPlayer {...rest} url={url} height={175} />
      </Container>
    );
  }, [url]);
  return renderVideo;
};
