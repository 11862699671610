import React from 'react';
import useTranslation from '@hooks/useTranslation';
import UserAvatar, { IUserAvatar } from '../UserAvatar';
import {
  Container,
  ListHeader,
  MessageListContainer,
  UserListContainer,
  Content,
  LoadMoreBtn,
  StateMessageWrap,
} from './style';
import { useHistory, useRouteMatch } from 'react-router-dom';
import StateMessage from '@components/StateMessage';
import chat_empty from '@assets/images/chat_empty.svg';
import error_message from '@assets/images/error_message.svg';
import LoadingCircle from '@components/Loading';
import { getQueryString } from '@utils/getQueryString';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { AutoSizer } from 'react-virtualized';

export type IListState = {
  items: IUserAvatar[];
  total: string;
  isFetchError: boolean;
  isFetching: boolean;
  isNoMore: boolean;
};

export type IUserWithSearch = {
  userList: IListState;
  userMessageList: IListState;
  searchTxt: string;
  onLoadMore: (loadType: 'user' | 'message') => void;
};

const UserWithSearch: React.FC<IUserWithSearch> = React.memo(
  ({ userList, userMessageList, searchTxt, onLoadMore }: IUserWithSearch) => {
    const history = useHistory();
    const t = useTranslation();
    const match: { [key: string]: any } | null = useRouteMatch('/recents/:id');
    const messageTime = getQueryString(history.location.search, 'messageTime');

    const onSelectUser = (user: IUserAvatar) => {
      if (user.time) {
        history.push(
          isMobile
            ? `/m/recents/${user.id}?messageTime=${dayjs(user.time).valueOf()}`
            : `/recents/${user.id}?messageTime=${dayjs(user.time).valueOf()}`,
        );
        return;
      }
      history.push(isMobile ? `/m/recents/${user.id}` : `/recents/${user.id}`);
    };

    const isSelectedUserWithTime = (userMessageTime?: number) => {
      if (messageTime && userMessageTime) {
        const messageTimeNumber = parseInt(messageTime);
        return userMessageTime === messageTimeNumber;
      }
      return false;
    };

    if (userList.isFetchError || userMessageList.isFetchError) {
      return (
        <StateMessageWrap>
          <StateMessage
            size="medium"
            type="error"
            img={error_message}
            label={t('livechat.error.userlist.label')}
            description={t('livechat.error.userlist.description')}
          />
        </StateMessageWrap>
      );
    }

    if (userList.isFetching && userMessageList.isFetching) {
      return <LoadingCircle />;
    }

    if (userList.items.length === 0 && userMessageList.items.length === 0) {
      return (
        <StateMessageWrap>
          <StateMessage
            size="medium"
            type="general"
            img={chat_empty}
            label={t('livechat.user.with.search.empty.both.title')}
            description={t('livechat.user.with.search.empty.both.description')}
          />
        </StateMessageWrap>
      );
    }

    return (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <div
              style={{
                width,
              }}
            >
              <div style={{ width: '100%', overflowY: 'auto', height: height }}>
                <MessageListContainer>
                  <ListHeader>
                    <div>
                      {t(`livechat.user.with.search.users.label`)} ({userList.total})
                    </div>
                  </ListHeader>

                  <Content>
                    {userList.items?.map((user) => {
                      return (
                        <div
                          key={user.time}
                          onClick={() => history.push(isMobile ? `/m/recents/${user.id}` : `/recents/${user.id}`)}
                        >
                          <UserAvatar
                            {...user}
                            message=""
                            searchTxt={searchTxt}
                            isSelected={match?.params?.id === user.id && !messageTime}
                          />
                        </div>
                      );
                    })}
                    {userList.isFetching && <LoadingCircle />}
                    {!userList.isNoMore && !userList.isFetching && (
                      <>
                        <LoadMoreBtn role="button" onClick={() => onLoadMore('user')}>
                          {t(`livechat.user.with.search.show.more.label`)}
                        </LoadMoreBtn>
                        <br />
                      </>
                    )}
                  </Content>
                </MessageListContainer>
                <UserListContainer>
                  <ListHeader>
                    <div>
                      {t(`livechat.user.with.search.messages.label`)} ({userMessageList.total})
                    </div>
                  </ListHeader>

                  <Content>
                    {userMessageList?.items.map((user) => {
                      return (
                        <div key={user.time} onClick={() => onSelectUser(user)}>
                          <UserAvatar {...user} searchTxt={searchTxt} isSelected={isSelectedUserWithTime(user.time)} />
                        </div>
                      );
                    })}
                    {userMessageList.isFetching && <LoadingCircle />}
                    {!userMessageList.isNoMore && !userMessageList.isFetching && (
                      <>
                        <LoadMoreBtn role="button" onClick={() => onLoadMore('message')}>
                          {t(`livechat.user.with.search.show.more.label`)}
                        </LoadMoreBtn>
                        <br />
                      </>
                    )}
                  </Content>
                </UserListContainer>
              </div>
            </div>
          );
        }}
      </AutoSizer>
    );
  },
);

export default UserWithSearch;
