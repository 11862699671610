import { assign, createMachine } from 'xstate';
import { getAgentInMyTeam } from '@api/livechat/TicketManagement';
import { assignTicketToAdmin, assignTicketToAgent, assignTicketToTeam } from '@api/livechat/Ticket';
import { ITicket, ITeam, IMember } from '@types';
import { getTeamsApi, getTeamsBySearchApi } from '@api/livechat/TeamManagement';
import { isEmpty } from 'lodash';
import { ERole } from '@enums/Role';
import _ from 'lodash';
import { MAX_TEAM_FETCH } from '@configs/constants';

export type IGroupPeople = {
  teamName: string;
  members: IMember[];
};

export type ITeamData = {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  rows: ITeam[];
};

export type IAssigneeMachineContext = {
  searchTxt: string;
  currentTab: string;
  currentTicket: ITicket;
  currentAssignee: IMember;
  peopleData: IGroupPeople[];
  peopleDataV2: Array<{
    teamId: string;
    teamName: string;
    agents: _.Omit<
      IMember & {
        teamName: string;
        teamId: string;
      },
      'teamId' | 'teamName' | 'teams'
    >[];
  }> | null;
  teamData: ITeamData;
  selectedAgent: IMember;
  selectedTeam: ITeam;
  errorName: string;
  errorMessage: string;
};

export enum TAB {
  PEOPLE = 'People',
  TEAMS = 'Teams',
}

const assigneeMachine = (currentTicket: ITicket) => {
  return createMachine<IAssigneeMachineContext>(
    {
      id: 'assigneeMachine',
      initial: 'firstFetching',
      context: {
        searchTxt: '',
        currentAssignee: {} as IMember,
        selectedTeam: {} as ITeam,
        currentTicket,
        currentTab: TAB.PEOPLE,
        peopleData: [],
        peopleDataV2: null,
        teamData: {
          page: 0,
          pageSize: 0,
          total: 0,
          totalPages: 0,
          rows: [],
        } as ITeamData,
        selectedAgent: {} as IMember,
        errorName: '',
        errorMessage: '',
      },
      states: {
        idle: {
          on: {
            LOAD_MORE_TEAM: {
              target: 'fetchingMoreTeam',
              cond: (context) => context.teamData.page < context.teamData.totalPages,
            },
            SELECT_AGENT: {
              target: 'selectAgent',
              actions: 'updateSelectedAgent',
            },
            SELECT_TEAM: {
              target: 'selectedTeam',
              actions: 'updateSelectedTeam',
            },
            ASK_CONFIRM: {
              target: 'askToConfirm',
            },
          },
        },
        firstFetching: {
          invoke: {
            id: 'first-fetch-my-team-agent',
            src: getAgentInMyTeam,
            onDone: {
              target: 'idle',
              actions: ['updateCurrentAssignee', 'updatePeopleList'],
            },
            onError: {
              target: 'fetchAgentsInMyTeamFailed',
            },
          },
        },
        fetchingAgentsInMyTeams: {
          invoke: {
            id: 'fetch-my-team-agent',
            src: getAgentInMyTeam,
            onDone: {
              target: 'idle',
              actions: ['updateCurrentAssignee', 'updatePeopleList'],
            },
            onError: {
              target: 'fetchAgentsInMyTeamFailed',
            },
          },
        },
        fetchAgentsInMyTeamFailed: {},
        fetchingTeam: {
          invoke: {
            id: 'fetch-all-team',
            src: async (context: IAssigneeMachineContext) => {
              const { searchTxt } = context;
              if (searchTxt) return getTeamsBySearchApi(searchTxt, 1, MAX_TEAM_FETCH);
              return await getTeamsApi(1, MAX_TEAM_FETCH);
            },
            onDone: {
              target: 'idle',
              actions: 'updateTeamList',
            },
            onError: {
              target: 'fetchTeamFailed',
              actions: 'clearTeamList',
            },
          },
        },
        fetchTeamFailed: {},
        debounceFetchTeam: {
          on: {
            SEARCH_TEXT_CHANGE: [
              {
                target: 'debounceFetchTeam',
                actions: 'updateSearchTxt',
              },
            ],
          },
          after: {
            450: {
              target: 'fetchingTeam',
            },
          },
        },
        fetchingMoreTeam: {
          invoke: {
            id: 'fetch-more-team',
            src: async (context: IAssigneeMachineContext) => {
              const { teamData, searchTxt } = context;
              if (teamData.page === teamData.totalPages) return;
              if (searchTxt) return getTeamsBySearchApi(searchTxt, teamData.page + 1, MAX_TEAM_FETCH);
              return await getTeamsApi(teamData.page + 1, MAX_TEAM_FETCH);
            },
            onDone: {
              target: 'idle',
              actions: 'updateTeamListFromLoadMore',
            },
            onError: {
              target: 'fetchTeamFailed',
              actions: 'clearTeamList',
            },
          },
        },
        filterMemberData: {
          on: {
            SELECT_AGENT: {
              target: 'selectAgent',
              actions: 'updateSelectedAgent',
            },
          },
        },
        selectAgent: {
          on: {
            CONFIRM_ASSIGN_AGENT: {
              target: 'confirmAssignAgent',
            },
            CANCEL_ASSIGN: {
              target: 'idle',
            },
            ASK_CONFIRM: {
              target: 'askToConfirm',
            },
            SELECT_AGENT: {
              target: 'selectAgent',
              actions: 'updateSelectedAgent',
            },
          },
        },
        selectedTeam: {
          on: {
            CONFIRM_ASSIGN_TEAM: {
              target: 'confirmAssignTeam',
            },
            CANCEL_ASSIGN: {
              target: 'idle',
            },
            ASK_CONFIRM: {
              target: 'askToConfirm',
            },
            SELECT_TEAM: {
              target: 'selectedTeam',
              actions: 'updateSelectedTeam',
            },
          },
        },
        askToConfirm: {
          on: {
            IDLE: 'idle',
            CONFIRM_ASSIGN_TEAM: {
              target: 'confirmAssignTeam',
            },
            CANCEL_ASSIGN: {
              target: 'idle',
            },
            CONFIRM_ASSIGN_AGENT: {
              target: 'confirmAssignAgent',
            },
          },
        },
        confirmAssignAgent: {
          invoke: {
            id: 'confirm-assign',
            src: async (context) => {
              try {
                const { selectedAgent } = context;
                //current agent is admin
                if (selectedAgent.roles[0] === ERole.ADMIN) {
                  return await assignTicketToAdmin(currentTicket._id, {
                    agentId: selectedAgent._id,
                  });
                }
                return await assignTicketToAgent(currentTicket._id, {
                  agentId: selectedAgent._id,
                  team: selectedAgent.teams[0]._id,
                });
              } catch (error) {
                console.error('error', error);
                throw error;
              }
            },
            onDone: 'assignTicketToAgentSuccess',
            onError: {
              target: 'assignTicketFailed',
              actions: ['updateErrorMessage', 'updateErrorName'],
            },
          },
        },
        confirmAssignTeam: {
          invoke: {
            id: 'confirm-assign-team',
            src: async (context) => {
              try {
                const { selectedTeam } = context;
                return await assignTicketToTeam(currentTicket._id, {
                  team: selectedTeam._id,
                });
              } catch (error) {
                throw error;
              }
            },
            onDone: 'assignTicketToTeamSuccess',
            onError: {
              target: 'assignTicketFailed',
              actions: ['updateErrorMessage', 'updateErrorName'],
            },
          },
        },
        assignTicketToAgentSuccess: {},
        assignTicketToTeamSuccess: {},
        assignTicketFailed: {
          on: {
            IDLE: 'idle',
          },
        },
      },
      on: {
        UPDATE_TICKET_DATA: {
          actions: assign({
            currentTicket: (_context, event) => {
              return event.data;
            },
          }),
        },
        TAB_CHANGE: [
          {
            target: 'fetchingTeam',
            cond: 'isTeamTab',
            actions: 'updateCurrentTab',
          },
          {
            target: 'fetchingAgentsInMyTeams',
            actions: 'updateCurrentTab',
          },
        ],
        SEARCH_TEXT_CHANGE: [
          {
            target: 'debounceFetchTeam',
            cond: (context) => context.currentTab === TAB.TEAMS,
            actions: 'updateSearchTxt',
          },
          {
            target: 'filterMemberData',
            actions: 'updateSearchTxt',
          },
        ],
      },
    },
    {
      actions: {
        updateCurrentAssignee: assign({
          currentAssignee: (context, event) => {
            const { data: members } = event.data;
            const { currentTicket } = context;
            const assignedMember = members.find((member: IMember) => member._id === currentTicket.agentId);
            return assignedMember;
          },
        }),
        updatePeopleList: assign({
          peopleData: (_context, event) => {
            const { data }: { data: IMember[] } = event.data;
            if (isEmpty(data)) {
              return [];
            }
            const teamKey: {
              [key: string]: IMember[];
            } = {};

            for (const item of data) {
              if (item.roles[0] === ERole.ADMIN) {
                teamKey[ERole.ADMIN] ? teamKey[ERole.ADMIN].push(item) : (teamKey[ERole.ADMIN] = [item]);
                continue;
              }
              if (!isEmpty(item.teams)) {
                const teamName = item.teams[0].name;
                teamKey[teamName] ? teamKey[teamName].push(item) : (teamKey[teamName] = [item]);
              }
            }
            const teamList = Object.keys(teamKey).map((key) => {
              const result = {
                teamName: key,
                members: teamKey[key],
              };
              return result;
            });

            return teamList;
          },
          peopleDataV2: (_context, event) => {
            // Step 0: remove admin
            const data = event.data.data;
            // Step 1: Flatten the data
            const flattenedData: (IMember & {
              teamName: string;
              teamId: string;
            })[] = data.flatMap((agent: IMember) => {
              if (agent.roles[0] === ERole.ADMIN) {
                return {
                  ...agent,
                  teamId: ERole.ADMIN,
                  teamName: ERole.ADMIN,
                };
              }
              return agent.teams.map((team: ITeam) => ({
                ...agent,
                teamId: team._id,
                teamName: team.name,
              }));
            });
            // Step 2: Group the data by teamId
            const groupedData = _.groupBy(flattenedData, 'teamId');

            // Step 3: Transform the grouped data to include team information
            const result = _.mapValues(groupedData, (agents, teamId) => ({
              teamId,
              teamName: agents[0].teamName,
              agents: agents.map((agent) => _.omit(agent, ['teamId', 'teamName', 'teams'])),
            }));
            const arrResult = Object.keys(result).map((key) => result[key]);
            return arrResult;
          },
        }),
        updateTeamList: assign({
          teamData: (_context, event) => {
            const { data } = event.data;
            return data;
          },
        }),
        updateTeamListFromLoadMore: assign({
          teamData: (context, event) => {
            const { teamData } = context;
            const { data } = event.data;
            const newTeams = {
              ...data,
              rows: teamData.rows.concat(data.rows),
            };
            return newTeams;
          },
        }),
        clearTeamList: assign({
          teamData: (_context, _event) => {
            return {
              page: 0,
              pageSize: 0,
              total: 0,
              totalPages: 0,
              rows: [],
            } as ITeamData;
          },
        }),
        updateCurrentTab: assign({
          currentTab: (_context, event) => {
            return event.currentTab;
          },
        }),
        updateSearchTxt: assign({
          searchTxt: (_context, event) => {
            return event.value;
          },
        }),
        updateSelectedAgent: assign({
          selectedAgent: (_context, event) => {
            return event.value;
          },
        }),
        updateSelectedTeam: assign({
          selectedTeam: (_context, event) => {
            return event.value;
          },
        }),
        updateErrorName: assign({
          errorName: (_context, event) => {
            return event.data?.response?.data?.name ?? '';
          },
        }),
        updateErrorMessage: assign({
          errorMessage: (_context, event) => {
            return event.data?.response?.data?.message ?? 'Something went wrong';
          },
        }),
      },
      guards: {
        isTeamTab: (_context, event) => {
          return event.currentTab === TAB.TEAMS;
        },
      },
    },
  );
};

export default assigneeMachine;
