import { slaCalculator } from '@utils/ticketSla';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState, useEffect } from 'react';

export type IUseSlaCounterParams = {
  slaHour?: number;
  slaMinute?: number;
  startTicketTime?: string | number | Date | dayjs.Dayjs;
};

export type IUseSlaState =
  | string
  | {
      slaHours: number;
      slaMinutes: string | number;
      slaSeconds: string | number;
      isOverdue: boolean;
    };

export const useSlaCounter = ({ slaHour, slaMinute, startTicketTime }: IUseSlaCounterParams) => {
  const [slaCounter, setSlaCounter] = useState<IUseSlaState>('');

  useEffect(() => {
    const interval = setInterval(() => {
      const result = slaCalculator({
        sla: {
          hr: !isNil(slaHour) ? slaHour : -1,
          min: !isNil(slaMinute) ? slaMinute : -1,
        },
        startTicketTime: startTicketTime,
      });
      setSlaCounter(result);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return { slaCounter };
};
