import { useActor } from '@xstate/react';
import { useTicketHistoryContext } from '..';

const useTicketHistoryUserProfile = () => {
  const { ticketHistoryService } = useTicketHistoryContext();
  const [current] = useActor(ticketHistoryService);
  const { currentTicket } = current.context;

  return {
    currentTicket,
  };
};

export default useTicketHistoryUserProfile;
