import React from 'react';
import dayjs from 'dayjs';
import { useSlaCounter } from './useSlaCounter';
import { SlaContainer } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { Font12G3W500 } from '@components/UtilsComponent';

type ISlaTextProps = {
  slaHour?: number;
  slaMinute?: number;
  startTicketTime?: string | number | Date | dayjs.Dayjs;
};

export const SlaText = ({ slaHour, slaMinute, startTicketTime }: ISlaTextProps) => {
  const { slaCounter } = useSlaCounter({
    slaHour,
    slaMinute,
    startTicketTime,
  });
  if (!startTicketTime) {
    return (
      <>
        <FontAwesomeIcon icon={faStopwatch} /> <span>&nbsp; 0:00</span>
      </>
    );
  }
  if (typeof slaCounter === 'object') {
    return (
      <SlaContainer isOverdue={slaCounter.isOverdue}>
        <>
          <FontAwesomeIcon icon={faStopwatch} />
          <Font12G3W500>
            {slaCounter.slaHours}:{slaCounter.slaMinutes}:{slaCounter.slaSeconds}
          </Font12G3W500>
        </>
      </SlaContainer>
    );
  }
  return null;
};
