import React, { useState, useEffect, useRef, useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useRecoilValue } from 'recoil';
import { selectedTicketState } from '@store/atom';
import { updateTicketTags } from '@api/livechat/EngagementMessage';
import { ITicket, ITicketTag, ITicketTagConsole } from '@types';
import { useDropdownTag } from '@hooks/useDropdownTag';
import { generateSelectedTag } from '@utils/generateTicketConsole';
import { MAX_TAG, TAG_ALERT_MESSAGE } from '@configs/constants';
import { useAlert } from 'react-alert';
import { useMember } from '@providers/MemberProvider';
import { useSetting } from '@providers/SettingProvider';
import { isNotMyTicket } from '@utils/ticketStatus';

const useTagSelector = (ticket: ITicket) => {
  const { member } = useMember();
  const selectedTicket = useRecoilValue(selectedTicketState);
  const [globalTagLoading, setGlobalTagLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<ITicketTagConsole[]>(
    selectedTicket?.tags ? generateSelectedTag(selectedTicket.tags) : [],
  );
  const { dropdownTagsInfo, searchKeyword, onUpdateSearchKeyword, onGetAvailableTags, onLoadMore, updateSelectedTags } =
    useDropdownTag();
  const alert = useAlert();
  const selectedTagsTemp = useRef<ITicketTagConsole[]>(
    selectedTicket?.tags ? generateSelectedTag(selectedTicket.tags) : [],
  );
  const { permission } = useSetting();
  useEffect(() => {
    updateSelectedTags(selectedTags);
  }, [selectedTags, updateSelectedTags]);

  const onRemoveTag = useCallback(async (tag: ITicketTag) => {
    // find tag in selected tag
    const cloneSelectedTag = cloneDeep(selectedTagsTemp.current);
    const tagIndex = cloneSelectedTag.findIndex((tagItem) => tagItem.id === tag.id);

    try {
      setGlobalTagLoading(true);
      // found tag
      if (tagIndex > -1) {
        // set loading true
        setSelectedTags((prev) => {
          const clone = cloneDeep(prev);
          clone[tagIndex].loading = true;
          return clone;
        });
        // remove tag from selectedTagClone
        cloneSelectedTag.splice(tagIndex, 1);
        // create payload for api
        const tagsPayload = {
          tags: cloneSelectedTag.map((item) => {
            return item.value;
          }),
        };
        if (selectedTicket) {
          await updateTicketTags(selectedTicket._id, tagsPayload);
          setGlobalTagLoading(false);
          setSelectedTags((prev) => {
            const filterLoadingout = prev.filter((tag) => !tag.loading);
            selectedTagsTemp.current = filterLoadingout;
            return filterLoadingout;
          });
        }
      }
    } catch (error) {
      alert.error(TAG_ALERT_MESSAGE.ERROR_REMOVE_TAG);
      setGlobalTagLoading(false);
      // set loading false
      setSelectedTags((prev) => {
        const clone = cloneDeep(prev);
        clone[tagIndex].loading = false;
        return clone;
      });
    }
  }, []);

  const onSelectItem = useCallback(async (item: ITicketTagConsole) => {
    try {
      // set global loading
      setGlobalTagLoading(true);
      const payload = {
        ...item,
        id: item.id,
        value: item.label,
        loading: true,
      };
      // set for render
      selectedTagsTemp.current.push(payload);
      // update selected tag
      setSelectedTags(() => [...selectedTagsTemp.current]);
      const tagsPayload = {
        tags: selectedTagsTemp.current.map((item) => {
          return item.value;
        }),
      };
      if (selectedTicket) {
        await updateTicketTags(selectedTicket._id, tagsPayload);
        setGlobalTagLoading(false);
        setSelectedTags((prev) => {
          const newSelectedTag = prev.map((tag) => {
            return {
              ...tag,
              loading: false,
            };
          });
          selectedTagsTemp.current = cloneDeep(newSelectedTag);
          return newSelectedTag;
        });
      }
    } catch (error) {
      alert.error(TAG_ALERT_MESSAGE.ERROR_ADD_TAG);
      setGlobalTagLoading(false);
      setSelectedTags((prev) => {
        // filter out failed tag
        const newSelectedTag = prev.filter((tag) => {
          return tag.id !== item.id;
        });
        selectedTagsTemp.current = cloneDeep(newSelectedTag);
        return newSelectedTag;
      });
    }
  }, []);

  return {
    canManageTag: (): boolean => {
      if (permission.canManageTicketTag) {
        return true;
      }
      if (ticket.status === 'open') {
        return false;
      }
      // agent or supervisor
      return !isNotMyTicket(ticket, member);
    },
    canAddTag: selectedTags.length < MAX_TAG,
    searchKeyword,
    globalTagLoading,
    dropdownTagsInfo,
    selectedTags,
    onUpdateSearchKeyword,
    onRemoveTag,
    onGetAvailableTags,
    onLoadMore,
    onSelectItem,
  };
};

export default useTagSelector;
