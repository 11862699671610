import { Button } from '@components/Button';
import SearchInput from '@components/SearchInput';
import { BaseTag } from '@components/Tag/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'antd';
import styled from 'styled-components/macro';

export const IconButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 100% !important;
  min-width: 100% !important;
  background: transparent;
  svg {
    font-size: 24px;
  }
`;

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  .ant-tabs-content-holder {
    flex: 1;
  }
`;

export const TopbarBox = styled.div`
  padding: 12px 16px;
`;

export const TopbarInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  -webkit-column-gap: 24px;
`;

export const StateMessageContainer = styled.div`
  height: calc(100vh - 108px);
`;

export const SearchActionContainer = styled.div`
  display: grid;
  align-items: center;
  column-gap: 16px;
  grid-template-columns: auto 24px;
  margin-left: 8px;
  width: 100%;
`;

export const TabBox = styled.div`
  height: 100%;
  .ant-tabs-nav {
    padding: 0 0 0 16px;
  }
`;

export const FullWidthSearchInput = styled(SearchInput)`
  height: 38px;
  background: ${({ theme }) => theme.GREY_9};
  input {
    padding-left: 4px;
    background: ${({ theme }) => theme.GREY_9};
  }
`;

export const SmallFontAwesome = styled(FontAwesomeIcon)`
  font-size: 18px;
`;

export const PaneContainer = styled.div`
  height: 100%;
  margin-top: 16px;
`;

export const SearchInputBox = styled.div`
  width: 100%;
`;

export const NotiBadge = styled(Badge)``;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
`;
