import React from 'react';
import { DownButtonComp } from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/pro-solid-svg-icons';
export type IDownButtonProps = {
  onClick: () => void;
};

export const DownButton = ({ onClick }: React.PropsWithChildren<IDownButtonProps>) => {
  return <DownButtonComp onClick={onClick} icon={<FontAwesomeIcon icon={faArrowDown} />} />;
};
