import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { client } from '@api';
import * as Sentry from '@sentry/react';
import useTranslation from '@hooks/useTranslation';
import { AlertModal } from '@components/Modal';
import StateMessage from '@components/StateMessage';
import Exclaimation from '@assets/images/exclaimation.svg';
import { TertiaryButton } from '@components/Button';

const ButtonContainer = styled.div`
  margin-top: 36px;
  button {
    padding: 0 24px;
  }
`;

const InjectAxiosInterceptors: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const t = useTranslation();
  const setupInterceptors = () => {
    client.interceptors.response.use(
      (res) => {
        // success
        return res;
      },
      (err) => {
        Sentry.captureException(err);
        if (err.response) {
          const { status } = err.response;
          console.error('err', status);
          if (status === 401) {
            setShowModal(true);
          }

          return Promise.reject(err);
        }
        return Promise.reject(err);
      },
    );
  };

  useEffect(setupInterceptors, []);

  const redirectToLogin = () => {
    setShowModal(false);
    window.location.href = window.origin;
  };
  // not rendering anything
  return (
    <AlertModal visible={showModal} closable={false} footer={null}>
      <StateMessage
        img={Exclaimation}
        label={t('livechat.401.label')}
        description={t('livechat.401.description')}
        size="large"
        type="success"
      >
        <ButtonContainer>
          <TertiaryButton onClick={redirectToLogin} size="large">
            {t('livechat.401.btn')}
          </TertiaryButton>
        </ButtonContainer>
      </StateMessage>
    </AlertModal>
  );
};

export default InjectAxiosInterceptors;
