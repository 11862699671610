import React from 'react';
import { DangerToastContainer, DangerIconWrap } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type IDangerToast = {
  align: string;
  [key: string]: any;
};

const DangerToast: React.FC<IDangerToast> = ({ children, align = 'center' }: IDangerToast) => {
  return (
    <DangerToastContainer align={align}>
      <DangerIconWrap>
        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
      </DangerIconWrap>
      {children}
    </DangerToastContainer>
  );
};

export default DangerToast;
