import React from 'react';
import { Space } from 'antd';
import { NotiBadge } from '../style';
import { useTheme } from 'styled-components';

export type ITaskCountProps = {
  tabName: string;
  taskCount?: number;
};

export const TaskCount = ({ tabName, taskCount }: React.PropsWithChildren<ITaskCountProps>) => {
  const theme: any = useTheme();
  const _renderTabContent = () => {
    if (taskCount && taskCount > 0) {
      return (
        <Space size={6}>
          {tabName}
          {taskCount && taskCount > 0 && <NotiBadge style={{ backgroundColor: theme.PRIMARY }} count={taskCount} />}
        </Space>
      );
    } else {
      return <>{tabName}</>;
    }
  };
  return <div style={{ padding: '0 8px' }}>{_renderTabContent()}</div>;
};
