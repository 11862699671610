import styled from 'styled-components/macro';

export const Container = styled.div`
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    width: 100%;
  }
`;

export const SheetHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 48px;
  span {
    font-size: 18px;
  }
  color: ${({ theme }) => theme.GREY_2};
`;

export const BackBtn = styled.span`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const CloseBtn = styled.span`
  display: flex;
  position: absolute;
  align-items: center;
  left: 12px;
  width: 24px;
  height: 24px;
`;

export const SearchBtn = styled.span`
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const TabContainer = styled.div<{ height?: number }>`
  height: ${({ height }) => (height ? height - 115 : 0)}px;
  overflow: auto;
`;

export const SearchInputContainer = styled.div`
  .ant-input-affix-wrapper,
  input {
    border: none;
    box-shadow: none !important;
    background: ${({ theme }) => theme.GREY_9} !important;
  }

  svg {
    color: ${({ theme }) => theme.GREY_5} !important;
  }
  width: 100%;
`;
