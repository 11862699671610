import { useState, useEffect, useCallback } from 'react';
import { Form } from 'antd';
import { useMachine } from '@xstate/react';
import generalSettingMachine, { IAccountConfig } from './generalSettingMachine';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { IGeneralConfig } from '@types';
import { removeSpecialWhenPasted } from '@utils/letters';
import { NUMBER_ONLY_REGEX } from '@configs/regex';
import { MAX_HOUR, MAX_MIN } from '@configs/settings';
import { useSetting } from '@providers/SettingProvider';

const useGeneral = () => {
  const [form] = Form.useForm();
  const { query, setting, onUpdateConfig } = useSetting();

  const generalSettingMachineCb = useCallback(() => {
    return generalSettingMachine(setting as IAccountConfig);
  }, [setting]);
  const [current, send] = useMachine(generalSettingMachineCb, {
    devTools: import.meta.env.DEV,
  });

  const t = useTranslation();
  const alert = useAlert();
  const [errorMessage, setErrorMessage] = useState({
    maximumTicket: '',
    slaHour: '',
    slaMin: '',
  });

  const getAutoAssignMethod = (config: IGeneralConfig) => {
    if (config.accountConfig?.autoAssignRandom) {
      return 'RANDOM';
    }
    if (config.accountConfig?.autoAssignRoundRobin) {
      return 'ROUND_ROBIN';
    }
    return 'LOAD_BALANCED' as const;
  };

  useEffect(() => {
    if (current.matches('saveSuccess')) {
      alert.success(t('livechat.settings.general.fetch.success.label'), {
        timeout: 2000,
      });
      const payload: IAccountConfig = {
        ...current.context.accountConfig,
        SLATime: {
          hour: current.context.accountConfig?.SLATime?.hour || '0',
          minute: current.context.accountConfig?.SLATime?.minute || '0',
        },
        autoAssignMethod: getAutoAssignMethod(current.context as IGeneralConfig),
      };

      onUpdateConfig(payload as IAccountConfig);
      send('IDLE');
      return;
    }
    if (current.matches('fetchFail')) {
      alert.error(t('livechat.settings.general.fetch.fail.label'), {
        timeout: 2000,
      });
      send('IDLE');
      return;
    }
    if (current.matches('saveFail')) {
      alert.error(t('livechat.settings.general.save.fail.label'), {
        timeout: 2000,
      });
      send('IDLE');
    }
  }, [current]);

  useEffect(() => form.resetFields(), [current.context.accountConfig]);

  const onFieldsChange = (changedFields: any) => {
    if (changedFields[0].name[0] === 'autoAssignRandom') {
      form.setFieldsValue({
        autoAssignRoundRobin: false,
        autoAssignLoadBalanced: false,
      });
    }
    if (changedFields[0].name[0] === 'autoAssignRoundRobin') {
      form.setFieldsValue({
        autoAssignRandom: false,
        autoAssignLoadBalanced: false,
      });
    }
    if (changedFields[0].name[0] === 'autoAssignLoadBalanced') {
      form.setFieldsValue({
        autoAssignRandom: false,
        autoAssignRoundRobin: false,
      });
    }
    // validate maxTicket
    if (changedFields[0].name[0] === 'maxTicket') {
      setErrorMessage((prev) => {
        return {
          ...prev,
          maximumTicket: changedFields[0].errors[0],
        };
      });
    }
    // validate SLATime hour
    if (changedFields[0].name[0] === 'SLATime' && changedFields[0].name[1] === 'hour') {
      setErrorMessage((prev) => {
        return {
          ...prev,
          slaHour: changedFields[0].errors[0],
        };
      });
    }
    // validate SLATime minute
    if (changedFields[0].name[0] === 'SLATime' && changedFields[0].name[1] === 'minute') {
      setErrorMessage((prev) => {
        return {
          ...prev,
          slaMin: changedFields[0].errors[0],
        };
      });
    }
    if (!current.matches('editing')) {
      send('EDITING');
    }
  };

  const onFinish = (values: IGeneralConfig) => {
    send('SAVE', values);
  };

  const onDiscardChange = () => {
    form.resetFields();
    setErrorMessage((prev) => {
      return {
        ...prev,
        maximumTicket: '',
      };
    });
    send('DISCARD');
  };

  const onIncreaseSlaHr = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // prevent auto submit
    e.preventDefault();
    const { SLATime } = form.getFieldsValue(['SLATime']);
    // clear error message
    clearErrorMessage('slaHour');
    if (SLATime) {
      const hour = Number(SLATime.hour);
      if (hour < MAX_HOUR) {
        form.setFieldsValue({ SLATime: { hour: (hour + 1).toString() } });
      } else {
        form.setFieldsValue({ SLATime: { hour: '0' } });
      }
    } else {
      form.setFieldsValue({ SLATime: { hour: '0' } });
    }
  };

  const clearErrorMessage = (key: keyof typeof errorMessage) => {
    if (errorMessage[key]) {
      setErrorMessage((prev) => {
        return {
          ...prev,
          [key]: '',
        };
      });
    }
  };

  const onDecreaseSlaHr = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // prevent auto submit
    e.preventDefault();
    clearErrorMessage('slaHour');
    const { SLATime } = form.getFieldsValue(['SLATime']);
    if (SLATime) {
      const hour = Number(SLATime.hour);
      if (hour > 0) {
        form.setFieldsValue({ SLATime: { hour: (hour - 1).toString() } });
      } else {
        form.setFieldsValue({ SLATime: { hour: MAX_HOUR.toString() } });
      }
    } else {
      form.setFieldsValue({ SLATime: { hour: '0' } });
    }
  };

  const onUpdateSlaHour = (value: string) => {
    const str = removeSpecialWhenPasted(value, NUMBER_ONLY_REGEX); // number only
    form.setFieldsValue({ SLATime: { hour: str } });
  };

  // SLATime minute
  const onUpdateSlaMin = (value: string) => {
    const str = removeSpecialWhenPasted(value, NUMBER_ONLY_REGEX); // number only
    // first character
    if (value.length === 1 && Number(str) > 5) {
      form.setFieldsValue({ SLATime: { minute: '5' } });
      return;
    }
    // second character
    if (value.length === 2 && Number(str) > MAX_MIN) {
      form.setFieldsValue({ SLATime: { minute: MAX_MIN.toString() } });
      return;
    }

    form.setFieldsValue({ SLATime: { minute: str } });
  };

  const onIncreaseSlaMin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // prevent auto submit
    e.preventDefault();
    const { SLATime } = form.getFieldsValue(['SLATime']);
    clearErrorMessage('slaMin');

    if (SLATime) {
      const minute = Number(SLATime.minute);
      if (minute < 59) {
        form.setFieldsValue({ SLATime: { minute: (minute + 1).toString() } });
      } else {
        form.setFieldsValue({ SLATime: { minute: '0' } });
      }
    } else {
      form.setFieldsValue({ SLATime: { minute: '0' } });
    }
  };

  const onDecreaseSlaMin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // prevent auto submit
    e.preventDefault();
    const { SLATime } = form.getFieldsValue(['SLATime']);
    clearErrorMessage('slaMin');

    if (SLATime) {
      const minute = Number(SLATime.minute);
      if (minute > 0) {
        form.setFieldsValue({ SLATime: { minute: (minute - 1).toString() } });
      } else {
        form.setFieldsValue({ SLATime: { minute: '59' } });
      }
    } else {
      form.setFieldsValue({ SLATime: { minute: '0' } });
    }
  };

  return {
    form,
    current,
    errorMessage,
    query,
    onFinish,
    onFieldsChange,
    onDiscardChange,
    onUpdateSlaHour,
    onIncreaseSlaHr,
    onDecreaseSlaHr,
    onUpdateSlaMin,
    onIncreaseSlaMin,
    onDecreaseSlaMin,
  };
};

export default useGeneral;
