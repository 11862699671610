import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';

export const SearchInputContainer = styled.div`
  padding: 16px 16px 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  margin-bottom: 1px;
  .ant-input-affix-wrapper,
  input {
    border: none;
    box-shadow: none !important;
    background: ${({ theme }) => theme.GREY_9} !important;
  }

  svg {
    color: ${({ theme }) => theme.GREY_5} !important;
  }
`;

export const ClearButton = styled.button`
  cursor: pointer;
  height: 24px;
  padding: 0;
  color: ${({ theme }) => theme.BLUE};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.1px;
  border: none;
  background-color: transparent;
`;

export const TabContainer = styled.div``;

export const Menu = styled(AntdMenu)`
  overflow: auto;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  width: 280px;
  & li {
    height: 44px;
    display: flex;
    align-items: center;
  }
  & li > div:nth-child(1) {
    flex: 1;
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
      height: 22px;
      width: 22px;
    }
  }

  & li:hover {
    background: ${({ theme }) => theme.GREY_9};
    color: initial;
  }
`;

export const ListItem = styled.ul`
  /* max-height: 250px; */
`;
