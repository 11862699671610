import { client } from '@api';

export const getSuggestionMessage = (ticketId: string, requestId: string) => {
  return client.post(
    `/api/v1/chats/suggestions`,
    {
      ticketId,
    },
    {
      headers: {
        'X-Request-ID': requestId,
        Accept: 'text/event-stream',
      },
      responseType: 'stream',
    },
  );
};
