import notiSound from '@assets/sounds/notisound.mp3';

const instance = new Audio(notiSound);

export class Notification {
  private audio: HTMLAudioElement = instance;

  get currentTime() {
    return this.audio.currentTime;
  }
  public playNotificationSound() {
    this.audio.play().catch((e) => {
      console.warn('playNotificationSound error', e);
    });
  }
  public resetNotificationSound() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}

export const notification = new Notification();
