import { sendMessage, sendTemplate } from '@api/livechat/EngagementMessage';
import { AnyEventObject } from 'xstate';
import { IMessageBoxMachineContext } from '../messageBoxMachine';

//agent send message with time
export const sendMessageWithTime = async (context: IMessageBoxMachineContext, event: AnyEventObject) => {
  try {
    const { memberId, messagePayload } = event;
    await sendMessage(memberId, messagePayload);
    return;
  } catch (error) {
    throw error;
  }
};

export const sendTemplateWithTime = async (context: IMessageBoxMachineContext, event: AnyEventObject) => {
  try {
    const { messagePayload } = event;

    await sendTemplate(messagePayload);
    return;
  } catch (error) {
    throw error;
  }
};
