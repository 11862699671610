import styled, { keyframes } from 'styled-components/macro';

const move = keyframes`
  0% {
    left: -40%;
  }

  100% {
    left: 125%;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #e5e5e5;
  height: 4px;
  border-radius: 4px;
  overflow: hidden;
  ::before {
    content: '';
    width: 40%;
    left: -40px;
    height: 3px;
    position: absolute;
    background-color: #04be8c;
    animation: ${move} 1s ease-out infinite;
  }
`;
