import { STICKER_PREFIX } from '@configs/constants';
import React from 'react';
import { Sticker } from './style';
import useSticker from './useSticker';

export type IStickerMessage = {
  text: string;
};

export const StickerMessage = ({ text }: IStickerMessage) => {
  const stickerData = text.replace(STICKER_PREFIX, '').split('_').pop() || '';
  const { ref, onError } = useSticker(stickerData);

  return <Sticker ref={ref} alt={''} width={200} height={200} onError={onError} />;
};
