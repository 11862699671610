import { useTicketManager } from '@providers/TicketManagerProvider';
import { Form } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import { useCallback, useEffect, useState } from 'react';
import { useAutosave } from 'react-autosave';
import { useHistory } from 'react-router';

enum ResolveFormKey {
  REASON = 0,
  OUTCOME = 1,
  NOTE = 2,
}

const DEFAULT_WRAPUP = {
  note: '',
  reasons: [],
  result: [],
};

export type IAutoSave = {
  isSaving: boolean;
  lastSaved?: number;
};

export type IResolveForm = {
  reasons: string[];
  result: string[];
  note: string;
};

const useResolveDrawer = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [wrapup, setWrapup] = useState<IResolveForm>(DEFAULT_WRAPUP);
  const { current, hasTicketResolveResult, handleResolveTicket, handleSaveDraftWrapup, handleOpenResolveDrawer } =
    useTicketManager();
  const { ticket } = current.context;

  const saveDraft = useCallback((data: IResolveForm) => {
    if (ticket?._id && !hasTicketResolveResult) {
      handleSaveDraftWrapup(data);
    }
  }, []);

  useAutosave({
    data: wrapup,
    onSave: saveDraft,
    interval: 300,
  });

  useEffect(() => {
    if (ticket) {
      const summary = ticket.summary;
      form.setFieldsValue({
        reasons: summary?.reasons?.[0],
        result: summary?.result?.[0],
        note: summary?.note,
      });
    } else {
      form.resetFields();
    }
  }, []);

  const handleCloseDrawer = () => {
    handleOpenResolveDrawer(false);
  };

  const handleOpenDrawer = () => {
    handleOpenResolveDrawer(true);
  };

  const handleOnSubmit = () => {
    handleResolveTicket();
  };

  const handleDirectToUserList = () => {
    history.push('/m/recents');
  };

  const handleOnFieldsChange = (_: any, allFields: FieldData[]) => {
    const wrapup = {
      reasons: allFields[ResolveFormKey.REASON].value ? [allFields[ResolveFormKey.REASON].value] : [],
      result: allFields[ResolveFormKey.OUTCOME].value ? [allFields[ResolveFormKey.OUTCOME].value] : [],
      note: allFields[ResolveFormKey.NOTE].value ?? [],
    };
    setWrapup(wrapup);
  };

  return {
    form,
    ticket,
    handleCloseDrawer,
    handleOpenDrawer,
    handleOnFieldsChange,
    handleDirectToUserList,
    handleOnSubmit,
  };
};

export default useResolveDrawer;
