import React from 'react';
import { InputNumberProps } from 'antd';
import { AntdInputNumber, Container } from './style';

export type IInputNumber = InputNumberProps;

const InputNumber = ({ ...rest }: IInputNumber) => {
  return (
    <Container>
      <AntdInputNumber {...rest} />
    </Container>
  );
};

export default React.forwardRef(InputNumber);
