import { useState } from 'react';

export const useConfirm = () => {
  const [visible, setvisible] = useState(false);

  return {
    visible,
    onToggle: () => {
      setvisible(!visible);
    },
    onConfirm: (confirmHandle: () => void) => {
      confirmHandle();
    },
  };
};
