import styled from 'styled-components/macro';

export const Container = styled.div<{
  fontColor: string;
  bgColor: string;
  height: string;
}>`
  color: ${({ fontColor }) => fontColor};
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 18px;
  padding: 4px 12px;
  width: fit-content;
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
`;
