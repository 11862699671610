import { useSpring } from '@react-spring/web';
import { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export const useTicketHistoryItem = (defaultHeadHeight = 45, headHeight = 80) => {
  const [toggle, setToggle] = useState(false);
  const { height, ref } = useResizeDetector();
  const chevronStyles = useSpring({
    transform: toggle ? 'rotate(180deg)' : 'rotate(0deg)',
  });
  const dividerStyles = useSpring({
    opacity: toggle ? 1 : 0,
  });
  const wrapperStyle = useSpring({
    height: toggle ? height! + headHeight : defaultHeadHeight,
  });

  return {
    toggle,
    ref,
    dividerStyles,
    wrapperStyle,
    chevronStyles,
    onToggle: () => {
      setToggle(!toggle);
    },
  };
};
