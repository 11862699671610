import React from 'react';
import { Typography } from 'antd';
import { Container, ButtonPreview } from './style';

export type IOptionMessage = {
  label: string;
  actions: {
    id: string;
    label: string;
  }[];
};

const { Text } = Typography;

export const OptionMessage: React.FC<IOptionMessage> = ({ label, actions }: IOptionMessage) => {
  return (
    <Container>
      <div>
        <Text>{label}</Text>
      </div>
      <div>
        {actions.map((action) => {
          return (
            <ButtonPreview key={action.id}>
              <Text>{action.label}</Text>
            </ButtonPreview>
          );
        })}
      </div>
    </Container>
  );
};
