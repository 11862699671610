import React from 'react';
import LoadingCircle from '@components/Loading';
import { LoadingWrap } from '@components/Loading/style';
import Table, { IColumns } from '@components/Table';
import { useMember } from '@providers/MemberProvider';
import { IMember, ITicket } from '@types';
import getLogoByChannelType from '@utils/getLogoByChannelType';
import { Col, Empty, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import StatusTag, { IStatusTag } from '../tags/StatusTag';
import TicketTagList from '../tags/TicketTagList';
import { AssigneeWrap, HeaderText, TableBox } from './style';
import { ETicketStatus } from '@enums/TicketStatus';
import { slaCalculator, slaCloseDiff } from '@utils/ticketSla';
import { isEmpty, isNil } from 'lodash';
import { Font14G1W600, Frame } from '@components/UtilsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFlag, faStar } from '@fortawesome/pro-solid-svg-icons';

type ITicketTable = {
  tickets: ITicket[];
  filterData?: {
    page: number;
    pageSize: number;
    total: number;
  };
  isLoading: boolean;
  onPageChange: (page: number) => void;
};

const generateSlaText = (startSla: string, record: ITicket) => {
  const slaHour = record.SLATime?.hour;
  const slaMinute = record.SLATime?.minute;
  const slaResult = slaCalculator({
    sla: {
      hr: !isNil(slaHour) ? slaHour : -1,
      min: !isNil(slaMinute) ? slaMinute : -1,
    },
    startTicketTime: startSla,
  });

  // overdue
  if (typeof slaResult === 'object' && slaResult.isOverdue) {
    return <Typography.Text style={{ color: '#F72C40' }}>{dayjs(startSla).fromNow()}</Typography.Text>;
  }
  // not overdue
  if (typeof slaResult === 'object' && !slaResult.isOverdue) {
    return <Typography.Text style={{ color: '#0F86FE' }}>{dayjs(startSla).fromNow()}</Typography.Text>;
  }
  return '-';
};

const generateClosedSlaText = (record: ITicket) => {
  const start = record.startSLA;
  const stop = record.stopSLA;
  if (start && stop) {
    const duration = slaCloseDiff(start, stop);
    let hourText = '';
    let minText = '';
    // hour > 1 will add hrs
    if (duration.hour > 1) {
      hourText = `${duration.hour} hrs`;
    }
    // hour = 1 will add hr
    if (duration.hour === 1) {
      hourText = `${duration.hour} hr`;
    }
    // min > 1 will add mins
    if (duration.minute > 1) {
      minText = `${duration.minute} mins`;
    }
    // min = 1 will add min
    if (duration.minute === 1) {
      minText = `${duration.minute} min`;
    }
    // hour = 0 and min = 0
    if (duration.hour === 0 && duration.minute === 0) {
      hourText = '';
      minText = '1 min';
    }
    return <Typography.Text>{`${hourText} ${minText}`}</Typography.Text>;
  }
  return '-';
};

const _renderFirstResponseIcon = (record: ITicket) => {
  // if ticket status is open return null
  if (record.status === ETicketStatus.OPEN) {
    return null;
  }
  // if first response is not empty display green icon
  if (!isEmpty(record.firstResponder)) {
    return (
      <Frame width="20px" height="20px">
        <FontAwesomeIcon icon={faCheckCircle} color="#29CB72" />
      </Frame>
    );
  }
  // if first response is null display grey icon
  if (isEmpty(record.firstResponder)) {
    return (
      <Frame width="20px" height="20px">
        <FontAwesomeIcon icon={faCheckCircle} color="#BDBDBD" />
      </Frame>
    );
  }
};

const _renderIcon = (record: ITicket, self: IMember) => {
  const isFavorite = record.followUps?.includes(self._id);
  const isTeamFollowUp = record.isTeamFollowUp;
  const startIcon = <FontAwesomeIcon icon={faStar} color="#FFB400" className="text-[18px]" />;
  const flagIcon = <FontAwesomeIcon icon={faFlag} color="#f72c40" className="text-[18px]" />;
  if (!isFavorite && !isTeamFollowUp) {
    return '--';
  }
  if (isFavorite && isTeamFollowUp) {
    return (
      <div className="flex gap-x-1">
        {startIcon}
        {flagIcon}
      </div>
    );
  }
  if (isFavorite && !isTeamFollowUp) {
    return startIcon;
  }
  if (isTeamFollowUp) {
    return flagIcon;
  }
  return '--';
};

const generateTicketColumns: (self: IMember) => IColumns<ITicket>[] = (self: IMember) => {
  return [
    {
      title: () => <></>,
      dataIndex: 'followUps',
      key: 'follow_up',
      render: (_, record) => {
        return <div className="flex justify-center items-center">{_renderIcon(record, self)}</div>;
      },
      width: 56,
    },
    {
      title: () => <HeaderText>STATUS</HeaderText>,
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return <StatusTag type={record.status as IStatusTag['type']} />;
      },
      width: 115,
    },
    {
      title: () => <HeaderText>TICKET NO.</HeaderText>,
      dataIndex: 'ticketNumber',
      key: 'ticket_number',
      width: 150,
    },
    {
      title: () => <HeaderText>CUSTOMER</HeaderText>,
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => {
        return (
          <Typography.Text style={{ width: 120 }} ellipsis>
            {record.userInfo?.name || '-'}
          </Typography.Text>
        );
      },
      width: 150,
    },
    {
      title: () => <HeaderText>DISPLAY NAME</HeaderText>,
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => {
        return (
          <Typography.Text style={{ width: 120 }} ellipsis>
            {record.userInfo?.displayName || '-'}
          </Typography.Text>
        );
      },
      width: 150,
    },
    {
      title: () => <HeaderText>SLA</HeaderText>,
      dataIndex: 'sla',
      key: 'sla',
      width: 100,
      render: (_, record) => {
        // ticket is open or assgined will run color
        const startSla = record.startSLA;

        if (startSla) {
          if (record.status === ETicketStatus.OPEN || record.status === ETicketStatus.ASSIGNED) {
            return generateSlaText(startSla, record);
          }

          // ticket is closed or resolved will not run color
          return generateClosedSlaText(record);
        }
        return '-';
      },
    },
    {
      title: () => <HeaderText>ASSIGNEE</HeaderText>,
      dataIndex: 'agentDisplayName',
      key: 'agentDisplayName',
      render: (_, record) => {
        return (
          <AssigneeWrap>
            <Row gutter={[8, 0]} wrap={false}>
              {/* first response icon */}
              <Col>{_renderFirstResponseIcon(record)}</Col>
              <Col>
                <Font14G1W600>{record.agentDisplayName ?? '-'}</Font14G1W600>
              </Col>
            </Row>
          </AssigneeWrap>
        );
      },
      width: 200,
    },
    {
      title: () => <HeaderText>CHANNEL</HeaderText>,
      dataIndex: 'channelType',
      key: 'channel',
      render: (_, record) => {
        return (
          <div className="flex gap-x-[8px]" title={record.channelName || ''}>
            <img src={getLogoByChannelType(record.channelType)} width={24} height={24} />
            <Font14G1W600 className="line-clamp-1">{record.channelName}</Font14G1W600>
          </div>
        );
      },
      width: 180,
    },
    {
      title: () => <HeaderText>LATEST MESSAGE</HeaderText>,
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => {
        return (
          <Typography.Text style={{ width: 160 }} ellipsis>
            {record.latestMessageAt ? dayjs(record.latestMessageAt).format('DD MMM YYYY, HH:mm') : '-'}
          </Typography.Text>
        );
      },
      width: 155,
    },
    {
      title: () => <HeaderText>OPEN TICKET</HeaderText>,
      dataIndex: 'startDateTime',
      key: 'start_datetime',
      render: (_, record) => {
        return <Typography.Text>{dayjs(record.createdAt).format('DD MMM YYYY, HH:mm')}</Typography.Text>;
      },
      width: 180,
    },
    {
      title: () => <HeaderText>TAG</HeaderText>,
      dataIndex: 'tags',
      key: 'tags',
      render: (_, record) => {
        return <TicketTagList tags={record.tags ? record.tags : []} displaySize={1} />;
      },
      width: 200,
    },
  ];
};

function TicketsTable({ tickets, filterData, isLoading, onPageChange }: ITicketTable): ReactElement {
  const { member } = useMember();
  const columns = generateTicketColumns(member);
  const history = useHistory();
  return (
    <TableBox>
      <Table
        scroll={{ y: 'calc(100vh - 320px)' }}
        header={<></>}
        columns={columns}
        rowKey={(record) => record._id}
        data={tickets}
        onRow={({ _id }: { _id: string }) => {
          return {
            onClick: () => {
              history.push(`/ticket-management/all-tickets/${_id}`);
            },
          };
        }}
        loading={
          isLoading && {
            indicator: (
              <LoadingWrap data-testid="test-loading-wrap">
                <LoadingCircle />
              </LoadingWrap>
            ),
          }
        }
        pagination={{
          current: filterData?.page,
          pageSize: filterData?.pageSize,
          total: filterData?.total,
          onChange: onPageChange,
          showSizeChanger: false,
        }}
        locale={{
          emptyText: <Empty description={'No results match your search'} />,
        }}
      />
    </TableBox>
  );
}

export default TicketsTable;
