import React from 'react';
import LoadingCircle from '@components/Loading';
import { LoadingWrap } from '@components/Loading/style';
import { ConfigProvider, Spin } from 'antd';
import SearchInput from '@components/SearchInput';
import { Button } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import useTranslation from '@hooks/useTranslation';
import TableList from '@components/TableList';
import Table, { IColumns } from '@components/Table';
import { Font14G1W400, Font16G1W600 } from '@components/UtilsComponent';
import { Link, useHistory } from 'react-router-dom';
import { ROUTE } from '@configs/route';
import { fetchHandler, useTable } from '@hooks/useTable';
import { tableListGenerator } from '@utils/tableListGenerator';
import { deleteQuickReplyCollection, fetchQuickReplyCollection } from '@api/quickReply';
import { DropdownGroup, IDropDownItem } from '@components/Dropdown';
import { QUICK_REPLY_COLLECTION_TABLE_ACTION } from '@configs/quickReplyCollection';
import { IQuickReplyCollectionResponse } from '@types';
import { useDelete } from '@hooks/useDelete';
import { DeleteModal } from '@components/Modal';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

const generateColumns = (
  handleSelectAction: (action: IDropDownItem, record: IQuickReplyCollectionResponse) => void,
): IColumns[] => [
  {
    title: 'COLLECTION NAME',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'TEMPLATE',
    dataIndex: '',
    key: '',
    render: (record) => <Font14G1W400>{record.templateIds?.length ?? 0}</Font14G1W400>,
  },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: 50,
    render: (text: string, record: IQuickReplyCollectionResponse, index: number) => (
      <DropdownGroup
        placement="bottomLeft"
        btn={(setFunction: (value: boolean) => void) => {
          return (
            <div className="flex justify-end">
              <FontAwesomeIcon
                onClick={() => setFunction(true)}
                color="#757575"
                icon={faEllipsisH}
                className="cursor-pointer mr-[10px]"
              />
            </div>
          );
        }}
        data={QUICK_REPLY_COLLECTION_TABLE_ACTION}
        handleSelectItem={(item) => handleSelectAction(item, record)}
      />
    ),
  },
];

export const TemplateCollectionList = () => {
  const t = useTranslation();
  const history = useHistory();
  const deleteHandler = useDelete<IQuickReplyCollectionResponse, boolean>({
    deleteFn: async (payload) => {
      await deleteQuickReplyCollection(payload.id);
      return true;
    },
  });
  const onHandleSelectAction = (action: IDropDownItem, record: IQuickReplyCollectionResponse) => {
    switch (action.value) {
      case 'edit':
        history.push(`${ROUTE.TEMPLATE_COLLECTION}/${record.id}`);
        break;
      case 'delete':
        deleteHandler.onSelectForDelete(record);
        deleteHandler.confirmModal.onToggle();
        break;
      default:
        break;
    }
  };
  const { query, keyword, isDisabledPrev, onUpdateKeyword, onNext, onPrev, onUpdateCursorId, onUpdateCurrentPage } =
    useTable({
      fetchFn: async (keyword, cursorId, currentPageEvent, currentPage) => {
        const result = await tableListGenerator({
          ...(await fetchHandler(
            keyword,
            cursorId,
            currentPageEvent,
            currentPage,
            async ({ keyword, cursorId, take }) => {
              const data = await fetchQuickReplyCollection(take, keyword || undefined, cursorId || undefined);
              return data;
            },
          )),
        });
        onUpdateCursorId(result?.nextCursorId ?? '', result?.prevCursorId ?? '');
        onUpdateCurrentPage(result?.currentPage ?? 1);
        return result;
      },
      debounceTime: 500,
    });
  return (
    <Spin
      spinning={query.isFetching}
      indicator={
        <LoadingWrap data-testid="test-loading-wrap">
          <LoadingCircle />
        </LoadingWrap>
      }
    >
      <div className="mt-6">
        <div className="flex justify-between items-center">
          <SearchInput
            className="w-[328px]"
            value={keyword}
            onClear={() => {
              onUpdateKeyword('');
            }}
            onChange={(e) => {
              onUpdateKeyword(e.target.value);
            }}
          />
          <Link to={ROUTE.TEMPLATE_COLLECTION_NEW}>
            <Button icon={<FontAwesomeIcon icon={faPlus} className="mr-2" />}>
              {t('template.collection.list.add.new.btn')}
            </Button>
          </Link>
        </div>
      </div>
      <ConfigProvider renderEmpty={() => <div>{t('template.collection.list.no.data')}</div>}>
        <TableList
          next={{
            onClick: onNext,
            disabled: query.data?.disabledNext || false,
          }}
          prev={{
            onClick: onPrev,
            disabled: isDisabledPrev,
          }}
        >
          <Table
            header={
              <div className="my-[16px]">
                <Font16G1W600>{t('template.collection.list.table.header')}</Font16G1W600>
              </div>
            }
            data={query.data?.items ?? []}
            columns={generateColumns(onHandleSelectAction)}
            pagination={false}
            scroll={{ y: 'calc(100vh - 420px)' }}
          />
        </TableList>
      </ConfigProvider>
      <DeleteModal
        visible={deleteHandler.confirmModal.visible}
        isLoading={deleteHandler.deleteMut.isLoading}
        handleConfirm={() =>
          deleteHandler.confirmModal.onConfirm(async () => {
            if (!deleteHandler.selectForDelete) return;
            await deleteHandler.onDelete(deleteHandler.selectForDelete);
            deleteHandler.confirmModal.onToggle();
            deleteHandler.onSelectForDelete(null);
            await query.refetch();
          })
        }
        handleClose={() => {
          if (deleteHandler.deleteMut.isLoading) return;
          deleteHandler.onSelectForDelete(null);
          deleteHandler.confirmModal.onToggle();
        }}
        cancelBtnTxt={t('cancel.btn')}
        confirmBtnTxt={t('confirm.btn')}
      >
        <span>
          {t('ticket.tag.delete.confirm.title', {
            name: <Font16G1W600>{deleteHandler.selectForDelete?.name || '-'}</Font16G1W600>,
          })}
        </span>
      </DeleteModal>
    </Spin>
  );
};
