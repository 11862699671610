import React, { useCallback, useMemo } from 'react';
import { BottomSection, Container, TopSection, TicketDetail, TickerNumberContainer } from './style';
import { Col, Row, Tooltip } from 'antd';
import useTranslation from '@hooks/useTranslation';

import useTicketConsole from './useTicketConsole';

import dayjs from 'dayjs';
import { Font12G4W500, Font12G1W500 } from '@components/UtilsComponent';
import { isNil } from 'lodash';
import { faStar as fasStar, faFlag as fasFlag } from '@fortawesome/pro-solid-svg-icons';
import { faStar as farStar, faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons';
import TagSelector from './TagSelector';
import { FirstResponse } from '@components/FirstResponse';
import { SlaText } from '@components/SlaText';
import { IconMove } from '@components/IconMove';
import { useTheme } from 'styled-components';
import { updateFollowUp, updateTeamFollowUp } from '@api/livechat/EngagementMessage';
import { useMember } from '@providers/MemberProvider';
import { ResolveTicket } from '@components/ResolveTicket';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedUserState, selectedTicketState } from '@store/EngagementUserListAtom';
import { useHistory } from 'react-router-dom';
import { IResolveForm } from '@components/ResolveTicket/ResolveModal/useResolveModal';
import { useFollowUp } from '@hooks/useFollowUp';
import { canDoTeamFollowUp } from '@utils/followUp';
import { useSetting } from '@providers/SettingProvider';
import { getSummaryFromPayload } from '@utils/ticketWrapUp';
import { ModalAssignee } from '@components/ModalAssignee';

const TicketConsole = () => {
  const theme = useTheme();
  const t = useTranslation();
  const { member, isAdmin } = useMember();
  const { permission } = useSetting();
  const selectedUser = useRecoilValue(selectedUserState);
  const [selectedTicket, setSelectedTicket] = useRecoilState(selectedTicketState);
  const { handleAssginSuccess } = useTicketConsole();
  const history = useHistory();
  const { setting } = useSetting();
  const isTicketOpen = selectedTicket?.status === 'open';
  const [followUp, _followUpMutation, onFollowUp] = useFollowUp({
    ticket: selectedTicket,
    member,
    updateFollowUpFn: updateFollowUp,
    initCondition: (ticket, member, setter) => {
      const isFollowUp = ticket?.followUps?.some((item: string) => item === member._id);
      if (isFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });
  const [teamFollowUp, _teamFollowUpMutation, onTeamFollowUp] = useFollowUp({
    ticket: selectedTicket,
    member,
    updateFollowUpFn: updateTeamFollowUp,
    initCondition: (ticket, _member, setter) => {
      if (ticket.isTeamFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });

  const slaHour = !isNil(selectedTicket?.SLATime?.hour) ? selectedTicket?.SLATime?.hour : -1;
  const slaMinute = !isNil(selectedTicket?.SLATime?.minute) ? selectedTicket?.SLATime?.minute : -1;
  const startTicketTime = selectedTicket?.startSLA ? selectedTicket.startSLA : undefined;

  const _renderSla = useCallback(() => {
    if (!selectedTicket) return null;
    return <SlaText slaHour={slaHour} slaMinute={slaMinute} startTicketTime={startTicketTime} />;
  }, [selectedTicket, slaHour, slaMinute, startTicketTime]);

  const canNotDoTeamFollowUp = useMemo(() => {
    // current login is admin
    if (isAdmin()) return false;
    if (permission.canTeamFollowUp) return false;
    if (!selectedTicket || !member) return false;
    return !canDoTeamFollowUp(selectedTicket, member);
  }, [member, selectedTicket]);
  return (
    <Container>
      <TopSection>
        <TicketDetail>
          <IconMove
            isActive={followUp}
            isLoading={false}
            activeIcon={fasStar}
            inactiveIcon={farStar}
            activeColor={'#FFB400'}
            inActiveColor={theme.GREY_5}
            onClick={onFollowUp}
          />
          {isTicketOpen ? (
            <Tooltip title={t('require.assignee.for.flag')} placement="top">
              <div>
                <IconMove
                  isActive={teamFollowUp}
                  isLoading={false}
                  activeIcon={fasFlag}
                  inactiveIcon={farFlag}
                  activeColor={'#f72c40'}
                  inActiveColor={theme.GREY_5}
                  isDisabled={canNotDoTeamFollowUp}
                  onClick={onTeamFollowUp}
                />
              </div>
            </Tooltip>
          ) : (
            <IconMove
              isActive={teamFollowUp}
              isLoading={false}
              activeIcon={fasFlag}
              inactiveIcon={farFlag}
              activeColor={'#f72c40'}
              inActiveColor={theme.GREY_5}
              isDisabled={canNotDoTeamFollowUp}
              onClick={onTeamFollowUp}
            />
          )}

          <TickerNumberContainer>
            <>
              <Row gutter={[8, 0]}>
                <Col>
                  <Font12G4W500>{t('livechat.ticket.console.ticket.number.label')} </Font12G4W500>
                  <Font12G1W500>{`#${selectedTicket?.ticketNumber}`}</Font12G1W500>
                </Col>
                {/* SLA */}
                <Col>{_renderSla()}</Col>
                <Col>
                  <Font12G4W500>{t('livechat.ticket.console.ticket.team.label')} </Font12G4W500>
                  <Font12G1W500>{selectedTicket?.teamDetail?.name ?? '-'}</Font12G1W500>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col>
                  <Font12G4W500>{t('livechat.ticket.console.ticket.start.label')} </Font12G4W500>
                  <Font12G1W500>{dayjs(selectedTicket?.createdAt).format('DD MMM YYYY, HH:mm')}</Font12G1W500>
                </Col>
                <Col>
                  <Font12G4W500>{t('livechat.ticket.console.ticket.first.response.label')} </Font12G4W500>
                  <FirstResponse ticket={selectedTicket} />
                </Col>
              </Row>
            </>
          </TickerNumberContainer>
        </TicketDetail>
        {selectedTicket && selectedUser && member ? (
          <ResolveTicket
            ticket={selectedTicket}
            user={selectedUser}
            isWrapUpTicketRequired={setting.isWrapUpTicketRequired}
            member={member}
            submitCb={() => {
              history.push(`/recents/`);
              history.push(`/recents/${selectedTicket.userId}`);
            }}
            onUpdateTicketField={function (payload: IResolveForm): void {
              setSelectedTicket((prev) => {
                if (!prev) return null;
                return {
                  ...prev,
                  summary: getSummaryFromPayload(payload),
                };
              });
            }}
          />
        ) : null}
      </TopSection>
      <BottomSection>
        <>
          {selectedTicket ? <ModalAssignee ticket={selectedTicket} onAssignSuccesss={handleAssginSuccess} /> : null}
          {selectedTicket ? <TagSelector ticket={selectedTicket} /> : null}
        </>
      </BottomSection>
    </Container>
  );
};

export default TicketConsole;
