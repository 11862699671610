import { Menu } from 'antd';
import styled from 'styled-components/macro';
import { Dropdown as AntdDropdownComp } from 'antd';

export const Container = styled.div``;
export const OverlayWrap = styled(Menu)`
  background: white;
  padding: 12px 0px;
  border-radius: 12px;
  max-height: 500px;
  overflow-y: auto;
`;

export const AntdDropdown = styled(AntdDropdownComp)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
  background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3)) !important;
  color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3)) !important;
  border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3)) !important;
  cursor: not-allowed;
`}
`;
