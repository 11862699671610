import React from 'react';
import { TICKET_MANAGEMENT_ROUTE } from '@configs/constants';
import { Redirect, Route, Switch } from 'react-router-dom';
import AllTicketsPane from './AllTicketsPane';
import TicketHistory from './TicketHistory';
import { Container } from './style';

const TicketManagement = () => {
  return (
    <Container>
      <Switch>
        {/* add key for rerender when reopen ticket */}
        <Route path={TICKET_MANAGEMENT_ROUTE.CHAT_HISTORY} key={Date.now()}>
          <TicketHistory />
        </Route>
        <Route path={TICKET_MANAGEMENT_ROUTE.ALL_TICKETS}>
          <AllTicketsPane />
        </Route>

        <Route path={TICKET_MANAGEMENT_ROUTE.DEFAULT} exact>
          <Redirect to={TICKET_MANAGEMENT_ROUTE.ALL_TICKETS} />
        </Route>
      </Switch>
    </Container>
  );
};

export default TicketManagement;
