import { isEmpty } from 'lodash';
import { useState, useLayoutEffect, useRef } from 'react';

export const useCalculateWidth = <T>(arr: T[], initialSize = 100000, extraWidth = 0) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tagRefs = useRef<{ id: string; el: HTMLElement }[]>([]);
  const tempsWidth = useRef<number[]>([]);
  const [index, setIndex] = useState(initialSize);

  useLayoutEffect(() => {
    if (!isEmpty(arr)) {
      for (const i of tagRefs.current) {
        tempsWidth.current.push(i.el.getBoundingClientRect().width);
      }
    }
  }, [arr, tagRefs, containerRef]);

  useLayoutEffect(() => {
    if (containerRef.current && tempsWidth.current) {
      const onResize = () => {
        cal();
      };
      window.addEventListener('resize', onResize);
      const cal = () => {
        if (containerRef.current && !isEmpty(tempsWidth.current)) {
          // sum of item width
          let sum = 0;
          let index = -1;
          const { width: containerWidth } = containerRef.current.getBoundingClientRect();
          for (const [i, width] of tempsWidth.current.entries()) {
            sum = sum + width;
            if (sum + extraWidth >= containerWidth) {
              index = i;
              break;
            }
          }
          if (index > -1) {
            setIndex(index);
          }
        }
      };
      cal();
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  }, [arr, containerRef, tagRefs]);

  return {
    containerRef,
    tagRefs,
    displaySize: arr.length - (arr.length - index),
  };
};
