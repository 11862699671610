export enum ETicketStatus {
  ALL = 'all',
  OPEN = 'open',
  RESOLVED = 'resolved',
  ASSIGNED = 'assigned',
}

export enum ESortBy {
  LATEST_MESSAGE = '-latestMessageAt',
  LATEST_OPENED_TICKET = '-createdAt',
}
