import React, { useCallback, useEffect, useRef } from 'react';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IOption } from '@components/Select';
import { SelectBtn } from '@components/SelectBtn';
import { DropdownItem } from './style';
import { IQuickReplyCollectionResponse } from '@types';
import { fetchQuickReplyCollection } from '@api/quickReply';

type IQucikReplyCollectionParams = {
  keyword: string;
  cursorId: string;
  take: number;
};

type IQuickReplyFilterProps = {
  selectedQuickReply: IQuickReplyCollectionResponse | null;
  onSelectQuickReply: (item: IQuickReplyCollectionResponse | null) => void;
};

export const QuickReplyFilter = ({ selectedQuickReply = null, onSelectQuickReply }: IQuickReplyFilterProps) => {
  const { data, searchKeyword, firstLoading, hasMore, onOpenDropdown, onLoadmore, onUpdateSearchKeyword } =
    useInfinityLoadByCursorId<IQuickReplyCollectionResponse, IOption>({
      fetchFn: async (filter, cancelToken) => {
        const newFilter: IQucikReplyCollectionParams = {
          cursorId: filter.cursorId ?? '',
          keyword: filter.keyword ?? '',
          take: 10,
        };
        const data = await fetchQuickReplyCollection(
          newFilter.take,
          newFilter.keyword || undefined,
          newFilter.cursorId || undefined,
          cancelToken,
        );
        const result = data.map((item) => {
          return {
            ...item,
            label: item.name,
            value: item.id,
          };
        });

        return result;
      },
    });
  const selectedRef = useRef<IQuickReplyCollectionResponse | null>(null);
  const _renderSelectedText = useCallback((selected: IQuickReplyCollectionResponse | null): React.ReactNode => {
    if (selected) {
      return selected.name;
    }

    return '';
  }, []);

  useEffect(() => {
    selectedRef.current = selectedQuickReply;
  }, [selectedQuickReply]);

  return (
    <>
      <DropdownDynamicBtn
        onClearAllSelection={() => {
          selectedRef.current = null;
          onSelectQuickReply(selectedRef.current);
        }}
        placement="topLeft"
        searchKeyword={searchKeyword}
        onUpdateSearchKeyword={onUpdateSearchKeyword}
        options={[
          {
            label: 'All collection',
            id: 'all',
            name: 'All collection',
            templateIds: [],
            value: 'all',
          },
          ...data,
        ]}
        isFirstLoading={firstLoading}
        renderOptions={(options, setOpenDropDown) => {
          return options.map((item) => {
            return (
              <DropdownItem
                key={item.value}
                onClick={() => {
                  selectedRef.current = item;
                  onSelectQuickReply(item);
                  if (setOpenDropDown) {
                    setOpenDropDown(false);
                  }
                }}
              >
                <span>{item.label}</span>
              </DropdownItem>
            );
          });
        }}
        renderBtn={function (
          prevState: boolean,
          setter: (status: React.SetStateAction<boolean>) => void,
        ): React.ReactNode {
          return (
            <SelectBtn
              className="max-w-[200px] min-w-[200px]"
              data-testid="test-quickreply-filter-btn"
              selectedText={_renderSelectedText(selectedRef.current)}
              placeholder={'All collection'}
              onClick={() => {
                // toggle dropdown
                setter(!prevState);
                if (!prevState) {
                  onOpenDropdown();
                }
              }}
            />
          );
        }}
        hasMore={hasMore}
        onLoadMore={onLoadmore}
      />
    </>
  );
};
