const ROLE_TITLE = {
  DEVELOPER: 'developer',
  EDITOR: 'editor',
  SUPERVISOR: 'supervisor',
  MODERATOR: 'moderator',
  AGENT: 'agent',
  BOT: 'bot',
  ADMIN: 'admin',
} as const;

export const getTagBgColor = (role: string) => {
  if (role.includes(ROLE_TITLE.DEVELOPER)) {
    return '#FFF4D9';
  }
  if (role.includes(ROLE_TITLE.EDITOR)) {
    return '#EEEEFA';
  }
  if (role.includes(ROLE_TITLE.SUPERVISOR)) {
    return '#E6F8FA';
  }
  if (role.includes(ROLE_TITLE.MODERATOR)) {
    return '#FCE8F1';
  }
  if (role.includes(ROLE_TITLE.AGENT)) {
    return '#FFF1EA';
  }
  if (role.includes(ROLE_TITLE.BOT)) {
    return '#E7F2FE';
  }
  return '#212121';
};

export const getFontColor = (role: string) => {
  if (role.includes(ROLE_TITLE.DEVELOPER)) {
    return '#FFB400';
  }
  if (role.includes(ROLE_TITLE.EDITOR)) {
    return '#5856D6';
  }
  if (role.includes(ROLE_TITLE.SUPERVISOR)) {
    return '#05BED2';
  }
  if (role.includes(ROLE_TITLE.MODERATOR)) {
    return '#E61E73';
  }
  if (role.includes(ROLE_TITLE.AGENT)) {
    return '#FF742F';
  }
  if (role.includes(ROLE_TITLE.BOT)) {
    return '#0F86FE';
  }
  if (role.includes(ROLE_TITLE.ADMIN)) {
    return '#212121';
  }
  return 'black';
};
