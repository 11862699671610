import React from 'react';
import { SwitchContainer } from './style';
import { Switch as AntdSwitch, SwitchProps } from 'antd';
import { SwitchSize } from 'antd/lib/switch';
export type ISwitch = {
  size?: 'small' | 'large' | 'default';
} & Omit<SwitchProps, 'size'>;

const Switch: React.FC<ISwitch> = ({ size, ...rest }: ISwitch) => {
  return (
    <SwitchContainer size={size}>
      <AntdSwitch size={size as SwitchSize} {...rest} />
    </SwitchContainer>
  );
};

export default Switch;
