import { useMutation, useQuery } from 'react-query';

export type IUseEditParams<FR, P> = {
  id: string;
  fetchFn: (id: string) => Promise<FR | undefined>;
  updateFn: (data: P) => Promise<P | undefined>;
};

export const useEdit = <FR, P>({ id, fetchFn, updateFn }: IUseEditParams<FR, P>) => {
  const query = useQuery(['fetch_fr', id], () => fetchFn(id), {
    cacheTime: 0,
  });
  const updateMute = useMutation((data: P) => updateFn(data));
  return {
    query,
    updateMute,
    onUpdate: async (payload: P) => {
      try {
        const result = await updateMute.mutateAsync(payload);
        return result;
      } catch (error) {
        console.error('Error update data: ', error);
        throw error;
      }
    },
  };
};
