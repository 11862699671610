import React, { useCallback } from 'react';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { Container, ListContainer, LoadingWrap } from './style';
import LoadingCircle from '@components/Loading';
import { AutoSizer } from 'react-virtualized';
import ChatMessage from '@pages/components/ChatMessage';
import { useComposerManager } from '@providers/ComposerManagerProvider';

const TicketHistoryMessageBox: React.FC = () => {
  const { current, listRef, handleScroll, _renderDownButton, handleResend } = useMessageBox();
  const { chatHistory } = current.context;
  const { preview } = useComposerManager();
  const { selectedTemplate } = preview;

  const _renderListContainer = useCallback(
    ({ height, width }) => {
      return (
        <ListContainer style={{ height, width }}>
          {chatHistory.messages?.map((message, index) => {
            return <ChatMessage key={message.getId + index} message={message} onResend={handleResend} />;
          })}
          <div className="h-[20px]"></div>
        </ListContainer>
      );
    },
    [chatHistory.messages, current.matches({ chatHistoryState: 'sendMessageFailed' })],
  );

  return (
    <>
      <Container ref={listRef} onScroll={handleScroll} className={`${!!selectedTemplate ? 'blur-sm' : ''}`}>
        {current.hasTag('loading-previous') && (
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        )}
        <AutoSizer>{_renderListContainer}</AutoSizer>
        {current.hasTag('loading-next') && (
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        )}
      </Container>
      {_renderDownButton()}
    </>
  );
};

export default TicketHistoryMessageBox;
