import React from 'react';
import useTranslation from '@hooks/useTranslation';
import { Spin, Typography } from 'antd';
import { Container, Content, AddDotButton, Item } from './style';
import useTagSelector from './useTagSelector';
import { TicketTagList } from '@components/Tag';
import { useCalculateWidth } from '@components/Tag/TicketTagList/useCalculateWidth';
import Chip from '@assets/images/Chip.svg';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { ITicket, ITicketTagConsole } from '@types';

const { Text } = Typography;

export type ITagSelectorProps = {
  ticket: ITicket;
};

const TagSelector: React.FC<ITagSelectorProps> = ({ ticket }) => {
  const t = useTranslation();
  const {
    searchKeyword,
    globalTagLoading,
    selectedTags,
    dropdownTagsInfo,
    canAddTag,
    canManageTag,
    onUpdateSearchKeyword,
    onGetAvailableTags,
    onLoadMore,
    onSelectItem,
    onRemoveTag,
  } = useTagSelector(ticket);
  const { containerRef, tagRefs, displaySize } = useCalculateWidth(selectedTags, 100000, 100);

  return (
    <Container>
      <Text>{t('livechat.ticket.console.ticket.tags')}</Text>
      <Content ref={containerRef} data-testid="tag-content-wrap-test">
        <Spin indicator={<div></div>} spinning={globalTagLoading}>
          <TicketTagList
            tags={selectedTags || []}
            canRemove={canManageTag()}
            showHiddenMenu
            onRemoveTag={onRemoveTag}
            displaySize={displaySize === 100000 ? 100000 : displaySize}
            tagRefs={tagRefs}
          />
          {canManageTag() && canAddTag ? (
            <DropdownDynamicBtn<ITicketTagConsole>
              onUpdateSearchKeyword={onUpdateSearchKeyword}
              options={dropdownTagsInfo.data}
              isFirstLoading={dropdownTagsInfo.isFirstLoading}
              renderOptions={(options, setOpenDropDown) => {
                return options.map((item) => {
                  return (
                    <Item
                      isDisabled={item.selected}
                      key={item.value}
                      onClick={() => {
                        !item.selected && setOpenDropDown && setOpenDropDown(false);
                        return !item.selected ? onSelectItem(item) : false;
                      }}
                    >
                      {item.label}
                    </Item>
                  );
                });
              }}
              renderBtn={function (
                prevState: boolean,
                setter: (status: React.SetStateAction<boolean>) => void,
              ): React.ReactNode {
                return (
                  <AddDotButton
                    onClick={() => {
                      // get data
                      if (!prevState) {
                        onGetAvailableTags();
                      }
                      // open dropdown
                      setter(!prevState);
                    }}
                  >
                    <img src={Chip} width={36} height={36} />
                  </AddDotButton>
                );
              }}
              hasMore={dropdownTagsInfo.hasMore}
              onLoadMore={onLoadMore}
              searchKeyword={searchKeyword}
            />
          ) : null}
        </Spin>
      </Content>
    </Container>
  );
};

export default TagSelector;
