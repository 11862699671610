import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BOTS } from '@types';
import { PreviewContent } from '@components/MessagePreview/PreviewContent';
export type ITemplatePreviewOverlayProps = {
  template: BOTS.ITemplateApi | null;
  visible: boolean;
  onClose: () => void;
};

export const TemplatePreviewOverlay: React.FC<ITemplatePreviewOverlayProps> = ({ template, visible, onClose }) => {
  if (!template) return null;
  return (
    <div
      className={`h-full w-full overflow-auto bg-[rgba(0,0,0,0.60)] absolute ${
        visible ? 'z-50' : 'z-[-50]'
      } transition-all`}
      style={{
        opacity: visible ? 1 : 0,
      }}
    >
      <div className="p-4 pb-0 h-full">
        <div className="relative h-full">
          <div className="absolute top-0 right-0">
            <div className=" w-[24px] h-[24px] flex justify-center items-center">
              <FontAwesomeIcon
                role="button"
                className="w-[24px] text-[18px]"
                icon={faTimes}
                color="white"
                onClick={onClose}
              />
            </div>
          </div>
          <div className="pt-[24px] h-full">
            <div className="w-[320px] max-w-[400px] h-full mx-auto">
              <PreviewContent messages={template.messages} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
