export enum EMessageType {
  TEXT = 'text',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  LINK = 'link',
  CAROUSEL = 'carousel',
  OPTION = 'option',
  FLEX = 'flex',
  TICKET = 'ticket',
  STICKER = 'sticker',
  IMAGEMAP = 'imagemap',
  IMAGE_CAROUSEL = 'image_carousel',
  LOCATION = 'location',
  BUTTONS = 'buttons',
  TEMPLATE_FAILED = 'template_failed',
  POSTBACK = 'postback',
  HTML = 'html',
}
