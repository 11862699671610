import styled from 'styled-components/macro';
import { GhostButton } from '@components/Button';

export const Container = styled.div<{ isActive: boolean; activeColor: string; inActiveColor: string }>`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background: transparent !important;
    padding: 0px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .icon-enable {
      color: ${({ activeColor }) => activeColor};
      transition: 0.3s;
      transform: translateY(30px);
    }

    .icon-enable.active {
      transition: 0.3s;
      transform: translateY(0px);
    }
    .icon-disable {
      color: ${({ inActiveColor }) => inActiveColor};
      transition: 0.3s;
      transform: translateY(20px);
    }
    .icon-disable.active {
      transition: 0.3s;
      transform: translateY(-20px);
    }
    svg {
      cursor: pointer;
      width: 24px !important;
      height: 20px !important;
      font-weight: 400;
      font-size: 18px;
    }
  }
`;

export const ButtonWrap = styled(GhostButton)`
  overflow: hidden;
`;
