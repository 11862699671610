import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { IMessageLevel } from '@types';

export type ICarouselMessageItem = {
  id: string;
  image: string;
  title: string;
  description: string;
  buttons: string[];
};

export type CarouselMessageJson = {
  items: ICarouselMessageItem[];
} & BaseMessageJson;
export class CarouselMessage extends BaseMessage {
  private items: ICarouselMessageItem[];
  private level: IMessageLevel;
  constructor(data: CarouselMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.items = data.items;
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      items: this.items,
      retry: this.retry,
      senderImage: this.senderImage,
      level: this.level,
      senderName: this.senderName,
    };
  }

  get getMessageType() {
    return 'carousel';
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
