import React from 'react';
import { Font14G5W400 } from '@components/UtilsComponent';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { useTheme } from 'styled-components';

export const AutoSaveText = () => {
  const t = useTranslation();
  const theme: any = useTheme();
  return (
    <div className="flex items-center">
      <FontAwesomeIcon
        icon={faSpinnerThird}
        spin
        className="text-[18px] mr-1"
        style={{
          color: theme.PRIMARY,
        }}
      />
      <Font14G5W400>{t('livechat.ticket.console.resolve.auto.save.text')}</Font14G5W400>
    </div>
  );
};
