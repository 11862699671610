import axios, { CancelTokenSource } from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useCancelTokenSource } from './useCancelToken';
import { DEFAULT_API_MAX_TAKE, DEFAULT_DEBOUNCE_TIME } from './useInfinityLoadByCursorId';
import { GLOBAL_MESSAGE } from '@configs/constants';
import { useDebounce } from 'use-lodash-debounce';
export type IInifinityLoadInitialFilter = {
  keyword?: string;
  take?: number;
  skip?: number;
  page?: number;
  debounceTime?: number;
};

export type IInfinityLoadFetchFnParams = {
  keyword?: string;
  take?: number;
  skip?: number;
  page?: number;
  pageSize?: number;
};

export type IFetchFnResponse<T> = {
  page: number;
  pageSize: number;
  rows: T[];
  total: number;
  totalPages: number;
};

export type IInfinityLoadFetchFn<T, K> = (
  params: IInfinityLoadFetchFnParams,
  cancelToken?: CancelTokenSource,
) => Promise<IFetchFnResponse<T & K>>;

export type IUseInfinityLoadParams<T, K = void> = {
  fetchFn: IInfinityLoadFetchFn<T, K>;
  initialFilter?: IInifinityLoadInitialFilter;
};

export const useInfinityLoadByPage = <T, K = void>(params: IUseInfinityLoadParams<T, K>) => {
  const isInit = useRef(false);
  const { newCancelTokenSource } = useCancelTokenSource();
  const [searchKeyword, setSearchKeyword] = useState('');
  const debouncedSearchTxt = useDebounce(
    searchKeyword,
    params.initialFilter?.debounceTime ? params.initialFilter?.debounceTime : DEFAULT_DEBOUNCE_TIME,
  );
  const filter = useRef({
    take: params.initialFilter?.take || DEFAULT_API_MAX_TAKE,
    skip: params.initialFilter?.skip,
    page: params.initialFilter?.page ?? 1,
  });
  const hasMore = useRef(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState<(T & K)[]>([]);

  // searching
  useEffect(() => {
    const cancelToken = newCancelTokenSource();
    const searchTagsByKeyword = async () => {
      try {
        // set has more false
        hasMore.current = false;
        // set first loading
        setFirstLoading(true);
        // reset data
        setData([]);
        // reset filter cursorId
        filter.current = {
          ...filter.current,
          page: 1,
        };

        const result = await params.fetchFn(
          {
            keyword: debouncedSearchTxt,
            page: 1,
            take: filter.current.take + 1,
          },
          cancelToken,
        );
        setLoadMoreFilter(result);
        setFirstLoading(false);
        setData((prev) => {
          return [...prev, ...result.rows];
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setFirstLoading(false);
          filter.current = {
            ...filter.current,
            page: 1,
          };
        }
      }
    };

    if (isInit.current) {
      searchTagsByKeyword();
    }
  }, [debouncedSearchTxt, isInit]);

  const setLoadMoreFilter = (data: IFetchFnResponse<T & K>) => {
    // set cursorId and hasmore
    hasMore.current = filter.current.page < data.totalPages;
    filter.current = {
      ...filter.current,
      page: filter.current.page < data.totalPages ? filter.current.page + 1 : filter.current.page,
    };
  };

  const resetFilter = () => {
    hasMore.current = false;
    filter.current = {
      take: params.initialFilter?.take || DEFAULT_API_MAX_TAKE,
      skip: params.initialFilter?.skip,
      page: params.initialFilter?.page ?? 1,
    };
  };

  return {
    searchKeyword,
    data,
    firstLoading,
    errorMessage,
    hasMore: hasMore.current,
    onOpenDropdown: async () => {
      try {
        const cancelToken = newCancelTokenSource();
        resetFilter();
        setFirstLoading(true);
        setData([]);
        const result = await params.fetchFn(
          {
            ...filter.current,
            take: filter.current.take,
            keyword: searchKeyword,
          },
          cancelToken,
        );
        setLoadMoreFilter(result);
        setData(result.rows);
        setFirstLoading(false);
        isInit.current = true;
      } catch (error) {
        if (!axios.isCancel(error)) {
          isInit.current = true;
          console.error('error first load content', error);
          setFirstLoading(false);
          setErrorMessage(GLOBAL_MESSAGE.ERROR_TITLE);
        }
      }
    },
    onLoadmore: async () => {
      try {
        if (!hasMore.current) return;
        // set loading
        const result = await params.fetchFn({
          keyword: searchKeyword,
          page: filter.current.page,
          take: filter.current.take,
        });
        setLoadMoreFilter(result);
        setData((prev) => {
          return [...prev, ...result.rows];
        });
      } catch (error) {
        console.error('error load more content', error);
      }
    },
    onUpdateSearchKeyword: (keyword: string) => {
      setSearchKeyword(keyword);
    },
  };
};
