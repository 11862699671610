import {
  IReasonBE,
  IReasonCategoryBE,
  IReasonCategoryFE,
  IReasonCategoryItemFE,
  IReasonCategoryCreatePayload,
  IReasonCategoryUpdatePayload,
} from '@types';
import { isEqual, set } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMutation } from 'react-query';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTE } from '@configs/route';
import { useEdit } from '@hooks/useEdit';
import { AxiosError } from 'axios';
import { useAlertModal } from '@providers/AlertModalProvider';
import { REASON_CATEGORY_ERROR_MAPPING } from '@configs/wrapup';
import { errorGenerator } from '@utils/errorGenerator';
import { createReasonCategory, fetchReasonCategoryById, updateReasonCategory } from '@api/livechat/Wrapup';

export const useEditor = () => {
  const { onOpen, onUpdateDetail } = useAlertModal();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const { query, updateMute, onUpdate } = useEdit<IReasonCategoryBE, IReasonCategoryUpdatePayload>({
    id,
    fetchFn: async (id) => {
      if (!id) return;
      const result = await fetchReasonCategoryById(id, 200);
      return result;
    },
    updateFn: async (data) => {
      if (!data.id) {
        return;
      }
      await updateReasonCategory(data.id, {
        ...data,
      });
      return data;
    },
  });
  const [reasonCategory, setReasonCategory] = useState<IReasonCategoryFE>({
    name: '',
    reasons: [],
  });
  const [temp, setTemp] = useState<IReasonCategoryFE>(reasonCategory);
  const [keyword, setKeyword] = useState('');
  const createMute = useMutation((payload: IReasonCategoryFE) => {
    const newPayload: IReasonCategoryCreatePayload = {
      name: payload.name,
      reasonIds: payload.reasons.map((item) => item.id),
    };
    return createReasonCategory(newPayload);
  });
  const t = useTranslation();
  const alert = useAlert();
  const isSomethingUpdate = useMemo(() => {
    return !isEqual(reasonCategory, temp);
  }, [temp, reasonCategory]);

  useEffect(() => {
    if (query.data) {
      const transform: IReasonCategoryFE = {
        id: query.data.id,
        name: query.data.name,
        reasons:
          query.data.reasons?.map((item) => {
            return {
              ...item,
              isChecked: false,
            };
          }) ?? [],
      };
      setTemp(transform);
      setReasonCategory(transform);
    }
  }, [query.data]);

  return {
    manage: {
      query,
      updateMute,

      reasonCategory,
      keyword,
      isSomethingUpdate,
      createMute,
      onSave: async () => {
        // create case
        try {
          await createMute.mutateAsync(reasonCategory);
          alert.success(t('reason.category.editor.save.success'), {
            timeout: 2000,
          });
          setTemp(reasonCategory);
          history.replace(ROUTE.TICKET_WRAP_UP);
        } catch (error) {
          console.error('Failed create quick reply collection', error);
          const err = error as Error | AxiosError;
          const errorResult = errorGenerator(err, REASON_CATEGORY_ERROR_MAPPING);
          if (typeof errorResult === 'object') {
            onUpdateDetail(errorResult.title, errorResult.description);
            onOpen();
          } else {
            onOpen();
          }
        }
      },
      onUpdate: async () => {
        // update case
        try {
          if (!reasonCategory.id) return;
          const transfrom: IReasonCategoryUpdatePayload = {
            id: reasonCategory.id,
            name: reasonCategory.name,
            reasonIds: reasonCategory.reasons.map((item) => {
              return item.id;
            }),
          };
          await onUpdate(transfrom);
          alert.success(t('reason.category.editor.update.success'), {
            timeout: 2000,
          });
          // update temp to prevent display unsaved changes
          setTemp(reasonCategory);
          history.replace(ROUTE.TICKET_WRAP_UP);
        } catch (error) {
          console.error('Failed update reason category', error);
          const err = error as Error | AxiosError;
          const errorResult = errorGenerator(err, REASON_CATEGORY_ERROR_MAPPING);
          if (typeof errorResult === 'object') {
            onUpdateDetail(errorResult.title, errorResult.description);
            onOpen();
          } else {
            onOpen();
          }
        }
      },

      onSearch: (keyword: string) => {
        setKeyword(keyword);
      },
      onRemoveSingleReason: (reason: IReasonCategoryItemFE) => {
        setReasonCategory((prev) => {
          return {
            ...prev,
            reasons: prev.reasons.filter((item) => item.id !== reason.id),
          };
        });
      },
      onCheckAll: (e: CheckboxChangeEvent) => {
        setReasonCategory((prev) => {
          return {
            ...prev,
            reasons: prev.reasons.map((item) => {
              return {
                ...item,
                isChecked: e.target.checked,
              };
            }),
          };
        });
      },
      onCheckSingle: (e: CheckboxChangeEvent, record: IReasonCategoryItemFE) => {
        const found = reasonCategory.reasons.findIndex((item) => item.id === record.id);
        if (found === -1) return;
        setReasonCategory((prev) => {
          return {
            ...prev,
            reasons: set(prev.reasons, `[${found}].isChecked`, e.target.checked),
          };
        });
      },
      onDeleteSelected: (outList: IReasonCategoryItemFE[]) => {
        setReasonCategory((prev) => {
          return {
            ...prev,
            reasons: outList,
          };
        });
      },
      onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setReasonCategory((prev) => {
          return {
            ...prev,
            name: e.target.value,
          };
        });
      },
      onAddReasonCategory: (reasons: IReasonBE[]) => {
        setReasonCategory((prev) => {
          return {
            ...prev,
            reasons: reasons.map((item) => {
              return {
                ...item,
                isChecked: false,
              };
            }),
          };
        });
      },
    },
  };
};

export const useModalListSelect = <T>() => {
  const [select, setSelect] = useState<T[]>([]);
  const [showModal, setShowModal] = useState(false);
  return {
    select,
    showModal,
    onToggle: () => {
      setShowModal(!showModal);
    },
    getSetter: () => {
      return setSelect;
    },
  };
};
