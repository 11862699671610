import styled from 'styled-components/macro';
import { Menu as AntdMenu, Divider as AntdDivider, Dropdown as AntdDropdown } from 'antd';
import { ISideBarBehavior } from './context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ShadowMenu = styled.div<{
  windowWidth: number;
  selectedMenu: string;
  collapsed: boolean;
  behavior?: ISideBarBehavior;
}>`
  height: 100vh;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  ${({ windowWidth, selectedMenu, collapsed, behavior }) => {
    return `
      width: ${manageShadowWidth(windowWidth, selectedMenu, collapsed, behavior)}
    `;
  }};
`;

export const InlineSettingIcon = styled(FontAwesomeIcon)``;
export const SettingIcon = styled(FontAwesomeIcon)`
  margin: 16px auto 0;
  color: ${({ theme }) => theme.GREY_6};
  font-size: 18px;
  width: 100% !important;
  cursor: pointer;
`;

export const UserSettingContainer = styled.div``;

export const Menu = styled(AntdMenu)<{ windowWidth: number; selectedMenu: string; inlineCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  background: #212121;
  border-right: none;
  height: 100%;
  width: ${({ inlineCollapsed }) => (inlineCollapsed ? '68px' : '240px')} !important;
  z-index: 99999;
  position: absolute;

  //member avatar
  > :last-child {
    margin: auto 18px 24px 18px;
    ${UserSettingContainer} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      span:nth-child(2) {
        color: ${({ theme }) => theme.WHITE};
        max-width: ${({ inlineCollapsed }) => (inlineCollapsed ? '0' : '100%')};
        overflow: ${({ inlineCollapsed }) => (inlineCollapsed ? 'hidden' : 'initial')};
        font-size: 14px;
        margin-left: ${({ inlineCollapsed }) => (inlineCollapsed ? '0' : '4px')};
        font-weight: 500;
      }
    }
    ${InlineSettingIcon} {
      color: ${({ theme }) => theme.GREY_6};
      font-size: 18px;
      display: ${({ inlineCollapsed }) => (inlineCollapsed ? 'none' : 'block')};
    }
    ${SettingIcon} {
      opacity: ${({ inlineCollapsed }) => (inlineCollapsed ? 1 : 0)};
    }
  }

  span {
    font-size: 14px !important;
  }
  .ant-menu-item::after {
    border-right: transparent !important;
  }
  .ant-menu-submenu-arrow {
    font-size: 20px;
    color: ${({ theme }) => theme.WHITE};
  }
`;

export const Divider = styled(AntdDivider)`
  background: ${({ theme }) => theme.GREY_5};
`;

export const MemberMenu = styled(AntdMenu)`
  width: 300px;
  border-radius: 12px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
  overflow: auto;
  & li:hover {
    background: ${({ theme }) => theme.GREY_9};
    color: initial;
  }
`;

export const MemberNestMenuItem = styled(MemberMenu.Item)<{ circleColor?: string }>`
  height: 44px;
  display: flex !important;
  align-items: center;
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  }
  > div:nth-child(1) {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${({ circleColor }) => circleColor};
    margin-right: 8px;
  }
`;

export const MemberMenuItem = styled(MemberMenu.Item)<{
  circleColor?: string;
  alignMent?: string;
  iconColor?: string;
  labelColor?: string;
}>`
  height: 44px;
  display: flex !important;
  align-items: center;
  justify-content: ${({ alignMent }) => (alignMent ? alignMent : 'flex-start')};
  & :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  }
  & > div:nth-child(1) {
    margin-right: 8px;
    svg {
      color: ${({ iconColor, theme }) => iconColor ?? theme.GREY_1};
    }
  }

  & > div:nth-child(2) span {
    color: ${({ theme, labelColor }) => labelColor ?? theme.GREY_1};
  }
`;

export const MemberSubMenu = styled(MemberMenu.SubMenu)`
  .ant-dropdown-menu-submenu-title {
    height: 44px;
    display: flex !important;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  }
`;

export const MemberProfile = styled.div`
  padding: 8px 12px;
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const Dropdown = styled(AntdDropdown)`
  width: 100%;
  > div {
    min-width: 32px;
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

const manageShadowWidth = (
  windowWidth: number,
  selectedMenu: string,
  collapsed: boolean,
  behavior?: ISideBarBehavior,
) => {
  const selectedMenuLength = selectedMenu.split('/').length;
  if (behavior === 'over') return '68px';
  if (behavior === 'push' && !collapsed) return '240px';
  if (windowWidth > 1280 && !collapsed && selectedMenuLength < 3) return '240px';
  if (windowWidth > 1280 && !collapsed && selectedMenuLength > 3) return '68px';
  return '68px';
};
