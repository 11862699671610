import { isEmpty } from 'lodash';
import { getGeneralConfig } from '@api/livechat/Settings';
import { useMember } from '@providers/MemberProvider';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { IAccountConfig } from '@pages/desktop/settings/GeneralManagement/generalSettingMachine';

export const useSettingHook = () => {
  const { member } = useMember();
  const [setting, setSetting] = useState<IAccountConfig>({} as IAccountConfig);
  const query = useQuery({
    queryKey: 'getSetting',
    queryFn: getGeneralConfig,
    enabled: !isEmpty(member),
    cacheTime: 0,
    onSuccess: (data) => {
      setSetting(data.data);
    },
  });
  const data = query.data;
  const permission = useMemo(() => {
    if (!data)
      return {
        canReply: false,
        canAssign: false,
        canTakeNote: false,
        canManageTicketTag: false,
      };
    const { data: innerData } = data;
    const collaraborationMode = innerData.isCollaborationModeEnabled;
    return {
      canReply: collaraborationMode,
      canAssign: collaraborationMode,
      canTakeNote: collaraborationMode,
      canManageTicketTag: collaraborationMode,
      canTeamFollowUp: collaraborationMode,
    };
  }, [data]);

  return {
    query,
    setting,
    permission,
    onUpdateConfig: (newConfig: IAccountConfig) => {
      setSetting(newConfig);
    },
  };
};
