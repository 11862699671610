import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Font14G1W400, Font14G1W600, Font14G3W500 } from '@components/UtilsComponent';
import { faHeadset, faMedal, faUserShield } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IAssigneeMachineContext } from '@pages/components/Assignee/assigneeMachine';
import { IMember, ITeam, ITicket } from '@types';
import { getFontColor } from '@utils/getRoleColor';
import { twMerge } from 'tailwind-merge';
import SearchInput from '@components/SearchInput';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { IOption } from '@components/Select';
import { SelectBtn } from '@components/SelectBtn';
import { DropdownItem } from './style';
import { chain, cloneDeep } from 'lodash';
import { Checkbox } from '@components/Checkbox';
import './overwrite.css';
import useTranslation from '@hooks/useTranslation';
import { IInternalSelectPeople } from '..';
import { useMember } from '@providers/MemberProvider';
import { ERole } from '@enums/Role';

type IPeopleTabProps = {
  ticket: ITicket;
  internalSelectedAgent: IInternalSelectPeople | null;
  list: IAssigneeMachineContext['peopleDataV2'];
  onSelectAgent: (agent: IMember) => void;
};

export const PeopleTab = ({
  ticket,
  internalSelectedAgent: internalSelected,
  list,
  onSelectAgent,
}: IPeopleTabProps) => {
  const { member, isAdmin } = useMember();
  const [searchText, setSearchText] = useState<string>('');
  const [selectedTeam, setSelectedTeam] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [teamData, setTeamData] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const t = useTranslation();
  useEffect(() => {
    if (!list) return;
    setTeamData(() => {
      const data = chain(list)
        .filter((item) => {
          // if admin return all team
          if (isAdmin()) {
            return true;
          }
          // filter admin out
          if (item.teamId === ERole.ADMIN) {
            return false;
          }

          // filter team that include in membem.teams
          return member.teamId.includes(item.teamId);
        })
        .map((item) => {
          return {
            label: item.teamName,
            value: item.teamId,
          };
        })
        .value();
      return data;
    });
  }, [list]);

  const filterTeam = useMemo(() => {
    return list?.filter((item) => {
      if (selectedTeam.length === 0) return true;

      return !!selectedTeam.find((i) => {
        return i.value === item.teamId;
      });
    });
  }, [searchText, selectedTeam, list]);

  const filterAgents = useMemo(() => {
    const result = chain(filterTeam)
      .map((item) => {
        return {
          ...item,
          agents: item.agents?.filter((agent) => agent.displayName?.toLowerCase().includes(searchText.toLowerCase())),
        };
      })
      .filter((item) => {
        // if admin return all agent
        if (isAdmin()) {
          return true;
        }
        return member.teamId.includes(item.teamId);
      })
      .value();
    return result;
  }, [searchText, filterTeam]);

  const _renderIcon = useCallback((role: string) => {
    if (!role) return null;
    if (role === 'agent') {
      return <FontAwesomeIcon icon={faHeadset} color="white" />;
    }
    if (role === 'supervisor') {
      return <FontAwesomeIcon icon={faMedal} color="white" />;
    }
    // admin
    return <FontAwesomeIcon icon={faUserShield} color="white" />;
  }, []);

  const highlight = (teamId: string, agentId: string) => {
    if (!ticket) return 'bg-transparent';
    // selected admin case
    if (teamId === ERole.ADMIN && ticket.agentId === agentId) {
      return 'border-[#04BE8C] bg-[#e6f8f3] opacity-50';
    }
    // selected agent case
    if (ticket.agentId === agentId && ticket.team === teamId) {
      return 'border-[#04BE8C] bg-[#e6f8f3] opacity-50';
    }
    // admin case
    if (teamId === ERole.ADMIN && !internalSelected?.teamId && agentId === internalSelected?.agentId) {
      return 'border-[#04BE8C] bg-[#e6f8f3]';
    }
    // normal case
    if (internalSelected?.agentId === agentId && internalSelected.teamId === teamId) {
      return 'border-[#04BE8C] bg-[#e6f8f3]';
    }

    return 'bg-transparent';
  };

  const _renderList = useCallback(() => {
    if (!list) return null;
    return filterAgents?.map((team) => {
      const m = twMerge('flex flex-col gap-y-[16px] mt-[16px]', team.agents?.length === 0 ? 'hidden' : '');
      return (
        <div key={team.teamId} className={m}>
          <div className="flex gap-x-[8px]">
            <Font14G3W500>Team</Font14G3W500>
            <Font14G1W600>{team.teamName}</Font14G1W600>
          </div>
          <div className="flex flex-wrap gap-[16px]">
            {team.agents?.map((agent) => {
              const mButton = twMerge(
                'flex p-[12px] rounded-[8px] border border-[#E5E5E5] border-solid w-[216px] overflow-hidden gap-x-[8px] items-center',
                highlight(team.teamId, agent._id),
              );

              return (
                <button
                  key={`${team.teamId}_${agent._id}`}
                  className={mButton}
                  title={agent.displayName}
                  onClick={() => {
                    onSelectAgent({
                      ...agent,
                      teamId: [team.teamId],
                      teams: [
                        {
                          _id: team.teamId,
                        } as ITeam,
                      ],
                    });
                  }}
                >
                  <div>
                    {agent.roles[0] ? (
                      <div
                        style={{
                          background: getFontColor(agent.roles[0]),
                        }}
                        className="w-[32px] h-[32px] bg-[#E5E5E5] rounded-full flex items-center justify-center"
                      >
                        {_renderIcon(agent.roles[0])}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <Font14G1W400 className="overflow-ellipsis w-[150px] block whitespace-nowrap overflow-hidden text-left">
                      {agent.displayName}
                    </Font14G1W400>
                    {agent.roles?.map((role) => {
                      return (
                        <p
                          key={role}
                          className="text-[14px] capitalize font-[400] text-left"
                          style={{
                            color: getFontColor(role),
                          }}
                        >
                          {role}
                        </p>
                      );
                    })}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      );
    });
  }, [internalSelected, filterAgents]);

  const _renderSelectedText = useCallback(
    (
      selected: {
        label: string;
        value: string;
      }[],
    ): React.ReactNode => {
      // if length more than 1
      if (selected.length > 1) {
        return `${selected.length} ${t('assignee.modal.people.tab.more.team.selected')}`;
      }
      if (selected.length === 1) {
        return t('assignee.modal.people.tab.one.team.selected');
      }
      return '';
    },
    [],
  );
  return (
    <div>
      <div className="flex gap-x-[8px] mt-[24px] w-[418px]">
        <SearchInput
          className="min-w-[230px]"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onClear={() => {
            setSearchText('');
          }}
        />
        <DropdownDynamicBtn
          options={teamData}
          hasMore={false}
          showSearch={false}
          className="w-full"
          searchKeyword={''}
          showClearAll={true}
          onClearAllSelection={() => {
            setSelectedTeam([]);
          }}
          dropdownClassName="team-dropdown"
          onLoadMore={() => false}
          onUpdateSearchKeyword={() => false}
          renderBtn={function (
            prevState: boolean,
            setter: (status: React.SetStateAction<boolean>) => void,
          ): React.ReactNode {
            return (
              <SelectBtn
                className="w-full"
                data-testid="test-team-filter-btn"
                selectedText={_renderSelectedText(selectedTeam)}
                placeholder={'All team'}
                onClick={() => {
                  // toggle dropdown
                  setter((prevState) => {
                    return !prevState;
                  });
                }}
              />
            );
          }}
          renderOptions={function (
            options: IOption[],
            setter?: ((status: React.SetStateAction<boolean>) => void) | undefined,
          ): React.ReactNode {
            return options.map((item) => {
              return (
                <DropdownItem
                  key={item.value}
                  onClick={() => {
                    const clone = cloneDeep(selectedTeam);
                    const index = clone.findIndex((i) => {
                      return i.value === item.value;
                    });
                    // check if exist then remove
                    if (index > -1) {
                      clone.splice(index, 1);
                      setSelectedTeam(clone);
                      return;
                    }
                    clone.push(item);
                    setSelectedTeam(clone);
                  }}
                >
                  <span>{item.label}</span>
                  <Checkbox
                    checked={
                      !!selectedTeam.find((i) => {
                        return i.value === item.value;
                      })
                    }
                  />
                </DropdownItem>
              );
            });
          }}
        />
      </div>
      <div className="max-h-[500px] overflow-auto">{_renderList()}</div>
    </div>
  );
};
