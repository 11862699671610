import { deleteDisplayName, updateDisplayName } from '@api/customName';
import { PERMISSION_DENIED, PERMISSION_DENIED_NAME_ERROR, REFRESH_PAGE_BUTTON } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';
import { useAlertModal } from '@providers/AlertModalProvider';
import { useMember } from '@providers/MemberProvider';
import { useSetting } from '@providers/SettingProvider';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';

export const useCustomName = (oldName?: string | null) => {
  const tempNameRef = useRef('');
  const { setting } = useSetting();
  const { isAdmin } = useMember();
  const [name, setName] = useState('');
  const alert = useAlert();
  const t = useTranslation();
  const { onOpen, onUpdateDetail } = useAlertModal();
  const deleteMute = useMutation({
    mutationKey: 'deleteName',
    mutationFn: async (id: string) => {
      await deleteDisplayName(id);
    },
  });
  const updateNameMute = useMutation({
    mutationKey: 'updateName',
    mutationFn: async ({ id, displayName }: { id: string; displayName: string }) => {
      await updateDisplayName(id, displayName);
    },
  });

  useEffect(() => {
    setName(oldName || '');
    tempNameRef.current = oldName || '';
  }, [oldName]);

  return {
    name,
    tempNameRef,
    updateNameMute,
    deleteMute,
    canEdit: isAdmin() || (!isAdmin() && !!setting?.isCustomNameEditorEnabled),
    onSaveName: async (id: string) => {
      // check offline
      if (!navigator.onLine) {
        alert.error(t('general.error.offline'));
        throw new Error('offline');
      }
      try {
        if (!name) {
          await deleteMute.mutateAsync(id);
          return;
        }
        await updateNameMute.mutateAsync({ id, displayName: name.trim() });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const name = error?.response?.data?.name;
          const errorMessage = error?.response?.data?.message;
          if (name === PERMISSION_DENIED_NAME_ERROR) {
            onUpdateDetail(PERMISSION_DENIED, errorMessage.replace(/\((.*?)\)/, ''), REFRESH_PAGE_BUTTON);
            onOpen(() => {
              window.location.reload();
            });
            return;
          }
          // Access to config, request, and response
          alert.error(error?.response?.data?.message ? error?.response?.data?.message : t('general.error.title'));
          return;
        }
        alert.error(t('general.error.title'));
      }
    },
    onChange: (str: string) => {
      // TH/ENG
      // number 0-9
      // not allow special character
      const newText = str.replace(/[^ก-ฺเ-๛| A-Za-z0-9]/g, '');
      setName(newText);
    },
  };
};
