import { AnyEventObject } from 'xstate';
import { IMessageBoxMachineContext } from '../messageBoxMachine';
import { fetchOldAndNewMessages } from './fetchOldAndNewMessages';
import { fetchLatestUserMessage } from './fetchLatestUserMessage';

export const fetchMessageWithTime = async (context: IMessageBoxMachineContext, _: AnyEventObject) => {
  try {
    const { userId, messageTime } = context;
    if (messageTime) {
      context.chatHistory = await fetchOldAndNewMessages(userId, messageTime);
      context.spareHistroy = await fetchLatestUserMessage(userId);
      context.scrollBottom = false;
      return context;
    }
  } catch (error) {
    throw error;
  }
};
