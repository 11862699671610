import React, { createContext, useContext, useRef, useState } from 'react';
import { TertiaryButton } from '@components/Button';
import { AlertModal } from '@components/Modal';
import StateMessage from '@components/StateMessage';
import { Spacing } from '@components/UtilsComponent';
import Exclaimation from '@assets/images/exclaimation.svg';
import { GLOBAL_MESSAGE } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';

export type IAlertModalContext = {
  onOpen: (cb?: () => void) => void;
  onUpdateDetail: (title: string, description: string, okText?: string) => void;
};

export type IAlertModalProvider = {
  children: React.ReactNode;
};

export type IAlertModalDetail = {
  title: string;
  description: string;
  okText?: string;
};

export const AlertModalContext = createContext<IAlertModalContext>({} as IAlertModalContext);

export const useAlertModal = () => useContext(AlertModalContext);

export const AlertModalProvider = ({ children }: IAlertModalProvider) => {
  const [show, setShow] = useState(false);
  const closeCallbackRef = useRef<(() => void) | null>();
  const t = useTranslation();
  const [detail, setDetail] = useState<IAlertModalDetail>({
    title: GLOBAL_MESSAGE.ERROR_TITLE,
    description: GLOBAL_MESSAGE.ERROR_DESCRIPTION,
    okText: t('ok'),
  });
  const onOpen = (cb?: () => void) => {
    setShow(true);
    if (cb) {
      closeCallbackRef.current = cb;
    }
  };

  const onClose = () => {
    setShow(false);
    if (closeCallbackRef.current) {
      closeCallbackRef.current = null;
    }
  };

  const onClickOk = () => {
    setShow(false);
    if (closeCallbackRef.current) {
      closeCallbackRef.current();
      closeCallbackRef.current = null;
    }
  };

  const onUpdateDetail = (title: string, description: string, okText?: string) => {
    setDetail((prev) => {
      return {
        title: title || prev.title,
        description: description || prev.description,
        okText: okText || prev.okText,
      };
    });
  };

  return (
    <AlertModalContext.Provider value={{ onOpen, onUpdateDetail }}>
      <AlertModal visible={show} closable={true} footer={null} destroyOnClose onCancel={onClose}>
        <StateMessage
          img={Exclaimation}
          label={detail.title}
          description={detail.description}
          size="large"
          type="success"
        >
          <Spacing height={16} />
          <TertiaryButton onClick={onClickOk} size="large" data-testid="test-ok-btn">
            {detail.okText}
          </TertiaryButton>
        </StateMessage>
      </AlertModal>
      <AlertModal />
      {children}
    </AlertModalContext.Provider>
  );
};
