import React from 'react';
import { Font14G5W600, Font12G5W400 } from '@components/UtilsComponent';
import { Container, IconContainer } from './style';

export type IEmptyStateProps = {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
};

export const EmptyState: React.FC<IEmptyStateProps> = ({ title, description, icon }: IEmptyStateProps) => {
  return (
    <Container>
      {icon ? <IconContainer>{icon}</IconContainer> : null}
      {title ? (
        <div>
          <Font14G5W600>{title}</Font14G5W600>
        </div>
      ) : null}
      {description ? (
        <div>
          <Font12G5W400>{description}</Font12G5W400>
        </div>
      ) : null}
    </Container>
  );
};
