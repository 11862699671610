import { Typography } from 'antd';
import styled, { keyframes } from 'styled-components/macro';

const pop = keyframes`
  50% {
    transform: scale(1.5);
  }
`;

export const Container = styled.div`
  padding: 24px;
  min-height: 152px;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const TopSection = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: flex-end;
  min-height: 60px;
`;

export const BottomSection = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
  min-height: 36px;
`;

export const BackBtnWrap = styled.div`
  display: inline-block;
  margin-left: 12px;
  padding: 8px 8px 8px 4px;
  column-gap: 8px;
  font-weight: 600;
  svg {
    color: ${({ theme }) => theme.GREY_4};
    margin-right: 8px;
  }
`;

export const TicketAction = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const BookmarkContainer = styled.div<{ followUp: boolean }>`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  :hover {
    background: ${({ theme }) => theme.GREY_9} !important;
    svg {
      color: ${({ followUp, theme }) => !followUp && theme.GREY_2} !important;
    }
  }
  button {
    padding: 8px;
    width: 100%;
    height: 100%;

    svg {
      font-weight: 400;
      font-size: 16px;
      color: ${({ theme, followUp }) => (followUp ? theme.PRIMARY : theme.GREY_5)};
    }
  }
  & button:active {
    svg {
      animation: ${pop} 0.5s linear;
    }
  }
`;

export const TextWrap = styled.div`
  display: flex;
  align-items: center;

  span:nth-child(2) {
    margin-left: 4px;
    color: ${({ theme }) => theme.GREY_1};
  }
`;

export const Seperator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.GREY_7};
  height: 100%;
`;

export const TicketDetail = styled.div`
  display: flex;
  margin-right: 60px;
`;

export const ActionWrap = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
`;

export const AssigneeWrap = styled.div``;

export const StatusWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const TagsWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const AlertWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-weight: 600;
    color: white;
  }
`;

export const LabelText = styled(Typography.Text)`
  min-width: 64px;
`;

export const ValueText = styled(Typography.Text)`
  white-space: nowrap;
`;

export const TagContent = styled.div`
  width: calc(100vw - 1030px);
`;
