import { client } from '@api';
import { ICustomField, TicketHistory } from '@types';

export const getTicketsByUserId = async (userId: string, status?: string, survey?: boolean) => {
  const { data }: { data: TicketHistory } = await client.get(`/api/tickets/user/${userId}`, {
    params: {
      status,
      survey,
    },
  });
  return data;
};

export const getAudienceByUserId = async (userId: string) => {
  const { data }: { data: ICustomField[] } = await client.get(`/api/users/${userId}/custom-fields`);
  return data;
};
