import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { ECustomerSatisfactionValue, ISurvey, ISurveyPayload, NestedKeyOf, NestedValueOf } from '@types';
import { v4 as uuid } from 'uuid';
import { useMutation, useQuery } from 'react-query';
import { createSurvey, getChannelSurveyById, updateSurvey } from '@api/survey';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import EChannelType from '@enums/ChannelType';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { convertMinToMs, convertMsToMin } from '@utils/time';
import { ROUTE } from '@configs/route';

export type IUpdateSurveyFnSignature = <K extends NestedKeyOf<ISurvey>>(
  key: K,
  value: NestedValueOf<ISurvey, K>,
) => void;

export const IMAGE_MAP_DIMISION = {
  WIDTH: 1040, //px
  HEIGHT: 1040, //px
};

const initialSurvey: ISurvey = {
  channelId: '',
  networkId: '',
  type: ECustomerSatisfactionValue.quickReply,
  channel: '',
  isEnable: false,
  message: {
    id: uuid(),
    question: '',
    feedbackMessage: {
      id: uuid(),
      question: '',
      options: [
        {
          id: uuid(),
          label: 'Yes',
          value: true,
        },
        {
          id: uuid(),
          label: 'No',
          value: false,
        },
      ],
    },
    imageMap: {
      id: uuid(),
      imgUrl: '',
      actions: [],
      baseSize: {
        width: IMAGE_MAP_DIMISION.WIDTH,
        height: IMAGE_MAP_DIMISION.HEIGHT,
      },
      baseUrl: '',
      imgHeight: IMAGE_MAP_DIMISION.WIDTH,
      imgWidth: IMAGE_MAP_DIMISION.HEIGHT,
    },
    options: [
      {
        id: uuid(),
        label: '',
        point: '1',
      },
      {
        id: uuid(),
        label: '',
        point: '2',
      },
    ],
    isFeedback: false,
    thxMessage: '',
  },
  modifiedBy: '',
  timeoutMS: 0,
  createdAt: '',
  updatedAt: '',
};

const getFeedbackMessage = (survey: ISurvey) => {
  return survey.message.isFeedback
    ? {
        id: survey.message.feedbackMessage!.id,
        question: survey.message.feedbackMessage!.question || '',
        guideline: survey.message.feedbackMessage!.guideline || '',
        options: survey.message.feedbackMessage!.options.map((item) => {
          return {
            id: item.id || uuid(),
            label: item.label,
            value: item.value,
          };
        }),
      }
    : undefined;
};

const getFeedbackMessageFE = (survey: ISurvey) => {
  if (survey.message.isFeedback && survey.message.feedbackMessage) {
    return {
      id: survey.message.feedbackMessage.id,
      question: survey.message.feedbackMessage.question,
      guideline: survey.message.feedbackMessage.guideline,
      options: survey.message.feedbackMessage.options.map((item) => {
        return {
          id: item.id,
          label: item.label,
          value: item.value,
        };
      }),
    };
  }

  return cloneDeep(initialSurvey.message.feedbackMessage);
};

const getPayload = (survey: ISurvey, isEnabled: boolean, channelId: string): ISurveyPayload => {
  if (survey.type === ECustomerSatisfactionValue.quickReply) {
    return {
      channelId,
      message: {
        ...survey.message,
        feedbackMessage: getFeedbackMessage(survey),
        options: survey.message.options.map((item) => {
          return {
            ...item,
            point: Number(item.point),
          };
        }),
        imageMap: undefined,
      },
      isEnabled,
      type: survey.type,
      timeoutMS: convertMinToMs(survey.timeoutMS),
    };
  } else if (survey.type === ECustomerSatisfactionValue.externalLink) {
    return {
      channelId,
      message: {
        id: survey.message.id,
        externalLink: survey.message.externalLink,
        isFeedback: survey.message.isFeedback,
        imageMap: undefined,
      },
      isEnabled,
      type: survey.type,
    };
  } else {
    // imagemap
    return {
      channelId,
      message: {
        id: survey.message.id,
        externalLink: undefined,
        imageMap: survey.message.imageMap
          ? {
              ...survey.message.imageMap,
              actions: survey.message.imageMap!.actions.map((item) => {
                return {
                  ...item,
                  id: item.id,
                  point: Number(item.point),
                };
              }),
            }
          : undefined,
        isFeedback: survey.message.isFeedback,
        feedbackMessage: getFeedbackMessage(survey),
        thxMessage: survey.message.thxMessage,
      },
      timeoutMS: convertMinToMs(survey.timeoutMS),
      isEnabled,
      type: survey.type,
    };
  }
};

export const useCustomerSatisfactionEditor = () => {
  const surveyTempRef = useRef<ISurvey>(cloneDeep(initialSurvey));
  const [survey, setSurvey] = useState<ISurvey>(cloneDeep(initialSurvey));
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const alert = useAlert();
  const location = useLocation<{
    channelId: string;
    channelName: string;
    channelType: EChannelType;
  }>();

  const createMute = useMutation(
    (payload: ISurveyPayload) => {
      return createSurvey(payload);
    },
    {
      onSuccess: (data) => {
        if (data.isEnabled) {
          alert.success(t('customer.satisfaction.editor.save.enable.success'));
        } else {
          alert.success(t('customer.satisfaction.editor.save.no.enable.success'));
        }
        history.replace(ROUTE.CUSTOMER_SATISFACTION);
      },
    },
  );

  const updateMute = useMutation(
    (payload: ISurveyPayload) => {
      if (!id) return Promise.reject('Survey ID is required');
      return updateSurvey(id, payload);
    },
    {
      onSuccess: (data) => {
        if (data.isEnabled) {
          alert.success(t('customer.satisfaction.editor.save.enable.success'));
        } else {
          alert.success(t('customer.satisfaction.editor.save.no.enable.success'));
        }
        history.replace(ROUTE.CUSTOMER_SATISFACTION);
      },
    },
  );

  const surveyFetch = useQuery(
    ['getChannelSuryveryById', id],
    () => {
      if (!location.state?.channelId || !id) return;
      return getChannelSurveyById(location.state.channelId, id);
    },
    {
      enabled: !!location.state?.channelId && !!id,
      retry: false,
      onSuccess: (data: ISurvey) => {
        if (data.type === ECustomerSatisfactionValue.quickReply) {
          const survey: ISurvey = {
            ...data,
            message: {
              ...initialSurvey.message,
              ...data.message,
              feedbackMessage: data.message.isFeedback
                ? data.message.feedbackMessage
                : initialSurvey.message.feedbackMessage,
              options: data.message.options.map((item) => {
                return {
                  ...item,
                  point: item.point.toString(),
                };
              }),
            },
            timeoutMS: convertMsToMin(data.timeoutMS || 0),
          };
          surveyTempRef.current = cloneDeep(survey);
          setSurvey(survey);
        } else if (data.type === ECustomerSatisfactionValue.externalLink) {
          const survey: ISurvey = {
            ...data,
            message: {
              ...initialSurvey.message,
              ...data.message,
              question: '',
              feedbackMessage: getFeedbackMessageFE(data),
              options: cloneDeep(initialSurvey.message.options),
            },
            timeoutMS: convertMsToMin(data.timeoutMS || 0),
          };

          surveyTempRef.current = cloneDeep(survey);
          setSurvey(survey);
        } else {
          // imagemap
          const survey: ISurvey = {
            ...data,
            message: {
              ...initialSurvey.message,
              ...data.message,
              feedbackMessage: data.message.isFeedback
                ? data.message.feedbackMessage
                : initialSurvey.message.feedbackMessage,
              thxMessage: data.message.thxMessage || '',
              imageMap: {
                ...initialSurvey.message.imageMap,
                ...data.message.imageMap,
                id: data.message.imageMap?.id ?? uuid(),
                baseUrl: data.message.imageMap?.imgUrl ?? '',
                imgUrl: data.message.imageMap?.imgUrl ?? '',
                imgHeight: data.message.imageMap?.imgHeight ?? IMAGE_MAP_DIMISION.HEIGHT,
                imgWidth: data.message.imageMap?.imgWidth ?? IMAGE_MAP_DIMISION.WIDTH,
                baseSize: {
                  width: IMAGE_MAP_DIMISION.WIDTH,
                  height: IMAGE_MAP_DIMISION.HEIGHT,
                },
                actions:
                  data.message.imageMap?.actions.map((item) => {
                    return {
                      ...item,
                      id: item.id ?? uuid(),
                      point: item.point.toString(),
                    };
                  }) ?? [],
              },
            },
            timeoutMS: convertMsToMin(data.timeoutMS || 0),
          };
          surveyTempRef.current = cloneDeep(survey);
          setSurvey(survey);
        }
      },
      onError: (error) => {
        console.error(error);
        alert.error(t('general.error.title'));
      },
    },
  );
  const t = useTranslation();
  const [showPrevew, setShowPrevew] = useState<boolean>(false);

  const isSomethingUpdate = useMemo(() => {
    return !isEqual(survey, surveyTempRef.current);
  }, [survey, surveyTempRef.current]);

  const setNestedValue = (obj: any, path: string, value: any): any => {
    const keys = path.split('.');
    const lastKey = keys.pop()!;
    const lastObj = keys.reduce((obj, key) => {
      return (obj[key] = obj[key] || {});
    }, obj);

    lastObj[lastKey] = value;

    return { ...obj };
  };

  const getDisabledSaveButton = useMemo(() => {
    if (survey.type === ECustomerSatisfactionValue.quickReply) {
      const hasFeedbackButOptionsEmpty =
        survey.message.isFeedback && survey.message.feedbackMessage?.options.some((option) => !option.label);

      return (
        !survey.message.question ||
        survey.message.options.some((option) => !option.label) ||
        !survey.message.thxMessage ||
        hasFeedbackButOptionsEmpty
      );
    }
    if (survey.type === ECustomerSatisfactionValue.externalLink) {
      return !survey.message.externalLink;
    }
    if (survey.type === ECustomerSatisfactionValue.imagemap) {
      const hasFeedbackButOptionsEmpty =
        survey.message.isFeedback && survey.message.feedbackMessage?.options.some((option) => !option.label);
      const validateImageMap =
        !survey.message.imageMap?.imgUrl ||
        isEmpty(survey.message.imageMap?.actions) ||
        survey.message.imageMap?.actions.some((action) => !action.point || !action.message);
      return validateImageMap || !survey.message.thxMessage || hasFeedbackButOptionsEmpty;
    }
    return false;
  }, [survey]);

  return {
    surveyId: id,
    survey,
    showPrevew,
    isDisabledSave: getDisabledSaveButton,
    routeState: {
      channelId: location.state?.channelId || '',
      channelName: location.state?.channelName || '',
      channelType: location.state?.channelType || '',
    },
    isSomethingUpdate,
    surveyFetch,
    createMute,
    updateMute,
    setShowPrevew,
    onCreate: async (isEnable: boolean) => {
      try {
        const newPayload: ISurveyPayload = getPayload(survey, isEnable, location.state.channelId);
        surveyTempRef.current = cloneDeep(survey);
        await createMute.mutateAsync(newPayload);
      } catch (error) {
        console.error(error);
        alert.error(t('general.error.title'));
      }
    },
    onUpdate: async (isEnable: boolean) => {
      try {
        const newPayload: ISurveyPayload = getPayload(survey, isEnable, location.state.channelId);
        surveyTempRef.current = cloneDeep(survey);
        await updateMute.mutateAsync(newPayload);
      } catch (error) {
        console.error(error);
        alert.error(t('general.error.title'));
      }
    },
    onUpdateSurvey: <K extends NestedKeyOf<ISurvey>>(key: K, value: NestedValueOf<ISurvey, K>) => {
      setSurvey((prev) => setNestedValue({ ...prev }, key, value));
    },
  };
};
