export const setCustomCookie = (name: string, value: string) => {
  if (!value || !name) return;
  document.cookie = `${name}=${value}; domain=${getDomain()}; path=/;`;

  document.cookie = `${name}=${value}; domain=${getDomain()}; path=/;`;
};

export const getCustomCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
};

export const getDomain = () => {
  return process.env.NODE_ENV === 'production' ? `${import.meta.env.VITE_APP_COGNITO_AUTH_COOKIE_DOMAIN}` : 'localhost';
};

export const clearAllCookie = () => {
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};
