import React from 'react';
import { Card } from '@components/Card';
import { FetchWrap } from '@components/FetchWrap';
import { Percent } from '@components/Percent';
import { Divider, Font12G5W400, Font32G1W500 } from '@components/UtilsComponent';
import { faTicketAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { getPercentChange } from '@utils/percent';
import { IFilterState } from '../useDashboardFilter';
import { getStatusTicketAnalytic } from '@api/livechat/Analytic';
import { IStatusTicketResponse } from '@types';
import { CardContent } from '@components/CardWrap';

type IStatusTicketProps = {
  filter: IFilterState;
};

const calculator = (
  targetPeriod?: IStatusTicketResponse['targetPeriod'],
  previousPeriod?: IStatusTicketResponse['previousPeriod'],
) => {
  if (!targetPeriod || !previousPeriod)
    return {
      overallTarget: 0,
      overallPercent: 0,
      unAssigned: 0,
      unAssignPercent: 0,
      assigned: 0,
      assignedPercent: 0,
      resolved: 0,
      resolvedPercent: 0,
    };
  const overallTarget = targetPeriod.opened + targetPeriod.assigned + targetPeriod.resolved;
  const overallPrevious = previousPeriod.opened + previousPeriod.assigned + previousPeriod.resolved;
  const unAssigned = targetPeriod.opened;
  const assigned = targetPeriod.assigned;
  const resolved = targetPeriod.resolved;
  const overallPercent = getPercentChange(overallTarget, overallPrevious);
  const unAssignPercent = getPercentChange(targetPeriod.opened, previousPeriod.opened);
  const assignedPercent = getPercentChange(targetPeriod.assigned, previousPeriod.assigned);
  const resolvedPercent = getPercentChange(targetPeriod.resolved, previousPeriod.resolved);

  return {
    overallTarget,
    overallPercent,
    unAssigned,
    unAssignPercent,
    assigned,
    assignedPercent,
    resolved,
    resolvedPercent,
  };
};

export const StatusTicket = ({ filter }: IStatusTicketProps) => {
  const t = useTranslation();
  return (
    <FetchWrap<IStatusTicketResponse, IFilterState>
      filter={filter}
      queryKey={['status-ticket', filter]}
      fetchFn={async (filter: IFilterState, cancelToken): Promise<IStatusTicketResponse> => {
        const { data }: { data: IStatusTicketResponse } = await getStatusTicketAnalytic({
          agents: filter.assignees,
          channels: filter.channels,
          tags: filter.tags,
          teams: filter.teams,
          startIsoStringDate: filter.startIsoStringDate.toISOString(),
          endIsoStringDate: filter.endIsoStringDate.toISOString(),
          cancelToken,
        });

        return {
          targetPeriod: {
            opened: data.targetPeriod.opened ?? 0,
            assigned: data.targetPeriod.assigned ?? 0,
            resolved: data.targetPeriod.resolved ?? 0,
          },
          previousPeriod: {
            opened: data.previousPeriod.opened ?? 0,
            assigned: data.previousPeriod.assigned ?? 0,
            resolved: data.previousPeriod.resolved ?? 0,
          },
        };
      }}
      renderFn={function ({
        isLoading,
        data,
        isError,
        refetch,
      }: {
        isLoading: boolean;
        data?: IStatusTicketResponse;
        isError: boolean;
        refetch: () => void;
      }): JSX.Element {
        const {
          overallTarget: overall,
          overallPercent,
          unAssigned,
          unAssignPercent,
          assigned,
          assignedPercent,
          resolved,
          resolvedPercent,
        } = calculator(data?.targetPeriod, data?.previousPeriod);
        return (
          <Card
            minHeight="420px"
            maxHeight="420px"
            divider
            title={t('status.ticket.title')}
            description={t('status.ticket.description')}
            topLeftIcon={<FontAwesomeIcon icon={faTicketAlt} />}
          >
            <div className="p-[24px]">
              <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                <div className="flex flex-col items-center gap-y-[12px]">
                  <Font12G5W400>{t('status.ticket.overall')}</Font12G5W400>
                  <Font32G1W500>{overall}</Font32G1W500>
                  <Percent num={overallPercent} />
                </div>
                <Divider className="my-[8px]" />
                <div className="flex gap-x-[16px]">
                  <div className="flex flex-col flex-1 items-center border-r border-r-[#E5E5E5] border-solid">
                    <Font12G5W400>{t('status.ticket.unassign')}</Font12G5W400>
                    <Font32G1W500>{unAssigned}</Font32G1W500>
                    <Percent num={unAssignPercent} />
                  </div>
                  <div className="flex flex-col flex-1 items-center border-r border-r-[#E5E5E5] border-solid">
                    <Font12G5W400>{t('status.ticket.assigned')}</Font12G5W400>
                    <Font32G1W500>{assigned}</Font32G1W500>
                    <Percent num={assignedPercent} />
                  </div>
                  <div className="flex flex-col flex-1 items-center">
                    <Font12G5W400>{t('status.ticket.resolved')}</Font12G5W400>
                    <Font32G1W500>{resolved}</Font32G1W500>
                    <Percent num={resolvedPercent} />
                  </div>
                </div>
              </CardContent>
            </div>
          </Card>
        );
      }}
    />
  );
};
