export type IDefaultFetchParam = {
  max: number;
  keyword?: string;
  nextCursorId?: string;
  prevCursorId?: string;
  currentPage: number;
};

export type ITableListGeneratorParams<T, P> = {
  behavior?: 'next' | 'prev' | 'none';
  fetchParams: (IDefaultFetchParam & P) | IDefaultFetchParam;
  fetchFn: (params: IFetchParams) => Promise<T[]>;
};

export type IFetchParams = {
  skip?: number;
  take?: number;
  cursorId?: string;
  keyword?: string;
};

export const tableListGeneratorV2 = async <T extends { id: string }, P = undefined>({
  behavior = 'none',
  fetchParams,
  fetchFn,
}: ITableListGeneratorParams<T, P>) => {
  try {
    if (behavior === 'none') {
      const result = await fetchFn({
        ...fetchParams,
        keyword: fetchParams.keyword,
        take: fetchParams.max,
      });
      const data = tableParamsGenerator.next(result, 1, fetchParams.max);
      return data;
    }
    if (behavior === 'next') {
      const result = await fetchFn({
        ...fetchParams,
        keyword: fetchParams.keyword,
        take: fetchParams.max,
        cursorId: fetchParams.nextCursorId,
      });
      const data = tableParamsGenerator.next(result, fetchParams.currentPage + 1, fetchParams.max);

      return data;
    }
    //only false,
    //undefined, null is not include
    if (behavior === 'prev') {
      const result = await fetchFn({
        ...fetchParams,
        keyword: fetchParams.keyword,
        take: -fetchParams.max,
        cursorId: fetchParams.prevCursorId,
      });

      const data = tableParamsGenerator.prev(result, fetchParams.currentPage - 1);

      return data;
    }
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const tableParamsGenerator = {
  prev: <T extends { id: string }>(inp: T[], currentPage: number) => {
    return {
      items: inp,
      currentPage,
      nextCursorId: inp[inp.length - 1].id,
      prevCursorId: inp[0].id,
      disabledNext: false,
    };
  },
  next: <T extends { id: string }>(inp: T[], currentPage: number, max: number) => {
    const hasNextPage = inp.length === max;

    if (hasNextPage) {
      return {
        items: inp,
        currentPage,
        nextCursorId: inp[inp.length - 1].id,
        prevCursorId: inp[0].id,
        disabledNext: !hasNextPage,
      };
    }
    // data length less than max take but more than 0
    if (inp.length > 0) {
      return {
        items: inp,
        currentPage,
        nextCursorId: '', // this mean no next page
        prevCursorId: inp[0].id,
        disabledNext: !hasNextPage,
      };
    }
    // data length is 0
    return {
      items: inp,
      currentPage,
      nextCursorId: undefined,
      prevCursorId: undefined,
      disabledNext: !hasNextPage,
    };
  },
};
