import { FILE_TYPE } from '@configs/fileType';

/**
 * determine input mime is image or not
 *
 * @param {String} mimeType mime type of file
 *
 * @returns {Boolean} is current image in boolean
 */
const isImageByMime = (mimeType: string) => {
  // except photoshop file
  if (mimeType === FILE_TYPE.PHOTOSHOP) {
    return false;
  }
  return !!mimeType.match('image.*');
};

export default isImageByMime;
