import { EAgentStatus } from '@enums/AgentStatus';
import { ISideBarBehavior } from './context';

export const DEPTH_RATIO = 5;
export const SIDEBAR_BEHAVIOR: ISideBarBehavior = 'over';
export const DEFAULT_PATH = '/recents';
export const DEFAULT_CALLPASED = true;
export const AGENT_STATUS = [
  {
    value: EAgentStatus.DUTY,
    label: 'Online',
    color: '#29CB72',
  },
  {
    value: EAgentStatus.BUSY,
    label: 'Busy',
    color: '#F72C40',
  },
  {
    value: EAgentStatus.UNAVAILABLE,
    label: 'Unavailable',
    color: '#757575',
  },
];
