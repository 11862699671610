import { Col } from 'antd';
import styled from 'styled-components/macro';

export const Container = styled.div``;

export const UserProfile = styled(Col)`
  width: 300px;
`;

export const MiddlePane = styled(Col)`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.GREY_7};
`;

export const ResolveActionWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
`;
