import { getRecentTicketByUserId } from '@api/livechat/EngagementMessage';
import { useQuery } from 'react-query';

export const useTicket = (userId: string) => {
  const ticketFetch = useQuery(['getRecentTicketByUserId', userId], () => getRecentTicketByUserId(userId), {
    retry: 0,
  });
  return {
    ticketFetch,
  };
};
