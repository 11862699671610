import styled from 'styled-components/macro';
import { Row } from 'antd';

export const Container = styled.div`
  width: 100%;
`;

export const Inner = styled(Row)`
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 40px;
`;

export const TestBeforeAfter = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid red;
  position: relative;
  :before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    right: -10px;
    background: green;
  }
`;

export const LoadingWrap = styled.div`
  position: initial !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
