import { BOTS } from '@types';
import { useState } from 'react';

export const useComposerManager = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<BOTS.ITemplateApi | null>(null);
  const [showTemplateComposer, setShowTemplateComposer] = useState(false);
  return {
    preview: {
      selectedTemplate,
      onSelectTemplate: (template: BOTS.ITemplateApi | null) => {
        setSelectedTemplate(template);
      },
    },
    composer: {
      showTemplateComposer,
      onToggleComposer: () => {
        setShowTemplateComposer(!showTemplateComposer);
      },
    },
  };
};
