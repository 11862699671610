import { AnyEventObject } from 'xstate';
import {
  getMembers as getMembersApi,
  getMemberById as getMemberByIdApi,
  getMemberBySearch as getMemberBySearchApi,
  getAvailableMembers as getAvailableMembersApi,
  getAvailableMembersBySearch as getAvailableMembersBySearchApi,
} from '@api/livechat/TeamManagement';
import { IControllerContext } from '../TeamManagementPane/teamManagementMachine';
import { INewTeamEditorContext } from '../NewTeamEditor/newTeamEditorMachine';
import { IEditTeamEditorContext } from '../EditTeamEditor/editTeamEditorMachine';
import { MEMBER_LIST_PAGE_SIZE } from '@configs/constants';

export const getAllMembers = (context: IControllerContext, event: AnyEventObject) => {
  const { searchTxt, selectedRole, memberCurrentPage } = context;
  if (searchTxt) {
    return getMemberBySearchApi(searchTxt, selectedRole, memberCurrentPage);
  }
  return getMembersApi(selectedRole, memberCurrentPage);
};

export const getAllBotMembers = (context: INewTeamEditorContext | IEditTeamEditorContext, event: AnyEventObject) => {
  const { searchTxt } = context;
  if (searchTxt) {
    getMemberBySearchApi(searchTxt, 'bot', 1, 100);
  }
  return getMembersApi('bot', 1, 100);
};

export const getMemberById = (id: string) => {
  return getMemberByIdApi(id);
};

export const getAvailableMembers = (context: INewTeamEditorContext, event: AnyEventObject) => {
  const { searchTxt } = context;
  const { page } = event;
  if (searchTxt) {
    return getAvailableMembersBySearchApi(searchTxt, page);
  }
  return getAvailableMembersApi(page);
};

export const getAvailableMembersIncludeTeam = (context: IEditTeamEditorContext, event: AnyEventObject) => {
  const { searchTxt, teamId } = context;
  const { page } = event;
  if (searchTxt) {
    return getAvailableMembersBySearchApi(searchTxt, 1, MEMBER_LIST_PAGE_SIZE, [teamId]);
  }
  return getAvailableMembersApi(page, MEMBER_LIST_PAGE_SIZE, [teamId]);
};
