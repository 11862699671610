import { AnyEventObject } from 'xstate';
import { UserListInvokeEvent } from '../userListMachine.type';

export function isUserListInvokeEvent(event: AnyEventObject): event is UserListInvokeEvent {
  if (event.data) {
    return true;
  } else {
    return false;
  }
}
