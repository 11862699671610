import { useAlertModal } from '@providers/AlertModalProvider';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';

export type IUseRowEditParams<T> = {
  successTxt?: string;
  errorMapping?: {
    [key: string]: {
      title: string;
      description: string;
    };
  };
  updateFn: (payload: T) => Promise<any>;
};

export type ISelectedState<T> = {
  index: number | null;
  value: T | null;
};

export const useRowEdit = <T>({ successTxt, errorMapping, updateFn }: IUseRowEditParams<T>) => {
  const [selected, setSelected] = useState<ISelectedState<T>>({
    index: null,
    value: null,
  });
  const { onOpen, onUpdateDetail } = useAlertModal();
  const updateMute = useMutation(updateFn);

  const alert = useAlert();
  return {
    selected,
    updateMute,
    onSelectEdit: (payload: ISelectedState<T>) => {
      setSelected(payload);
    },
    onUpdateSelected: (onUpdateState: (setter: React.Dispatch<React.SetStateAction<ISelectedState<T>>>) => void) => {
      onUpdateState(setSelected);
    },
    onConfirmEdit: async (cb: () => Promise<void>) => {
      try {
        if (!selected.value) return;
        await updateMute.mutateAsync(selected.value);
        alert.success(successTxt ?? 'Success updating');
        await cb();
        setSelected({ index: null, value: null });
      } catch (error) {
        const err = error as AxiosError;
        const errorMessage = err.response?.data?.message ? (err.response?.data?.message as string) : '';
        if (errorMessage && errorMapping?.[errorMessage]) {
          onUpdateDetail(
            errorMapping[err.response?.data?.message].title,
            errorMapping[err.response?.data?.message].description,
          );
        }
        onOpen();
      }
    },
    onCancelEdit: () => {
      setSelected({ index: null, value: null });
    },
  };
};
