import { cloneDeep } from 'lodash';
import { AnyEventObject, assign, createMachine } from 'xstate';
import { IRegion } from '.';
import { v4 as uniqid } from 'uuid';
import { filterSmallRegionOut } from './fns';

export const imageMapMachine = (imageMapData: any) => {
  return createMachine(
    {
      id: 'imageMapMachine',
      initial: 'idle',
      context: {
        imageMap: imageMapData,
        regions: [] as IRegion[],
        showPopup: false,
        errorMessage: '',
      },
      states: {
        idle: {},
        blankMode: {},
        imageMode: {
          initial: 'idle',
          states: {
            idle: {},
            changeMedia: {},
            mapImage: {},
            hovering: {},
          },
          on: {
            CHANGE_MEDIA: {
              target: '.changeMedia',
            },
            UPDATE_REGIONS: {
              actions: 'updateRegions',
            },
            MAP_IMAGE: {
              target: '.mapImage',
            },
            MAPPING_IMAGE: {
              actions: 'updateImageMap',
            },
            UPDATE_ACTION_POPUP: {
              actions: 'updateActionPopupItem',
            },
            CONFIRM_MAP_ACTION: {
              target: '.idle',
              actions: ['updateMapAction', 'updateErrorMessage'],
            },
            CANCEL_MAP_ACTION: {
              target: '.idle',
              actions: ['undoBeforeAction', 'resetErrorMessage'],
            },
            HOVERING: {
              target: '.hovering',
            },
            IDLE: {
              target: '.idle',
            },
            SUMBIT_INTERNAL_REGION_ACTION: {
              actions: ['submitInternalRegionAction', 'updateErrorMessage'],
            },
            CANCEL_INTERNAL_REGION_ACTION: {
              actions: 'cancelInternalRegionAction',
            },
            REMOVE_INTERNAL_REGION_ACTION: {
              actions: 'removeInternalRegionAction',
            },
            INVALIDATE_MAP_ACTION: {
              actions: 'updateErrorMessage',
            },
            UPDATE_REGION_ERROR: {
              actions: 'updateRegionError',
            },
          },
        },
      },
      on: {
        BLANK_MODE: 'blankMode',
        IMAGE_MODE: 'imageMode',
        UPDATE_SHOW_POPUP: {
          actions: 'updateShowpopup',
        },
      },
    },
    {
      actions: {
        updateShowpopup: assign({
          showPopup: (_context, event: AnyEventObject) => event.showPopup,
        }),
        updateActionPopupItem: assign({
          regions: (context, event: AnyEventObject) => {
            const { regions } = context;
            const { index } = event;
            regions[index].data.btn.isPopover = !regions[index].data.btn.isPopover;
            context.regions = regions;
            return context.regions;
          },
        }),
        updateRegionError: assign({
          regions: (context, event: AnyEventObject) => {
            const { newRegions } = event;
            context.regions = cloneDeep(newRegions);
            return context.regions;
          },
        }),
        updateImageMap: assign({
          regions: (_context, event: AnyEventObject) => {
            const { regions } = event;
            if (regions.length > 0 && regions[regions.length - 1].new) {
              const lastArea = regions[regions.length - 1];
              const region: IRegion = {
                ...lastArea,
                data: {
                  index: lastArea.data.index,
                  btn: {
                    id: uniqid(),
                    message: '',
                    point: undefined,
                    isPopover: false,
                    area: {
                      height: lastArea.height,
                      width: lastArea.width,
                      x: lastArea.x,
                      y: lastArea.y,
                    },
                  },
                },
              };
              regions.splice(regions.length - 1, 1, region);
              return regions;
            }

            //filter small region out
            const newRegions = regions.filter((region: IRegion) =>
              filterSmallRegionOut({
                region,
                width: 4,
                height: 4,
              }),
            );
            return newRegions;
          },
        }),
        updateMapAction: assign({
          imageMap: (context, event: AnyEventObject) => {
            const regions = event.regions as IRegion[];
            const newImageMap: any = {
              ...context.imageMap,
              actions: regions.map((region) => {
                const { area, ...rest } = region.data.btn;
                return {
                  ...rest,
                  area: {
                    height: region.height,
                    width: region.width,
                    x: region.x,
                    y: region.y,
                  },
                };
              }),
            };
            return newImageMap;
          },
        }),
        undoBeforeAction: assign({
          regions: (context, _event: AnyEventObject) => {
            if (context.imageMap.actions.length === 0) {
              return [];
            }
            const newRegions: IRegion[] = context.imageMap.actions.map((action: any) => {
              return {
                isChanging: false,
                new: false,
                width: action.area.width,
                height: action.area.height,
                x: action.area.x,
                y: action.area.y,
                data: {
                  btn: {
                    ...action,
                  },
                },
              };
            });
            return newRegions;
          },
        }),
        submitInternalRegionAction: assign({
          regions: (context, event: AnyEventObject) => {
            const { message, point, area, index } = event;
            context.regions[index].data.btn = {
              area,
              message,
              point,
              isPopover: false,
            };
            return context.regions;
          },
        }),
        cancelInternalRegionAction: assign({
          regions: (context, event: AnyEventObject) => {
            const { index } = event;
            context.regions[index].data.btn = {
              ...context.regions[index].data.btn,
              isPopover: false,
            };
            return context.regions;
          },
        }),
        removeInternalRegionAction: assign({
          regions: (context, event: AnyEventObject) => {
            const { index } = event;
            context.regions.splice(index, 1);
            return context.regions;
          },
        }),
        updateRegions: assign({
          regions: (context, event: AnyEventObject) => {
            const regions: IRegion[] = event.actions.map((item: any) => {
              const region = {
                height: item.area.height,
                width: item.area.width,
                x: item.area.x,
                y: item.area.y,
                new: false,
                data: {
                  btn: item,
                },
                isChanging: false,
              };
              return region;
            });
            context.regions = regions;
            return context.regions;
          },
        }),
        updateErrorMessage: assign({
          errorMessage: (_context, event: AnyEventObject) => event.errorMessage,
        }),
        resetErrorMessage: assign({
          errorMessage: (_) => '',
        }),
      },
    },
  );
};
