import styled from 'styled-components/macro';
import { Divider as AntdDivider } from 'antd';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.GREY_7};
`;

export const TopSection = styled.section`
  padding: 14px 14px 0 14px;
`;

export const Divider = styled(AntdDivider)`
  width: 235px;
  margin: 14px 0;
  background: ${({ theme }) => theme.GREY_7};
`;

export const MainSection = styled.section`
  flex: 1;
  width: 300px;
  display: flex;
  flex-direction: column;
`;

export const SearchContainer = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 0 14px;
`;

export const UserListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 16px;
  width: 100%;
`;
