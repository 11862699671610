import React from 'react';
import { ModalContainer, ActionContainer, LoadingIcon } from './style';
import { Button, DangerButton } from '@components/Button';
import { ModalProps } from 'antd';

export type IDeleteConfirmModal = {
  visible: boolean;
  confirmBtnTxt: string;
  cancelBtnTxt: string;
  isLoading: boolean;
  handleClose: (status: boolean) => void;
  handleConfirm: (event: React.MouseEvent<HTMLElement>) => void;
} & ModalProps;

export const DeleteModal: React.FC<React.PropsWithChildren<IDeleteConfirmModal>> = ({
  visible,
  isLoading,
  cancelBtnTxt,
  confirmBtnTxt,
  handleClose,
  handleConfirm,
  children,
  ...rest
}: React.PropsWithChildren<IDeleteConfirmModal>) => {
  return (
    <ModalContainer
      {...rest}
      visible={visible}
      onCancel={() => handleClose(false)}
      footer={[
        <ActionContainer key="1">
          <Button size="large" onClick={() => handleClose(false)}>
            {cancelBtnTxt}
          </Button>
          <DangerButton onClick={handleConfirm} size="large" disabled={isLoading}>
            {isLoading && <LoadingIcon icon={['fas', 'sync-alt']} />}
            {confirmBtnTxt}
          </DangerButton>
        </ActionContainer>,
        ,
      ]}
    >
      {children}
    </ModalContainer>
  );
};
