import { IRegion } from '..';

export type IFilterSmallRegionOutParams = {
  region: IRegion;
  width: number;
  height: number;
};
/**
 * Filter small region out
 * if has data btn in region and isChanging or width and height is greater than width and height in percentage return true
 * @param {IFilterSmallRegionOutParams} params
 * @param {IRegion} params.region
 * @param {number} params.width width in percentage default 10
 * @param {number} params.height height in percentage default 10
 * @returns {boolean}
 */
export const filterSmallRegionOut = ({ region, width = 10, height = 10 }: IFilterSmallRegionOutParams) => {
  const hasData = region.data && region.data.btn;
  const isChanging = region.isChanging;
  const isInRule = region.width > width && region.height > height;

  return hasData && (isChanging || isInRule);
};
