import React, { useEffect, useRef, useState } from 'react';
import AbstractBottomModal from '@components/BottomModal/AbstractBottomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Divider, Font12G1W400, Font14G3W400, Font16G1W400, Font16G1W600 } from '@components/UtilsComponent';
import { Checkbox } from '@components/Checkbox';
import useTranslation from '@hooks/useTranslation';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IGetByCursorParams, ITeam } from '@types';
import { getMyTeam } from '@api/livechat/TeamManagement';
import { IOption } from '@components/Select';
import { Spin } from 'antd';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { cloneDeep } from 'lodash';
import { scrollHandler } from '@utils/scroll';

type IBottomFilterMenuProps = {
  open: boolean;
  selectedTeams: string[];
  filterByUnRepliedChecked: boolean;
  onDismiss: () => void;
  onDone: (payload: IAdditionalFilterState) => void;
};

type IAdditionalFilterState = {
  filterByUnRepliedChecked: boolean;
  selectedTeams: string[];
};

export const BottomFilterMenu = ({
  open,
  filterByUnRepliedChecked,
  selectedTeams,
  onDismiss,
  onDone,
}: IBottomFilterMenuProps) => {
  const t = useTranslation();
  const tempRef = useRef<IAdditionalFilterState>(
    cloneDeep({
      filterByUnRepliedChecked,
      selectedTeams,
    }),
  );
  const [internalState, setInternalState] = useState<IAdditionalFilterState>(tempRef.current);
  const { data, hasMore, firstLoading, isLoadingMore, onOpenDropdown, onLoadmore } = useInfinityLoadByCursorId<
    ITeam & { id: string },
    IOption
  >({
    fetchFn: async (filter, cancelToken) => {
      const newFilter: IGetByCursorParams = {
        ...filter,
        cursorId: filter.cursorId,
      };
      const { data }: { data: ITeam[] } = await getMyTeam(newFilter, cancelToken);
      const result = data.map((item) => {
        return {
          ...item,
          id: item.id!,
          label: item.name,
          value: item.id!,
        };
      });
      return result;
    },
  });

  useEffect(() => {
    // save state
    tempRef.current = {
      filterByUnRepliedChecked,
      selectedTeams,
    };
  }, [selectedTeams, filterByUnRepliedChecked]);

  useEffect(() => {
    if (open) {
      onOpenDropdown();
    }
  }, [open]);

  return (
    <div>
      <AbstractBottomModal
        snapPoints={({ maxHeight }: { minHeight: number; maxHeight: number }) => {
          return [maxHeight, maxHeight];
        }}
        open={open}
        onDismiss={() => {
          // reset state
          const { filterByUnRepliedChecked, selectedTeams } = tempRef.current;
          setInternalState({
            filterByUnRepliedChecked,
            selectedTeams,
          });
          onDismiss();
        }}
      >
        <div className="flex justify-between items-center h-[48px] px-[24px] py-[12px]">
          <FontAwesomeIcon
            icon={faTimes}
            role="button"
            className="text-[18px]"
            onClick={() => {
              const { filterByUnRepliedChecked, selectedTeams } = tempRef.current;
              setInternalState({
                filterByUnRepliedChecked,
                selectedTeams,
              });
              onDismiss();
            }}
          />
          <button onClick={() => onDone(internalState)}>
            <Font16G1W600 className="!text-[#0F86FE]">{t('done.btn')}</Font16G1W600>
          </button>
        </div>
        <div className="mt-[16px]  px-[24px]">
          <button
            onClick={() => {
              setInternalState({
                ...internalState,
                filterByUnRepliedChecked: false,
                selectedTeams: [],
              });
            }}
          >
            <Font12G1W400 className="!text-[#0F86FE]">{t('clear.selection')}</Font12G1W400>
          </button>
        </div>
        <Divider />
        <div
          className="overflow-auto h-[calc(100vh-140px)]"
          onScroll={(e) => {
            if (!hasMore || firstLoading || isLoadingMore) return;
            scrollHandler.onScrollToBottom(e, onLoadmore);
          }}
        >
          <div className="mt-[16px]">
            <Font14G3W400 className="px-[24px]">{t('livechat.user.list.additional.filter.general')}</Font14G3W400>
            <button className="focus:bg-[#e6f7ff] w-full mt-[10px] px-[24px]">
              <div
                className="flex justify-between items-center h-[44px]"
                onClick={() => {
                  setInternalState({
                    ...internalState,
                    filterByUnRepliedChecked: !internalState.filterByUnRepliedChecked,
                  });
                }}
                role="button"
              >
                <Font16G1W400 role="button">
                  {t('livechat.user.list.additional.filter.show.not.replied.only')}
                </Font16G1W400>
                <Checkbox checked={internalState.filterByUnRepliedChecked} />
              </div>
            </button>
          </div>
          <Spin
            indicator={
              <LoadingWrap>
                <LoadingCircle />
              </LoadingWrap>
            }
            spinning={firstLoading}
          >
            <div className="mt-[16px]">
              <Font14G3W400 className="px-[24px]">{t('livechat.user.list.additional.filter.team')}</Font14G3W400>
              {data.map((item) => {
                return (
                  <button
                    key={item.value}
                    role="button"
                    className="flex justify-between items-center mt-[10px] h-[44px] w-full focus:bg-[#e6f7ff] px-[24px]"
                    onClick={() => {
                      // remove if exist and add if not
                      const selectedTeamsClone = internalState.selectedTeams.includes(item.value)
                        ? internalState.selectedTeams.filter((i) => i !== item.value)
                        : [...internalState.selectedTeams, item.value];
                      setInternalState({
                        ...internalState,
                        selectedTeams: selectedTeamsClone,
                      });
                    }}
                  >
                    <Font16G1W400>{item.label}</Font16G1W400>
                    <Checkbox checked={internalState.selectedTeams.includes(item.value)} id={item.value} />
                  </button>
                );
              })}
            </div>
          </Spin>
        </div>
      </AbstractBottomModal>
    </div>
  );
};
