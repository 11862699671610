import { useEffect, Key, useState } from 'react';
import { useMachine } from '@xstate/react';
import { Form } from 'antd';
import editTeamEditorMachine from './editTeamEditorMachine';
import { useHistory, useParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';
import { IMember } from '@types';
import useForceUpdate from '@hooks/useForceUpdate';
import { ERole } from '@enums/Role';
import { IVaHandler } from '../VaBlock';

const useEditTeamEditor = () => {
  const history = useHistory();
  const t = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const alert = useAlert();
  const forceUpdate = useForceUpdate();
  const [selectedVas, setSelectedVas] = useState<{
    firstResponse: IVaHandler['selected'];
    copilot: IVaHandler['selected'];
  }>({
    firstResponse: {
      id: '',
      name: '',
    },
    copilot: {
      id: '',
      name: '',
    },
  });
  const [current, send] = useMachine(editTeamEditorMachine(id), {
    devTools: import.meta.env.DEV,
  });

  useEffect(() => {
    if (current.matches('updateTeamSuccess')) {
      alert.success(t('livechat.team.management.team.editor.edit.alert.update.team.success'), {
        timeout: 2000,
        onOpen: () => {
          history.push('/team-management');
        },
      });
    }
  }, [current]);

  useEffect(() => {
    const { teamData } = current.context;

    if (current.matches('fetchTeamDataSuccess')) {
      // set virtual agent
      setSelectedVas({
        firstResponse: {
          id: teamData.virtualAgentConfig?.active?._id || '',
          name: teamData.virtualAgentConfig?.active?.displayName || '',
        },
        copilot: {
          id: teamData.virtualAgentConfig?.passive?._id || '',
          name: teamData.virtualAgentConfig?.passive?.displayName || '',
        },
      });
      form.setFieldsValue({
        name: teamData.name,
        department: teamData.department,
        default: teamData.default,
      });
    }
  }, [current]);

  const isCheckBoxDisabled = (record: IMember) => {
    const isDefaultTeam = form.getFieldValue('default');
    const isBotRole = record.roles.includes(ERole.BOT);

    return !isDefaultTeam && isBotRole;
  };

  const onSubmit = (values: { name: string; department: string; default?: boolean }) => {
    const { selectedMembers } = current.context;
    const payload = {
      ...values,
      default: values.default ?? false,
      agents: selectedMembers,
    };
    send('SUBMIT_FORM', {
      payload: {
        ...payload,
        virtualAgentConfig: {
          active: selectedVas.firstResponse.id || undefined,
          passive: selectedVas.copilot.id || undefined,
        },
      },
    });
  };

  const handleSelectMembers = (selectedRowKeys: Key[]) => {
    send('SET_SELECTED_MEMBERS', { data: selectedRowKeys });
  };

  const handlePageChange = (page: number) => {
    send('FETCH_MEMBER', { page });
  };

  const filterOutSelectedBotMembers = () => {
    if (!form.getFieldValue('default')) {
      send('FILTER_OUT_BOT_MEMBER');
    }
    forceUpdate();
  };
  return {
    current,
    form,
    vaHandler: {
      selectedVas,
      onSelectActiveVa: (id: string, name: string) => {
        setSelectedVas((prev) => ({
          ...prev,
          firstResponse: {
            id,
            name,
          },
        }));
      },
      onSelectPassiveVa: (id: string, name: string) => {
        setSelectedVas((prev) => ({
          ...prev,
          copilot: {
            id,
            name,
          },
        }));
      },
    },
    send,
    onSubmit,
    forceUpdate,
    filterOutSelectedBotMembers,
    handleSelectMembers,
    handlePageChange,
    isCheckBoxDisabled,
  };
};

export default useEditTeamEditor;
