import styled from 'styled-components/macro';

export const Container = styled.div`
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-tab {
    min-width: auto !important;
  }
`;
