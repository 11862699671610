import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@components/Tag/TagWithRemove/style';

export const Container = styled.div`
  width: 100%;
  overflow: auto;
  .style__Container-sc-79sxwz-0 {
    padding-top: 0;
    margin-top: -12px;
    .style__Inner-sc-79sxwz-1 {
      padding-top: 0;
    }
  }
`;
