import styled from 'styled-components/macro';
import DianaSearchInput from '@components/SearchInput';
import { EDropdownSize } from '@components/Dropdown/DropdownTags';
export const Container = styled.div`
  input {
    font-size: 16px;
  }
`;

export const DropdownContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 12px 0;
  box-shadow: 0px 4px 20px 0px rgba(23, 24, 28, 0.15), 0px 0px 2px 0px rgba(23, 24, 28, 0.2);
`;

export const DropdownContent = styled.div`
  max-height: 200px;
  overflow: auto;
`;

export const DropdownItem = styled.div<{ isSelected: boolean }>`
  height: 44px;
  width: 100%;
  padding: 12px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${({ isSelected }) => (isSelected ? '#e6f7ff' : 'white')};
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '400')};
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
`;

export const EmptyWord = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.GREY_5};
`;

export const SelectedArea = styled.div<{ isDisabled: boolean; size?: EDropdownSize }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 48px;
  font-size: 16px;
  background-color: ${({ isDisabled, theme }) => (isDisabled ? theme.GREY_9 : '#fff')};
  gap: 8px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  border-radius: 8px !important;
  padding: 14px 11px;
  color: ${({ theme }) => theme.GREY_1};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  ${({ size }) => `
    ${size === EDropdownSize.MIDDLE ? 'min-height: 36px; padding: 8px 12px' : ''}
  `}
`;

export const PlaceholderText = styled.span`
  color: #9e9e9e;
  font-size: 16px;
`;

export const SearchBox = styled.div`
  padding: 0px 16px 8px 16px;
`;

export const SearchInput = styled(DianaSearchInput)`
  height: 36px;
  background: rgba(0, 0, 0, 0.04);
  border: none;
  .ant-input {
    background: transparent;
  }
`;

export const Button = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  :disabled {
    cursor: not-allowed;
  }
`;

export const SelectedText = styled.div`
  font-size: 16px;
  display: block;
  max-width: 90%;

  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
`;
