import { TertiaryButton } from '@components/Button';
import styled from 'styled-components/macro';

export const DownButtonComp = styled(TertiaryButton)`
  position: absolute;
  bottom: 0;
  width: 36px !important;
  height: 36px !important;
  padding: 8px;
  font-size: 16px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
`;
