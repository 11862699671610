import styled from 'styled-components/macro';
import { Col, Divider as AntdDivider } from 'antd';

export const Container = styled.div`
  padding: 28px 24px;
`;

export const TabBox = styled.div`
  margin-top: 24px;
`;

export const GroupBox = styled.div`
  margin-top: 16px;
`;

export const ExportContainer = styled(Col)`
  flex: 1;
  text-align: right;
`;

export const ExportText = styled.span`
  margin-right: 8px;
  font-weight: 600;
`;

export const Divider = styled(AntdDivider)`
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
`;

export const DropdownItem = styled.li`
  cursor: pointer;
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    flex: 1;
    height: 100%;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SelectContainer = styled.div`
  .ant-select-selection-item {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    display: block !important;
  }
`;
