import { useCallback, useEffect } from 'react';
import { useInterpret, useActor } from '@xstate/react';
import ticketHistoryMachine, { ITicketHistoryMachineContext } from './ticketHistoryMachine';
import { useParams } from 'react-router-dom';
import { useSelector } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useTranslation from '@hooks/useTranslation';

const ticketContext = ({ context }: { context: ITicketHistoryMachineContext }) => context;

const useTicketHistory = () => {
  const t = useTranslation();
  const { id } = useParams<{ id: string }>();
  const alert = useAlert();
  const history = useHistory();
  const ticketHistoryFn = useCallback(() => ticketHistoryMachine(id), [id]);
  const ticketHistoryService = useInterpret(ticketHistoryFn, {
    devTools: import.meta.env.DEV,
  });
  const ticket = useSelector(ticketHistoryService, ticketContext);
  const [current] = useActor(ticketHistoryService);
  const { send } = ticketHistoryService;

  useEffect(() => {
    const { currentTicket, errorMessage } = current.context;
    if (current.matches('openTicketSuccess')) {
      alert.success(t('livechat.ticket.management.alert.reopen.success', { ticketNumber: currentTicket.ticketNumber }));
      history.push(`/recents/${currentTicket.userId}`);
      return;
    }
    if (current.matches('errorOpenTicket')) {
      alert.error(errorMessage);
      send('IDLE');
      return;
    }
    if (current.matches('reOpenTicketSuccess')) {
      alert.success(t('livechat.ticket.management.alert.open.success', { ticketNumber: currentTicket.ticketNumber }));
      history.push(`/ticket-management/all-tickets/${currentTicket._id}`);
      return;
    }
    if (current.matches('errorReOpenTicket')) {
      alert.error(errorMessage);
      send('IDLE');
      return;
    }
  }, [current]);

  const onFetchUser = () => {
    send('FETCH_USER');
  };

  return {
    ticketHistoryService,
    ticket,
    current,
    onFetchUser,
    send,
  };
};

export default useTicketHistory;
