import { GLOBAL_MESSAGE } from '@configs/constants';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-lodash-debounce';
import { useCancelTokenSource } from './useCancelToken';

export type IInifinityLoadInitialFilter = {
  keyword?: string;
  take?: number;
  skip?: number;
  cursorId?: string;
  debounceTime?: number;
};
export type IInfinityLoadFetchFnParams = {
  keyword?: string;
  take?: number;
  skip?: number;
  page?: number;
  pageSize?: number;
  cursorId?: string;
};
export type IInfinityLoadFetchFn<T, K> = (
  params: IInfinityLoadFetchFnParams,
  cancelToken?: CancelTokenSource,
) => Promise<(T & K)[]>;

export type IUseInfinityLoadParams<T extends { id: string }, K> = {
  fetchFn: IInfinityLoadFetchFn<T, K>;
  initialFilter?: IInifinityLoadInitialFilter;
};

export const DEFAULT_API_MAX_TAKE = 10;
export const DEFAULT_DEBOUNCE_TIME = 400;

export const useInfinityLoadByCursorId = <T extends { id: string }, K = void>(params: IUseInfinityLoadParams<T, K>) => {
  const isInit = useRef(false);
  const { newCancelTokenSource } = useCancelTokenSource();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const debouncedSearchTxt = useDebounce(
    searchKeyword,
    params.initialFilter?.debounceTime ? params.initialFilter?.debounceTime : DEFAULT_DEBOUNCE_TIME,
  );
  const filter = useRef({
    take: params.initialFilter?.take || DEFAULT_API_MAX_TAKE,
    skip: params.initialFilter?.skip,
    cursorId: params.initialFilter?.cursorId,
  });
  const hasMore = useRef(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState<(T & K)[]>([]);

  // searching
  useEffect(() => {
    const cancelToken = newCancelTokenSource();
    const searchTagsByKeyword = async () => {
      try {
        // set first loading
        setFirstLoading(true);
        // reset data
        setData([]);
        // reset filter cursorId
        filter.current = {
          ...filter.current,
          cursorId: '',
        };

        const result = await params.fetchFn(
          {
            keyword: debouncedSearchTxt,
            cursorId: '',
            take: filter.current.take + 1,
          },
          cancelToken,
        );
        setLoadMoreFilter(result);
        setFirstLoading(false);
        setData((prev) => {
          return [...prev, ...result];
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setFirstLoading(false);
          filter.current = {
            ...filter.current,
            cursorId: '',
          };
        }
      }
    };

    if (isInit.current) {
      searchTagsByKeyword();
    }
  }, [debouncedSearchTxt, isInit]);

  const setLoadMoreFilter = (data: (T & K)[]) => {
    // set cursorId and hasmore
    hasMore.current = data.length > filter.current.take;
    filter.current = {
      ...filter.current,
      cursorId: data.length === filter.current.take + 1 ? data[data.length - 1].id : '',
    };
  };

  const resetFilter = () => {
    hasMore.current = false;
    filter.current = {
      take: params.initialFilter?.take || DEFAULT_API_MAX_TAKE,
      skip: params.initialFilter?.skip,
      cursorId: params.initialFilter?.cursorId,
    };
  };

  return {
    searchKeyword,
    data,
    firstLoading,
    errorMessage,
    isLoadingMore,
    hasMore: hasMore.current,
    onOpenDropdown: async () => {
      try {
        const cancelToken = newCancelTokenSource();
        resetFilter();
        setFirstLoading(true);
        setData([]);
        const result = await params.fetchFn(
          {
            ...filter.current,
            take: filter.current.take + 1,
            keyword: searchKeyword,
          },
          cancelToken,
        );
        setLoadMoreFilter(result);
        setData(result);
        setFirstLoading(false);
        isInit.current = true;
      } catch (error) {
        if (!axios.isCancel(error)) {
          isInit.current = true;
          console.error('error first load content', error);
          setFirstLoading(false);
          setErrorMessage(GLOBAL_MESSAGE.ERROR_TITLE);
        }
      }
    },
    onLoadmore: async () => {
      try {
        // set loading
        setIsLoadingMore(true);
        const result = await params.fetchFn({
          keyword: searchKeyword,
          cursorId: filter.current.cursorId,
          take: filter.current.take + 1,
        });
        setLoadMoreFilter(result);
        setData((prev) => {
          return [...prev, ...result];
        });
        setIsLoadingMore(false);
      } catch (error) {
        setIsLoadingMore(false);
        console.error('error load more content', error);
      }
    },
    onUpdateSearchKeyword: (keyword: string) => {
      // reset hasmore to avoid double call loadmore
      hasMore.current = false;
      setSearchKeyword(keyword);
    },
  };
};
