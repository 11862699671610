import React, { createContext, useContext } from 'react';
import useTicketManagerHook from './useTicketManager';
import StateMessage from '@components/StateMessage';
import error_message from '@assets/images/error_message.svg';
import useTranslation from '@hooks/useTranslation';

export const TicketManagerContext = createContext<ReturnType<typeof useTicketManagerHook>>(
  {} as ReturnType<typeof useTicketManagerHook>,
);

export const useTicketManager = () => useContext(TicketManagerContext);

export type ITicketManager = {
  userId: string;
};

const TicketManagerProvider: React.FC<React.PropsWithChildren<ITicketManager>> = ({
  userId,
  children,
}: React.PropsWithChildren<ITicketManager>) => {
  const ticketManager = useTicketManagerHook(userId);
  const { current } = ticketManager;
  const t = useTranslation();
  const isFechingTicketFailed = current.matches('fetchTicketFailed');

  if (isFechingTicketFailed) {
    return (
      <StateMessage
        size="medium"
        type="error"
        img={error_message}
        label={t('livechat.general.error')}
        description={t('livechat.general.error.description')}
      />
    );
  }
  return <TicketManagerContext.Provider value={ticketManager}>{children}</TicketManagerContext.Provider>;
};

export default TicketManagerProvider;
