import React from 'react';
import { InfoToastContainer, InfoIconWrap } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type IInfoToast = {
  align: string;
};

const InfoToast: React.FC<React.PropsWithChildren<IInfoToast>> = ({
  children,
  align = 'center',
}: React.PropsWithChildren<IInfoToast>) => {
  return (
    <InfoToastContainer align={align}>
      <InfoIconWrap>
        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
      </InfoIconWrap>
      {children}
    </InfoToastContainer>
  );
};

export default InfoToast;
