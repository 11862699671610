import React from 'react';
import styled from 'styled-components/macro';
import { Player } from '@lottiefiles/react-lottie-player';

import lottieJson from './lottie.json';

export type ILoadingCircle = {
  width?: number;
  height?: number;
  [key: string]: any;
};

const Container = styled(Player)<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const LoadingCircle: React.FC<ILoadingCircle> = ({ width = 100, height = 50, ...rest }: ILoadingCircle) => {
  return <Container id="loading-indicator" {...rest} width={width} height={height} autoplay loop src={lottieJson} />;
};

export default LoadingCircle;
