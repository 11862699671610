import React, { createContext, useContext } from 'react';
import { useSettingHook } from './useSetting';
import { LoadingContainer } from '@providers/MemberProvider/style';
import LoadingCircle from '@components/Loading';

export type ISettingProviderContext = ReturnType<typeof useSettingHook>;
const Context = createContext<ISettingProviderContext>({} as ISettingProviderContext);

export const useSetting = () => useContext(Context);
export const SettingProvider: React.FC = ({ children }) => {
  const setting = useSettingHook();
  const _renderPage = () => {
    if (setting.query.isLoading) {
      return (
        <LoadingContainer data-testid="test-loading-wrap">
          <LoadingCircle />
        </LoadingContainer>
      );
    }

    return children;
  };
  return <Context.Provider value={setting}>{_renderPage()}</Context.Provider>;
};
