import styled from 'styled-components/macro';

export const Container = styled.div`
  margin-top: 11.25px;
  display: grid;
  gap: 3.5px;
`;

export const UnassignBlock = styled.div`
  margin-top: 16px;
  margin-bottom: 2px;
  font-size: 12px;
  color: ${({ theme }) => theme.GREY_4};
`;
