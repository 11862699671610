import React from 'react';
import { Spin } from 'antd';
import { CloseIcon, MainSection, TopSection } from './style';
import { ImageUpload } from '@components/ImageUpload';
import { RcFile } from 'antd/lib/upload';
import { useAlert } from 'react-alert';
import { useImageUploadContext, useImageUpload, IImageUploadedParams } from '@providers/ImageUploadProvider';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Font20G1W600 } from '@components/UtilsComponent';

export const MAX_IMAGE_FILE_SIZE = 1; //MB
export const IMAGE_MAP_MIN_WIDTH = 372; //px
export const IMAGE_MAP_MIN_HEIGHT = 372; //px

export type IImageMapUploader = {
  selectedMedia: string;
  onRemoveImageUrl: () => void;
  onUploadedImage: ({ url, type, dimension }: IImageUploadedParams) => void;
  onCancel: () => void;
};

export const ImageMapUploader: React.FC<IImageMapUploader> = ({
  selectedMedia,
  onUploadedImage,
  onCancel,
  onRemoveImageUrl,
}: IImageMapUploader) => {
  const alert = useAlert();
  const { uploader } = useImageUploadContext();
  const { currentUrl, isLoading, textUrl, beforeUpload, onUpload, isNoUrl, onClearDraftUrl } = useImageUpload({
    selectedMedia,
    minImageDimension: {
      minHeight: IMAGE_MAP_MIN_WIDTH,
      minWidth: IMAGE_MAP_MIN_HEIGHT,
    },
    uploader,
  });

  return (
    <Spin
      indicator={
        <LoadingWrap>
          <LoadingCircle />
        </LoadingWrap>
      }
      spinning={isLoading}
    >
      <TopSection>
        <Font20G1W600>Import file</Font20G1W600>
        <CloseIcon icon={faTimes} onClick={onCancel} />
      </TopSection>
      <MainSection>
        <ImageUpload
          textUrl={textUrl}
          selectedMedia={currentUrl}
          beforeUpload={async (sourceFile: RcFile) => {
            const result = await beforeUpload(sourceFile, MAX_IMAGE_FILE_SIZE);
            return result;
          }}
          onRemoveDraft={() => {
            onClearDraftUrl();
          }}
          onUpload={async () => {
            try {
              const result = await onUpload();
              if (result) {
                onUploadedImage(result);
              }
            } catch (error) {
              alert.error('Upload file failed please try again later', {
                timeout: 2000,
              });
            }
          }}
          isNoUrl={isNoUrl()}
          onRemoveCurrentUrl={() => {
            onClearDraftUrl();
            onRemoveImageUrl();
          }}
          onCancel={onCancel}
        />
      </MainSection>
    </Spin>
  );
};
