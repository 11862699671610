import { client } from '@api';
import { BOTS } from '@types';

export const getTemplates = async (params?: BOTS.IFetchTemplateRequest) => {
  const { data }: { data: BOTS.ITemplateApi[] } = await client.get('/api/v1/templates', {
    params: {
      take: params?.take,
      cursorId: params?.cursorId,
      keyword: params?.keyword ? params.keyword : undefined,
      skip: params?.skip,
    },
  });
  return data;
};
