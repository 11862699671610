import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as theme from '@styles/theme';
import { Typography } from 'antd';
import styled from 'styled-components';

export const TableBox = styled.div`
  margin-top: 16px;
  table {
    td {
      cursor: pointer;
    }
  }
`;

export const HeaderText = styled(Typography.Text)`
  color: ${({ theme }) => theme.GREY_3};
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
`;

export const CenterContainer = styled.div`
  text-align: center;
`;

export const PaddingContainer = styled.div`
  padding-left: 24px;
`;

export const AssigneeWrap = styled.div`
  word-break: break-word;
`;
