import React, { useCallback, useMemo } from 'react';
import { IMember, ITicket, IUser } from '@types';
import { Font14G1W400, Font20G1W600, ResolveBtn } from '@components/UtilsComponent';
import useTranslation from '@hooks/useTranslation';
import ResolveModal from './ResolveModal';
import { canResolveTicket } from '@utils/canResolveTicket';
import { useResolveTicket } from './useResolveTicket';
import { BlankModal } from '@components/Modal';
import { ErrorWrap } from './style';
import error_message from '@assets/images/error_message.svg';
import { IResolveForm } from './ResolveModal/useResolveModal';
import { Tooltip } from 'antd';

type IResolveTicketProps = {
  ticket: ITicket;
  member: IMember;
  user: IUser;
  isWrapUpTicketRequired: boolean; // if true open modal for wrap up ticket after click resolve button
  submitCb: () => void;
  onUpdateTicketField: (payload: IResolveForm) => void;
};

export const ResolveTicket = ({
  user,
  ticket,
  member,
  isWrapUpTicketRequired,
  submitCb,
  onUpdateTicketField,
}: IResolveTicketProps) => {
  const t = useTranslation();
  const { showModal, errorMessage, isResolving, onShowModal, onSubmit, onHideErrorModal } = useResolveTicket();
  const isTicketOpen = ticket?.status === 'open';
  const isDisalbedResolve = useMemo(() => {
    if (!ticket || !member || !user || isTicketOpen) return true;
    return !canResolveTicket(ticket, member);
  }, [ticket, member, user, isTicketOpen]);

  const _renderErrorModal = useCallback(() => {
    return (
      <>
        <div>
          <Font20G1W600>{t('livechat.ticket.console.resolve.error.title')}</Font20G1W600>
        </div>
        <div>
          <Font14G1W400>{t('livechat.ticket.console.resolve.error.description')}</Font14G1W400>
        </div>
      </>
    );
  }, []);

  if (!ticket || !member || !user) return null;

  if (isTicketOpen) {
    return (
      <Tooltip title={t('require.assignee.for.resolve')} placement="top">
        <ResolveBtn size="middle" disabled>
          {t('livechat.ticket.console.ticket.summarize')}
        </ResolveBtn>
      </Tooltip>
    );
  }

  return (
    <>
      {isWrapUpTicketRequired ? (
        <ResolveBtn size="middle" disabled={isDisalbedResolve} onClick={() => onShowModal(true)}>
          {t('livechat.ticket.console.ticket.summarize')}
        </ResolveBtn>
      ) : (
        <ResolveBtn
          size="middle"
          loading={isResolving}
          disabled={isDisalbedResolve}
          onClick={() => onSubmit(ticket, user, submitCb)}
        >
          {t('livechat.ticket.console.ticket.summarize')}
        </ResolveBtn>
      )}
      {showModal ? (
        <ResolveModal
          ticket={ticket}
          show={showModal}
          handleShowModal={onShowModal}
          handleSubmit={() => onSubmit(ticket, user, submitCb)}
          isResolving={isResolving}
          onUpdateTicketField={onUpdateTicketField}
        />
      ) : null}
      <BlankModal visible={!!errorMessage} onCancel={onHideErrorModal}>
        <ErrorWrap>
          <div>
            <img src={error_message} />
          </div>
          {_renderErrorModal()}
        </ErrorWrap>
      </BlankModal>
    </>
  );
};
