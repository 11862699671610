import { client } from '@api';
import { UserMessages } from '@types';

export const getMessages = (userId: string) => {
  return client.get<{ total: number; userMessages: UserMessages[] }>(`/api/messages/users/${userId}?limit=30`);
};

export const getOlderMessage = (userId: string, firstMessageCreatedAt: number) => {
  return client.get<{ total: number; userMessages: UserMessages[] }>(
    `/api/messages/users/${userId}?pagination[when]=before&pagination[createdAt]=${firstMessageCreatedAt}&limit=30`,
  );
};

export const getNewerMessage = (userId: string, lastMessageCreateAt: number) => {
  return client.get<{ total: number; userMessages: UserMessages[] }>(
    `/api/messages/users/${userId}?pagination[when]=after&pagination[createdAt]=${lastMessageCreateAt}&limit=30`,
  );
};
