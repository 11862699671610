import React, { useState } from 'react';
import { ConfirmModal } from '@components/Modal/ConfirmModal';
import Tab, { ITabItem } from '@components/Tab';
import { Font14G1W400, Font14G1W700, Font20G1W600 } from '@components/UtilsComponent';
import useTranslation from '@hooks/useTranslation';
import useAssignee from '@pages/components/Assignee/useAssignee';
import { IMember, ITeam, ITicket } from '@types';
import { PeopleTab } from './PeopleTab';
import { IAssigneeMachineContext, TAB } from '@pages/components/Assignee/assigneeMachine';
import { Space, Spin } from 'antd';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { TeamTab } from './TeamTab';
import { ActionContainer, ConfirmModal as AssigneeConfirmModal } from '@pages/components/Assignee/style';
import { Button, PrimaryButton } from '@components/Button';
import LoadingIcon from '@components/LoadingIcon';
import { faChevronDown, faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import { SelectedText } from '@components/SelectBtn/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from './style';
import { useMember } from '@providers/MemberProvider';
import { useSetting } from '@providers/SettingProvider';
import { checkDisableAssigneeSelect } from '@pages/components/Assignee/fns/checkDisable';
import { Button as AntdButton } from 'antd';
type IModalAssigneeProps = {
  ticket: ITicket;
  onAssignSuccesss: (currentTicket: ITicket, agentName: string) => void;
};

export type IInternalSelectPeople = {
  teamId: string;
  agentId: string;
};

type ITabParams = {
  ticket: ITicket;
  people: IAssigneeMachineContext['peopleDataV2'];
  teams: IAssigneeMachineContext['teamData']['rows'];
  internalSelectedAgent: IInternalSelectPeople | null;
  internalSelectedTeam: string | null;
  isLoadingMoreTeam: boolean;
  searchTeamHandler: {
    keywords: string;
    setKeywords: (value: string) => void;
  };
  onSelectAgent: (agent: IMember) => void;
  onSelectTeam: (team: ITeam) => void;
  onLoadmoreTeam: () => void;
};

const TABS = ({
  ticket,
  people,
  teams,
  internalSelectedAgent,
  internalSelectedTeam,
  isLoadingMoreTeam,
  searchTeamHandler,
  onSelectAgent,
  onSelectTeam,
  onLoadmoreTeam,
}: ITabParams): ITabItem[] => [
  {
    tab: 'People',
    key: TAB.PEOPLE,
    children: (
      <PeopleTab
        ticket={ticket}
        list={people}
        internalSelectedAgent={internalSelectedAgent}
        onSelectAgent={onSelectAgent}
      />
    ),
  },
  {
    tab: 'Team',
    key: TAB.TEAMS,
    children: (
      <TeamTab
        ticket={ticket}
        list={teams}
        internalSelectedTeam={internalSelectedTeam}
        isLoadingMoreTeam={isLoadingMoreTeam}
        searchTeamHandler={searchTeamHandler}
        onSelectTeam={onSelectTeam}
        onLoadmoreTeam={onLoadmoreTeam}
      />
    ),
  },
];

export const ModalAssignee = ({ ticket, onAssignSuccesss }: IModalAssigneeProps) => {
  const [visible, setvisible] = useState(false);
  const { member } = useMember();
  const { permission } = useSetting();
  const [internalSelectedTeam, setInternalSelectedTeam] = useState<string | null>(
    ticket.team && !ticket.agentId ? ticket.team : null,
  );
  const [internalSelectedAgent, setInternalSelectedAgent] = useState<IInternalSelectPeople | null>(
    ticket.agentId
      ? {
          teamId: ticket.team,
          agentId: ticket.agentId,
        }
      : null,
  );

  const t = useTranslation();
  const {
    current,
    currentAssignee,
    currentTab,
    handleConfirm,
    handleAskConfirm,
    onIdle,
    onSelectAgent,
    onTabChange,
    onLoadmoreTeam,
    onSelectTeam,
    send,
  } = useAssignee(ticket, onAssignSuccesss);
  const onSelectInternalTeam = (team: ITeam) => {
    setInternalSelectedTeam(team._id);
  };
  const _renderModalContent = () => {
    return (
      <>
        <div>
          <Font20G1W600>{t('livechat.ticket.console.ticket.assignee.modal.title')}</Font20G1W600>
        </div>
        <div>
          <Font14G1W400>{t('livechat.ticket.console.ticket.assignee.modal.description')}</Font14G1W400>
        </div>
      </>
    );
  };

  // disable if
  // 1. current tab is people
  // 2. selected agent is empty or agent id is equal to ticket and team id is equal to ticket team id
  const disableAssignBtnForPeople =
    currentTab === TAB.PEOPLE &&
    (!internalSelectedAgent?.agentId ||
      (internalSelectedAgent?.agentId === ticket.agentId && internalSelectedAgent?.teamId === ticket.team));
  // disable if
  // 1. current tab is team
  // 2. selected agent is empty or team id is equal to ticket team id
  const disableAssignBtnForTeam =
    currentTab === TAB.TEAMS && (!internalSelectedTeam || internalSelectedTeam === ticket.team);
  return (
    <>
      <Container>
        <Space size={'small'} direction={'vertical'}>
          <Font14G1W700>{t('livechat.ticket.console.ticket.assignee')}</Font14G1W700>
          <Spin spinning={current.matches('firstFetching')} indicator={<LoadingCircle />}>
            <AntdButton
              data-testid="assignee-btn"
              onClick={() => setvisible(!visible)}
              disabled={checkDisableAssigneeSelect({
                currentTicket: ticket,
                member,
                isCollaboration: permission.canAssign || false,
              })}
            >
              <SelectedText>{currentAssignee?.username ?? t('assignee.modal.choose.assignee')}</SelectedText>
              <div>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </AntdButton>
          </Spin>
        </Space>
      </Container>
      <ConfirmModal
        zIndex={9}
        destroyOnClose
        width={752}
        bodyWidth="100%"
        centered
        visible={visible || current.matches('confirmAssignAgent') || current.matches('confirmAssignTeam')}
        isDisabledConfirmBtn={disableAssignBtnForPeople || disableAssignBtnForTeam}
        confirmBtnTxt={t('assignee.modal.confirm.button')}
        cancelBtnTxt={t('assignee.modal.cancel.button')}
        handleClose={function (status: boolean): void {
          setInternalSelectedAgent({
            teamId: ticket.team,
            agentId: ticket.agentId ?? '',
          });
          onIdle();
          setvisible(false);
        }}
        handleConfirm={function (event?: React.MouseEvent<HTMLElement, MouseEvent> | undefined): void {
          handleAskConfirm();
        }}
      >
        <Spin
          spinning={
            current.matches('firstFetching') ||
            current.matches('fetchingTeam') ||
            current.matches('fetchingAgentsInMyTeams')
          }
          indicator={
            <LoadingWrap>
              <LoadingCircle />
            </LoadingWrap>
          }
        >
          <div>
            <div className="mb-[14px]">
              <Font20G1W600>{t('assignee.modal.title')}</Font20G1W600>
            </div>
            <div>
              <Tab
                tabs={TABS({
                  ticket,
                  people: current.context.peopleDataV2,
                  teams: current.context.teamData.rows,
                  internalSelectedAgent: internalSelectedAgent,
                  internalSelectedTeam: internalSelectedTeam,
                  isLoadingMoreTeam: current.matches('fetchingMoreTeam'),
                  searchTeamHandler: {
                    keywords: current.context.searchTxt,
                    setKeywords: (value) => send('SEARCH_TEXT_CHANGE', { value: value }),
                  },
                  onSelectAgent: (item) => {
                    onSelectAgent(item);
                    setInternalSelectedAgent({
                      teamId: item.teamId[0],
                      agentId: item._id,
                    });
                  },
                  onSelectTeam: (item) => {
                    onSelectTeam(item);
                    onSelectInternalTeam(item);
                  },
                  onLoadmoreTeam,
                })}
                defaultActiveKey={''}
                onTabClick={function (
                  key: string,
                  _event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>,
                ): void {
                  onTabChange(key);
                }}
              />
            </div>
          </div>
        </Spin>
      </ConfirmModal>
      <AssigneeConfirmModal
        footer={false}
        zIndex={10}
        visible={
          current.matches('askToConfirm') ||
          current.matches('confirmAssignAgent') ||
          current.matches('confirmAssignTeam')
        }
        onCancel={() => send('CANCEL_ASSIGN')}
      >
        {_renderModalContent()}
        <ActionContainer>
          <Button size="large" onClick={() => handleConfirm(false)}>
            {t('livechat.ticket.console.ticket.assignee.modal.cancel')}
          </Button>
          <PrimaryButton
            size="large"
            disabled={current.matches('confirmAssignAgent') || current.matches('confirmAssignTeam')}
            onClick={() => {
              handleConfirm(true);
              setvisible(false);
            }}
          >
            {(current.matches('confirmAssignAgent') || current.matches('confirmAssignTeam')) && (
              <LoadingIcon icon={faSyncAlt} />
            )}
            {t('livechat.ticket.console.ticket.assignee.modal.confirm')}
          </PrimaryButton>
        </ActionContainer>
      </AssigneeConfirmModal>
    </>
  );
};
