import React from 'react';
import { Font14G1W500 } from '@components/UtilsComponent';
import { BOTS } from '@types';
import { Container, Inner, TopSection, AvatarContainer, QuickReplyWrap, ContentWrap } from './style';
import Avatar from '@assets/images/sample_avatar.png';
import { PreviewContent } from '../PreviewContent';

export type IPreviewWrapperProps = {
  showName?: boolean;
  message: BOTS.ITemplateApi;
};

export const PreviewWrapper = ({ message, showName }: IPreviewWrapperProps) => {
  return (
    <Container>
      {showName && (
        <TopSection>
          <div className="w-[80%]" title={message.name}>
            <Font14G1W500 className="w-full block overflow-hidden overflow-ellipsis text-center whitespace-nowrap">
              {message.name}
            </Font14G1W500>
          </div>
        </TopSection>
      )}
      <Inner>
        <PreviewContent messages={message.messages} />
      </Inner>
    </Container>
  );
};

export const Wrap = ({ children, quickReply }: { children?: React.ReactNode; quickReply?: BOTS.IQuickReplyApi }) => {
  return (
    <AvatarContainer>
      <div>
        <img width={30} height={30} src={Avatar} />
      </div>
      <ContentWrap>
        {children}
        <QuickReplyWrap>
          {quickReply?.items.map((item) => {
            return <div key={item.action.id}>{item.action.label}</div>;
          })}
        </QuickReplyWrap>
      </ContentWrap>
    </AvatarContainer>
  );
};
