import { searchTag } from '@api/livechat/Tag';
import { ETableEvent } from '@hooks/useTable';

export const fetchTagHandler = async (
  keyword: string,
  cursorId: {
    next: string;
    prev: string;
  },
  currentPageEvent: ETableEvent,
  currentPage: number,
) => {
  return {
    fetchFn: async (maxTake: number) => {
      let cursor = '';
      if (currentPageEvent === ETableEvent.NEXT) {
        cursor = cursorId.next;
      }
      if (currentPageEvent === ETableEvent.PREV) {
        cursor = cursorId.prev;
      }

      const { data } = await searchTag({ cursorId: cursor, keyword: keyword, take: maxTake });

      return data;
    },
    behavior: currentPageEvent,
    params: {
      max: 10,
      currentPage: currentPage,
    },
  };
};
