import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .reason {
    width: 100%;
  }
  .reason span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Counter = styled.div`
  text-align: right;
`;
