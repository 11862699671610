import { useMachine } from '@xstate/react';
import { useEffect, useCallback } from 'react';
import { imageMapMachine } from './imageMapMachine';
import { ISurveyImageMapAction } from '@types';

export const useImageMap = (imageMap?: ISurveyImageMapAction) => {
  const machine = useCallback(() => imageMapMachine(imageMap), [imageMap]);
  const [current, send] = useMachine(machine, { devTools: import.meta.env.DEV });
  useEffect(() => {
    if (imageMap?.imgUrl) {
      send('IMAGE_MODE');
      if (imageMap.actions.length > 0) {
        send('UPDATE_REGIONS', { actions: imageMap.actions });
      }
    } else {
      send('BLANK_MODE');
    }
  }, [imageMap?.imgUrl]);
  return {
    current,
    isIdle: current.matches('idle'),
    isBlankMode: current.matches('blankMode'),
    isImageMode: current.matches('imageMode'),
    internalErrorMessage: current.context.errorMessage,
    imageMode: {
      isIdle: current.matches('imageMode.idle'),
      isMapImage: current.matches('imageMode.mapImage'),
      isHovering: current.matches('imageMode.hovering'),
    },
    send,
  };
};
