import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from 'antd';
import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const UserDetail = styled.div`
  display: grid;
  column-gap: 8px;
  grid-template-columns: 16px 32px 1fr;
  align-items: center;
  width: calc(100% - 150px);
`;

export const UserDetailInner = styled.div`
  overflow: hidden;
  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  width: auto;
  color: ${({ theme }) => theme.WHITE};
`;

export const UserName = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  width: 80%;
  margin-bottom: 2px;
  color: ${({ theme }) => theme.GREY_1};
`;

export const TicketNumber = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.GREY_5};
`;

export const ActionBox = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  justify-content: flex-end;
`;

export const MessageContainer = styled.div``;
export const ComposerContainer = styled.div`
  padding-top: 16px;
`;
