import React, { useCallback } from 'react';
import { TagItem, FontAwesomeIcon, TagLabel } from './style';
import { faSpinnerThird, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Font14G1W700 } from '@components/UtilsComponent';
import { useTheme } from 'styled-components';

export type ITagWithRemove = {
  label: string;
  isLoading?: boolean;
  className?: string;
  handleRemoveTag?: () => void;
};

export const TagWithRemove: React.FC<ITagWithRemove> = ({
  label,
  isLoading,
  className,
  handleRemoveTag,
}: ITagWithRemove) => {
  const theme: any = useTheme();
  const _renderAction = useCallback(() => {
    // render loading
    if (isLoading) {
      return (
        <FontAwesomeIcon
          icon={faSpinnerThird}
          spin
          style={{ color: theme.PRIMARY, cursor: isLoading ? 'not-allowed' : 'auto' }}
        />
      );
    }
    // render remove icon
    if (handleRemoveTag) {
      return <FontAwesomeIcon icon={faTimes} data-testid={'tag-with-remove-button'} onClick={handleRemoveTag} />;
    }
  }, [label, isLoading, handleRemoveTag]);
  return (
    <TagItem className={className} isLoading={isLoading}>
      <Font14G1W700>
        <TagLabel isLoading={isLoading}>{label}</TagLabel>
      </Font14G1W700>
      {_renderAction()}
    </TagItem>
  );
};

export default TagWithRemove;
