import { getNewerMessage } from '@api/livechat/EngagementMessage';
import { TicketMessage } from '@model/MessageItem';
import { AnyEventObject } from 'xstate';
import { IChatHistory, IMessageBoxMachineContext } from '../messageBoxMachine';
import { getMessage, isPersonMessage, messageGenerator } from './messageGenerator';

export const fetchNextMessagesWithTime = async (context: IMessageBoxMachineContext, event: AnyEventObject) => {
  try {
    const { chatHistory, userId } = context;
    const res = await getNextMessages(userId, chatHistory);
    context.chatHistory = {
      ...context.chatHistory,
      nextRemain: res.nextRemain,
      messages: res.messages,
    };
    return context;
  } catch (error) {
    throw error;
  }
};

const getNextMessages = async (userId: string, chatHistory: IChatHistory) => {
  const lastMessage = chatHistory.messages[chatHistory.messages.length - 1];
  if (lastMessage instanceof TicketMessage || (lastMessage instanceof isPersonMessage(lastMessage) && userId)) {
    const { data } = await getNewerMessage(userId, lastMessage.createdDate);
    const userMessage = data.userMessages.map((message) => getMessage(message));
    const processMessage = messageGenerator(chatHistory.messages, userMessage);
    const res = {
      nextRemain: data.userMessages.length > 0,
      messages: processMessage,
    };
    return res;
  }
  return {
    previousRemain: false,
    nextRemain: false,
    messages: [],
  };
};
