import { PRIMARY } from '@styles/theme';
import { DatePicker, Divider, Typography } from 'antd';
import styled from 'styled-components/macro';

export const RangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
  border-radius: 8px;
`;

export const AbstractPickerCell = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50% !important;
  font-size: 12px;
`;

export const PickerTodayCell = styled(AbstractPickerCell)`
  border: 1px ${PRIMARY} solid;
`;

export const PickerSelectedCell = styled(AbstractPickerCell)`
  background-color: ${PRIMARY};
`;

export const PickerInRangeCell = styled(AbstractPickerCell)``;

export const PickerCell = styled(AbstractPickerCell)``;

export const TextBox = styled.div`
  margin-top: 16px;
  text-align: center;
  display: block;
  line-height: 40px;
  height: 40px;
`;

export const CustomDivider = styled(Divider)`
  margin: 2px;
`;

export const Text = styled(Typography.Title)``;
