import React from 'react';
import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSidebar } from './context';
import { DEPTH_RATIO } from './constants';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
const { SubMenu: AntdSubMenu } = AntdMenu;

type ISubMenuProps = {
  label: string;
  iconName?: IconProp;
  to?: string;
  level?: number;
  [key: string]: any;
};

const Container = styled(AntdSubMenu)<{ collapsed: boolean; level: number; selected: boolean; [key: string]: any }>`
  color: ${({ theme }) => theme.GREY_5} !important;
  cursor: pointer;
  background: transparent !important;
  font-size: 1.3rem;
  padding: 0 20px !important;
  padding-left: ${({ level }) => (level !== 1 ? (level * DEPTH_RATIO) / 14 : 1)}rem !important;
  padding-right: ${({ level }) => level > 1 && 0}px !important;
  user-select: none !important;
  ul {
    background: transparent !important;
  }
  & > .ant-menu-submenu-title {
    width: 100%;
    min-width: 40px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0 !important;
    color: ${({ theme, selected, level }) =>
      selected && level === 1 ? theme.WHITE : selected && level === 2 ? theme.PRIMARY : theme.GREY_5} !important;
    background: ${({ theme, selected, level }) => (level === 1 && selected ? theme.PRIMARY : 'transparent')} !important;
    &:hover {
      background: ${({ theme, selected }) => !selected && theme.GREY_2};
      color: ${({ theme, level }) => (level >= 3 ? theme.WHITE : theme.GREY_5)};
    }
    svg {
      margin-left: 10.5px;
    }
    span {
      margin-left: 0.5rem;
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), min-width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        color 0.3s;
      ${({ collapsed }) =>
        collapsed &&
        `
        max-width: 0;
        display: inline-block;
        opacity: 0;
    `};
    }
    i {
      color: ${({ theme }) => theme.WHITE} !important;
      transition: opacity 0.3s;
      opacity: 1;
      display: block;
      ${({ collapsed }) =>
        collapsed &&
        `
        display: block !important;
        opacity: 0;
      `};
    }
  }
`;

const SubMenu: React.FC<ISubMenuProps> = ({ label, iconName, level, children, ...props }: ISubMenuProps) => {
  const { collapsed, setCollapsed, selectedMenu } = useSidebar();
  const handleClickIcon = () => {
    if (collapsed) {
      setCollapsed(false);
    }
  };
  return (
    <Container
      onTitleClick={handleClickIcon}
      title={<span>{label}</span>}
      onClick={handleClickIcon}
      icon={iconName && <FontAwesomeIcon icon={iconName} />}
      collapsed={collapsed}
      data-tip={collapsed ? label : ''}
      level={level as number}
      selected={`/${selectedMenu.split('/')[1]}` === props.to && (level as number) === 1}
      {...props}
    >
      {children}
    </Container>
  );
};

export default SubMenu;
