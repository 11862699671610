import React from 'react';
import styled from 'styled-components/macro';
import { Menu as AntdMenu } from 'antd';
import { useSidebar } from './context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '@assets/images/ams_white.png';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
const { Item } = AntdMenu;

const Container = styled(Item)<{ collapsed: boolean }>`
  ${({ collapsed }) =>
    collapsed &&
    `img {
    opacity: 0 !important;
    max-width: 0;
  }`}
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent !important;
  cursor: initial;
  position: relative;
  margin: 0.5rem 0 1.5rem 0 !important;
  img {
    transition: opacity 0.3s;
    opacity: 1;
    height: 24px;
    margin-right: 21px !important;
  }
  user-select: none;
  background: transparent;
  padding: 0.5rem !important;
  svg {
    font-size: 21px;
    min-width: 21px;
    margin-left: 15.5px;
    cursor: pointer;
    vertical-align: 0;
  }
`;

const Burger: React.FC = (props) => {
  const { collapsed, setCollapsed } = useSidebar();
  return (
    <Container title="" {...props} collapsed={collapsed}>
      <FontAwesomeIcon onClick={() => setCollapsed(!collapsed)} icon={['fas', 'bars']} color="white" />
      <img src={Logo} />
    </Container>
  );
};

export default Burger;
