/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { client } from '@api';
import { people, teams } from './mock';
import { IAddTagRequestParams, ITicketTag } from '@types';

type RosolveTicketPayload = {
  categories: string[] | undefined;
  note: string | undefined;
  result: string[] | undefined;
  reasons: string[] | undefined;
};

type RosolveTicketPayloadMVP = {
  categories: string[] | undefined;
  note: string | undefined;
  result: string[] | undefined;
  reasons: string[] | undefined;
};

export const getPeople = async () => {
  //TODO add real api
  return import.meta.env.VITE_APP_MOCK_DATA ? Promise.resolve(people) : Promise.resolve(people); //client.get('/api/people');
};

export const getTeams = async () => {
  return import.meta.env.VITE_APP_MOCK_DATA ? Promise.resolve(teams) : Promise.resolve(teams); //client.get('/api/teams');
};

export const updateTicketSummary = (payload: { data: RosolveTicketPayload; ticketId: string }) => {
  return client.post(`/api/tickets/${payload.ticketId}/summary`, { summary: payload.data });
};

export const updateTicketSummaryMVP = (payload: { data: RosolveTicketPayloadMVP; ticketId: string }) => {
  return client.post(`/api/tickets/${payload.ticketId}/summary`, { summary: payload.data });
};

export const getRecentTicketByUserId = (userId: string) => {
  if (userId) {
    return client.get(`/api/tickets/recent/user/${userId}`);
  }
  return null;
};

export const resolveTicket = (ticketId: string, nid: string) => {
  return client.post(`/api/tickets/resolve`, {
    id: ticketId,
    nid: nid,
  });
};

export const updateTicketTags = (ticketId: string, payload: IAddTagRequestParams) => {
  return client.post(`/api/tickets/${ticketId}/tag`, payload);
};

export const updateFollowUp = (ticketId: string, status: { flag: 'follow' | 'unfollow' }) => {
  return client.post(`/api/tickets/${ticketId}/follow-ups`, status);
};

export const updateTeamFollowUp = (ticketId: string, status: { flag: 'follow' | 'unfollow' }) => {
  return client.post(`/api/tickets/${ticketId}/team-follow-up`, status);
};
