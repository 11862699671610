import React from 'react';

import { Container, Item, TopSection } from './style';
import { Font14G3W400, Spacing } from '@components/UtilsComponent';
import { BOTS } from '@types';

export type IButtonsPreviewProps = {
  buttons: BOTS.IButtonsMessageApi;
};

export const ButtonsPreview = ({ buttons }: IButtonsPreviewProps) => {
  return (
    <Container>
      <TopSection>
        <Font14G3W400 className="break-words">{buttons.text}</Font14G3W400>
      </TopSection>
      {buttons.actions.map((button, index) => {
        return (
          <React.Fragment key={button.id}>
            <Item>
              <button className="overflow-hidden overflow-ellipsis whitespace-nowrap">{button.label}</button>
            </Item>
            {index !== buttons.actions.length - 1 ? null : <Spacing height={20} />}
          </React.Fragment>
        );
      })}
    </Container>
  );
};
