/**
 * determine input mime is video or not
 *
 * @param {String} mimeType mime type of file
 *
 * @returns {Boolean} is current video in boolean
 */
const isVideoByMime = (mimeType: string) => {
  return !!mimeType.match('video.*');
};

export default isVideoByMime;
