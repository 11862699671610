import React from 'react';
import { PaneContainer, StateMessageContainer } from '../style';
import { UserTaskItem } from '../userListMachine.type';
import chat_empty from '@assets/images/chat_empty.svg';
import error from '@assets/images/error.svg';
import useTranslation from '@hooks/useTranslation';
import StateMessage from '@components/StateMessage';
import LoadingCircle from '@components/Loading';
import UserList_ from '@pages/components/UserList_';
import { isEmpty } from 'lodash';
import UserList from '@model/UserList';
import { UserDataItem } from '@types';

export type IUserListPane = {
  data: UserTaskItem[];
  loadMore: () => void;
  hasNextPage?: boolean;
  isFailed?: boolean;
  isLoading?: boolean;
  isNextPageLoading?: boolean;
};

const UserListPane = (props: IUserListPane) => {
  const { data, isFailed, isLoading, hasNextPage, loadMore } = props;
  const t = useTranslation();

  const userData = data.map(({ isRead, user, ticket, message }) => {
    return {
      isRead,
      user: {
        id: user._id,
        name: user.name,
        img: user.image,
        message: UserList.getDescriptionMessage(message.payload.messageType as string, message.payload.text as string),
        time: message?.createdAt ? message.createdAt : Date.now(),
        channelType: ticket.channelType,
        userSocialId: user.referenceId,
        displayName: user.displayName,
      },
      ticket,
    } as UserDataItem;
  });

  if (isFailed) {
    return (
      <StateMessage
        size="medium"
        type="general"
        img={error}
        label={t('livechat.error.userlist.label')}
        description={t('livechat.error.userlist.description')}
      />
    );
  }

  if (isEmpty(userData) && isLoading) {
    return <LoadingCircle />;
  }

  if (userData.length > 0) {
    return (
      <PaneContainer>
        <UserList_ userData={userData} hasMore={hasNextPage ?? false} handleLoadmore={loadMore} />
      </PaneContainer>
    );
  }

  return (
    <StateMessageContainer>
      <StateMessage
        size="medium"
        type="general"
        img={chat_empty}
        label={t('livechat.empty.userlist.label')}
        description={t('livechat.empty.userlist.description')}
      />
    </StateMessageContainer>
  );
};

export default UserListPane;
