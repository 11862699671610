import { ERole } from '@enums/Role';
import { ETicketStatus } from '@enums/TicketStatus';
import { IMember, ITicket } from '@types';

export const canResolveTicket = (ticket: ITicket, currentAgent: IMember): boolean => {
  const isAssigned = ticket.status === ETicketStatus.ASSIGNED;
  const isAgentOrSup = currentAgent.roles[0] === ERole.AGENT || currentAgent.roles[0] === ERole.SUPERVISOR;
  const isOwn = ticket.agentId === currentAgent._id;
  // return false if ticket is assigned but no agent take it
  if (isAssigned && !ticket.agentId) {
    return false;
  }
  // return false if current agent is agent role or supervisor and ticket is not own
  if (isAssigned && isAgentOrSup && !isOwn) {
    return false;
  }
  return true;
};
