import React, { useContext, createContext } from 'react';
import { ICustomField, TicketHistory } from '@types';
import { useQuery, UseQueryResult } from 'react-query';
import { getAudienceByUserId, getTicketsByUserId } from '@api/livechat/EngagementUserProfile';
import { ETicketStatus } from '@enums/TicketStatus';

export type IEngagementUserProfile = {
  userId: string;
  children: React.ReactNode;
};

export const EngagementUserProfileContext = createContext<{
  currentAssignedTicket: UseQueryResult<TicketHistory, unknown>;
  ticketHistory: UseQueryResult<TicketHistory, unknown>;
  audienceInfo: UseQueryResult<ICustomField[], unknown>;
}>({
  currentAssignedTicket: {} as UseQueryResult<TicketHistory, unknown>,
  ticketHistory: {} as UseQueryResult<TicketHistory, unknown>,
  audienceInfo: {} as UseQueryResult<ICustomField[], unknown>,
});

export const useEngagementUserProfile = () => useContext(EngagementUserProfileContext);

const EngagementUserProfileProvider: React.FC<IEngagementUserProfile> = ({
  userId,
  children,
}: IEngagementUserProfile) => {
  const userProfileData = useQuery(['getTicketByUserId', userId], () =>
    getTicketsByUserId(userId, ETicketStatus.RESOLVED, true),
  );

  const currentAssignedTicket = useQuery(['getAssignedTicketByUserId', userId], () =>
    getTicketsByUserId(userId, ETicketStatus.ASSIGNED),
  );

  const audienceData = useQuery<ICustomField[]>(['getAudienceInfo', userId], () => getAudienceByUserId(userId));
  return (
    <EngagementUserProfileContext.Provider
      value={{
        currentAssignedTicket: currentAssignedTicket,
        ticketHistory: userProfileData,
        audienceInfo: audienceData,
      }}
    >
      {children}
    </EngagementUserProfileContext.Provider>
  );
};

export default EngagementUserProfileProvider;
