import parseFileDataFromUrl from '@utils/parseFileDataFromUrl';
import React from 'react';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { Container, FileName, FileSizeWrap } from './style';
import { getOgData } from '@api/og';
import { useQuery } from 'react-query';
import { Font14G1W700 } from '@components/UtilsComponent';
import { SkeletonOgImage, SkeletonOgTitle } from '@components/Skeleton';

export type ILinkMessage = {
  url: string;
};

export const LinkMessage: React.FC<ILinkMessage> = ({ url }: ILinkMessage) => {
  const { ext, name } = parseFileDataFromUrl(url) || {};

  if (ext) {
    return (
      <Container download href={url} target="_blank">
        <FileSizeWrap>
          <FileIcon extension={ext} {...defaultStyles[ext as DefaultExtensionType]} />
          <FileName ellipsis={{ rows: 2, suffix: `.${ext}` }}>{name}</FileName>
        </FileSizeWrap>
      </Container>
    );
  }

  return (
    <Container download href={url} target="_blank" className="min-h-[63px]">
      <FileSizeWrap target="_blank" rel="noreferrer">
        <OgUrl url={url} />
      </FileSizeWrap>
    </Container>
  );
};

const OgUrl = ({ url }: ILinkMessage) => {
  const { data, isLoading } = useQuery(['og', url], () => getOgData(url), {
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const _renderContent = () => {
    if (isLoading) {
      return (
        <div className="gap-x-[8px] mt-[8px] flex">
          <div className="flex-1 flex flex-col gap-y-[4px]">
            <SkeletonOgTitle className="!w-[100px]" active />
            <SkeletonOgTitle className="!w-[150px]" active />
            <SkeletonOgTitle className="!w-[130px]" active />
          </div>
          <div>
            <SkeletonOgImage active />
          </div>
        </div>
      );
    }
    if (!data) return null;
    return (
      <div className="gap-x-[8px] mt-[8px] flex">
        <div
          style={{
            height: 'min-content',
          }}
          className="flex-1 border-solid border-l-[3px] border-primary pl-[4px]"
        >
          <Font14G1W700 className="line-clamp-2">{data.title}</Font14G1W700>

          <p className="line-clamp-2  overflow-hidden text-ellipsis !text-[#BDBDBD] leading-5">{data.description}</p>
        </div>
        {data.image ? (
          <div className="w-[54px] h-[54px] border border-solid border-black rounded-lg p-1">
            <img className="w-full h-full object-contain" src={data.image} alt="og" />
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div className="flex flex-col">
      <span className="line-clamp-1">{url}</span>
      <div className="h-full flex-1">{_renderContent()}</div>
    </div>
  );
};
