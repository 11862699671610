import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, SheetHeader, SpanBtn, TextContainer, Content, ButtonContainer } from './style';
import useTranslation from '@hooks/useTranslation';
import { Text } from '@components/UtilsComponent';
import { Typography } from 'antd';
import { PrimaryButton } from '@components/Button';
import LoadingIcon from '@components/LoadingIcon';
import error_message from '@assets/images/error_message.svg';
import StateMessage from '@components/StateMessage';
import { ITicket } from '@types';

export type IConfirmSelect = {
  ticket: ITicket;
  height?: number;
  isLoading: boolean;
  isError: boolean;
  handleBack: () => void;
  handleConfirmAssignee: () => void;
};

const ConfirmSelect: React.FC<IConfirmSelect> = ({
  ticket,
  height,
  isLoading,
  isError,
  handleConfirmAssignee,
  handleBack,
}: IConfirmSelect) => {
  const t = useTranslation();
  const _renderContent = useCallback(() => {
    if (isError) {
      return (
        <StateMessage
          size="medium"
          type="error"
          img={error_message}
          label={t('livechat.error.userlist.label')}
          description={t('livechat.error.userlist.description')}
        />
      );
    }

    return (
      <>
        <div>
          <Typography.Title level={3}>{t('livechat.mobile.assign.ticket.confirm.label')}</Typography.Title>
          <Typography.Text>{t('livechat.mobile.assign.ticket.confirm.description')}</Typography.Text>
        </div>
        <ButtonContainer>
          <PrimaryButton onClick={handleConfirmAssignee} size="large" disabled={isLoading}>
            {isLoading && <LoadingIcon icon={['fas', 'sync-alt']} />}
            {t('livechat.mobile.assign.ticket.confirm.btn')}
          </PrimaryButton>
        </ButtonContainer>
      </>
    );
  }, [handleConfirmAssignee, isError, isLoading, t, ticket.ticketNumber]);
  return (
    <Container>
      <SheetHeader>
        <SpanBtn onClick={handleBack}>
          <FontAwesomeIcon icon={['fas', 'arrow-left']} />
        </SpanBtn>
        <TextContainer>
          <Text>{t('livechat.mobile.get.change.assignee.label')}</Text>
        </TextContainer>
      </SheetHeader>
      <Content height={height}>{_renderContent()}</Content>
    </Container>
  );
};

export default ConfirmSelect;
