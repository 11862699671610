import React, { Suspense, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import TeamManagement from './team_management';
import Recents from './recents';
import Settings from './settings';
import { RecoilRoot } from 'recoil';
import TicketManagement from './ticket_management';
import { useMember } from '@providers/MemberProvider';
import SideMenu from '@components/SideMenu';
import { sideBarData } from '@configs/sidebar';
import { Report } from './report';
import { ROUTE } from '@configs/route';
import { PrivateRoute } from '@routes/PrivateRoutes';
import Login from '@pages/auth/login';
import ChangePassword from '@pages/auth/change_password';
import { OperationalDashboard } from './operational_dashboard';

const LiveChatDesktop: React.FC = () => {
  const { member, cimPermission, handleLogout, handleChangeMemberStatus } = useMember();
  const _renderSideMenu = useCallback(() => {
    return (
      <SideMenu
        handleLogout={handleLogout}
        member={member}
        cimPermission={cimPermission}
        sideBarData={sideBarData}
        handleChangeMemberStatus={handleChangeMemberStatus}
      />
    );
  }, [handleChangeMemberStatus, handleLogout, member]);

  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        <Route path="/" exact>
          <Redirect to={ROUTE.RECENTS} />
        </Route>
        <Route path={ROUTE.OPERATIONAL_DASHBOARD}>
          <PrivateRoute>
            {_renderSideMenu()}
            <OperationalDashboard />
          </PrivateRoute>
        </Route>
        <Route path={ROUTE.TICKET_MANAGEMENT}>
          <PrivateRoute>
            {_renderSideMenu()}
            <TicketManagement />
          </PrivateRoute>
        </Route>

        <Route path={ROUTE.TEAM_MANAGEMENT}>
          <PrivateRoute>
            {_renderSideMenu()}
            <TeamManagement />
          </PrivateRoute>
        </Route>

        <Route path={ROUTE.RECENTS}>
          <PrivateRoute>
            {_renderSideMenu()}
            <RecoilRoot>
              <Recents />
            </RecoilRoot>
          </PrivateRoute>
        </Route>

        <Route path={ROUTE.SETTINGS}>
          <PrivateRoute>
            {_renderSideMenu()}
            <Settings />
          </PrivateRoute>
        </Route>

        <Route path={ROUTE.REPORT_WITH_ID} key="report-2">
          <PrivateRoute>
            <Report />
          </PrivateRoute>
        </Route>

        <Route path={ROUTE.REPORT} key="report-1">
          <PrivateRoute>
            <Report />
          </PrivateRoute>
        </Route>
        {import.meta.env.DEV && (
          <>
            <Route path={ROUTE.LOGIN}>
              <Login />
            </Route>

            <Route path={ROUTE.CHANGE_PASSWORD}>
              <ChangePassword />
            </Route>
          </>
        )}
      </Switch>
    </Suspense>
  );
};

export default LiveChatDesktop;
