import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px 8px 0px;
`;

export const ArrowWrap = styled.div`
  margin-top: 24px;
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-end;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;
