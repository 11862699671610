import styled from 'styled-components/macro';

export const Container = styled.div`
  .ant-input-textarea-show-count::after {
    float: right;
    color: #9e9e9e;
    white-space: nowrap;
    pointer-events: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
  }
`;
