import React from 'react';
import { Button, GhostButton, PrimaryButton } from '@components/Button';
import { Popover } from '@components/Popover';
import { Counter, Font12G5W400, Font16G1W600, Font20G1W600 } from '@components/UtilsComponent';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import Select, { IOption } from '@components/Select';
import TextInput from '@components/TextInput';
import { CustomFormItem } from '@components/CustomFormItem';
import { MAX_OPTION_LABEL_LENGTH } from '@configs/constants';
import useTranslation from '@hooks/useTranslation';
import { useSurveyPopover } from './useSurveyPopover';
type ISurveyPopoverProps = {
  points: IOption[];
  regionId: string;
  outSidepoint?: string;
  outSideMessage?: string;
  isPopover: boolean;
  children: React.ReactNode;
  onDone: ({ point, message }: { point?: string; message: string }) => void;
  onCancel: () => void;
  onDelete: () => void;
  onActionPopoverChange: () => void;
};

export const SurveyPopover = ({
  points,
  outSidepoint,
  outSideMessage = '',
  regionId,
  children,
  isPopover,
  onActionPopoverChange,
  onDelete,
  onDone,
  onCancel,
}: ISurveyPopoverProps) => {
  const t = useTranslation();
  const { point, message, onSelectPoint, onUpdateText } = useSurveyPopover(outSidepoint, outSideMessage);
  return (
    <Popover
      overlayInnerStyle={{
        borderRadius: '12px',
        overflow: 'hidden',
      }}
      trigger={['click']}
      onVisibleChange={() => {
        //outside component
        onActionPopoverChange();
      }}
      destroyTooltipOnHide={true}
      overlayClassName="amity-popover"
      visible={isPopover}
      placement="right"
      content={
        <div className="overflow-hidden" data-wrapper="amity-popover-wrapper">
          <div className="flex items-center justify-between w-[352px] px-[24px] pb-[12px] pt-[24px]">
            <Font20G1W600>{t('customer.satisfaction.editor.imagemap.label')}</Font20G1W600>

            <GhostButton
              className="flex items-center !text-[#f72c40]"
              onClick={onDelete}
              icon={<FontAwesomeIcon className="mr-[8px] text-[16px]" icon={faTrash} />}
            >
              {t('customer.satisfaction.editor.imagemap.delete.btn.label')}
            </GhostButton>
          </div>
          <div className="w-full h-[1px] bg-[#E5E5E5]" />
          <div className="px-[24px] py-[12px] flex flex-col gap-y-[8px]">
            <Font16G1W600>{t('customer.satisfaction.editor.imagemap.point.label')}</Font16G1W600>
            <Select
              value={point}
              placeholder="Point"
              options={points}
              size="large"
              onChange={(value: string) => {
                onSelectPoint(value);
              }}
            />
          </div>
          <div className="px-[24px] pt-[12px] flex flex-col gap-y-[8px] mt-[16px]">
            <Font16G1W600>{t('customer.satisfaction.editor.imagemap.message.label')}</Font16G1W600>
            <CustomFormItem
              id={`imagemap-${regionId}`}
              isError={!message}
              errorMessage={t('customer.satisfaction.editor.imagemap.message.validate')}
              value={message}
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                onUpdateText(e.target.value);
              }}
              counter={
                <Counter>
                  <Font12G5W400>{`${message.length ? message.length : 0} / ${MAX_OPTION_LABEL_LENGTH}`}</Font12G5W400>
                </Counter>
              }
            >
              <TextInput
                size="large"
                placeholder={t('customer.satisfaction.editor.imagemap.message.label.placeholder')}
                maxLength={MAX_OPTION_LABEL_LENGTH}
              />
            </CustomFormItem>
          </div>
          <div className="flex justify-between gap-x-[8px] p-[24px]">
            <Button
              size="large"
              className="w-full"
              onClick={() => {
                onSelectPoint(outSidepoint);
                onUpdateText(outSideMessage);
                onCancel();
              }}
            >
              {t('cancel.btn')}
            </Button>
            <PrimaryButton
              size="large"
              disabled={!message || !point}
              className="w-full"
              onClick={() => {
                onDone({
                  point,
                  message,
                });
              }}
            >
              {t('done.btn')}
            </PrimaryButton>
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );
};
