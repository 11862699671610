import React from 'react';
import { Container } from './style';
import { GhostButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type IIconMoveProps = {
  isActive: boolean | null;
  isLoading: boolean;
  activeIcon: IconProp;
  inactiveIcon: IconProp;
  activeColor: string;
  inActiveColor: string;
  isDisabled?: boolean;
  onClick: () => void;
};

export const IconMove = ({
  isActive,
  isDisabled,
  activeIcon,
  inactiveIcon,
  activeColor,
  inActiveColor,
  onClick,
}: IIconMoveProps) => {
  // prevent render animation on first render
  if (isActive === null) return null;
  return (
    <Container isActive={isActive} activeColor={activeColor} inActiveColor={inActiveColor}>
      <div>
        <GhostButton disabled={isDisabled} onClick={() => onClick()}>
          <FontAwesomeIcon className={`icon-enable ${isActive ? 'active' : ''}`} icon={activeIcon} />
          <FontAwesomeIcon className={`icon-disable ${!isActive ? 'active' : ''}`} icon={inactiveIcon} />
        </GhostButton>
      </div>
    </Container>
  );
};
