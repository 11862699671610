import styled from 'styled-components/macro';

export const Container = styled.div`
  background: white;
  border-radius: 12px;
  min-width: 220px;
  max-width: 290px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 16px 0;
  border-radius: 8px;
  text-align: center;
  button {
    font-size: 14px;
    font-weight: 400;
    color: #42659a;
    border: none;
    background: transparent;
  }
`;

export const TopSection = styled.div`
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.GREY_8};
`;
