import React from 'react';

import { Container, Group, ButtonUploadWrap, List, ListItem, HintWrap, Footer, UrlTextWrap } from './style';
import './style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Font14G5W400, Font16G1W600, TrashIcon } from '@components/UtilsComponent';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { Button, PrimaryButton } from '@components/Button';
import TextInput from '@components/TextInput';

export const MAX_IMAGE_FILE_SIZE = 1; //MB

export type IImageSection = {
  selectedMedia?: string;
  showUrlInput?: boolean;
  isSelectedRaw?: boolean;
  isNoUrl: boolean;
  textUrl: string;
  suggestions?: string[];
  beforeUpload: (sourceFile: RcFile, fileList: RcFile[]) => Promise<boolean>;
  onUpload: () => Promise<void>;
  onCancel: () => void;
  onRemoveCurrentUrl: () => void;
  onAddTextUrl?: (url: string) => void;
  onUpdateTextUrl?: (url: string) => void;
  onRemoveDraft: () => void;
};

export const ImageUpload: React.FC<IImageSection> = ({
  selectedMedia,
  showUrlInput,
  isNoUrl,
  isSelectedRaw,
  textUrl = '',
  suggestions = ['Jpeg, Png file format', 'No limit image size', `Max file size ${MAX_IMAGE_FILE_SIZE}mb`],
  onUpdateTextUrl,
  onAddTextUrl,
  beforeUpload,
  onUpload,
  onCancel,
  onRemoveCurrentUrl,
  onRemoveDraft,
}: IImageSection) => {
  return (
    <Container>
      <Group>
        <Font16G1W600>Upload file</Font16G1W600>
        {selectedMedia ? (
          <UrlTextWrap>
            <Typography.Text ellipsis={true}>{selectedMedia}</Typography.Text>
            <TrashIcon icon={faTrash} onClick={onRemoveCurrentUrl} />
          </UrlTextWrap>
        ) : (
          <ButtonUploadWrap>
            <Upload beforeUpload={beforeUpload} maxCount={1} accept=".jpg,.png" onRemove={onRemoveDraft}>
              <Button icon={<FontAwesomeIcon icon={['fas', 'arrow-to-top']} />} size="large">
                Upload
              </Button>
            </Upload>
          </ButtonUploadWrap>
        )}
      </Group>
      {showUrlInput && (
        <Group>
          <Font16G1W600>Image url</Font16G1W600>
          <TextInput
            value={textUrl}
            onChange={(e) => onAddTextUrl && onAddTextUrl(e.target.value)}
            placeholder="https://"
            size="large"
            disabled={!!selectedMedia || isSelectedRaw}
          />
        </Group>
      )}

      <Group>
        <HintWrap>
          <Font14G5W400>Image</Font14G5W400>
          <List>
            {suggestions.map((suggestion, index) => {
              return <ListItem key={index}>{suggestion}</ListItem>;
            })}
          </List>
        </HintWrap>
      </Group>
      <Footer>
        <Button size="large" onClick={onCancel}>
          Cancel
        </Button>
        {textUrl ? (
          <PrimaryButton size="large" onClick={() => onUpdateTextUrl && onUpdateTextUrl(textUrl)}>
            Upload
          </PrimaryButton>
        ) : (
          <PrimaryButton size="large" onClick={onUpload} disabled={isNoUrl}>
            Upload
          </PrimaryButton>
        )}
      </Footer>
    </Container>
  );
};
