import React, { useState, useCallback, useMemo } from 'react';
import { IAssigneeMachineContext } from '@pages/components/Assignee/assigneeMachine';
import { ITeam, ITicket } from '@types';
import { twMerge } from 'tailwind-merge';
import SearchInput from '@components/SearchInput';
import Avatar from '@components/Avatar';
import { scrollHandler } from '@utils/scroll';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';

type ITeamTabProps = {
  internalSelectedTeam: string | null;
  ticket: ITicket;
  list: IAssigneeMachineContext['teamData']['rows'];
  searchTeamHandler: {
    keywords: string;
    setKeywords: (value: string) => void;
  };
  isLoadingMoreTeam: boolean;
  onSelectTeam: (team: ITeam) => void;
  onLoadmoreTeam: () => void;
};

export const TeamTab = ({
  internalSelectedTeam,
  ticket,
  list,
  isLoadingMoreTeam,
  searchTeamHandler,
  onSelectTeam,
  onLoadmoreTeam,
}: ITeamTabProps) => {
  const [searchText, setSearchText] = useState<string>('');

  const filterTeam = useMemo(() => {
    return list?.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchText, internalSelectedTeam, list]);

  const highlight = (teamId: string) => {
    if (!ticket) return 'bg-transparent';
    // selected team
    if (ticket.team === teamId) return 'border-[#04BE8C] bg-[#e6f8f3] opacity-50';
    if (internalSelectedTeam === teamId) return 'border-[#04BE8C] bg-[#e6f8f3]';
    return 'bg-transparent';
  };

  const _renderList = useCallback(() => {
    if (!list) return null;
    return (
      <div className="grid grid-cols-3 gap-[16px] mt-[16px] pr-2">
        {list?.map((item) => {
          const mButton = twMerge(
            'flex p-[12px] rounded-[8px] border border-[#E5E5E5] border-solid overflow-hidden gap-x-[8px] items-center',
            highlight(item._id),
          );
          return (
            <button
              key={item._id}
              className={mButton}
              title={item.name}
              onClick={() => {
                onSelectTeam(item);
              }}
            >
              <Avatar
                name={item.name}
                width={32}
                height={32}
                customName={(name) => {
                  if (!name) return '-';
                  // return 2 string from name
                  if (name.length > 1) {
                    return `${name[0]}${name[1]}`;
                  }
                  return `${name[0]}`;
                }}
              />
              <div>{item.name}</div>
            </button>
          );
        })}
      </div>
    );
  }, [filterTeam, internalSelectedTeam]);

  return (
    <div>
      <div className="flex gap-x-[8px] mt-[24px] w-[418px]">
        <SearchInput
          className="min-w-[230px] max-w-[230px]"
          value={searchTeamHandler.keywords}
          onChange={(e) => searchTeamHandler.setKeywords(e.target.value)}
          onClear={() => {
            searchTeamHandler.setKeywords('');
          }}
        />
      </div>
      <div
        className="h-[500px] max-h-[500px] overflow-auto"
        onScroll={(e) => {
          if (isLoadingMoreTeam) return;
          scrollHandler.onScrollToBottom(e, onLoadmoreTeam);
        }}
      >
        {_renderList()}
        {isLoadingMoreTeam && (
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        )}
      </div>
    </div>
  );
};
