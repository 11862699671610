import React, { useEffect, useMemo } from 'react';
import { Container, Counter } from './style';
import { DropdownTags } from '@components/Dropdown/DropdownTags';
import useTranslation from '@hooks/useTranslation';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IOutcomeBE } from '@types';
import { IOption } from '@components/Select';
import { fetchOutcome } from '@api/livechat/Wrapup';

import { Font12G5W400 } from '@components/UtilsComponent';
import { scrollHandler } from '@utils/scroll';

type IOutcomeSelectProps = {
  selectedValues: string[];
  max?: number;
  validateText?: string;
  onDelete: (item: string) => void;
  onSelect: (item: string) => void;
  onClearSelect: () => void;
};

export const OutcomeSelect = ({
  selectedValues = [],
  validateText,
  max,
  onDelete,
  onSelect,
  onClearSelect,
}: IOutcomeSelectProps) => {
  const t = useTranslation();
  const [keyword, setKeyword] = React.useState('');
  const selected = useMemo(() => {
    return selectedValues.map((v) => {
      return {
        id: v,
        name: v,
      };
    });
  }, [selectedValues]);

  const { data, hasMore, isLoadingMore, onLoadmore, onOpenDropdown } = useInfinityLoadByCursorId<IOutcomeBE, IOption>({
    fetchFn: async (filter, cancelToken) => {
      const newFilter = {
        cursorId: filter.cursorId ?? '',
        keyword: filter.keyword ?? '',
        take: filter.take,
      };
      const data = await fetchOutcome(
        newFilter.take,
        newFilter.keyword || undefined,
        newFilter.cursorId || undefined,
        cancelToken,
      );
      const result = data.map((item) => {
        return {
          ...item,
          id: item.id,
          label: item.name,
          value: item.name,
        };
      });
      return result;
    },
    initialFilter: {
      take: 10,
    },
  });

  useEffect(() => {
    onOpenDropdown();
  }, []);

  const filterData = useMemo(() => {
    return data.filter((v) => v.name.trim().toLocaleLowerCase().includes(keyword.trim().toLocaleLowerCase()));
  }, [data, keyword]);

  return (
    <Container>
      <div
        style={{
          border: validateText ? '1px solid #F72C40' : '',
        }}
        className="rounded-[8px]"
      >
        <DropdownTags<IOutcomeBE>
          placeholder={t('livechat.ticket.console.resolve.result.placeholder')}
          callback={onOpenDropdown}
          dropdownClassName="z-[9999999]"
          maxHeight={100}
          isDisabled={false}
          options={filterData}
          onScroll={(e) => {
            if (hasMore && !isLoadingMore) {
              scrollHandler.onScrollToBottom(e, onLoadmore);
            }
          }}
          canClear
          onClearSelect={onClearSelect}
          onUpdateSearchKeyword={(value) => setKeyword(value)}
          keyword={keyword}
          selectedTag={selected}
          onDeleteTag={(value: string) => {
            onDelete(value);
          }}
          checkedFn={(option) => selected.some((v) => v.name === option.name)}
          onSelect={(value) => {
            const val = value.name;
            onSelect(val);
          }}
          onClearSearchKeyword={() => {
            setKeyword('');
          }}
          emptyWord={t('no.matching.result')}
          fullMessage={selected.length === max ? t('maximum.selection.limit.exceeded') : ''}
          tagClassName="outcome"
        />
      </div>
      <div className="flex justify-between mt-[4px]">
        {<Font12G5W400 className="!text-[#F72C40]">{validateText}</Font12G5W400>}
        <Counter>
          {max ? <Font12G5W400>{`${selected.length ? selected.length : 0} / ${max}`}</Font12G5W400> : null}
        </Counter>
      </div>
    </Container>
  );
};
