import styled from 'styled-components/macro';

export const Container = styled.div`
  margin-top: 16px;
`;

export const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 8px;
  }
`;

export const AdminBadge = styled.div`
  padding: 4px 12px;
  background: ${({ theme }) => theme.GREY_1};
  border-radius: 18px;
  color: white;
  width: fit-content;
  text-transform: capitalize;
`;

export const AgentBadge = styled.div`
  text-transform: capitalize;
`;

export const LoadingWrap = styled.div`
  position: initial !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrap = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #757575;
  svg {
    font-size: 16px !important;
  }
`;

export const UsernameWrap = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const AvatarWrap = styled.div``;
export const DetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  span:nth-child(1) {
    font-weight: 600;
    color: ${({ theme }) => theme.GREY_1};
  }
  span:nth-child(2) {
    font-weight: 600;
    color: ${({ theme }) => theme.GREY_5};
    font-size: 12px;
  }
`;
