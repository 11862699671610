import { getMyTeam } from '@api/livechat/TeamManagement';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { ConfirmModal } from '@components/Modal/ConfirmModal';
import { IOption } from '@components/Select';
import { SelectBtn } from '@components/SelectBtn';
import { DropdownItem, Font14G1W400, Font14G1W600, Font20G1W600 } from '@components/UtilsComponent';
import useTranslation from '@hooks/useTranslation';
import { IGetByCursorParams, ITeam } from '@types';
import React, { useEffect } from 'react';

import './overwrite.css';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
type IModalSelectTeamProps = {
  visible: boolean;
  isLoading: boolean;
  onCloseModal: () => void;
  onConfirm: (internalSelect: ITeam) => void;
};

export const ModalSelectTeam = ({ visible, isLoading, onCloseModal, onConfirm }: IModalSelectTeamProps) => {
  const [internalSelect, setInternalSelect] = React.useState<ITeam | null>(null);
  const t = useTranslation();
  const {
    data,
    hasMore,
    firstLoading,
    isLoadingMore,
    searchKeyword,
    onOpenDropdown,
    onUpdateSearchKeyword,
    onLoadmore,
  } = useInfinityLoadByCursorId<ITeam & { id: string }, IOption>({
    fetchFn: async (filter, cancelToken) => {
      const newFilter: IGetByCursorParams = {
        ...filter,
        cursorId: filter.cursorId,
      };
      const { data }: { data: (ITeam & { id: string })[] } = await getMyTeam(newFilter, cancelToken);
      const result = data.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.id,
        };
      });
      return result;
    },
  });
  useEffect(() => {
    onOpenDropdown();
  }, []);
  return (
    <ConfirmModal
      visible={visible}
      confirmBtnTxt={t('confirm.btn')}
      cancelBtnTxt={t('cancel.btn')}
      width={400}
      bodyWidth="100%"
      handleClose={onCloseModal}
      isLoading={isLoading}
      isDisabledConfirmBtn={!internalSelect}
      handleConfirm={() => {
        if (!internalSelect) return;
        onConfirm(internalSelect);
      }}
    >
      <div className="!flex flex-col gap-y-[8px]">
        <div>
          <Font20G1W600>{t('modal.select.team.title')}</Font20G1W600>
        </div>
        <div>
          <Font14G1W400>{t('modal.select.team.description')}</Font14G1W400>
        </div>
        <div className="h-[1px] w-full bg-[#E5E5E5]" />
        <div />
      </div>
      <div className="mb-[8px]">
        <Font14G1W600>{t('modal.select.team.dropdown.label')}</Font14G1W600>
      </div>
      <div>
        <DropdownDynamicBtn
          options={data}
          hasMore={hasMore}
          searchKeyword={searchKeyword}
          showClearAll
          showSearch
          isFirstLoading={firstLoading || isLoadingMore}
          onClearAllSelection={() => onUpdateSearchKeyword('')}
          onLoadMore={onLoadmore}
          onUpdateSearchKeyword={(keyword) => onUpdateSearchKeyword(keyword)}
          dropdownClassName="modal-select-team-dropdown"
          menuWidth="100%"
          renderBtn={(prevState, setter) => {
            return (
              <div className="relative">
                <SelectBtn
                  selectedText={internalSelect?.name ?? ''}
                  placeholder={t('modal.select.team.placeholder')}
                  onClick={() => {
                    setter(!prevState);
                  }}
                ></SelectBtn>
              </div>
            );
          }}
          renderOptions={function (
            options: (IOption & ITeam)[],
            setter: ((status: React.SetStateAction<boolean>) => void) | undefined,
          ): React.ReactNode {
            return (
              <div className="py-[10px]">
                <div className="flex flex-col gap-y-[8px] mt-[8px]">
                  {options.map((item) => {
                    return (
                      <DropdownItem
                        key={item.value}
                        onClick={() => {
                          setInternalSelect(item);
                          setter ? setter((prev) => !prev) : false;
                        }}
                      >
                        <Font14G1W400>{item.label}</Font14G1W400>
                      </DropdownItem>
                    );
                  })}
                </div>
              </div>
            );
          }}
        ></DropdownDynamicBtn>
      </div>
    </ConfirmModal>
  );
};
