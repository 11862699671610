import { searchTag } from '@api/livechat/Tag';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { IOption } from '@components/Select';
import { SelectBtn } from '@components/SelectBtn';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { ITagResponse } from '@types';

import React, { useCallback, useEffect, useRef } from 'react';
import { Checkbox } from '@components/Checkbox';

import { DropdownItem as DropdownItemStyle } from '../AllTicketsPane/style';

type IDropdownFilterTagProps = {
  selectedTag: string[];
  onSelectDropdown: (items: string[]) => void;
};

export const DropdownFilterTag = ({ selectedTag = [], onSelectDropdown }: IDropdownFilterTagProps) => {
  const { data, searchKeyword, firstLoading, hasMore, onOpenDropdown, onLoadmore, onUpdateSearchKeyword } =
    useInfinityLoadByCursorId<ITagResponse, IOption>({
      fetchFn: async (filter, cancelToken) => {
        const { data }: { data: ITagResponse[] } = await searchTag(filter, cancelToken);
        const result = data.map((item) => {
          return {
            ...item,
            label: item.value,
            value: item.id,
          };
        });
        return result;
      },
    });
  const selectedRef = useRef<string[]>([]);
  const _renderSelectedText = useCallback((selected: string[]): string => {
    // if length more than 1
    if (selected.length > 1) {
      return `${selected.length} tags selected`;
    }

    if (selected.length === 1) {
      return '1 tag selected';
    }
    return '';
  }, []);

  useEffect(() => {
    selectedRef.current = selectedTag;
  }, [selectedTag]);

  return (
    <>
      {/* TAG dropdown */}
      <DropdownDynamicBtn
        showClearAll
        onClearAllSelection={() => {
          selectedRef.current = [];
          onSelectDropdown(selectedRef.current);
        }}
        searchKeyword={searchKeyword}
        onUpdateSearchKeyword={onUpdateSearchKeyword}
        options={data}
        isFirstLoading={firstLoading}
        renderOptions={(options) => {
          return options.map((item) => {
            return (
              <DropdownItemStyle
                key={item.value}
                onClick={() => {
                  const index = selectedRef.current.indexOf(item.id);
                  // check if exist then remove
                  if (selectedRef.current.length > 0 && index > -1) {
                    selectedRef.current.splice(index, 1);
                    onSelectDropdown(selectedRef.current);
                    return;
                  }
                  selectedRef.current.push(item.id);
                  onSelectDropdown(selectedRef.current);
                }}
              >
                <span>{item.label}</span>
                <Checkbox
                  checked={
                    !!selectedTag.find((i) => {
                      return i === item.id;
                    })
                  }
                />
              </DropdownItemStyle>
            );
          });
        }}
        renderBtn={function (
          prevState: boolean,
          setter: (status: React.SetStateAction<boolean>) => void,
        ): React.ReactNode {
          return (
            <SelectBtn
              data-testid="test-dropdown-filter-tag-btn"
              selectedText={_renderSelectedText(selectedTag)}
              placeholder={'All Tags'}
              onClick={() => {
                // toggle dropdown
                setter(!prevState);
                if (!prevState) {
                  onOpenDropdown();
                }
              }}
            />
          );
        }}
        hasMore={hasMore}
        onLoadMore={onLoadmore}
      />
    </>
  );
};
