import React from 'react';
import { OverlayWrap, AntdDropdown } from './style';

export type IBasicDropdownProps = {
  children?: React.ReactNode;
  overlayWidth?: number;
  overlayContent: React.ReactNode;
} & Omit<React.ComponentProps<typeof AntdDropdown>, 'overlay'>;

export const BasicDropdown = ({ overlayContent, disabled, children, overlayWidth, ...rest }: IBasicDropdownProps) => {
  const _renderOverlay = () => {
    return (
      <OverlayWrap
        style={{
          width: overlayWidth ? overlayWidth : 200,
        }}
      >
        {overlayContent}
      </OverlayWrap>
    );
  };

  return (
    <AntdDropdown {...rest} overlay={_renderOverlay()} disabled={disabled}>
      {children}
    </AntdDropdown>
  );
};
