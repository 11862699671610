import React, { useContext } from 'react';
import { useComposerManager as hook } from './useComposerManager';

export type IComposerManagerContext = ReturnType<typeof hook>;

const ComposerManagerContext = React.createContext({} as IComposerManagerContext);

export const useComposerManager = () => useContext(ComposerManagerContext);

export const ComposerManagerProvider: React.FC = ({ children }) => {
  const val = hook();

  return <ComposerManagerContext.Provider value={val}>{children}</ComposerManagerContext.Provider>;
};
