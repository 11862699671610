import styled from 'styled-components/macro';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex !important;
  column-gap: 16px;
  width: 100% !important;
  > div {
    min-width: 368px;
  }
`;

export const ListContainer = styled.ul`
  flex: 1;
  overflow: auto;
  height: 400px;
  display: table;
  border: 1px solid ${({ theme }) => theme.GREY_7};
  border-radius: 8px;
`;

export const ListHeader = styled.div`
  display: table-row;
  height: 48px;
  background: ${({ theme }) => theme.GREY_9};

  > div {
    display: table-cell;
    vertical-align: middle;
    padding: 16px 0px;
  }
  div:nth-child(1) {
    width: 56px;
    border-radius: 8px 0 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
    border-right: none;
  }
  div:nth-child(2) {
    width: 156px;

    border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
    border-left: none;
  }
  div:nth-child(3) {
    text-align: right;
    width: 156px;
    border-radius: 0 8px 0 0;
    border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
    padding-right: 16px;
  }
`;

export const ListItem = styled.li<{ isChecked: boolean }>`
  display: table-row;
  height: 60px;
  padding: 16px;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.GREY_9};
  }
  :not(:last-child) {
    > div {
      border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
    }
  }
  > div {
    background: ${({ isChecked }) => (isChecked ? '#e6f9f4' : 'transparent')};
    display: table-cell;
    vertical-align: middle;
    svg {
      color: ${({ theme, isChecked }) => (isChecked ? theme.PRIMARY : '')};
    }
  }
  div:nth-child(1) {
    min-width: 60px;
    max-width: 60px;
    text-align: center;
  }
  div:nth-child(2) {
    min-width: 290px;
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 290px;
  }
`;

export const ListItemWrapper = styled.div`
  width: 368px;
  height: 400px;
  overflow: auto;
`;

export const SearchWrap = styled.div`
  display: flex !important;
  column-gap: 8px;
`;

export const SpinContainer = styled.div`
  width: 100% !important;
  .ant-spin-nested-loading {
    width: 100%;
  }
`;

export const PreviewContainer = styled.div`
  flex: 1;
  height: 460.14px;
`;
