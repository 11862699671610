import { useEffect, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import { IAllTicketPaneContext, allTicketPane } from './machine';
import { EFilterActionType, IFilterValue } from './machine/event';
import { useAlert } from 'react-alert';
import { ROUTE } from '@configs/route';
import { useMember } from '@providers/MemberProvider';
import { useLocation, useHistory } from 'react-router-dom';
import { DateRange } from '@components/DateRangePicker/useDateRangePicker';
import { ETicketStatus, ESortBy } from '@enums/TicketStatus';
import { compact } from 'lodash';

const defaultFilter = {
  keyword: '',
  onlyFollowUp: false,
  onlyTeamFollowUp: false,
  selectedChannels: [],
  selectedAssignees: [],
  selectedTags: [],
  selectedSortBy: ESortBy.LATEST_MESSAGE,
  selectedStatus: ETicketStatus.ALL,
  selectedDateRange: {} as DateRange,
  page: 1,
  total: 0,
  totalPages: 0,
};

const getDefaultFilter = () => {
  const searchParams = new URLSearchParams(location.search);
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  return {
    ...defaultFilter,
    keyword: searchParams.get('keyword') ?? '',
    onlyFollowUp: !!searchParams.get('onlyFollowUp'),
    onlyTeamFollowUp: !!searchParams.get('onlyTeamFollowUp'),
    selectedStatus: (searchParams.get('selectedStatus') as ETicketStatus) ?? ETicketStatus.ALL,
    selectedSortBy: (searchParams.get('selectedSortBy') as ESortBy) ?? ESortBy.LATEST_MESSAGE,
    selectedChannels: (compact(searchParams.get('selectedChannels')?.split(',')) as string[]) ?? [],
    selectedAssignees: (compact(searchParams.get('selectedAssignees')?.split(',')) as string[]) ?? [],
    selectedTags: (compact(searchParams.get('selectedTags')?.split(',')) as string[]) ?? [],
    selectedDateRange: {
      from: from ? new Date(from) : undefined,
      to: to ? new Date(to) : undefined,
    },
    page: Number(searchParams.get('page')) || 1,
  };
};
const useAllTicketsPane = () => {
  const location = useLocation();
  const history = useHistory();
  const machine = useMemo(() => {
    return allTicketPane(getDefaultFilter());
  }, []);
  const [newCurrent, newSend] = useMachine(machine, {
    devTools: import.meta.env.DEV,
  });
  const alert = useAlert();
  const { isSupervisor, isAdmin, isSuperAdmin } = useMember();

  useEffect(() => {
    // check exportSuccess and display success alert and open new tab with id
    if (newCurrent.matches('report.modalState.exportSuccess')) {
      newSend(EFilterActionType.EXPORT_REPORT_IDLE);
      alert.success('Downloading report');
      window.open(`${ROUTE.REPORT}/${newCurrent.context.report.selectedReport.id}`, '_blank');
      return;
    }
  }, [newCurrent]);

  const updateQueryParamsFromInput = (fieldName: keyof IAllTicketPaneContext['filter'], inp: IFilterValue) => {
    const searchParams = new URLSearchParams(location.search);
    switch (fieldName) {
      case 'keyword':
        searchParams.set('keyword', inp as string);
        break;
      case 'selectedStatus':
        searchParams.set('selectedStatus', inp as string);
        break;
      case 'onlyFollowUp':
        if (!inp) {
          searchParams.delete('onlyFollowUp');
          break;
        }
        searchParams.set('onlyFollowUp', inp as string);
        break;
      case 'onlyTeamFollowUp':
        if (!inp) {
          searchParams.delete('onlyTeamFollowUp');
          break;
        }
        searchParams.set('onlyTeamFollowUp', inp as string);
        break;
      case 'selectedSortBy':
        searchParams.set('selectedSortBy', inp as string);
        break;
      case 'selectedChannels':
        searchParams.set('selectedChannels', (inp as string[]).map((i) => i).join(','));
        break;
      case 'selectedAssignees':
        searchParams.set('selectedAssignees', (inp as string[]).map((i) => i).join(','));
        break;
      case 'selectedTags':
        searchParams.set('selectedTags', (inp as string[]).map((i) => i).join(','));
        break;
      case 'page':
        searchParams.set('page', inp as string);
        break;
      case 'selectedDateRange':
        const dateRange = inp as DateRange;
        searchParams.set('from', dateRange.from ? dateRange.from.toString() : '');
        searchParams.set('to', dateRange.to ? dateRange.to.toString() : '');
        break;
    }
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.replace(newUrl);

    return;
  };

  const clearQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('keyword');
    searchParams.delete('selectedStatus');
    searchParams.delete('onlyFollowUp');
    searchParams.delete('onlyTeamFollowUp');
    searchParams.delete('selectedSortBy');
    searchParams.delete('selectedChannels');
    searchParams.delete('selectedTags');
    searchParams.delete('page');
    searchParams.delete('from');
    searchParams.delete('to');
    searchParams.delete('selectedAssignees');
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    history.replace(newUrl);
  };

  return {
    newCurrent,
    newContext: newCurrent.context,
    isDisplayExportModal:
      newCurrent.matches('report.modalState.idle') || newCurrent.matches('report.modalState.exporting'),
    isDisabledExportConfirmBtn: !newCurrent.context.report.messageReport && !newCurrent.context.report.ticketReport,
    isExporting: newCurrent.matches('report.modalState.exporting'),
    isExportFailed: newCurrent.matches('report.modalState.exportFailed'),
    canExportReport: () => {
      return isSupervisor() || isAdmin() || isSuperAdmin();
    },
    onFilterChange: (fieldName: keyof IAllTicketPaneContext['filter'], payload: IFilterValue) => {
      updateQueryParamsFromInput(fieldName, payload);
      newSend(EFilterActionType.UPDATE_FILTER_DATA, {
        payload: {
          fieldName,
          value: payload,
        },
      });
    },
    onClearAllFilter: () => {
      clearQueryParams();
      newSend(EFilterActionType.CLEAR_ALL_FILTER_DATA);
    },

    onClickExport: () => {
      newSend(EFilterActionType.EXPORT_REPORT);
    },
    onCloseExportModal: () => {
      newSend(EFilterActionType.EXPORT_REPORT_CANCEL);
    },
    onConfirmExport: () => {
      newSend(EFilterActionType.EXPORT_REPORT_CONFIRM);
    },
    onCheckReport: (fieldName: keyof IAllTicketPaneContext['report'], value: boolean) => {
      newSend(EFilterActionType.UPDATE_SELECTED_REPORT, {
        payload: {
          key: fieldName,
          value,
        },
      });
    },
  };
};

export default useAllTicketsPane;
