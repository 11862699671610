import dayjs from 'dayjs';
import React from 'react';

type TimestampProps = {
  timestamp?: number;
};

function Timestamp({ timestamp }: TimestampProps) {
  if (timestamp) {
    const dateTitle = new Date(timestamp).toISOString();
    return dayjs(new Date()).diff(timestamp, 'd') > 7 ? (
      <div title={dateTitle}>{dayjs(timestamp).format('D MMM YY')}</div>
    ) : (
      <div title={dateTitle}>{dayjs(timestamp).fromNow() || ''}</div>
    );
  }

  return <></>;
}

export default Timestamp;
