import React, { useEffect, useMemo } from 'react';
import { Container, Counter } from './style';
import { DropdownTags } from '@components/Dropdown/DropdownTags';
import useTranslation from '@hooks/useTranslation';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IReasonBE } from '@types';
import { IOption } from '@components/Select';
import { fetchReasonsByCategoryName } from '@api/livechat/Wrapup';

import { Font12G5W400 } from '@components/UtilsComponent';

type IReasonSelectProps = {
  categoryName: string;
  selectedValues: string[];
  validateText?: string;
  max?: number;
  isDisabled: boolean;
  onDelete: (item: string) => void;
  onSelect: (item: string) => void;
  onClearSelect: () => void;
};

export const ReasonSelect = ({
  categoryName,
  selectedValues = [],
  validateText,
  max,
  isDisabled,
  onDelete,
  onSelect,
  onClearSelect,
}: IReasonSelectProps) => {
  const t = useTranslation();
  const [keyword, setKeyword] = React.useState('');
  const selected = useMemo(() => {
    return selectedValues.map((v) => {
      return {
        id: v,
        name: v,
      };
    });
  }, [selectedValues]);

  const { data, onOpenDropdown } = useInfinityLoadByCursorId<IReasonBE, IOption>({
    fetchFn: async (filter, cancelToken) => {
      if (!categoryName) return [];
      const newFilter = {
        cursorId: filter.cursorId ?? '',
        keyword: filter.keyword ?? '',
        take: 200,
      };
      const data = await fetchReasonsByCategoryName(
        categoryName,
        newFilter.take,
        newFilter.keyword || undefined,
        newFilter.cursorId || undefined,
        cancelToken,
      );
      const result = data.reasons.map((item) => {
        return {
          ...item,
          id: item.name,
          label: item.name,
          value: item.name,
        };
      });
      return result;
    },
  });

  const filterData = useMemo(() => {
    return data.filter((v) => v.name.trim().toLocaleLowerCase().includes(keyword.trim().toLocaleLowerCase()));
  }, [data, keyword]);

  useEffect(() => {
    if (categoryName) {
      onOpenDropdown();
    }
  }, [categoryName]);

  return (
    <Container>
      <div
        style={{
          border: validateText ? '1px solid #F72C40' : '',
        }}
        className="rounded-[8px]"
      >
        <DropdownTags<IReasonBE>
          placeholder={t('livechat.ticket.console.resolve.reason.placeholder')}
          maxHeight={100}
          dropdownClassName="z-[9999999]"
          isDisabled={isDisabled}
          options={filterData}
          onScroll={() => false}
          canClear
          onClearSelect={onClearSelect}
          onUpdateSearchKeyword={(value) => setKeyword(value)}
          keyword={keyword}
          selectedTag={selected}
          onDeleteTag={(value: string) => {
            onDelete(value);
          }}
          onSelect={(value) => {
            const val = value.name;
            onSelect(val);
          }}
          onClearSearchKeyword={() => {
            setKeyword('');
          }}
          emptyWord={t('no.matching.result')}
          fullMessage={selected.length === max ? t('maximum.selection.limit.exceeded') : ''}
          tagClassName="reason"
        />
      </div>
      <div className="flex justify-between mt-[4px]">
        {<Font12G5W400 className="!text-[#F72C40]">{validateText}</Font12G5W400>}
        <Counter>
          {max ? <Font12G5W400>{`${selected.length ? selected.length : 0} / ${max}`}</Font12G5W400> : null}
        </Counter>
      </div>
    </Container>
  );
};
