import styled, { keyframes } from 'styled-components/macro';
import { Modal as AntdModal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div``;
export const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 12px;
  }
`;
export const ItemWrapWithImage = styled.div`
  margin-top: 20px;
  width: 100%;
  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
`;

export const ItemWrapWithText = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
`;
export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LoadingIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  animation: ${rotate} 1.2s linear infinite;
`;
