import React from 'react';
import { Card } from '@components/Card';
import { SentimentPercent } from '@components/Percent';
import { Font12G5W400, Font32G1W500 } from '@components/UtilsComponent';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertSecToHrs } from '@utils/day';
import { getPercentChange } from '@utils/percent';
import useTranslation from '@hooks/useTranslation';
import { FetchWrap } from '@components/FetchWrap';
import { CancelTokenSource } from 'axios';
import { IFilterState } from '../useDashboardFilter';
import { CardContent } from '@components/CardWrap';
import { IAverageTimeResponse } from '@types';
import { getAverageTimeAnalytic } from '@api/livechat/Analytic';

type IAverageTimeProps = {
  filter: IFilterState;
};

export const AverageTime = ({ filter }: IAverageTimeProps) => {
  const t = useTranslation();

  return (
    <FetchWrap<IAverageTimeResponse, IFilterState>
      filter={filter}
      queryKey={['average-time', filter]}
      fetchFn={async (
        filter: IFilterState,
        cancelToken?: CancelTokenSource | undefined,
      ): Promise<IAverageTimeResponse> => {
        const { data }: { data: IAverageTimeResponse } = await getAverageTimeAnalytic({
          agents: filter.assignees,
          channels: filter.channels,
          tags: filter.tags,
          teams: filter.teams,
          startIsoStringDate: filter.startIsoStringDate.toISOString(),
          endIsoStringDate: filter.endIsoStringDate.toISOString(),
          cancelToken,
        });
        return data;
      }}
      renderFn={function ({
        isLoading,
        data,
        isError,
        refetch,
      }: {
        isLoading: boolean;
        data?: IAverageTimeResponse;
        isError: boolean;
        refetch: () => void;
      }): JSX.Element {
        const firstResponse = convertSecToHrs(data?.targetPeriod.firstResponseSec ?? 0);
        const resolution = convertSecToHrs(data?.targetPeriod.resolutionSec ?? 0);
        const firstResponsePercent = getPercentChange(
          data?.targetPeriod.firstResponseSec ?? 0,
          data?.previousPeriod.firstResponseSec ?? 0,
        );

        const resolutionPercent = getPercentChange(
          data?.targetPeriod.resolutionSec ?? 0,
          data?.previousPeriod.resolutionSec ?? 0,
        );
        return (
          <Card
            divider
            minHeight="420px"
            maxHeight="420px"
            title={t('average.time.title')}
            description={t('average.time.description')}
            topLeftIcon={<FontAwesomeIcon icon={faClock} />}
          >
            <div className="p-[24px]">
              <CardContent isLoading={isLoading} isError={isError} onRetry={refetch}>
                <div className="flex h-[240px]">
                  <div className="flex flex-col gap-y-[12px] justify-center  w-full min-w-[185px] items-center">
                    <div className="flex items-center gap-x-[8px]">
                      <div className="flex flex-col items-center gap-y-[12px]">
                        <Font12G5W400>{t('average.time.first.response.time')}</Font12G5W400>
                        <Font32G1W500>{firstResponse}</Font32G1W500>
                        <div className="flex gap-x-[8px] items-center">
                          <SentimentPercent num={firstResponsePercent} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* TODO: uncomment when ready  */}
                  {/* <div className="w-[1px] h-full bg-[#E5E5E5]" />
                  <div className="flex flex-col gap-y-[12px] justify-center  w-full min-w-[185px] items-center">
                    <div className="flex items-center gap-x-[8px]">
                      <div className="flex flex-col items-center gap-y-[12px]">
                        <Font12G5W400>{t('average.time.response.time')}</Font12G5W400>
                        <Font32G1W500>{response}</Font32G1W500>
                        <div className="flex gap-x-[8px] items-center">
                          <SentimentPercent num={responsePercent} />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="w-[1px] h-full bg-[#E5E5E5]" />
                  <div className="flex flex-col gap-y-[12px] justify-center w-full min-w-[185px] items-center">
                    <div className="flex items-center gap-x-[8px]">
                      <div className="flex flex-col items-center gap-y-[12px]">
                        <Font12G5W400>{t('average.time.resolution.time')}</Font12G5W400>
                        <Font32G1W500>{resolution}</Font32G1W500>
                        <div className="flex gap-x-[8px] items-center">
                          <SentimentPercent num={resolutionPercent} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </div>
          </Card>
        );
      }}
    />
  );
};
