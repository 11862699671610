import React, { useMemo, useState } from 'react';
import Table from '@components/Table';
import { Container, LoadingWrap } from './style';
import LoadingCircle from '@components/Loading';
import { MEMBER_LIST_PAGE_SIZE } from '@configs/constants';

import dayjs from 'dayjs';
import { IVaData, IVaList } from '../teamManagementMachine';
import { Font14G1W400, Font14G1W500, Font14G1W600, Font20G1W600 } from '@components/UtilsComponent';
import { TicketTagList } from '@components/Tag';
import { isEmpty } from 'lodash';
import { Button, PrimaryButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useCreateModal } from '@hooks/useCreateModal';
import { Popover } from '@components/Popover';
import TextInput from '@components/TextInput';

export type IVaListProps = {
  vaData: IVaList;
  currentPage: number;
  isLoading: boolean;
  // handleSelectAction: (record: IVaList['rows']) => void;
  handlePageChange: (page: number, pageSize: number) => void;
};

const VaList: React.FC<IVaListProps> = ({ vaData, currentPage, isLoading, handlePageChange }: IVaListProps) => {
  const [newVa, setNewVa] = useState({
    name: '',
    secret: '',
  });
  const createModal = useCreateModal(async () => {
    // console.log('create modal');
  });
  const MEMBER_COLUMNS = useMemo(() => {
    return [
      {
        // eslint-disable-next-line react/display-name
        title: 'DISPLAY NAME',
        dataIndex: 'display_name',
        key: 'display_name',
        // eslint-disable-next-line react/display-name
        render: (_: string, record: IVaData) => {
          return <Font14G1W600>{record.displayName}</Font14G1W600>;
        },
      },
      {
        title: 'SOURCE',
        dataIndex: 'source',
        key: 'source',
        render: (_, record: IVaData) => {
          if (record.virtualAgentConfig.virtualAgentSource === 'genai') {
            return <Font14G1W400>Generative AI Assistant</Font14G1W400>;
          }
          return <Font14G1W400>External assistant</Font14G1W400>;
        },
      },
      {
        title: 'CURRENT TEAMS',
        dataIndex: 'current_team',
        key: 'current_team',
        // eslint-disable-next-line react/display-name
        render: (_, record: IVaData) => {
          return isEmpty(record.teams) ? (
            <Font14G1W400>-</Font14G1W400>
          ) : (
            <TicketTagList
              showHiddenMenu
              tags={
                record.teams
                  ? record.teams.map((item) => {
                      return {
                        id: item._id,
                        value: item.name,
                        label: item.name,
                        selected: false,
                        loading: false,
                      };
                    })
                  : []
              }
              displaySize={1}
            />
          );
        },
      },
      {
        // eslint-disable-next-line react/display-name
        title: 'CREATED DATE',
        dataIndex: 'created_date',
        key: 'created_date',
        // eslint-disable-next-line react/display-name
        render: (_: string, record: IVaData) => {
          return <Font14G1W400>{dayjs(record.createdAt).format('DD MMM YYYY')}</Font14G1W400>;
        },
      },
      // TODO: uncomment this code
      // {
      //   title: '',
      //   dataIndex: 'action',
      //   key: 'action',
      //   width: 57,
      //   // eslint-disable-next-line react/display-name
      //   render: (text: string, record: any, index: number) => (
      //     <DropdownGroup
      //       placement="bottomLeft"
      //       btn={(setFunction: (status: React.SetStateAction<boolean>) => void) => {
      //         return (
      //           <IconWrap>
      //             <FontAwesomeIcon
      //               onClick={() => setFunction(true)}
      //               color="#757575"
      //               icon={['far', 'ellipsis-h']}
      //               style={{ cursor: 'pointer' }}
      //             />
      //           </IconWrap>
      //         );
      //       }}
      //       data={TEAM_MANAGEMENT_MEMBER_LIST_ACTIONS}
      //       handleSelectItem={(item) => console.log}
      //     />
      //   ),
      // },
    ];
  }, []);
  return (
    <Container>
      <Table
        scroll={{ y: 'calc(100vh - 350px)' }}
        columns={MEMBER_COLUMNS}
        header={<></>}
        // TODO: uncomment this when ready
        // header={
        //   <Popover
        //     overlayClassName="popover-content w-[352px]"
        //     visible={createModal.showConfirmModal}
        //     content={
        //       <div>
        //         <div className="py-[12px] px-[8px] flex flex-col gap-y-[16px]">
        //           <Font20G1W600>{t('livechat.team.management.va.list.add.new.va.title')}</Font20G1W600>
        //           <div className="flex flex-col gap-y-[8px]">
        //             <Font14G1W500>{t('livechat.team.management.va.list.add.new.va.name.label')}</Font14G1W500>
        //             <TextInput
        //               size="large"
        //               placeholder={t('livechat.team.management.va.list.add.new.va.name.input.placeholder')}
        //               value={newVa.name}
        //               onChange={(e) => setNewVa({ ...newVa, name: e.target.value })}
        //             />
        //           </div>
        //           <div className="flex flex-col gap-y-[8px]">
        //             <Font14G1W500>{t('livechat.team.management.va.list.add.new.va.secret.label')}</Font14G1W500>
        //             <TextInput
        //               size="large"
        //               placeholder={t('livechat.team.management.va.list.add.new.va.secret.input.placeholder')}
        //               value={newVa.name}
        //               onChange={(e) => setNewVa({ ...newVa, secret: e.target.value })}
        //             />
        //           </div>
        //         </div>
        //         <div className="flex w-full justify-end gap-x-[8px]">
        //           <Button size="large">{t('cancel.btn')}</Button>
        //           <PrimaryButton size="large">{t('add')}</PrimaryButton>
        //         </div>
        //       </div>
        //     }
        //     placement="bottomRight"
        //     trigger={['click']}
        //   >
        //     <Button
        //       onClick={createModal.onToggle}
        //       className="mb-[24px] mt-[8px]"
        //       icon={<FontAwesomeIcon className="mr-2" icon={faPlus} />}
        //     >
        //       {t('livechat.team.management.va.list.add.new.va.btn')}
        //     </Button>
        //   </Popover>
        // }
        data={vaData.rows}
        loading={
          isLoading && {
            indicator: (
              <LoadingWrap>
                <LoadingCircle />
              </LoadingWrap>
            ),
          }
        }
        pagination={{
          defaultPageSize: MEMBER_LIST_PAGE_SIZE,
          current: currentPage,
          total: vaData.total,
          onChange: (page: number, pageSize?: number) => handlePageChange(page, pageSize ?? MEMBER_LIST_PAGE_SIZE),
          showSizeChanger: false,
        }}
      ></Table>
    </Container>
  );
};

export default VaList;
