import React, { useEffect } from 'react';
import useTranslation from '@hooks/useTranslation';
import Tab from '@components/Tab';
import { ReasonCategoryList } from './ReasonCategoryList';
import { ReasonList } from './ReasonList';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@hooks/useQueryParams';
import { OutcomeList } from './OutcomeList';
import { Item, Label, Description } from '../GeneralManagement/style';
import Switch from '@components/Switch';
import { useSetting } from '@providers/SettingProvider';
import { saveGeneralConfig } from '@api/livechat/Settings';
import { useMutation } from 'react-query';

export const TABS_DATA = [
  {
    tab: 'Reason category',
    key: 'reason-category',
    children: <ReasonCategoryList key="reason-category" />,
  },
  {
    tab: 'Reason',
    key: 'reason',
    children: <ReasonList key="reason" />,
  },
  {
    tab: 'Outcome',
    key: 'outcome',
    children: <OutcomeList key="outcome" />,
  },
] as const;

export const TicketWrapUp = () => {
  const history = useHistory();
  const t = useTranslation();
  const query = useQueryParams();
  const currentTab = query.get('tab');
  useEffect(() => {
    if (currentTab !== 'reason-category' && currentTab !== 'reason' && currentTab !== 'outcome') {
      history.replace(`?tab=${TABS_DATA[0].key}`);
    }
  }, [currentTab]);
  const { query: qy, setting, onUpdateConfig } = useSetting();
  const updateWrapupMute = useMutation({
    mutationFn: ({ value }: { value: boolean }) => {
      const newSla = {
        hour: Number(setting.SLATime.hour),
        minute: Number(setting.SLATime.minute),
      };
      return saveGeneralConfig({
        maxTicket: setting.maxTicket,
        autoAssign: setting.autoAssign,
        SLATime: newSla,
        isCollaborationModeEnabled: setting.isCollaborationModeEnabled,
        isWrapUpTicketRequired: value,
      });
    },
  });

  const onUpdateWrapup = async (value: boolean) => {
    const tempVal = setting.isWrapUpTicketRequired;
    try {
      onUpdateConfig({ ...setting, isWrapUpTicketRequired: value });
      await updateWrapupMute.mutateAsync({ value });
    } catch (error) {
      console.error(error);
      onUpdateConfig({ ...setting, isWrapUpTicketRequired: tempVal });
    }
  };
  return (
    <>
      <div>
        <div className="mt-[40px]">
          <div className="flex gap-x-[10px]">
            <Item>
              <Switch
                checked={setting?.isWrapUpTicketRequired}
                onChange={(e) => onUpdateWrapup(e)}
                loading={updateWrapupMute.isLoading}
              />
            </Item>
            <Item>
              <Label>{t('livechat.settings.general.allow.wrapup.label')}</Label>
              <Description>{t('livechat.settings.general.allow.wrapup.description')}</Description>
            </Item>
          </div>
        </div>
      </div>
      <div className="mt-[24px]">
        <Tab
          tabs={TABS_DATA}
          defaultActiveKey={currentTab!}
          destroyInactiveTabPane
          onTabClick={function (
            key: string,
            _: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>,
          ): void {
            history.replace(`?tab=${key}`);
          }}
          activeKey={currentTab!}
        />
      </div>
    </>
  );
};
