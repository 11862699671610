import { sendLineLoadingAnimation } from '@api/line';
import EChannelType from '@enums/ChannelType';
import { useSetting } from '@providers/SettingProvider';
import { IChannel } from '@types';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';

type IUseLineLoadingAnimationParams = {
  channel?: IChannel;
  userId?: string;
  txt: string;
};

type IUseLineLoadingAnimationPayload = {
  channelId: string;
  userId: string;
};

export const useLineLoadingAnimation = ({ channel, userId, txt }: IUseLineLoadingAnimationParams) => {
  const { setting } = useSetting();
  const sendLineAnimationMute = useMutation({
    mutationKey: ['sendLineLoadingAnimation'],
    mutationFn: async (payload: IUseLineLoadingAnimationPayload) => {
      return sendLineLoadingAnimation(payload.channelId, payload.userId);
    },
  });

  useEffect(() => {
    if (channel?.type !== EChannelType.LINE || !channel.id || !userId || !setting.loadingAnimation.enabled) return;

    sendAnimationThrottle();
  }, [txt, channel, userId, setting.loadingAnimation]);

  const onSendAnimation = async () => {
    try {
      if (!channel?.id || !userId) return;
      sendLineAnimationMute.mutate({ channelId: channel.id, userId });
    } catch (error) {
      console.error('Error sending line loading animation', error);
    }
  };

  const sendAnimationThrottle = useCallback(
    _.throttle(onSendAnimation, setting.loadingAnimation.loadingSeconds * 1000, { trailing: false }),
    [],
  );
};
