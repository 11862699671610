import { EMessageType } from '@enums/MessageType';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { IImageCarouselColumn, IMessageLevel } from '@types';

type ImageCarouselMessageJson = {
  columns: IImageCarouselColumn[];
} & BaseMessageJson;

export class ImageCarousel extends BaseMessage {
  private columns: IImageCarouselColumn[];
  private level: IMessageLevel;

  constructor(data: ImageCarouselMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.columns = data.columns;
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      columns: this.columns,
      retry: this.retry,
      level: this.level,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  get getMessageType() {
    return EMessageType.IMAGE;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
