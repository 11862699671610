import React, { useEffect, useRef, useState } from 'react';

type IFadeProps = {
  isShow: boolean;
  duration: number;
} & React.HTMLAttributes<HTMLDivElement>;

const fIn = [{ opacity: '0' }, { opacity: '1' }];
const fOut = [{ opacity: '1' }, { opacity: '0' }];
export const Fade: React.FC<IFadeProps> = React.memo(({ isShow, duration, children, ...rest }) => {
  const elRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<Animation | null>(null);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (initialRender && !isShow) {
      if (elRef.current) {
        elRef.current.style.display = 'none';
      }
      setInitialRender(false);
      return;
    }
    if (!elRef.current || initialRender) {
      setInitialRender(false);
      return;
    }

    // visible play fade in
    if (isShow) {
      elRef.current!.style.display = 'block';
      animationRef.current = elRef.current.animate(fIn, {
        duration: duration,
        fill: 'forwards',
      });
    } else {
      animationRef.current = elRef.current.animate(fOut, {
        duration: duration,
        fill: 'forwards',
      });
      animationRef.current.onfinish = () => {
        elRef.current!.style.display = 'none';
      };
    }
    return () => {
      // Cleanup animation on component unmount
      if (animationRef.current) {
        animationRef.current.cancel();
      }
    };
  }, [isShow, initialRender]);

  return (
    <div {...rest} ref={elRef}>
      {children}
    </div>
  );
});
