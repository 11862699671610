import { useState } from 'react';

export const useSurveyPopover = (outSidepoint?: string, outSideMessage = '') => {
  const [point, setPoint] = useState<string | undefined>(outSidepoint);
  const [message, setMessage] = useState<string>(outSideMessage);
  return {
    point,
    message,
    onSelectPoint: (value: string | undefined) => {
      setPoint(value);
    },
    onUpdateText: (value: string) => {
      setMessage(value);
    },
  };
};
