import { getNewerMessage, getOlderMessage } from '@api/livechat/EngagementMessage';
import { IChatHistory } from '../messageBoxMachine';
import { getMessage, messageGenerator } from './messageGenerator';

export const fetchOldAndNewMessages = async (userId: string, messageTime: number) => {
  const { data: oldMessageData } = await getOlderMessage(userId, messageTime);
  const { data: newMessageData } = await getNewerMessage(userId, messageTime - 1);
  const userOldMessage = oldMessageData.userMessages.map((message) => getMessage(message));
  const userNewMessage = newMessageData.userMessages.map((message) => getMessage(message));
  const processOldMessage = messageGenerator([], userOldMessage);
  const processNewMessage = messageGenerator(processOldMessage, userNewMessage);

  const res: IChatHistory = {
    previousRemain: oldMessageData.userMessages.length > 0,
    nextRemain: newMessageData.userMessages.length > 0,
    messages: processNewMessage,
  };
  return res;
};
