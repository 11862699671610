import { BaseMessage, BaseMessageJson } from './BaseMessage';

export type TicketMessageJson = {
  text: string;
} & BaseMessageJson;

export class TicketMessage extends BaseMessage {
  private text: string;

  constructor(data: TicketMessageJson) {
    const { id, createdAt, messageStatus } = data;
    super({ id, createdAt, messageStatus });
    this.text = data.text;
  }

  get instanceData() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      text: this.text,
      retry: this.retry,
      senderName: this.senderName,
    };
  }
}
