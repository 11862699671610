import React from 'react';
import ScopeIcon from '@assets/images/scope_icon.svg';
import StateMessage from '@components/StateMessage';
import UserWithSearch, { IListState } from '@pages/components/UserWithSearch';
import { generateMessageListData, generateUserListData } from '../fns/generateDataListState';
import { PaneContainer, StateMessageContainer } from '../style';
import { SearchData } from '../userListMachine.type';

export type IUserWithSearchPane = {
  keyword: string;
  searchData: SearchData;
  userListStatus?: Omit<IListState, 'items' | 'total'>;
  messageListStatus?: Omit<IListState, 'items' | 'total'>;
  onLoadMore: (type: 'message' | 'user') => void;
};

const UserWithSearchPane = ({
  searchData,
  keyword,
  userListStatus,
  messageListStatus,
  onLoadMore,
}: IUserWithSearchPane) => {
  const userList = generateUserListData({
    userData: searchData.userList,
    isFetchError: userListStatus?.isFetchError ?? false,
    isFetching: userListStatus?.isFetching ?? false,
    isNoMore: userListStatus?.isNoMore ?? true,
  });
  const messageList = generateMessageListData({
    messageData: searchData.messageList,
    keyword,
    isFetchError: messageListStatus?.isFetchError ?? false,
    isFetching: messageListStatus?.isFetching ?? false,
    isNoMore: messageListStatus?.isNoMore ?? true,
  });
  if (keyword) {
    return (
      <PaneContainer>
        <UserWithSearch searchTxt={keyword} userList={userList} userMessageList={messageList} onLoadMore={onLoadMore} />
      </PaneContainer>
    );
  } else {
    return (
      <StateMessageContainer>
        <StateMessage
          img={ScopeIcon}
          label="Find your users or messages"
          description=""
          size="medium"
          imageWidth={48}
          imageHeight={48}
        />
      </StateMessageContainer>
    );
  }
};

export default UserWithSearchPane;
