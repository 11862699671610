import { DrawerProps } from 'antd';
import React, { PropsWithChildren } from 'react';
import { ContentBox, Drawer } from './style';

type ISideDrawer = {
  onClose: () => void;
} & Pick<DrawerProps, 'visible'>;

const SideDrawer = (props: PropsWithChildren<ISideDrawer>) => {
  const { onClose, children, ...rest } = props;
  return (
    <Drawer {...rest} width={'100vw'} closeIcon={null} placement="left">
      {children}
    </Drawer>
  );
};

export default SideDrawer;
