import React, { useEffect, useMemo, useState } from 'react';
import Avatar from '@components/Avatar';
import millify from 'millify';
import { GhostButton } from '@components/Button';
import {
  Font12G4W400,
  Font14G1W400,
  Font14G1W500,
  Font14G1W600,
  Font14G3W400,
  Font16G1W500,
} from '@components/UtilsComponent';
import { faStar, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfigProvider, Spin } from 'antd';
import TableList from '@components/TableList';
import Table from '@components/Table';
import { useQuery } from 'react-query';
import { getAverageTimeAnalytic, getRatingByAgentId } from '@api/livechat/Analytic';
import StateMessage from '@components/StateMessage';
import useTranslation from '@hooks/useTranslation';
import Exclaimation from '@assets/images/exclaimation.svg';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { IFilterState } from '../useDashboardFilter';
import dayjs from 'dayjs';
import { IRatingAgentResponse, IRatingAgentTable } from '@types';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { convertSecToHrs } from '@utils/day';

type IRatingDrawerContentProps = {
  agentId: string;
  filter: IFilterState;
  sort: 'asc' | 'desc';
  onClose: () => void;
};

let nextPageArr: (string | undefined)[] = [undefined];
let jobId: string | undefined;

const getNextToken = (page: number, nextPageArr: (string | undefined)[]) => {
  if (page === 1) return undefined;
  return nextPageArr[page - 1];
};

let spare: IRatingAgentResponse | null = null;

export const RatingDrawerContent = ({ filter, agentId, sort, onClose }: IRatingDrawerContentProps) => {
  const t = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // reset
    nextPageArr = [undefined];
    jobId = undefined;
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);
  const avgTimeQuery = useQuery(
    ['average_time', filter],
    () => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return getAverageTimeAnalytic({
        agents: filter.assignees,
        channels: filter.channels,
        tags: filter.tags,
        teams: filter.teams,
        startIsoStringDate: filter.startIsoStringDate.toISOString(),
        endIsoStringDate: filter.endIsoStringDate.toISOString(),
        cancelToken: source,
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0,
    },
  );
  const query = useQuery(
    ['rating_by_agentId', filter, currentPage],
    () => {
      return getRatingByAgentId({
        tags: filter.tags,
        agents: filter.assignees.map((assignee) => assignee),
        teams: filter.teams.map((team) => team),
        channels: filter.channels.map((channel) => channel),
        agentId: agentId,
        startIsoStringDate: filter.startIsoStringDate.toISOString(),
        endIsoStringDate: filter.endIsoStringDate.toISOString(),
        jobId: currentPage === 1 ? undefined : jobId,
        nextToken: getNextToken(currentPage, nextPageArr),
        sort,
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0,
      onSuccess(data) {
        nextPageArr = data.data.nextToken ? [...nextPageArr, data.data.nextToken] : nextPageArr;
        jobId = data.data.jobId;
        spare = data.data;
      },
    },
  );
  const columns = [
    {
      title: 'TICKET NUMBER',
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      render: (_: any, record: IRatingAgentTable) => {
        return <Font14G1W400>{record.ticketNumber || '-'}</Font14G1W400>;
      },
    },
    {
      title: 'POINT',
      dataIndex: 'scorePoint',
      key: 'scorePoint',
      render: (_: any, record: IRatingAgentTable) => {
        return <Font14G1W400>{record.scorePoint || '-'}</Font14G1W400>;
      },
    },
    {
      title: 'FEEDBACK',
      dataIndex: 'feedbackMessage',
      key: 'feedbackMessage',
      render: (_: any, record: IRatingAgentTable) => {
        return <Font14G1W400>{record.feedbackMessage || '-'}</Font14G1W400>;
      },
    },
    {
      title: 'RESOLVED DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: any, record: IRatingAgentTable) => {
        return <Font14G1W400>{dayjs(record.createdAt.value).format('MMM DD, YYYY, HH:mm') || '-'}</Font14G1W400>;
      },
    },
  ];

  const _renderScores = useMemo(() => {
    const data = query.data?.data;
    if (isEmpty(data?.agent.summary.scorePointDistribution) && isEmpty(spare?.agent.summary.scorePointDistribution)) {
      // generate 10 empty array
      return Array.from({ length: 10 }, (_, i) => {
        return {
          point: i + 1,
          count: 0,
        };
      });
    }
    if (isEmpty(data?.agent.summary.scorePointDistribution) && !isEmpty(spare?.agent.summary.scorePointDistribution)) {
      return Object.keys(spare?.agent.summary.scorePointDistribution).map((key) => {
        return {
          point: key,
          count: spare?.agent.summary.scorePointDistribution[key],
        };
      });
    }
    if (data && !isEmpty(data.agent.summary.scorePointDistribution)) {
      const result = Object.keys(data.agent.summary.scorePointDistribution).map((key) => {
        return {
          point: key,
          count: data?.agent.summary.scorePointDistribution[key],
        };
      });
      return result;
    }
    return Array.from({ length: 10 }, (_, i) => {
      return {
        point: i + 1,
        count: 0,
      };
    });
  }, [query.data?.data.agent.summary.scorePointDistribution, spare]);

  const _renderContent = useMemo(() => {
    if (query.isError) {
      return (
        <div className="relative h-full">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-[8px]">
              <FontAwesomeIcon icon={faStar} className="text-[16px]" />
              <Font16G1W500>{t('agent.rating.drawer.title')}</Font16G1W500>
            </div>
            <div>
              <GhostButton
                className="flex items-center"
                onClick={() => {
                  jobId = undefined;
                  nextPageArr = [undefined];
                  spare = null;
                  setCurrentPage(1);
                  onClose();
                }}
              >
                <FontAwesomeIcon icon={faTimes} className="text-[18px]" />
              </GhostButton>
            </div>
          </div>
          {/* center */}
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <StateMessage
              label={t('general.error.title')}
              description={t('general.error.description')}
              img={Exclaimation}
              type="error"
              size={'large'}
            />
          </div>
        </div>
      );
    }

    const data = query.data?.data;
    const avg = convertSecToHrs(avgTimeQuery.data?.data.targetPeriod.resolutionSec ?? 0);

    return (
      <Spin
        spinning={query.isLoading}
        indicator={
          <LoadingWrap>
            <LoadingCircle />
          </LoadingWrap>
        }
      >
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-[8px]">
            <FontAwesomeIcon icon={faStar} className="text-[16px]" />
            <Font16G1W500>{t('agent.rating.drawer.title')}</Font16G1W500>
          </div>
          <div>
            <GhostButton className="flex items-center" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} className="text-[18px]" />
            </GhostButton>
          </div>
        </div>

        <div className="p-[12px] bg-background-secondary w-full rounded-[8px] mt-[24px]">
          <div className="flex">
            <div className="flex items-center gap-x-[8px] pr-[24px] border-r border-line-default border-solid">
              <div>
                <Avatar width={40} height={40}>
                  {data?.agent.agentDisplayName || '-'}
                </Avatar>
              </div>
              <div className="flex flex-col">
                <Font16G1W500>{data?.agent.agentDisplayName || spare?.agent.agentDisplayName || '-'}</Font16G1W500>
                <Font12G4W400>{data?.agent.email || spare?.agent.email || '-'}</Font12G4W400>
              </div>
            </div>
            <div className="flex flex-col pl-[24px] flex-auto gap-y-[8px]">
              <div className="flex">
                <div className="flex gap-x-[6px] mr-[40px]">
                  <Font14G3W400>{t('agent.rating.drawer.role.label')}</Font14G3W400>
                  <Font14G1W400 className="capitalize">
                    {data?.agent.roles.join(', ') || spare?.agent.roles.join(', ') || '-'}
                  </Font14G1W400>
                </div>
                <div className="flex gap-x-[6px]">
                  <Font14G3W400>{t('agent.rating.drawer.team.label')}</Font14G3W400>
                  <Font14G1W400>
                    {data?.agent.teams.map((team) => team.name).join(', ') ||
                      spare?.agent.teams.map((team) => team.name).join(', ') ||
                      '-'}
                  </Font14G1W400>
                </div>
              </div>
              <div className="flex">
                <div className="flex gap-x-[16px]">
                  <Font14G3W400>{t('agent.rating.drawer.avg.label')}</Font14G3W400>
                  {avgTimeQuery.isLoading ? (
                    <Font14G1W400>{t('loading')}</Font14G1W400>
                  ) : (
                    <Font14G1W400>{avg}</Font14G1W400>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-[8px] px-[24px] border-l border-solid border-line-default">
              <Font14G3W400>{t('agent.rating.drawer.avg.point')}</Font14G3W400>
              <Font14G1W400>{data?.agent.summary.averageScorePoint?.toFixed(2) || '-'}</Font14G1W400>
            </div>
          </div>
          <div className="divider-y-12" />
          <div className="flex overflow-auto py-[16px]">
            <div className="flex">
              {_renderScores.map(({ point, count }, index) => {
                return (
                  <div
                    className="flex-col gap-y-[8px] px-[20px] border-line-default border-r border-solid flex items-center justify-center"
                    key={index}
                  >
                    <Font12G4W400 className="text-text-secondary self-start">{point}PT</Font12G4W400>
                    <Font14G1W500 className="text-text-tertiary self-start">
                      {millify(count || 0, { precision: 0 })}
                    </Font14G1W500>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col px-[20px] gap-y-[8px]">
              <Font12G4W400 className="text-text-secondary whitespace-nowrap">
                {t('agent.rating.drawer.no.feedback.label')}
              </Font12G4W400>
              <div className="flex items-center gap-x-[12px]">
                <div className="px-[6px] py-[4px] rounded-[4px] bg-white flex justify-center items-center text-[12px]">
                  📝
                </div>
                <Font14G1W600>{data?.agent.summary.feedbackMessageCount || '-'}</Font14G1W600>
                <div className="px-[6px] py-[4px] rounded-[4px] bg-[#FEEAEC] flex justify-center items-center text-[12px]">
                  ⏩
                </div>
                <Font14G1W600>{data?.agent.summary.scorePointNotExistsCount || '-'}</Font14G1W600>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[18px]">
          <ConfigProvider renderEmpty={() => <div>{t('no.data')}</div>}>
            <TableList
              next={{
                onClick: () => {
                  setCurrentPage((prev) => prev + 1);
                },
                disabled: !query.data?.data.nextToken,
              }}
              prev={{
                onClick: () => {
                  if (currentPage === 1) return;
                  setCurrentPage((prev) => {
                    if (prev === 2) {
                      jobId = undefined;
                      nextPageArr = [undefined];
                    }
                    return prev - 1;
                  });
                },
                disabled: currentPage === 1,
              }}
            >
              <div>
                <Table
                  header={
                    <div className="mb-[18px]">
                      <Font14G1W400>{t('agent.rating.drawer.review.details')}</Font14G1W400>
                    </div>
                  }
                  data={query.data?.data.data || spare?.data || []}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 'calc(100vh - 450px)' }}
                />
              </div>
            </TableList>
          </ConfigProvider>
        </div>
      </Spin>
    );
  }, [query]);

  return _renderContent;
};
