import {
  LINE_MAX_IMAGE_BYTES,
  LINE_MAX_FILE_BYTES,
  FACEBOOK_MAX_IMAGE_BYTES,
  FACEBOOK_MAX_FILE_BYTES,
  WEBCHAT_MAX_IMAGE_BYTES,
  WEBCHAT_MAX_FILE_BYTES,
  CUSTOM_MAX_IMAGE_BYTES,
  CUSTOM_MAX_FILE_BYTES,
  LINE_MAX_VIDEO_BYTES,
  FACEBOOK_MAX_VIDEO_BYTES,
  WEBCHAT_MAX_VIDEO_BYTES,
  CUSTOM_MAX_VIDEO_BYTES,
} from '@configs/constants';
import EChannelType from '@enums/ChannelType';
import shouldFileBeAllowed from './shouldFileBeAllowed';

const filterFileByChannel = (channelType?: EChannelType, file?: File) => {
  if (!channelType || !file) {
    return false;
  }
  const fileMimeType = file.type;
  const fileSize = file.size;
  switch (channelType) {
    case EChannelType.LINE: {
      return shouldFileBeAllowed(fileMimeType, fileSize, {
        maxImageSize: LINE_MAX_IMAGE_BYTES,
        maxVideoSize: LINE_MAX_VIDEO_BYTES,
        maxFileSize: LINE_MAX_FILE_BYTES,
      });
    }
    case EChannelType.FACEBOOK: {
      return shouldFileBeAllowed(fileMimeType, fileSize, {
        maxImageSize: FACEBOOK_MAX_IMAGE_BYTES,
        maxVideoSize: FACEBOOK_MAX_VIDEO_BYTES,
        maxFileSize: FACEBOOK_MAX_FILE_BYTES,
      });
    }
    case EChannelType.WEBCHAT: {
      return shouldFileBeAllowed(fileMimeType, fileSize, {
        maxImageSize: WEBCHAT_MAX_IMAGE_BYTES,
        maxVideoSize: WEBCHAT_MAX_VIDEO_BYTES,
        maxFileSize: WEBCHAT_MAX_FILE_BYTES,
      });
    }
    case EChannelType.CUSTOM: {
      return shouldFileBeAllowed(fileMimeType, fileSize, {
        maxImageSize: CUSTOM_MAX_IMAGE_BYTES,
        maxVideoSize: CUSTOM_MAX_VIDEO_BYTES,
        maxFileSize: CUSTOM_MAX_FILE_BYTES,
      });
    }
  }
  return false;
};

export default filterFileByChannel;
