import React, { useMemo } from 'react';
import check_circle from '@assets/images/checi_circle.svg';
import error from '@assets/images/error.svg';
import { PrimaryButton } from '@components/Button';
import LoadingCircle from '@components/Loading';
import SideDrawer from '@components/SideDrawer';
import StateMessage from '@components/StateMessage';
import TextArea from '@components/TextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { useTicketManager } from '@providers/TicketManagerProvider';
import { Col, Form, Row, Space, Spin, Typography } from 'antd';
import {
  AutoSaveBox,
  BottomBox,
  ButtonBox,
  Container,
  ContentBox,
  HeaderBox,
  TextCenterBox,
  InnerContentBox,
} from './style';
import useResolveDrawer from './useResolveDrawer';
import { AutoSaveText } from '@components/AutoSaveText';
import { Label, Section } from '@components/ResolveTicket/ResolveModal/style';
import { OutcomeSelect, ReasonCategorySelect, ReasonSelect } from '@components/TicketWrapup';
import { DangerToast } from '@components/Toast';
import useResolveModal from '@components/ResolveTicket/ResolveModal/useResolveModal';
import { cloneDeep } from 'lodash';
import { useSetting } from '@providers/SettingProvider';
import { useMember } from '@providers/MemberProvider';
import { Font14G3W400, HyperLink } from '@components/UtilsComponent';
import { ROUTE } from '@configs/route';

const ResolveDrawer = () => {
  const t = useTranslation();
  const {
    isOnResultStage,
    isOnResolveStage,
    isTicketResolved,
    isResolvedTicketSuccess,
    isResolvedTicketFailed,
    isResolvingTicket,
  } = useTicketManager();
  const { setting } = useSetting();
  const { ticket, handleCloseDrawer, handleOnSubmit, handleDirectToUserList, handleOpenDrawer } = useResolveDrawer();
  const { isAdmin: isAdminFn } = useMember();
  const { validateFields, fieldsValue, errorMessage, form, isAutoSave, onUpdateFields, isValidatePass } =
    useResolveModal(ticket!, () => false);

  const isAdmin = useMemo(() => isAdminFn(), [isAdminFn]);

  const _renderAutoSave = () => {
    if (isAutoSave) {
      return (
        <Space>
          <AutoSaveText />
        </Space>
      );
    }
  };

  const _renderResolveSuccess = () => {
    return (
      <SideDrawer visible={isResolvedTicketSuccess || isTicketResolved} onClose={handleCloseDrawer}>
        <Container>
          <ContentBox>
            <InnerContentBox>
              <StateMessage
                size="large"
                type="success"
                img={check_circle}
                label={t('livechat.ticket.resolved.title', { ticketNumber: ticket?.ticketNumber })}
                description={''}
              />
            </InnerContentBox>
          </ContentBox>
          <BottomBox>
            <ButtonBox>
              <PrimaryButton
                form="resolve-ticket-form"
                htmlType="submit"
                size="large"
                block
                onClick={handleDirectToUserList}
              >
                {t('livechat.ticket.console.resolve.done.button')}
              </PrimaryButton>
            </ButtonBox>
          </BottomBox>
        </Container>
      </SideDrawer>
    );
  };

  const _renderResolveFailed = () => {
    return (
      <SideDrawer visible={isResolvedTicketFailed} onClose={handleCloseDrawer}>
        <Container>
          <ContentBox>
            <InnerContentBox>
              <StateMessage
                size="large"
                type="error"
                img={error}
                label={t('livechat.error.title')}
                description={t('livechat.error.description')}
              />
            </InnerContentBox>
          </ContentBox>
          <BottomBox>
            <ButtonBox>
              <PrimaryButton
                form="resolve-ticket-form"
                htmlType={'submit'}
                size="large"
                block
                onClick={() => {
                  if (setting.isWrapUpTicketRequired) {
                    return handleOpenDrawer();
                  }
                  handleCloseDrawer();
                }}
              >
                {t('livechat.ticket.console.resolve.cancel.button')}
              </PrimaryButton>
            </ButtonBox>
          </BottomBox>
        </Container>
      </SideDrawer>
    );
  };

  const _renderResolveForm = () => {
    return (
      <SideDrawer visible={isOnResolveStage} onClose={handleCloseDrawer}>
        {!setting?.isWrapUpTicketRequired ? null : (
          <Container>
            <ContentBox>
              <HeaderBox>
                <Row>
                  <Col span={6}>
                    <FontAwesomeIcon size={'lg'} icon={'arrow-left'} onClick={handleCloseDrawer} />
                  </Col>
                  <Col span={12}>
                    <TextCenterBox>
                      <Typography.Title level={4}>{t('livechat.ticket.console.resolve.title')}</Typography.Title>
                    </TextCenterBox>
                  </Col>
                  <Col span={6} />
                </Row>
              </HeaderBox>
              <InnerContentBox>
                <Spin spinning={false} indicator={<LoadingCircle />}>
                  <Typography.Title level={5}>
                    {t('livechat.ticket.console.ticket.number.label')} {ticket?.ticketNumber}
                  </Typography.Title>
                  <Form id="resolve-ticket-form" form={form}>
                    {errorMessage && <DangerToast align="flex-start">{errorMessage}</DangerToast>}

                    <Section>
                      <Label>{t('livechat.ticket.console.resolve.reason.category.label')}</Label>
                      <Form.Item>
                        <div className="w-[calc(100vw-32px)]">
                          <ReasonCategorySelect
                            validateText={
                              validateFields.categories
                                ? t('livechat.ticket.console.resolve.reason.category.invalidate')
                                : ''
                            }
                            value={fieldsValue.data.categories?.[0] ?? ''}
                            onChange={(value) => {
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  categories: [value.name],
                                  reasons: [],
                                },
                              });
                            }}
                            onClearSelection={() => {
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  categories: [],
                                  reasons: [],
                                },
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                      {isAdmin ? (
                        <div className="mt-[8px] flex gap-x-[8px]">
                          <Font14G3W400>
                            {t('livechat.ticket.console.resolve.reason.category.add.new.description')}
                          </Font14G3W400>
                          <HyperLink to={ROUTE.TICKET_WRAP_UP}>
                            {t('livechat.ticket.console.resolve.add.new.category.hyperlink')}
                          </HyperLink>
                        </div>
                      ) : null}
                    </Section>
                    <Section>
                      <Label>{t('livechat.ticket.console.resolve.reason.label')}</Label>
                      <Form.Item>
                        <div className="w-[calc(100vw-32px)]">
                          <ReasonSelect
                            categoryName={fieldsValue.data?.categories?.[0] ?? ''}
                            selectedValues={fieldsValue.data.reasons ?? []}
                            isDisabled={!fieldsValue.data.categories?.length}
                            validateText={
                              validateFields.reasons ? t('livechat.ticket.console.resolve.reason.invalidate') : ''
                            }
                            max={20}
                            onClearSelect={() => {
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  reasons: [],
                                },
                              });
                            }}
                            onSelect={(payload) => {
                              const reasonIndex = fieldsValue.data.reasons.indexOf(payload);
                              const reasonName = payload;
                              let reasonResult: string[] = [];

                              if (reasonIndex === -1) {
                                // add new reason
                                reasonResult = [...fieldsValue.data.reasons, reasonName];
                              } else {
                                // splice reason
                                const clone = cloneDeep(fieldsValue.data.reasons);
                                clone.splice(reasonIndex, 1);
                                reasonResult = clone;
                              }
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  reasons: reasonResult,
                                },
                              });
                            }}
                            onDelete={function (item: string): void {
                              // remove item from reason
                              const reasonIndex = fieldsValue.data.reasons.indexOf(item);
                              const clone = cloneDeep(fieldsValue.data.reasons);
                              clone.splice(reasonIndex, 1);
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  reasons: clone,
                                },
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                      {isAdmin ? (
                        <div className="flex gap-x-[8px]">
                          <Font14G3W400>{t('livechat.ticket.console.resolve.reason.add.new.description')}</Font14G3W400>
                          <HyperLink to={`${ROUTE.TICKET_WRAP_UP}?tab=reason`}>
                            {t('livechat.ticket.console.resolve.add.new.reason.hyperlink')}
                          </HyperLink>
                        </div>
                      ) : null}
                    </Section>
                    <div className="h-[1px] bg-[#E5E5E5] w-full mt-[16px] mb-[16px]" />
                    <Section>
                      <Label>{t('livechat.ticket.console.resolve.result.label')}</Label>
                      <Form.Item>
                        <div className="w-[calc(100vw-32px)]">
                          <OutcomeSelect
                            max={20}
                            validateText={
                              validateFields.result ? t('livechat.ticket.console.resolve.outcome.invalidate') : ''
                            }
                            selectedValues={fieldsValue.data.result ?? []}
                            onSelect={(payload) => {
                              const index = fieldsValue.data.result?.indexOf(payload);
                              let outcome: string[] = [];

                              if (index === -1) {
                                // add new reason
                                outcome = [...fieldsValue.data.result, payload];
                              } else {
                                // splice reason
                                const clone = cloneDeep(fieldsValue.data.reasons);
                                clone.splice(index, 1);
                                outcome = clone;
                              }
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  result: outcome,
                                },
                              });
                            }}
                            onDelete={function (item: string): void {
                              // remove item from reason
                              const index = fieldsValue.data.result.indexOf(item);
                              const clone = cloneDeep(fieldsValue.data.result);
                              clone.splice(index, 1);
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  result: clone,
                                },
                              });
                            }}
                            onClearSelect={function (): void {
                              onUpdateFields({
                                ticketId: fieldsValue.ticketId,
                                data: {
                                  ...fieldsValue.data,
                                  result: [],
                                },
                              });
                            }}
                          />
                        </div>
                      </Form.Item>
                      {isAdmin ? (
                        <div className="flex gap-x-[8px]">
                          <Font14G3W400>
                            {t('livechat.ticket.console.resolve.outcome.add.new.description')}
                          </Font14G3W400>
                          <HyperLink to={`${ROUTE.TICKET_WRAP_UP}?tab=outcome`}>
                            {t('livechat.ticket.console.resolve.add.new.outcome.hyperlink')}
                          </HyperLink>
                        </div>
                      ) : null}
                    </Section>
                    <div className="h-[1px] bg-[#E5E5E5] w-full mt-[16px] mb-[16px]" />
                    <Section>
                      <Label>{t('livechat.ticket.console.resolve.note.label')}</Label>
                      <Form.Item>
                        <TextArea
                          showCount
                          maxLength={200}
                          value={fieldsValue.data.note}
                          onChange={(e) => {
                            onUpdateFields({
                              ticketId: fieldsValue.ticketId,
                              data: {
                                ...fieldsValue.data,
                                note: e.target.value,
                              },
                            });
                          }}
                        />
                      </Form.Item>
                    </Section>
                  </Form>
                </Spin>
              </InnerContentBox>
            </ContentBox>
            <BottomBox>
              <ButtonBox>
                <PrimaryButton
                  form="resolve-ticket-form"
                  htmlType={'submit'}
                  size="large"
                  block
                  disabled={isResolvingTicket}
                  onClick={() => {
                    // if config set required of reason and outcome, we need to validate form
                    if (setting.isWrapUpTicketRequired) {
                      const result = isValidatePass();
                      if (!result) {
                        return;
                      }
                    }
                    handleOnSubmit();
                  }}
                >
                  {t('livechat.ticket.console.resolve.ok.button')}
                </PrimaryButton>
                <AutoSaveBox>{_renderAutoSave()}</AutoSaveBox>
              </ButtonBox>
            </BottomBox>
          </Container>
        )}
      </SideDrawer>
    );
  };
  if (isOnResultStage) {
    if (isResolvedTicketSuccess || isTicketResolved) {
      return _renderResolveSuccess();
    }
    if (isResolvedTicketFailed) {
      return _renderResolveFailed();
    }
  }

  return _renderResolveForm();
};

export default ResolveDrawer;
