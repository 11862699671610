import React from 'react';
import { ModalProps } from 'antd';
import { ErrorContainer } from './style';
export type IBlankModal = React.PropsWithChildren<ModalProps>;

export const BlankModal: React.FC<IBlankModal> = ({ visible, children, ...rest }: IBlankModal) => {
  return (
    <ErrorContainer {...rest} title="" visible={visible} footer={false}>
      {children}
    </ErrorContainer>
  );
};
