enum FileExtension {
  AI = 'ai',
  PSD = 'psd',
  PDF = 'pdf',
  PPTX = 'pptx',
  DOCX = 'docx',
  CSV = 'csv',
  XLSX = 'xlsx',
  PPT = 'ppt',
  DOC = 'doc',
  PNG = 'png',
  JPEG = 'jpeg',
  MP3 = 'mp3',
  M4A = 'm4a',
}

export default FileExtension;
