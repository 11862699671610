import _ from 'lodash';
import { createMachine, assign } from 'xstate';
import { getAllBotMembers, getAvailableMembers, saveTeam } from '../fns';
import { IMemberList, IMember } from '../TeamManagementPane/teamManagementMachine';

export type INewTeamEditorContext = {
  searchTxt: string;
  availableMembers: IMemberList;
  selectedMembers: string[];
  currentPage: number;
  errorMessage: string;
};

const newTeamEditorMachine = createMachine<INewTeamEditorContext>(
  {
    id: 'team-management-new-team-editor-machine',
    initial: 'fetchingAvailableMembers',
    context: {
      selectedMembers: [],
      searchTxt: '',
      availableMembers: {} as IMemberList,
      currentPage: 1,
      errorMessage: '',
    },
    states: {
      editing: {
        on: {
          SET_SELECTED_MEMBERS: {
            target: 'editing',
            actions: 'updateSelectedMembers',
          },
          SUBMIT_FORM: {
            target: 'saving',
          },
          FETCH_MEMBER: {
            target: 'fetchingAvailableMembers',
            actions: 'updateCurrentPage',
          },
          FILTER_OUT_BOT_MEMBER: {
            target: 'filteringOutBotMembers',
          },
        },
      },
      fetchingAvailableMembers: {
        invoke: {
          id: 'fetch-available-member',
          src: getAvailableMembers,
          onDone: {
            target: 'editing',
            actions: 'updateAvailableMembers',
          },
          onError: 'fetchAvailableMembersFailed',
        },
      },
      fetchAvailableMembersFailed: {},
      debounceFetchMember: {
        on: {
          SEARCH_TEXT_CHANGE: {
            target: 'debounceFetchMember',
            actions: 'updateSearchTxt',
          },
        },
        after: {
          450: {
            target: 'fetchingAvailableMembers',
          },
        },
      },
      saving: {
        invoke: {
          id: 'save-team',
          src: saveTeam,
          onDone: {
            target: 'saveSuccess',
            // actions: 'updateAvailableMembers',
          },
          onError: {
            target: 'editing',
            actions: 'updateErrorMessage',
          },
        },
      },
      saveSuccess: {
        type: 'final',
      },
      filteringOutBotMembers: {
        invoke: {
          id: 'filtering-out-bot-members',
          src: getAllBotMembers,
          onDone: {
            target: 'editing',
            actions: 'postUpdateFilteredBotMembers',
          },
          onError: {
            target: 'editing',
            actions: 'updateErrorMesssage',
          },
        },
      },
    },
    on: {
      SEARCH_TEXT_CHANGE: {
        target: '.debounceFetchMember',
        actions: 'updateSearchTxt',
      },
    },
  },
  {
    actions: {
      updateSearchTxt: assign({
        searchTxt: (context, event) => {
          return event.value;
        },
      }),
      updateAvailableMembers: assign({
        availableMembers: (context, event) => {
          const { data } = event.data;
          return data;
        },
      }),
      updateSelectedMembers: assign({
        selectedMembers: (context, event) => {
          const { data } = event;
          return data;
        },
      }),
      updateCurrentPage: assign({
        currentPage: (context, event) => {
          return event.page;
        },
      }),
      updateErrorMessage: assign({
        errorMessage: (context, event) => {
          return event.data?.response?.data?.message ?? 'Something went wrong';
        },
      }),
      postUpdateFilteredBotMembers: assign({
        selectedMembers: (context, event) => {
          const bots: (Record<string, unknown> & { _id: string })[] = event.data.data.rows;
          const keys: string[] = bots.map((bot) => bot._id);
          return _.without(context.selectedMembers, ...keys);
        },
      }),
    },
  },
);

export default newTeamEditorMachine;
