import { client } from '@api';
import { CancelTokenSource } from 'axios';
import qs from 'qs';

export type IAssigneeParams = {
  skip?: number;
  take?: number;
  keyword?: string;
  cursorId?: string;
};

export const getAllAgentsInNetwork = async (params: IAssigneeParams, cancelToken?: CancelTokenSource) => {
  const url = '/api/agents';
  const queryString = qs.stringify(params);
  return client.get(`${url}?${queryString}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getAgentInMyTeam = () => {
  return client.get('/api/teams/me/agents', {
    params: {
      limits: 1000,
    },
  });
};
