import React, { useEffect, useMemo } from 'react';
import {
  Container,
  ImageSection,
  Overlay,
  TertiaryButton,
  ImagePlaceholder,
  ActionWrap,
  regionStyle,
  MapDecision,
  IconBtn,
  IconWrap,
  ImageContainer,
  OverlayAction,
  RegionSelectContainer,
} from './style';
import RegionSelect from 'react-region-select';
import { useImageMap } from './useImageMap';
import { Popover } from '@components/Popover';
import { ImageMapUploader } from './ImagemapUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IImageUploadedParams } from '@providers/ImageUploadProvider';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faExclamation, faExclamationCircle, faImage, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Font14G5W400 } from '@components/UtilsComponent';
import { SurveyPopover } from '@components/SurveyPopover';
import { QUICK_REPLY_POINT } from '@configs/constants';
import { ISurveyImageMapAction } from '@types';

export const enum VALIDATE_MESSAGE {
  TEXT = 'Text is required',
  URL = 'URL is required',
  COMPLETENESS = 'Please check completeness',
  ACTION = 'Action value is required',
  IMAGE_AND_ACTION = 'images and actions is required',
  IMAGE_MAP_NOT_COMPLETE = 'Please complete the imagemap before saving.',
  EMPTY_IMAGE_MAP_ACTION = 'At least one action is required',
}

export type IRegion = {
  isChanging: boolean;
  new: boolean;
  height: number;
  width: number;
  x: number;
  y: number;
  index: number;
  btn: {
    area: {
      height: number;
      width: number;
      x: number;
      y: number;
    };
    message: string;
    point: string;
    isPopover: boolean;
    errorMessage?: string;
  };
  data: any;
};

type ImageMapData = {
  data: any;
  isChanging: boolean;
  index: number;
};

export type IImageMapMessage = {
  imagemap?: ISurveyImageMapAction;
  maxRegions: number;
  onUploadedImage: ({ url }: IImageUploadedParams) => void;
  onRemoveImageUrl: () => void;
  onConfirmMapAction: (regions: IRegion[]) => void;
  [key: string]: any;
};

const MAX_REGIONs = 50;

export const ImageMapMessage: React.FC<IImageMapMessage> = ({
  imagemap,
  maxRegions = MAX_REGIONs,
  onUploadedImage,
  onConfirmMapAction,
  onRemoveImageUrl,
}) => {
  const { current, isBlankMode, imageMode, isImageMode, internalErrorMessage, send } = useImageMap(imagemap);
  const { context } = current;
  const points = useMemo(() => {
    const usedPoints = current.context.regions.map((d: IRegion) => d.data?.btn?.point);
    const newPoints = QUICK_REPLY_POINT.map((point) => {
      return usedPoints.includes(point.value) ? { ...point, disabled: true } : { ...point, disabled: false };
    });
    return newPoints;
  }, [current.context]);

  const _regionRenderer = (data: ImageMapData) => {
    if (!data.isChanging) {
      const { area, ...rest } = data.data.btn;
      const { errorMessage } = data.data.btn;
      return (
        <>
          <ActionWrap>
            <SurveyPopover
              outSideMessage={rest.message}
              outSidepoint={rest.point}
              points={points}
              onActionPopoverChange={() => {
                send('UPDATE_ACTION_POPUP', {
                  index: data.index,
                });
              }}
              onDelete={() => {
                send('REMOVE_INTERNAL_REGION_ACTION', { index: data.index });
              }}
              regionId={rest.id}
              isPopover={rest.isPopover}
              onDone={({ point, message }) => {
                send('SUMBIT_INTERNAL_REGION_ACTION', {
                  message,
                  point,
                  area,
                  index: data.index,
                  errorMessage: '',
                });
              }}
              onCancel={() => {
                send('CANCEL_INTERNAL_REGION_ACTION', { index: data.index });
              }}
            >
              <div className="w-[20px] h-[20px] flex justify-center items-center">
                <FontAwesomeIcon icon={faEllipsisV} className="!text-[16px]" />
              </div>
            </SurveyPopover>
          </ActionWrap>
          {errorMessage ? (
            <div className="absolute left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2">
              <FontAwesomeIcon icon={faExclamationCircle} className="!text-[16px] !text-[#FFB400]" />
            </div>
          ) : null}
        </>
      );
    }
  };
  const _renderImageMapContent = () => {
    if (isImageMode) {
      return (
        <>
          {imageMode.isMapImage ? (
            <RegionSelect
              style={{ width: '100%' }}
              regionStyle={regionStyle}
              constraint
              maxRegions={maxRegions}
              regions={context.regions}
              onChange={
                // onChange
                (regions: IRegion[]) => {
                  send('MAPPING_IMAGE', { regions });
                }
              }
              regionRenderer={_regionRenderer}
            >
              <img src={imagemap?.imgUrl || ''} />
            </RegionSelect>
          ) : (
            <RegionSelectContainer
              onMouseOver={() => {
                send('HOVERING');
              }}
              onMouseLeave={() => {
                send('IDLE');
              }}
            >
              <RegionSelect
                style={{ width: '100%' }}
                regionStyle={regionStyle}
                onChange={() => false}
                constraint
                maxRegions={maxRegions}
                regions={context.regions}
              >
                <img src={imagemap?.imgUrl} />
                {imageMode.isHovering && (
                  <Overlay>
                    <OverlayAction>
                      <TertiaryButton
                        onClick={() => {
                          if (isImageMode) {
                            send('CHANGE_MEDIA');
                            send('UPDATE_SHOW_POPUP', {
                              showPopup: true,
                            });
                          }
                        }}
                      >
                        Change Media
                      </TertiaryButton>
                      <TertiaryButton
                        onClick={() => {
                          if (isImageMode) {
                            send('MAP_IMAGE');
                            send('DRAGE_MODE', {
                              showPopup: true,
                            });
                          }
                        }}
                      >
                        Map image
                      </TertiaryButton>
                    </OverlayAction>
                  </Overlay>
                )}
              </RegionSelect>
            </RegionSelectContainer>
          )}
        </>
      );
    }

    //blank
    return (
      <ImagePlaceholder className="flex flex-col justify-center gap-y-[16px] items-center">
        <FontAwesomeIcon icon={faImage} className="text-[#BDBDBD] text-[72px]" />
        <Font14G5W400>Click to setup your media</Font14G5W400>
      </ImagePlaceholder>
    );
  };

  return (
    <Container>
      <Popover
        overlayInnerStyle={{
          borderRadius: 12,
        }}
        visible={context.showPopup}
        onVisibleChange={() => {
          if (isBlankMode) {
            send('UPDATE_SHOW_POPUP', {
              showPopup: !context.showPopup,
            });
          }
        }}
        content={
          <ImageMapUploader
            selectedMedia={imagemap?.imgUrl ?? ''}
            onUploadedImage={({ url, type, dimension }) => {
              onUploadedImage({ url, type, dimension });
              send('UPDATE_SHOW_POPUP', {
                showPopup: false,
              });
            }}
            onRemoveImageUrl={() => {
              onRemoveImageUrl();
            }}
            onCancel={() =>
              send('UPDATE_SHOW_POPUP', {
                showPopup: false,
              })
            }
          />
        }
        placement="right"
        trigger={['click']}
      >
        <ImageContainer>
          {imageMode.isMapImage && (
            <MapDecision>
              <IconWrap
                onClick={() => {
                  // no action
                  if (context.regions.length === 0) {
                    send('INVALIDATE_MAP_ACTION', {
                      errorMessage: VALIDATE_MESSAGE.EMPTY_IMAGE_MAP_ACTION,
                    });
                    return;
                  }
                  const newRegions = [];
                  let isInvalid = false;
                  for (const item of context.regions) {
                    if (!item.data.btn.message || !item.data.btn.point) {
                      isInvalid = true;
                      newRegions.push({
                        ...item,
                        data: {
                          ...item.data,
                          btn: {
                            ...item.data.btn,
                            errorMessage: VALIDATE_MESSAGE.IMAGE_AND_ACTION,
                          },
                        },
                      });
                    } else {
                      newRegions.push(item);
                    }
                  }

                  if (isInvalid) {
                    send('UPDATE_REGION_ERROR', {
                      newRegions,
                    });
                    return;
                  }

                  send('CONFIRM_MAP_ACTION', {
                    regions: context.regions,
                    errorMessage: '',
                  });
                  onConfirmMapAction(context.regions);
                }}
              >
                <IconBtn icon={faCheck} />
              </IconWrap>
              <IconWrap
                onClick={() => {
                  send('CANCEL_MAP_ACTION');
                }}
              >
                <IconBtn icon={faTimes} />
              </IconWrap>
            </MapDecision>
          )}
          <ImageSection imgUrl={imagemap?.imgUrl} isInvalidate={!!internalErrorMessage}>
            {_renderImageMapContent()}
          </ImageSection>
        </ImageContainer>
      </Popover>
    </Container>
  );
};
