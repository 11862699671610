import { IDropDownData } from '@components/Dropdown';

export const COLLECTION_ERROR_MAPPING: {
  [key: string]: {
    title: string;
    description: string;
  };
} = {
  'Unique constraint failed. (TemplateCollection_networkId_name_key)': {
    title: 'Collection name already existing',
    description: 'Please check and change the name collection',
  },
};

export const QUICK_REPLY_COLLECTION_TABLE_ACTION: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Edit collection',
        value: 'edit',
      },
      {
        label: 'Delete collection',
        value: 'delete',
      },
    ],
  },
];
