import { resolveTicket } from '@api/livechat/EngagementMessage';
import { saveDraftWrapup } from '@api/livechat/Ticket';
import { AnyEventObject } from 'xstate';
import { ITicketMachineContext } from '.';

export const resolveTicketState = {
  on: {
    SAVE_DRAFT_WRAPUP: {
      target: '.saveDraftWrapup.savingDraft',
    },
    CONFIRM_RESOLVING_TICKET: {
      target: '.resolveTicket.resolvingTicket',
    },
  },
  initial: 'idle',
  states: {
    idle: {
      tags: ['isOnResolvePageTag'],
    },
    saveDraftWrapup: {
      initial: 'idle',
      states: {
        idle: {
          tags: ['isOnResolvePageTag'],
        },
        savingDraft: {
          tags: ['isOnResolvePageTag'],
          invoke: {
            src: (context: ITicketMachineContext, event: AnyEventObject) => {
              const { ticket } = context;
              const { reasons, result, note } = event;
              if (ticket) {
                return saveDraftWrapup(ticket._id, {
                  categories: ticket.summary?.categories ?? [],
                  reasons,
                  result,
                  note,
                });
              }
              return Promise.reject('Ticket not found');
            },
            onDone: {
              target: 'savedDraftSuccess',
              actions: 'updateTicketAfterSaveWrapup',
            },
            onError: {
              target: 'savedDraftFailed',
            },
          },
        },
        savedDraftSuccess: {
          tags: ['isOnResolvePageTag'],
        },
        savedDraftFailed: {
          tags: ['isOnResolvePageTag'],
        },
      },
    },
    resolveTicket: {
      initial: 'idle',
      states: {
        idle: {
          tags: ['isOnResolvePageTag'],
        },
        resolvingTicket: {
          tags: ['isOnResolvePageTag'],
          invoke: {
            src: (context: ITicketMachineContext) => {
              if (context.ticket) {
                const ticket = context.ticket;
                return resolveTicket(ticket._id, ticket.nid);
              }
              return Promise.reject(`Ticket not found`);
            },
            onDone: {
              target: 'resolvedTicketSuccess',
            },
            onError: {
              target: 'resolvedTicketFailed',
            },
          },
        },
        resolvedTicketSuccess: {
          tags: ['isOnResultPageTag', 'resolveSuccess'],
        },
        resolvedTicketFailed: {
          tags: ['isOnResultPageTag', 'resolveFailed'],
        },
      },
    },
  },
};
