import { EEventType } from '@enums/EventType';
import { ESenderType } from '@enums/SenderType';
import { IRealMessage, ImageMessage, LinkMessage, TextMessage, VideoMessage } from '@model/MessageItem';
import { IMember, ITicket, IUser } from '@types';

type IMessagePayloadGeneratorParams = {
  message: IRealMessage;
  member: IMember;
  selectedUser: IUser;
  selectedTicket: ITicket;
};

export const messagePayloadGenerator = ({
  message,
  member,
  selectedUser,
  selectedTicket,
}: IMessagePayloadGeneratorParams) => {
  const payload = getMessagePayload(message, member);
  if (!payload) return null;
  return {
    payloads: [payload],
    ...getUserPayload(selectedUser),
    channelType: selectedUser.channel?.type,
    ticketId: selectedTicket._id,
    createdAt: message.createdDate,
  };
};

const getMessagePayload = (message: IRealMessage, member: IMember) => {
  if (message instanceof ImageMessage || message instanceof VideoMessage || message instanceof LinkMessage) {
    return {
      eventType: EEventType.MESSAGE,
      messageType: message.getMessageType,
      sender: ESenderType.AGENT,
      senderName: member.username,
      url: message.instanceData.url,
    };
  }
  if (message instanceof TextMessage) {
    return {
      eventType: EEventType.MESSAGE,
      messageType: message.getMessageType,
      sender: ESenderType.AGENT,
      senderName: member.username,
      text: message.instanceData.text,
    };
  }
  return null;
};

const getUserPayload = (selectedUser: IUser) => {
  return {
    userId: selectedUser.id,
    userSocialId: selectedUser.userSocialId,
    channelId: selectedUser.channel?.id,
    channelType: selectedUser.channel?.type,
  };
};
