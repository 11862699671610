import { Radio } from 'antd';
import styled from 'styled-components/macro';

export const Container = styled.div``;

export const AntdRadio: typeof Radio = styled(Radio)`
  align-items: center;
  .ant-radio {
    top: 0;
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border: 1px solid #bdbdbd;
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: ${({ theme }) => theme.PRIMARY};
    border: none;
    :after {
      background-color: white;
    }
  }

  .ant-radio-inner::after {
    top: 6px;
    left: 6px;
  }
`;
