import dayjs, { ManipulateType } from 'dayjs';
import { useState, useEffect } from 'react';

const MAX_ROW = 6;
const TODAY = dayjs();
// left calendar
const firstDayOfCurrentMonth = TODAY.startOf('month');
const endDayOfCurrentMonth = TODAY.endOf('month');
// right calendar
const firstDayOfNextMonth = TODAY.add(1, 'month').startOf('month');
const endDayOfNextMonth = TODAY.add(1, 'month').endOf('month');

export const enum EDateType {
  DATE_OF_PREV_MONTH = 'date_of_prev_month',
  DATE_OF_CURRENT_MONTH = 'date_of_current_month',
  DATE_OF_NEXT_MONTH = 'date_of_next_month',
}

export const enum EStep {
  START = 'start',
  END = 'end',
}

export type IDateArray = {
  type: EDateType;
  date: dayjs.Dayjs;
};

const generateDateOfMonth = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  const dArr: IDateArray[] = [];

  for (let i = 0; i < MAX_ROW; i++) {
    for (let j = 0; j < 7; j++) {
      // first week shift to the right if date is not in month
      if (i === 0 && j < startDate.day()) {
        dArr.push({
          type: EDateType.DATE_OF_PREV_MONTH,
          date: startDate.subtract(startDate.day() - j, 'day'),
        });
        continue;
      }
      // first week
      if (i === 0 && j === startDate.day()) {
        dArr.push({ type: EDateType.DATE_OF_CURRENT_MONTH, date: startDate });
        continue;
      }
      const lastDate = dArr[dArr.length - 1];
      const nextDay = lastDate.date.add(1, 'day');
      // if last date is date obj push date + 1 to arr
      if (
        lastDate.type === EDateType.DATE_OF_CURRENT_MONTH &&
        lastDate.date.isBefore(endDate) &&
        nextDay.isSame(endDate, 'month')
      ) {
        dArr.push({
          type: EDateType.DATE_OF_CURRENT_MONTH,
          date: nextDay,
        });
        continue;
      }
      if (lastDate.type === EDateType.DATE_OF_NEXT_MONTH && lastDate.date.isAfter(endDate)) {
        dArr.push({
          type: EDateType.DATE_OF_NEXT_MONTH,
          date: nextDay,
        });
        continue;
      }
      dArr.push({
        type: EDateType.DATE_OF_NEXT_MONTH,
        date: nextDay,
      });
    }
  }

  return dArr;
};

const getEndTime = (date: dayjs.Dayjs) => {
  if (date.isSame(TODAY, 'day')) {
    // less than 30 min set to 00 min
    if (TODAY.minute() < 30) {
      return TODAY.startOf('hour');
    }
    // more than 30 set to 30 min
    else {
      return TODAY.startOf('hour').add(30, 'minute');
    }
  }
  return date.endOf('day').subtract(29, 'minute');
};

const startDatePicker = generateDateOfMonth(firstDayOfCurrentMonth, endDayOfCurrentMonth);
const endDatePicker = generateDateOfMonth(firstDayOfNextMonth, endDayOfNextMonth);

type IDateRangePickerState = {
  startDate: dayjs.Dayjs | null;
  startTime: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  endTime: dayjs.Dayjs | null;
};

export const useDateRangePicker = (isClearEvent?: boolean) => {
  const [step, setStep] = useState<EStep>(EStep.START);
  const [selectedDate, setSelectedDate] = useState<IDateRangePickerState>({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });
  const [hoverDate, setHoverDate] = useState<dayjs.Dayjs | null>(null);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(TODAY);
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(TODAY.add(1, 'month'));
  const [startDateArr, setStartDateArr] = useState<IDateArray[]>(startDatePicker);
  const [endDateArr, setEndDateArr] = useState<IDateArray[]>(endDatePicker);

  useEffect(() => {
    if (isClearEvent) {
      clearAllState();
    }
  }, [isClearEvent]);

  const clearAllState = () => {
    setStep(EStep.START);
    setSelectedDate({
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
    });
    setHoverDate(null);
    setStartDate(TODAY);
    setEndDate(TODAY.add(1, 'month'));
    setStartDateArr(startDatePicker);
    setEndDateArr(endDatePicker);
  };

  return {
    step,
    hoverDate,
    selectedDate,
    startDate,
    endDate,
    startDateArr,
    endDateArr,
    onHoverDate: (date: dayjs.Dayjs | null) => {
      setHoverDate(date);
    },
    onClear: () => {
      // set step to start
      setStep(EStep.START);
      // clear all selected date and time
      setSelectedDate({
        startDate: null,
        startTime: null,
        endDate: null,
        endTime: null,
      });
    },
    onSelectDate: (date: dayjs.Dayjs | null, type: 'start' | 'end') => {
      if (!date) return;
      // select start date
      if (type === 'start') {
        // set step to end
        setStep(EStep.END);
        // if end date is before start date then change start date to that date
        if (selectedDate.endDate && date.isAfter(selectedDate.endDate)) {
          setSelectedDate({
            ...selectedDate,
            startDate: date,
            startTime: date.startOf('day'),
            endDate: null,
            endTime: null,
          });
          return;
        }
        setSelectedDate({
          ...selectedDate,
          startDate: date,
          // update start time to 00:00
          startTime: date.startOf('day'),
        });
      }
      // select end date
      if (type === 'end') {
        // if start date not selected set step to start
        if (!selectedDate.startDate) {
          setStep(EStep.START);
        }
        // if end date is before start date change start date instead
        if (date.isBefore(selectedDate.startDate)) {
          setSelectedDate({
            ...selectedDate,
            startDate: date,
            startTime: date.startOf('day'),
          });
          return;
        }
        setSelectedDate({
          ...selectedDate,
          endDate: date,
          // update end time to end of day if date is not today
          endTime: getEndTime(date),
        });
      }
    },
    onSelectTime: (time: dayjs.Dayjs | null, type: 'start' | 'end') => {
      if (!time) return;
      if (type === 'start') {
        setSelectedDate((prev) => {
          return {
            ...prev,
            startTime: time,
          };
        });
      }
      if (type === 'end') {
        setSelectedDate((prev) => {
          return {
            ...prev,
            endTime: time,
          };
        });
      }
    },
    onClickDateBox: (step: EStep) => {
      // set step
      setStep(step);
    },
    onClickNext: (unit: ManipulateType) => {
      // change startDate to next unit
      const nextStartDate = startDate.add(1, unit);
      const nextEndDate = endDate.add(1, unit);
      setStartDate(nextStartDate);
      // change endDate to next unit
      setEndDate(nextEndDate);
      setStartDateArr(() => {
        return generateDateOfMonth(nextStartDate.startOf('month'), nextStartDate.endOf('month'));
      });
      setEndDateArr(() => {
        return generateDateOfMonth(nextEndDate.startOf('month'), nextEndDate.endOf('month'));
      });
    },
    onClickPrev: (unit: ManipulateType) => {
      const prevStartDate = startDate.subtract(1, unit);
      const prevEndDate = endDate.subtract(1, unit);
      // change startDate to prev unit
      setStartDate(prevStartDate);
      // change endDate to prev unit
      setEndDate(prevEndDate);
      setStartDateArr(generateDateOfMonth(prevStartDate.startOf('month'), prevStartDate.endOf('month')));
      setEndDateArr(generateDateOfMonth(prevEndDate.startOf('month'), prevEndDate.endOf('month')));
    },
  };
};
