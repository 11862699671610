import { ERole } from '@enums/Role';
import { ETicketStatus } from '@enums/TicketStatus';
import { IMember, ITicket } from '@types';

export type ICheckDisableAssigneeSelectParams = {
  currentTicket: ITicket;
  member: IMember;
  isCollaboration: boolean;
};

const isCurrentLoginAgentIsOwnerOfTheTicket = (currentLoginMember: IMember, ticket: ITicket) => {
  return currentLoginMember._id === ticket.agentId;
};

export const checkDisableAssigneeSelect = ({
  currentTicket,
  member,
  isCollaboration,
}: ICheckDisableAssigneeSelectParams): boolean => {
  // ticket is resolved
  if (currentTicket.status === ETicketStatus.RESOLVED) {
    return true;
  }

  if (isCollaboration) {
    return false;
  }

  if (member.roles[0] === ERole.SUPERADMIN) {
    return true;
  }
  // ticket does not belong to agent but it's assigned to team
  if (!currentTicket.agentId && currentTicket.team && currentTicket.status === ETicketStatus.ASSIGNED) {
    return false;
  }
  // current agent is not owner of the ticket
  // ticket status is assigned
  // current agent is not admin
  if (
    !isCurrentLoginAgentIsOwnerOfTheTicket(member, currentTicket) &&
    currentTicket.status === ETicketStatus.ASSIGNED &&
    member.roles[0] !== ERole.ADMIN
  ) {
    return true;
  }
  // current agent is not owner of the ticket
  // ticket status is open
  if (!isCurrentLoginAgentIsOwnerOfTheTicket(member, currentTicket) && currentTicket.status === ETicketStatus.OPEN) {
    return false;
  }

  return false;
};
