import React from 'react';
import { Container, TabContainer } from './style';

export type ITabItem = {
  tab: string | JSX.Element;
  key: string;
  children?: any;
};

export type ITab = {
  tabs: Readonly<ITabItem[]>;
  defaultActiveKey: string;
  onTabClick: (key: string, event: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>) => void;
  [key: string]: any;
};

const Tab: React.FC<ITab> = ({ tabs, ...props }: ITab) => {
  return (
    <Container>
      <TabContainer {...props}>
        {tabs.map(({ tab, key, children }) => {
          return (
            <TabContainer.TabPane tab={tab} key={key}>
              {children}
            </TabContainer.TabPane>
          );
        })}
      </TabContainer>
    </Container>
  );
};

export default Tab;
