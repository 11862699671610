import styled from 'styled-components/macro';
import { Badge as AntdBadge } from 'antd';

export const Badge = styled(AntdBadge)`
  .ant-scroll-number {
    background: ${({ theme }) => theme.PRIMARY};
    color: white;
    border: none;
  }
`;

export const Container = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 8px;
  min-width: 268px;
  padding: 8px;
  cursor: pointer;
  height: 40px;
  line-height: 20px;
  div {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    color: ${({ active, theme }) => (active ? theme.PRIMARY : theme.GREY_1)};
    font-weight: ${({ active }) => (active ? '600' : '400')};
    svg {
      width: 24px !important;
      height: 20px !important;
      margin-right: 8px;
      color: ${({ active, theme }) => (active ? theme.PRIMARY : theme.GREY_3)};
    }
  }

  ${Badge} {
    .ant-scroll-number {
      ${({ theme, active }) => `
            color: ${active ? theme.PRIMARY : 'black'};
            background:  transparent;
            font-weight: ${active ? '600' : '400'};
        `}
    }
  }
`;
