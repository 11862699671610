import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 64px;
  width: 100%;
  padding: 16px 12px;
  display: grid;
  column-gap: 12px;
  grid-template-columns: 40px auto;
  align-items: center;
`;
