import React from 'react';
import { Container } from './style';
import { ITeam } from '@types';
import { Text } from '@components/UtilsComponent';

export type ITeamListItem = {
  team: ITeam;
  onSelect: (member: ITeam) => void;
};

const TeamListItem: React.FC<ITeamListItem> = ({ team, onSelect }: ITeamListItem) => {
  return (
    <Container onClick={() => onSelect(team)}>
      <Text>{team.name}</Text>
    </Container>
  );
};

export default TeamListItem;
