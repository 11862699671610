import React from 'react';
import { Container } from './style';
import { PopoverProps } from 'antd';
type IPopoverProps = {
  content: React.ReactNode;
} & PopoverProps;

export const Popover = (props: IPopoverProps) => {
  return <Container {...props} content={props.content}></Container>;
};
