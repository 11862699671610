import { useEffect, useRef } from 'react';

export const useKeyPress = (keys: string[], cb: () => void) => {
  const keysRef = useRef<{ [key: string]: boolean }>({});
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (keys.includes(e.key)) {
        e.preventDefault();
        if (!keysRef.current[e.key]) {
          keysRef.current[e.key] = true;
        }
      }
      // check if all key pressed
      if (keys.every((k) => keysRef.current[k])) {
        cb();
      }
    };

    const handleKeyUp = () => {
      // reset every key pressed
      if (keysRef.current) {
        keysRef.current = {};
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [keys]);
};
