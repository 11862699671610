import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WarningToastContainer, WarningIconWrap } from './styles';

type IWarningToast = {
  align: string;
  [key: string]: any;
};

const WarningToast: React.FC<IWarningToast> = ({ children, align }: IWarningToast) => {
  return (
    <WarningToastContainer align={align}>
      <WarningIconWrap>
        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
      </WarningIconWrap>
      {children}
    </WarningToastContainer>
  );
};

export default WarningToast;
