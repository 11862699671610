import { BOTS, IMessageLevel } from '@types';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { EMessageType } from '@enums/MessageType';
import EChannelType from '@enums/ChannelType';
import { getMessageFromTemplate } from '@providers/MessageBoxProvider/fns';

export type ITemplateMessage = ReturnType<typeof getMessageFromTemplate>['newMessage'];
type TemplateFailedJson = {
  payload: {
    templateId: string;
    userId: string;
    userSocialId: string;
    channelId: string;
    ticketId: string;
    channelType: EChannelType;
  };
  livechatMessages: ITemplateMessage;
  previewMessage: BOTS.IMessageApi[];
} & BaseMessageJson;

export class TemplateFailed extends BaseMessage {
  private payload: TemplateFailedJson['payload'] = {} as TemplateFailedJson['payload'];
  private livechatMessages: ITemplateMessage;
  private previewMessage: BOTS.IMessageApi[];
  private level: IMessageLevel;

  constructor(data: TemplateFailedJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt });
    this.payload = data.payload;
    this.livechatMessages = data.livechatMessages;
    this.previewMessage = data.previewMessage;
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      data: this.payload,
      livechatMessage: this.livechatMessages,
      previewMessage: this.previewMessage,
      retry: this.retry,
      level: this.level,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  get getMessageType() {
    return EMessageType.TEMPLATE_FAILED;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
