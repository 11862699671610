import React, { useCallback, useEffect, useMemo } from 'react';
import SelectBottomModal from '@components/BottomModal/SelectBottomModal';
import { SelectData } from '@components/BottomModal/SelectBottomModal/useSelectBottomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Menu, Row, Space, Typography } from 'antd';
import { BoldText } from '@components/UtilsComponent';
import { BorderlessButton } from './style';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { IChannel } from '@types';
import { IOption } from '@components/Select';
import { IChannelParams, getChannels } from '@api/livechat/Settings';
import { faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconBox, Label, LabelBox } from '@components/BottomModal/SelectBottomModal/style';
import { Checkbox } from '@components/Checkbox';
import getLogoByChannelType from '@utils/getLogoByChannelType';
import SearchInput from '@components/SearchInput';
import { ClearButton } from '@components/Dropdown/DropdownDynamicBtn/style';
import { useChannelSelector } from './useChannelSelector';

const { Item } = Menu;

export type IChannelSelector = {
  open: boolean;
  selected: IChannel[];
  setOpen: (open: boolean) => void;
  onSelectDone: (values: IChannel[]) => void;
};
const ChannelSelector = ({ open, selected, setOpen, onSelectDone }: IChannelSelector) => {
  const { data, searchKeyword, hasMore, isLoadingMore, onOpenDropdown, onLoadmore, onUpdateSearchKeyword } =
    useInfinityLoadByCursorId<IChannel, IOption>({
      fetchFn: async (filter, cancelToken) => {
        const newFilter: IChannelParams = {
          ...filter,
        };
        const { data }: { data: IChannel[] } = await getChannels(newFilter, cancelToken);
        const result = data.map((item) => {
          return {
            ...item,
            label: item.name,
            value: item.id,
          };
        });
        return result;
      },
      initialFilter: {
        take: 30,
      },
    });

  const { innerSelect, onInnerSelect, onClearAll, preserveOuter } = useChannelSelector(selected);

  const checkSelected = useCallback(
    (value: IChannel) => {
      return innerSelect.some((item) => item.id === value.id);
    },
    [innerSelect],
  );

  const items = useMemo(() => {
    const items: SelectData<IChannel>[] = data.map((item) => {
      return {
        label: item.name,
        icon: <img width={24} src={getLogoByChannelType(item.type)} />,
        value: item,
      };
    });
    return items;
  }, [data]);
  // initial load
  useEffect(() => {
    onOpenDropdown();
  }, []);
  const _renderSelectedChannels = () => {
    if (selected.length > 1 && selected.length < items.length) {
      return <BoldText>{`${selected.length} channels selected`}</BoldText>;
    } else if (selected.length === 1) {
      if (selected[0]) {
        return (
          <Row align={'middle'} gutter={[8, 0]}>
            <Col>
              <img src={getLogoByChannelType(selected[0].type)} className="w-[24px] h-[24px]" />
            </Col>
            <Col>
              <BoldText>{selected[0].name}</BoldText>
            </Col>
          </Row>
        );
      } else {
        return <>-</>;
      }
    } else {
      return <BoldText>All channels</BoldText>;
    }
  };
  const _renderButtonContent = () => {
    return (
      <Space size={'small'}>
        {_renderSelectedChannels()}
        <div>
          <FontAwesomeIcon size="xs" icon={faChevronDown} />
        </div>
      </Space>
    );
  };

  return (
    <div>
      <BorderlessButton
        onClick={() => {
          setOpen(true);
        }}
      >
        {_renderButtonContent()}
      </BorderlessButton>
      <SelectBottomModal
        open={open}
        selected={selected}
        items={items}
        onDismiss={() => {
          setOpen(false);
          preserveOuter(selected);
        }}
        onScrollCapture={(e) => {
          if (isLoadingMore) return;
          if (hasMore) {
            onLoadmore();
          }
        }}
        snapPoints={({ maxHeight }: { maxHeight: number }) => [maxHeight / 2, maxHeight]}
        _renderHeader={() => {
          return (
            <>
              <Row justify={'space-between'}>
                <Col>
                  <FontAwesomeIcon
                    size={'lg'}
                    icon={faTimes}
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                </Col>
                <Col>
                  <Typography.Title
                    level={5}
                    style={{
                      color: '#0F86FE',
                    }}
                    onClick={() => {
                      onSelectDone(innerSelect);
                      setOpen(false);
                    }}
                  >
                    Done
                  </Typography.Title>
                </Col>
              </Row>
              <div className="mt-[12px] flex flex-col">
                <div className="flex flex-col items-start">
                  <SearchInput
                    isGrayBg
                    value={searchKeyword}
                    onChange={(e) => {
                      onUpdateSearchKeyword(e.target.value);
                    }}
                    onClear={() => {
                      onUpdateSearchKeyword('');
                    }}
                  />

                  <ClearButton onClick={onClearAll}>Clear selection</ClearButton>
                </div>
              </div>
            </>
          );
        }}
        _renderItem={(config) => {
          return (
            <Item
              onClick={() => {
                onInnerSelect(config.item.value);
              }}
              key={`${config.item.label}-${config.item.value}`}
            >
              <Row justify={'space-between'}>
                <Col>
                  <Row align="middle" gutter={[8, 0]}>
                    {config.item.icon && (
                      <Col span={'40px'}>
                        <IconBox>{config.item.icon}</IconBox>
                      </Col>
                    )}
                    <Col>
                      <LabelBox>
                        <Label>{config.item.label}</Label>
                      </LabelBox>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Checkbox checked={checkSelected(config.item.value)} />
                </Col>
              </Row>
            </Item>
          );
        }}
      />
    </div>
  );
};

export default ChannelSelector;
