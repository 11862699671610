import { useActor } from '@xstate/react';
import { useTicketHistoryContext } from '..';
import { IResolveForm } from '@components/ResolveTicket/ResolveModal/useResolveModal';

export const useTicketConsole = () => {
  const { ticketHistoryService } = useTicketHistoryContext();
  const [current] = useActor(ticketHistoryService);
  const { send } = ticketHistoryService;

  const onUpdateTicketSummary = (payload: IResolveForm) => {
    send('UPDATE_TICKET_SUMMARY', { payload });
  };

  return {
    current,
    onUpdateTicketSummary,
  };
};
