import { IDropDownData } from '@components/Dropdown';

// Category Reason
export const CATEGORY_REASON_NAME_MIN_LENGTH = 3;
export const CATEGORY_REASON_NAME_MAX_LENGTH = 200;
export const CATEGORY_REASON_SELECTION_MAX_LENGTH = 200;
// Reason
export const REASON_NAME_MIN_LENGTH = 3;
export const REASON_NAME_MAX_LENGTH = 200;
export const REASON_MAX_LENGTH = 200;

export const REASON_TABLE_ACTION: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Delete reason',
        value: 'delete',
      },
    ],
  },
];
export const REASON_ERROR_MAPPING: {
  [key: string]: {
    title: string;
    description: string;
  };
} = {
  'Unique constraint failed. (TicketWrapUpReason_networkId_name_key)': {
    title: 'Reason already exist',
    description: 'Please check and change the name',
  },
};

// Outcome
export const OUTCOME_NAME_MIN_LENGTH = 3;
export const OUTCOME_NAME_MAX_LENGTH = 200;
export const OUTCOME_MAX_LENGTH = 200;
export const OUTCOME_TABLE_ACTION: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Delete outcome',
        value: 'delete',
      },
    ],
  },
];
export const OUTCOME_ERROR_MAPPING: {
  [key: string]: {
    title: string;
    description: string;
  };
} = {
  'Unique constraint failed. (TicketWrapUpOutcome_networkId_name_key)': {
    title: 'Outcome already exist',
    description: 'Please check and change the name',
  },
};

export const REASON_CATEGORY_TABLE_ACTION: IDropDownData[] = [
  {
    key: '',
    items: [
      {
        label: 'Edit category',
        value: 'edit',
      },
      {
        label: 'Delete category',
        value: 'delete',
      },
    ],
  },
];

export const REASON_CATEGORY_ERROR_MAPPING: {
  [key: string]: {
    title: string;
    description: string;
  };
} = {
  'Unique constraint failed. (TicketWrapUpCategory_networkId_name_key)': {
    title: 'Reason Category already exist',
    description: 'Please check and change the name',
  },
};
