import React from 'react';
import { Col, Row, Typography } from 'antd';
import { BaseTag, TagLabel } from '../style';
import { ITicketTag } from '@types';

export type ITicketTagList = {
  tags: ITicketTag[];
  displaySize: number;
};
const TicketTagList = ({ tags, displaySize }: ITicketTagList) => {
  const showTags = tags.slice(0, displaySize);
  const hiddenSize = tags.length - displaySize;
  const _renderTag = (tag: string) => {
    return (
      <BaseTag key={tag}>
        <TagLabel>{tag}</TagLabel>
      </BaseTag>
    );
  };
  return (
    <>
      <Row wrap={false}>
        {tags.length <= 0 && (
          <Typography.Text type={'secondary'} strong>
            -
          </Typography.Text>
        )}
        {tags.length > 0 && <Col>{showTags.map((tag) => _renderTag(tag.value))}</Col>}
        {hiddenSize > 0 && (
          <Col>
            <BaseTag>
              <TagLabel>+{hiddenSize}</TagLabel>
            </BaseTag>
          </Col>
        )}
      </Row>
    </>
  );
};

export default TicketTagList;
