import { client } from '@api';

export const getTicketById = (id: string) => {
  return client.get(`/api/tickets/${id}`);
};

export const assignTicketToAgent = (ticketId: string, payload: { agentId: string; team: string }) => {
  return client.put(`/api/tickets/${ticketId}/assignment/agents`, payload);
};

export const assignTicketToAdmin = (ticketId: string, payload: { agentId: string }) => {
  return client.put(`/api/tickets/${ticketId}/assignment/agents`, payload);
};

export const assignTicketToTeam = (ticketId: string, payload: { team: string }) => {
  return client.put(`/api/tickets/${ticketId}/assignment/teams`, payload);
};

export const openTicket = (userId: string, teamId?: string) => {
  return client.post(`/api/tickets/user/${userId}/create`, {
    teamId,
  });
};

export const reOpenTicket = (userId: string) => {
  return client.put(`/api/tickets/${userId}/re-open`);
};

export const saveDraftWrapup = (
  ticketId: string,
  payload: { reasons: string[]; result: string[]; note: string[] | string; categories: string[] },
) => {
  return client.post(`/api/tickets/${ticketId}/summary`, { summary: payload });
};

export const markReadByTicketId = (ticketId: string) => {
  return client.post(`/api/ticket-markers`, {
    ticketId,
    activity: 'read',
  });
};
