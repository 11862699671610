import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 64px;
  width: 100%;
  padding: 16px 12px;
  display: flex;
  column-gap: 12px;
  align-items: center;
`;
