import { client } from '@api';
import { IRatingAgentResponse } from '@types';
import { CancelTokenSource } from 'axios';
import qs from 'qs';

export type IBaseAnalyticParams = {
  agents?: string[];
  channels?: string[];
  tags?: string[];
  teams?: string[];
  startIsoStringDate: string;
  endIsoStringDate: string;

  cancelToken?: CancelTokenSource;
};

export const getStatusTicketAnalytic = ({ cancelToken, ...rest }: IBaseAnalyticParams) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/status?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getTicketActivityAnalytic = ({ cancelToken, ...rest }: IBaseAnalyticParams & { interval: string }) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/activity?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getAverageTimeAnalytic = ({ cancelToken, ...rest }: IBaseAnalyticParams) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/time?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getTicketByAgentAnalytic = ({
  cancelToken,
  ...rest
}: IBaseAnalyticParams & { status?: string[]; nextToken?: string; jobId?: string }) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/tickets/agents?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getRating = ({
  cancelToken,
  ...rest
}: IBaseAnalyticParams & { sort: 'asc' | 'desc'; nextToken?: string; jobId?: string }) => {
  const query = qs.stringify(rest);
  return client.get(`/api/v1/analytics/surveys?${query}`, {
    cancelToken: cancelToken?.token,
  });
};

export const getRatingByAgentId = ({
  agentId,
  cancelToken,
  ...rest
}: {
  agentId: string;
  cancelToken?: CancelTokenSource;
  sort: 'asc' | 'desc';
  jobId?: string;
  nextToken?: string;
} & IBaseAnalyticParams) => {
  const query = qs.stringify(rest);
  return client.get<IRatingAgentResponse>(`/api/v1/analytics/surveys/agents/${agentId}?${query}`, {
    cancelToken: cancelToken?.token,
  });
};
