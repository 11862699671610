/**
 * the determine is a string url by simple url regex
 *
 * @param {String} string input string to determine
 *
 * @returns {Boolean} is input string a url
 */
const isUrl = (string: string) => {
  const r = /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g;
  return r.test(string);
};

export default isUrl;
