import React, { useRef } from 'react';
import TextArea from '@components/TextArea';
import { Counter, Font12G5W400, Font14G1W500, Font14G5W400, Font10WhiteW400 } from '@components/UtilsComponent';
import { useGSAP } from '@gsap/react';
import useTranslation from '@hooks/useTranslation';
import gsap from 'gsap';
import { IUpdateSurveyFnSignature } from '../../CustomerSatisfactionEditor/useCustomerSatisfactionEditor';
import {
  MAX_ADDITIONAL_OPTION_LABEL_LENGTH,
  MAX_ADDITIONAL_QUESTION_LENGTH,
  MAX_GUIDELINE_LENGTH,
  MAX_OPTION_LABEL_LENGTH,
} from '@configs/constants';
import TextInput from '@components/TextInput';
import { ISurveyFeedbackOption } from '@types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { CustomFormItem } from '@components/CustomFormItem';
import { removeExceedFromText } from '@utils/letters';

type IAdditionalSurveyProps = {
  question: string;
  guideline: string;
  options: ISurveyFeedbackOption[];
  onUpdateSurvey: IUpdateSurveyFnSignature;
};

export const AdditionalSurvey = ({ question, guideline, options, onUpdateSurvey }: IAdditionalSurveyProps) => {
  const t = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  useGSAP(() => {
    // play fade and pop anitmation
    const tl = gsap.timeline();
    tl.from(containerRef.current, {
      opacity: 0,
      duration: 0.3,
    });
  }, []);

  return (
    <div className="w-full" ref={containerRef}>
      <div className="w-full rounded-[16px] shadow-custom p-[24px] bg-white transition-all">
        <div className="flex flex-col gap-y-[4px]">
          <Font14G1W500>{t('customer.satisfaction.editor.additional.qustion.label')}</Font14G1W500>
          <TextArea
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const result = removeExceedFromText(e.target.value, MAX_ADDITIONAL_QUESTION_LENGTH);
              onUpdateSurvey('message.feedbackMessage.question', result);
            }}
            value={question}
            autoSize={{
              minRows: 4,
              maxRows: 8,
            }}
            maxLength={MAX_ADDITIONAL_QUESTION_LENGTH}
          />
          <Counter>
            <Font12G5W400>{`${question?.length || 0} / ${MAX_ADDITIONAL_QUESTION_LENGTH}`}</Font12G5W400>
          </Counter>
        </div>
        <div className="mt-[24px]">
          <div className="flex-between-center">
            <div className="flex gap-x-[8px] items-center">
              <Font14G1W500>{t('customer.satisfaction.editor.additional.options.label')}</Font14G1W500>
            </div>
          </div>
          <div className="divider-y-16" />
          <div className="flex items-center min-h-[302px]">
            <div className="flex flex-col gap-y-[4px] w-[343px] border-r border-solid border-[#E5E5E5] pr-[16px]">
              <CustomFormItem
                id={`additional-yes-option`}
                isError={!options[0]?.label}
                errorMessage={t('customer.satisfaction.editor.message.response.validate')}
                value={options[0]?.label}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  onUpdateSurvey(
                    'message.feedbackMessage.options',
                    options.map((o) => (o.id === options[0]?.id ? { ...o, label: e.target.value } : o)),
                  );
                }}
                counter={
                  <Counter>
                    <Font12G5W400>{`${
                      options[0]?.label.length ? options[0]?.label.length : 0
                    } / ${MAX_ADDITIONAL_OPTION_LABEL_LENGTH}`}</Font12G5W400>
                  </Counter>
                }
              >
                <TextInput
                  size="large"
                  placeholder={t('customer.satisfaction.editor.additional.option.item.yes.placeholder')}
                  maxLength={MAX_ADDITIONAL_OPTION_LABEL_LENGTH}
                />
              </CustomFormItem>

              <Font14G5W400>{t('customer.satisfaction.editor.additional.option.item.yes.hint')}</Font14G5W400>
              <div>
                <TextArea
                  className="mt-[4px]"
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    const result = removeExceedFromText(e.target.value, MAX_GUIDELINE_LENGTH);
                    onUpdateSurvey('message.feedbackMessage.guideline', result);
                  }}
                  value={guideline}
                  autoSize={{
                    minRows: 4,
                    maxRows: 8,
                  }}
                  maxLength={MAX_GUIDELINE_LENGTH}
                />
                <Counter>
                  <Font12G5W400>{`${guideline?.length || 0} / ${MAX_GUIDELINE_LENGTH}`}</Font12G5W400>
                </Counter>
              </div>
              <div className="flex gap-x-[4px]">
                <FontAwesomeIcon icon={faInfoCircle} className="text-[16px] text-[#BDBDBD]" />
                <Font14G5W400>{t('customer.satisfaction.editor.additional.hint')}</Font14G5W400>
              </div>
            </div>
            <div className="flex flex-col gap-y-[4px] w-[343px] self-start pl-[16px]">
              <CustomFormItem
                id={`additional-no-option`}
                isError={!options[1]?.label}
                errorMessage={t('customer.satisfaction.editor.message.response.validate')}
                value={options[1]?.label}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  onUpdateSurvey(
                    'message.feedbackMessage.options',
                    options.map((o) => (o.id === options[1].id ? { ...o, label: e.target.value } : o)),
                  );
                }}
                counter={
                  <Counter>
                    <Font12G5W400>{`${
                      options[1]?.label.length ? options[1]?.label.length : 0
                    } / ${MAX_OPTION_LABEL_LENGTH}`}</Font12G5W400>
                  </Counter>
                }
              >
                <TextInput
                  size="large"
                  placeholder={t('customer.satisfaction.editor.additional.option.item.no.placeholder')}
                  maxLength={MAX_ADDITIONAL_OPTION_LABEL_LENGTH}
                />
              </CustomFormItem>

              <Font14G5W400>
                <div className="flex items-center gap-x-[8px]">
                  {t('customer.satisfaction.editor.additional.option.item.no.hint')}
                  <div className="w-[16px] h-[16px] bg-[#BDBDBD] rounded-[20px] flex justify-center items-center">
                    <Font10WhiteW400>3</Font10WhiteW400>
                  </div>
                </div>
              </Font14G5W400>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
