import { getMessages } from '@api/livechat/EngagementMessage';
import { getMessage, messageGenerator } from './messageGenerator';

export const fetchLatestUserMessage = async (userId: string) => {
  const { data } = await getMessages(userId);
  const userMessage = data.userMessages.map((message) => getMessage(message));
  const processMessage = messageGenerator([], userMessage);
  const res = {
    previousRemain: data.userMessages.length > 0,
    nextRemain: false,
    messages: processMessage,
  };
  return res;
};
