import { atom } from 'recoil';
import { CombineAllMessage } from '@model/MessageItem';

export type ChatHistory = {
  nextRemain: boolean;
  previousRemain: boolean;
  messages: CombineAllMessage[];
};

export const chatHistoryState = atom<ChatHistory>({
  key: 'chatHistoryState',
  default: {
    nextRemain: false,
    previousRemain: false,
    messages: [],
  },
});
