import React, { useMemo } from 'react';
import useTranslation from '@hooks/useTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Space, Tooltip, Typography } from 'antd';
import {
  Container,
  BackBtnWrap,
  TextWrap,
  AssigneeWrap,
  AlertWrap,
  TagContent,
  TopSection,
  BottomSection,
  Seperator,
} from './style';
import { TicketTagList, StatusTag } from '@components/Tag';
import { useTicketConsole } from './useTicketConsole';
import { useMember } from '@providers/MemberProvider';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { ITicket } from '@types';
import { IStatusTag } from '../../AllTicketsPane/tags/StatusTag';
import { Font12G4W500, Font14G1W700, Font14G3W700 } from '@components/UtilsComponent';
import { ETicketStatus } from '@enums/TicketStatus';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faStar as fasStar, faFlag as fasFlag } from '@fortawesome/pro-solid-svg-icons';
import { faStar as farStar, faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons';
import { generateSelectedTag } from '@utils/generateTicketConsole';
import { useCalculateWidth } from '@components/Tag/TicketTagList/useCalculateWidth';
import { FirstResponse } from '@components/FirstResponse';
import { ResolveTicket } from '@components/ResolveTicket';
import { IResolveForm } from '@components/ResolveTicket/ResolveModal/useResolveModal';
import { IconMove } from '@components/IconMove';
import { useFollowUp } from '@hooks/useFollowUp';
import { updateFollowUp, updateTeamFollowUp } from '@api/livechat/EngagementMessage';
import { useTheme } from 'styled-components';
import { canDoTeamFollowUp } from '@utils/followUp';
import { useSetting } from '@providers/SettingProvider';
import { ModalAssignee } from '@components/ModalAssignee';
const { Text } = Typography;

const TicketConsole: React.FC = () => {
  const { member } = useMember();
  const { current, onUpdateTicketSummary } = useTicketConsole();
  const { currentTicket, user } = current.context;
  const alert = useAlert();
  const t = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const { setting, permission } = useSetting();
  const isTicketOpen = currentTicket?.status === 'open';

  const [followUp, _followUpMutation, onFollowUp] = useFollowUp({
    ticket: currentTicket,
    member,
    updateFollowUpFn: updateFollowUp,
    initCondition: (ticket, member, setter) => {
      const isFollowUp = ticket?.followUps?.some((item: string) => item === member._id);
      if (isFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });
  const [teamFollowUp, _teamFollowUpMutation, onTeamFollowUp] = useFollowUp({
    ticket: currentTicket,
    member,
    updateFollowUpFn: updateTeamFollowUp,
    initCondition: (ticket, _member, setter) => {
      if (ticket.isTeamFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });

  const ticketTag = useMemo(() => {
    if (currentTicket && currentTicket.tags) {
      return generateSelectedTag(currentTicket.tags);
    }
    return [];
  }, [currentTicket.tags]);

  const { containerRef, tagRefs, displaySize } = useCalculateWidth(ticketTag, 100000, 0);
  const canNotDoTeamFollowUp = useMemo(() => {
    if (isTicketOpen) return true;
    if (permission.canTeamFollowUp) return false;
    return !canDoTeamFollowUp(currentTicket, member);
  }, [member, currentTicket]);

  const _renderModalContent = () => {
    let title = '';
    let descrtipion = '';
    // current ticket has open status
    if (currentTicket.status === ETicketStatus.OPEN) {
      title = t('livechat.ticket.management.ticket.console.assignee.modal.open.status.title');
      descrtipion = t('livechat.ticket.management.ticket.console.assignee.modal.open.status.description');
    } else if (currentTicket.status === ETicketStatus.ASSIGNED) {
      title = t('livechat.ticket.management.ticket.console.assignee.modal.assigned.status.title');
      descrtipion = t('livechat.ticket.management.ticket.console.assignee.modal.assigned.status.description');
    }
    return (
      <>
        <div>
          <Text>{title}</Text>
        </div>
        <div>
          <Text>{descrtipion}</Text>
        </div>
      </>
    );
  };

  const popAlert = (currentTicket: ITicket, agentName: string) => {
    alert.success(
      <AlertWrap>
        <span>
          {t('livechat.ticket.management.ticket.console.assgin.success', {
            ticketNumber: currentTicket.ticketNumber.toString(),
            agentName,
          })}
        </span>
        <Link to={`/recents/${currentTicket.userId}`}>
          {t('livechat.ticket.management.ticket.console.go.to.livechat')}
        </Link>
      </AlertWrap>,
      {
        timeout: 5000,
      },
    );
    history.push(`/ticket-management/all-tickets`);
  };

  const onBack = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <Container>
      <TopSection>
        <BackBtnWrap>
          <Link to="/ticket-management/all-tickets" onClick={onBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <Font14G3W700>{t('livechat.ticket.management.ticket.console.back.btn')}</Font14G3W700>
          </Link>
        </BackBtnWrap>
        {/* assignee dropdown */}
        {!isEmpty(currentTicket) && (
          <AssigneeWrap>
            <ModalAssignee ticket={currentTicket} onAssignSuccesss={popAlert} />
          </AssigneeWrap>
        )}
        {/* ticket status */}
        {currentTicket.status && (
          <Space size={'small'} direction={'vertical'}>
            <Font14G1W700>Status:</Font14G1W700>
            <StatusTag type={currentTicket.status as IStatusTag['type']} />
          </Space>
        )}
        {/* ticket tags */}
        {!isEmpty(currentTicket.tags) && (
          <TagContent ref={containerRef}>
            <Space size={'small'} direction={'vertical'}>
              <Font14G1W700>Tag:</Font14G1W700>
              <TicketTagList tagRefs={tagRefs} tags={ticketTag} displaySize={displaySize} showHiddenMenu />
            </Space>
          </TagContent>
        )}
        {/* resolve */}
        {currentTicket && currentTicket.status === ETicketStatus.ASSIGNED && user && member ? (
          <div className="flex-1 text-right flex justify-end">
            <ResolveTicket
              ticket={currentTicket}
              user={user}
              member={member}
              submitCb={() => {
                window.location.reload();
              }}
              onUpdateTicketField={function (payload: IResolveForm): void {
                onUpdateTicketSummary(payload);
              }}
              isWrapUpTicketRequired={setting.isWrapUpTicketRequired}
            />
          </div>
        ) : null}
      </TopSection>
      <BottomSection>
        <div className="flex gap-x-3">
          <IconMove
            isActive={followUp}
            isLoading={false}
            activeIcon={fasStar}
            inactiveIcon={farStar}
            activeColor={'#FFB400'}
            inActiveColor={theme.GREY_5}
            onClick={onFollowUp}
          />
          {isTicketOpen ? (
            <Tooltip title={t('require.assignee.for.flag')} placement="right">
              <div>
                <IconMove
                  isActive={teamFollowUp}
                  isLoading={false}
                  activeIcon={fasFlag}
                  inactiveIcon={farFlag}
                  activeColor={'#f72c40'}
                  inActiveColor={theme.GREY_5}
                  isDisabled={canNotDoTeamFollowUp}
                  onClick={onTeamFollowUp}
                />
              </div>
            </Tooltip>
          ) : (
            <IconMove
              isActive={teamFollowUp}
              isLoading={false}
              activeIcon={fasFlag}
              inactiveIcon={farFlag}
              activeColor={'#f72c40'}
              inActiveColor={theme.GREY_5}
              isDisabled={canNotDoTeamFollowUp}
              onClick={onTeamFollowUp}
            />
          )}
        </div>

        <TextWrap>
          <Font12G4W500>{t('livechat.ticket.management.ticket.console.ticket.number.label')}</Font12G4W500>
          {currentTicket?.ticketNumber && <Font12G4W500>{`#${currentTicket?.ticketNumber}`}</Font12G4W500>}
        </TextWrap>
        <TextWrap>
          <div>
            <Font12G4W500>{t('livechat.ticket.management.ticket.console.ticket.start.label')}</Font12G4W500>
            {currentTicket?.createdAt && (
              <Font12G4W500>{dayjs(currentTicket?.createdAt).format('DD MMM YYYY, HH:mm')}</Font12G4W500>
            )}
          </div>{' '}
        </TextWrap>
        <Seperator />
        <TextWrap>
          <div>
            <Font12G4W500>{t('livechat.ticket.management.ticket.console.first.response.label')}</Font12G4W500>
            <FirstResponse ticket={currentTicket} />
          </div>
        </TextWrap>
      </BottomSection>
    </Container>
  );
};

export default TicketConsole;
