import { ONLY_NUMBER_AND_WORD } from '@configs/regex';

export const restrictLetter = (e: React.KeyboardEvent, reg: string | RegExp, allowSpace?: boolean) => {
  if (!allowSpace && e.code === 'Space') {
    e.preventDefault();
    return false;
  } else if (allowSpace && e.code === 'Space') {
    return;
  }
  const specialCharRegex = new RegExp(reg);
  const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (!specialCharRegex.test(pressedKey)) {
    e.preventDefault();
    return false;
  }
};
export const removeSpecialWhenPasted = (str: string, reg: RegExp = ONLY_NUMBER_AND_WORD): string => {
  const result = str.replace(reg, '');
  return result;
};

export const removeExceedFromText = (str: string, limit: number): string => {
  return str.length > limit ? str.slice(0, limit) : str;
};
