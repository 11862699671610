import { IMessageBoxMachineContext } from '../messageBoxMachine';
import { fetchOldAndNewMessages } from './fetchOldAndNewMessages';
import { fetchLatestUserMessage } from './fetchLatestUserMessage';
import { filterMessageByStatus } from './filterFailedMessages';
import { EMessageStatus } from '@enums/MessageStatus';

export const firstfetchMessage = async (context: IMessageBoxMachineContext) => {
  try {
    // store failed message from context
    const failedMessages = filterMessageByStatus(context.chatHistory.messages, EMessageStatus.FAILED);
    const { userId, messageTime } = context;
    if (userId && !messageTime) {
      context.chatHistory = await fetchLatestUserMessage(userId);
      context.scrollBottom = true;
      // append failed message to chat history
      context.chatHistory.messages = [...context.chatHistory.messages, ...failedMessages];
      return context;
    }
    if (userId && messageTime) {
      context.chatHistory = await fetchOldAndNewMessages(userId, messageTime);
      context.scrollBottom = false;
    }
    context.chatHistory = await fetchLatestUserMessage(userId);
    context.scrollBottom = true;
    // append failed message to chat history
    context.chatHistory.messages = [...context.chatHistory.messages, ...failedMessages];
    return context;
  } catch (error) {
    throw error;
  }
};
