import { useInterpret, useActor } from '@xstate/react';
import ticketManager from './machines';
import { IMember, ITeam } from '@types';
import { IResolveForm } from '@pages/mobile/EngagementMessage/ResolveDrawer/useResolveDrawer';
import { useMember } from '@providers/MemberProvider';
import { canResolveTicket } from '@utils/canResolveTicket';
import { useCallback } from 'react';
import { useSetting } from '@providers/SettingProvider';
import { useChatPermission } from '@hooks/useChatPermission';

const useTicketManager = (userId: string) => {
  const { member } = useMember();
  const machine = useCallback(() => ticketManager(userId), [userId]);
  const ticketManagerService = useInterpret(machine, {
    devTools: import.meta.env.DEV,
  });
  const { setting } = useSetting();

  const [current] = useActor(ticketManagerService);
  const { send } = ticketManagerService;
  const { ticket } = current.context;

  const isOnResultStage = current.hasTag('isOnResultPageTag');
  const isOnResolveStage = current.hasTag('isOnResolvePageTag');
  const isSavingDraftWrapup = current.matches('idle.resolveTicket.saveDraftWrapup.savingDraft');
  const isResolvingTicket = current.matches('idle.resolveTicket.resolveTicket.resolvingTicket');
  const isResolvedTicketSuccess = current.hasTag('resolveSuccess');
  const isResolvedTicketFailed = current.hasTag('resolveFailed');
  const isTicketResolved = ticket?.status === 'resolved';
  const hasTicketResolveResult = isResolvedTicketSuccess || isResolvedTicketFailed;
  const { canChat } = useChatPermission(ticket!);

  const handleFollow = () => {
    send('FOLLOW');
  };

  const handleUnfollow = () => {
    send('UN_FOLLOW');
  };

  const handleSelectAssignee = () => {
    send('SELECT_ASSIGNEE');
  };
  const handleResolveTicket = () => {
    send('CONFIRM_RESOLVING_TICKET');
  };

  const isOwnTicket = () => {
    return ticket?.agentId === member._id;
  };

  const handleCloseAssigneeModal = () => {
    send('STANDBY');
  };

  const handleOpenResolveDrawer = (open: boolean) => {
    if (open) {
      send('RESOLVE_TICKET');
    } else {
      send('STANDBY');
    }
  };

  const handleTabChange = (tabName: string) => {
    if (tabName === 'member') {
      send('SELECT_MEMBER_TAB');
    } else {
      send('SELECT_TEAM_TAB');
    }
  };

  const handleSelectMember = (member: IMember) => {
    send('ASK_TO_CONFIRM_SELECT', { value: member });
  };

  const handleSelectTeam = (team: ITeam) => {
    send('ASK_TO_CONFIRM_SELECT', { value: team });
  };

  const handleCancelConfirm = () => {
    send('CANCEL_CONFIRM');
  };

  const handleConfirmAssignee = () => {
    send('CONFIRM_SELECT_ASSIGNEE', { historyValue: current.historyValue?.current });
  };

  const handleSaveDraftWrapup = (payload: IResolveForm) => {
    send('SAVE_DRAFT_WRAPUP', payload);
  };

  const handleInstanceResolve = () => {
    if (!setting.isWrapUpTicketRequired) {
      send('INSTANCE_RESOLVE_TICKET', {
        canInstanceResolve: true,
      });
    }
  };

  const checkDisableTicket = () => {
    if (ticket && member) {
      return canResolveTicket(ticket, member);
    }
    return false;
  };

  return {
    current,
    isOnResultStage,
    isOnResolveStage,
    isResolvingTicket,
    isResolvedTicketSuccess,
    isResolvedTicketFailed,
    isTicketResolved,
    hasTicketResolveResult,
    isSavingDraftWrapup,
    canResolveTicket: checkDisableTicket(),
    handleInstanceResolve,
    canChat,
    isOwnTicket,
    send,
    handleFollow,
    handleUnfollow,
    handleSelectAssignee,
    handleResolveTicket,
    handleCloseAssigneeModal,
    handleTabChange,
    handleSelectTeam,
    handleSelectMember,
    handleCancelConfirm,
    handleConfirmAssignee,
    handleSaveDraftWrapup,
    handleOpenResolveDrawer,
  };
};

export default useTicketManager;
