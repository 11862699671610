import { useComposerManager } from '@providers/ComposerManagerProvider';
import { useMessageBox } from '@providers/MessageBoxProvider';
import { IFileHandler, IMessageBoxHandler } from '@types';

export const useComposer = () => {
  const { preview, composer } = useComposerManager();
  const { onSelectTemplate } = preview;
  const { onToggleComposer } = composer;
  const {
    current: messageCurrent,
    messageContext,
    disabledSendButton,
    beforeUpload,
    handleCancelUpload,
    handleUploadFile,
    handleTextInputChange,
    handleSendMessage,
    handleSendTemplate,
  } = useMessageBox();
  const fileHandler: IFileHandler = {
    isFileProcessing: messageCurrent.hasTag('file-processing'),
    isFileUploading:
      messageCurrent.matches({ chatHistoryState: 'uploadingFiles' }) ||
      messageCurrent.matches({ chatHistoryState: 'uploadFilesSuccess' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendingFilesMessage' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendFilesMessageWithTime' }),

    isFileUploadFailed:
      messageCurrent.matches({ chatHistoryState: 'uploadFilesFailed' }) ||
      messageCurrent.matches({ chatHistoryState: 'sendFilesMessageFailed' }),
    selectedFiles: messageContext.selectedFiles,
    rejectedFiles: messageContext.rejectFiles,
    errorMessage: messageContext.fileErrorMessage,
    onConfirmUpload: handleUploadFile,
    onCancelUpload: handleCancelUpload,
    beforeUpload,
  };
  const messageBoxHandler: IMessageBoxHandler = {
    textMessage: messageContext.textMessage,
    disabledSendButton: disabledSendButton,
    handleTextInputChange,
    handleSendMessage,
    handleSendTemplate: (template) => {
      handleSendTemplate(template);
      // close composer
      onToggleComposer();
      // close preview
      onSelectTemplate(null);
    },
  };
  return {
    fileHandler,
    messageBoxHandler,
  };
};

export default useComposer;
