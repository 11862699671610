import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 240px;
  * {
    line-height: 1.5715 !important;
  }
`;

export const CarouselItem = styled.div`
  border-radius: 12px;
  overflow: hidden;
  width: 220px !important;
`;

export const ImageContainer = styled.div`
  img {
    height: 165px;
    width: 100%;
    object-fit: cover;
  }
`;

export const ContentContainer = styled.div`
  background: ${({ theme }) => theme.GREY_8};
  padding: 12px;
`;

export const ContentHeader = styled.div`
  margin-bottom: 12px;
  span {
    font-weight: 600;
    font-size: 12px;
    color: ${({ theme }) => theme.GREY_1};
  }
  div {
    font-size: 12px;
    color: ${({ theme }) => theme.GREY_4};
  }
`;

export const ContentBody = styled.div`
  display: grid;
  gap: 8px;
`;

export const ButtonPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: ${({ theme }) => theme.WHITE};
  border-radius: 8px;
  text-align: center;
  span {
    color: ${({ theme }) => theme.GREY_1};
  }
`;

export const ArrowRight = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')} !important;
  width: 28px !important;
  height: 28px !important;
  background: ${({ theme }) => theme.GREY_5} !important;
  right: 7px !important;
  z-index: 99;
  border-radius: 50%;
  font-size: 12px !important;
  text-align: center;
  svg {
    margin-left: 2px !important;
    color: white;
    vertical-align: -1px;
  }
  &:before {
    content: '' !important;
  }
`;

export const ArrowLeft = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')} !important;
  width: 28px !important;
  height: 28px !important;
  background: ${({ theme }) => theme.GREY_5} !important;
  left: -12px !important;
  z-index: 99;
  border-radius: 50%;
  font-size: 12px !important;
  text-align: center;
  svg {
    margin-right: 2px !important;
    color: white;
    vertical-align: -1px;
  }
  &:before {
    content: '' !important;
  }
`;
