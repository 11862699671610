import React, { useCallback, useRef } from 'react';
import { FrameV2, TextCentered, Font10G5W600, Font14G1W500, Spacing, Font14G5W400 } from '@components/UtilsComponent';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import {
  Container,
  DateOfMonthWrap,
  DateText,
  DateTextWrap,
  DayOfWeekWrap,
  SelectedCircle,
  InRangeDate,
  SelectTime,
  TimeItem,
  SelectedTimeInner,
  DisabledTimeItem,
} from './style';
import { isEmpty } from 'lodash';
import { BasicDropdown } from '@components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { EDateType, IDateArray } from '../useDateRangePicker';

dayjs.extend(isToday);
dayjs.extend(isBetween);
export type ICalendarProps = {
  showTime?: boolean;
  timepicker?: {
    label: string;
  };
  hoverDate: dayjs.Dayjs | null;
  dateArr: IDateArray[];
  isStartDate?: boolean;
  startDate: dayjs.Dayjs | null;
  endDate?: dayjs.Dayjs | null;
  selectedTime?: dayjs.Dayjs | null;
  startTime?: dayjs.Dayjs | null;
  endTime?: dayjs.Dayjs | null;
  disablePrevDate?: dayjs.Dayjs;
  isDisabledFutureDate?: boolean;
  onSelectDate: (date: dayjs.Dayjs) => void;
  onHoverDate: (date: dayjs.Dayjs | null) => void;
  onSelectTime: (date: dayjs.Dayjs) => void;
};

const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export const Calendar = React.memo(
  ({
    showTime,
    timepicker,
    hoverDate,
    startDate,
    endDate,
    selectedTime,
    startTime,
    endTime,
    isStartDate,
    dateArr,
    isDisabledFutureDate,
    disablePrevDate,
    onSelectDate,
    onHoverDate,
    onSelectTime,
  }: ICalendarProps) => {
    const ref = useRef<HTMLDivElement>(null);

    const _renderInRangeCondition = (date: dayjs.Dayjs, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
      // start date is saturday
      if (startDate.day() === 6 && date.isSame(startDate, 'day') && startDate.isSame(endDate, 'day')) {
        return null;
      }
      // end date is same day with start date
      if (endDate && startDate && endDate.isSame(startDate, 'day')) {
        return null;
      }
      // end date less than startDate
      if (endDate.isBefore(startDate)) {
        return null;
      }
      // in range and it is saturaday
      if (date.day() === 6 && date.isBetween(startDate, endDate, 'day')) {
        return (
          <InRangeDate
            style={{
              left: '0',
              width: 34,
              borderRadius: '0 50% 50% 0',
            }}
          />
        );
      }
      // end date is saturaday
      if (endDate && endDate.day() === 0 && date.isSame(endDate, 'day')) {
        return (
          <InRangeDate
            style={{
              left: '0',
              borderRadius: '0 50% 50% 0',
            }}
          />
        );
      }
      // date is start date and it saturaday
      if (date.isSame(startDate, 'day') && date.day() === 6) {
        return (
          <InRangeDate
            style={{
              left: '30%',
              width: 24,
            }}
          />
        );
      }
      // date is start date and it end of month
      if (date.isSame(startDate, 'day') && date.date() === date.daysInMonth()) {
        return (
          <InRangeDate
            style={{
              left: '30%',
              width: 24,
            }}
          />
        );
      }
      // date is start date
      if (date.isSame(startDate, 'day')) {
        return (
          <InRangeDate
            style={{
              left: '30%',
            }}
          />
        );
      }
      // date is end date and it first day of month
      if (date.isSame(endDate, 'day') && date.date() === 1) {
        return (
          <InRangeDate
            style={{
              left: '-2px',
              width: 24,
            }}
          />
        );
      }

      // date is end date
      if (date.isSame(endDate, 'day')) {
        return (
          <InRangeDate
            style={{
              left: '-30%',
            }}
          />
        );
      }
      // date is between start and end date but it end of month
      if (date.isBetween(startDate, endDate, 'day') && date.date() === date.daysInMonth()) {
        return (
          <InRangeDate
            style={{
              width: 32,
            }}
          />
        );
      }
      // date is between start and end date
      if (date.isBetween(startDate, endDate, 'day')) {
        return (
          <InRangeDate
            style={{
              width: 40,
            }}
          />
        );
      }
    };

    const _renderInRangeDate = (date: dayjs.Dayjs) => {
      // not show if date is past date
      if (disablePast(date)) return;
      // not show if date is future date
      if (disableFuture(date)) return;
      // no select start date and date between hoverDate and endDate
      if (!startDate && endDate && hoverDate && date.isBetween(hoverDate, endDate, 'day', '[]')) {
        // in range and it is saturaday
        return _renderInRangeCondition(date, hoverDate, endDate);
      }
      // no select end date and date between startDate and hoverDate
      if (!endDate && startDate && hoverDate && date.isBetween(startDate, hoverDate, 'day', '[]')) {
        // in range and it is saturaday
        return _renderInRangeCondition(date, startDate, hoverDate);
      }
      if (!startDate || !endDate) return;

      return _renderInRangeCondition(date, startDate, endDate);
    };

    const generateTimeListFromDate = (date: dayjs.Dayjs) => {
      // Create a Day.js object representing the starting time (00:00)

      const startTime = date.set('hour', 0).set('minute', 0).set('second', 0);

      // Create a Day.js object representing the ending time (23:30)
      const endTime = date.set('hour', 23).set('minute', 30).set('second', 0);
      // Initialize an array to store the generated times
      const generatedTimes = [];

      // Loop through the times with 30-minute intervals
      let currentTime = startTime.clone();
      while (currentTime.isBefore(endTime) || currentTime.isSame(endTime)) {
        generatedTimes.push({
          label: currentTime.format('HH:mm'),
          value: currentTime,
        });
        currentTime = currentTime.add(30, 'minutes');
      }

      return generatedTimes;
    };

    const getDate = () => {
      if (isStartDate) {
        if (startDate) return startDate;
        return dayjs();
      } else {
        if (endDate) return endDate;
        return dayjs();
      }
    };

    const _renderTimePicker = useCallback(
      (selectedDate?: dayjs.Dayjs | null) => {
        if (!timepicker || isEmpty(timepicker)) return null;
        const { label } = timepicker;
        const date = getDate();
        const timeList = generateTimeListFromDate(date);

        return (
          <>
            {/* time picker */}
            <Spacing height={24} />
            <div>
              <div>
                <Font14G5W400>{label}</Font14G5W400>
              </div>
              <BasicDropdown
                trigger={['click']}
                disabled={!selectedDate}
                overlayContent={timeList.map((timeItem) => {
                  // start time must be before end time or equal
                  if (isStartDate && (timeItem.value.isAfter(endTime, 'm') || timeItem.value.isSame(endTime, 'm'))) {
                    return <DisabledTimeItem key={timeItem.label}>{timeItem.label}</DisabledTimeItem>;
                  }
                  // end time must be after start time or equal
                  if (
                    !isStartDate &&
                    (timeItem.value.isBefore(startTime, 'm') || timeItem.value.isSame(startTime, 'm'))
                  ) {
                    return <DisabledTimeItem key={timeItem.label}>{timeItem.label}</DisabledTimeItem>;
                  }
                  // if today and time is more than current time
                  if (selectedDate?.isToday() && timeItem.value.isAfter(dayjs(), 'm')) {
                    return <DisabledTimeItem key={timeItem.label}>{timeItem.label}</DisabledTimeItem>;
                  }

                  return (
                    <TimeItem
                      key={timeItem.label}
                      // highlight selected time
                      isSelected={!!selectedTime?.isSame(timeItem.value, 'minute')}
                      onClick={() => onSelectTime(timeItem.value)}
                    >
                      {timeItem.label}
                    </TimeItem>
                  );
                })}
              >
                <SelectTime>
                  <SelectedTimeInner>
                    <FrameV2 width={20} height={20} centered>
                      <FontAwesomeIcon icon={faClock} fontSize={16} />
                    </FrameV2>
                    {selectedTime?.format('HH:mm')}
                  </SelectedTimeInner>

                  <FrameV2 width={20} height={20} centered>
                    <FontAwesomeIcon icon={faChevronDown} fontSize={16} />
                  </FrameV2>
                </SelectTime>
              </BasicDropdown>
            </div>
          </>
        );
      },
      [startDate, endDate, isStartDate, timepicker, selectedTime],
    );

    const onSelectDateHandler = (date: dayjs.Dayjs) => {
      // if date is past date
      if (disablePast(date)) return;
      // if disable future date
      if (disableFuture(date)) return;

      return onSelectDate(date);
    };

    const disableFuture = (date: dayjs.Dayjs): boolean => {
      // no set disable future date
      if (!isDisabledFutureDate) return false;
      // enable disable future date but date is before today
      if (isDisabledFutureDate && !date.isAfter(dayjs(), 'day')) return false;

      return true;
    };

    const disablePast = (date: dayjs.Dayjs): boolean => {
      if (!disablePrevDate) return false;
      if (disablePrevDate && !date.isBefore(disablePrevDate, 'day')) return false;

      return true;
    };

    const isShowHighlight = (date: dayjs.Dayjs) => {
      const isStartDateHighlighted = startDate?.isSame(date) || false; // start date is same with highlight date
      const isEndDateHighlighted = endDate?.isSame(date) || false; // end date is same with highlight date
      const isNonEndAndHoverDateHighlighted = !endDate && (hoverDate?.isSame(date) || false); // dont have end date and hover date is same with date
      const isNonStarAndHoverDateHighlighted = !startDate && (hoverDate?.isSame(date) || false); // dont have start date and hover date is same with date

      return (
        isStartDateHighlighted ||
        isEndDateHighlighted ||
        isNonEndAndHoverDateHighlighted ||
        isNonStarAndHoverDateHighlighted
      );
    };

    return (
      <Container isStartDate={!!isStartDate} ref={ref}>
        <div>
          {/* generate days */}
          <DayOfWeekWrap>
            {DAYS.map((day) => {
              return (
                <FrameV2 key={day} width={32} height={22}>
                  <TextCentered>
                    <Font10G5W600>{day}</Font10G5W600>
                  </TextCentered>
                </FrameV2>
              );
            })}
          </DayOfWeekWrap>
          <DateOfMonthWrap>
            {dateArr.map(({ type, date }) => {
              const dateFormatted = date.format('DDMMYYYY');
              if (type === EDateType.DATE_OF_PREV_MONTH) {
                return (
                  <DateText
                    data-testid={dateFormatted}
                    key={dateFormatted}
                    style={{
                      padding: date.day() === 6 ? '16px 0 0 0' : '16px 8px 0 0',
                    }}
                  >
                    <FrameV2 width={32} height={22}></FrameV2>
                  </DateText>
                );
              }
              if (type === EDateType.DATE_OF_NEXT_MONTH) {
                return (
                  <DateText data-testid={dateFormatted} key={dateFormatted}>
                    <FrameV2 width={0} height={0}></FrameV2>
                  </DateText>
                );
              }
              return (
                <DateText
                  key={dateFormatted}
                  data-testid={dateFormatted}
                  style={{
                    padding: date.day() === 6 ? '16px 0 0 0' : '16px 8px 0 0',
                  }}
                  onClick={() => onSelectDateHandler(date)}
                  onMouseEnter={() => !disableFuture(date) && !disablePast(date) && onHoverDate(date)}
                  onMouseLeave={() => onHoverDate(null)}
                  isSelected={isShowHighlight(date)}
                >
                  {/* this will display primary green background */}
                  {/* dont allow to select if date is future date */}
                  {!disableFuture(date) && !disablePast(date) && (
                    <SelectedCircle isToday={date.isToday()} isSelected={isShowHighlight(date)} />
                  )}

                  {/* this will display light green backgroud */}
                  {_renderInRangeDate(date)}
                  {/* this will display date text */}
                  <DateTextWrap isDisabled={disableFuture(date) || disablePast(date)}>
                    <FrameV2 width={32} height={22}>
                      <TextCentered>
                        <Font14G1W500>{date.date()}</Font14G1W500>
                      </TextCentered>
                    </FrameV2>
                  </DateTextWrap>
                </DateText>
              );
            })}
          </DateOfMonthWrap>
        </div>

        {showTime ? _renderTimePicker(isStartDate ? startDate : endDate) : null}
      </Container>
    );
  },
);

Calendar.displayName = 'Calendar';
