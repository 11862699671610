import React, { useEffect } from 'react';
import { Button, GhostButton } from '@components/Button';
import TextArea from '@components/TextArea';
import TextInput from '@components/TextInput';
import { Counter, Font14G1W500, Font14G3W600, Font12G5W400 } from '@components/UtilsComponent';
import { MAX_OPTION_LABEL_LENGTH, MAX_OPTION_LENGTH, MAX_QUESTION_LENGTH, QUICK_REPLY_POINT } from '@configs/constants';
import { faEllipsisV, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '@hooks/useTranslation';
import { ISurveyQuickReplyAction } from '@types';
import Select, { IOption } from '@components/Select';
import { v4 as uuid } from 'uuid';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { twMerge } from 'tailwind-merge';
import { IUpdateSurveyFnSignature } from '../../CustomerSatisfactionEditor/useCustomerSatisfactionEditor';
import { CustomFormItem } from '@components/CustomFormItem';
import { removeExceedFromText } from '@utils/letters';

type IQuickReplySurveyProps = {
  question: string;
  options: ISurveyQuickReplyAction[];
  onUpdateSurvey: IUpdateSurveyFnSignature;
};

const reorder = (list: ISurveyQuickReplyAction[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const QuickReplySurvey = ({ question, options, onUpdateSurvey }: IQuickReplySurveyProps) => {
  const t = useTranslation();
  const [points, setPoints] = React.useState<IOption[]>(QUICK_REPLY_POINT);
  const dropClass = twMerge('flex flex-col gap-y-[16px]');
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedItems = reorder(options, result.source.index, result.destination.index);

    onUpdateSurvey('message.options', reorderedItems);
  };

  useEffect(() => {
    // disable point if it's already used
    const usedPoints = options.map((o) => o.point);
    const newPoints = QUICK_REPLY_POINT.map((point) => {
      return usedPoints.includes(point.value) ? { ...point, disabled: true } : { ...point, disabled: false };
    });
    setPoints(newPoints);
  }, [options]);

  return (
    <div className="w-full">
      <div className="w-full rounded-[16px] shadow-custom p-[24px] bg-white transition-all">
        <div className="flex flex-col gap-y-[4px]">
          <Font14G1W500>{t('customer.satisfaction.editor.quick.reply.qustion.label')}</Font14G1W500>
          <CustomFormItem
            id="quick-reply-question"
            isError={!question}
            errorMessage={t('customer.satisfaction.editor.quick.reply.question.validate')}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const result = removeExceedFromText(e.target.value, MAX_QUESTION_LENGTH);
              onUpdateSurvey('message.question', result);
            }}
            value={question}
            counter={
              <Counter>
                <Font12G5W400>{`${question.length} / ${MAX_QUESTION_LENGTH}`}</Font12G5W400>
              </Counter>
            }
          >
            <TextArea
              autoSize={{
                minRows: 4,
                maxRows: 8,
              }}
              maxLength={MAX_QUESTION_LENGTH}
            />
          </CustomFormItem>
        </div>
        <div className="mt-[24px]">
          <div className="flex-between-center">
            <div className="flex gap-x-[8px] items-center">
              <Font14G1W500>{t('customer.satisfaction.editor.quick.reply.options.label')}</Font14G1W500>
              <Counter>
                <Font12G5W400>{`${options.length} / ${MAX_OPTION_LENGTH}`}</Font12G5W400>
              </Counter>
            </div>
            <GhostButton
              onClick={() => {
                if (options.length >= 10 || options.length < 2) {
                  return;
                }
                // filter non selected points
                const selectedPoints = new Set(options.map((o) => o.point));
                const nonSelectedPoints = points.filter((point) => !selectedPoints.has(point.value));
                onUpdateSurvey('message.options', [
                  ...options,
                  {
                    id: uuid(),
                    label: '',
                    point: nonSelectedPoints.length > 0 ? nonSelectedPoints[0].value : '0',
                  },
                ]);
              }}
            >
              <div className="flex items-center gap-x-[8px]">
                <FontAwesomeIcon icon={faPlus} className="text-[16px] text-[#757575]" />
                <Font14G3W600>{t('customer.satisfaction.editor.quick.reply.add.option.btn')}</Font14G3W600>
              </div>
            </GhostButton>
          </div>
        </div>
        <div className="divider-y-16" />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className={dropClass}
                style={{
                  minHeight: options.length * 68.14 + 16 * (options.length - 1),
                }}
                {...provided.droppableProps}
                ref={(ref) => {
                  provided.innerRef(ref);
                }}
              >
                {options?.map((option, index) => {
                  return (
                    <Draggable key={option.id} draggableId={option.id!} index={index}>
                      {(provided) => (
                        <div className="flex gap-x-[8px]" ref={provided.innerRef} {...provided.draggableProps}>
                          <div
                            {...provided.dragHandleProps}
                            className="h-[48px] flex items-center w-[28px] justify-center"
                          >
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </div>
                          <div className="flex flex-col gap-y-[4px] w-[343px]">
                            <CustomFormItem
                              id={`quick-reply-option-${option.id}`}
                              isError={!option.label}
                              errorMessage={t('customer.satisfaction.editor.quick.reply.option.validate')}
                              value={option.label}
                              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                onUpdateSurvey(
                                  'message.options',
                                  options.map((o) => (o.id === option.id ? { ...o, label: e.target.value } : o)),
                                );
                              }}
                              counter={
                                <Counter>
                                  <Font12G5W400>{`${
                                    option.label.length ? option.label.length : 0
                                  } / ${MAX_OPTION_LABEL_LENGTH}`}</Font12G5W400>
                                </Counter>
                              }
                            >
                              <TextInput
                                size="large"
                                placeholder={t(
                                  'customer.satisfaction.editor.quick.reply.option.item.label.placeholder',
                                )}
                                maxLength={MAX_OPTION_LABEL_LENGTH}
                              />
                            </CustomFormItem>
                          </div>
                          <div className="flex-1">
                            <Select
                              value={option.point}
                              placeholder={t('customer.satisfaction.editor.quick.reply.option.item.point.placeholder')}
                              options={points}
                              size="large"
                              onChange={(value: string) => {
                                onUpdateSurvey(
                                  'message.options',
                                  options.map((o) => (o.id === option.id ? { ...o, point: value } : o)),
                                );
                              }}
                            />
                          </div>
                          <div className="w-[48px] h-[48px]">
                            <Button
                              className="flex items-center justify-center w-[48px] min-h-[48px]"
                              disabled={options.length <= 2}
                              onClick={() => {
                                if (options.length <= 2) {
                                  return;
                                }
                                // remove option
                                onUpdateSurvey(
                                  'message.options',
                                  options.filter((o) => o.id !== option.id),
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} className="text-[18px]" />
                            </Button>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
