import styled from 'styled-components/macro';

export const ButtonPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: #fbfbfb;
  border-radius: 8px;
  text-align: center;
  span {
    color: ${({ theme }) => theme.GREY_1};
  }
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.GREY_8};
  border-radius: 12px;
  padding: 12px;
  min-width: 220px;
  * {
    line-height: 1.5715 !important;
  }
  & > div:nth-child(1) {
    margin-bottom: 12px;
  }

  ${ButtonPreview} {
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;
