import React from 'react';

import { Form } from 'antd';
import { ActionWrap, Container, ForgotPassword, Logo, AppVersion } from './style';

import { Spacing, ToastWrap } from '@components/UtilsComponent';
import { PrimaryButton } from '@components/Button';
import FormItem from '@components/FormItem';
import LoadingIcon from '@components/LoadingIcon';
import PasswordInput from '@components/PasswordInput';
import TextInput from '@components/TextInput';
import { DangerToast } from '@components/Toast';

export type ILoginComponent = {
  errorMessage: string;
  loading: boolean;
  appVersion?: string;
  onForgotPassword: () => void;
  onLogin: (username: string, password: string) => void;
};

export const Login = ({ errorMessage, loading, appVersion, onForgotPassword, onLogin }: ILoginComponent) => {
  return (
    <Container>
      <Logo />

      {errorMessage && (
        <ToastWrap>
          <DangerToast align="flex-start">{errorMessage}</DangerToast>
        </ToastWrap>
      )}
      <Form autoComplete="off" layout="vertical" onFinish={({ username, password }) => onLogin(username, password)}>
        <FormItem
          name="username"
          rules={[
            {
              required: true,
              message: 'Please enter your username!',
            },
          ]}
        >
          <TextInput placeholder="Username" size="large" />
        </FormItem>
        <FormItem
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter your password!',
            },
          ]}
        >
          <PasswordInput placeholder="Password" size="large" autoComplete="new-password" />
        </FormItem>
        <ActionWrap>
          <PrimaryButton size="large" htmlType="submit" disabled={loading} data-testid="test-login-btn">
            {loading && <LoadingIcon icon={['fas', 'sync-alt']} data-testid="test-loading-icon" />}
            Log in
          </PrimaryButton>
          {/* <ForgotPassword>
            <a onClick={onForgotPassword}>Forgot password?</a>
          </ForgotPassword> */}
        </ActionWrap>
      </Form>
      <Spacing height={16} />
      <AppVersion>{appVersion}</AppVersion>
    </Container>
  );
};
