import { animated } from '@react-spring/web';
import styled from 'styled-components/macro';

export const Container = styled(animated.div)`
  border: 1px solid ${({ theme }) => theme.GREY_7};
  border-radius: 12px;
  overflow: hidden;
  height: 45px;
`;

export const Header = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  cursor: pointer;
`;

export const LeftSection = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
`;

export const RightSection = styled.div``;

export const IconButton = styled(animated.div)`
  width: 28px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  padding: 16px 8px;
  background: ${({ theme }) => theme.GREY_10};
`;

export const TicketHistoryDivider = styled(animated.div)`
  height: 1px;
  background: ${({ theme }) => theme.GREY_7};
`;
