import React from 'react';
import { Container, TopSection, Divider, MainSection, SearchContainer, UserListContainer } from './style';
import { useEngagementUser } from './useEngagementUserList';
import UserWithSearch from '../../../components/UserWithSearch';
import TicketFilter from './TicketFilter';
import SearchInput from '@components/SearchInput';
import UserList_ from '@pages/components/UserList_';
import { ChannelFilter } from '@components/ChannelFilter';
import { AdditionalFilter } from './AdditionalFilter';

const MemoEngagementUser = () => {
  const {
    currentFilter,
    userList,
    debouncedSearchTxt,
    searchUserList,
    searchUserMessagesList,
    searchTxt,
    handleFilterChange,
    loadMoreOnScroll,
    handleSearchTxtChange,
    handleLoadMoreUserAndMessage,
    handleSelectUser,
    onSelectChannel,
  } = useEngagementUser();
  const _renderUserList = () => {
    if (debouncedSearchTxt.length > 0) {
      return (
        <UserWithSearch
          userMessageList={searchUserMessagesList}
          userList={searchUserList}
          searchTxt={debouncedSearchTxt}
          onLoadMore={handleLoadMoreUserAndMessage}
        />
      );
    }

    return (
      <UserList_
        userData={userList.items}
        hasMore={currentFilter.hasNextPage}
        handleLoadmore={loadMoreOnScroll}
        handleSelectUser={handleSelectUser}
      />
    );
  };
  return (
    <Container>
      <TopSection>
        <ChannelFilter selectedChannels={currentFilter.channels} onSelectChannel={onSelectChannel} />
        <TicketFilter />
      </TopSection>
      <Divider />
      <MainSection>
        <SearchContainer>
          <AdditionalFilter
            selectedTeams={currentFilter.selectedTeam ?? []}
            filterByUnRepliedChecked={!!currentFilter.filterByUnreplied}
            onSelectedTeamsChange={(value) => {
              handleFilterChange<string[]>(value, 'selectedTeam');
            }}
            onFilterByUnRepliedChange={() =>
              handleFilterChange<boolean>(!currentFilter.filterByUnreplied, 'filterByUnreplied')
            }
            onClearAllSelection={() => {
              handleFilterChange<string[]>([], 'selectedTeam');
              handleFilterChange<boolean>(false, 'filterByUnreplied');
            }}
          />
          <SearchInput
            value={searchTxt}
            onClear={() => handleSearchTxtChange('')}
            onChange={(e) => handleSearchTxtChange(e.target.value)}
          />
        </SearchContainer>
        <UserListContainer>{_renderUserList()}</UserListContainer>
      </MainSection>
    </Container>
  );
};

//this Component is used in Layout/livechat
const EngagementUser = React.memo(MemoEngagementUser);

export default EngagementUser;
