import React from 'react';
import { ErrorBoundary as ErrorBoundaryComp } from 'react-error-boundary';

export type IErrorBoundaryProps = {
  children: React.ReactNode;
};

export const ErrorBoundary = ({ children }: React.PropsWithChildren<IErrorBoundaryProps>) => {
  const logError = (error: Error, info: { componentStack: string }) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('error', error);
      console.log('info', info);
      console.trace();
    }
  };

  return (
    <ErrorBoundaryComp fallbackRender={() => <div></div>} onError={logError}>
      {children}
    </ErrorBoundaryComp>
  );
};
