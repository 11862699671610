export const OVERLINE_10_SEMIBOLD = `
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1.2;
  text-transform: uppercase;
`;

export const CAPTION_12_REGULAR = `
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.3334;
  letter-spacing: -0.00625rem;
`;

export const BODY2_14_REGULAR = `
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4286;
  letter-spacing: -0.0125rem;
`;
export const BODY2_14_MEDIUM = `
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.4286;
  letter-spacing: -0.0125rem;
`;
export const BODY2_14_SEMIBOLD = `
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.4286;
  letter-spacing: -0.0125rem;
`;

export const INPUT_14_REGULAR = `
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.4286;
  letter-spacing: -0.0125rem;
`;
export const INPUT_16_REGULAR = `
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: -0.0125rem;
`;

export const BODY1_16_REGULAR = `
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.0125rem;
`;
export const BODY1_16_MEDIUM = `
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.0125rem;
`;
export const BODY1_16_SEMIBOLD = `
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: -0.0125rem;
`;

export const TITLE_20_SEMIBOLD = `
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: -0.025rem;
`;

export const H3_24_MEDIUM = `
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.025rem;
`;
export const H3_24_BOLD = `
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5;
  letter-spacing: -0.025rem;
`;
export const H2_32_MEDIUM = `
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: -0.025rem;
`;
export const H1_40_MEDIUM = `
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1.3;
  letter-spacing: -0.025rem;
`;
