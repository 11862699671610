export const BLACK = '#000000';
export const GREY_1 = '#212121';
export const GREY_2 = '#424242';
export const GREY_3 = '#616161';
export const GREY_4 = '#757575';
export const GREY_5 = '#9E9E9E';
export const GREY_6 = '#BDBDBD';
export const GREY_7 = '#E0E0E0';
export const GREY_8 = '#EEEEEE';
export const GREY_9 = '#F5F5F5';
export const GREY_10 = '#FAFAFA';
export const WHITE = '#FFFFFF';

export const COMPANY_COLOR = '#04BE8C';

export const BLUE = '#0F86FE';
export const BLUE_LIGHT = '#E7F2FE';
export const GREEN = '#29CB72';
export const GREEN_LIGHT = '#E9F7EB';
export const YELLOW = '#FFB400';
export const YELLOW_LIGHT = '#FFF4D9';
export const RED = '#F72C40';
export const RED_LIGHT = '#FFEAEE';
export const GREY_DARK = '#242731';

export const CYAN = '#05BED2';
export const CYAN_LIGHT = '#E6F8FA';
export const VIOLET = '#5856D6';
export const VIOLET_LIGHT = '#EEEEFA';
export const PINK = '#E61E73';
export const PINK_LIGHT = '#FCE8F1';
export const ORANGE = '#FF742F';
export const ORANGE_LIGHT = '#FFF1EA';
