import styled, { keyframes } from 'styled-components/macro';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div``;

export const ModalContainer = styled(Modal)`
  width: 400px !important;
  .ant-modal-header {
    padding: 24px;
  }
  .ant-modal-content {
    overflow: hidden;
    border-radius: 12px;
  }
  .ant-modal-footer {
    padding: 24px;
  }

  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-body {
    font-size: 16px;
    padding: 24px 24px 0px;
    > * {
      display: block;
      width: 90%;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const LoadingIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  animation: ${rotate} 1.2s linear infinite;
`;
