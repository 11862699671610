import React, { useState } from 'react';
import { Container, Menu, MenuItem, StatusActionContainer, LogoutBox, MemberStatusItem, StatusBox } from './style';
import AbstractBottomModal from '@components/BottomModal/AbstractBottomModal';
import { Icon, Text } from '@components/UtilsComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AGENT_STATUS } from '@components/SideMenu/constants';
import { Spin } from 'antd';
import LoadingCircle from '@components/Loading';
import useTranslation from '@hooks/useTranslation';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { faCheck, faAngleRight, faSignOutAlt } from '@fortawesome/pro-regular-svg-icons';
import { EAgentStatus } from '@enums/AgentStatus';

export type IMemberMenu = {
  open: boolean;
  status: string;
  loading: boolean;
  handleCloseModal: () => void;
  handleLogout: () => void;
  handleChangeMemberStatus: (status: EAgentStatus) => void;
};

const MemberMenu: React.FC<IMemberMenu> = ({
  open,
  status,
  loading,
  handleCloseModal,
  handleLogout,
  handleChangeMemberStatus,
}: IMemberMenu) => {
  const t = useTranslation();
  const [showStatusMenu, setShowStatusMenu] = useState(false);

  const handleToggleStatusMenu = () => {
    setShowStatusMenu(!showStatusMenu);
  };

  const _renderMenu = () => {
    if (showStatusMenu) {
      return (
        <>
          <Menu>
            <MenuItem>
              <div onClick={handleToggleStatusMenu}>
                <Icon icon={faArrowLeft} />
              </div>
              <div
                onClick={() => {
                  handleCloseModal();
                  handleToggleStatusMenu();
                }}
              >
                <span>{t('livechat.mobile.member.menu.done.btn')}</span>
              </div>
            </MenuItem>
            <Spin spinning={loading} indicator={<LoadingCircle />}>
              {AGENT_STATUS.map((item) => (
                <MemberStatusItem
                  key={item.value}
                  circleColor={item.color}
                  onClick={() => {
                    handleChangeMemberStatus(item.value);
                  }}
                >
                  <StatusBox>
                    <div />
                    <div>
                      <span key={item.value}>{item.label}</span>
                    </div>
                  </StatusBox>
                  {status === item.value && (
                    <div>
                      <Icon icon={faCheck} />
                    </div>
                  )}
                </MemberStatusItem>
              ))}
            </Spin>
          </Menu>
        </>
      );
    }
    return (
      <>
        <Menu>
          <MenuItem onClick={handleToggleStatusMenu}>
            <div>
              <Text>{t('livechat.mobile.member.menu.status.label')}</Text>
            </div>
            <StatusActionContainer>
              <span>{AGENT_STATUS.find((agentStatus) => agentStatus.value === status)?.label ?? ''}</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </StatusActionContainer>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutBox>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>{t('livechat.mobile.member.menu.logout.btn')}</span>
            </LogoutBox>
          </MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <Container>
      <AbstractBottomModal
        snapPoints={({ minHeight }: { minHeight: number; maxHeight: number }) => {
          return [minHeight, showStatusMenu ? 216 : 132];
        }}
        open={open}
        onDismiss={() => {
          handleCloseModal();
          if (showStatusMenu) {
            handleToggleStatusMenu();
          }
        }}
      >
        {_renderMenu()}
      </AbstractBottomModal>
    </Container>
  );
};

export default MemberMenu;
