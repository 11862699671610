import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 240px;
  * {
    line-height: 1.5715 !important;
  }
`;

export const CarouselItem = styled.div`
  border-radius: 12px;
  overflow: hidden;
  /* width: 220px !important; */
  position: relative;
  button {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 145px;
    max-width: 145px;
    height: 36px;
    position: absolute;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 8px 16px 8px 12px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    border: none;
    color: white;
    cursor: auto;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
    }
  }
`;

export const ImageContainer = styled.div`
  img {
    height: 250px;
    width: 250px;
    object-fit: cover;
  }
`;

export const ArrowRight = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')} !important;
  width: 28px !important;
  height: 28px !important;
  background: ${({ theme }) => theme.GREY_5} !important;
  right: -12px !important;
  z-index: 99;
  border-radius: 50%;
  font-size: 12px !important;
  text-align: center;
  svg {
    margin-left: 2px !important;
    color: white;
    vertical-align: -1px;
  }
  &:before {
    content: '' !important;
  }
`;

export const ArrowLeft = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')} !important;
  width: 28px !important;
  height: 28px !important;
  background: ${({ theme }) => theme.GREY_5} !important;
  left: -12px !important;
  z-index: 99;
  border-radius: 50%;
  font-size: 12px !important;
  text-align: center;
  svg {
    margin-right: 2px !important;
    color: white;
    vertical-align: -1px;
  }
  &:before {
    content: '' !important;
  }
`;
