import { updateFollowUp } from '@api/livechat/EngagementMessage';
import { ITicketMachineContext } from '.';

export const followUpState = {
  on: {
    UN_FOLLOW: '.unFollowing',
  },
  initial: 'following',
  states: {
    following: {
      tags: 'calling-api-follow',
      invoke: {
        id: 'follow',
        src: async (context: ITicketMachineContext) => {
          const { ticket } = context;
          if (ticket) {
            return await updateFollowUp(ticket._id, { flag: 'follow' });
          }
          return false;
        },
        onDone: {
          target: 'followedSuccess',
          actions: 'updateTicketAfterFollow',
        },
        onError: 'followedFailed',
      },
    },
    followedSuccess: {},
    followedFailed: {},
    unFollowing: {
      tags: 'calling-api-follow',
      invoke: {
        id: 'unfollow',
        src: async (context: ITicketMachineContext) => {
          const { ticket } = context;
          if (ticket) {
            return await updateFollowUp(ticket._id, { flag: 'unfollow' });
          }
          return false;
        },
        onDone: {
          target: 'unFollowedSuccess',
          actions: 'updateTicketAfterFollow',
        },
        onError: 'unFollowedFailed',
      },
    },
    unFollowedSuccess: {},
    unFollowedFailed: {},
  },
};
