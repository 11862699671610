import styled from 'styled-components/macro';
import { Row as AntdRow, Col as AntdCol } from 'antd';

export const Container = styled(AntdRow)`
  width: 100%;
`;
export const UserListConainer = styled(AntdCol)``;
export const EngagementMessageContainer = styled(AntdCol)`
  min-width: 600px;
`;
export const UserProfileContainer = styled(AntdCol)`
  width: 300px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;
