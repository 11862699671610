import { client } from '@api';
import { IReasonCategoryBE, IReasonBE, IReasonCategoryCreatePayload } from '@types';
import { CancelTokenSource } from 'axios';

export const createReason = async (name: string) => {
  return client.post('/api/v1/ticket-summaries/reasons', {
    name: name.trim(),
  });
};

export const updateReason = async (id: string, name: string) => {
  return client.put(`/api/v1/ticket-summaries/reasons/${id}`, {
    name: name.trim(),
  });
};

export const deleteReason = async (id: string) => {
  return client.delete(`/api/v1/ticket-summaries/reasons/${id}`);
};

export const fetchReasons = async (
  take?: number,
  keyword?: string,
  cursorId?: string,
  cancelToken?: CancelTokenSource,
) => {
  const { data }: { data: IReasonBE[] } = await client.get('/api/v1/ticket-summaries/reasons', {
    params: {
      take,
      keyword,
      cursorId,
    },
    cancelToken: cancelToken?.token,
  });
  return data;
};

export const createOutcome = async (name: string) => {
  return client.post('/api/v1/ticket-summaries/outcomes', {
    name: name.trim(),
  });
};

export const updateOutcome = async (id: string, name: string) => {
  return client.put(`/api/v1/ticket-summaries/outcomes/${id}`, {
    name: name.trim(),
  });
};

export const deleteOutcome = async (id: string) => {
  return client.delete(`/api/v1/ticket-summaries/outcomes/${id}`);
};

export const fetchOutcome = async (
  take?: number,
  keyword?: string,
  cursorId?: string,
  cancelToken?: CancelTokenSource,
) => {
  const { data }: { data: IReasonBE[] } = await client.get('/api/v1/ticket-summaries/outcomes', {
    params: {
      take,
      keyword,
      cursorId,
    },
    cancelToken: cancelToken?.token,
  });
  return data;
};

export const fetchReasonCategory = async (
  take?: number,
  keyword?: string,
  cursorId?: string,
  cancelToken?: CancelTokenSource,
) => {
  const { data }: { data: IReasonCategoryBE[] } = await client.get('/api/v1/ticket-summaries/categories', {
    params: {
      take,
      keyword,
      cursorId,
    },
    cancelToken: cancelToken?.token,
  });
  return data;
};

export const fetchReasonCategoryById = async (id: string, take: number) => {
  const { data }: { data: IReasonCategoryBE } = await client.get(`/api/v1/ticket-summaries/categories/${id}`, {
    params: {
      take: take,
    },
  });
  return data;
};

export const updateReasonCategory = async (id: string, payload: IReasonCategoryCreatePayload) => {
  return client.put(`/api/v1/ticket-summaries/categories/${id}`, {
    ...payload,
    name: payload.name.trim(),
  });
};

export const createReasonCategory = async (payload: IReasonCategoryCreatePayload) => {
  return client.post('/api/v1/ticket-summaries/categories', {
    ...payload,
    name: payload.name.trim(),
  });
};

export const deleteReasonCategory = async (id: string) => {
  return client.delete(`/api/v1/ticket-summaries/categories/${id}`);
};

export const fetchReasonsByCategoryId = async (
  id: string,
  take?: number,
  keyword?: string,
  cursorId?: string,
  cancelToken?: CancelTokenSource,
) => {
  const { data }: { data: IReasonCategoryBE } = await client.get(`/api/v1/ticket-summaries/categories/${id}`, {
    params: {
      take,
      keyword,
      cursorId,
    },
    cancelToken: cancelToken?.token,
  });
  return data;
};

export const fetchReasonsByCategoryName = async (
  name: string,
  take?: number,
  keyword?: string,
  cursorId?: string,
  cancelToken?: CancelTokenSource,
) => {
  const { data }: { data: IReasonCategoryBE } = await client.get(`/api/v1/ticket-summaries/categories/name/${name}`, {
    params: {
      take,
      keyword,
      cursorId,
    },
    cancelToken: cancelToken?.token,
  });
  return data;
};
