import React from 'react';
import { Typography } from 'antd';
import { Container, Inner, ImageItem } from './style';

export type IStateMessage = {
  label: React.ReactNode;
  description: React.ReactNode;
  img: string;
  type?: 'error' | 'general' | 'success';
  size: 'large' | 'medium';
  imageWidth?: number;
  imageHeight?: number;
  children?: React.ReactNode;
};
const { Text, Paragraph } = Typography;
const StateMessage: React.FC<IStateMessage> = ({
  label,
  description,
  img,
  type = 'general',
  imageWidth = 64,
  imageHeight = 64,
  size,
  children,
}: IStateMessage) => {
  return (
    <Container>
      <Inner type={type} size={size}>
        <ImageItem
          style={{
            width: 'auto',
            height: imageHeight,
          }}
          src={img}
        />
        <Text>{label}</Text>
        <Paragraph>{description}</Paragraph>
      </Inner>
      {children}
    </Container>
  );
};

export default StateMessage;
