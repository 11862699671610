import styled from 'styled-components/macro';

export const Container = styled.div``;

export const SheetHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  padding: 8px 12px;
  height: 48px;
  span {
    font-size: 18px;
  }
  color: ${({ theme }) => theme.GREY_2};
  border-bottom: 1px solid ${({ theme }) => theme.GREY_7};
`;

export const SpanBtn = styled.span`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 12px;
`;

export const TextContainer = styled.div``;

export const Content = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  height: ${({ height }) => (height ? height - 75 : 0)}px;
  span {
    line-height: 20px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  button {
    width: 100%;
  }
`;
