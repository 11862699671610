import { TextAreaProps } from 'antd/lib/input';
import React from 'react';
import { AntdTextarea, Container } from './style';

export type ITextArea = TextAreaProps;

const TextArea = ({ ...rest }: ITextArea, ref: any) => {
  return (
    <Container>
      <AntdTextarea {...rest} ref={ref} />
    </Container>
  );
};

export default React.forwardRef(TextArea);
