import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import {
  Container,
  DropdownContainer,
  DropdownItem,
  EmptyWord,
  PlaceholderText,
  SelectedArea,
  SearchBox,
  DropdownContent,
  SearchInput,
  Button,
  SelectedText,
} from './style';
import { useResizeDetector } from 'react-resize-detector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { EDropdownSize } from '@components/Dropdown/DropdownTags';

export type IDropdownAutoSearchFn = {
  onScroll: (e: SyntheticEvent<HTMLDivElement>) => void;
  onSelect: (value: { id: string; name: string }) => void;
  onClearSearchKeyword: () => void;
  onInputChange: (value: string) => void;
  onClearSelected: (handler: () => void) => void;
};

export type IDropdownAutoSearchProps<T extends { id: string; name: string }> = {
  selected: string;
  inputPlaceholder: string;
  options: T[];
  emptyWord: string;
  keyword: string;
  isDisabled?: boolean;
  className?: string;
  disabled?: boolean;
  size?: EDropdownSize;
  onInputChange: (value: string) => void;
  onScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onSelect: (value: T) => void;
  onClearSearchKeyword: () => void;
  onClearSelected: () => void;
  renderCustomDropdownContent?: (options: T[]) => React.ReactNode;
};

export const DropdownAutoSearch = <T extends { id: string; name: string }>({
  inputPlaceholder,
  selected = '',
  options = [],
  emptyWord,
  keyword,
  isDisabled = false,
  className,
  size,
  onInputChange,
  onScroll,
  onSelect,
  onClearSearchKeyword,
  onClearSelected,
  renderCustomDropdownContent,
}: IDropdownAutoSearchProps<T>) => {
  const { width, ref } = useResizeDetector();
  const searchRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (ref.current && searchRef.current) {
        const withinBoundariesBase = e.composedPath().includes(ref.current);
        const withinBoundariesDropdown = e.composedPath().includes(searchRef.current);
        if (withinBoundariesBase || withinBoundariesDropdown) {
          e.preventDefault();
          return;
        } else {
          setShowPopup(false);
        }
      }
    });

    return () => {
      window.removeEventListener('click', () => false);
    };
  }, [ref, searchRef]);

  const _renderDropdown = () => {
    if (options.length === 0) {
      return <EmptyWord>{emptyWord}</EmptyWord>;
    }
    if (renderCustomDropdownContent) {
      return renderCustomDropdownContent(options);
    }

    return options.map((option) => {
      return (
        <DropdownItem
          key={option.id}
          isSelected={selected === option.name}
          onClick={() => {
            //call external function
            onSelect(option);
          }}
        >
          {option.name}
        </DropdownItem>
      );
    });
  };

  return (
    <Container className={className ? className : ''}>
      <Dropdown
        visible={showPopup}
        trigger={['click']}
        overlay={
          <DropdownContainer
            style={{
              width: width ? width : 0,
            }}
            data-wrapper="amity-popover-wrapper"
          >
            <SearchBox ref={searchRef}>
              <SearchInput
                onChange={(e) => onInputChange(e.target.value)}
                value={keyword}
                onClear={onClearSearchKeyword}
              />
            </SearchBox>
            {/* data-wrapper="amity-popover-wrapper" prevent imagemap do wrong behavior */}
            <DropdownContent onScroll={onScroll}>{_renderDropdown()}</DropdownContent>
          </DropdownContainer>
        }
      >
        <>
          <Button
            ref={ref}
            disabled={isDisabled}
            onClick={() => (isDisabled ? false : setShowPopup(true))}
            data-testid="test-dropdown-auto-search"
          >
            <SelectedArea isDisabled={isDisabled} className="select-area" size={size}>
              {selected ? (
                <>
                  <SelectedText>{selected}</SelectedText>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      isDisabled ? false : onClearSelected();
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} color="#9E9E9E" />
                  </div>
                </>
              ) : (
                <>
                  <PlaceholderText>{inputPlaceholder}</PlaceholderText>
                  <FontAwesomeIcon icon={faChevronDown} color="rgba(0,0,0,.25)" />
                </>
              )}
            </SelectedArea>
          </Button>
        </>
      </Dropdown>
    </Container>
  );
};
