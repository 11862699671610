import React from 'react';
import { BOTS } from '@types';
import {
  Content,
  ListContainer,
  ListHeader,
  ListItem,
  ListItemWrapper,
  PreviewContainer,
  SearchWrap,
  SpinContainer,
} from './style';
import { Font14G3W400, Font20G1W600, Frame, Spacing } from '@components/UtilsComponent';
import { Form, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { PreviewWrapper } from '@components/MessagePreview';
import { ConfirmModal } from '@components/Modal';
import SearchInput from '@components/SearchInput';
import FormItem from '@components/FormItem';
import { Button } from '@components/Button';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { WarningToast } from '@components/Toast';
import useTranslation from '@hooks/useTranslation';

export type ITemplateSelectorModalProps = {
  visible: boolean;
  selectedTemplate: BOTS.ITemplateApi | null;
  isLoading: boolean;
  isLoadingMore: boolean;
  listData: BOTS.ITemplateApi[];
  isDisabledConfirmBtn?: boolean;
  selectCount?: number;
  maxCount?: number;
  showToast?: boolean;
  _renderListItem?: (
    item: BOTS.ITemplateApi,
    index: number,
    onSelectTemplate: (template: BOTS.ITemplateApi) => void,
  ) => React.ReactNode;
  onUpdateKeyword: (keyword: string) => void;
  onScroll: (e: React.SyntheticEvent<HTMLDivElement, Event>) => void;
  onSearch: (value: { searchKeyword: string }) => void;
  onToggleModal: (event: 'confirm' | 'close') => void;
  onSelectTemplate: (template: BOTS.ITemplateApi) => void;
  onConfirmSelectTemplate: () => void;
};

export const TemplateSelectorModal = ({
  visible,
  selectedTemplate,
  isLoading,
  isLoadingMore,
  listData,
  isDisabledConfirmBtn = false,
  selectCount = 0,
  maxCount,
  showToast,
  onScroll,
  onUpdateKeyword,
  onSearch,
  onToggleModal,
  onSelectTemplate,
  onConfirmSelectTemplate,
  _renderListItem,
}: ITemplateSelectorModalProps) => {
  const [form] = Form.useForm();
  const t = useTranslation();
  return (
    <ConfirmModal
      visible={visible}
      width={800}
      confirmBtnTxt={'Confirm'}
      cancelBtnTxt={'Cancel'}
      isDisabledConfirmBtn={isDisabledConfirmBtn}
      handleClose={() => {
        onToggleModal('close');
      }}
      handleConfirm={() => {
        onConfirmSelectTemplate();
        onToggleModal('confirm');
      }}
    >
      <Font20G1W600>{t('template.collection.editor.modal.create.select.template.name.label')}</Font20G1W600>
      <Spacing height={16} />
      <Form onFinish={onSearch} form={form}>
        <SearchWrap>
          <Frame width={'328px'} height={'48px'}>
            <FormItem name="searchKeyword">
              <SearchInput
                size="large"
                value={''}
                onChange={(e) => onUpdateKeyword(e.target.value)}
                onClear={() => {
                  form.setFieldsValue({ searchKeyword: '' });
                  onUpdateKeyword('');
                }}
              />
            </FormItem>
          </Frame>
          <div>
            <Button size="large" htmlType="submit">
              {t('template.collection.editor.modal.create.search.name.label')}
            </Button>
          </div>
        </SearchWrap>
      </Form>
      <Spacing height={24} />
      <SpinContainer>
        <Spin
          indicator={
            <LoadingWrap>
              <LoadingCircle />
            </LoadingWrap>
          }
          spinning={isLoading}
        >
          <Content>
            <ListContainer>
              <div>
                <ListHeader>
                  <div></div>
                  <div>
                    <p> {t('template.collection.editor.modal.create.template.name.label')}</p>
                  </div>
                  <div>
                    <p>
                      {selectCount}/{maxCount ?? ''}
                    </p>
                  </div>
                </ListHeader>
              </div>
              <ListItemWrapper onScroll={onScroll}>
                {listData.map((item: BOTS.ITemplateApi, index: number) => {
                  if (_renderListItem) return _renderListItem(item, index, onSelectTemplate);
                  return (
                    <ListItem
                      isChecked={selectedTemplate?.id === item.id}
                      key={item.id}
                      onClick={() => onSelectTemplate(item)}
                    >
                      <div>{selectedTemplate?.id === item.id && <FontAwesomeIcon icon={faCheck} />}</div>
                      <div>{item.name}</div>
                    </ListItem>
                  );
                })}
                {isLoadingMore && <LoadingCircle />}
              </ListItemWrapper>
            </ListContainer>
            <PreviewContainer>
              {selectedTemplate ? <PreviewWrapper message={selectedTemplate} /> : null}
            </PreviewContainer>
          </Content>
          <div className="mt-[16px]">
            <Font14G3W400>
              {t('template.collection.editor.modal.create.new.label')}{' '}
              <a
                className="text-[#0F86FE]"
                href={`${import.meta.env.VITE_MEGABOT_WEB_PATH}/chatbot/template`}
                target="_blank"
                rel="noreferrer"
              >
                {t('template.collection.editor.modal.create.new.btn')}
              </a>
            </Font14G3W400>
          </div>
        </Spin>
        {showToast && maxCount && selectCount && selectCount > maxCount ? (
          <div className="mt-5">
            <WarningToast align={'middle'}>{t('template.collection.editor.modal.create.new.full.txt')}</WarningToast>
          </div>
        ) : null}
      </SpinContainer>
    </ConfirmModal>
  );
};
