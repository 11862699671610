import React from 'react';
import { Container, Content, AddOns, Text, Paragraph, NotRepliedBadge } from './style';
import Avatar from '@components/Avatar';
import LabelMatchSearch from '@components/LabelMatchSearch';
import EChannelType from '@enums/ChannelType';
import Timestamp from './Timestamp';
import { isMobile } from 'react-device-detect';

export type IUserAvatar = {
  id: string;
  img: string;
  name: string;
  message: any;
  displayName?: string | null;
  shouldShowNotification: boolean;
  time?: number;
  followUp?: boolean;
  isSelected?: boolean;
  channelType?: EChannelType;
  channelId?: string;
  searchTxt?: string;
  userSocialId?: string;
};

const UserAvatar: React.FC<IUserAvatar> = ({
  id,
  img,
  name,
  message,
  shouldShowNotification,
  time,
  displayName,
  followUp,
  isSelected,
  channelType = EChannelType.WEBCHAT,
  searchTxt,
}: IUserAvatar) => {
  const getMaxWidth = () => {
    if (isMobile) {
      return 'calc(100vw - 200px)';
    }
    if (shouldShowNotification) {
      return '100px';
    }

    return '130px';
  };

  const _renderName = () => {
    if (displayName) {
      return <Text ellipsis={true}>{displayName}</Text>;
    }

    return <Text ellipsis={true}>{name}</Text>;
  };
  return (
    <Container followUp={followUp} selected={isSelected}>
      <Avatar img={img} channelType={channelType} width={56} height={56}></Avatar>
      <Content
        style={{
          maxWidth: getMaxWidth(),
        }}
        hasLastMessage={message ? true : false}
      >
        {_renderName()}

        {searchTxt ? (
          <Paragraph ellipsis={{ rows: 2 }}>
            <LabelMatchSearch word={message?.text ?? ''} searchTxt={searchTxt} />
          </Paragraph>
        ) : (
          <Paragraph ellipsis={{ rows: 2 }}>{message ?? ''}</Paragraph>
        )}
      </Content>
      <AddOns>
        <Timestamp timestamp={time} />
        <div>
          <span>{shouldShowNotification && <NotRepliedBadge>Not replied</NotRepliedBadge>}</span>
        </div>
      </AddOns>
    </Container>
  );
};

export default UserAvatar;
