export const stringToHslColor = (str: string, s: number, l: number) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const matchFirstLetterToColor = (str: string) => {
  const word = str.toLocaleLowerCase();
  //A-D
  if (word.match(/^[abcd]+/gi)) {
    return '#FFB400';
  }
  //E-H
  if (word.match(/^[efgh]+/gi)) {
    return '#F72C40';
  }
  //I-L
  if (word.match(/^[ijkl]+/gi)) {
    return '#FF742F';
  }
  //M-P
  if (word.match(/^[mnop]+/gi)) {
    return '#05BED2';
  }
  //Q-T
  if (word.match(/^[qrst]+/gi)) {
    return '#5856D6';
  }
  //U-Z
  return '#E61E73';
};
