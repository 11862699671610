import axios from 'axios';
const ogEndpoint = import.meta.env.VITE_OG_SERVER_ENDPOINT;
export const getOgData = async (url: string) => {
  const {
    result,
  }: {
    result: {
      title: string;
      description: string;
      image: string;
    };
  } = (await axios.get(`${ogEndpoint}?url=${url}`)).data;
  return result;
};
