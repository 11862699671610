import styled from 'styled-components/macro';

export const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  #lottie {
    width: 200px;
    height: 200px;
  }
`;
