import { useState, ChangeEvent } from 'react';
import { DropDownData } from '.';

const useDropdownApply = (
  data: DropDownData[],
  selected: DropDownData[],
  onSelectChange: (selected: DropDownData[]) => void,
) => {
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  const handleSelectItem = (value: DropDownData) => {
    const newSelected = selected.slice();
    const index = newSelected.findIndex((item) => item.value === value.value);
    if (index > -1) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(value);
    }
    onSelectChange(newSelected);
  };

  const handleOnSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (searchText !== value) {
      setSearchText(value);
    }
  };

  const handleOnClearSearchText = () => {
    setSearchText('');
  };

  const handleSelectAll = () => {
    //is all
    if (selected.length === data.length) {
      onSelectChange([]);
    } else {
      onSelectChange(data);
    }
  };

  const handleClear = () => {
    setVisible(false);
  };

  const handleVisible = (value: boolean) => {
    setVisible(value);
  };

  return {
    visible,
    searchText,
    handleSelectItem,
    handleSelectAll,
    handleClear,
    handleVisible,
    handleOnClearSearchText,
    handleOnSearchTextChange,
  };
};

export default useDropdownApply;
