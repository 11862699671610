import React from 'react';
import Avatar from '@components/Avatar';
import { Container } from './style';
import { IMember } from '@types';
import { Text } from '@components/UtilsComponent';

export type IMemberListItem = {
  member: IMember;
  onSelect: (member: IMember) => void;
};

const MemberListItem: React.FC<IMemberListItem> = ({ member, onSelect }: IMemberListItem) => {
  return (
    <Container onClick={() => onSelect(member)}>
      <Avatar name={member.username} width={40} height={40} />
      <Text>{member.username}</Text>
    </Container>
  );
};

export default MemberListItem;
