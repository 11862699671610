import React, { useCallback, useEffect, useRef } from 'react';
import { DropdownDynamicBtn } from '@components/Dropdown';
import { IOption } from '@components/Select';

import { SelectBtn } from '@components/SelectBtn';
import { Checkbox } from '@components/Checkbox';
import { DropdownItem } from './style';
import { cloneDeep } from 'lodash';
import { IMember } from '@types';
import { Font12G5W400, Font14G1W400 } from '@components/UtilsComponent';
import { useInfinityLoadByCursorId } from '@hooks/useInfinityLoadByCursorId';
import { getAgentInMyTeam } from '@api/livechat/TicketManagement';

type IAssigneeFilterDashboardProps = {
  teamsIds: string[];
  selectedAssignee: string[];
  onSelectAssignee: (items: string[]) => void;
};

export const AssigneeFilterDashboard = ({
  selectedAssignee = [],
  teamsIds,
  onSelectAssignee,
}: IAssigneeFilterDashboardProps) => {
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const { data, firstLoading, hasMore, onOpenDropdown, onLoadmore } = useInfinityLoadByCursorId<
    IMember & { id: string },
    IOption
  >({
    fetchFn: async () => {
      const res = await getAgentInMyTeam();
      const result = res.data.map((item: IMember & { id: string }) => {
        return {
          ...item,
          id: item._id,
          label: item.username,
          value: item.id,
        };
      });
      return result;
    },
  });

  const selectedRef = useRef<string[]>([]);
  const _renderSelectedText = useCallback((selected: string[]): React.ReactNode => {
    // if length more than 1
    if (selected.length > 1) {
      return `${selected.length} assignees selected`;
    }
    if (selected.length === 1) {
      return `1 assignee selected`;
    }
    return '';
  }, []);

  useEffect(() => {
    selectedRef.current = selectedAssignee;
  }, [onSelectAssignee]);
  return (
    <>
      <DropdownDynamicBtn
        showClearAll
        onClearAllSelection={() => {
          selectedRef.current = [];
          onSelectAssignee(selectedRef.current);
        }}
        searchKeyword={searchKeyword}
        onUpdateSearchKeyword={(keyword) => setSearchKeyword(keyword)}
        options={data}
        isFirstLoading={firstLoading}
        renderOptions={(options) => {
          const filtered = options.filter((item) => {
            return item.username.toLowerCase().includes(searchKeyword.toLowerCase());
          });
          return filtered.map((item) => {
            const teamsStr = item.teams
              .map((team) => {
                return team.name;
              })
              .join(', ');
            return (
              <DropdownItem
                data-testid={`test-assignee-${item.label}`}
                key={item.value}
                onClick={() => {
                  const clone = cloneDeep(selectedRef.current);
                  const index = clone.findIndex((i) => {
                    return i === item.id;
                  });
                  // check if exist then remove
                  if (index > -1) {
                    clone.splice(index, 1);
                    onSelectAssignee(clone);
                    return;
                  }
                  clone.push(item.id);
                  selectedRef.current = clone;
                  onSelectAssignee(clone);
                }}
              >
                <div className="flex flex-col !items-start">
                  <Font14G1W400>{item.label}</Font14G1W400>
                  <Font12G5W400 className="max-w-[180px] overflow-hidden overflow-ellipsis" title={teamsStr}>
                    {teamsStr}
                  </Font12G5W400>
                </div>
                <Checkbox
                  checked={
                    !!selectedAssignee.find((i) => {
                      return i === item.id;
                    })
                  }
                />
              </DropdownItem>
            );
          });
        }}
        renderBtn={function (
          prevState: boolean,
          setter: (status: React.SetStateAction<boolean>) => void,
        ): React.ReactNode {
          return (
            <SelectBtn
              data-testid="test-assignee-filter-btn"
              selectedText={_renderSelectedText(selectedAssignee)}
              placeholder={'All Assignees'}
              onClick={() => {
                // toggle dropdown
                setter(!prevState);
                if (!prevState) {
                  onOpenDropdown();
                }
              }}
            />
          );
        }}
        hasMore={hasMore}
        onLoadMore={onLoadmore}
      />
    </>
  );
};
