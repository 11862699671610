import styled from 'styled-components/macro';
import { Image as AntdImage } from 'antd';

export const Image = styled(AntdImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: ${({ theme }) => theme.GREY_9};
`;

export const Container = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
  .ant-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: ${({ theme }) => theme.GREY_9};
  }
`;
