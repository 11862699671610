import styled, { css } from 'styled-components/macro';

export const defaultStyle = css`
  padding: 4px 10px;
  text-align: center;
  border-radius: 60px;
  width: fit-content;
  font-size: 12px;
  line-height: 16px;
`;

export const BadgeDate = styled.div`
  background: ${({ theme }) => theme.GREY_9};
  color: ${({ theme }) => theme.GREY_4};
  ${defaultStyle};
`;

export const BadgeTicket = styled.div`
  background: ${({ theme }) => theme.BLUE_LIGHT};
  color: ${({ theme }) => theme.GREY_2};
  ${defaultStyle};
`;
