import styled from 'styled-components/macro';
import { FontAwesomeIcon as FIcon } from '@fortawesome/react-fontawesome';
import { Tag } from 'antd';

export const TagItem = styled(Tag)<{ isLoading?: boolean }>`
  padding: 8px 12px;
  border: none;
  border-radius: 20px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.GREY_8};
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'auto')};
`;

export const TagLabel = styled.span<{ isLoading?: boolean }>`
  color: ${({ theme, isLoading }) => (isLoading ? theme.GREY_6 : theme.GREY_1)} !important;
`;

export const FontAwesomeIcon = styled(FIcon)`
  margin-left: 6px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.GREY_5};
`;
