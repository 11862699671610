import { ERole } from '@enums/Role';
import { ETicketStatus } from '@enums/TicketStatus';
import { IMember, ITicket } from '@types';

export const canDoTeamFollowUp = (ticket: ITicket, member: IMember): boolean => {
  // ticket is open
  const isOpen = ticket?.status === ETicketStatus.OPEN;
  const isAgent = member.roles.includes(ERole.AGENT);
  const isSupervisor = member.roles.includes(ERole.SUPERVISOR);
  // other agent cannot do team follow up if the ticket is not assigned to them
  const isOtherAgent = (isAgent || isSupervisor) && member._id !== ticket?.agentId;
  if (isOpen || isOtherAgent) {
    return false;
  }
  return true;
};
