import React, { ReactNode } from 'react';
import { Table as AntTable, TableProps } from 'antd';
import { Container } from './style';
import { ColumnType } from 'antd/lib/table';

export type IColumns<T = any> = ColumnType<T>;

export type ITable<T = any> = {
  header: React.ReactNode;
  data: any;
  columns: IColumns<T>[];
} & TableProps<T>;

const Table: React.FC<ITable> = ({ header, data, columns, ...rest }: ITable) => {
  return (
    <Container>
      {header}
      <AntTable columns={columns} dataSource={data} {...rest} />
    </Container>
  );
};

export default Table;
