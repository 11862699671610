import React from 'react';
import { BaseTag, TagLabel } from '../style';
import { AssignedTag, OpenTag, ResolvedTag } from './style';

export type IStatusTag = {
  type: 'open' | 'assigned' | 'resolved';
};

const StatusTag = (props: IStatusTag) => {
  switch (props.type) {
    case 'open':
      return (
        <OpenTag>
          <TagLabel>Open</TagLabel>
        </OpenTag>
      );
    case 'assigned':
      return (
        <AssignedTag>
          <TagLabel>Assigned</TagLabel>
        </AssignedTag>
      );
    case 'resolved':
      return (
        <ResolvedTag>
          <TagLabel type={'secondary'}>Resolved</TagLabel>
        </ResolvedTag>
      );
    default:
      return (
        <BaseTag>
          <TagLabel type={'secondary'}>Unassigned</TagLabel>
        </BaseTag>
      );
  }
};

export default StatusTag;
