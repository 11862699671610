import React from 'react';
import { Container } from './style';

export type IVideoPreviewProps = {
  url: string;
};

export const VideoPreview = ({ url }: IVideoPreviewProps) => {
  return (
    <Container>
      <video src={url} controls />
    </Container>
  );
};
