import React, { useEffect } from 'react';
import { ConfirmModal } from '@components/Modal/ConfirmModal';
import { Font20G1W600, Frame, Spacing } from '@components/UtilsComponent';
import { IFetchParams } from '@utils/tableListGeneratorV2';
import { Content, ListContainer, ListHeader, ListItem, ListItemWrapper, SearchWrap, SpinContainer } from './style';
import { Form, Spin } from 'antd';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import FormItem from '@components/FormItem';
import SearchInput from '@components/SearchInput';
import { Button } from '@components/Button';
import { useInfinityScrollTable } from '@hooks/useInfinityScrollTable';
import StateMessage from '@components/StateMessage';
import error from '@assets/images/error.svg';
import useTranslation from '@hooks/useTranslation';

export type IModalListProps<T> = {
  title: string;
  columns: { label: string; key: string; width?: string }[];
  visible: boolean;
  isDisabledConfirmBtn?: boolean;
  modalWidth?: number;
  confirmText?: string;
  cancelText?: string;
  isMultiple?: boolean;
  maxTake?: number;
  extra?: React.ReactNode;
  fetchFn: (params: IFetchParams) => Promise<T[]>;
  onToggleModal: (event: 'confirm' | 'close') => void;
  _renderItems: (item: T[]) => React.ReactNode;
};

export const ModalList = <T,>({
  title,
  columns,
  visible,
  modalWidth,
  isDisabledConfirmBtn,
  confirmText,
  cancelText,
  maxTake,
  extra,
  fetchFn,
  onToggleModal,
  _renderItems,
}: IModalListProps<T>) => {
  const t = useTranslation();
  const [form] = Form.useForm();
  const { current, isLoading, onSearch, onScroll, onUpdateKeyword } = useInfinityScrollTable({
    fetchFn,
    maxTake: maxTake ?? 10,
  });

  useEffect(() => {
    if (visible) {
      onSearch();
    }
  }, [visible]);

  return (
    <>
      <ConfirmModal
        visible={visible}
        width={modalWidth ? modalWidth : 800}
        confirmBtnTxt={confirmText ? confirmText : 'Confirm'}
        cancelBtnTxt={cancelText ? cancelText : 'Cancel'}
        isDisabledConfirmBtn={isDisabledConfirmBtn}
        handleClose={() => {
          onToggleModal('close');
        }}
        handleConfirm={() => {
          onToggleModal('confirm');
        }}
      >
        <Font20G1W600>{title}</Font20G1W600>
        <Spacing height={16} />
        <Form onFinish={onSearch} form={form}>
          <SearchWrap>
            <Frame width={'328px'} height={'48px'}>
              <FormItem name="searchKeyword">
                <SearchInput
                  size="large"
                  value={current.context.searchKeyword}
                  onChange={(e) => onUpdateKeyword(e.target.value)}
                  onClear={() => {
                    form.setFieldsValue({ searchKeyword: '' });
                    onUpdateKeyword('');
                  }}
                />
              </FormItem>
            </Frame>
            <div>
              <Button size="large" htmlType="submit">
                Search
              </Button>
            </div>
          </SearchWrap>
        </Form>
        <Spacing height={24} />
        <SpinContainer>
          <Spin
            indicator={
              <LoadingWrap>
                <LoadingCircle />
              </LoadingWrap>
            }
            spinning={isLoading}
          >
            <Content>
              <ListContainer>
                <div>
                  <ListHeader>
                    {columns.map((column) => {
                      return (
                        <div key={column.label} style={{ minWidth: column.width ?? '100%' }}>
                          <p>{column.label}</p>
                        </div>
                      );
                    })}
                  </ListHeader>
                </div>
                {current.matches('failure') ? (
                  <div className="h-full">
                    <StateMessage
                      size="large"
                      type="error"
                      img={error}
                      label={t('livechat.error.title')}
                      description={t('livechat.error.description')}
                    />
                  </div>
                ) : (
                  <ListItemWrapper onScroll={onScroll}>
                    {_renderItems(current.context.list.data as T[])}
                    {current.matches('loadingMore') && <LoadingCircle />}
                  </ListItemWrapper>
                )}
              </ListContainer>
            </Content>
            {extra}
          </Spin>
        </SpinContainer>
      </ConfirmModal>
    </>
  );
};
