import { getUserListBySearch, getUserMessageListBySearch } from '@api/livechat/EngagementUserList';
import { message } from 'antd';
import dayjs from 'dayjs';
import {
  ConcatMesssagesSearchDataEvent,
  ConcatUsersSearchDataEvent,
  IUserListMachineContext,
  UpdateMessagesSearchDataEvent,
  UpdateUsersSearchDataEvent,
} from '../userListMachine.type';

export const getMessagesBySearch = async (context: IUserListMachineContext) => {
  const { channels, keyword, member, status } = context.filterParams;
  const messageList = context.searchData.messageList.userMessages;
  const lastItemSearchMessage =
    messageList.length > 0 ? dayjs(messageList[messageList.length - 1].time).valueOf() : undefined;
  const result = await getUserMessageListBySearch(
    keyword,
    member._id,
    channels.join(','),
    status,
    member.nid,
    lastItemSearchMessage,
  );
  return {
    type: lastItemSearchMessage ? 'CONCAT_MESSAGES_SEARCH_DATA' : 'FETCH_MESSAGES_SEARCH_DATA',
    value: result.data,
  } as UpdateMessagesSearchDataEvent | ConcatMesssagesSearchDataEvent;
};

export const getUsersBySearch = async (context: IUserListMachineContext) => {
  const { keyword, member, channels, status } = context.filterParams;
  const userList = context.searchData.userList.userMessages;
  const lastItemSearchMessage = userList.length > 0 ? dayjs(userList[userList.length - 1].time).valueOf() : undefined;
  const result = await getUserListBySearch(
    keyword,
    member._id,
    channels.join(','),
    status,
    member.nid,
    lastItemSearchMessage,
  );
  return {
    type: lastItemSearchMessage ? 'CONCAT_USERS_SEARCH_DATA' : 'FETCH_USERS_SEARCH_DATA',
    value: result.data,
  } as UpdateUsersSearchDataEvent | ConcatUsersSearchDataEvent;
};
