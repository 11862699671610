import styled from 'styled-components/macro';

export const Container = styled.div`
  > div {
    max-height: 57px;
    audio {
      height: 57px !important;
      width: 300px !important;
    }
  }
`;
