import { EMessageType } from '@enums/MessageType';
import { BaseMessage, BaseMessageJson } from './BaseMessage';
import { IMessageLevel } from '@types';

export type IOptionItem = {
  id: string;
  label: string;
};

export type OptionMessageJson = {
  label: string;
  actions: IOptionItem[];
} & BaseMessageJson;

export class OptionMessage extends BaseMessage {
  private label: string;
  private actions: IOptionItem[];
  private level: IMessageLevel;

  constructor(data: OptionMessageJson) {
    const { id, createdAt } = data;
    super({ ...data, id, createdAt, senderImage: data.senderImage });
    this.label = data.label;
    this.actions = data.actions;
    this.level = 1;
  }

  get instanceData() {
    return {
      id: this.id,
      createdAt: this.createdAt,
      label: this.label,
      retry: this.retry,
      actions: this.actions,
      level: this.level,
      senderImage: this.senderImage,
      senderName: this.senderName,
    };
  }

  get getMessageType() {
    return EMessageType.OPTION;
  }

  set setLevel(level: IMessageLevel) {
    this.level = level;
  }
}
