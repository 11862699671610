import { Button, GhostButton, PrimaryButton } from '@components/Button';
import Select from '@components/Select';
import {
  BgDot,
  Font10G5W600,
  Font12G3W400,
  Font14G1W400,
  Font14G1W600,
  Font14WhiteW500,
  Font16G1W400,
  Font20G1W600,
  Font12WhiteW500,
  Font12G5W400,
  Counter,
  Font14G1W500,
} from '@components/UtilsComponent';
import useTranslation from '@hooks/useTranslation';
import getLogoByChannelType from '@utils/getLogoByChannelType';
import { Breadcrumb, Drawer, Spin } from 'antd';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCustomerSatisfactionEditor } from './useCustomerSatisfactionEditor';
import { ECustomerSatisfactionValue, ISurvey, SURVEY_TYPE_OPTION } from '@types';
import { QuickReplySurvey } from '../components/QuickReplySurvey';
import Switch from '@components/Switch';
import { AdditionalSurvey } from '../components/AdditionalSurvey';
import TextInput from '@components/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faInfoCircle, faRobot, faTimes } from '@fortawesome/pro-solid-svg-icons';
import TextArea from '@components/TextArea';
import { MAX_MESSAGE_RESPONSE_LENGTH } from '@configs/constants';
import { CustomFormItem } from '@components/CustomFormItem';
import LoadingCircle from '@components/Loading';
import { LoadingWrap } from '@components/Loading/style';
import { RouteLeavingGuard } from '@components/RouteLeavingGuard';
import { ExternalSurvey } from '../components/ExternalSurvey';
import { ImageMapSurvey } from '../components/ImageMapSurvey';
import EChannelType from '@enums/ChannelType';

export const CustomerSatisfactionEditor = () => {
  const t = useTranslation();
  const {
    createMute,
    surveyFetch,
    surveyId,
    survey,
    isDisabledSave,
    showPrevew,
    routeState,
    updateMute,
    isSomethingUpdate,
    onUpdate,
    onCreate,
    setShowPrevew,
    onUpdateSurvey,
  } = useCustomerSatisfactionEditor();

  const history = useHistory();
  const selectValue = useMemo(() => {
    if (routeState.channelType === EChannelType.FACEBOOK) {
      return SURVEY_TYPE_OPTION.filter((option) => option.value !== ECustomerSatisfactionValue.imagemap);
    }
    return SURVEY_TYPE_OPTION;
  }, [routeState]);
  const goBack = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    history.goBack();
  };
  const _renderSurveyType = useMemo(() => {
    if (survey.type === ECustomerSatisfactionValue.externalLink) {
      return <ExternalSurvey question={survey.message.externalLink || ''} onUpdateSurvey={onUpdateSurvey} />;
    }
    if (survey.type === ECustomerSatisfactionValue.imagemap && routeState.channelType !== EChannelType.FACEBOOK) {
      return (
        <ImageMapSurvey
          channelType={routeState.channelType}
          imagemap={survey.message.imageMap}
          onUpdateSurvey={onUpdateSurvey}
        />
      );
    }
    return (
      <QuickReplySurvey
        question={survey.message.question}
        options={survey.message.options}
        onUpdateSurvey={onUpdateSurvey}
      />
    );
  }, [survey]);

  const _renderPreveiwStep = () => {
    if (survey.type === ECustomerSatisfactionValue.quickReply) {
      return (
        <div className="mt-[28px] w-full flex justify-center flex-col items-center">
          <PreviewStep step={1} />
          <div className="flex gap-x-[8px] mt-[24px] w-full">
            <BotIcon />
            <div className="bg-white py-[4px] px-[10px] rounded-[16px]  break-words max-w-[300px]">
              {survey.message.question || '-'}
            </div>
          </div>

          <div className="flex gap-x-[8px] items-center w-full overflow-auto pb-[16px]">
            {survey.message.options.map((option) => {
              if (option.label === '') return null;
              return (
                <div key={option.id} className="bg-[#a8b1bc] py-[7px] px-[10px] rounded-[16px] mt-[8px] text-white">
                  {option.label}
                </div>
              );
            })}
          </div>
          <TheRestStepPreview survey={survey} />
        </div>
      );
    }
    if (survey.type === ECustomerSatisfactionValue.externalLink) {
      return (
        <div className="mt-[28px] w-full flex justify-center flex-col items-center">
          <PreviewStep step={1} />
          <div className="flex gap-x-[8px] mt-[24px] w-full">
            <BotIcon />
            <div className="bg-white py-[4px] px-[10px] rounded-[16px]  break-words max-w-[300px]">
              {survey.message.externalLink || '-'}
            </div>
          </div>
        </div>
      );
    }
    if (survey.type === ECustomerSatisfactionValue.imagemap) {
      return (
        <div className="mt-[28px] w-full flex justify-center flex-col items-center">
          <PreviewStep step={1} />
          <div className="flex gap-x-[8px] mt-[24px] w-full">
            <BotIcon />
            <div className="w-full h-full">
              <img src={survey.message.imageMap?.imgUrl ?? ''} />
            </div>
          </div>
          <TheRestStepPreview survey={survey} />
        </div>
      );
    }
  };

  return (
    <Spin
      spinning={createMute.isLoading || surveyFetch.isLoading || updateMute.isLoading}
      indicator={
        <LoadingWrap>
          <LoadingCircle
            style={{
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </LoadingWrap>
      }
    >
      <RouteLeavingGuard
        wording={t('customer.satisfaction.editor.route.leaving.guard')}
        when={isSomethingUpdate}
        navigate={(path) => {
          history.push(path);
        }}
        shouldBlockNavigation={() => {
          if (isSomethingUpdate) {
            return true;
          }
          return false;
        }}
      />
      <BgDot className="h-full fixed w-full z-[-1]" />
      <Button className="fixed top-[101px] right-[32px]" onClick={() => setShowPrevew(!showPrevew)}>
        <Font14G1W600>
          <FontAwesomeIcon icon={faEye} className="mr-[8px]" />
          {t('customer.satisfaction.editor.preview.btn')}
        </Font14G1W600>
      </Button>
      <Drawer
        width={400}
        placement="right"
        bodyStyle={{
          background: '#f5f5f5',
        }}
        closable={false}
        onClose={() => setShowPrevew(false)}
        visible={showPrevew}
      >
        <div className="h-full flex flex-col">
          <div className="flex justify-between items-center min-h-[36px]">
            <div className="flex-1" />
            <div className="!text-[#BDBDBD] flex-1 text-center">
              <FontAwesomeIcon icon={faEye} className="mr-[8px]" />
              {t('customer.satisfaction.editor.preview.btn')}
            </div>
            <div className="flex-1 text-right">
              <Button onClick={() => setShowPrevew(false)} className="inline-flex items-center !bg-[#e4e4e4]">
                <FontAwesomeIcon icon={faTimes} className="text-[16px]" />
              </Button>
            </div>
          </div>
          {/* Content */}
          <div className="flex flex-col items-center gap-y-[16px] h-full">{_renderPreveiwStep()}</div>
        </div>
      </Drawer>
      <div>
        <div className="fixed top-0 z-[99] w-[calc(100vw-65px)] h-auto py-[16px] px-[32px] bg-white  flex justify-center items-centaer border-b border-solid border-[#E5E5E5]">
          <div className="flex-1 items-center flex">
            <Breadcrumb separator="/">
              <Link to="/settings" onClick={(e) => goBack(e)}>
                <Breadcrumb.Item>{t('customer.satisfaction.editor.breadcrumb.livechat.settings')}</Breadcrumb.Item>
              </Link>
              <Breadcrumb.Item>{t('customer.satisfaction.editor.breadcrumb.survey.customization')}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="flex-1 flex justify-center items-center gap-x-[8px]">
            <Font10G5W600>{t('customer.satisfaction.editor.channel')}</Font10G5W600>
            <Font20G1W600>{routeState.channelName}</Font20G1W600>
            <img alt={'LINE'} width={20} height={20} src={getLogoByChannelType(routeState.channelType)} />
          </div>
          <div className="flex-1 flex justify-end items-center gap-x-[8px]">
            <Link to="/settings" onClick={(e) => goBack(e)}>
              <GhostButton>{t('customer.satisfaction.editor.cancel')}</GhostButton>
            </Link>
            <Button
              disabled={isDisabledSave}
              onClick={() => {
                // update survey
                if (surveyId) {
                  onUpdate(survey.isEnable);
                } else {
                  // create survey
                  onCreate(survey.isEnable);
                }
              }}
            >
              {t('customer.satisfaction.editor.save')}
            </Button>
            <PrimaryButton
              disabled={isDisabledSave}
              onClick={() => {
                // update survey
                if (surveyId) {
                  onUpdate(true);
                } else {
                  // create survey
                  onCreate(true);
                }
              }}
            >
              {t('customer.satisfaction.editor.save.and.turn.on')}
            </PrimaryButton>
          </div>
        </div>
        <div className="w-[600px] mx-auto mt-[101px] min-h-[calc(100vh-69px-32px)]">
          {/* step 1 */}
          <div>
            <div>
              <div className="flex gap-x-[8px]">
                <Step step={1} />
                <Font16G1W400>{t('customer.satisfaction.editor.step1')}</Font16G1W400>
              </div>
            </div>
            <div className="flex items-center gap-x-[16px] mt-[24px]">
              <Font14G1W400 className="min-w-[80px]">
                {t('customer.satisfaction.editor.step1.survey.type.label')}
              </Font14G1W400>
              <div className="w-[230px]">
                <Select
                  value={survey.type}
                  options={selectValue}
                  onChange={(value: ECustomerSatisfactionValue) => {
                    onUpdateSurvey('type', value);
                  }}
                  size="large"
                />
              </div>
            </div>
            <div className="py-[24px]">{_renderSurveyType}</div>
            {survey.type !== ECustomerSatisfactionValue.externalLink && (
              <div className="border-t border-solid border-[#E5E5E5]">
                <div className="flex gap-x-[8px] py-[24px]">
                  <Step step={2} />
                  <Font16G1W400>{t('customer.satisfaction.editor.step2')}</Font16G1W400>
                  <Switch
                    size="large"
                    checked={survey.message.isFeedback}
                    onChange={(checked) => onUpdateSurvey('message.isFeedback', checked)}
                  />
                </div>
                <div>
                  {survey.message.isFeedback && (
                    <AdditionalSurvey
                      question={survey.message.feedbackMessage?.question || ''}
                      guideline={survey.message.feedbackMessage?.guideline || ''}
                      options={survey.message.feedbackMessage?.options || []}
                      onUpdateSurvey={onUpdateSurvey}
                    />
                  )}
                </div>
                <div className="divider-y-24" />
                <div className="flex gap-x-[8px] pb-[24px]">
                  <Step step={3} />
                  <Font16G1W400>{t('customer.satisfaction.editor.step3')}</Font16G1W400>
                </div>
                <div className="flex gap-x-[16px] items-center">
                  <Font14G1W400>{t('customer.satisfaction.editor.step3.timeout.label')}</Font14G1W400>
                  <div className="w-[80px]">
                    <TextInput
                      size="large"
                      value={survey.timeoutMS}
                      onChange={(e) => onUpdateSurvey('timeoutMS', parseInt(e.target.value) || 0)}
                    />
                  </div>
                  <Font14G1W400>{t('customer.satisfaction.editor.step3.timeout.unit')}</Font14G1W400>
                  <div className="flex gap-x-[4px]">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-[16px] text-[#BDBDBD]" />
                    <Font12G3W400 className="italic">
                      {t('customer.satisfaction.editor.step3.timeout.hint')}
                    </Font12G3W400>
                  </div>
                </div>
                <div className="mt-[24px] pb-[24px]">
                  <div className="w-full rounded-[16px] shadow-custom p-[24px] bg-white transition-all">
                    <div className="flex flex-col gap-y-[4px]">
                      <Font14G1W500>{t('customer.satisfaction.editor.message.response.qustion.label')}</Font14G1W500>
                      <CustomFormItem
                        id={`message-response`}
                        isError={!survey.message.thxMessage}
                        errorMessage={t('customer.satisfaction.editor.message.response.validate')}
                        value={survey.message.thxMessage}
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                          onUpdateSurvey('message.thxMessage', e.target.value);
                        }}
                        counter={
                          <Counter>
                            <Font12G5W400>{`${
                              survey.message.thxMessage?.length || 0
                            } / ${MAX_MESSAGE_RESPONSE_LENGTH}`}</Font12G5W400>
                          </Counter>
                        }
                      >
                        <TextArea
                          autoSize={{
                            minRows: 4,
                            maxRows: 8,
                          }}
                          maxLength={MAX_MESSAGE_RESPONSE_LENGTH}
                        />
                      </CustomFormItem>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
};

const Step = ({ step }: { step: number }) => {
  return (
    <div className="flex items-center justify-center min-w-[26px] min-h-[26px] max-w-[26px] max-h-[26px] bg-[#04BE8C] text-white rounded-[20px]">
      <Font14WhiteW500>{step}</Font14WhiteW500>
    </div>
  );
};

const PreviewStep = ({ step }: { step: number }) => {
  return (
    <div className="flex items-center gap-x-[8px] w-[80%]">
      <div className="h-[1px] flex-1 bg-[#E5E5E5]" />
      <div className="rounded-[28px] min-w-[26px] min-h-[26px] max-w-[26px] max-h-[26px] bg-[#d8d8d8] flex justify-center items-center text-white">
        <Font12WhiteW500>{step}</Font12WhiteW500>
      </div>
      <div className="h-[1px] flex-1 bg-[#E5E5E5]" />
    </div>
  );
};

const BotIcon = () => {
  return (
    <div className="min-w-[32px] max-w-[32px] max-h-[32px] min-h-[32px] bg-primary rounded-full flex justify-center items-center">
      <FontAwesomeIcon icon={faRobot} className="text-white" />
    </div>
  );
};

const TheRestStepPreview = ({ survey }: { survey: ISurvey }) => {
  const t = useTranslation();
  return (
    <>
      {survey.message.isFeedback && (
        <>
          <div className="mt-[40px]" />
          <PreviewStep step={2} />
          <div className="flex gap-x-[8px] mt-[24px] w-full">
            <BotIcon />
            <div className="bg-white py-[4px] px-[10px] rounded-[16px]  break-words max-w-[300px]">
              {survey.message.feedbackMessage?.question || '-'}
            </div>
          </div>
          <div className="flex gap-x-[8px] items-center w-full overflow-auto pb-[16px]">
            {survey.message.feedbackMessage?.options.map((option) => {
              if (option.label === '') return null;
              return (
                <div key={option.id} className="bg-[#a8b1bc] py-[7px] px-[10px] rounded-[16px] mt-[8px] text-white">
                  {option.label}
                </div>
              );
            })}
          </div>
          <div className="mt-[24px] w-full">
            <Font12G5W400>{t('customer.satisfaction.editor.message.response.yes.btn.hint')}</Font12G5W400>
            <div className="flex gap-x-[8px] mt-[8px] w-full">
              <BotIcon />
              <div className="bg-white py-[4px] px-[10px] rounded-[16px]  break-words max-w-[300px]">
                {survey.message.feedbackMessage?.guideline || '-'}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="mt-[24px]" />
      <PreviewStep step={3} />
      <div className="flex gap-x-[8px] mt-[24px] w-full pb-[24px]">
        <BotIcon />
        <div className="bg-white py-[4px] px-[10px] rounded-[16px]  break-words max-w-[300px]">
          {survey.message.thxMessage || '-'}
        </div>
      </div>
    </>
  );
};
