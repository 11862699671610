import React, { useState, useEffect } from 'react';
import { Container, Image } from './style';

export type IImageMessage = {
  url: string;
};

export const ImageMessage: React.FC<IImageMessage> = ({ url }: IImageMessage) => {
  return (
    <Container>
      <Image loading="lazy" src={url} />
    </Container>
  );
};
