import React from 'react';
import EngagementUserProfileProvider from '@pages/desktop/recents/EngagementUserProfile/EngagementUserProfileProvider';
import { Container, UserProfileContainer, HistoryContainer } from './style';
import UserProfile from '@pages/desktop/recents/EngagementUserProfile/UserProfile';
import useTicketHistoryUserProfile from './useTicketHistoryUserProfile';
import UserDetailTab from '@pages/desktop/recents/EngagementUserProfile/UserDetailTab';
import { IUser } from '@types';

export type ITicketHistoryUserProfileProps = {
  user: IUser;
  onFetchUser: () => void;
};

const TicketHistoryUserProfile: React.FC<ITicketHistoryUserProfileProps> = ({ user, onFetchUser }) => {
  const { currentTicket } = useTicketHistoryUserProfile();
  return (
    <>
      <EngagementUserProfileProvider userId={currentTicket.userId}>
        <Container>
          <UserProfileContainer>
            <UserProfile userProfile={user} ticket={currentTicket} onRefetch={() => onFetchUser()} />
          </UserProfileContainer>
          <HistoryContainer>
            <UserDetailTab />
          </HistoryContainer>
        </Container>
      </EngagementUserProfileProvider>
    </>
  );
};

export default TicketHistoryUserProfile;
